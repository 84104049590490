import {
    Component, OnInit, Input,
    ViewChild, OnDestroy, AfterViewInit,
    OnChanges, SimpleChanges, HostListener, Output, EventEmitter, ComponentFactoryResolver, forwardRef, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Editing } from 'devextreme/ui/data_grid';
import * as $ from 'jquery';
import { TableState, User } from '@app/shared/model';
import { Tools } from '@app/shared/class/tools';
import { IDaoServiceOptions } from '../dx-form/models/form-field';
import { Deferred, concatDefinedArrray } from '@app/utils';
import { KeyedTemplateDirective } from '@app/shared/directive/keyed-template-directive/keyed-template.directive';
import { DxDataGridColumn, dataType, loadDataType, NextDxDataGridOptions } from './models';
import { IDaoBaseService } from '@app/shared/interface';
import { notifySuccessDelay, variablesKeys } from '@app/globalsParameter';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core/services';
import { HeaderInfo } from '@app/shared/component/left-header-bloc/models';
import { DataGridAction, DataGridActionColumn } from './models/dataGridActionColumn';
import { PaginationSet } from '@app/shared/viewModel/pagination-set';
import { DataGridFiltersParam, GlobalColumnFilter, LookupColumnFilter, SingleColumnFilter } from '@app/shared/viewModel/data-grid-filters-param';
import { SlidePanelModel } from '../slide-panel/slide-panel-model';
import { SlidePanelComponent } from '../slide-panel/slide-panel.component';
import { confirmDialog, CustomConfirmDialogResult } from '@app/utils/dialogs';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { convertEnumToKeyValue } from '@app/utils/enum-helper';
import { DataGridInput, SlidePanelChildOptions } from '../genericComponentOption';
import { FooterInfo } from '@app/shared/component/data-grid-footer/models/footer-info';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import * as globalsParameter from '@app/globalsParameter';
import { defaultDataGridOptions } from '../defaultDevExtremeConfig';
import { orderObject } from '@app/utils/orderObject';
import { Router } from '@angular/router';
import { StorageService } from '@app/core/services/storage.service';

@Component({
    selector: 'app-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @ViewChild(forwardRef(() => SlidePanelComponent), { static: false }) slidePanelComponent: SlidePanelComponent;
    @ViewChild(DxDataGridComponent, { static: true }) dxGrid: DxDataGridComponent;
    // Variable pour columnChosser
    @ViewChild('columnChooser_list', { static: false }) columnChooser_list: DxListComponent;
    @ViewChild('searchInput', { static: false }) searchInput: DxTextBoxComponent;

    @Output() onValueSelected: EventEmitter<any> = new EventEmitter<any>();
    /** indique si le grid subbit de changement au niveau d'affichage (tri) */
    @Output() afterAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterEditing: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdatingServerError: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdatingServerSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() editingStart: EventEmitter<any> = new EventEmitter<any>();
    @Output() editingCanceled: EventEmitter<any> = new EventEmitter<any>();
    @Output() editCanceling: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdating: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveTextButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() validateSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteAdvancedFilter: EventEmitter<any> = new EventEmitter<any>();
    @Output() slidePanelClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditButtonClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() gridCancelButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    /** (optional input) nom du champ qui défini la clé primaire. Si non défini on prend id par défaut*/
    @Input() primaryKeyFieldName?: string = 'id';
    /** the breadcrumb to be shown we can hide it in options input */
    @Input() breadcrumb: (string | { name: string; breadcrumb: string; path: string; })[];
    /** Le titre de la page */
    @Input() pageTitle?: string;
    @Input() footerInfoTitle?: string;
    @Input() pageTitleAdds?: string;
    /** L'icon du titre de la page */
    @Input() pageTitleIcon?: string;
    /** Choisir le mode du datagrid */
    @Input() isModeCreation?: boolean;
    /** Le nom du table state NB: c'est obligatoire */
    @Input() tableStateName: string;
    @Input() loadDataType?: loadDataType = 'templateSide';
    @Input() actionselectedrow?: boolean = false;
    /** le service liés pour les données a afficher */
    @Input() service: IDaoBaseService;
    @Input() serviceOptions?: IDaoServiceOptions;
    /** si fournit affiche directement les données depuis dataSource */
    @Input() dataSource?: DataSource;
    @Input() slidePanelOptions?: SlidePanelModel;
    // get slide Panel options from parent components
    @Input() multiSlidePanelOptions: SlidePanelModel[] = null;
    @Input() showNavigationButtons?: boolean = false;
    @Input() headerHasParent?: boolean = false;
    @Input() returnButton: string;
    /**Height of datagrid of is grid for form */
    @Input() height?: string;
    @Input() set model(value: any) { this._model = value; }
    /** (optional input) pour personnaliser que quelque champ de notre dataGrid */
    @Input() customFields?: { [key: string]: DxDataGridColumn } = {};
    /** configuration des colonnes (overwrite any others) si on va envoyer toutes les champs du datagrid */
    @Input() dataGridColumns?: DxDataGridColumn[];
    /** options avancé du datagrid (exemple faire le traitement du service ou non) */
    @Input() options?: NextDxDataGridOptions = new NextDxDataGridOptions();
    /** (optional input) les champs que tu veux exclure de la liste des colonnes du dataGrid */
    @Input() set excludedFieldList(list: string[]) { this._excludedFieldList = list; }
    /** indique si le grid est dans un forum */
    @Input() isGridForForm?: boolean = false;
    /** indique si le grid est dans un popup */
    @Input() inPopup?: boolean = false;
    /** configuration de l'affichage du block gauche du header */
    @Input() headerInfo: HeaderInfo;
    @Input() footerInfo: FooterInfo = new FooterInfo();
    footerInfoForGridFooter: FooterInfo = new FooterInfo();
    @Input() dataGridInput: DataGridInput = new DataGridInput();
    @Input() slidePanelChildOptions: SlidePanelChildOptions = new SlidePanelChildOptions();
    /** liste des actions multiple */
    @Input() selectionActions: DataGridActionColumn = new DataGridActionColumn(); // data selectbox  selection multiple
    /** liste des actions for item  */
    @Input() itemActions: DataGridActionColumn = new DataGridActionColumn(); // data selectbox  selection multiple
    /** liste des option de grid  */
    @Input() additionelGridOptions: ({ id: number, value: number; name: string; icon: string; badge?: undefined; } | { id: number, value: number; name: string; icon: string; badge: string; })[];
    @Input() inSlidePanel?: boolean = false;
    /** Input and field name for grid for form */
    @Input() itemGridForForm: any = null;
    @Input() fieldNameGridForForm: string = null;
    // navigation
    @Input() navigationPreviousButtonDisabled?: boolean = true;
    @Input() navigationNextButtonDisabled?: boolean = false;
    @Input() numberOfPages: number;
    @Input() currentPage: number;
    @Input() pageSize: number;
    @Input() searchBarStyle: string = null;  // change searchbarStyle (exemple : réduire la longueur si le titre est un peu long)
    // navigation
    @Input() editActionVisible = false;
    @Input() deleteSearchElementVisible = true;
    @Input() filterButton = false;
    @Input() advancedFilterConfigArray?: [];
    @Input() modelAdvancedFilter?: any;
    @Input() modelAdvancedFilterCopy?: any;
    @Input() additionelTemplate: TemplateRef<any>;


    slidePanelOptionsForMultiple?: SlidePanelModel = null;
    dropDownGridItems: ({ id: number, value: number; name: string; icon: string; badge?: undefined; } | { id: number, value: number; name: string; icon: string; badge: string; })[];
    dropDownGridItem: ({ value: number; name: string; icon: string; badge?: undefined; } | { value: number; name: string; icon: string; badge: string; })[];
    rowClicked: any;
    rowClickedIndex: any;
    headerSearch = false;
    /**Number of rows of dxDataGrid */
    totalCount: number;
    /** Check if the grid for forum row are valid before any action*/
    columns: DxDataGridColumn[] = []; // pour stocker les colonnes a inserer ds la DataGrid
    editing: Editing;
    cacheEnabled = true;
    dataLoaded = false;
    export: {
        allowExportSelectedData?: boolean;
        // eslint-disable-next-line @typescript-eslint/ban-types
        customizeExcelCell?: Function;
        enabled?: boolean;
        excelFilterEnabled?: boolean;
        excelWrapTextEnabled?: boolean;
        fileName?: string;
        ignoreExcelErrors?: boolean;
        proxyUrl?: string;
        texts?: {
            exportAll?: string;
            exportSelectedRows?: string;
            exportTo?: string;
        };
    };
    user: User;
    dataGrid: any = DataGrid;
    selectedRows: any[] = [];
    confirmPopupUndo = false;
    multipleActionVisibility = false;
    gridForFormIsValid = false;
    lastPageIndex = 0;
    showSearchPanel = false;
    searchingWord: string;
    searchingWordChange: Subject<string> = new Subject<string>();
    lastLoadOptions: any;
    lastAdvancedFilter: any;
    lastLookUpFilter: any;
    datagridSearch: any;
    filter: Array<SingleColumnFilter> = [];
    filterHeader: Array<LookupColumnFilter> = [];
    advancedFilter: Array<SingleColumnFilter> = [];
    // lookUpFilter pour les filtres du type LookupColumnFilter
    lookUpFilter: Array<LookupColumnFilter> = [];
    /**attributs for controling actions on rows */
    isAdding = false;
    isEditing = false;
    actionButtonClicked = false;

    public _model: any;
    public templateDirectiveMap: Map<string, any>;
    public gridDataSource: DataSource;
    /** dataSource Final utilisé dans le datagrid*/
    public columnChooserColumn = [];
    public columnChooserSelected = [];
    public columnChooserPopupVisible = false;
    /**  true if no row selected */
    public nothingSelected = true;

    private _excludedFieldList: string[] = ['id', 'version'];
    private gridState: any;
    /** pour stocker les resultats des filter-header recu par web service */
    private objectValues: { [key: string]: any[] } = {};
    /** les differents type du colonne dataGrid */
    private readonly tableOfDxType: { [key: string]: dataType } = {
        undefined: 'object',
        function: 'object',
        number: 'number',
        symbol: 'object',
        string: 'string',
        boolean: 'boolean',
        bigint: 'number',
        date: 'date',
        array: 'object'
    };
    // subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        private _http: HttpClient,
        private _translate: TranslateService,
        private _authService: AuthService,
        private resolver: ComponentFactoryResolver,
        private router: Router,
        public localStorage: StorageService
    ) {
        this.subscriptions.push(
            this.searchingWordChange.pipe(
                // change timeout value
                debounceTime(1200),
                distinctUntilChanged())
                .subscribe(
                    searchText => {
                        this.dxGrid.instance.searchByText(searchText);
                    }
                )
        );
        this.tableStateLoad = this.tableStateLoad.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loadDataType === 'async' && this.dataSource) {
            this.subscriptions.push(
                this.loadDataGridState().pipe(take(1)).subscribe(
                    () => {
                        this.gridDataSource = this.dataSource;
                        this.dataLoaded = true;
                    }
                )
            );
        } else {
            // Ne pas rafraîchir les données au cas où c'est dans un pop-up ou bien quand le slide-panel est ouvert
            if (!this.inPopup && !this.slidePanelOptions?.isSlidePanelOpen) {
                this.subscriptions.push(
                    this.loadDataGridState().pipe(take(1)).subscribe(
                        () => {
                            this.getData(this.advancedFilter, this.lookUpFilter, changes);
                        }
                    )
                );
            }
        }

        this.subscriptions.push(
            this._authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    this.user = user;
                }
            )
        );
        if ((changes.customFields && !changes.customFields.firstChange) ||
            (changes.excludedFieldList && !changes.excludedFieldList.firstChange)) {
            this.columns = this.prepareColumnsForDataGrid();
        }
    }
    ngOnInit(): void {
        this.datagridSearch = {
            buttonsBefore:
                [
                    {
                        name: 'loop',
                        options: {
                            disabled: true,
                            stylingMode: 'text',
                            icon: 'search',
                            template: 'templateButtonBefore'
                        }
                    }
                ],
            buttonsAfter: [{
                name: 'clearSearch',
                options: {
                    focusStateEnabled: false,
                    hoverStateEnabled: false,
                    activeStateEnabled: false,
                    stylingMode: 'text',
                    icon: 'clear',
                    template: 'templateButtonAfter',
                    visible: false,
                    onClick: () => {
                        this.searchingWord = "";
                    }
                }
            }
            ]
        };
        if (this.filterButton) {
            this.datagridSearch.buttonsAfter.push(
                {
                    name: 'filterButton',
                    class: 'buttonsAfterFilter',
                    options: {
                        focusStateEnabled: false,
                        hoverStateEnabled: false,
                        activeStateEnabled: false,
                        stylingMode: 'text',
                        icon: 'filterSearchBar',
                        template: 'templateButtonAfter',
                        visible: true,
                        onClick: (): void => {
                            this.slidePanelOptions.isSlidePanelOpen = true;
                        }
                    }
                }
            )
        }
        if (this.footerInfoTitle != null) {
            this.footerInfo.elementName = this.footerInfoTitle;
        } else {
            this.footerInfo.elementName = this.pageTitle ? this._translate.instant('DataGridFooter.' + this.pageTitle) : 'éléments';
        }
        if (!this.itemActions.Actions.find(i => i.actionCode === 'deleteItem') && this.dataGridInput.allowDeleteItemActions) {
            this.itemActions.Actions.push(
                {
                    actionCode: 'deleteItem', value: 1,
                    name: this._translate.instant('data-grid.deleteSelectedRows'), icon: 'trash',
                });
        }
        if (!this.itemActions.Actions.find(i => i.actionCode === 'modifyItem') && this.dataGridInput.allowModifyItemActions) {
            this.itemActions.Actions.push(
                {
                    actionCode: 'modifyItem', value: 1,
                    name: this._translate.instant('menuAction.modify'), icon: 'modify',
                });
        }

        this.dropDownGridItems = [
            { id: 1, value: 1, name: this._translate.instant('data-grid.hint-columnchooser'), icon: 'column' },
            // { id: 2, value: 2, name: this._translate.instant('data-grid.hint-savestate'), icon: 'save' },
            // { id: 3, value: 3, name: this._translate.instant('data-grid.hint-reinitstate'), icon: 'undo' },
            { id: 4, value: 4, name: this._translate.instant('data-grid.hint-filterrow'), icon: 'filterInDropDown' },
            { id: 5, value: 5, name: this._translate.instant('data-grid.hint-exportall'), icon: 'export' }
        ];
        if (this.additionelGridOptions && this.additionelGridOptions.length > 0) {
            this.dropDownGridItems = this.dropDownGridItems.concat(this.additionelGridOptions);
        }

        this.buildDataGridOptions();
        if (typeof this.dataGridColumns !== 'undefined' && this.dataGridColumns.length > 0) {
            this.columns = this.dataGridColumns;
        } else {

            if (this._model) {
                this.columns = this.prepareColumnsForDataGrid();
            }
        }
        this.multipleActionVisibility = this.options.header.selectionMultile.enabled;
    }

    ngAfterViewInit(): void {
        if (this.options.rows.doubleClickEnabled) {
            this.subscriptions.push(
                this.dxGrid.onRowDblClick.subscribe(
                    event => {
                        this.rowDoubleClick.emit(event);
                    }
                )
            );
        }
    }

    ngAfterViewChecked(): void {
        if (this.isGridForForm) {
            if (document.getElementsByTagName('dx-data-grid')[0]) {
                const dxDataGridForForm: HTMLElement = document.getElementsByTagName('dx-data-grid')[0] as HTMLElement;
                if (this.height) {
                    dxDataGridForForm.style.height = this.height;
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }

    //#region Input Events les methodes ici sont appelé depuis le composant parent

    /**
    * called from parent to reload data
    */
    public refreshData(): void {
        // for popup we initially load datagrid state and data when it's opened for the first time.
        if (this.inPopup && !this.dataLoaded && (this.loadDataType === 'templateSide' || this.loadDataType === 'serverSide')) {
            this.subscriptions.push(
                this.loadDataGridState().pipe(take(1)).subscribe(
                    () => {
                        this.getData(this.advancedFilter, this.lookUpFilter);
                    }
                )
            );
        } else {
            // for template side we call get data with dataLoaded = false so it reload using get All function
            if (this.loadDataType === 'templateSide') {
                this.dataLoaded = false;
                this.getData(this.advancedFilter, this.lookUpFilter);
            } else {
                this.getData();
                this.dxGrid.instance.refresh();
            }
        }
    }

    /**
     * this function is called from the template directive to add a template to this component
     * @param key identifiant du template
     * @param templateDirective directive contenant le template
     */
    public registerTemplate = function (key: string, templateDirective: KeyedTemplateDirective): void {
        if (!this.templateDirectiveMap) {
            this.templateDirectiveMap = new Map<string, any>();
        }

        const keyedTemplate = {
            key: key,
            templateDirective,
        };
        this.templateDirectiveMap.set(key, keyedTemplate);
    };

    /**
     * change une option de column depuis le composant parent
     * @param caption nom de la colonne
     * @param option options
     * @param value nouvelle valeur
     */
    public changeColumnVisibilityByCaption(caption: string, option: string, value: any): void {
        this.orderAppliedFromGrid();
        this.dxGrid.instance.beginUpdate();
        const i = this.columnChooserColumn.findIndex(val => val === caption);
        this.dxGrid.instance.columnOption(i, option, value);
        this.dxGrid.instance.endUpdate();
    }

    //#endregion

    /**
     * build the dataGrid Initial Configuration ( défault then add join with customDxDataGridOptions)
     */
    private buildDataGridOptions(): void {

        // défault Config
        this.editing = {
            allowDeleting: false,
            mode: 'row',
            useIcons: true,
            texts: { confirmDeleteMessage: '' }
        };
        this.export = {
            enabled: true,
            allowExportSelectedData: false,
            excelFilterEnabled: true,
            fileName: this.pageTitle + new Date().getTime()
        };

        // add custom config
        if (this.options && this.options.customDxDataGridOptions) {
            if (this.options.customDxDataGridOptions.editing) {
                this.editing.allowDeleting = this.options.customDxDataGridOptions.editing.allowUpdating;
                this.editing = { ...this.editing, ...this.options.customDxDataGridOptions.editing };
            }

            if (this.options.customDxDataGridOptions.export) {
                this.export = { ...this.export, ...this.options.customDxDataGridOptions.export };
            }
        }

        // activer le cache que pour le templateSide
        if (this.loadDataType === 'serverSide') {
            this.cacheEnabled = false;
        } else {
            this.cacheEnabled = true;
        }
    }
    // TODO amélioré la récupération pour l'utiliser partout
    private existInParameterSelected(parameterCode: number): boolean {
        return this.user.parametersSelected.some((selectedParam: any) => parameterCode === selectedParam.parameter.code);
    }

    prepareColumnsForDataGrid(): DxDataGridColumn[] {
        const columnsForDataGrid: DxDataGridColumn[] = [];
        let fields: string[] = Object.getOwnPropertyNames(this._model);
        let modelValidator = {};
        if (this._model.validator !== undefined && this.loadDataType !== 'serverSide') {
            modelValidator = this._model.validator();
        }
        // ajout des ligne supplementaire (définit dans customFields et pas dans model)
        let additional: string[] = Object.getOwnPropertyNames(this.customFields);
        additional = additional.filter(c => {
            const some = fields.some(f => c === f);
            if (!some && (!this.customFields[c] || !this.customFields[c].dataType || !this.customFields[c].caption)) {
                throw new Error('you must set datatype or caption for Field:' + c);
            }
            return !some || !this.customFields[c];
        });
        // supprimer les champs a exclure du datagrid
        fields.push(...additional);
        fields = fields.filter(el => {
            return this._excludedFieldList.indexOf(el) === -1 && (!this.customFields[el] || (this.customFields[el] && !this.customFields[el].excluded));
        });



        fields.forEach((field: string) => {
            // default config values :
            let column: DxDataGridColumn = {
                allowHeaderFiltering: false,
                allowEditing: field !== this.primaryKeyFieldName && field !== 'version' && field !== 'dateCreated' &&
                    field !== 'dateUpdated',
                fixed: false
            };
            // end default config values

            // ajout custom config values
            if (typeof this.customFields !== 'undefined' && typeof this.customFields[field] !== 'undefined') {
                column = { ...column, ...this.customFields[field] };
                // if (this.loadDataType === 'templateSide') {
                //     column.dataField = field;
                //     column.dataType = this.tableOfDxType[Tools.getType(this._model[field])];
                // }
                if (!column.dataField) {
                    column.dataField = field;
                }
                if (column.cellTemplate || column.dataFieldExpr) {
                    column.allowFiltering = (typeof column.allowFiltering !== 'undefined') ? column.allowFiltering : false;
                }
                if (column.dataFieldExpr) {
                    column.dataField = column.dataField + '.' + column.dataFieldExpr;
                    column.allowHeaderFiltering = (typeof this.customFields[field].allowHeaderFiltering !== 'undefined') ? this.customFields[field].allowHeaderFiltering : (typeof column.url !== 'undefined');
                    if (column.allowHeaderFiltering) {
                        column.dataType = 'string';
                    }
                }
                if (!column.dataType) {
                    column.dataType = this.tableOfDxType[Tools.getType(this._model[column.dataField])];
                }
                if (column.lookup) {
                    column.allowHeaderFiltering = (typeof this.customFields[field].allowHeaderFiltering !== 'undefined') ? this.customFields[field].allowHeaderFiltering : true;
                    if(!column.allowFiltering){
                        column.allowFiltering = false;
                    }
                }
                if (typeof column.editorOptions !== 'undefined' && typeof column.editorOptions.editorName !== 'undefined' && column.editorOptions.editorName === 'dxColorBox') {
                    column.cellTemplate = column.cellTemplate ? column.cellTemplate : 'colorCellTemplate';
                }
                if (typeof column.editorOptions !== 'undefined' && typeof column.editorOptions.editorName !== 'undefined' && column.editorOptions.editorName === 'dataGridPicker') {
                    column['label'] = { text: this._translate.instant('model.' + column.dataField), visible: column.visible }; // ajouter la translation du champ
                    column.cellTemplate = column.cellTemplate ? column.cellTemplate : 'dataGridPickerCellTemplate';
                    column.editCellTemplate = column.editCellTemplate ? column.editCellTemplate : 'editDataGridPickerCellTemplate';
                }
            } else {
                column.name = field;
                column.dataField = field;
                column.dataType = this.tableOfDxType[Tools.getType(this._model[field])];
            }

            if (this.dataGridInput.editableFieldOnlyThis.length > 0) {
                if (this.dataGridInput.editableFieldOnlyThis.includes(column.dataField)) {
                    column.allowEditing = true;
                } else {
                    column.allowEditing = false;
                }
            }

            if (!column.caption) {
                column.caption = this._translate.instant('model.' + field);
            }
            if (column.dataType === 'boolean') {
                column.cellTemplate = column.cellTemplate ? column.cellTemplate : 'switchCellTemplate';
                column.editCellTemplate = column.editCellTemplate ? column.editCellTemplate : 'editswitchCellTemplate';
            }
            if (column.dataType === 'date' || column.dataType === 'datetime' || column.dataType === 'time') {
                if (!column.editorOptions) {
                    column.editorOptions = { dateSerializationFormat: Tools.getDateSerializationFormat(column.dataType) };
                } else if (!column.editorOptions.dateSerializationFormat) {
                    column.editorOptions.dateSerializationFormat = Tools.getDateSerializationFormat(column.dataType);
                }
                if(!column.alignment) {
                    column.alignment = 'center';
                }
            }
            // ajoute le css editable au tr
            if ((this.editing.allowUpdating || this.editing.allowAdding) && column.allowEditing !== false) {
                column.cssClass = 'editable';
                column.validationRules = concatDefinedArrray(modelValidator[column.dataField], column.validationRules);
                if (column.validationRules && column.validationRules.length) {
                    column.validationRules.map(val => {
                        if (val.useTranslator) {
                            val.message = this._translate.instant(val.message, { field: column.caption, min: (val as any).min, max: (val as any).max });
                        }
                        return val;
                    });
                }
            }
            columnsForDataGrid.push(column);

        });


        /** adding edit, delete and custom command button button */
        if (this.editing && this.editing.allowUpdating && this.dataGridInput.showButtonsEditing || this.editing.allowAdding || this.options.columns.command) {
            const commandColumn: DxDataGridColumn = {};
            commandColumn.fixed = true;
            commandColumn.fixedPosition = 'right';
            commandColumn.type = 'buttons';
            commandColumn.buttons = [{ name: 'edit', template: 'buttonEdit' }, { name: 'save', template: 'buttonSave' }, { name: 'cancel', template: 'buttonCancel' }];
            commandColumn.width = 100;
            commandColumn.minWidth = 100;
            commandColumn.allowSorting = false;
            commandColumn.allowFiltering = false;
            commandColumn.allowEditing = false;
            commandColumn.allowHeaderFiltering = false;
            commandColumn.encodeHtml = false;
            if (this.options.columns.command) {
                commandColumn.buttons.push(...this.options.columns.command.buttons);
            }
            columnsForDataGrid.push(commandColumn);


        }

        const actionColumn: DxDataGridColumn = {};
        actionColumn.type = 'buttons';
        actionColumn.cellTemplate = 'actionButtonTemplate';
        actionColumn.allowEditing = false;
        actionColumn.fixed = true;
        actionColumn.allowSorting = false;
        actionColumn.allowFiltering = true;
        actionColumn.allowEditing = false;
        actionColumn.allowHeaderFiltering = false;
        actionColumn.encodeHtml = false;
        actionColumn.fixedPosition = 'right';
        actionColumn.width = 50;
        actionColumn.caption = 'Actions';
        // if (!this.inPopup && this.dataGridInput.showActions) {
        //     columnsForDataGrid.push(actionColumn);
        // }
        if (this.dataGridInput.showActions) {
            columnsForDataGrid.push(actionColumn);
        }



        return columnsForDataGrid;

    }

    private loadDataGridState(): Observable<any> {
        return new Observable(observer => {
            if (this.service.getGridState) {
                this.subscriptions.push(
                    this.service.getGridState(TableState.convertIdStringTableToInt(this.tableStateName), this.getGlobalParams).subscribe(
                        (gridState: JSON) => {
                            this.gridState = gridState || null;
                            // Indispensable sinon devExtreme ne fait pas le job
                            this.tableStateLoad = this.tableStateLoad.bind(this);
                            if (!this.gridState && !this.options.noGridState) {
                                notify(this._translate.instant('GridState.NoGridState'), 'warning', notifySuccessDelay);

                            }
                            observer.next(this.gridState);
                            observer.complete();
                        },
                        () => {
                            observer.next(null);
                            observer.complete();
                        }
                    )
                );
            } else {
                observer.next(null);
                observer.complete();
            }
        });
    }

    getData(advancedFilter?: Array<SingleColumnFilter>, advancedLookupFilter?: Array<LookupColumnFilter>, changes?: SimpleChanges) {
        if (this.footerInfoTitle != null) {
            this.footerInfo.elementName = this.footerInfoTitle;
        } else {
            this.footerInfo.elementName = this.pageTitle ? this._translate.instant('DataGridFooter.' + this.pageTitle) : 'éléments';
        }
        this.advancedFilter = advancedFilter;
        this.lookUpFilter = advancedLookupFilter;
        if (this.dataLoaded && ((changes?.serviceOptions?.previousValue === undefined) || ((changes?.serviceOptions?.previousValue !== undefined) &&
            (changes?.serviceOptions?.currentValue.methodsParams.global === changes?.serviceOptions?.previousValue.methodsParams.global)))) {
            return;
        }
        if (this.loadDataType === 'serverSide') {
            const store = new CustomStore({
                load: (loadOptions: any) : any=> {
                    /**
                     * Cette partie corrige le bogue dans la grille d'opérations commerciales
                     * dans certains cas où loadOptions renvoient deux propriétés (filtre et sélection) undefend
                    */
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    const tmpLoadOptions = loadOptions as Object;
                    // eslint-disable-next-line no-prototype-builtins
                    if (loadOptions && tmpLoadOptions.hasOwnProperty('filter') && tmpLoadOptions.hasOwnProperty('select')) {
                        if (loadOptions.filter == undefined && loadOptions.select == undefined) {
                            return of({
                                data: this.service.list || [],
                                totalCount: this.dxGrid.instance.totalCount()
                            }).toPromise();
                        }
                    }

                    let beginning = 0;
                    if (loadOptions.skip) {
                        beginning = loadOptions.skip;
                    }
                    this.filter = [];
                    this.filterHeader = [];
                    this.lookUpFilter = [];
                    // filtre globale ( 1ér objet de la liste)
                    const filterGlobal: Array<GlobalColumnFilter> = [];
                    if (this.advancedFilter && this.advancedFilter.length > 0) {
                        this.filter.push(...this.advancedFilter);
                    }
                    if (advancedLookupFilter && advancedLookupFilter.length > 0) {
                        this.filterHeader.push(...advancedLookupFilter);
                        this.lookUpFilter.push(...advancedLookupFilter);
                    }

                    let numberOfTuples = 'all';
                    let trisField = null;
                    let trisStrategie = 'asc';
                    if (!loadOptions.isLoadingAll) {
                        numberOfTuples = loadOptions.take || defaultDataGridOptions.paging.pageSize;
                    }
                    if (loadOptions.sort) {
                        trisField = loadOptions.sort[0].selector;
                        if (loadOptions.sort[0].desc) {
                            trisStrategie = 'desc';
                        }
                    }
                    // gestion du filtre global
                    if (loadOptions.filter) {
                        let dxGridFilterGlobal: Array<any>;
                        if (loadOptions.filter.length > 3 && Array.isArray(loadOptions.filter) && loadOptions.filter[1] === 'or') {
                            dxGridFilterGlobal = loadOptions.filter;
                        } else {
                            // la recherche global est toujours le dernier element du tableau avec un or comme 1er operateur
                            dxGridFilterGlobal = loadOptions.filter.find(x => x.length > 3 && Array.isArray(x) && x[1] === 'or');
                        }
                        if (typeof dxGridFilterGlobal !== 'undefined') {
                            const visibleColumnsOfStringType = this.dxGrid.instance.getVisibleColumns();
                            const dxGridFilterGlobalWithouOrExpression = dxGridFilterGlobal.filter(item => visibleColumnsOfStringType.some(x => x.dataField === item[0]) && !!item.filterValue); 
                            for (let index = 0; index < dxGridFilterGlobalWithouOrExpression.length; index++) {
                                const col = dxGridFilterGlobalWithouOrExpression[index];
                                filterGlobal.push({
                                    'dataField': col[0],
                                    'selectedFilterOperation': col[1],
                                    'filterValue': col.filterValue
                                });
                            }
                        }
                    }
                    if (typeof loadOptions.dataField === 'undefined') {
                        for (let i = 0; i < this.dxGrid.instance.columnCount(); i++) {
                            const column = this.dxGrid.instance.columnOption(i);

                            if (column.filterValue !== null && typeof column.filterValue !== 'undefined') {
                                this.filter.push({
                                    'dataField': column.dataField,
                                    'selectedFilterOperation': column.selectedFilterOperation,
                                    'filterValue': column.selectedFilterOperation !== 'between' ? column.filterValue : null,
                                    'filterValueRange': column.selectedFilterOperation === 'between' ? column.filterValue : null,
                                    'origin': 'dataGrid',
                                    'type': column.dataType === 'date' ? 'date' : null,
                                    'caption': column.caption,
                                    'showFilterBadge': column.showFilterBadge !== undefined ? column.showFilterBadge : true
                                });
                            }

                            // filter header
                            if (column.filterValues !== null && typeof column.filterValues !== 'undefined') {
                                const filterValues: any = [];
                                column.filterValues.forEach(item => {
                                    filterValues.push(item);
                                });
                                this.filterHeader = this.lookUpFilter;
                                this.filterHeader.push({
                                    'dataField': column.dataField,
                                    'filterValues': filterValues,
                                    'filterValue': filterValues,
                                    'caption': column.caption,
                                    'enumType': column.lookup != null && column.lookup != undefined ? column.lookup.valueMap : null,
                                    'dataType': column.dataType === 'object' && column.lookup.valueMap != null && column.lookup.valueMap != undefined ? 'enum' : null,
                                    'origin': 'dataGrid'
                                });
                            }
                        }
                    } else if (loadOptions.dataField.includes('.')) { // Alimentation des données des header filter
                        const loadOptionsDataField = loadOptions.dataField;
                        const dataFieldName = loadOptionsDataField.split('.')[0];
                        let dataFieldSubName = null;
                        let dataFieldValue = null;
                        //condition si dataField a 3 niveaux (customerToInvoice.postalAddress.name1)
                        if (loadOptionsDataField.split('.').length === 3) {
                            dataFieldSubName = loadOptionsDataField.split('.')[1];
                            dataFieldValue = loadOptionsDataField.split('.')[2];
                        } else {
                            dataFieldValue = loadOptionsDataField.split('.')[1];
                        }
                        const dataSource = [];
                        if (!this.objectValues[dataFieldName]) {
                            let url: string;
                            if (typeof this.customFields !== 'undefined') {
                                let element;
                                if (dataFieldSubName != null) {
                                    element = this.customFields[dataFieldName + '.' + dataFieldSubName];
                                } else {
                                    element = this.customFields[dataFieldName];
                                }
                                url = element.url;
                            } else {
                                throw new Error('Error Custom fields input Structure');
                            }
                            return this._http.get(url).toPromise()
                                .then((response: any) => {
                                    this.objectValues[dataFieldName] = response[0].sort((a, b) => a.text.localeCompare(b.text));
                                    this.objectValues[dataFieldName].forEach(element => {
                                        const object = {}, subObject = {}, key = dataFieldName, keyOfKey = dataFieldValue, value = element.text;
                                        if (dataFieldSubName != null) {
                                            const subKey = dataFieldSubName;
                                            const subObject1 = {};
                                            subObject1[keyOfKey] = value;
                                            subObject[subKey] = subObject1;
                                            object[key] = subObject;
                                            dataSource.push(object);
                                        } else {
                                            subObject[keyOfKey] = value;
                                            object[key] = subObject;
                                            dataSource.push(object);
                                        }
                                    });
                                    const object = {}, subObject = {}, key = dataFieldName, keyOfKey = dataFieldValue, value = null;
                                    subObject[keyOfKey] = value;
                                    object[key] = subObject;
                                    dataSource.push(object);
                                    return dataSource;
                                })
                                .catch((error: any) => {
                                    console.log('Error Data Loading : ', error.error);
                                });
                        } else {
                            this.objectValues[dataFieldName].forEach(element => {
                                // gestion du filtre de recherche à l'intérieur du popup headeFilter
                                // on ajoute systematiquement l'element dans le popup s'il n'y pas de recherche à l'interieur
                                let addElement = typeof loadOptions.filter === 'undefined' || (loadOptions.filter.length > 0 && !Array.isArray(loadOptions.filter[0]));
                                if (!addElement && loadOptions.filter.length > 0 && Array.isArray(loadOptions.filter[0])) {
                                    // Si aucun filtre sur le grid, le filtre du popup est directement dans le 1er element du tableau
                                    // alors que s'il y en a deja 1 ils sont dans un tableau à l'interieur du 1er element
                                    if (loadOptions.filter[0].length === 1) {
                                        // pour être sur que ce n'est pas un filtre du grid lui même on controle que le 1er elt est bien une fonction
                                        if (typeof loadOptions.filter[0][0][0] === 'function') {
                                            addElement = element.text.toUpperCase().includes(loadOptions.filter[0][0][2].toUpperCase());
                                        } else {
                                            addElement = true;
                                        }
                                    } else {
                                        // pour être sur que ce n'est pas un filtre du grid lui même on controle que le 1er elt est bien une fonction
                                        if (typeof loadOptions.filter[0][0] === 'function') {
                                            addElement = element.text.toUpperCase().includes(loadOptions.filter[0][2].toUpperCase());
                                        } else {
                                            addElement = true;
                                        }
                                    }
                                }
                                // ajout au datasource du popup header filter de l'élément s'il n'y a pas de recherche dans le popup
                                // ou si la recherche l'a trouvée
                                if (addElement) {
                                    const object = {}, subObject = {};
                                    const key = dataFieldName, keyOfKey = dataFieldValue, value = element.text;
                                    if (dataFieldSubName != null) {
                                        const subKey = dataFieldSubName;
                                        const subObject1 = {};
                                        subObject1[keyOfKey] = value;
                                        subObject[subKey] = subObject1;
                                        object[key] = subObject;
                                        dataSource.push(object);
                                    } else {
                                        subObject[keyOfKey] = value;
                                        object[key] = subObject;
                                        dataSource.push(object);
                                    }
                                }
                            });
                            const object = {}, subObject = {}, key = dataFieldName, keyOfKey = dataFieldValue, value = null;
                            // subObject['designation'] = 'Non renseigné';
                            // subObject['value'] = value
                            subObject[keyOfKey] = value;
                            object[key] = subObject;
                            dataSource.push(object);
                            return dataSource;
                        }


                    }


                    /**
                     * Return si loadOptions est le même que l'appelle précédent
                     * => Cette solution résou le problème d'appel multiple pour le même résultat
                     * Il faut ordonner les objets avant de les comparer :
                     * => Existe un cas où les objets ne sont pas ordonnés
                     * */
                    if ((changes?.serviceOptions?.previousValue === undefined) || ((changes?.serviceOptions?.previousValue != undefined) &&
                        (changes?.serviceOptions?.currentValue.methodsParams.global === changes?.serviceOptions?.previousValue.methodsParams.global)))
                        if (JSON.stringify(orderObject(this.lastLoadOptions)) === JSON.stringify(orderObject(loadOptions)))
                            if (JSON.stringify(orderObject(this.lastAdvancedFilter)) === JSON.stringify(orderObject(this.advancedFilter)))
                                if (JSON.stringify(orderObject(this.lastLookUpFilter)) === JSON.stringify(orderObject(this.lookUpFilter))) {

                                    return of({
                                        data: this.service.list || [],
                                        totalCount: this.totalCount
                                    }).toPromise();
                                }
                    this.lastLoadOptions = loadOptions;
                    this.lastAdvancedFilter = this.advancedFilter;
                    this.lastLookUpFilter = this.lookUpFilter;

                    const params: DataGridFiltersParam = new DataGridFiltersParam();
                    params.globalSearch = filterGlobal;
                    params.lookupSearch = this.filterHeader;
                    params.singleColumnSearch = this.filter;
                    if (this.dataGridInput.dataGridFiltersParam) {
                        if (this.dataGridInput.dataGridFiltersParam.lookupSearch && this.dataGridInput.dataGridFiltersParam.lookupSearch.length > 0) {
                            this.dataGridInput.dataGridFiltersParam.lookupSearch.forEach(element => {
                                params.lookupSearch.push(element);
                            });
                        }

                        if (this.dataGridInput.dataGridFiltersParam.singleColumnSearch && this.dataGridInput.dataGridFiltersParam.singleColumnSearch.length > 0) {
                            this.dataGridInput.dataGridFiltersParam.singleColumnSearch.forEach(element => {
                                params.singleColumnSearch.push(element);
                            });
                        }
                    }
                    this.service.trisField = trisField;
                    this.service.trisStrategie = trisStrategie;
                    this.service.beginning = beginning;
                    this.service.numberOfTuples = numberOfTuples;
                    this.service.params = params;



                    // empecher le rechargement des données apres une édition de ligne
                    if (!loadOptions.take && this.options.customDxDataGridOptions.editing.allowUpdating && this.options.customDxDataGridOptions.editing.refreshMode === 'repaint') {
                        return of({
                            data: this.service.list || [],
                            // summary: response.itemsSummary,
                            totalCount: this.dxGrid.instance.totalCount()
                        }).toPromise();
                    }
                    return this.service.getByFilter(...this.getAllParams)
                        .toPromise()
                        .then((data: PaginationSet<any, any>) => {
                            this.service.list = data.items;
                            if (data.redisKeyPrint) {
                                this.service.redisKeyPrint = data.redisKeyPrint;
                            }

                            return {

                                data: data.items || [],
                                // summary: response.itemsSummary,
                                totalCount: data.totalFiltered
                            };
                        })
                        .catch(() => {
                            // throw new Error('Data Loading Error');
                        });
                },
                remove: (key: any) => {
                    return this.service.delete(key[this.primaryKeyFieldName], ...this.getGlobalParams).toPromise()
                        .then(() => {
                            notify('Suppression effectuée', 'success', notifySuccessDelay);
                            return null;
                        })
                        .catch(() => {
                            // throw new Error('Error Delete');
                        });
                },
                update: (key, values) => {
                    return of({ ...key, ...values }).toPromise();
                }
            });
            this.gridDataSource = new DataSource(store);
            this.dataLoaded = true;
        } else if (this.loadDataType === 'templateSide') {
            if (this.service.clearListTablesCache) {
                this.service.clearListTablesCache(...this.getGlobalParams);
            }
            this.subscriptions.push(
                this.service.getAll(...this.getAllParams).pipe(take(1)).subscribe(
                    (result: any[]) => {
                        this.dataLoaded = true;
                        this.gridDataSource = new DataSource(result);
                    }
                )
            );
        }
    }

    /**
     * récupére le template du column en question
     * @param tplName : nom du template
    */
    getTemplate(tplName: string): string {
        return this.templateDirectiveMap && this.templateDirectiveMap.has(tplName) ? this.templateDirectiveMap.get(tplName).templateDirective.template : null;
    }

    tableStateSave(event): void {
        this.columnChooserSelected = event;
        this.applyColumnChooser();
        this.gridState = this.dxGrid.instance.state();
        if (this.gridState && this.gridState.selectedRowKeys != null) {
            this.gridState.selectedRowKeys = null;
        }
        this.saveGrid();
    }

    /**
     * Reset de l'état de la table
    */
    public tableStateReset(): void {
        if (this.service?.resetGridState) {
            this.subscriptions.push(
                this.service.resetGridState(TableState.convertIdStringTableToInt(this.tableStateName)).subscribe(
                    (gridState: JSON) => {
                        this.gridState = gridState || null;
                        // Indispensable sinon devExtreme ne fait pas le job
                        this.tableStateLoad = this.tableStateLoad.bind(this);
                        this.columnChooserPopupVisible = false;
                        if (!this.gridState) {
                            notify(this._translate.instant('GridState.NoGridState'), 'warning', notifySuccessDelay);

                        }
                        this.saveGrid();
                        notify('Tableau réinitialisé avec succès', 'success', notifySuccessDelay);
                    },
                    (error: any) => {
                        console.log('error resetGridState', error);
                    }
                )
            );
        } else {
            notify(this._translate.instant('GridState.ServiceNotFound'), 'error', notifySuccessDelay);
        }
    }

    // save the grid after reset or change column
    private saveGrid() {
        const tableState = new TableState();
        tableState.grid = TableState.convertIdStringTableToInt(this.tableStateName);
        tableState.state = JSON.stringify(this.gridState);
        if (this.service && this.service.tableStateSave) {
            this.service.tableStateSave(tableState, this.getGlobalParams);
        } else {
            notify(this._translate.instant('GridState.ServiceNotFound'), 'error', notifySuccessDelay);
        }
    }

    public cancelTableStateReset(): void {
        this.confirmPopupUndo = false;
        this.columnChooserPopupVisible = false;

    }

    public tableStateLoad(): unknown {
        return this.gridState;
    }

    onAddbuttonClicked(): void {
        if (this.slidePanelOptions?.isMultiple && this.multiSlidePanelOptions && this.multiSlidePanelOptions.length > 0) {
            this.slidePanelOptionsForMultiple = this.multiSlidePanelOptions[0];
        }
        if (this.slidePanelOptions && this.slidePanelOptions.quickAdding) {
            const slidePanel: HTMLElement = document.getElementsByClassName('slide-panel')[0] as HTMLElement;
            slidePanel.style.top = this.slidePanelOptions.css.top;
            slidePanel.style.height = this.slidePanelOptions.css.height;

            this.slidePanelOptions.isSlidePanelOpen = true;
        } else {
            if (this.isGridForForm) {
                this.dxGrid.instance.addRow();
                this.isAdding = true;
                this.isEditing = false;
            } else if (this.editing.allowAdding) {
                this.dxGrid.instance.addRow();
            } else if (this.tableStateName === 'customersOpGridTable') {
                let haveExerciseDate = true;
                const parameterSelected: any[] = JSON.parse(this.localStorage.getItem(variablesKeys.USER)).societies[0].parametersSelected;
                const parametersName: string[] = [];
                parameterSelected.forEach(element => {
                    parametersName.push(element.parameter?.name);

                    if ((element.parameter?.name === 'exerciseStartDate' || element.parameter?.name === 'exerciseEndDate') && element.value === null) {
                        notify(this._translate.instant('exerciceDateError'), 'error', globalsParameter.notifyErrorDelay);
                        haveExerciseDate = false;
                    }
                });
                if (parametersName.includes('exerciseStartDate') && parametersName.includes('exerciseStartDate') && haveExerciseDate) {
                    haveExerciseDate = true;
                } else {
                    notify(this._translate.instant('exerciceDateError'), 'error', globalsParameter.notifyErrorDelay);
                    haveExerciseDate = false;
                }
                if (haveExerciseDate) {
                    this.navigateToCreatePage();
                }
            } else {
                this.navigateToCreatePage();
            }
        }
    }

    onFilterButtonClicked(): void {
        if (this.slidePanelOptions.isMultiple && this.multiSlidePanelOptions && this.multiSlidePanelOptions.length > 0) {
            this.slidePanelOptionsForMultiple = this.multiSlidePanelOptions[1];
        }
        if (this.slidePanelOptions) {
            const slidePanel: HTMLElement = document.getElementsByClassName('slide-panel')[0] as HTMLElement;
            slidePanel.style.top = this.slidePanelOptions.css.top;
            slidePanel.style.height = this.slidePanelOptions.css.height;

        }
        this.slidePanelOptions.isSlidePanelOpen = true;


    }

    /**
     * A function that is executed when user click or tap on create button
     */
    public navigateToCreatePage(): void {
        this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
    }

    /**
     * A function that is executed when a row is clicked or tapped.
     * @param event
     */
    public onRowClick(event: any): void {
        if (this.isAdding) {
            this.rowClickedIndex = event.row.rowIndex;
            return null;
        }
        if (!event.data && event.rowType === 'filter' && event.column.caption === 'Actions') {
            this.headerSearch = !this.headerSearch;
        } else {
            if (event.column.type === 'buttons') {
                this.rowClicked = event.data;
                this.rowClickedIndex = event.row.rowIndex;
                if (this.actionButtonClicked) {
                    this.onEditButtonClicked.emit(this.rowClicked.id);
                }
                if (!this.itemActions.isMultiple && this.itemActions.Actions[0]) {
                    this.itemActions.Actions[0].callback(this.rowClicked);
                }
                return null;
            }
            if (this.options.header.selectionMultile.enabled && !this.options.allowNavigateToDetail) {
                const indexRow = this.selectedRows.findIndex(x => x === event.key);
                if (indexRow === -1) {
                    this.selectedRows.push(event.key);
                } else {
                    this.selectedRows.splice(indexRow, 1);
                }
            }
            if ((event.data && !event.data.items && event.rowType === 'data' && !this.editing.allowUpdating) && this.options.rows.clickEnabled && !this.inPopup && (event.column.type !== 'selection') && this.options.allowNavigateToDetail) {
                this.service.navigateToDetail(event.data[this.primaryKeyFieldName], ...this.getNavigateToDetailPageParams);
            }
        }
    }

    /**
     * A function that is executed after a row is created.
     * @param event
     */
    public onRowPrepared(event: any): void {
        if (event.rowType === 'data' && this.editing.allowUpdating) {
            (event.rowElement as Element).classList.add('editable');
        }
    }

    /**
     * A function that is executed before a row is removed from the data source.
     * @param event
     */
    public onRowRemoving(event: any): void {
        const d = new Deferred<any>();
        event.cancel = d.promise;
        if (this.loadDataType !== 'serverSide') {
            const dialogDeleteLine = confirmDialog(this._translate.instant('popup.confirmDelete.title'), this._translate.instant('popup.confirmDelete.validateButton'),
                this._translate.instant('popup.confirmDelete.cancelButton'), this._translate.instant('popup.confirmDelete.body'));
            dialogDeleteLine.show()
                .then((dialogResult: CustomConfirmDialogResult) => {
                    switch (dialogResult) {
                        case CustomConfirmDialogResult.YES:
                            this.subscriptions.push(
                                this.service.delete(event.data[this.primaryKeyFieldName], ...this.getGlobalParams)
                                    .pipe(take(1))
                                    .subscribe(
                                        () => {
                                            this.afterDelete.emit(event.data.id);
                                            notify('Suppression effectuée', 'success', notifySuccessDelay);
                                            d.resolve();
                                        },
                                        (error: any) => {
                                            console.log('reach error', error);
                                            d.resolve(true);
                                        }
                                    )
                            );
                            break;
                        default:
                            d.resolve(true);
                            break;
                    }
                });
        } else {
            const dialogDeleteLine = confirmDialog(this._translate.instant('popup.confirmDelete.title'), this._translate.instant('popup.confirmDelete.validateButton'),
                this._translate.instant('popup.confirmDelete.cancelButton'), this._translate.instant('popup.confirmDelete.body'));
            dialogDeleteLine.show()
                .then((dialogResult: CustomConfirmDialogResult) => {
                    switch (dialogResult) {
                        case CustomConfirmDialogResult.YES:
                            d.resolve();
                            break;
                        default:
                            d.resolve(true);
                            break;
                    }
                });
        }
    }

    /**
     * A function that is executed after cells in a row are validated against validation rules.
    * @param event
     */
    public onRowValidating(event: any): void {
        this.gridForFormIsValid = event.isValid;
    }

    /**
     * A function that is executed before a new row is added to the widget.
     * set initial values
     * @param event
     * component: DataGrid
     *  data: Object
     */
    public onInitNewRow(event: any): void {
        let initValues = {};
        if (this._model.initValues !== undefined) {
            initValues = this._model.initValues();
        }
        const fields: string[] = Object.getOwnPropertyNames(this._model);
        fields.forEach((field: string) => {

            // supprimer les champs a exclure du formulaire
            if (!this._excludedFieldList.some((defaultExcludedField: string) => defaultExcludedField === field)) {
                if (this.customFields[field]
                    && this.customFields[field].editorOptions
                    && this.customFields[field].editorOptions.value != null
                    && this.customFields[field].editorOptions.value !== undefined) {
                    // initialiser les valeur par défaut à partir du this.customFields[field].editorOptions.value
                    event.data[field] = this.customFields[field].editorOptions.value;
                } else if (initValues[field]) {
                    event.data[field] = initValues[field];
                }
            }
        });
    }

    /**
     * A function that is executed before a new row is inserted into the data source.
     * @param event
     */
    public onRowInserting(event: any): void {
        if (event && event.data && this.loadDataType !== 'serverSide') {
            if (this.options.synchronize.add === true) {
                const d = new Deferred<any>();
                event.cancel = d.promise;
                this.subscriptions.push(
                    this.service.post(event.data, ...this.getPostParams)
                        .pipe(take(1))
                        .subscribe(
                            (response: NGCResults<any>) => {
                                if (response) {
                                    // resolve cancel to false ( success)
                                    d.resolve(false);
                                    // applique le retour serveur dans la ligne
                                    Object.assign(event.data, response.ngcResults[0].data);
                                    notify('Creation effectuée', 'success', notifySuccessDelay);
                                    // action after add new row
                                    this.afterAdd.emit(event.data);
                                } else {
                                    d.resolve(true);
                                }
                            },
                            () => {
                                // set cancel to true
                                d.resolve(true);
                            }
                        )
                );
            } else if (this.isAdding && !this.isModeCreation && this.gridForFormIsValid) {
                const d = new Deferred<any>();
                event.cancel = d.promise;
                this.subscriptions.push(
                    this.service.post(event.data, ...this.getPostParams)
                        .pipe(take(1))
                        .subscribe(
                            (response: NGCResults<any>) => {
                                if (response) {
                                    // resolve cancel to false ( success)
                                    d.resolve(false);
                                    // applique le retour serveur dans la ligne
                                    Object.assign(event.data, response.ngcResults[0].data);
                                    notify('Creation effectuée', 'success', notifySuccessDelay);
                                    // action after add new row
                                    this.afterAdd.emit(event.data);
                                } else {
                                    d.resolve(true);
                                }
                            },
                            () => {
                                // set cancel to true
                                d.resolve(true);
                            }
                        )
                );
            }
        }
    }

    /**
     * A function that is executed before a row is updated in the data source.
     * @param event
     */
    public onRowUpdating(event: any): void {
        this.rowUpdating.emit(event);
        if (event && event.newData && this.loadDataType !== 'serverSide') {
            if (this.options.synchronize.update === true) {
                const newVal = event.key;
                for (const [newKey, newValue] of Object.entries(event.newData)) {
                    if (typeof newValue !== 'object') {
                        newVal[newKey.toString()] = newValue;
                    } else {
                        for (const [objectKey, objectValue] of Object.entries(event.newData[newKey])){
                            newVal[newKey.toString()][objectKey.toString()] = objectValue;
                        }
                    }
                }
                const d = new Deferred<any>();
                event.cancel = d.promise;

                this.subscriptions.push(
                    this.service.put(newVal[this.primaryKeyFieldName], newVal, ...this.getPutParams)
                        .pipe(take(1))
                        .subscribe(
                            (response: NGCResults<any>) => {
                                if (response) {
                                    // resolve cancel to false ( success)
                                    d.resolve(false);
                                    // applique le retour serveur dans la ligne
                                    Object.assign(event.newData, response.ngcResults[0].data);
                                    notify('Modification effectuée', 'success', notifySuccessDelay);
                                    this.rowUpdatingServerSuccess.emit(response);
                                } else {
                                    d.resolve(true);
                                }
                            },
                            (error: any) => {
                                this.rowUpdatingServerError.emit({ event, error });
                                d.resolve(true);
                            }
                        )
                );
            }
        }
        this.afterEditing.emit(event);
    }

    onSaveTextButton(): void {
        this.saveTextButton.emit(true)
    }

    get getAllParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.getAll);
        }
        return [];
    }

    get getPutParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.put);
        }
        return [];
    }

    get getPostParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.post);
        }
        return [];
    }

    get getNavigateToDetailPageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToDetail);
        }
        return [];
    }

    get getNavigateToCreatePageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToCreatePage);
        }
        return [];
    }

    get getGlobalParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams && this.serviceOptions.methodsParams.global) {
            return this.serviceOptions.methodsParams.global;
        }
        return [];
    }

    /*************************************** méthdes pour columnChooser */
    // méthode appelé l'ors du click sur le bouton columnChooser
    // elle s'occupe de charger tous les columns(si jamais on as changer les ordres directement sur le tableaux) et d'afficher le popup
    public getColumnChooser(): void {
        this.columnChooserSelected = [];
        for (let i = 0; i < this.dxGrid.instance.getVisibleColumns().length; i++) {
            if (this.dxGrid.instance.getVisibleColumns()[i].caption !== undefined) {
                this.columnChooserSelected.push(this.dxGrid.instance.getVisibleColumns()[i].caption);
            }
        }
        this.orderAppliedFromGrid();
        this.columnChooserPopupVisible = true;
    }

    private orderAppliedFromGrid() {
        /// this.dxGrid.instance.refresh();
        this.columnChooserColumn = [];
        for (let i = 0; i < this.dxGrid.instance.columnCount(); i++) {
            if (this.dxGrid.instance.columnOption(i).caption !== undefined && !this.columnChooserSelected.includes(this.dxGrid.instance.columnOption(i).caption)) {
                this.columnChooserColumn.push(this.dxGrid.instance.columnOption(i).caption);
            }
        }
        this.columnChooserColumn = this.columnChooserSelected.concat(this.columnChooserColumn.sort());

    }

    // méthode appelé l'or du clique sur le bouton apply
    applyColumnChooser(): void {
        this.columnChooserPopupVisible = false;
        this.dxGrid.instance.beginUpdate();
        for (let i = 0; i < this.dxGrid.instance.columnCount(); i++) {
            if (
                this.columnChooserSelected.indexOf(this.dxGrid.instance.columnOption(i).caption) > -1
            ) {
                this.dxGrid.instance.columnOption(i, 'visible', true);
                // eslint-disable-next-line eqeqeq
                if (this.dxGrid.instance.columnOption(i, 'visibleIndex') != this.columnChooserSelected.indexOf(this.dxGrid.instance.columnOption(i).caption)) {
                    this.dxGrid.instance.columnOption(i, 'visibleIndex', this.columnChooserSelected.indexOf(this.dxGrid.instance.columnOption(i).caption));
                }
            } else {
                if (this.dxGrid.instance.columnOption(i).type === 'buttons') {
                    this.dxGrid.instance.columnOption(i, 'visible', true);
                } else {
                    this.dxGrid.instance.columnOption(i, 'visible', false);
                }
            }
        }
        this.dxGrid.instance.endUpdate();
    }

    // méthode appelé l'or de l'événement onItemReordered
    itemReordered(e): void {
        this.columnChooserSelected = [];
        for (let i = 0; i < this.columnChooser_list.items.length; i++) {
            if (this.columnChooser_list.selectedItems.indexOf(this.columnChooser_list.items[i]) > -1) {
                this.columnChooserSelected.push(this.columnChooser_list.items[i]);
            }
        }
    }

    // pour fermer la popup du ColumnChooser
    cancelColumnChooser(): void {
        this.columnChooserPopupVisible = false;
    }

    // méthode utiliser pour désactiver le réordering en mode d'affichage plusieurs colomne
    resizePopup(e): void {
        if ((this.columnChooser_list['element']['nativeElement']['clientWidth']) > 400) {
            this.columnChooser_list.itemDragging.allowReordering = false;
        } else {
            this.columnChooser_list.itemDragging.allowReordering = true;
        }
    }
    /*************************************** /méthdes pour columnChooser */

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.manageHeightGrid();
    }

    /**
     * l'element dx-data-grid
     * @returns Jquery element
    */
    get dxGridElement(): any {
        return $(this.dxGrid.instance.element());
    }

    public manageHeightGrid(): void {
        const tableHeight = this.dxGridElement.find('.dx-datagrid-rowsview .dx-datagrid-content').outerHeight() +
            this.dxGridElement.find('.dx-datagrid-headers').outerHeight() +
            (this.headerSearch ? 35 : 0) +
            (this.dxGridElement.find('.dx-datagrid-pager').outerHeight() ? this.dxGridElement.find('.dx-datagrid-pager').outerHeight() : 0) -
            this.dxGridElement.find('.dx-row.dx-freespace-row').height() + 5;

        // on peut avoir une hauteur calculée de 0 (+ les 5 de const) si la grille n'est pas visible, dans l cas du popup fermé par exemple
        // dans ce cas on ne touche pas à la hauteur
        if (tableHeight >= 5) {
            if (this.inPopup) {
                const popup = this.dxGridElement.closest('.dx-popup-content');
                const screenHeight = popup.height() - 36;
                const paddingPopup = popup.outerHeight() - popup.height();
                const othersHeight = (popup.find('#divBtnsFooter').outerHeight(true) ? popup.find('#divBtnsFooter').outerHeight(true) : 0);
                this.dxGridElement.css('height', screenHeight - othersHeight - paddingPopup + 'px');
            } else {
                const screenHeight = window.innerHeight;
                const afterHeader = document.querySelector('.after-header');
                const pageHeaderContainer = document.querySelector('.page-header-container');
                const dataGridFooter = document.querySelector('app-data-grid-footer div.left-bloc');
                const appHeader = document.querySelector('app-header div.header-wrapper');
                let afterHeaderHeight = 0;
                if (afterHeader) {
                    afterHeaderHeight = afterHeader.clientHeight + 30;
                }
                let pageHeaderContainerHeight = 0;
                if (pageHeaderContainer) {
                    pageHeaderContainerHeight = pageHeaderContainer.clientHeight;
                }
                let dataGridFooterHeight = 0;
                if (dataGridFooter) {
                    dataGridFooterHeight = dataGridFooter.clientHeight;
                }
                let appHeaderHeight = 0;
                if (appHeader) {
                    appHeaderHeight = appHeader.clientHeight;
                }
                const totalHeight = pageHeaderContainerHeight + afterHeaderHeight + appHeaderHeight + dataGridFooterHeight + 40;
                this.dxGridElement.height(screenHeight - totalHeight + 'px');
                this.dxGridElement.find('.dx-row.dx-freespace-row').css('display', 'none');
            }
        }
    }

    onContentReady(e): void {
        this.manageHeightGrid();
        this.numberOfPages = this.dxGrid.instance.pageCount();
        this.currentPage = this.dxGrid.instance.pageIndex();
        this.pageSize = this.dxGrid.instance.pageSize() == 0 || undefined ? 10 : this.dxGrid.instance.pageSize();
        this.totalCount = this.dxGrid.instance.totalCount();

        if (this.footerInfo) {
            this.footerInfo.totalElements = this.totalCount;
        }
        this.footerInfoForGridFooter = new FooterInfo();
        Object.assign(this.footerInfoForGridFooter, this.footerInfo);

    }

    onEditorPreparing(event: any): void {
        if (event.parentType === 'dataRow' && event.editorOptions.editorName) {
            event.editorName = event.editorOptions.editorName;
            if (event.editorOptions.editorName === 'dxSwitch') {
                event.editorOptions.switchedOnText = this._translate.instant('yes');
                event.editorOptions.switchedOffText = this._translate.instant('no');
            }

        }
        // pour desactiver le champ en mode edition selon le retour d'un callback
        if (event.allowEditing && event.allowEditing instanceof Function && event.parentType === 'dataRow') {
            event.editorOptions.disabled = !event.allowEditing({ dataField: event.dataField, inserted: event.row.isNewRow, editorPreparing: event });
            event.readonly = !event.allowEditing({ dataField: event.dataField, inserted: event.row.isNewRow, editorPreparing: event });
        }
        if (event.parentType === 'dataRow' && event.editorOptions.disabledCallback && event.editorOptions.disabledCallback instanceof Function) {
            event.editorOptions.disabled = event.editorOptions.disabledCallback(event);
        }
    }

    onOptionChanged(e): void {
        if (this.dxGrid.instance.getSelectedRowsData()[0]) {
            this.nothingSelected = false;
        } else {
            this.nothingSelected = true;
        }
        this.lastPageIndex = (e.component.pageIndex());
    }

    toggleSelectionAll(event: any): void {
        event.value ? this.dxGrid.instance.selectAll() : this.dxGrid.instance.deselectAll();
    }

    onMultipleActionItemClicked(event: any): void {
        if (this.slidePanelOptions) {
            const slidePanel: HTMLElement = document.getElementsByClassName('slide-panel')[0] as HTMLElement;
            slidePanel.style.top = this.slidePanelOptions.css.top;
            slidePanel.style.height = this.slidePanelOptions.css.height;

            setTimeout(() => {
                const slidePanelDrawerPanel: HTMLElement = document.getElementsByClassName('slide-panel')[0].getElementsByClassName('dx-drawer-panel-content')[0] as HTMLElement;
                slidePanelDrawerPanel.style.width = this.slidePanelOptions.css.width;
            });
            this.slidePanelOptions.isSlidePanelOpen = true;
        }
        if (event.itemData.actionCode === 'delete') {
            const dialogDeleteLines = confirmDialog(this._translate.instant('popup.confirmDelete.title'), this._translate.instant('popup.confirmDeleteMultiple.validateButton'),
                this._translate.instant('popup.confirmDeleteMultiple.cancelButton'), this._translate.instant('popup.confirmDeleteMultiple.body'));
            dialogDeleteLines.show()
                .then((dialogResult: CustomConfirmDialogResult) => {
                    switch (dialogResult) {
                        case CustomConfirmDialogResult.YES:
                            this.dxGrid.instance.beginCustomLoading('');
                            this.subscriptions.push(
                                this.service.deleteAll(this.reduceSelectedRows()).subscribe(
                                    (data: any) => {
                                        this.dxGrid.instance.refresh().then(() => {
                                            this.dxGrid.instance.endCustomLoading();
                                            notify(data.value, 'success', notifySuccessDelay);
                                        })
                                            .catch(() => {
                                                throw new Error('Error On Refresh datagrid');
                                            });
                                    }
                                )
                            );
                            break;
                        default:
                            break;
                    }
                });
        }
        (event.itemData as DataGridAction).callback(this.selectedRows);
    }

    onDropDownGridItemsClicked(event: any): void {
        if (event.itemData.id === 1) {
            this.getColumnChooser();
        } else if (event.itemData.id === 2) {
            //this.tableStateSave();
        } else if (event.itemData.id === 3) {
            this.tableStateReset();
        } else if (event.itemData.id === 4) {
            this.headerSearch = !this.headerSearch;
            this.manageHeightGrid();
        } else if (event.itemData.id === 5) {
            this.onExporting(this.dxGrid.instance);
        } else {
            (event.itemData as DataGridAction).callback(null);
        }
    }

    onExporting(e): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e,
            worksheet: worksheet,
            selectedRowsOnly: false,
            customizeCell: function (options) {
                const excelCell = options.excelCell;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            }
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: BlobPart) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
        });
        e.cancel = true;
    }

    onDropDownGridItemClicked(event: any): void {
        if (event.itemData.actionCode === 'deleteItem') {
            this.dxGrid.instance.deleteRow(this.rowClickedIndex);
        } else if (event.itemData.actionCode === 'modifyItem') {
            this.dxGrid.instance.editRow(this.rowClickedIndex);
        }
        else {
            (event.itemData as DataGridAction).callback(this.rowClicked);
        }
    }

    gridForFormCancelRow(): void {
        setTimeout(() => {
            this.dxGrid.instance.cancelEditData();
        });
        this.gridCancelButton.emit(true);
    }

    gridForFormSavingData(): void {
        this.dxGrid.instance.saveEditData();
    }

    gridForFormEditingRow(): void {
        setTimeout(() => {
            this.dxGrid.instance.editRow(this.rowClickedIndex);

            this.isAdding = false;
            this.isEditing = true;
        });
    }

    gridForFormDeleteRow(): void {
        setTimeout(() => {
            this.dxGrid.instance.deleteRow(this.rowClickedIndex);
        });
    }

    gridForFormSaveRow(): void {
        this.dxGrid.instance.saveEditData();

        setTimeout(() => {
            if (!this.isModeCreation && this.gridForFormIsValid) {
                const payload = this.rowClicked;
                if (this.isEditing) {
                    this.subscriptions.push(
                        this.service.put(payload.id, payload)
                            .pipe(take(1))
                            .subscribe(
                                (response: NGCResults<any>) => {
                                    if (response) {
                                        notify('Modification effectuée', 'success', notifySuccessDelay);
                                    }

                                }
                            )
                    );
                }
            } else if (this.isModeCreation && this.gridForFormIsValid) {
                let tempData;

                this.dxGrid.instance.getDataSource().store().load().then(x => {
                    tempData = x
                });

                if (this.itemGridForForm[this.fieldNameGridForForm]) {
                    this.itemGridForForm[this.fieldNameGridForForm] = tempData;
                } else {
                    this.itemGridForForm[this.fieldNameGridForForm] = [];
                    this.itemGridForForm[this.fieldNameGridForForm] = tempData;
                }
            }
            if (this.gridForFormIsValid) {
                this.isAdding = false;
                this.isEditing = false;
            }
        });
    }

    private reduceSelectedRows(): string[] {
        return this.selectedRows.reduce((accumulateur, valeurCourante, index, self) => {
            accumulateur[index] = valeurCourante[this.primaryKeyFieldName];
            return accumulateur;
        }, []);
    }

    onSelectionChanged(event): void {
        this.onValueSelected.emit(event.selectedRowsData);
        if (this.selectedRows.length > 1) {
            this.dxGrid.instance.beginUpdate();
            this.dxGrid.instance.columnOption(this.dxGrid.instance.columnCount() - 1, 'visible', false);
            this.dxGrid.instance.endUpdate();
        } else {
            this.dxGrid.instance.beginUpdate();
            this.dxGrid.instance.columnOption(this.dxGrid.instance.columnCount() - 1, 'visible', true);
            this.dxGrid.instance.endUpdate();
        }


    }

    onEditingStart(event: unknown): void {
        this.editingStart.emit(event);
    }

    onEditCanceled(event: unknown): void {
        this.editingCanceled.emit(event);
    }

    onEditCanceling(event: unknown): void {
        this.editCanceling.emit(event);
    }

    onRowUpdated(event: unknown): void {
        this.rowUpdated.emit(event);
    }

    clearSelection(): void {
        this.dxGrid.instance.clearSelection();
    }

    // headerLeftBloc
    get headerLeftBlocTemplate(): unknown {
        return (this.templateDirectiveMap && this.templateDirectiveMap.has('headerLeftBloc')) ? this.templateDirectiveMap.get('headerLeftBloc').templateDirective : null;
    }

    // headerLeftBloc
    get slidePanelTemplateContent(): void {
        this.slidePanelOptions.templateDirectiveMap = this.templateDirectiveMap.get('slidePanelTemplateContent');
        return null;
    }

    handleDataGridPickerChanged(evt: any, data: any): void {
        data.setValue(evt.value);
    }

    handleDataGridPickerClear(evt: any, data: any): void {
        data.setValue(null);

    }

    onSearchingWordValueChanged(event): void {
        this.totalCount = this.dxGrid.instance.totalCount();
        if (event === null || event === undefined || event.value == undefined || event.value == '') {
            this.searchingWordChange.next("");
            this.datagridSearch.buttonsAfter.forEach(
                btn => {
                    if (btn.name == "clearSearch") {
                        btn.options.visible = false;
                    }
                    if (btn.name == "filterButton") {
                        btn.options.visible = true;
                    }
                }
            );
        }
        else {
            this.searchingWord = event.value;
            this.searchingWordChange.next(event.value);
            this.datagridSearch.buttonsAfter.forEach(
                btn => {
                    if (btn.name == "clearSearch") {
                        btn.options.visible = true;
                    }
                    if (btn.name == "filterButton") {
                        btn.options.visible = false;
                    }
                }
            );
        }
    }

    toggleSearchPanel(): void {
        $('#search').animate({ width: 'toggle' }, () => this.searchInput.instance.focus());
        this.searchingWord = '';
    }

    changeMultipleVisibility(): void {
        this.multipleActionVisibility = !this.multipleActionVisibility;

    }

    changeMode(): void {
        if (this.options.header.toggle.value === true) {
            this.serviceOptions.methodsParams.getAll = this.options.header.toggle.secondGetAllParams;
        } else {
            this.serviceOptions.methodsParams.getAll = this.options.header.toggle.firstGetAllParams;
        }
        this.options.header.toggle.value = !this.options.header.toggle.value;
        this.dataLoaded = false;
        this.getData(this.advancedFilter, this.lookUpFilter);
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.visible = false;
    }


    handleSwitchChanged(evt: any, data: any): void {
        data.setValue(evt);
    }

    onValidateSuccess(val: any): void {
        this.slidePanelOptions.isSlidePanelOpen = false;
        this.validateSuccess.emit(val);
    }

    onSlidePanelClose(): void {
        this.slidePanelOptions.isSlidePanelOpen = false;
    }

    deleteSearchElement(event: any, filterValue?: string): void {
        this.dxGrid.instance.beginUpdate();
        this.dataLoaded = false;
        // suppresion du advancedfilter

        const idxToDel = this.advancedFilter.findIndex(x => x.dataField === event.dataField);
        const idxToDelLookupFilter = this.lookUpFilter.findIndex(x => x.dataField === event.dataField);
        if (idxToDel > -1) {
            this.advancedFilter.splice(idxToDel, 1);
        } else if (idxToDelLookupFilter > -1) {
            if (event.filterValues.length <= 1) {
                this.lookUpFilter.splice(idxToDelLookupFilter, 1);
                this.dxGrid.instance.columnOption(event.dataField, 'filterValues', null)
            } else if (event.filterValues.length > 1) {
                const idxToDelFilterValueInLookupFilter = this.lookUpFilter[idxToDelLookupFilter].filterValues.findIndex(x => x === filterValue)
                this.lookUpFilter[idxToDelLookupFilter].filterValues.splice(idxToDelFilterValueInLookupFilter, 1);
                const filterValuesArray = this.lookUpFilter[idxToDelLookupFilter].filterValues;
                this.dxGrid.instance.columnOption(event.dataField, 'filterValues', filterValuesArray)
            }
        }
        if (event.origin === 'advance') {
            this.dxGrid.instance.columnOption(event.dataField, 'allowFiltering', true);
            this.deleteAdvancedFilter.emit(event.dataField);
            this.getData(this.advancedFilter, this.lookUpFilter);
        } else {
            this.dxGrid.instance.columnOption(event.dataField, 'filterValue', null);
            this.dxGrid.instance.columnOption(event.dataField, 'filterValueRange', null);
        }
        /** réinitialiser les "last filters" */
        this.lastAdvancedFilter = null;
        this.lastLookUpFilter = null;

        this.dxGrid.instance.endUpdate();
    }

    convertEnumToKeyValue(filterValue, enumType, translateSource): unknown {
        const data = convertEnumToKeyValue(enumType, translateSource, this._translate);
        return data.find(x => x.value == filterValue).designation;
    }

    public cancelResetTable(): void {
        this.columnChooserPopupVisible = false;
    }

    //********** data grid pagination */

    onChangePageSize(event): void {
        this.dxGrid.instance.pageSize(event);
        this.currentPage = this.dxGrid.instance.pageIndex();
        this.numberOfPages = this.dxGrid.instance.pageCount();
        if (this.dxGrid.instance.pageCount() === 1) {
            this.currentPage = 0;
        }
        this.dxGrid.instance.pageIndex() === 0 ? this.navigationPreviousButtonDisabled = true : this.navigationPreviousButtonDisabled = false;
        this.dxGrid.instance.pageIndex() === this.dxGrid.instance.pageCount() || this.dxGrid.instance.pageIndex() + 1 === this.dxGrid.instance.pageCount()
            ? this.navigationNextButtonDisabled = true : this.navigationNextButtonDisabled = false;
        this.dxGrid.instance.refresh();
    }

    onNavigateToPreviousPage(): void {
        this.dxGrid.instance.pageIndex(this.dxGrid.instance.pageIndex() - 1);
        this.currentPage = this.dxGrid.instance.pageIndex();
        this.dxGrid.instance.pageIndex() === 0 ? this.navigationPreviousButtonDisabled = true : this.navigationPreviousButtonDisabled = false;
        this.dxGrid.instance.pageIndex() + 1 === this.dxGrid.instance.pageCount() ? this.navigationNextButtonDisabled = true : this.navigationNextButtonDisabled = false;
    }

    onNavigateToNextPage(): void {
        this.dxGrid.instance.pageIndex(this.dxGrid.instance.pageIndex() + 1);
        this.currentPage = this.dxGrid.instance.pageIndex();
        this.dxGrid.instance.pageIndex() === 0 ? this.navigationPreviousButtonDisabled = true : this.navigationPreviousButtonDisabled = false;
        this.dxGrid.instance.pageIndex() + 1 === this.dxGrid.instance.pageCount() ? this.navigationNextButtonDisabled = true : this.navigationNextButtonDisabled = false;
    }

    onNavigateToPage(pageIndex): void {
        this.dxGrid.instance.pageIndex(pageIndex);
        this.currentPage = this.dxGrid.instance.pageIndex();
        pageIndex === 0 ? this.navigationPreviousButtonDisabled = true : this.navigationPreviousButtonDisabled = false;
        pageIndex + 1 === this.dxGrid.instance.pageCount() ? this.navigationNextButtonDisabled = true : this.navigationNextButtonDisabled = false;
    }


    //********** end data grid pagination */

    someActionsIsDisabled(row: any): boolean {
        let disabled = true;
        for (let index = 0; index < this.itemActions.Actions.length; index++) {
            const action = this.itemActions.Actions[index];
            if (!action.visibleCallback || action.visibleCallback(row)) {
                disabled = false;
                break;
            }
        }
        return disabled;
    }

    ReturnButtonPath() {
        return this.router.navigate([this.returnButton]);
    }

    editButtonClicked(): void {
        this.actionButtonClicked = true;

    }

}
