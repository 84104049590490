import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '@app/core/services/auth/auth.service';
import { AppContextService } from '../services';
import { canActivateAndCandLoad } from './helper/authGuard';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router, private appContext: AppContextService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return canActivateAndCandLoad(this.auth, this.appContext, state.url);
    }
}
