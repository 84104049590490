import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderInfo, HeaderMode, SubtitleItem } from './models';
import { ScreenService } from '@app/core/services';
import { isDefined } from '@app/utils';

@Component({
    selector: 'app-left-header-bloc',
    templateUrl: './left-header-bloc.component.html',
    styleUrls: ['./left-header-bloc.component.scss']
})
export class LeftHeaderBlocComponent implements OnChanges, OnDestroy {

    /** configuration de l'affichage du block gauche du header */
    @Input() headerInfo: HeaderInfo;
    /** objet actif du formulaire ouvert */
    @Input() item: SubtitleItem[];
    /** true si le form est du type parent et child */
    public multiHeaderBlocMode = false;
    private currentItem: any;
    HeaderMode = HeaderMode;
    currentItemSubscription: Subscription;
    href: string;
    hrefChild : string;
    hrefPhone : string;
    constructor(private screenService: ScreenService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.headerInfo.parent && this.headerInfo.parent.parentService) {
            this.currentItemSubscription = this.headerInfo.parent.parentService.currentItem.subscribe(currentValue => {
                this.currentItem = currentValue;
            });
            this.multiHeaderBlocMode = true;
        }
        // sauvegarder le currentItem quand ont ouvre le composant qui contien le parent uniquement.
        if (this.item && changes.item && this.headerInfo.service) {
            if(this.headerInfo.service.currentItem) {
                this.headerInfo.service.currentItem.next(this.item);
            }
        }

        // TODO FB : demander à Amine à quoi ça sert car ca empeche le fonctionnement du header avec parent
        /*if (changes.item && changes.item.currentValue) {
        this.currentItem = changes.item.currentValue;
        }*/

    }

    ngOnDestroy(): void {
        if (this.currentItemSubscription && !this.currentItemSubscription.closed) {
            this.currentItemSubscription.unsubscribe();
        }
    }

    get parentItem(): SubtitleItem[] {
        if (this.headerInfo.parent && this.headerInfo.parent.parentService) {
            return this.currentItem;
        } else {
            return this.item;
        }
    }

    get pageTitle(): string {
        if ('string' === typeof this.headerInfo.pageTitle) {
            return this.headerInfo.pageTitle;
        } else if ('function' === typeof this.headerInfo.pageTitle) {
            return this.headerInfo.pageTitle(this.parentItem);
        } else {
            throw new Error('what did you put as headerInfo.pageTitle ??');
        }
    }
    getIcon(icon, item): any {
        let result = null;
        if ('string' === typeof icon) {
            result = icon;
        } else if ('function' === typeof icon) {
            result = icon(item);
        }
        return result;
    }
    get childItem(): SubtitleItem[] {
        return this.item;
    }

    getSubtitleItemText(item: any , subtitleItem: SubtitleItem): string {
        if(!item){
            return '';
        }
        if (subtitleItem.attribute !== undefined && subtitleItem.attribute !== null) {
            if (subtitleItem.attribute === 'email') {
                if (item.email != null) {
                    this.href = 'mailto:'.concat(this.getNestedObject(item, subtitleItem.attribute));
                }
            }
            else if (subtitleItem.attribute === 'phone') {
                if (item.phone != null) {
                    this.hrefPhone = 'tel:'.concat(this.getNestedObject(item, subtitleItem.attribute));
                }
            }

            const value = this.getNestedObject(item, subtitleItem.attribute);
            return (value === undefined || value === null || value == '') ? '' : value;
        }
        else {
            return subtitleItem.textCallback(item);
        }
    }

    getNestedObject(parent: any, path: string): string {
        if (!parent || !path) {
            return '';
        }
        const keyArr = path.split('.');
        let tmp = parent;
        for (let index = 0; index < keyArr.length; index++) {
            const key = keyArr[index];
            if (!isDefined(tmp[key])) {
                return '';
            }
            tmp = tmp[key];
        }
        return tmp || '';
    }


    filterItemsOfType(list: any[]) {
        if (window.innerWidth > 1800) {
            return list.sort((a, b) => a.priority - b.priority);
        } else if (window.innerWidth > 1500) {
            return list.sort((a, b) => a.priority - b.priority).filter((a) => a.priority < 4);
        } else if (window.innerWidth > 1000) {
            return list.sort((a, b) => a.priority - b.priority).filter((a) => a.priority < 3);
        } else {
            return list.sort((a, b) => a.priority - b.priority).filter((a) => a.priority < 2);
        }
    }

}
