import { Component, OnInit, Input, EventEmitter, Output, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { UploadDocument } from '@app/shared/model/uploadDocument';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { TranslateService } from '@ngx-translate/core';
import { ScreenService } from '@app/core/services';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
    @Input() item: any;
    @Input() field: any;
    @Input() disabled: boolean = false;


    @ViewChild(DxFileUploaderComponent, { static: false }) fileUploader: DxFileUploaderComponent;
    /** emet false si la nouvelle valeur est differente a la précedente */
    @Output() canDeactivate = new EventEmitter<boolean>();
    /** emet la nouvelle valeur  aprés une edition*/
    @Output() valueChange = new EventEmitter<any>();

    imagesWithoutDefault: any[] = [];
    /** image par défaut ( si this.field.editorOptions.defaultImageFieldName != null) */
    defaultImage: any;
    /** état du popup d'affichage d'image */
    showImagePopup: boolean = false;
    /** l'image a afficher */
    popupImage: any;
    /** état du popup selection d'image from url */
    popupImageFromExternalUrl: boolean = false;
    /** url temporaire lors de choix d'image from url */
    tempImageUrl: string;
    defaultVisible: boolean;

    constructor(public transalteService: TranslateService
        , private screenService: ScreenService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setImagesList();
    }

    /**
     * déclanché lors de chaque event
     * @param event changement fait
     */
    public onFileChange(event: any): void {
        if (this.field.editorOptions && this.field.editorOptions.multiple) {
            const startIndex = event.previousValue.length;
            if (!this.item[this.field.dataField]) {
                this.item[this.field.dataField] = [];
            }
            // when we add case
            if (startIndex < event.value.length) {
                for (let index = startIndex; index < event.value.length; index++) {
                    const file = event.value[index];
                    const reader = new FileReader();
                    this.item[this.field.dataField][this.item[this.field.dataField].length] = {};
                    // we get last index here because on load will execute after certain amount of time.
                    const lastIndex = this.item[this.field.dataField].length - 1;
                    // first image is the default one when adding new
                    if (this.field.editorOptions.defaultImageFieldName) {
                        this.item[this.field.dataField][lastIndex][this.field.editorOptions.defaultImageFieldName] = lastIndex === 0;
                    }
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.item[this.field.dataField][lastIndex][this.field.editorOptions.multiple + '_upload'] = {
                            name: file.name,
                            value: reader.result.toString(),
                            mimeType: file.type
                        };
                        this.setImagesList();
                    };
                }
            } 
        } else {
            const file = event.value[0];
            const reader = new FileReader();
            if (event.value[0] && event.value[0] !== undefined) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.item[this.field.dataField + '_upload'] = {
                        name: file.name,
                        value: reader.result.toString(),
                        mimeType: file.type
                    };
                    // if the change is due to user interaction
                    if (this.item[this.field.dataField + '_upload'] && this.item[this.field.dataField + '_upload'].value) {
                        this.canDeactivate.emit(false);
                    }
                };
            } else {
                this.item[this.field.dataField + '_upload'] = new UploadDocument();
            }
        }

        this.valueChange.emit({ event: event, dataField: this.field.dataField });
    }

    /**
     * change l'état du champ editorOptions.defaultImageFieldName
     * @param event changement fait
     * @param index index de l'image a traité
     */
    toggledDefaultImage(event: any, index?: number): void {
        if ((index == null || index == undefined) && this.imagesWithoutDefault.length != 0) {
            index = 0;
        }
        if (event) {
            let aux: any;
            if (this.defaultImage) {
                this.defaultImage[this.field.editorOptions.defaultImageFieldName] = false;
                aux = this.defaultImage;
            }

            this.defaultImage = this.imagesWithoutDefault[index];
            this.defaultImage[this.field.editorOptions.defaultImageFieldName] = true;
            if (aux) {
                this.imagesWithoutDefault[index] = aux;
            } else {
                this.imagesWithoutDefault.splice(index, 1);
            }
            if (this.defaultImageFromOriginalArray) {
                this.defaultImageFromOriginalArray[this.field.editorOptions.defaultImageFieldName] = false;
            }
            if (this.findEquivalentIndexInOriginalArray(this.defaultImage) > -1) {
                this.item[this.field.dataField][this.findEquivalentIndexInOriginalArray(this.defaultImage)][this.field.editorOptions.defaultImageFieldName] = true;
            }
            this.valueChange.emit({ event: { event, value: this.item[this.field.dataField] }, dataField: this.field.dataField });
        }
    }

    /**
     * zoom l'image a l'aide d'un popup
     * @param iamge l'image a voir
     */
    view(image?: any): void {
        this.popupImage = image;
        this.showImagePopup = true;
    }

    /**
 * close popup of input url without saving it
 */
    closeImageViewer(): void {
        this.showImagePopup = false;
        this.popupImage = null;
    }


    /**
     * add the current image url in popup to the list of image
     */
    addImageFromUrl(): void {
        const image: any = {};
        image[this.field.editorOptions.externalUrlFieldName] = this.tempImageUrl;

        if (this.item[this.field.dataField].length === 0) {
            image[this.field.editorOptions.defaultImageFieldName] = true;
        } else {
            image[this.field.editorOptions.defaultImageFieldName] = false;
        }
        this.item[this.field.dataField].push(image);

        if (image[this.field.editorOptions.defaultImageFieldName]) {
            this.defaultImage = image;
        } else {
            this.imagesWithoutDefault.push(image);
        }
        this.tempImageUrl = null;
        this.popupImageFromExternalUrl = false;
        this.valueChange.emit({ event: { event, value: this.item[this.field.dataField] }, dataField: this.field.dataField });
    }

    /**
     * close popup of input url without saving it
     */
    cancelAddingFromUrl(): void {
        this.popupImageFromExternalUrl = false;
        this.tempImageUrl = null;
    }

    get defaultImageFromOriginalArray(): any {
        return this.item[this.field.dataField].find((v: any) => v[this.field.editorOptions.defaultImageFieldName]);
    }

    /**
     * used in html
     */
    public get defaultImageIndexFromOriginalArray(): number {
        return this.item[this.field.dataField].findIndex((v: any) => v[this.field.editorOptions.defaultImageFieldName]);
    }

    /**
     * find the images in the original array
     * @param image like ArticleImage
     */
    private findEquivalentIndexInOriginalArray(image: any): number {
        return this.item[this.field.dataField].findIndex(v => v === image);
    }

    /**
     * convert to list without default image
     */
    private setImagesList(): void {
        if (this.item[this.field.dataField] && this.field.editorOptions.multiple) {
            if (this.field.editorOptions.defaultImageFieldName) {
                this.defaultImage = this.item[this.field.dataField].find((v: any) => v[this.field.editorOptions.defaultImageFieldName]);
                this.imagesWithoutDefault = this.item[this.field.dataField].filter((v: any) => !v[this.field.editorOptions.defaultImageFieldName]);
            } else {
                this.imagesWithoutDefault = this.item[this.field.dataField];
            }
        }
    }

    /**
     * remove item from both array
     * @param index index of image in the second array (temporary array)
     */
    private deleteFileFromBothArray(index: number, event?: any): void {
        let idx: number = this.findEquivalentIndexInOriginalArray(this.imagesWithoutDefault[index]);
        if (idx === -1) {
            idx = this.item[this.field.dataField].findIndex(x => x.isDefault === true);
            if (!this.item[this.field.dataField][idx].cdnImage_upload) {
                this.item[this.field.dataField][idx].cdnImage_upload = new UploadDocument();
                this.defaultImage.cdnImage_upload = new UploadDocument();
                this.item[this.field.dataField][idx].cdnImage_upload.value = 'delete';
                this.defaultImage.cdnImage_upload.value = 'delete';
                this.toggledDefaultImage(event);
            }

        } else {
            if (!this.item[this.field.dataField][idx][this.field.editorOptions.multiple + '_upload']) {
                this.item[this.field.dataField][idx][this.field.editorOptions.multiple + '_upload'] = new UploadDocument();
                this.imagesWithoutDefault[index][this.field.editorOptions.multiple + '_upload'] = new UploadDocument();
                this.item[this.field.dataField][idx][this.field.editorOptions.multiple + '_upload'].value = 'delete';
                this.imagesWithoutDefault[index][this.field.editorOptions.multiple + '_upload'].value = 'delete';
            }
        }
    }

    /**
     * met le fichier depuis la base à l'état a supprimé
     * @param index index de l'image a traité
     */
    public deleteFile(index?: number, event?: any): void {
        // todo cas de suppression d'une image qu'on vient de sélectionner
        this.canDeactivate.emit(false);
        if (this.field.editorOptions && this.field.editorOptions.multiple) {
            this.deleteFileFromBothArray(index, event);
        } else {
            if (!this.item[this.field.dataField + '_upload']) {
                this.item[this.field.dataField + '_upload'] = new UploadDocument();
            }
            this.item[this.field.dataField + '_upload'].value = 'delete';
        }
    }

    /**
     * restore l'état du fichier a supprimé
     * @param index  index de l'image a traité
     */
    public restoreFile(index?: number): void {
        if (this.field.editorOptions && this.field.editorOptions.multiple) {
            let idx: number;
            if (index !== null && index !== undefined) {
                idx = this.findEquivalentIndexInOriginalArray(this.imagesWithoutDefault[index]);
                delete this.item[this.field.dataField][idx][this.field.editorOptions.multiple + '_upload'];
                delete this.imagesWithoutDefault[index][this.field.editorOptions.multiple + '_upload'];
            } else {
                idx = this.item[this.field.dataField].findIndex(x => x.isDefault === true);
                delete this.item[this.field.dataField][idx].cdnImage_upload;
                delete this.defaultImage.cdnImage_upload;

            }
        }
    }

    /**
     * déclenche l'evenement de l'input (pour selectionner 1 ou n fichier(s))
     */
    selectClick(): void {
        this.fileUploader.instance.reset();
        this.fileUploader.instance['_isCustomClickEvent'] = true;
        this.fileUploader.instance['_$fileInput'][0].click();
    }

    /**
     * event du click sur le text URL
     */
    selectClickFromUrl(): void {
        this.popupImageFromExternalUrl = true;
    }

    /**
     * supprimer le fichier nouvellement ajouté
     * @param index index de l'image a traité
     */
    deleteAddedFile(index?: number): void {
        if (this.field.editorOptions && this.field.editorOptions.multiple) {
            const idx = this.findEquivalentIndexInOriginalArray(this.imagesWithoutDefault[index]);
            this.item[this.field.dataField].splice(idx, 1);
            this.imagesWithoutDefault.splice(index, 1);
        } else {
            this.item[this.field.dataField + '_upload'] = new UploadDocument();
        }
    }

    /**
     * on tile View Ready
     * @param event voir doc
     */
    tileViewContentReady(event: any): void {
    }

    toggleDefault() {
        this.defaultVisible = !this.defaultVisible;
    }
    toggleDefaultLeave() {
        this.defaultVisible = false;
    }

}

