import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { RoundingRule } from '@app/shared/model';

export enum RoundingRulesForSociety { R0_0001 = RoundingRule.R0_0001,
  R0_001 = RoundingRule.R0_001, R0_01 = RoundingRule.R0_01, R0_1 = RoundingRule.R0_1,
  R1 = RoundingRule.R1, R0_0005 = RoundingRule.R0_0005, R0_005 = RoundingRule.R0_005,
  R0_05 = RoundingRule.R0_05, R0_5 = RoundingRule.R0_5, R5 = RoundingRule.R5,
  R10 = RoundingRule.R10, R50 = RoundingRule.R50, R100 = RoundingRule.R100,
  R500 = RoundingRule.R500, R1000 = RoundingRule.R1000 }

export class Round extends BaseEntity2 {
    limit: number;
    roundingRule: RoundingRulesForSociety;
    constructor() {
        super();
        this.limit = 0;
        this.roundingRule = RoundingRulesForSociety.R0_0001;
    }
}
