import { BaseEntity2, TaxingType, ManagementTypeTax, CommercialOperationState, CommercialOperationInternalState, CommercialOperationInvoicingState, OfferStatus } from '..';
import { CommercialOperationType } from './commercialOperationType';
import { BasicRate, Currency, NatureVAT } from '../company';
import { Depot } from '../stock';
import { Invoice } from './invoice';
export class CommercialOperation extends BaseEntity2 {

    /** Date limite de l'offre
     *    
     * vide par  defaut
     * 
    */
    deadline: Date;
    /** status de l'offre
     *    
     * (exemple : saisie,clotutéé ...)
     * 
    */
    offerStatus: OfferStatus;
    /** obligatoire */
    type: CommercialOperationType;
    /** désignation
     *
     * longueur max 100
     *
     * vide par  defaut
     *
     * obligatoire
    */
    designation: string;
    /**
     * Date à laquelle la commande a été passée pas forcement identique à celle saisie dans le logiciel
     *
     * (exemple : cde recu par mail mais saisie plus tard, ...)
     *
     * obligatoire
    */
    dateCompanyOrdering: Date;
    /** Date de référence utilisé pour le calcul des taxes, tarifs, ....
     *
     * obligatoire
     */
    dateActivityRef: Date;
    /** tarif de l'operation commerciale
     *
     * obligatoire
    */
    basicRate: BasicRate;
    /**
     * devise de l'operation commerciale
     *
     * obligatoire
    */
    currency: Currency;
    /**
     * Taux de la devise a la creation de la commande
     *
     * obligatoire
    */
    currencyRate: number;
    /** Nature de la tva
     *
     * not-null en base mais null dans le modele pour pouvoir vérifier la non nullité dans les validateurs*/
    natureVAT: NatureVAT;
    /** */
    managementTypeTax: ManagementTypeTax;
    /**
     * Dépot de l'opération commerciale
     *
     * obligatoire
     *
     * lien vers la table depot
     * */
    depot: Depot;
    /** */
    state: CommercialOperationState;
    /** status interne de commande*/
    internalState: CommercialOperationInternalState;
    /** */
    comment: string;
    /** */
    taxingType: TaxingType;
    /** offre valable jusqu'a */
    peremptionDate: Date;
    /** */
    dateActivityRefBlock: boolean;
    /** */
    weekNumberCompanyOrdering: number;
    /** */
    yearCompanyOrdering: number;
    /** */
    weekNumberCreated: number;
    /** */
    yearCreated: number;
    /** */
    weekNumberPeremptionDate: number;
    /** */
    yearCompanyPeremptionDate: number;
    /**Status de la facturation**/
    invoicingState: CommercialOperationInvoicingState;

    invoice: Invoice;


    constructor() {
        super();
        this.type = null;
        this.deadline = null;
        this.designation = '';
        this.dateCompanyOrdering = new Date();
        this.dateActivityRef = new Date();
        this.basicRate = null;
        this.currency = null;
        this.currencyRate = 0;
        this.natureVAT = NatureVAT.EU_DELIVERY;
        this.managementTypeTax = 0;
        this.depot = null;
        this.state = CommercialOperationState.GRAPSED;
        this.internalState = CommercialOperationInternalState.INVALID;
        this.comment = '';
        this.taxingType = TaxingType.HT;
        this.peremptionDate = new Date();
        this.dateActivityRefBlock = true;
        this.weekNumberCompanyOrdering = 0;
        this.yearCompanyOrdering = 0;
        this.weekNumberCreated = 0;
        this.yearCreated = 0;
        this.weekNumberPeremptionDate = 0;
        this.yearCompanyPeremptionDate = 0;
        this.invoicingState = CommercialOperationInvoicingState.NOT_BILLABLE;
        this.invoice = null;
        this.offerStatus = null;

    }

}
