import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';

export class PaymentChoice extends BaseEntity2 {
    designation: string;
    code: string;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.code = '';
        this.visible = true;

    }

    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<PaymentChoice> {
        return {
            visible: true
        };
    }
}
