import { Component, HostListener, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import notify from 'devextreme/ui/notify';
import * as $ from 'jquery';

import { User, UserType } from '@app/shared/model/user';
import { AuthService, SidebarService } from '@app/core/services';
import { notifyErrorDelay } from '@app/globalsParameter';
import { ApplicationMode } from '@app/shared/model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModuleService } from '@app/core/services/admin/module.service';
import { AnyARecord } from 'dns';
import { Module } from '@app/shared/model/admin/module';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    toggleMenuState: boolean = false;
    clickIsOnHeader: boolean = false;
    currentUser: User;
    UserType = UserType;
    User = User;
    public displayUserMenu: Boolean = false;
    private subscriptions: Subscription[] = [];
    mobilStore = false;


    constructor(
        public sidebarService: SidebarService,
        public authService: AuthService,
        private translateService: TranslateService,
        private router: Router,
        public moduleService: ModuleService,

    ) {
        this.subscriptions.push(
            this.authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    this.currentUser = user;
                },
                (error) => notify(error.error, 'error', notifyErrorDelay)
            )
        );
        this.subscriptions.push(
            this.moduleService.moduleBehaviourSubject.subscribe(
                (mobilStore: boolean) => {
                   this.mobilStore = mobilStore;
                },
                (error) => notify(error.error, 'error', notifyErrorDelay)
            )
        );
    }

    ngOnInit(): void {
        if (this.currentUser.type != UserType.SUPER_ADMINISTRATOR) {
            this.subscriptions.push(
                this.moduleService.getAll().subscribe(
                    (modules: Module[]) => {
                        this.moduleService.moduleBehaviourSubject.next(modules[modules.findIndex((x)=>x.name === 'Mobilstore')].actif === 1);
                    },
                    (error) => notify(error.error, 'error', notifyErrorDelay)
                )
            );
        }
    }

    toggleSideNav(): void {
        this.sidebarService.opened = !this.sidebarService.opened;
        this.sidebarService._openedState.next(this.sidebarService.opened);
    }

    toggleMenu(): void {
        this.toggleMenuState = !this.toggleMenuState;
        $('#menu').slideToggle();
    }

    goToHelpCenter() {
        let lang = '?lang=fr';
        if (this.translateService.currentLang !== 'fr') {
            lang = '?lang=en';
        }
        if (this.currentUser && this.currentUser.societies[0].applicationMode === ApplicationMode.HORTI) {

            window.open('http://horti.help.lginextgc.com/' + lang, '_blank');
        } else {
            window.open('http://viti.help.lginextgc.com/' + lang, '_blank');
        }
    }


    @HostListener('window:click', ['$event'])
    onClick(event) {
        /**
         * Problème firefox:
         * La propriété path des objets Event n'est pas standard.
         * L'équivalent standard est composePath, qui est une méthode.
         * Il faut donc vérifier l'orgine de path
         * Src: https://stackoverflow.com/questions/39245488/event-path-is-undefined-running-in-firefox
         */
        const path = event.path || (event.composedPath && event.composedPath());
        if(path) {
            this.clickIsOnHeader = (path as Array<any>).some(path => path.id === 'userInfo');
            if (!this.clickIsOnHeader) {
                $('#menu').hide();
                this.toggleMenuState = false;
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.displayUserMenu = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
