import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DxDataGridColumn } from '../data-grid/models';

@Component({
    selector: 'dx-data-grid-field',
    templateUrl: './data-grid-field.component.html',
    styleUrls: ['./data-grid-field.component.scss']
})
export class DataGridFieldComponent implements OnInit {
    @Input() dataSource: any[];
    @Input() columns: { [key: string]: DxDataGridColumn };
    @Input() title: string;
    @Input() isRequired: boolean;
    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Input() allowAdding: boolean = true;
    @Input() allowUpdating: boolean = true;
    @Input() allowDeleting: boolean = true;

    constructor(

    ) { }


    ngOnInit() {
        if (!this.dataSource) {
            this.dataSource = [];

        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'title'
        });
    }

    onDataChange() {
        setTimeout(() => this.valueChanged.emit(this.dataSource));
    }


}
