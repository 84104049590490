import { BaseEntity2 } from './base-entity2';
import { User } from './user';

export class PopupConfig extends BaseEntity2 {
    popupId: number;
    user: User;
    width: number;
    height: number;
    x: number;
    y: number;


    constructor() {
        super();
        this.popupId = 0;
        this.user = null;
        this.width = 0;
        this.height = 0;
        this.x = 0;
        this.y = 0;
    }

    public static convertIdStringPopupToInt(identifiant: string): number {
        switch (identifiant) {
            case 'category':
                return 1;
            case 'articleListReportPrint_favorisReports':
                return 2;
            case 'articleListReportPrint_Reports':
                return 3;
            case 'articleDetailReportPrint_favorisReports':
                return 4;
            case 'articleDetailReportPrint_Reports':
                return 5;
            case 'representative1':
                return 6;
            case 'representative2':
                return 7;
            case 'representative3':
                return 8;
            case 'paymentChoice':
                return 8;
            case 'movementTitleType':
                return 9;
            case 'followUpStatusPrevious':
                return 10;
            case 'followUpStatus':
                return 11;
            case 'civility':
                return 12;
            case 'currency':
                return 13;
            case 'basicRate':
                return 14;
            case 'userTelesales':
                return 15;
            case 'designationRef4':
                return 16;
            case 'designationRef5':
                return 17;
            case 'family':
                return 18;
            case 'underFamily':
                return 19;
            case 'costGroup':
                return 20;
            case 'purchaseCode':
                return 21;
            case 'salesCode':
                return 22;
            case 'transportationBase':
                return 23;
            case 'finalPackage':
                return 24;
            case 'portScale':
                return 25;
            case 'referencing':
                return 26;
            case 'customerListReportPrint_favorisReports':
                return 27;
            case 'customerListReportPrint_Reports':
                return 28;
            case 'customerDetailReportPrint_favorisReports':
                return 29;
            case 'customerDetailReportPrint_Reports':
                return 30;
            case 'supplierListReportPrint_favorisReports':
                return 31;
            case 'supplierListReportPrint_Reports':
                return 32;
            case 'supplierDetailReportPrint_favorisReports':
                return 33;
            case 'supplierDetailReportPrint_Reports':
                return 34;
            case 'class':
                return 35;
            case 'loginServerInfo':
                return 36;
            case 'contactSupplier':
                return 37;
            case 'contactCustomer':
                return 38;
            case 'clientRef':
                return 39;
            case 'function':
                return 40;
            case 'packaging_packagingArticle':
                return 41;
            case 'pricing_pricingArticle':
                return 49;
            case 'idPrivilegiedPackaging1_packagingArticle':
                return 42;
            case 'idPrivilegiedPackaging2_packagingArticle':
                return 43;
            case 'idPrivilegiedPackaging3_packagingArticle':
                return 44;
            case 'idPrivilegiedPackaging4_packagingArticle':
                return 45;
            case 'packaging_packagingCrossing':
                return 46;
            case 'packaging_packagingOverPack':
                return 47;
            case 'vat':
                return 48;
            case 'popupBlock':
                return 49;
            case 'popupUnderBlock':
                return 50;
            case 'popupField':
                return 51;
            case 'tva_tvaTax':
                return 52;
            case 'tax_taxFamily':
                return 53;
            case 'taxFamily_Product':
                return 54;
            case 'InWaitingWidgetPicker':
                return 55;
            case 'representative4':
                return 56;
            case 'representative5':
                return 66;
            case 'representative6':
                return 67;
            case 'customerToInvoiceTable':
                return 68;
            case 'customerOpInvoicing':
                return 69;
            case 'supplierOpInvoicing':
                return 70;
            case 'depotParamUser':
                return 71;
            case 'operationLogistiqueCustomer':
                return 72;
            case 'articlesForOperation':
                return 73;
            case 'operationListReportPrint_favorisReports':
                return 74;
            case 'operationListReportPrint_Reports':
                return 75;
            case 'operationDetailReportPrint_favorisReports':
                return 76;
            case 'operationDetailReportPrint_Reports':
                return 77;
            case 'whyFree':
                return 78;
            case 'paymentMethod':
                return 79;
            case 'usersTable':
                return 80;
            case 'transportTable':
                return 81;
            case 'ftpChoice':
                return 82;
            case 'stockLotForMvts':
                return 83;
            case 'packaging1_packagingArticle':
                return 84;
            case 'packagingFinal_packagingArticle':
                return 85;
            case 'ebacchus_product':
                return 86;
            case 'productColor_product':
                return 87;
            case 'productType_product':
                return 88;
            case 'tax_taxLabels':
                return 89;
            case 'typeDesignation5':
                return 90;
            case 'product':
                return 91;
            case 'supplierToInvoiceTable':
                return 92;
            case 'articleRef':
                return 93;
            case 'StockLotListReportPrint_Reports':
                return 94;
            case 'StockLotListReportPrint_favorisReports':
                return 95;
            case 'StockLotDetailsReportPrint_Reports':
                return 96;
            case 'StockLotDetailsReportPrint_favorisReports':
                return 97;
            case 'StockMvtListReportPrint_Reports':
                return 98;
            case 'StockMvtListReportPrint_favorisReports':
                return 99;
            case 'society':
                return 100;
            case 'customerOperationDetailReportPrint_favorisReports':
                return 101;
            case 'customerOperationDetailReportPrint_Reports':
                return 102;
            case 'supplierOperationDetailReportPrint_favorisReports':
                return 103;
            case 'supplierOperationDetailReportPrint_Reports':
                return 104;
            case 'operationDetailMail':
                return 105;
            case 'suppliersOpNewTable':
                return 106;
            case 'operationDetailLogisticEtiquetteProduitPrint_Reports':
                return 108;
            case 'operationDetailLogisticEtiquetteProduitPrint_favorisReports':
                return 109;
            case 'operationDetailLogisticEtiquetteColisagePrint_Reports':
                return 110;
            case 'operationDetailLogisticEtiquetteColisagePrint_favorisReports':
                return 111;
            case 'customerEtiquetteColisagePrint_Reports':
                return 112;
            case 'customerEtiquetteColisagePrint_favorisReports':
                return 113;
            case 'customerEtiquetteProduitPrint_Reports':
                return 114;
            case 'customerEtiquetteProduitPrint_favorisReports':
                return 115;
            case 'customersOpNewTable':
                return 116;
            case 'customersOpDetailTable':
                return 117;
            case 'suppliersOpDetailTable':
                return 118;
            case 'operationDetailLabel':
                return 119;
            case 'ftp':
                return 120;
            case 'mailTemplateBase':
                return 121;
            case 'mailTemplatePerso':
                return 122;
            case 'reportBase':
                return 123;
            case 'reportPerso':
                return 124;
            case 'customerReglementTable':
                return 125;
            case 'articles':
                return 126;
            case 'usersTableMobilEquipement':
                return 127;
            case 'customerOfReferenceMobilEquipment':
                return 128;
            case 'checkoutCustomerMobilEquipement':
                return 129;
            case 'externUsers':
                return 130;
            case 'usersTableExternUsers':
                return 131;
            case 'articleDetailEtiquetteProduitPrint_Reports':
                return 132;
            case 'articleDetailEtiquetteProduitPrint_favorisReports':
                return 133;
            case 'articleDetailEtiquetteColisagePrint_Reports':
                return 134;
            case 'articleDetailEtiquetteColisagePrint_favorisReports':
                return 135;
            case 'commercialOperationLineEtiquetteProduitPrint_Reports':
                return 136;
            case 'commercialOperationLineEtiquetteProduitPrint_favorisReports':
                return 137;
            case 'commercialOperationLineEtiquetteColisagePrint_Reports':
                return 138;
            case 'commercialOperationLineEtiquetteColisagePrint_favorisReports':
                return 139;
            case 'invoiceInCommissionTable':
                return 140;
            case 'commercialOperationLineEtiquetteColisagePrint_Reports_favorisReports':
                return 141;
            case 'commercialOperationLineEtiquetteColisagePrint_Reports_Reports':
                return 142;
            default:
                console.error(identifiant + ' ajout identifier dans popupconfig');
                break;
        }
    }
    public static mapToFormatObject(obj: PopupConfig) {
        if (obj) {
            return {
                size: {
                    width: obj.width,
                    height: obj.height
                },
                position: {
                    x: obj.x,
                    y: obj.y
                },
                fromLocalStorage: false
            };
        } else {
            return undefined;
        }
    }

}
