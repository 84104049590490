import { Directive, Input, TemplateRef, ViewContainerRef, Optional, Host, Renderer2, NgZone } from '@angular/core';
import { DxFormComponent } from '@app/shared/modules/devextreme/dx-form/dx-form.component';
import { DataGridComponent } from '@app/shared/modules/devextreme/data-grid/data-grid.component';
import { SlidePanelComponent } from '@app/shared/modules/devextreme/slide-panel/slide-panel.component';
import { ExtraButtonFieldComponent } from '@app/shared/modules/devextreme/extra-button-field/extra-button-field.component';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[templateKey]'
})
export class KeyedTemplateDirective {
    private key: string;
    @Input()
    set templateKey(key: any) {
        if (this.parentForm && this.templateRef) {
            this.parentForm.registerTemplate(key, this);
        } else if (this.parentGrid && this.templateRef) {
            if (key == 'filterTemplateContent' && this.parentGrid.filterButton == true) {
                this.parentExtraButton.registerTemplate(key, this);
            } else {
                this.parentGrid.registerTemplate(key, this);
            }
        } else if (this.parentExtraButton && this.templateRef){
            this.parentExtraButton.registerTemplate(key, this)
        }
        if (this.parentSlidePanel && this.templateRef) {
            this.parentSlidePanel.registerTemplate(key, this);
        }
    }

    get templateKey() {
        return this.key;
    }

    constructor(
        @Host() @Optional() public parentForm: DxFormComponent,
        @Host() @Optional() public parentGrid: DataGridComponent,
        @Host() @Optional() public parentSlidePanel: SlidePanelComponent,
        @Host() @Optional() public parentExtraButton: ExtraButtonFieldComponent,
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private renderer: Renderer2,
        private zone: NgZone
    ) {
    }
/*
// actuellement non utilisé c'était la premiére méthode pour rendre les templates
    private renderTemplate(renderData) {
        console.log('fdfffffffffffffffffffffff');
        const _that = this;
        const childView = _that.vcRef.createEmbeddedView(this.templateRef, {
            '$implicit': renderData.model
        });
        const container = this.getElement(renderData.container);
        if (renderData.container) {
            childView.rootNodes.forEach(function (element) {
                _that.renderer.appendChild(container.nativeElement, element);
            });
        }
        return childView;
    }

    public render(renderData: any) {
        console.log('fdfffffffffffffffffffffff');
        const _that = this;
        let childView;
        if (this.zone.isStable) {
            childView = this.zone.run(function () {
                return this.renderTemplate(renderData);
            });
        } else {
            childView = this.renderTemplate(renderData);
            // =========== WORKAROUND =============
            // https://github.com/angular/angular/issues/12243
            childView['detectChanges']();
            // =========== /WORKAROUND =============
        }
        childView.rootNodes.forEach(function (element) {
            if (element.nodeType === 1) {
                _that.renderer.addClass(element, 'dx-template-wrapper');
            }
            one(element, 'dxremove', function (_a, params) {
                if (!params || !params._angularIntegration) {
                    childView.destroy();
                }
            });
        });
        return childView.rootNodes;
    }
*/
    getElement(element) {
        return element.get ? element.get(0) : element;
    }

    get template(): TemplateRef<any> {
        return this.templateRef;
    }

    // updateView() {
    // this.vcRef.clear();
    // this.vcRef.createEmbeddedView(this.templateRef, this.context);
    // }
}
