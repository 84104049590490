import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { DaoGridStateBaseService } from '..';
import { IDaoBaseService } from '@app/shared/interface';
import {
    CommercialOperation, CompanyType, SupplierCommercialOperation, CustomerCommercialOperation, TableState, CustomerCommercialOperationLine,
    CommercialOperationExpeditionState, CommercialOperationInvoicingState, OriginPage, CodeCommercialOperationType
} from '@app/shared/model';
import { notifyErrorDelay, numberOfTuplesForCache } from '@app/globalsParameter';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { LockObjectKey } from '@app/shared/model/common/redis-object-lock';
import { CommercialOperationTryLockViewModel } from '@app/shared/viewModel/CommercialOperationTryLock';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';
import { CreateCommercialOperationCustomer } from '@app/shared/viewModel/createCommercialOperationCustomer';
import { CreateCommercialOperationSupplier } from '@app/shared/viewModel/createCommercialOperationSupplier';
import { DetailSet } from '@app/shared/viewModel/detailSet';
export enum ModePage { DEFAULT, CREATEMODE }
import { StorageService } from '../storage.service';
import { ModuleService } from '../admin/module.service';

@Injectable({
    providedIn: 'root'
})

export class CommercialOperationService extends DaoGridStateBaseService implements IDaoBaseService {
    private _gridStateComOpCustomer: Observable<string> = null;
    private _gridStateComOpSupplier: Observable<string> = null;
    trisField = null;
    trisStrategie = 'asc';
    beginning = 0;
    numberOfTuples = '10';
    list: CommercialOperation[] = [];
    idList: number[] = [];
    redisKeyPrint: string;
    params: DataGridFiltersParam = new DataGridFiltersParam();
    currentItem: BehaviorSubject<CustomerCommercialOperation | SupplierCommercialOperation> = new BehaviorSubject<CustomerCommercialOperation | SupplierCommercialOperation>(null);
    currentOpState: BehaviorSubject<string> = new BehaviorSubject<string>('inProgress');
    currentOpType: BehaviorSubject<CodeCommercialOperationType> = new BehaviorSubject<CodeCommercialOperationType>(CodeCommercialOperationType.ALL);

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration,
        public localStorage: StorageService,
        public moduleService: ModuleService
    ) {
        super(http, _translate, appConfiguration);
    }


    getByFilter(companyType: CompanyType, operationState: string, operationType: CodeCommercialOperationType, state?: string): Observable<any> {
        let serviceUrl: string = null;

        serviceUrl = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'getByFilter/' + companyType + '/'
            + this.beginning + '/' + this.numberOfTuples + '/' + this.trisField + '/' + this.trisStrategie
            + '/' + operationState + '/' + (operationType ? (operationType) : CodeCommercialOperationType.ALL);
            if (state == 'toBill') {
                this.params.singleColumnSearch.push(
                    {
                        'dataField': 'invoicingState',
                        'filterValue': CommercialOperationInvoicingState.BILLABLE,
                        'filterValueRange': null,
                        'selectedFilterOperation': '=',
                        'origin': 'dataGrid',
                        'type': 'enum',
                        'enumType': CommercialOperationInvoicingState,
                        'caption': 'type',
                        'showFilterBadge': false,
                    }
                );
            }
            this.moduleService.moduleBehaviourSubject.subscribe(
            (mobilStore: boolean) => {
                if (!mobilStore) {
                    this.params.singleColumnSearch.push(
                        {
                            'dataField': 'type.designation',
                            'filterValue': 'Ticket',
                            'filterValueRange': null,
                            'selectedFilterOperation': '<>',
                            'origin': 'dataGrid',
                            'type': 'string',
                            'caption': 'type',
                            'showFilterBadge': false,
                        }
                    );
                }
            },
            (error) => notify(error.error, 'error', notifyErrorDelay)
        )
        return this.http.post(serviceUrl, this.params);
    }

    lock(companyType: CompanyType, operationTryLock: CommercialOperationTryLockViewModel): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'tryLockCommercialOperation/' + companyType;
        // + (companyType === CompanyType.CUSTOMER ? LockObjectKey.CUSTOMER_OPERATION : LockObjectKey.SUPPLIER_OPERATION) + '/'
        //  + idOperation;
        return this.http.post(serviceUrl, operationTryLock);
    }

    unlock(idOperation: number, companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsLock + 'unlockState/' + (companyType === CompanyType.CUSTOMER ? LockObjectKey.CUSTOMER_OPERATION : LockObjectKey.SUPPLIER_OPERATION) + '/'
            + idOperation;
        return this.http.post(serviceUrl, {});
    }

    getlockState(idOperation: number, companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsLock + 'getLockState/' + (companyType === CompanyType.CUSTOMER ? LockObjectKey.CUSTOMER_OPERATION : LockObjectKey.SUPPLIER_OPERATION) + '/'
            + idOperation;
        return this.http.get(serviceUrl);
    }

    get(id: number, companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'get/' + companyType + '/' + id;
        return this.http.get(serviceUrl);
    }

    getForDetail(id: number, companyType: CompanyType): Observable<DetailSet<CommercialOperation>> {

        let url: string = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'getForDetail/' + companyType + '/' + id + '/' + numberOfTuplesForCache + '/' + this.trisField + '/' + this.trisStrategie;
        if (this.currentOpState.value) {
            url += '/' + this.currentOpState.value;
        }

        if (this.currentOpType.value) {
            url += '/' + this.currentOpType.value;
        }

        this.moduleService.moduleBehaviourSubject.subscribe(
            (mobilStore: boolean) => {
                if (!mobilStore) {
                    this.params.singleColumnSearch.push(
                        {
                            'dataField': 'type.designation',
                            'filterValue': 'Ticket',
                            'filterValueRange': null,
                            'selectedFilterOperation': '<>',
                            'origin': 'dataGrid',
                            'type': 'string',
                            'caption': 'type',
                            'showFilterBadge': false,
                        }
                    );
                }
            },
            (error) => notify(error.error, 'error', notifyErrorDelay)
        )
        return this.http.post<DetailSet<CommercialOperation>>(url, this.params);
    }

    getForNavigation(idList: number[], companyType: CompanyType): Observable<CommercialOperation[]> {
        const url: string = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'getForDetailNavigation/' + companyType;
        return this.http.post<CommercialOperation[]>(url, idList);
    }

    post(payload: CreateCommercialOperationCustomer | CreateCommercialOperationSupplier, companyType: CompanyType, id?: number): Observable<NGCResults<CustomerCommercialOperation> | NGCResults<SupplierCommercialOperation>> {

        const body = {
            company: payload.company.id,
            type: payload.type.id,
            date: payload.dateCompanyOrdering,
            refForCompany: payload.info.refForCompany,
            designation: payload.designation,
        };

        if (!id) {
            const url = this.appConfiguration.UrlsConfig.wsCommercialOperation + companyType;
            return this.http.post<NGCResults<CustomerCommercialOperation> | NGCResults<SupplierCommercialOperation>>(url, body).clearListCache(this.clearListTablesCache, companyType);
        } else {
            const url = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'duplicate/' + companyType + '/' + id;
            return this.http.post<NGCResults<CustomerCommercialOperation> | NGCResults<SupplierCommercialOperation>>(url, body).clearListCache(this.clearListTablesCache, companyType);
        }
    }

    generateCreditNote(companyType: CompanyType, item: CustomerCommercialOperation | SupplierCommercialOperation): Observable<NGCResults<CustomerCommercialOperation> | NGCResults<SupplierCommercialOperation>> {
        const url = this.appConfiguration.UrlsConfig.wsCommercialOperation + 'generateCreditNote/' + companyType + '/' + item.id;
        const body = {
            company: item.company.id,
            date: item.dateCompanyOrdering,
            refForCompany: item.info.refForCompany,
            designation: companyType === CompanyType.CUSTOMER ? this._translate.instant("CommercialOperation.creditNoteOnCommand", { idCommand: item.id }) : this._translate.instant("CommercialOperation.creditNoteOnPurchase", { idPurchase: item.id })
        };
        return this.http.post<NGCResults<CustomerCommercialOperation> | NGCResults<SupplierCommercialOperation>>(url, body).clearListCache(this.clearListTablesCache, companyType);
    }

    postCCOpLine(body: CustomerCommercialOperationLine[], companyType: CompanyType): Observable<CustomerCommercialOperationLine[]> {

        const url = this.appConfiguration.UrlsConfig.wsCommercialOperationLine + companyType;
        return this.http.post<any>(url, body).clearListCache(this.clearListTablesCache, companyType);
    }

    put(code: number, payload: CustomerCommercialOperation | SupplierCommercialOperation,
        companyType: CompanyType): Observable<NGCResults<CustomerCommercialOperation | SupplierCommercialOperation>> {
        const url = this.appConfiguration.UrlsConfig.wsCommercialOperation + companyType + '/' + code + '/false';
        return this.http.put<NGCResults<CustomerCommercialOperation | SupplierCommercialOperation>>(url, payload);
    }

    delete(id: number, companyType: CompanyType): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsCommercialOperation + companyType + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: number, companyType: CompanyType, mode: ModePage, opState?: string, opType?: CodeCommercialOperationType, origin?: OriginPage): void {
        if (this.currentOpState.value != null) {
            opState = this.currentOpState.value;
            this.currentOpState.next(opState)
        }
        if (this.currentOpType.value != null) {
            opType = this.currentOpType.value;
            this.currentOpType.next(opType);
        }
        let opTypeRoute = '';
        if (opType) {
            switch (opType) {
                case CodeCommercialOperationType.COMMANDE:
                    opTypeRoute = 'commandes/'
                    break;
                case CodeCommercialOperationType.OFFRE:
                    opTypeRoute = 'offres/'
                    break;
                case CodeCommercialOperationType.TICKET:
                    opTypeRoute = 'tickets/'
                    break;

                default:
                    break;
            }
        }
        this.currentItem.next(null)
        if (origin == OriginPage.OPERATION_TO_BILL) {
            this.router.navigate(['operation/trackInvoices/toBill/' + mode + '/' + id]);
        } else if (origin == OriginPage.OPERATION_TO_SHIP) {
            this.router.navigate(['operation/logistic/toShip/' + mode + '/' + id]);
        } else if (origin == OriginPage.TRACK_INVOICE) {
            this.router.navigate(['operation/trackInvoices/vente/' + mode + '/' + id + '/' + origin])
        } else {
            this.router.navigate(['operation/' +
                (companyType === CompanyType.CUSTOMER ? 'vente/' : 'achat/') + opTypeRoute +
                mode + '/' + id]);
        }

    }

    navigateToCreatePage(companyType: CompanyType, operationType?: CodeCommercialOperationType): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (operationType === CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/create']);
            } else if (operationType === CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/create']);
            } else {
                this.router.navigate(['/operation/vente/create']);
            }
        } else if (companyType === CompanyType.SUPPLIER) {
            this.router.navigate(['/operation/achat/create']);
        }
    }

    navigateToLogistic(modePage: ModePage, id: number, companyType: CompanyType, origin?: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/logistic']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/logistic']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/logistic']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/logistic']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/logistic']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/logistic'])
            }else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/logistic']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/logistic']);
        }
    }

    navigateToExpedition(id: number, companyType: CompanyType, origin?: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + id + '/expedition']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + id + '/expedition']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + id + '/expedition']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + id + '/expedition']);
            } else {
                this.router.navigate(['/operation/vente/' + id + '/expedition']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + id + '/reception']);
        }
    }

    navigateToInfo(modePage: ModePage, id: number, companyType: CompanyType, origin?: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/info']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/info']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/info']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/info']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/info']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/info'])
            } else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/info']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/info']);
        }
    }

    navigateToInvoicing(modePage: ModePage, id: number, companyType: CompanyType, origin: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/invoicing']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/invoicing']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/invoicing']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/invoicing']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/invoicing']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/invoicing'])
            } else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/invoicing']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/invoicing']);
        }
    }

    navigateToPricing(modePage: ModePage, id: number, companyType: CompanyType, origin: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/pricing']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/pricing']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/pricing']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/pricing']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/pricing']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/pricing'])
            } else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/pricing']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/pricing']);
        }
    }

    navigateToHeader(modePage: ModePage, id: number, companyType: CompanyType, origin: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/header']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/header']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/header']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/header']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/header']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/header'])
            } else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/header']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/header']);
        }
    }

    navigateToList(companyType: CompanyType, origin: OriginPage): void { 
        this.currentItem.next(null);
        // this.router.navigate(['operation/' +
        //     (companyType === CompanyType.CUSTOMER ? 'vente/' : 'achat/')]);

            if (companyType === CompanyType.CUSTOMER) {
                if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                    this.router.navigate(['/operation/vente/commandes']);
                } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                    this.router.navigate(['/operation/vente/offres']);
                } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                    this.router.navigate(['/operation/vente/tickets']);
                } else if (origin == OriginPage.OPERATION_TO_BILL) {
                    this.router.navigate(['operation/trackInvoices/toBill']);
                } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                    this.router.navigate(['operation/logistic/toShip']);
                } else if (origin == OriginPage.TRACK_INVOICE) {
                    this.router.navigate(['operation/trackInvoices/vente'])
                } else {
                    this.router.navigate(['/operation/vente']);
                }
            } else {
                this.router.navigate(['/operation/achat']);
            }
    }

    navigateToInvoiceValidation(id: number, origin: OriginPage): void {
        if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
            this.router.navigate(['/operation/vente/commandes/' + id + '/invoice-validation']);
        } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
            this.router.navigate(['/operation/vente/offres/' + id + '/invoice-validation']);
        } else if (origin == OriginPage.OPERATION_TO_BILL) {
            this.router.navigate(['operation/trackInvoices/toBill/' + id + '/invoice-validation']);
        } else if (origin == OriginPage.OPERATION_TO_SHIP) {
            this.router.navigate(['operation/logistic/toShip/' + id + '/invoice-validation']);
        } else {
            this.router.navigate(['/operation/vente/' + id + '/invoice-validation']);
        }
    }

    navigateToHistory(modePage: ModePage, id: number, companyType: CompanyType, origin: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            if (this.currentOpType.value == CodeCommercialOperationType.COMMANDE) {
                this.router.navigate(['/operation/vente/commandes/' + modePage + '/' + id + '/history']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.OFFRE) {
                this.router.navigate(['/operation/vente/offres/' + modePage + '/' + id + '/history']);
            } else if (this.currentOpType.value == CodeCommercialOperationType.TICKET) {
                this.router.navigate(['/operation/vente/tickets/' + modePage + '/' + id + '/history']);
            } else if (origin == OriginPage.OPERATION_TO_BILL) {
                this.router.navigate(['operation/trackInvoices/toBill/' + modePage + '/' + id + '/history']);
            } else if (origin == OriginPage.OPERATION_TO_SHIP) {
                this.router.navigate(['operation/logistic/toShip/' + modePage + '/' + id + '/history']);
            } else if (origin == OriginPage.TRACK_INVOICE) {
                this.router.navigate(['operation/trackInvoices/vente/' + modePage + '/' + id + '/history'])
            } else {
                this.router.navigate(['/operation/vente/' + modePage + '/' + id + '/history']);
            }
        } else {
            this.router.navigate(['/operation/achat/' + modePage + '/' + id + '/history']);
        }
    }

    clearListTablesCache = (): void => {
        this.idList = null;
        this.list = null;
    }

    public getSupplierParameterListGridState(): Observable<any> {
        if (!this._gridStateComOpSupplier) {
            const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('supplierTableParameter');
            this._gridStateComOpSupplier = this.http.get<string>(url).pipe(shareReplay(1));
        }

        return this._gridStateComOpSupplier;
    }

    public getCustomersParameterListGridState(): Observable<any> {

        if (!this._gridStateComOpCustomer) {
            const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('customerTableParameter');
            this._gridStateComOpCustomer = this.http.get<string>(url).pipe(shareReplay(1));
        }

        return this._gridStateComOpCustomer;
    }

    public getCommercialOperationTypesParams(companyType: CompanyType): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsCommercialOperationTypesParams + companyType;
        return this.http.get<Observable<any>>(url);
    }

    public controleNavigateToExpedition(operationData: CustomerCommercialOperation | SupplierCommercialOperation, companyType: CompanyType, origin?: OriginPage): void {
        if (operationData.logistic?.expeditionState === CommercialOperationExpeditionState.SHIPPABLE) {
            this.navigateToExpedition(operationData.id, companyType, origin);
        } else if (operationData.logistic?.expeditionState === CommercialOperationExpeditionState.NOT_SHIPPABLE) {
            notify((companyType === CompanyType.CUSTOMER) ? this._translate.instant('FormErrorMessages.operationNotShippable') : this._translate.instant('FormErrorMessages.operationNotReceivable'), 'ERROR');
        } else if (operationData.logistic?.expeditionState === CommercialOperationExpeditionState.SHIPPED) {
            notify((companyType === CompanyType.CUSTOMER) ? this._translate.instant('FormErrorMessages.operationShipped') : this._translate.instant('FormErrorMessages.operationReceived'), 'ERROR');
        } else {
            notify(this._translate.instant('Cas non implémenté'), 'ERROR');
        }
    }

    public controleNavigateToInvoicing(operationData: CustomerCommercialOperation, origin?: OriginPage): void {
        if (operationData.invoicing?.makeInvoice) {
            if (operationData.logistic?.expeditionState !== CommercialOperationExpeditionState.SHIPPED) {
                notify(this._translate.instant('FormErrorMessages.operationMustBeShipped'), 'ERROR');
            } else if (operationData.invoicingState === CommercialOperationInvoicingState.BILLABLE) {
                this.navigateToInvoiceValidation(operationData.id, origin);
            } else if (operationData.invoicingState === CommercialOperationInvoicingState.NOT_BILLABLE) {
                notify(this._translate.instant('FormErrorMessages.notBillableOperation'), 'ERROR');
            } else if (operationData.invoicingState === CommercialOperationInvoicingState.BILLED) {
                notify(this._translate.instant('FormErrorMessages.operationBilled'), 'ERROR');
            } else {
                notify(this._translate.instant('Cas non implémenté'), 'ERROR');
            }
        } else {
            notify(this._translate.instant('FormErrorMessages.notBillableOperation'), 'ERROR');
        }
    }

    clearGridStateCustomerCache(): void {
        this._gridStateComOpCustomer = null;
    }

    clearGridStateSupplierCache(): void {
        this._gridStateComOpSupplier = null;
    }

    navigateToArticle(idOperation: number, idArticle: number, companyType: CompanyType, modePage: ModePage, originPage: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            this.router.navigate(['article/operation/vente/' + modePage + '/' + idOperation + '/article/detail/' + idArticle + '/' + originPage]);
        } else {
            this.router.navigate(['article/operation/achat/' + modePage + '/' + idOperation + '/article/detail/' + idArticle + '/' + originPage]);
        }
    }

    navigateToCompanyFromCreate(companyType: CompanyType, idCompany: number, originPage: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            this.router.navigate(['company/operation/vente/create/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        } else {
            this.router.navigate(['company/operation/achat/create/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        }
    }

    navigateToCompanyFromDuplicate(companyType: CompanyType, idCompany: number, idDuplicate: number, originPage: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            this.router.navigate(['company/operation/vente/duplicate/' + idDuplicate + '/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        } else {
            this.router.navigate(['company/operation/achat/duplicate/' + idDuplicate + '/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        }
    }

    navigateToCompanyFromHeader(companyType: CompanyType, idCompany: number, idOperation: number, modePage: ModePage, originPage: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            this.router.navigate(['company/operation/vente/' + modePage + '/' + idOperation + '/header/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        } else {
            this.router.navigate(['company/operation/achat/' + modePage + '/' + idOperation + '/header/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        }
    }

    navigateToCompanyFromInvoicing(companyType: CompanyType, idCompany: number, idOperation: number, modePage: ModePage, originPage: OriginPage): void {
        if (companyType === CompanyType.CUSTOMER) {
            this.router.navigate(['company/operation/vente/' + modePage + '/' + idOperation + '/invoicing/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        } else {
            this.router.navigate(['company/operation/achat/' + modePage + '/' + idOperation + '/invoicing/company/' + companyType + '/detail/' + idCompany + '/' + originPage]);
        }
    }
}
