import { custom, alert } from 'devextreme/ui/dialog';
import { NGCResult } from '@app/shared/model/NGCResults/NGCResults';

export enum CustomConfirmDialogResult {
    NO,
    YES,
    CANCEL
}
/**
 */
export const confirmDialogWithCancel = (title: string, textCancel: string, textNo: string, textYes: string,
    content: string, widthBtnCancel: string, widthBtnYes: string, widthBtnNo: string): any => {
    return custom({
        title: title,
        messageHtml: content,
        buttons: [
            {
                text: textCancel,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: widthBtnCancel,
                stylingMode: 'outlined',
                onClick: () => {
                    return CustomConfirmDialogResult.CANCEL;
                }
            },
            {
                text: textNo,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: widthBtnYes,
                stylingMode: 'outlined',
                onClick: () => {
                    return CustomConfirmDialogResult.NO;
                }
            },
            {
                text: textYes,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: widthBtnNo,
                onClick: () => {
                    return CustomConfirmDialogResult.YES;
                }
            }
        ]
    });
};

export const confirmDuplicateDialogWithCancel = (title: string, textCancel: string, textNo: string, textYes: string,
    content: string): any => {
    return custom({
        title: title,
        messageHtml: content,
        buttons: [
            {
                text: textCancel,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: '90',
                stylingMode: 'outlined',
                onClick: () => {
                    return CustomConfirmDialogResult.CANCEL;
                }
            },
            {
                text: textNo,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: '90',
                stylingMode: 'outlined',
                onClick: () => {
                    return CustomConfirmDialogResult.NO;
                }
            },
            {
                text: textYes,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                width: '90',
                onClick: () => {
                    return CustomConfirmDialogResult.YES;
                }
            }
        ]
    });
};

// export const errorDialog = (title: string,  textYes: string,
//     content: string) => {
//     return custom({
//         showTitle: title && title !== '',
//         title: title,
//         messageHtml: content,
//         buttons: [
//             {
//                 text: textYes,
//                 focusStateEnabled: false,
//                 useSubmitBehavior: false,
//                 type: 'danger',
//                 stylingMode: 'contained',
//                 onClick: (e) => {
//                     return CustomConfirmDialogResult.YES;
//                 }
//             }
//         ]
//     })
// }

export const confirmDialog = (title: string, textYes: string, textNo: string,
    content: string): any => {
    return custom({
        showTitle: title && title !== '',
        title: title,
        messageHtml: content,
        buttons: [
            {
                text: textNo,
                focusStateEnabled: false,
                useSubmitBehavior: false,
                type: 'default',
                stylingMode: 'outlined',
                onClick: () => {
                    return CustomConfirmDialogResult.NO;
                }
            },
            {
                text: textYes,
                useSubmitBehavior: false,
                focusStateEnabled: false,
                type: 'default',
                onClick: () => {
                    return CustomConfirmDialogResult.YES;
                }
            },
        ]
    });
};

export const showAlertsNGCResults = (alertList: NGCResult<any>[], title: string):any => {
    let htmlContent = '';
    alertList.forEach(ngcResult => {
        if (ngcResult.groupFunctionalCautions?.functionalCautions) {
            if (ngcResult.groupFunctionalCautions.id) {
                htmlContent += '<h4>' + ngcResult.groupFunctionalCautions.id + '</h4>';
            }
            htmlContent += '<ul>';
            ngcResult.groupFunctionalCautions.functionalCautions.forEach(caution => {
                htmlContent += '<li>' + caution.message + '</li>';
            });
            htmlContent += '</ul>';
        }
    });
    return alert(htmlContent, title);
};

export const showAlerts = (alerts: string, title: string): any => {
    let htmlContent = '';

    htmlContent += '<h4>' + alerts + '</h4>';

    return alert(htmlContent, title);
};
