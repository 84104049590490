<dx-popup [wrapperAttr]="{ id: 'idpopup', class:'columnChooser' }" [width]="260" [showTitle]="true" [height]="getHeight()"
    [(visible)]="columnChooserPopupVisible" [showCloseButton]="false" position="right" [shading]="false"
    [resizeEnabled]="true" (onResize)="resizePopup($event)" [closeOnOutsideClick]="true" (onHiding)="onHidingBinder()">
    <div *dxTemplate="let data of 'content'" class="columns-chooser-container">
        <app-extra-button-field [buttonsBefore]="datagridSearch.buttonsBefore" [(value)]="searchingWord"
            (ValueChanged)="onSearchingWordValueChanged($event)">
        </app-extra-button-field>
        <dx-scroll-view class="grid-state-scroll-view">
            <dx-list #columnChooser_list [dataSource]="columnChooserColumn" pageLoadMode="scrollBottom"
                [searchEnabled]="false" selectionMode="all" [(selectedItems)]="columnChooserSelected"
                selectAllMode="allPages" [showSelectionControls]="true" allowItemReordering="true" searchMode="contains"
                (onItemReordered)="itemReordered($event)">
            </dx-list>
        </dx-scroll-view>
        <div class="footer">
            <div class="undo">
                <dx-button (click)="onTableStateReset()" class="btn
                    btn-default undo-btn" type="default">
                    <span class="undo-title"> {{'GridState.restateTable' |
                        translate}}
                    </span>

                </dx-button>
            </div>
            <div class="save">
                <dx-button (click)="tableStateSave()" class="btn
                    btn-default save-btn" type="default">
                    <span class="save-title"> {{'GridState.save' | translate}}
                    </span>
                </dx-button>
            </div>
        </div>
    </div>

    <div class="col-chooser-title" *dxTemplate="let data of 'title'">
        <span> {{'GridState.chooseColumn' | translate}} </span>
    </div>
</dx-popup>
<dx-popup [wrapperAttr]="{ class: 'popup undo-popup' }" [width]="400" [height]="230" [dragEnabled]="true" [closeOnOutsideClick]="true"
    [(visible)]="confirmPopupUndo" [showTitle]="true" [showCloseButton]="false"
    [title]="'GridState.resetTitle' | translate">
    <div class="popup-body ">
        <span>
            {{'GridState.resetQuestion1' | translate}}
            {{'GridState.resetQuestion2' | translate}}
        </span>
    </div>
    <dx-button (click)="cancelTableStateReset()" class="btn btn-default
        btn-cancel" stylingMode="outlined" type="default">
        <span class="btn-cancel-title"> {{'GridState.cancel' | translate}}
        </span>
    </dx-button>
    <dx-button text="REINITIALISER" (click)="confirmTableStateReset()" class="btn btn-confirm" type="default">
        <span class="btn-confirm-title"> {{'GridState.reset' | translate}}
        </span>
    </dx-button>
</dx-popup>
