import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { PredefinedData } from '@app/shared/model/common/enum-predefined-data';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export enum AccountingCodeType { SALES, PURCHASE, CUSTOMER_ACCOUNTING_FAMILY}
export class AccountingCode extends BaseEntity2 {
    designation: string;
    analyticalCode: string;
    natureCode: string;
    visible: boolean;
    predefinedData: PredefinedData;
    constructor() {
        super();
        this.designation = '';
        this.analyticalCode = '';
        this.natureCode = '';
        this.visible = true;
        this.predefinedData = null;
    }
    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true}
            ],
            analyticalCode : [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true}
            ],
            natureCode : [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true}
            ],
        };
    }

    initValues(): Partial<AccountingCode> {
        return {
            visible: true
        };
    }
}

export class PurchaseCode extends AccountingCode {
}

export class SalesCode extends AccountingCode {
}

export class CustomerAccountingFamily extends AccountingCode {
}
