import { ArticleType, PurchaseCode, SalesCode, ArticleFamily, Supplier, Country, Advice, ArticleUnderFamily, CostGroup, WhyFree, Location, ArticleCriteriaValueSelected } from 'app/shared/model';
import { ZoneProtegee } from 'app/shared/model/article/zoneProtegee'
import { UniteArticle } from 'app/shared/model/article/uniteArticle';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { TVA } from '../livraison/TVA';
import { Designation4 } from './designation4';
import { Designation5 } from './designation5';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { ArticleImage } from './article-images';
import { GammaWineOperation } from '../customs/gamma-tab';
import { Product } from './product';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { StockLot } from '../stock/stockLot';
import { ApplicationMode } from '../base-entity1';
import { TaxTypeRight } from './taxTypeRight';
import { RegleEtiquette, RegleImpression, ReportType } from '../enum';
import { EtiquetteIdAndName } from '../Etiquette/Etiquette';
import { ArticleRegi } from './articleRegi';
import { TradeFamily } from '../trade-family';
import { Designation6 } from './designation6';

export const isArticlePassage = (article: { id: number; }): boolean => {
    return article && article.id === 1;
};
export class Article extends BaseEntity2 {
    etiquetteColisage: EtiquetteIdAndName;
    etiquetteProduit: EtiquetteIdAndName;
    typeEtiquetteColisage: RegleEtiquette;
    typeEtiquetteProduit: RegleEtiquette;
    ruleEtiquetteProduit: RegleImpression;
    ruleEtiquetteColisage: RegleImpression;
    etiquetteColisageReportType: ReportType;
    etiquetteProduitReportType: ReportType;
    priceEtiquette: number;
    designation1: string;
    designation2: string;
    designation3: string;
    designationRef4: Designation4;
    designationRef5: Designation5;
    designationRef6: Designation6;
    visible: boolean;
    code: string;
    type: ArticleType;
    barCode: string;
    byHowMuch: boolean;
    commercialDesignation: string;
    botanicName: string;
    complement: string;
    complementaryName: string;
    density: number;
    edi: boolean;
    endPurchaseDate: Date;
    financial: boolean;
    grossWeight: number;
    height: number;
    logisticComment: string;
    netWeight: number;
    origin: string;
    primeur: boolean;
    assemblyArea: boolean;
    bottleProcessArea: boolean;
    depotTransfertArea: boolean;
    diversArea: boolean;
    labellingBottleArea: boolean;
    purchaseArea: boolean;
    salesArea: boolean;
    saleValidityEndWeekNumber: number;
    saleValidityStartWeekNumber: number;
    seed: boolean;
    updateTelepurchase: boolean;
    updateTelesales: boolean;
    degreeAlcoholPure: number;
    degreePlato: number;
    exciseIncluded: boolean;
    socialSecurityStampIncluded: boolean;
    noLot: string;
    sugarRate: number;
    blockDiscountCostPrice: boolean;
    blockDiscountAdvancedCost: boolean;
    blockDiscountPersonalCost: boolean;
    freeArticle: false;
    marginRefPrice: number;
    pricingUnit: number;
    purchasePriceRate: number;
    destockingCoefficient: number;
    stocked: boolean;
    stockMini: number;
    totalized: boolean;
    purchaseAccountingSupplement: string;
    salesAccountingSupplement: string;
    assortmentNotLabelOnDetail: boolean;
    assortmentMode: boolean;
    assortmentPartnerOnDetail: boolean;
    assortmentStock: boolean;
    portEquivalence: number;
    purchaseCarryForwardQtyN: number;
    purchaseCarryForwardTurnover: number;
    purchaseQtyN: number;
    purchaseQtyN1: number;
    purchaseQtyN2: number;
    purchaseTurnoverN: number;
    purchaseTurnoverN1: number;
    purchaseTurnoverN2: number;
    salesCarryForwardQtyN: number;
    salesCarryForwardTurnover: number;
    salesQtyN: number;
    salesQtyN1: number;
    salesQtyN2: number;
    salesTurnoverN: number;
    salesTurnoverN1: number;
    salesTurnoverN2: number;
    gaugeMaxQty: number;
    generatedSchema: boolean;
    harvest: boolean;
    inputProduction: boolean;
    minEconomicQtyOfLaunch: number;
    multipleOfProduction: number;
    toBeProduced: boolean;
    designationXshop1: string;
    designationXshop2: string;
    designationXshop3: string;
    endDateSubscriptionXOL: Date;
    startDateSubscriptionXOL: Date;
    qrCodeKey: string;
    prestashopActive: boolean;
    prestashopProduct: boolean;
    purchaseCode: PurchaseCode;
    salesCode: SalesCode;
    family: ArticleFamily;
    underFamily: ArticleUnderFamily;
    mainSupplier: Supplier;
    originCountry: Country;
    owner: Supplier;
    replacementArticle: Article;
    taxTypeRight: TaxTypeRight;
    wineOperation: GammaWineOperation;
    costGroup: CostGroup;
    vat: TVA;
    whyFree: WhyFree;
    zoneProtegee: ZoneProtegee;
    destockingArticle: Article;
    defaultLocation: Location;
    defaultPickingLocation: Location;
    defaultPickingLocation2: Location;
    receptionLocation: Location;
    totalizerArticle: Article;
    advice1: Advice;
    advice2: Advice;
    advice3: Advice;
    advice4: Advice;
    advice5: Advice;
    mainSupplyOfProduction: Article;
    product: Product;
    stockLot: StockLot[];
    physicalStock: number;
    potentialStock: number;
    availableStock: number;
    articleImages: ArticleImage[];
    articleCriteriaSelected: ArticleCriteriaValueSelected[];
    drinkTaxIncluded: boolean;
    sugarDuties: number;
    sweetenerDuties: number;
    energizingDuties: number;
    softDrinkDuties: number;
    exciseDuties: number;
    socialSecurityStampDuties: number;
    // champ non mappé cote back pour afficher en consultation la couleur associée au product choisie pour l'article
    productColor: string;
    uniteArticle: UniteArticle;
    useInMsStore: boolean;
    useInMsOrder: boolean;
    articleRegi: ArticleRegi;
    hasMvtAfterCreated: boolean;
    hasCommission: boolean;
    hasBrokerage: boolean;
    commissionFamily: TradeFamily;
    brokerageFamily: TradeFamily;

    constructor() {
        super();
        this.designation1 = '';
        this.designation2 = '';
        this.designation3 = '';
        this.designationRef4 = null;
        this.designationRef5 = null;
        this.designationRef6 = null;
        this.visible = true;
        this.code = '';
        this.type = ArticleType.ARTICLE;
        this.barCode = '';
        this.byHowMuch = false;
        this.commercialDesignation = '';
        this.botanicName = '';
        this.complement = '';
        this.complementaryName = '';
        this.density = 0;
        this.edi = true;
        this.endPurchaseDate = new Date();
        this.financial = false;
        this.grossWeight = 0;
        this.height = 0;
        this.logisticComment = '';
        this.netWeight = 0;
        this.origin = '';
        this.primeur = false;
        this.assemblyArea = true;
        this.bottleProcessArea = true;
        this.depotTransfertArea = true;
        this.diversArea = true;
        this.labellingBottleArea = true;
        this.purchaseArea = true;
        this.salesArea = true;
        this.saleValidityEndWeekNumber = 0;
        this.saleValidityStartWeekNumber = 0;
        this.seed = false;
        this.updateTelepurchase = true;
        this.updateTelesales = true;
        this.degreeAlcoholPure = 0;
        this.degreePlato = 0;
        this.exciseIncluded = false;
        this.socialSecurityStampIncluded = false;
        this.noLot = '';
        this.sugarRate = 0;
        this.blockDiscountCostPrice = false;
        this.blockDiscountAdvancedCost = false;
        this.blockDiscountPersonalCost = false;
        this.freeArticle = false;
        this.marginRefPrice = 0;
        this.pricingUnit = 0;
        this.purchasePriceRate = 0;
        this.destockingCoefficient = 1;
        this.stocked = false;
        this.stockMini = 0;
        this.totalized = false;
        this.purchaseAccountingSupplement = '';
        this.salesAccountingSupplement = '';
        this.assortmentNotLabelOnDetail = false;
        this.assortmentMode = false;
        this.assortmentPartnerOnDetail = false;
        this.assortmentStock = false;
        this.portEquivalence = 0;
        this.purchaseCarryForwardQtyN = 0;
        this.purchaseCarryForwardTurnover = 0;
        this.purchaseQtyN = 0;
        this.purchaseQtyN1 = 0;
        this.purchaseQtyN2 = 0;
        this.purchaseTurnoverN = 0;
        this.purchaseTurnoverN1 = 0;
        this.purchaseTurnoverN2 = 0;
        this.salesCarryForwardQtyN = 0;
        this.salesCarryForwardTurnover = 0;
        this.salesQtyN = 0;
        this.salesQtyN1 = 0;
        this.salesQtyN2 = 0;
        this.salesTurnoverN = 0;
        this.salesTurnoverN1 = 0;
        this.salesTurnoverN2 = 0;
        this.gaugeMaxQty = 0;
        this.generatedSchema = false;
        this.harvest = false;
        this.inputProduction = false;
        this.minEconomicQtyOfLaunch = 0;
        this.multipleOfProduction = 0;
        this.toBeProduced = false;
        this.designationXshop1 = '';
        this.designationXshop2 = '';
        this.designationXshop3 = '';
        this.endDateSubscriptionXOL = new Date();
        this.startDateSubscriptionXOL = new Date();
        this.qrCodeKey = '';
        this.prestashopActive = false;
        this.prestashopProduct = false;
        this.physicalStock = null;
        this.potentialStock = null;
        this.availableStock = null;
        this.purchaseCode = null;
        this.salesCode = null;
        this.family = null;
        this.underFamily = null;
        this.mainSupplier = null;
        this.originCountry = null;
        this.owner = null;
        this.replacementArticle = null;
        this.costGroup = null;
        this.vat = null;
        this.whyFree = null;
        this.zoneProtegee = null;
        this.destockingArticle = null;
        this.defaultLocation = null;
        this.defaultPickingLocation = null;
        this.defaultPickingLocation2 = null;
        this.receptionLocation = null;
        this.totalizerArticle = null;
        this.advice1 = null;
        this.advice2 = null;
        this.advice3 = null;
        this.advice4 = null;
        this.advice5 = null;
        this.mainSupplyOfProduction = null;
        this.articleCriteriaSelected = null;
        this.articleImages = null;
        this.wineOperation = null;
        this.product = null;
        this.stockLot = null;
        this.taxTypeRight = null;
        this.drinkTaxIncluded = false;
        this.sugarDuties = null;
        this.sweetenerDuties = null;
        this.energizingDuties = null;
        this.softDrinkDuties = null;
        this.exciseDuties = null;
        this.socialSecurityStampDuties = null;
        this.productColor = null;
        this.uniteArticle = null;
        this.useInMsStore = false;
        this.useInMsOrder = false;
        this.etiquetteColisage = null
        this.etiquetteProduit = null;
        this.typeEtiquetteColisage = null;
        this.typeEtiquetteProduit = null;
        this.ruleEtiquetteColisage = null;
        this.ruleEtiquetteProduit = null;
        this.etiquetteColisageReportType = null;
        this.etiquetteProduitReportType = null;
        this.priceEtiquette = 0;
        this.articleRegi = null;
        this.hasMvtAfterCreated = false;
        this.hasCommission = false;
        this.hasBrokerage = false;
        this.commissionFamily = null;
        this.brokerageFamily = null;
    }

    validator(applicationMode: ApplicationMode): ValidationRulesByFieldName {
        const validators: ValidationRulesByFieldName = {
            code: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            type: [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            uniteArticle: [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            designation1: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 35, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            designation2: [
                { type: 'stringLength', max: 35, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            designation3: [
                { type: 'stringLength', max: 35, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            qrCodeKey: [
                { type: 'stringLength', max: 25, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            purchaseAccountingSupplement: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            salesAccountingSupplement: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            salesCode: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            barCode: [
                { type: 'stringLength', max: 14, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            commercialDesignation: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            complement: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            complementaryName: [
                { type: 'stringLength', max: 120, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            logisticComment: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            origin: [
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            noLot: [
                { type: 'stringLength', max: 20, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            vat: [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
        if (applicationMode === ApplicationMode.VITI) {
            validators['designationRef5'] = [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ];
            validators['taxTypeRight'] = [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ];
        }
        return validators;
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            {
                nameProp: 'articleRegi',
                objectInstance: new ArticleRegi()
            }
        ];
        specificFieldForConfscreenModel.excluded = ['dateUpdated', 'dateCreated', 'version'];
        return specificFieldForConfscreenModel;
    }
}

