import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { AppContextService, ScreenService } from '@app/core/services';
import { variablesKeys } from '@app/globalsParameter';
import { ViewChild } from '@angular/core';
import { AuthService } from '@app/core/services';
import { User } from '@app/shared/model';
import { PopupConfig } from '@app/shared/model/popupConfig';
import { PopupConfigService } from '@app/core/services/popupConfig.service';
import { of, Observable, Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import * as $ from 'jquery';
import { positionConfig } from 'devextreme/animation/position';
import { TranslateService } from '@ngx-translate/core';
import { ExtraButtonFieldComponent } from '../extra-button-field/extra-button-field.component';
import { SlidePanelModel } from '../slide-panel/slide-panel-model';


@Component({
    selector: 'ngc-next-dx-popup',
    templateUrl: './next-dx-popup.component.html',
    styleUrls: ['./next-dx-popup.component.scss'],
})
export class NextDxPopupComponent implements OnInit, OnDestroy {

    @Input() closeOnOutsideClick: boolean | Function;
    @Input() popupClass?: string;
    @Input() disabled: boolean;
    @Input() dragEnabled: boolean;
    @Input() fullScreen: boolean;
    @Input() height: number | Function;
    @Input() hint: string;
    @Input() maxHeight: number | Function | string;
    @Input() maxWidth: number | Function | string;
    @Input() minHeight: number | Function | string;
    @Input() minWidth: number | Function | string;
    @Input() position: positionConfig | Function | string = 'center';
    @Input() resizeEnabled: boolean;
    @Input() showCloseButton: boolean;
    @Input() showTitle: boolean;
    @Input() title: string;
    @Input() visible: boolean;
    @Input() width: number | Function;
    @Input() isPopupPrintVisible: boolean;
    @Input() isPopupMailVisible: boolean;
    @Input() isPopupLabelVisible: boolean;
    @Input() isPopupGrid = false;
    @Input() identifier: number;
    @Input() isPopupPrintTwoVisible = false;
    @Input() dropDownAdd = false;
    @Input() isPopupForEtiquette = false;
    @Input() shading = true;
    @Input() popupPrintType: string;
    @Input() filterButton: boolean;
    @Input() slidePanelOptions?: SlidePanelModel;
    @Input() advancedFilterConfigArray?: [];
    @Input() advancedFilter?: any;
    @Input() advancedFilterCopy?: any;

    @Output() onContentReady: EventEmitter<any> = new EventEmitter<any>();
    @Output() disposing: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() onHidden: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHiding: EventEmitter<any> = new EventEmitter<any>();
    @Output() onInitialized: EventEmitter<any> = new EventEmitter<any>();
    @Output() onOptionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() onResize: EventEmitter<any> = new EventEmitter<any>();
    @Output() onResizeEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() resizeStart: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() onShown: EventEmitter<any> = new EventEmitter<any>();
    @Output() titleRendered: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() closeOnBackButtonChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeOnOutsideClickChange: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() dragEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() fullScreenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() heightChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() hintChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() maxHeightChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() maxWidthChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() minHeightChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() minWidthChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() resizeEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showCloseButtonChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showTitleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() titleChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() widthChange: EventEmitter<number | Function | string> = new EventEmitter<number | Function | string>();
    @Output() clickMore: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() clickRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() columnChooser: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchingWordChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() manageHeightGrid: EventEmitter<any> = new EventEmitter<any>();
    @Output() popupContentHeightChanged: EventEmitter<number | Function> = new EventEmitter<number | Function>();
    @Output() validateFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(DxPopupComponent, { static: true }) dxPopUp: DxPopupComponent;
    @ViewChild('extraButton', { static: true }) extraButtonField: ExtraButtonFieldComponent;

    showFilterButton: boolean;
    baseConfig: { size: { width: number, height: number }, position: { x: number, y: number } };
    currentUser: User;
    public subscribers: Subscription[] = [];
    searchingWord: string;
    datagridSearch = {
        buttonsBefore:
            [
                {
                    name: 'loop',
                    class: 'buttonsBeforeLoop',
                    options: {
                        disabled: true,
                        stylingMode: 'text',
                        icon: 'search',
                        template: 'templateButtonBefore'
                    }
                }
            ],
        buttonsAfter: [
            {
                name: 'clearSearch',
                class: 'buttonsAfterSearch',
                options: {
                    focusStateEnabled: false,
                    hoverStateEnabled: false,
                    activeStateEnabled: false,
                    stylingMode: 'text',
                    icon: 'clear',
                    template: 'templateButtonAfter',
                    visible: false,
                    onClick: (): void => {
                        this.searchingWord = "";
                    }
                }
            }
        ]
    };
    dropDownGridItems = [
        { id: 1, value: 1, name: this.translateService.instant('data-grid.hint-columnchooser'), icon: 'column' },
        { id: 2, value: 2, name: this.translateService.instant('data-grid.hint-filterrow'), icon: 'filterInDropDown' },
    ];


    constructor(
        private authService: AuthService,
        private appContext: AppContextService,
        private popupConfigService: PopupConfigService,
        private screenService: ScreenService,
        private translateService: TranslateService
    ) {
        this.slidePanelOptions = new SlidePanelModel();
        this.subscribers.push(
            authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    this.currentUser = user;
                }
            )
        );
    }

    private get shouldBeFullScreen() {
        return this.screenService.sizes.handset || this.screenService.sizes['tablet-portrait'];
    }

    private fixPopupSize(): { width: boolean, height: boolean } {
        let widthIsGreaterThanScreen = false;
        let heightIsGreaterThanScreen = false;
        if (window.innerWidth <= this.width) {
            widthIsGreaterThanScreen = true;
            this.width = window.innerWidth - 40;
        }
        if (window.innerHeight <= this.height) {
            heightIsGreaterThanScreen = true;
            this.height = window.innerHeight - 40;
        }
        return { width: widthIsGreaterThanScreen, height: heightIsGreaterThanScreen };
    }

    ngOnInit(): void {
        if (this.filterButton) {
            this.datagridSearch.buttonsAfter.push(
                {
                    name: 'filterButton',
                    class: 'buttonsAfterFilter',
                    options: {
                        focusStateEnabled: false,
                        hoverStateEnabled: false,
                        activeStateEnabled: false,
                        stylingMode: 'text',
                        icon: 'filterSearchBar',
                        template: 'templateButtonAfter',
                        visible: true,
                        onClick: (): void => {
                            this.slidePanelOptions.isSlidePanelOpen = true;
                        }
                    }
                }
            )
        }

        if (!environment.production && typeof this.identifier === 'undefined') {
            throw new Error('next-dx-popup must have identifier');
        }
        if (typeof this.fullScreen === 'undefined') {
            this.fullScreen = this.shouldBeFullScreen;
        }

        // event when resize;
        this.subscribers.push(
            this.screenService.changed.subscribe(
                () => {
                    if (this.shouldBeFullScreen) {
                        this.fullScreen = true;
                        // this.height = 'auto';
                        // this.width = 'auto';
                    } else {
                        this.fullScreen = false;
                        let isGreaterThanScreen = { width: false, height: false };
                        // fix size and position
                        if (this.width && this.height) {
                            isGreaterThanScreen = this.fixPopupSize();
                        }
                        if (this.position && this.position['my'] && this.position['my'] === 'left top' && this.position['offset']) {
                            const offset = this.fixPopupOffset(isGreaterThanScreen);
                            if (this.position['offset'] !== offset) {
                                this.position = {
                                    my: 'left top', at: 'left top', of: window,
                                    offset: offset
                                };
                            }
                        }
                    }
                }
            )
        );
        if (this.popupClass == 'popupNotFavoris')
            this.shading = false;
    }

    ngOnDestroy(): void {
        this.subscribers.forEach(subsc => subsc.unsubscribe());
    }

    private fixPopupOffset(isGreaterThanScreen: { width: boolean, height: boolean }): { x: number, y: number } {
        const oldOffset = { x: this.position['offset'].x, y: this.position['offset'].y };
        const offset = { x: oldOffset.x, y: oldOffset.y };
        if (isGreaterThanScreen.width || oldOffset.x < 0) {
            offset.x = 20;
        } else {
            if ((oldOffset.x + this.width) > window.innerWidth) {
                const overtakingWidth: number = oldOffset.x + this.width - window.innerWidth;
                offset.x = oldOffset.x - overtakingWidth;
            }
        }
        if (isGreaterThanScreen.height || oldOffset.y < 0) {
            offset.y = 20;
        } else {
            if ((oldOffset.y + this.height) > window.innerHeight) {
                const overtakingHeight: number = oldOffset.y + this.height - window.innerHeight;
                offset.y = oldOffset.y - overtakingHeight;
            }
        }

        // Ne jamais mettre 0 dans x ou y car ça fait bugger le compo devExtreme car il considere que c'est pas défini
        // et du coup il prend la valeur de l'autre (ex x = 0 et y = 500, il va positionner à x = 500 et y = 500)
        if (offset.x === 0) {
            offset.x = 1;
        }
        if (offset.y === 0) {
            offset.y = 1;
        }
        return offset;
    }



    public closePopup(): void {
        this.visible = !this.visible;
        this.visibleChange.emit(this.visible);
    }

    private getPopupStoredConfig(): Observable<{ size: { width: number, height: number }, position: { x: number, y: number }, fromLocalStorage: boolean }> {
        const popupConfig = this.appContext.getPersisted(variablesKeys.POPUP_CONFIG);
        if (popupConfig && popupConfig[this.identifier]) {
            popupConfig[this.identifier]['fromLocalStorage'] = true;
            this.storePopupIsSyncWithDB(true);
            return of(popupConfig[this.identifier]);
        } else if (this.currentUser) {
            return this.popupConfigService.get(this.identifier);
        } else {
            return of(null);
        }
    }

    private storePopupIsSyncWithDB(syncWithDB: boolean) {
        let popupConfig = this.appContext.getPersisted(variablesKeys.POPUP_CONFIG);
        if (!popupConfig) {
            popupConfig = {};
        }
        if (!popupConfig[this.identifier]) {
            popupConfig[this.identifier] = {};
        }
        popupConfig[this.identifier]['syncWithDB'] = syncWithDB;
        this.appContext.setPersist(variablesKeys.POPUP_CONFIG, popupConfig);
    }

    private storePopupNewSize(size: { width: number, height: number }) {
        let popupConfig = this.appContext.getPersisted(variablesKeys.POPUP_CONFIG);
        if (!popupConfig) {
            popupConfig = {};
        }
        if (!popupConfig[this.identifier]) {
            popupConfig[this.identifier] = {};
        }
        popupConfig[this.identifier]['size'] = size;
        popupConfig[this.identifier]['syncWithDB'] = false;

        this.appContext.setPersist(variablesKeys.POPUP_CONFIG, popupConfig);

    }

    private storePopupNewPosition(position: { x: number, y: number }) {
        let popupConfig = this.appContext.getPersisted(variablesKeys.POPUP_CONFIG);
        if (!popupConfig) {
            popupConfig = {};
        }
        if (!popupConfig[this.identifier]) {
            popupConfig[this.identifier] = {};
        }
        if (popupConfig[this.identifier]['position']) {
            if ((position.x !== popupConfig[this.identifier]['position']['x']) || (position.y !== popupConfig[this.identifier]['position']['y'])) {
                popupConfig[this.identifier]['position'] = position;
                popupConfig[this.identifier]['syncWithDB'] = false;
                this.appContext.setPersist(variablesKeys.POPUP_CONFIG, popupConfig);
            } else {
                popupConfig[this.identifier]['syncWithDB'] = true;
            }
        } else {
            popupConfig[this.identifier]['position'] = position;
            popupConfig[this.identifier]['syncWithDB'] = false;
            this.appContext.setPersist(variablesKeys.POPUP_CONFIG, popupConfig);
        }
    }

    private matrixToTransformObj(matrix) {
        // this happens when there was no translate yet in CSS
        if (matrix === 'none') {
            matrix = 'matrix(0,0,0,0,0)';
        }
        const obj: { x: number, y: number } = {
            x: 0,
            y: 0
        };
        const values = matrix.match(/([-+]?[\d\\.]+)/g);
        obj.x = +values[0];
        obj.y = +values[1];
        return obj;
    }

    //#region outputBinder
    /**
       * A function that is executed when the widget's content is ready and each time the content is changed.
       */
    onContentReadyBinder(val: unknown): void {
        this.onContentReady.emit(val);
    }
    /**
     * A function that is executed before the widget is disposed of.
     */
    onDisposingBinder(val: unknown): void {
        this.disposing.emit(val);
    }
    /**
     * A function that is executed after the widget is hidden.
     */
    onHiddenBinder(val: unknown): void {
        this.onHidden.emit(val);
    }
    /**
     * A function that is executed before the widget is hidden.
     */
    onHidingBinder(val: { component: [] }): void {
        this.visibleChange.emit(this.visible);
        if (!this.shouldBeFullScreen) {
            const position = this.matrixToTransformObj((val.component['_$content'][0] as HTMLElement).style.getPropertyValue('transform'));
            this.storePopupNewPosition(position);

            const size: { width: number, height: number } = {
                width: Number.parseInt((val.component['_$content'][0] as HTMLElement).style.width.replace('px', ''), 500),
                height: Number.parseInt((val.component['_$content'][0] as HTMLElement).style.height.replace('px', ''), 500)
            };
            if (this.currentUser) {
                const popupConfig = this.appContext.getPersisted(variablesKeys.POPUP_CONFIG);
                if (!popupConfig[this.identifier]['syncWithDB']) {
                    const storeInBase: PopupConfig = new (PopupConfig);
                    storeInBase.popupId = this.identifier;
                    storeInBase.width = size.width || 0;
                    storeInBase.height = size.height || 0;
                    storeInBase.x = Math.round(position.x);
                    storeInBase.y = Math.round(position.y);
                    storeInBase.user = this.currentUser;
                    this.subscribers.push(
                        this.popupConfigService.post(storeInBase).subscribe(
                            {
                                error: err => console.error(err),
                            }
                        )
                    );
                }
                popupConfig[this.identifier]['syncWithDB'] = true;
                this.appContext.setPersist(variablesKeys.POPUP_CONFIG, popupConfig);
            }
        }
        this.onHiding.emit(val);

    }
    /**
     * A function that is executed only once, after the widget is initialized.
     */
    onInitializedBinder(val: unknown): void {
        this.onInitialized.emit(val);
    }
    /**
     * A function that is executed after a widget option is changed.
     */
    onOptionChangedBinder(val: unknown): void {
        this.onOptionChanged.emit(val);
    }
    /**
     * A function that is executed each time the widget is resized by one pixel.
     */
    onResizeBinder(val: unknown): void {
        this.onResize.emit(val);
    }


    /**
     * A function that is executed when resizing ends.
     */
    onResizeEndBinder(val: { component: [] }): void {
        // this.popupContentHeightChanged.emit(this.height);

        this.emitHeightPopupContent();

        if (!this.shouldBeFullScreen) {
            const size: { width: number, height: number } = {
                width: Number.parseInt((val.component['_$content'][0] as HTMLElement).style.width.replace('px', ''), 500),
                height: Number.parseInt((val.component['_$content'][0] as HTMLElement).style.height.replace('px', ''), 500)
            };
            this.storePopupNewSize(size);
        }
        this.onResizeEnd.emit(val);
    }
    /**
     * A function that is executed when resizing starts.
     */
    onResizeStartBinder(val: unknown): void {
        this.resizeStart.emit(val);
    }
    /**
     * A function that is executed before the widget is displayed.
     */
    onShowingBinder(val: unknown): void {
        this.onShown.emit(val);
    }

    /**
     * A function that is executed when the widget's title is rendered.
     */
    onTitleRenderedBinder(val: unknown): void {
        this.titleRendered.emit(val);
    }
    /**
     * A handler for the closeOnBackButtonChange event.
     */
    closeOnBackButtonChangeBinder(val: boolean): void {
        this.closeOnBackButtonChange.emit(val);
    }
    /**
     * A handler for the closeOnOutsideClickChange event.
     */
    closeOnOutsideClickChangeBinder(val: unknown): void {
        this.closeOnOutsideClickChange.emit(val);
    }
    /**
     * A handler for the disabledChange event.
     */
    disabledChangeBinder(val: boolean): void {
        this.disabledChange.emit(val);
    }
    /**
     * A handler for the dragEnabledChange event.
     */
    dragEnabledChangeBinder(val: boolean): void {
        this.dragEnabledChange.emit(val);
    }
    /**
     * A handler for the fullScreenChange event.
     */
    fullScreenChangeBinder(val: boolean): void {
        this.fullScreenChange.emit(val);
    }
    /**
     * A handler for the heightChange event.
     */
    heightChangeBinder(val: number | Function | string): void {
        this.heightChange.emit(val);
    }
    /**
     * A handler for the hintChange event.
     */
    hintChangeBinder(val: string): void {
        this.hintChange.emit(val);
    }
    /**
     * A handler for the maxHeightChange event.
     */
    maxHeightChangeBinder(val: number | Function | string): void {
        this.maxHeightChange.emit(val);
    }
    /**
     * A handler for the maxWidthChange event.
     */
    maxWidthChangeBinder(val: number | Function | string): void {
        this.maxWidthChange.emit(val);
    }
    /**
     * A handler for the minHeightChange event.
     */
    minHeightChangeBinder(val: number | Function | string): void {
        this.minHeightChange.emit(val);
    }
    /**
     * A handler for the minWidthChange event.
     */
    minWidthChangeBinder(val: number | Function | string): void {
        this.minWidthChange.emit(val);
    }
    /**
     * A handler for the resizeEnabledChange event.
     */
    resizeEnabledChangeBinder(val: boolean): void {
        this.resizeEnabledChange.emit(val);
    }
    /**
     * A handler for the showCloseButtonChange event.
     */
    showCloseButtonChangeBinder(val: boolean): void {
        this.showCloseButtonChange.emit(val);
    }
    /**
     * A handler for the showTitleChange event.
     */
    showTitleChangeBinder(val: boolean): void {
        this.showTitleChange.emit(val);
    }
    /**
     * A handler for the titleChange event.
     */
    titleChangeBinder(val: string): void {
        this.titleChange.emit(val);
    }
    /**
     * A handler for the visibleChange event.
     */
    visibleChangeBinder(val: boolean): void {
        this.visibleChange.emit(val);
    }
    /**
     * A handler for the widthChange event.
     */
    widthChangeBinder(val: number | Function | string): void {
        this.widthChange.emit(val);
    }
    //#endregion outputBinder

    onShownBinder(): void {
        this.emitHeightPopupContent();
    }

    // calcul de la taille et position du popup
    // à appeler depuis le composant avant de rendre visible le popup
    // car si on le fait de onShowing erreur "Expresseion changed..."
    // et si on le fait dans le onShown on voit le popup se deplacer à l'écran
    managePosition(): void {
        if (this.shouldBeFullScreen) {
            // this.height = 'auto';
            // this.width = 'auto';
            this.fullScreen = true;
        } else {
            this.fullScreen = false;
            this.subscribers.push(
                this.getPopupStoredConfig().subscribe(
                    popupConfig => {
                        if (popupConfig) {
                            let isGreaterThanScreen = { width: false, height: false };
                            if (popupConfig.size) {
                                this.height = popupConfig.size.height || this.height;
                                this.width = popupConfig.size.width || this.width;
                                isGreaterThanScreen = this.fixPopupSize();
                            }
                            if (popupConfig.position && (popupConfig.position.x || popupConfig.position.x === 0) && (popupConfig.position.y || popupConfig.position.y === 0)) {
                                // fix position when sizeIsGreaterThanScreen
                                this.position = {
                                    my: 'left top', at: 'left top', of: window,
                                    offset: { x: popupConfig.position.x, y: popupConfig.position.y }
                                };
                                // function use this.position as source ( must be after initialisation)
                                this.position.offset = this.fixPopupOffset(isGreaterThanScreen);
                            }
                            if (!popupConfig.fromLocalStorage) {
                                this.storePopupNewSize(popupConfig.size);
                                this.storePopupNewPosition(popupConfig.position);
                                this.storePopupIsSyncWithDB(true);
                            }
                        }
                    }
                )
            );
        }
    }

    emitHeightPopupContent(): void {
        // envoi de la taille du popup content, attention il faut fiare un setTimeout() pour récupérer
        // la hauteur au prochain cycle javascript sinon ce n'est pas la bonne
        setTimeout(() => {
            this.popupContentHeightChanged.emit($('#popup' + this.identifier).outerHeight());
        });
    }

    onSearchingWordValueChanged(event: { value: string }): void {
        if (event === null || event === undefined || event.value == undefined || event.value == '') {
            this.datagridSearch.buttonsAfter.forEach(
                btn => {
                    if (btn.name == "clearSearch") {
                        btn.options.visible = false;
                    }
                    if (btn.name == "filterButton") {
                        btn.options.visible = true;
                    }
                }
            );
        }
        else {
            this.datagridSearch.buttonsAfter.forEach(
                btn => {
                    if (btn.name == "clearSearch") {
                        btn.options.visible = true;
                    }
                    if (btn.name == "filterButton") {
                        btn.options.visible = false;
                    }
                }
            );
        }

        this.searchingWord = event.value;
        this.searchingWordChange.emit(event)
        return;
    }

    onDropDownGridItemsClicked(event: { itemData: { id: number } }): void {
        if (event.itemData.id === 1) {
            this.columnChooser.emit();
        } else if (event.itemData.id === 2) {
            this.manageHeightGrid.emit();
        }
    }

    onCloseClick(): void {
        this.visible = false;
    }

    onValidateSuccess(): void {
        this.validateFilter.emit(true);
    }




}
