import { Country } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { TVA } from '@app/shared/model/livraison/TVA';

export class CarrierCode extends BaseEntity2 {

 designation: string;
 address1: string;
 address2: string;
 zipCode: string;
 city: string;
 country: Country;
 phone: string;
 portablePhone: string;
 fax: string;
 email: string;
 interlocutor: string;
 tvaCode: TVA;
 preparationHour: Date;
 noExp: string;


 constructor() {
  super();
  this.designation = '';
  this.address1 = '';
  this.address2 = '';
  this.zipCode = '';
  this.city = '';
  this.country = null;
  this.phone = '';
  this.portablePhone = '';
  this.fax = '';
  this.email = '';
  this.interlocutor = '';
  this.tvaCode = null;
  this.preparationHour = new Date();
  this.noExp = '';
 }

}

