import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AppContextService } from '../services/app-context.service';
import { AuthService } from '../services';
import { variablesKeys, codeVersion, sessionLifeDuration } from '@app/globalsParameter';
import { AccessToken } from '../services/auth/models/access-token';
import { AppConfiguration } from '../services/application/app-configuration.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    private isRefreshingToken: boolean = false;
    private activeLanguage: string;

    constructor(
        private appContext: AppContextService,
        private authService: AuthService,
        public router: Router,
        private appConfiguration: AppConfiguration) {
        this.activeLanguage = appContext.getPersisted(variablesKeys.LOCALE);
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith('/')) { // used to ignore i18n or any internal request.
            return next.handle(request);
        }

        if(this.activeLanguage || this.activeLanguage == undefined || this.activeLanguage == null) {
            this.activeLanguage = this.appContext.getPersisted(variablesKeys.LOCALE) || 'fr' ;
        }
        // get access token and add it to reqeust header
        const accessToken = (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).accessToken;
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json; charset=utf-8',
                'codeVersion': codeVersion,
                'language': this.activeLanguage,
                'Content-Encoding': 'application/gzip',
            }
        });

        this.appContext.changeLoadingBarState(true);

        // check Session not expired and authentificated
        const knownUserWithRememberMeButExpiredToken = () => {
            return this.appContext.hasKey(variablesKeys.ACCESS_TOKEN) && this.appContext.hasKey(variablesKeys.USER) &&
                this.appContext.getPersisted(variablesKeys.REMEMBER_ME) != undefined && this.appContext.getPersisted(variablesKeys.REMEMBER_ME) == 1;
        };
        if (this.authService.isAuthenticated()) {
            const newAccessToken = this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) as AccessToken;
            newAccessToken.sessionExpiresAt = sessionLifeDuration + new Date().getTime();
            this.appContext.setPersist(variablesKeys.ACCESS_TOKEN, newAccessToken);
            return this.getNext(request, next);
        } else if (this.authService.sessionNotExpired() || knownUserWithRememberMeButExpiredToken()) {
            if (this.appConfiguration.loginMethod === 'owner') {
                if (request.url.indexOf('Api/refreshtokenowner') === -1 && !this.isRefreshingToken) {
                    this.isRefreshingToken = true;
                    return this.authService.refreshOwnerToken().pipe(switchMap((result) => {
                        return this.getRequestObservable(request, next);
                    }), finalize(() => {
                        this.isRefreshingToken = false;
                    }), catchError(val => {
                        this.authService.logout();
                        return throwError('Server error');
                    })
                    );
                } else if (request.url.indexOf('Api/refreshtokenowner') > -1) {
                    return this.getNext(request, next);
                }
            } else {
                return this.authService.checkLoginAuth0().pipe(switchMap((result) => {
                    return this.getNext(request, next);
                }));
            }
        } else {
            if (request.url.includes('/Api/')) { // on returne la reqsuete sauf si l'url est non securise(pas besoin d'envoyer un token)
                return this.getNext(request, next);
            }
            this.router.navigate(['/auth/login']);
            return of(null);
        }
    }

    getNext(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request);
    }

    getRequestObservable(request: HttpRequest<any>, next: HttpHandler) {
        // get access token and add it to reqeust header
        const accessToken = (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).accessToken;
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json; charset=utf-8',
                'codeVersion': codeVersion,
                'language': this.activeLanguage,
                'Content-Encoding': 'gzip',
            }
        });

        return this.getNext(request, next);
    }
}
