import { BaseEntity2 } from 'app/shared/model';
import { TVA } from 'app/shared/model/livraison/TVA';

export class TvaToDate extends BaseEntity2 {
    tva: TVA;
    dateBegin: Date;
    dateEnd: Date;
    rate: number;

    constructor() {
        super();
        this.tva = null;
        this.dateBegin = new Date();
        this.dateEnd = new Date();
        this.rate = 0;
    }

}

