import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnectedContentComponent } from './connected-content.component';
import { AuthGuard } from '@app/core/guard/auth.guard';
import { CanLoadGuard } from '@app/core/guard/auth.load.guard';
import { TranslationLoaderResolver } from '@app/core/resolvers/translate-loader.resolver';
import { RoleGuard } from '../core/guard/role.guard';
import { SuperAdminGuard } from '../core/guard/superAdmin.guard';
import { AdminGuard } from '../core/guard/admin.guard';
const routes: Routes = [
    {
        path: '',
        component: ConnectedContentComponent,
        canActivate: [AuthGuard],
        resolve: { model: TranslationLoaderResolver },
        children: [
            {
                path: '',
                loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
            },
            {
                path: 'superAdmin',
                loadChildren: () => import('./super-administrator/super-administrator.module').then(m => m.SuperAdministratorModule),
                canLoad: [CanLoadGuard],
                canActivate: [SuperAdminGuard]
            },
            {
                path: 'admin',
                loadChildren: () => import('./administrator/administrator.module').then(m => m.AdministratorModule),
                canLoad: [CanLoadGuard]
            },
            {
                path: 'shared',
                loadChildren: () => import('./shared-lazy/shared-lazy.module').then(m => m.SharedLazyModule),
                canLoad: [CanLoadGuard]
            },
            {
                path: 'company',
                loadChildren: () => import('./user/company/company.module').then(m => m.CompanyModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'parameters',
                loadChildren: () => import('./user/parameters/parameters.module').then(m => m.ParametersModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'article',
                loadChildren: () => import('./user/article/article.module').then(m => m.ArticleModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'stock',
                loadChildren: () => import('./user/stock/stock.module').then(m => m.StockModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'contact',
                loadChildren: () => import('./user/contact/contact.module').then(m => m.ContactModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'operation',
                loadChildren: () => import('./user/commercial-operation/commercial-operation.module').then(m => m.CommercialOperationModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'reglement',
                loadChildren: () => import('./user/reglement/reglement.module').then(m => m.ReglementModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'trackInvoices',
                loadChildren: () => import('./user/track-invoices/track-invoices.module').then(m => m.TrackInvoicesModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'commissionCourtage',
                loadChildren: () => import('./user/commission-courtage/commission-courtage.module').then(m => m.CommissionCourtageModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            },
            {
                path: 'basicRate',
                loadChildren: () => import('./user/basic-rate/basic-rate.module').then(m => m.BasicRateModule),
                canLoad: [CanLoadGuard],
                canActivate: [RoleGuard]
            }
        ]
    },
    // Si mauvaise URL alor redirection par default vers /
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ConnectedContentRoutingModule { }
