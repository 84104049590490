export * from './Widget';
export * from './WidgetBase';
export * from './WidgetBreaking';
export * from  './WidgetConfig';

export  enum WidgetType {
  INTERNAL,
  EXTERNAL
}

export enum EnumChartType {
  DOUGHNUT,
  BAR,
  PIE,
  KPI_BOX,
}

export enum ApplicationMode {
  COMMUN,
  HORTI,
  VITI
}


