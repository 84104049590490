import { Injectable } from '@angular/core';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { TypeArticleDesignation5 } from '@app/shared/model';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class TypeDesignation5Service extends DaoGridStateBaseService implements IDaoBaseService {
    public _list: Observable<TypeArticleDesignation5[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<TypeArticleDesignation5[]> {
        const url = this.appConfiguration.UrlsConfig.wsTypeDesignation5;
        return this.http.get<any[]>(url)
            .pipe(
                shareReplay(1)
            );
    }

    /**
    * Create a TypeArticleDesignation5
    * @param payload request body
    */
    post(payload: TypeArticleDesignation5): Observable<NGCResults<TypeArticleDesignation5>> {
        const url = this.appConfiguration.UrlsConfig.wsTypeDesignation5;
        return this.http.post<NGCResults<TypeArticleDesignation5>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a TypeArticleDesignation5
    * @param id TypeArticleDesignation5 id
    * @param payload TypeArticleDesignation5 data
    */
    put(id: number, payload: TypeArticleDesignation5): Observable<NGCResults<TypeArticleDesignation5>> {
        const url = this.appConfiguration.UrlsConfig.wsTypeDesignation5 + id;
        return this.http.put<NGCResults<TypeArticleDesignation5>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(): string {
        return 'article.Typedesignation5Grid';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsTypeDesignation5 + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
        this.router.navigate(['/parameters/article/type-designation5/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/type-designation5/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/type-designation5']);
    }
}
