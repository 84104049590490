// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseEntity {

}

export class BaseEntity1 implements IBaseEntity {
    dateCreated: Date;
    dateUpdated: Date;

    constructor() {
        this.dateCreated = new Date();
        this.dateUpdated = new Date();
    }
}
export enum ApplicationMode { COMMUN, HORTI, VITI }
