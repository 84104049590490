import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CompanyType, Currency } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from './application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService extends DaoGridStateBaseService implements IDaoBaseService  {
    private _list: Observable<Currency[]> = null;

    constructor(
        private http: HttpClient, 
        private router: Router, 
        private translate: TranslateService,
        private appConfiguration: AppConfiguration) {
        super(http, translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Currency[]> {
        const url = this.appConfiguration.UrlsConfig.wsCurrency;
        if (!this._list) {
            this._list = this.http.get<Currency[]>(url)
                .pipe(
                    shareReplay(1)
                )
                ;
        }
        return this._list;
    }

    
    get(id: number): Observable<Currency> {
        const url = this.appConfiguration.UrlsConfig.wsCurrency + id;
        return this.http.get<Currency>(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    /**
    * Create a location
    * @param payload request body
    */
    post(payload: Currency): Observable<NGCResults<Currency>> {
        const url = this.appConfiguration.UrlsConfig.wsCurrency;
        return this.http.post<NGCResults<Currency>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a location
    * @param id location code
    * @param payload location data
    */
    put(id: string, payload: Currency): Observable<NGCResults<Currency>> {
        const url = this.appConfiguration.UrlsConfig.wsCurrency + id;
        return this.http.put<NGCResults<Currency>>(url, payload).clearListCache(this.clearListTablesCache);;

    }



    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsCurrency;
        return this.http.delete(url + id);
    }

    navigateToDetail(id: string) {
        return this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/currency/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/currency/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/currency']);
    }
}
