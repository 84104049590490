import { Article, Packaging, } from '@app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { checkEan } from '@app/utils';
import { RoundingRulePackaging } from '..';
import { PackagingArticleImage } from '@app/shared/model/packaging/packaging_article_image';

export class PackagingArticle extends BaseEntity2 {

    isDefault: boolean;
    packagingFinal: Packaging;
    GTINPackagingFinal: number;
    qtyCrossToPackagingFinal: number;
    packaging1: Packaging;
    GTINPackaging1: number;
    qtyCrossToPackaging1: number;
    article: Article;
    packagingArticleImage: PackagingArticleImage;
    roundingRulePackaging: RoundingRulePackaging;
    nomberlayer: number;
    PCB: number;

    // Ces données ont été provisoirement mises  en commentaire see: https://projects.zoho.com/portal/lagraineinformatique#taskdetail/588742000003648009/588742000006832055/588742000006941459
    // packaging: Packaging;
    // quantityPackaging: number;
    // privilegiedPackaging1: Packaging;
    // quantityPrivilegiedPackaging1: number;
    // privilegiedPackaging2: Packaging;
    // quantityPrivilegiedPackaging2: number;
    // privilegiedPackaging3: Packaging;
    // quantityPrivilegiedPackaging3: number;
    // privilegiedPackaging4: Packaging;
    // quantityPrivilegiedPackaging4: number;
    // isDefault: boolean;
    // noOrder: number;
    // priority: boolean;
    // GTIN_Standard: string;
    // GTIN_NON_Standard: string;
    // updateLotStock: boolean;

    constructor() {
        super();
        this.isDefault = false;
        this.packagingFinal = null;
        this.GTINPackagingFinal = 0;
        this.qtyCrossToPackagingFinal = 0;
        this.packaging1 = null;
        this.GTINPackaging1 = 0;
        this.qtyCrossToPackaging1 = 0;
        this.article = null;
        this.packagingArticleImage = null;
        this.roundingRulePackaging = RoundingRulePackaging.NO_RULE;
        this.nomberlayer = 0;
        this.PCB = 0;
        // Ces données ont été provisoirement mises  en commentaire see: https://projects.zoho.com/portal/lagraineinformatique#taskdetail/588742000003648009/588742000006832055/588742000006941459
        // this.packaging = null;
        // this.quantityPackaging = 0;
        // this.privilegiedPackaging1 = null;
        // this.quantityPrivilegiedPackaging1 = 0;
        // this.privilegiedPackaging2 = null;
        // this.quantityPrivilegiedPackaging2 = 0;
        // this.privilegiedPackaging3 = null;
        // this.quantityPrivilegiedPackaging3 = 0;
        // this.privilegiedPackaging4 = null;
        // this.quantityPrivilegiedPackaging4 = 0;
        // this.isDefault = false;
        // this.noOrder = 0;
        // this.priority = false;
        // this.GTIN_Standard = '';
        // this.GTIN_NON_Standard = '';
        // this.updateLotStock = false;
    }

    validator(): ValidationRulesByFieldName {
        return {
            article: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            packagingFinal: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            qtyCrossToPackagingFinal: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            roundingRulePackaging: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            isDefault: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }



    initValues(): Partial<PackagingArticle> {
        return {
            PCB: 0,
            //priority: false
        };
    }
}
