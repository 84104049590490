import { BaseEntity2 } from './base-entity2';
import { ValidationRulesByFieldName } from '../modules/devextreme/dx-form/models';
import { Civility } from './company';
import { Country } from './country';
import { SpecificFieldForConfscreenModel } from './admin/SpecificFieldForConfscreenModel';

export class PostalAddress extends BaseEntity2 {
    name1: string;
    name2: string;
    socialReason: string;
    zipCode: string;
    address1: string;
    address2: string;
    address3: string;
    civility: Civility;
    country: Country;
    city: string;

    constructor() {
        super();
        this.name1 = '';
        this.name2 = '';
        this.socialReason = '';
        this.zipCode = '';
        this.address1 = '';
        this.address2 = '';
        this.address3 = '';
        this.civility = null;
        this.country = null;
        this.city = '';

    }

    validator(): ValidationRulesByFieldName {
        return {
            name1: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            address1: [
                { type: 'stringLength', max: 38, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            address2: [
                { type: 'stringLength', max: 38, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            address3: [
                { type: 'stringLength', max: 38, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            city: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            name2: [
                { type: 'stringLength', max: 15, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            socialReason: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            zipCode: [
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.excluded = ['dateUpdated', 'dateCreated', 'version', 'id'];
        return specificFieldForConfscreenModel;
    }
}
