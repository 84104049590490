import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { GenericCriteria } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';


@Injectable({
    providedIn: 'root'
})
export class ArticleCriteriaService extends DaoGridStateBaseService implements IDaoBaseService {
    public _list: Observable<GenericCriteria[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration) {
        super(http, _translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<GenericCriteria[]> {
        const url = this.appConfiguration.UrlsConfig.wsArticleCriteria;
        if (!this._list) {
            this._list = this.http.get<GenericCriteria[]>(url)
                .pipe(
                    map((criteriaArray) => {
                        return criteriaArray.map(criteria => {
                            criteria.criteriaValues.map(value => {
                                value.criteriaId = criteria.id;
                                return value;
                            });
                            return criteria;
                        });
                    }),
                    shareReplay(1)
                );
        }
        return this._list;
    }

    post(payload: any): Observable<NGCResults<GenericCriteria>> {
        const url = this.appConfiguration.UrlsConfig.wsArticleCriteria;
        return this.http.post<NGCResults<GenericCriteria>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    put(code: string, payload: any): Observable<NGCResults<GenericCriteria>> {
        const url = this.appConfiguration.UrlsConfig.wsArticleCriteria + '/' + code;
        return this.http.put<NGCResults<GenericCriteria>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticleCriteria + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: any) {
        this.router.navigate(['/parameters/article/articleCriteria/detail', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/articleCriteria/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/articleCriteria']);
    }

}
