import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
// import { WelcomeComponent } from './welcome/welcome.component';
import { AppComponent } from './app.component';
// import { UserModule } from './user/user.module';
import { CoreModule } from './core/core.module';
import { ConnectedContentModule } from './connected-content/connected-content.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfiguration } from '@app/core/services/application/app-configuration.service';


declare function require(url: string);
require('@app/utils/libraryExtension');


import DxThemes from 'devextreme/ui/themes';


// Fonction nécessaire pour charger le module de traduction
const  HttpLoaderFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http);
};

@NgModule({
    declarations: [
        AppComponent,
        // WelcomeComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        ConnectedContentModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    providers: [
        Title,
        {
            provide: APP_INITIALIZER,
            useFactory: AppConfigurationFactory,
            deps: [AppConfiguration,HttpClient],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        DxThemes.current(window.localStorage.getItem('dx-theme') || 'generic.light');
    }
}
export function AppConfigurationFactory(appConfig: AppConfiguration) {
    return () => appConfig.ensureInit();
}
