import { IBaseEntity } from '../base-entity1';

export class SpecificFieldForConfscreenModel {
    subForm: { nameProp: string, objectInstance: IBaseEntity }[] = [];
    list: string[] = [];
    excluded: string[] = [];
    groupsField: { [key: string]: string[] } = {};


}
