import { Injectable } from '@angular/core';
import { Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  opened = true;
  openMode: 'shrink' | 'overlap' = 'shrink';
  revealMode: 'expand' | 'slide' = 'expand';
  minSize = 0;
  shaderEnabled = false;

  _openedState = new Subject<boolean>();
  openedState$ = this._openedState.asObservable();

  constructor() { }

}
