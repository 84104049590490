import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { SidenavComponent } from '@app/connected-content/widgets/sidenav/sidenav.component';
import { User, UserType } from '@app/shared/model/user';
import { CompanyType } from '@app/shared/model/company';
import { DxDrawerComponent } from 'devextreme-angular/ui/drawer';
import { SidebarService, ScreenService } from '@app/core/services';
import { SignalRService } from '@app/core/services/signal-r/signalR.service';
import notify from 'devextreme/ui/notify';
import * as globalsParameter from '@app/globalsParameter';
import { Subscription } from 'rxjs';
import { TypeSignalR } from '@app/shared/model';

@Component({
    selector: 'app-connected-content',
    templateUrl: './connected-content.component.html',
    styleUrls: ['./connected-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConnectedContentComponent implements OnInit, OnDestroy {


    @ViewChild(DxDrawerComponent, { static: false }) drawer: DxDrawerComponent;
    @ViewChild(SidenavComponent, {static: false}) sidenav: SidenavComponent;

    currentUser: User;
    UserType = UserType;
    User = User;
    CompanyType = CompanyType;
    photo: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private screen: ScreenService,
        public sidebarService: SidebarService,
        private signalRService: SignalRService
    ) {
        this.signalRService.connect().then(() => {
            this.subscribeToEvents();
        });
    }

    ngOnInit(): void {
        this.sidebarService.opened = this.screen.sizes['screen-large'];
        this.updateDrawer();
        this.subscriptions.push(
            this.screen.changed.subscribe(
                () => this.updateDrawer()
            )
        );
    }

    onSideNavToggleState() {
        this.sidebarService.opened = !this.sidebarService.opened;
        this.sidebarService._openedState.next(this.sidebarService.opened);
    }

    updateDrawer() {
        const isXSmall = this.screen.sizes['screen-x-small'];
        const isLarge = this.screen.sizes['screen-large'];

        this.sidebarService.openMode = isLarge ? 'shrink' : 'overlap';
        this.sidebarService.revealMode = isXSmall ? 'slide' : 'expand';
        this.sidebarService.minSize = isXSmall ? 0 : 65;
        this.sidebarService.shaderEnabled = !isLarge;
        this.sidebarService.opened = !isLarge ? false : true;
    }
    closeOnOutsideClick(event: any) {
        if (!this.sidebarService.opened === event) {
            this.sidebarService.opened = event;
        }
    }

    private subscribeToEvents(): void {
        this.subscriptions.push(
            this.signalRService.messageReceived.subscribe(
                (message: any) => {
                    if (!this.isJson(message)) {
                        console.log('messageReceived signalR', message);
                        if (message.url !== undefined)
                            window.open(message.url, "_blank");
                        else
                            notify(message, 'success', globalsParameter.notifySuccessDelay);
                    } else {
                        const result = JSON.parse(message);
                        if (result.regiType == TypeSignalR.ARTICLE_REGI_FAILED) {
                            this.sidenav.ngOnInit();
                        }
                    }
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }

    isJson(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
