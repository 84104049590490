import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { BasicRate } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from '../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';


@Injectable({
    providedIn: 'root'
})
export class BasicRateService extends DaoGridStateBaseService implements IDaoBaseService {
    private _list: Observable<BasicRate[]> = null;

    constructor(private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);

    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<BasicRate[]> {
        const url = this.appConfiguration.UrlsConfig.wsBasicRate;
        if (!this._list) {
            this._list = this.http.get<BasicRate[]>(url)
                .pipe(
                    shareReplay(1)
                )
                ;
        }
        return this._list;
    }

    get(id: number): Observable<BasicRate> {
        const url = this.appConfiguration.UrlsConfig.wsBasicRate + id;
        return this.http.get<BasicRate>(url);
    }
    /**
    * Create a BasicRate
    * @param payload request body
    */
    post(payload: BasicRate): Observable<NGCResults<BasicRate>> {
        const url = this.appConfiguration.UrlsConfig.wsBasicRate;
        return this.http.post<NGCResults<BasicRate>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a BasicRate
    * @param id BasicRate id
    * @param payload BasicRate data
    */
    put(id: number, payload: BasicRate): Observable<NGCResults<BasicRate>> {
        const url = this.appConfiguration.UrlsConfig.wsBasicRate + id;
        return this.http.put<NGCResults<BasicRate>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (): void => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<unknown> {

        const url: string = this.appConfiguration.UrlsConfig.wsBasicRate;
        return this.http.delete(url + id);
    }

    navigateToDetail(id: number): Promise<boolean> {
        return this.router.navigate(['/basicRate/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/basicRate/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/basicRate']);
    }

    navigateToArticleByBasicRate(id: number, title: string): void {
        this.router.navigate(['/basicRate/' + id, {title: title}])
    }
}

