export const environment = {
    production: false,
    environmentName: 'preprod',
    configName: 'preprod',


    loginMethod: 'owner', // 'auth0' || 'owner'. Defaults to 'owner'.,

    auth0: {
        clientID: 'nfqnEexp2TUcazAhmLFk4TZVEdxenfdw',
        domain: 'nextgc.eu.auth0.com',
        responseType: 'token id_token',
        audience: `https://serviceuser.demo.lginextgc.com`,
        redirectUri: 'http://demo.lginextgc.com/auth/login-callback',
        scope: 'openid profile email'
    }
};
