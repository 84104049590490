import { AfterViewInit, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FooterInfo } from './models/footer-info';

@Component({
    selector: 'app-data-grid-footer',
    templateUrl: './data-grid-footer.component.html',
    styleUrls: ['./data-grid-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridFooterComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
    public set footerInfo(v: FooterInfo) {
        this._footerInfo = v;
    }

    public get footerInfo(): FooterInfo {
        return this._footerInfo;
    }

    _footerInfo = new FooterInfo;

    @Input() navigationPreviousButtonDisabled: boolean = false;
    @Input() navigationNextButtonDisabled: boolean;
    @Input() numberOfPages: number;
    @Input() currentPage: number;
    @Input() pageSize: number;

    @Output() changePageSize: EventEmitter<any> = new EventEmitter<any>();
    @Output() navigateToPreviousPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() navigateToNextPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() navigateToPage: EventEmitter<any> = new EventEmitter<any>();

    buttons = [];
    activeIndex: number = 0;
    totalpages: number = 12;
    constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.buttons = [];
        for (let i = 0; i < this.numberOfPages; i++) {
            if (i + 1 < 10) {
                this.buttons.push({ text: '0' + (i + 1), value: i });
            } else {
                this.buttons.push({ text: i + 1, value: i });
            }
        }
        if (changes.currentPage) {
            this.activeIndex = changes.currentPage.currentValue;
            this.currentPage = changes.currentPage.currentValue;
        }
        if (changes.navigationNextButtonDisabled) {
            this.navigationNextButtonDisabled = changes.navigationNextButtonDisabled.currentValue;
        }
        if (changes.numberOfPages) {
            if (changes.numberOfPages.currentValue === 1) {
                this.navigationNextButtonDisabled = true;
                this.navigationPreviousButtonDisabled = true;
            } else if (changes.numberOfPages.currentValue === this.currentPage) {
                this.navigationNextButtonDisabled = true;
                this.navigationPreviousButtonDisabled = false;
            } else if (changes.numberOfPages.currentValue > this.currentPage && this.currentPage !== 0) {
                this.navigationNextButtonDisabled = false;
                this.navigationPreviousButtonDisabled = false;
            } else if (changes.numberOfPages.currentValue > this.currentPage && this.currentPage === 0) {
                this.navigationNextButtonDisabled = false;
                this.navigationPreviousButtonDisabled = true;
            } else if (changes.numberOfPages.currentValue < this.currentPage) {
                this.navigationNextButtonDisabled = true;
                this.navigationPreviousButtonDisabled = false;
            }
        }
    }

    ngAfterViewInit(): void {
       
    }

    changeSize(event) {
        setTimeout(() => {
            this.changePageSize.emit(event.value);
            this.buttons = [];
            for (let i = 0; i < this.numberOfPages; i++) {
                if (i + 1 < 10) {
                    this.buttons.push({ text: '0' + (i + 1), value: i });
                } else {
                    this.buttons.push({ text: i + 1, value: i });
                }
            }
            this.activeIndex = this.currentPage;
            this.cdr.detectChanges();
        });
    }

    goToPreviousPage() {
        this.navigateToPreviousPage.emit();
        this.activeIndex = this.currentPage;
    }

    goToNextPage() {
        this.navigateToNextPage.emit();
        this.activeIndex = this.currentPage;
    }

    goToPage(pageIndex) {
        this.navigateToPage.emit(pageIndex);
        this.activeIndex = pageIndex;
    }

    ngOnInit() {
    }

}
