import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '../modules/devextreme/dx-form/models';
import { ApplicationMode } from './base-entity1';
import { User } from './user';

export enum ReportCategory {
    CUSTOMER_LIST = 0,
    CUSTOMER_DETAILS,
    SUPPLIER_LIST,
    SUPPLIER_DETAILS,
    ARTICLE_LIST,
    ARTICLE_DETAILS,
    CUSTOMER_OPECOM_LIST,
    CUSTOMER_OPECOM_DETAILS,
    SUPPLIER_OPECOM_LIST,
    SUPPLIER_OPECOM_DETAILS,
    STOCK_MVT_LIST,
    STOCK_LOT_LIST,
    STOCK_DETAILS,
    ALL
}

export class ReportFavoris {
    id: number;
    reportPersoFavoris: ReportPerso;
    reportBaseFavoris: number;
    user: User;
}

export enum ReportState { INACTIF, ACTIF }

export class Report extends BaseEntity2 {
    idOrigine: number;
    idOriginePerso: number;
    category: ReportCategory;
    name: string;
    labelNumber: number;
    state: ReportState;
    editingCounter: number;
    favoris: boolean;
    subReport: boolean;

    constructor() {
        super();
        this.idOrigine = 0;
        this.idOriginePerso = 0;
        this.category = ReportCategory.ARTICLE_DETAILS;
        this.name = '';
        this.labelNumber = 0;
        this.state = ReportState.ACTIF;
        this.editingCounter = 0;
        this.favoris = false;
        this.subReport = false;
    }

    public static getReportState(value: ReportState): string {
        let wording: string;
        if (value === ReportState.INACTIF) {
            wording = 'Bloqué';
        } else if (value === ReportState.ACTIF) {
            wording = 'Autorisé';
        }
        return wording;
    }

    validator(): ValidationRulesByFieldName {
        return {
            category : [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            name : [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            state : [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            applicationMode : [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true },
            ],
        };
    }
}

export class ReportBase extends Report {
    applicationMode: ApplicationMode;
    constructor() {
        super();
        this.applicationMode = ApplicationMode.COMMUN;
    }
}

export class ReportPerso extends Report {
    idOrigine: number;
    idOriginePerso: number;
    constructor() {
        super();
        this.idOrigine = 0;
        this.idOriginePerso = 0;
    }
}
