import { Injectable } from '@angular/core';
import { IDaoBaseService } from '@app/shared/interface';
import { CompanyType } from '@app/shared/model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyAddressViewModel } from '@app/connected-content/user/company/company/address/address-view-model';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyAddressService extends DaoGridStateBaseService implements IDaoBaseService {
    trisField = null;
    trisStrategie = 'asc';
    beginning: number = 0;
    numberOfTuples: string = '1';
    idList: number[] = [];
    params: DataGridFiltersParam = new DataGridFiltersParam();

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    getAll?(companyType: CompanyType, idCompany?: number): Observable<CompanyAddressViewModel[]> {
        let url: string;
        url = this.appConfiguration.UrlsConfig.wsCompanyAddress + 'getListAddressCompany/' + companyType;

        if (typeof (idCompany) !== 'undefined') {
            url += '/' + idCompany;
        }
        // if (!this[companyType]) {
        // this[companyType] =
        return this.http.get<CompanyAddressViewModel[]>(url);
        // }
        // return this[companyType];
    }

    post(payload: CompanyAddressViewModel, companyType: CompanyType): Observable<NGCResults<CompanyAddressViewModel>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyAddress + companyType;
        return this.http.post<NGCResults<CompanyAddressViewModel>>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    /**
     * retourne 0 si aucun address
     * retourne l'id du address si 1 seul existe
     * retourne null si plusieurs address
     */
    getFirstAddressIdByCompany(companyType?: CompanyType, companyId?: number): Observable<number> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompanyAddress + 'getFirstAddressIdByCompany/' + companyType + '/' + companyId;
        return this.http.get<number>(url).clearListCache(this.clearListTablesCache, companyType);
    }

    associate(companyType: CompanyType, idCompany: number, id: number): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyAddress + 'association/' + companyType + '/' + idCompany + '/' + id;
        return this.http.post<any>(url, null);
    }

    put(id: number, payload: CompanyAddressViewModel, companyType: CompanyType): Observable<NGCResults<CompanyAddressViewModel>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyAddress + companyType + '/' + id;
        return this.http.put<NGCResults<CompanyAddressViewModel>>(url, payload);
    }

    delete(id: number, companyType: CompanyType, idCompany: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompanyAddress + companyType + '/' + idCompany + '/' + id;
        return this.http.delete(url);
    }

    // TODO where is address id ?
    navigateToDetail(id: number, companyType: CompanyType, idCompany: any) {
        this.router.navigate(['/company/addresses/' + companyType + '/' + idCompany + '/address-detail/' + id]);
    }

    navigateToCreatePage(companyType: CompanyType, idCompany: any) {
        this.router.navigate(['/company/addresses/' + companyType + '/' + idCompany + '/address-create/']);
    }

    getGridTitle?(...params: any[]): string {
        throw new Error('Method not implemented.');
    }

    navigateToList(companyType: CompanyType, companyId: number): void {
        this.router.navigate(['/company/addresses/' + companyType + '/' + companyId + '/list']);
    }

    getByFilter(companyType: CompanyType, idCompany: number): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCompanyAddress + 'getToAssociate/' + companyType + '/' + idCompany + '/' + this.beginning + '/' + this.numberOfTuples + '/' + this.trisField + '/' + this.trisStrategie;
        return this.http.post(serviceUrl, this.params);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType: CompanyType) => {
        this[companyType] = null;
    }

}
