import {
    CommercialOperation, SupplierCommercialOperationRepresentative, SupplierCommercialOperationHistory, SupplierCommercialOperationLine,
    SupplierCommercialOperationPricing, SupplierCommercialOperationLogistic, SupplierCommercialOperationVat, SupplierCommercialOperationLabel, SupplierCommercialOperationInvoicing, SupplierCommercialOperationInfo
} from '..';
import { Supplier } from '../..';



export class SupplierCommercialOperation extends CommercialOperation {
    company: Supplier;
    invoicing: SupplierCommercialOperationInvoicing;
    representative: SupplierCommercialOperationRepresentative;
    histories: SupplierCommercialOperationHistory[];
    lines: SupplierCommercialOperationLine[];
    pricing: SupplierCommercialOperationPricing;
    logistic: SupplierCommercialOperationLogistic;
    opVat: SupplierCommercialOperationVat;
    label: SupplierCommercialOperationLabel;
    info: SupplierCommercialOperationInfo;
    constructor() {
        super();
        this.company = null;
        this.invoicing = null;
        this.representative = null;
        this.histories = [];
        this.lines = [];
        this.pricing = null;
        this.logistic = null;
        this.opVat = null;
        this.label = null;
        this.info = null;

    }
}

