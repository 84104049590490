/** MS [16/06/2021]
 *  L'ancienne méthode a un problème lors du clonage d'un array.
 *  Elle change le type Array => Object donc la méthode find ne marche pas pour les arrays
 *  On a trouvé cette solution, c'est de simplement :
 *  1 - Convertir l'objet en string
 *  2 - Le reconvertir on objet 
 *  => Est de là l'objet à une nouvelle référence et le type Array n'est pas changé
 *  Autres solutions en cas déchou de celle-ci ;
 *  1 - node-package : just-clone
 *  => Méthode d'import : declare function require(url: string); const clone = require('just-clone');
 *  2 - node-package : lodash 
 */
export function cloneObject(obj) {
    // const clone = {};
    // for (const i in obj) {
    //     if (obj[i] != null && typeof (obj[i]) === 'object') {
    //         clone[i] = cloneObject(obj[i]);
    //         }
    //         clone[i] = cloneObject(obj[i]);
    //     } else {
    //         clone[i] = obj[i];
    //     }
    // }
    // return clone;
    return JSON.parse(JSON.stringify(obj));
}
