import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IDaoBaseService } from '@app/shared/interface';
import { IBaseEntity } from '@app/shared/model';
import { Module } from '@app/shared/model/admin/module';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { DetailSet } from '@app/shared/viewModel/detailSet';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { DaoGridStateBaseService } from '..';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class ModuleService extends DaoGridStateBaseService implements IDaoBaseService {
    private _list: Observable<Module[]> = null;
    public moduleBehaviourSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration

    ) {
        super(http, _translate, appConfiguration);
    }

    getAll(): Observable<Module[]> {
        const url = this.appConfiguration.UrlsConfig.wsModule;
        return this.http.get<Module[]>(url)
            .pipe(
                shareReplay(1)
            );
    }

    put(id: number, payload: Module): Observable<NGCResults<Module>> {
        const url = this.appConfiguration.UrlsConfig.wsModule + id;
        if (payload.name === 'Mobilstore' &&  payload.actif === 1) {
            this.moduleBehaviourSubject.next(true);
        } else if (payload.name === 'Mobilstore' &&  payload.actif === 0) {
            this.moduleBehaviourSubject.next(false);
        }
        return this.http.put<NGCResults<Module>>(url, payload);
    }

    clearListTablesCache?(): void {
        this._list = null;
    }

    //#region "Méthodes non implémentées"
    get?(id: any, ...params: any[]): Observable<IBaseEntity> {
        throw new Error('Method not implemented.');
    }
    getByFilter?(...params: any[]): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getForDetail?(id: any, ...params: any[]): Observable<DetailSet<IBaseEntity>> {
        throw new Error('Method not implemented.');
    }
    getForNavigation?(idList: number[], ...params: any[]): Observable<IBaseEntity[]> {
        throw new Error('Method not implemented.');
    }
    post(payload: IBaseEntity, ...params: any[]): Observable<NGCResults<IBaseEntity>> {
        throw new Error('Method not implemented.');
    }
    delete(id: any, ...params: any[]): Observable<any> {
        throw new Error('Method not implemented.');
    }
    deleteAll?(idList: string[]): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getGridTitle?(...params: any[]): string {
        throw new Error('Method not implemented.');
    }
    navigateToDetail(id: string | number, ...params: any[]): void {
        throw new Error('Method not implemented.');
    }
    navigateToCreatePage(...params: any[]): void {
        throw new Error('Method not implemented.');
    }
    navigateToList(...params: any[]): void {
        throw new Error('Method not implemented.');
    }
    //#endregion "Méthodes non implémentées"
}
