export * from './location';
export * from './depot';


export enum MvtStockOrigin {
    MANUAL_ENTRY,
    MANUAL_EXIT,
    ORDER,
    PURCHASE,
    INVENTORY,
    ORDER_RETURN,
    ORDER_INPROGRESS,
    PURCHASE_INPROGRESS,
    PURCHASE_RETURN,
    ORDER_RETURN_INPROGRESS,
    PURCHASE_RETURN_INPROGRESS
}

export enum MvtStockType {
    ENTRY,
    EXIT,
    INVENTORY,
    INVENTORY_ENTRY
}

export enum InventoryrState {
    IN_PROGRESS,
    VALIDATE
}

export enum StockState {
    YES= 1, // stock physique / disponible/ potentiel > 0
    NO = 2, // stock physique / disponible/ potentiel <= 0
    ALL = 3 //pas de test
}