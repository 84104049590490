import { Injectable } from '@angular/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { IDaoBaseService } from '@app/shared/interface';
import { Observable } from 'rxjs/internal/Observable';
import { PackagingFamily } from '@app/shared/model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { shareReplay } from 'rxjs/operators';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PackagingFamilyService extends DaoGridStateBaseService implements IDaoBaseService {

  private _list: Observable<PackagingFamily[]> = null;

  constructor(
      private http: HttpClient, 
      private router: Router, 
      private translate: TranslateService,
      private appConfiguration: AppConfiguration) {
    super(http, translate, appConfiguration);
  }

  // méthode appeler par composent générique shared/module/devextreme/data-grid.component
  getAll(): Observable<any[]> {
    const url = this.appConfiguration.UrlsConfig.wsPackagingFamily;
    if (!this._list) {
      this._list = this.http.get<PackagingFamily[]>(url)
        .pipe(
          shareReplay(1)
        )
        ;
    }
    return this._list;
  }


  /**
  * Create a Packaging Family
  * @param payload request body
  */
  post(payload: PackagingFamily): Observable<NGCResults<PackagingFamily>> {
    const url = this.appConfiguration.UrlsConfig.wsPackagingFamily;
    return this.http.post<NGCResults<PackagingFamily>>(url, payload).clearListCache(this.clearListTablesCache);
  }

  /**
  * Update a Packaging Family
  * @param id PackagingFamily id
  * @param payload PackagingFamily data
  */
  put(id: number, payload: PackagingFamily): Observable<NGCResults<PackagingFamily>> {
    const url = this.appConfiguration.UrlsConfig.wsPackagingFamily + id;
    return this.http.put<NGCResults<PackagingFamily>>(url, payload);
  }

  // méthode appeler par composent générique shared/module/devextreme/data-grid.component
  // pour faire clear cache des données
  clearListTablesCache = () => {
    this._list = null;
  }

  // méthode appeler par composent générique shared/module/devextreme/data-grid.component
  delete(id: number): Observable<any> {
    const url: string = this.appConfiguration.UrlsConfig.wsPackagingFamily;
    return this.http.delete(url + id);
  }

  navigateToDetail(id: string) {
    return this.router.navigate(['/company/settings/packaging-family/detail/', id]);
  }

  navigateToCreatePage(): void {
    this.router.navigate(['/company/settings/packaging-family/create']);
  }

  navigateToList(): void {
    this.router.navigate(['/company/settings/packaging-family']);
  }

}
