import { TranslateService } from '@ngx-translate/core';

/**
 *
 * @param enumerable la classe
 * @param nameEnum prefix de la traduction
 * @param translate service de traduction
 */
export const convertEnumToKeyValue = (enumerable: any, nameEnum: string, translate: TranslateService, excludedList:any[] = []): Array<{ designation: string, value: number }> => {
    const list: Array<{ designation: string, value: number }> = [];
    for (const item in enumerable) {
        if (!isNaN(+item)) {
            if(excludedList.length > 0 && ((excludedList.indexOf(0) !== -1 && +item === 0) || excludedList.find(x => x === +item))) {
                continue;
            }
            if (nameEnum != undefined || nameEnum != null) {
                list.push({designation: translate.instant(nameEnum + '.' + enumerable[item]), value: +item});
            } else {
                list.push({designation: translate.instant(enumerable[item]), value: +item});
            }
        }
    }
    return list;
};

export const convertEnumToTag = (enumerable: any, nameEnum: string, translate: TranslateService): Array<{ designation: string, value: string }> => {
    const list: Array<{ designation: string, value: string }> = [];
    for (const item in enumerable) {
        if (!isNaN(+item)) {
            if (item.length == 1) {
                list.push({ designation: translate.instant(nameEnum + '.' + enumerable[item]) + ':', value: '<ARG00' + +item + '>' });
            } else if (item.length == 2) {
                list.push({ designation: translate.instant(nameEnum + '.' + enumerable[item]) + ':', value: '<ARG0' + +item + '>' });
            } else {
                list.push({ designation: translate.instant(nameEnum + '.' + enumerable[item]) + ':', value: '<ARG' + +item + '>' });
            }
        }
    }
    return list;
};

export const bitToEnumFlag = (enumerable: any, bit: any): number[] => {
    const enumArray = [];
    const bits = Object.keys(enumerable).map(Number).filter(Boolean);
    const result = bits.map((b) => (bit & b) === b);
    for (let i = 0; i < result.length; i++ ) {
        if (result[i]) {
            enumArray.push(bits[i]);
        }
    }
    return enumArray;
}
