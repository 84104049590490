import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ngc-checkbox',
    templateUrl: './ngc-checkbox.component.html',
    styleUrls: ['./ngc-checkbox.component.scss']
})
export class NgcCheckboxComponent implements OnInit {

    @Input() disabled: boolean = false;
    @Input() value: boolean = true;
    @Input() field = '';

    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();


    constructor() { }

    ngOnInit() {
    }

    onValueChanged() {
        this.value = !this.value;
        this.valueChanged.emit(this.value);
    }
}
