<div id="content">
    <div *ngIf="headerSimplified && type == 'create'" class="header-simplified">{{ 'Creating' | translate }} {{headerSimplified | translate }}</div>
    <div *ngIf="headerSimplified && type == 'update'" class="header-simplified">{{ 'Update' | translate }} {{headerSimplified | translate }}</div>
    <div class="dx-form-wrapper" [class.in-popup]="inPopup">
        <dx-scroll-view [height]="getHeight()">
            <form (submit)="save($event)" class="generic-form ngc-generic-form"
                [class.inpopup]="inPopup"
                autocomplete="off">
                <div class="form-content">
                    <div class="pl-0 pr-0 editable-content"
                        [ngClass]="cssClasses">
                        <dx-validation-group #validationGroupe>
                            <ng-container *ngIf="formBuilded">
                                <!-- itérer sur le tableau items  DEPERCATED -->
                                <ng-template [ngIf]="formItems.length> 0 &&
                                    formItems[0].itemType !== 'group'">
                                    <!-- affichage des erreurs ici -->
                                    <div class="card" style="border: 0px; height: 700px;">
                                        <dx-scroll-view
                                            [height]="popupContentHeight">
                                            <div class="form-inputs card-body">
                                                <div style="width: 100%;
                                                    padding: 20px;padding-top:
                                                    5px">
                                                    <!-- show errors -->
                                                    <div
                                                    *ngIf="!validForm">
                                                    <div class="card
                                                    errors-card">
                                                        <div
                                                            class="errors-card-body">
                                                            <div
                                                                class="errors-msg">
                                                                <ngc-icon
                                                                    size="24"
                                                                    icon="evenodd"></ngc-icon>
                                                                    <div>{{'FormErrorMessages.InvalidForm' | translate}}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                    <ng-template ngFor
                                                        let-formItem
                                                        [ngForOf]="formItems"
                                                        let-i="index">
                                                        <!-- cas d'affichage de liste de champs directement ( sans groups) -->
                                                        <!-- <ng-template
                                                            [ngIf]="formItem.itemType
                                                            ==='simple'"> -->
                                                            <!-- <div [hidden]="!formItem.visible && !formItem.label.visible" class="dx-fieldset-header"
                                                                    [ngClass]="formItem.cssClass">
                                                                    {{ formItem.designation }}</div> -->
                                                                <div class="custom-dx-field">
                                                                    <div *ngIf="formItem.hasLinkBefore && formItem.visible" class="custom-dx-field-before">
                                                                        <!-- Link Before Custom Dx Field -->
                                                                        <div *ngIf="formItem.hasButtonBefore">
                                                                            <ngc-icon style="color: #5D78FF;" size="24" icon="vector"></ngc-icon>
                                                                        </div>
                                                                        <ng-container>
                                                                            <div *ngFor="let item of formItem.hasLinkBefore.name">
                                                                                <a [ngClass]="formItem.cssClass" (click)="onLinkClick(item,formItem)">{{item}}</a>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                    <!-- Input Custom Dx Field -->
                                                                    <div
                                                                        *ngIf="formItem.itemType && formItem.itemType ==='simple'"
                                                                        class="form-group"
                                                                        [ngClass]="{'espacement'
                                                                        :
                                                                        formItem.marginBottom}"
                                                                        [ngStyle]="{'width':(formItem.width?
                                                                        formItem.width :
                                                                        '440px') }"
                                                                        style="display:
                                                                        inline-grid;">
                                                                        <app-dx-field
                                                                            *ngIf="formItem.dataField"
                                                                            [hidden]="!formItem.visible
                                                                            &&
                                                                            !formItem.label.visible"
                                                                            [editable]="isEditable"
                                                                            [field]="formItem"
                                                                            [item]="item"
                                                                            [itemCopy]="itemCopy"
                                                                            [service]="service"
                                                                            [contextualHelpOn]="contextualHelpOn"
                                                                            (canDeactivate)="onCanDeactivateChanged($event,
                                                                            formItem)"
                                                                            (valueChange)="onValueChanged($event)"
                                                                            [templateDirective]="getTemplate(formItem)"
                                                                            [id]="id"
                                                                            [showPopup]="formItem.popupVisible">
                                                                        </app-dx-field>
                                                                    </div>
                                                                    <!-- Icon Custom Dx Field -->
                                                                    <div class="custom-dx-field-after">
                                                                        <div *ngIf="formItem.hasButton" style="padding-top: 13px;">
                                                                            <ngc-icon class="custom-dx-field-after-config" (click)="onLinkClick('icon',formItem)" size="20" icon="cog"></ngc-icon>
                                                                        </div>
                                                                        <!-- Link After Custom Dx Field -->
                                                                        <div *ngIf="formItem.hasLinkAfter && formItem.visible" class="custom-dx-field-link-after">
                                                                            <div *ngFor="let item of formItem.hasLinkAfter">
                                                                                <a  class="link" [ngClass]="{'linkEnabled': item.visible == false, 'linkDisabled': item.visible == true}" 
                                                                                    (click)="onLinkClick(item.name,formItem)">
                                                                                    {{item.name}}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="custom-dx-field-visibility" *ngIf="formItem.hasVisibleButton && formItem.visible" >
                                                                            <ngc-icon size="24" icon="times" (click)="onVisibilityClick(formItem)"></ngc-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div>
                                                                <ng-template ngFor let-subField
                                                                    [ngForOf]="formItem.items">
                                                                    <div class="form-group" *ngIf="subField.visible"
                                                                        [ngClass]="{'takeFullRow':
                                                                        subField.LineBreak
                                                                        ,
                                                                        'espacement'
                                                                        :
                                                                        subField.marginBottom}" [ngStyle]="{'width':((screenService.isSmallScreen())?
                                                                        '100%':subField.width?
                                                                        subField.width
                                                                        :
                                                                        '440px')
                                                                        }">
                                                                        <app-dx-field [editable]="isEditable"
                                                                            [parentItem]="item" [field]="subField"
                                                                            [item]="item[formItem.dataField]"
                                                                            [itemCopy]="itemCopy[formItem.dataField]"
                                                                            (valueChange)="onValueChanged($event)"
                                                                            [service]="service"
                                                                            [contextualHelpOn]="contextualHelpOn"
                                                                            (canDeactivate)="onCanDeactivateChanged($event,
                                                                            subField)"
                                                                            (initSlidePanel)="onInitSlidePanel($event)"
                                                                            [templateDirective]="getTemplate(subField)"
                                                                            [showPopup]="formItem.popupVisible">
                                                                        </app-dx-field>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        <!-- </ng-template> -->
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <ng-container
                                                *ngTemplateOutlet="formFooterTemplate
                                                ? formFooterTemplate.template :
                                                null; context: { $implicit:
                                                {item: item, inPopup: inPopup,
                                                itemLoading: itemLoading} }">
                                            </ng-container>
                                        </dx-scroll-view>

                                    </div>
                                </ng-template>
                            </ng-container>
                        </dx-validation-group>
                    </div>
                </div>
            </form>
        </dx-scroll-view>
    </div>
    <div class="footer" *ngIf="options?.submitBtn?.show">
        <dx-button class="cancelBtn" *ngIf="options.cancelBtn.show === true" [text]="options.cancelBtn.text" (onClick)="cancelBtn()" type="default" style="width: 120px; height: 36px;" [useSubmitBehavior]="false">
        </dx-button>
        <dx-button [disabled]="options.submitBtn.disabled" [text]="options.submitBtn.text" (onClick)="saveBtn()" type="default" style="width: 120px; height: 36px;" [useSubmitBehavior]="false">
        </dx-button>
        <div class="submitMessage" *ngIf="options.submitBtn.message!=''">{{options.submitBtn.message}}</div>
    </div>
</div>
