<div class="ngc-generic-datagrid table-container">
    <dx-data-grid class="gridfield" [dataSource]="dataSource" [columns]="columns" [columnAutoWidth]="false"
        (onToolbarPreparing)="onToolbarPreparing($event)" (onRowInserted)="onDataChange()"
        (onRowRemoved)="onDataChange()" (onRowUpdated)="onDataChange()">
        <dxo-editing mode="cell" refreshMode="full" [allowAdding]="allowAdding" [allowUpdating]="allowUpdating" [allowDeleting]="allowDeleting">
        </dxo-editing>
        <dxo-search-panel [visible]="true">
        </dxo-search-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-export [enabled]="false"></dxo-export>

        <div *dxTemplate="let data of 'designation'">
            <div *ngIf="data.value">
                {{data.value.designation}}
            </div>
        </div>

        <div *dxTemplate="let data of 'title'">

            <div>
                <span class="label">{{ title }}
                </span>
                <span
                class="dx-field-item-required-mark" *ngIf="isRequired">*</span>
            </div>

        </div>

        <div *dxTemplate="let data of 'imageCellTemplate'">
            <img class="chart-logo" [src]="data.value"/>
        </div>

    </dx-data-grid>
</div>
