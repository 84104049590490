import {
    CustomerRepresentative,
    SupplierRepresentative,
    CustomerClass,
    SupplierClass,
    CompanyCategory,
    Country,
    State,
    CustomerCategory,
    CustomerAddressAssociateCustomer,
    Currency,
    SupplierCategory,
    SupplierAddressAssociateSupplier,
    PaymentChoice,
    CustomerFollowUpStatus,
    SupplierFollowUpStatus,
    BasicRate,
    PortScale,
    Packaging,
    Referencing,
    User,
    Sign,
    Depot,
    CompanyCriteriaValueSelected,
    MovementTitleType,
    CustomerAccountingFamily
} from 'app/shared/model';
import { RegleImpression, ReportType } from '../enum';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from 'app/shared/modules/devextreme/dx-form/models/validation-rules';
import { PaymentMethod } from './paymentMethod';
import { PostalAddress } from '../postalAddress';
import { EtiquetteBase } from '../Etiquette/Etiquette';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { ApplicationMode } from '../base-entity1';

export enum CompanyType { CUSTOMER, SUPPLIER, COMMUN }
export enum CustomerType { CUSTOMER, PROSPECT }
export enum TaxCompanyType { INDIVIDUAL, BEVERAGE_VENDOR, AUTHORIZED_WAREHOUSEKEEPER, OTHERNOT_AW, EXEMPT, AW_RULE_ACCORDINGTO_TAX }
export enum FrancoTypeValue { ORDER_AMOUNT, ARTICLE_QUANTITY, ORDER_WEIGHT, ORDER_TRANSPORTATIONBASE, ORDER_DESIGNATION5 }
export enum InvoiceConsolidationType { INVOICED_CUSTOMER, DELIVERED_INVOICE, NONE }
export enum NatureVAT { VAT = 1, EU_DELIVERY, NOT_EU_DELIVERY, EXONERATION }
export enum LabelRule { NONE, LINE, UNIT, CONDITIONING, HUNDRED_UNITS, FINALPACKAGING, PACKAGE2, ARTICLE_PACKAGING }
export enum TypePort { SHIPPING_COST, POSTAGE_PAID, DUE_TO_THE_CARRIER }
export enum RoundingRule {
    DEFINED_FOR_SOCIETY, R0_0001, R0_0005, R0_001, R0_005, R0_01, R0_05, R0_1, R0_5, R1, R5, R10, R50, R100,
    R500, R1000, R0_10_SUP, R0__0_45__0_95, R0__0_99
}
export enum ActionCommercialOperationLine {
    NONE, ADD, UPDATE, DELETE
}
export enum SplittingOrderRule { NONE, SPLITTING_CRITERIA, DEPOSIT, SPLITTING_CRITERIA_AND_DEPOSIT }
export enum StatementsPeriodicity { NONE, WEEKLY, DECADE, TWO_WEEKS, MONTHLY }
export const isCompanyPassage = (company) => {
    return company && company.id === 1;
};
export class Company extends BaseEntity2 {
    customerType: CustomerType;
    // name1: string;
    // name2: string;
    email: string;
    state: State;
    cdnLogo: string;
    category: CompanyCategory;
    lastOrderDate: Date;
    fidelityCreationDate: Date;
    blockDiscountCostPrice: boolean;
    blockDiscountAdvancedCost: boolean;
    blockDiscountPersonalCost: boolean;
    ceiling: number;
    cnuf: number;
    basicRate: BasicRate;
    delayBeforeShipping: number;
    depositInAccount: boolean;
    valuedDeposit: boolean;
    transferFromDepotToDepot: boolean;
    durationDelivery: number;
    fidelityActive: boolean;
    francoTypeValue: FrancoTypeValue;
    hortipassV3Code: number;
    hortipassV3Code2: number;
    hortipassV3Code2Enable: boolean;
    numberOfInvoices: number;
    invoiceCharges: boolean;
    invoiceEDI: boolean;
    labelReport: number;
    logisticLabelReport: number;
    makeInvoice: boolean;
    depositMovement: boolean;
    stockMovement: boolean;
    nature: NatureVAT;
    referencing: Referencing;
    idAuxiliarySociety: number;
    visible: boolean;
    oldIdCustomer: number;
    paymentOnStatement: boolean;
    sendDepositToHortitrace: number;

    companyType: CompanyType;

    taxCompanyType: TaxCompanyType;
    heterogeneousPackagingAreAllowed: boolean;
    callMonday: boolean;
    callTuesday: boolean;
    callWednesday: boolean;
    callThursday: boolean;
    callFriday: boolean;
    callSaturday: boolean;
    callSunday: boolean;
    splittingOrderRule: SplittingOrderRule;
    tradeDiscountPrecounted: boolean;
    idAccountXOL: number;
    idSocietyXOL: number;
    idPrestashop: number;
    invoiceCentralizedReport: boolean;
    paymentCentralizedReport: boolean;
    customerLinked: Customer;


    downPaymentRate: number;
    purchasePriceRate: number;
    turnoverN: number;
    turnoverN1: number;
    turnoverN2: number;
    turnoverCarryForward: number;
    turnoverObjective: number;
    creditTurnoverN: number;
    discountRateLine: number;
    discountRateFoot: number;
    tradeDiscountRate: number;
    fidelityPoints: number;
    francoValue: number;
    latitude: number;
    longitude: number;
    numeric01: number;
    coefficientResaleLabel: number;
    creditRateTurnoverN: number;
    roundingRuleForPriceCalculationLabel: RoundingRule;

    // zipCode: string;
    EDIAccountingCenter: string;
    // address1: string;
    // address2: string;
    idConnexionXOL: string;
    cvi: string;
    qualification: string;
    oldQualification: string;
    hortitraceCode: string;
    exciseNumber: string;
    vatIdentification: string;
    comment: string;
    comment2: string;
    comment3: string;
    comment4: string;
    recipientGroups: string;
    url: string;
    url2: string;
    recipientGroupsXOL: string;
    paymentChoice: PaymentChoice;
    paymentMethod: PaymentMethod;
    transportationBase: Packaging;
    // civility: Civility;
    currency: Currency;
    depot: Depot;
    destinationDepot: Depot;
    finalPackage: Packaging;
    invoiceConsolidationType: InvoiceConsolidationType;
    qtyTypeForOneLabel: LabelRule;
    port: TypePort;
    movementTitleType: MovementTitleType;
    sign: Sign;
    statementsPeriodicity: StatementsPeriodicity;
    thirdAccount: string;
    code: string;
    fidelityCode: string;
    hortipassCode: string;
    siretNumber: string;
    globalLocationNumber: string;
    /*
    representativeCommission1: string;
    representativeCommission2: string;
    representativeCommission3: string;
    representativeCommission4: string;
    representativeCommission5: string;
    representativeCommission6: string;
    */
    mobilBusinessInvoiceReport: string;
    portScale: PortScale;
    fax: string;
    phone: string;
    mobilePhone: string;
    callTime: Date;
    accountingSupplement: string;
    assurance: string;
    // city: string;
    // socialReason: string;
    userTelesales: User;
    // filterCriteriaArticle: string;
    countryCodeForBarcode: string;
    // country: Country;
    countryOrigin: Country;
    countryOriginDestination: Country;
    postalAddress: PostalAddress;
    ruleEtiquetteColisage:RegleImpression;
    ruleEtiquetteProduit:RegleImpression;
    useInMsStore : boolean;
    useInMsOrder : boolean;


    constructor() {
        super();
        // this.name1 = '';
        // this.name2 = '';
        this.email = '';
        this.state = null;
        this.cdnLogo = '';
        this.category = null;
        this.lastOrderDate = new Date();
        this.fidelityCreationDate = new Date();
        this.blockDiscountCostPrice = false;
        this.blockDiscountAdvancedCost = false;
        this.blockDiscountPersonalCost = false;
        this.ceiling = 0;
        this.cnuf = 0;
        this.basicRate = null;
        this.delayBeforeShipping = 0;
        this.depositInAccount = false;
        this.valuedDeposit = false;
        this.transferFromDepotToDepot = false;
        this.durationDelivery = 0;
        this.fidelityActive = false;
        this.hortipassV3Code = 0;
        this.hortipassV3Code2 = 0;
        this.hortipassV3Code2Enable = false;
        this.numberOfInvoices = 0;
        this.invoiceCharges = false;
        this.invoiceEDI = false;
        this.labelReport = 0;
        this.logisticLabelReport = 0;
        this.makeInvoice = false;
        this.depositMovement = false;
        this.stockMovement = false;
        this.nature = null;
        this.referencing = null;
        this.idAuxiliarySociety = 0;
        this.visible = true;
        this.oldIdCustomer = 0;
        this.paymentOnStatement = false;
        this.sendDepositToHortitrace = 0;
        this.heterogeneousPackagingAreAllowed = false;
        this.callMonday = false;
        this.callTuesday = false;
        this.callWednesday = false;
        this.callThursday = false;
        this.callFriday = false;
        this.callSaturday = false;
        this.callSunday = false;
        this.splittingOrderRule = null;
        this.tradeDiscountPrecounted = false;
        this.idAccountXOL = 0;
        this.idSocietyXOL = 0;
        this.idPrestashop = 0;
        this.invoiceCentralizedReport = false;
        this.paymentCentralizedReport = false;
        this.customerLinked = null;
        this.downPaymentRate = 0;
        this.purchasePriceRate = 0;
        this.turnoverN = 0;
        this.turnoverN1 = 0;
        this.turnoverN2 = 0;
        this.turnoverCarryForward = 0;
        this.turnoverObjective = 0;
        this.creditTurnoverN = 0;
        this.discountRateLine = 0;
        this.discountRateFoot = 0;
        this.tradeDiscountRate = 0;
        this.fidelityPoints = 0;
        this.francoValue = 0;
        this.latitude = 0;
        this.longitude = 0;
        this.numeric01 = 0;
        this.coefficientResaleLabel = 0;
        this.creditRateTurnoverN = 0;
        this.roundingRuleForPriceCalculationLabel = null;
        // this.zipCode = '';
        this.EDIAccountingCenter = '';
        // this.address1 = '';
        // this.address2 = '';
        this.idConnexionXOL = '';
        this.cvi = '';
        this.qualification = '';
        this.oldQualification = '';
        this.hortitraceCode = '';
        this.exciseNumber = '';
        this.vatIdentification = '';
        this.comment = '';
        this.comment2 = '';
        this.comment3 = '';
        this.comment4 = '';
        this.recipientGroups = '';
        this.url = '';
        this.url2 = '';
        this.recipientGroupsXOL = '';
        this.paymentChoice = null;
        this.paymentMethod = null;
        this.transportationBase = null;
        // this.civility = null;
        this.currency = null;
        this.depot = null;
        this.destinationDepot = null;
        this.finalPackage = null;
        this.invoiceConsolidationType = null;
        this.qtyTypeForOneLabel = null;
        this.port = null;
        this.movementTitleType = null;
        this.sign = null;
        this.statementsPeriodicity = null;
        this.thirdAccount = '';
        this.code = '';
        this.fidelityCode = '';
        this.hortipassCode = '';
        this.siretNumber = '';
        this.globalLocationNumber = '';
        /*
        this.representativeCommission1 = '';
        this.representativeCommission2 = '';
        this.representativeCommission3 = '';
        this.representativeCommission4 = '';
        this.representativeCommission5 = '';
        this.representativeCommission6 = '';
        */
        this.mobilBusinessInvoiceReport = '';
        this.portScale = null;
        this.fax = '';
        this.phone = '';
        this.mobilePhone = '';
        this.callTime = new Date();
        this.accountingSupplement = '';
        this.assurance = '';
        // this.city = '';
        // this.socialReason = '';
        this.userTelesales = null;
        // this.filterCriteriaArticle = '';
        this.countryCodeForBarcode = '';
        // this.country = null;
        this.countryOrigin = null;
        this.countryOriginDestination = null;
        this.francoTypeValue = null;
        this.taxCompanyType = null;
        this.postalAddress = null;   

        this.ruleEtiquetteColisage = null;
        this.ruleEtiquetteProduit = null;      
        this.useInMsStore = false;
        this.useInMsOrder = false;
    }

    initValues(): Partial<Company> {
        return {
            idAuxiliarySociety: 1
        };
    }

    validator(applicationMode: ApplicationMode): ValidationRulesByFieldName {
        const validators: ValidationRulesByFieldName =  {
            postalAddress: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            basicRate: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            category: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            email: [
                // { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true }
            ],
            referencing: [
                // { type: 'required', trim: true, message: 'FormErrorMessages.referencing.required', useTranslator: true }
            ],
            currency: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            depot: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            accountingSupplement: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            assurance: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            code: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment2: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment3: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment4: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            cvi: [
                { type: 'stringLength', max: 15, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            EDIAccountingCenter: [
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            exciseNumber: [
                { type: 'stringLength', max: 13, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            fax: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            fidelityCode: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            globalLocationNumber: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            hortipassCode: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            hortitraceCode: [
                { type: 'stringLength', max: 20, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            idConnexionXOL: [
                { type: 'stringLength', max: 100, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            mobilePhone: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            oldQualification: [
                { type: 'stringLength', min: 2, max: 2, message: 'FormErrorMessages.StringEqualLength', useTranslator: true, ignoreEmptyValue: true }
            ],
            qualification: [
                { type: 'stringLength', min: 2, max: 2, message: 'FormErrorMessages.StringEqualLength', useTranslator: true, ignoreEmptyValue: true }
            ],
            phone: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            siretNumber: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            state: [
                { type: 'required' }
            ],
            thirdAccount: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            url: [
                { type: 'stringLength', max: 50, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            url2: [
                { type: 'stringLength', max: 50, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            vatIdentification: [
                { type: 'stringLength', max: 20, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ]
        };

        if (applicationMode === ApplicationMode.VITI) {
            validators['movementTitleType'] = [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ];
        }

        return validators;
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            {
                nameProp: 'postalAddress',
                objectInstance: new PostalAddress()
            }
        ];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = [];
        specificFieldForConfscreenModel.groupsField = {};

        return specificFieldForConfscreenModel;
    }
}

export class Customer extends Company {
    category: CustomerCategory;
    addresses: CustomerAddressAssociateCustomer[];
    representative1: CustomerRepresentative;
    representative2: CustomerRepresentative;
    representative3: CustomerRepresentative;
    representative4: CustomerRepresentative;
    representative5: CustomerRepresentative;
    representative6: CustomerRepresentative;
    followUpStatus: CustomerFollowUpStatus;
    followUpStatusPrevious: CustomerFollowUpStatus;
    class: CustomerClass;
    companyCriteriaSelected: CompanyCriteriaValueSelected[];
    customerToInvoice: Customer;
    etiquetteColisage: number;
    etiquetteProduit: number;
    etiquetteColisageReportType: ReportType;
    etiquetteProduitReportType: ReportType;
    customerAccountingFamily: CustomerAccountingFamily;

    constructor() {
        super();
        this.category = null;
        this.addresses = null;
        this.representative1 = null;
        this.representative2 = null;
        this.representative3 = null;
        this.representative4 = null;
        this.representative5 = null;
        this.representative6 = null;
        this.followUpStatus = null;
        this.followUpStatusPrevious = null;
        this.class = null;
        this.companyCriteriaSelected = null;
        this.customerToInvoice = null;
        this.etiquetteColisage = null;
        this.etiquetteProduit = null;
        this.etiquetteColisageReportType = ReportType.BASE;
        this.etiquetteProduitReportType = ReportType.BASE;
        this.customerAccountingFamily = null;
    }
}

export class Supplier extends Company {
    category: SupplierCategory;
    addresses: SupplierAddressAssociateSupplier[];
    representative1: SupplierRepresentative;
    representative2: SupplierRepresentative;
    representative3: SupplierRepresentative;
    representative4: SupplierRepresentative;
    representative5: SupplierRepresentative;
    representative6: SupplierRepresentative;
    followUpStatus: SupplierFollowUpStatus;
    followUpStatusPrevious: SupplierFollowUpStatus;
    class: SupplierClass;
    companyCriteriaSelected: CompanyCriteriaValueSelected[];
    supplierToInvoice: Supplier;
    etiquetteColisage: EtiquetteBase;
    etiquetteProduit: EtiquetteBase;

    constructor() {
        super();
        this.category = null;
        this.addresses = null;
        this.representative1 = null;
        this.representative2 = null;
        this.representative3 = null;
        this.representative4 = null;
        this.representative5 = null;
        this.representative6 = null;
        this.followUpStatus = null;
        this.followUpStatusPrevious = null;
        this.class = null;
        this.companyCriteriaSelected = null;
        this.supplierToInvoice = null;
        this.etiquetteColisage = null;
        this.etiquetteProduit = null;
    }
}
