import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { Customer, Supplier, Civility } from '../company';
import { ContactAddress } from './contactAddress';
import { PhoneNumber } from './phone';
import { ContactFunction } from './contactFunction';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { PostalAddress } from '../postalAddress';


export enum ContactTypes { CUSTOMER, SUPPLIER, OTHER }

export class Contacts extends BaseEntity2 {
    customer: Customer;
    supplier: Supplier;
    type: ContactTypes;
    function: ContactFunction;
    // name1: string;
    // name2: string;
    // civility: Civility;
    postalAddress: PostalAddress;
   // address: ContactAddress;
    phoneNumbers: PhoneNumber[];
    email: string;
    comment: string;
    code: string;


    constructor() {
        super();
        this.customer = null;
        this.supplier = null;
        this.type = ContactTypes.OTHER;
        this.function = null;
        // this.name1 = '';
        // this.name2 = '';
        // this.civility = null;
        this.postalAddress = null;
       // this.address = null;
        this.phoneNumbers = [];
        this.email = '';
        this.comment = '';
        this.code = '';
    }
    validator(): ValidationRulesByFieldName {
        return {
            code: [{ type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            email: [
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true },
                { type: 'stringLength', max: 100, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

    /*get objectUnderForm(): { subForm: any, list: string[] } {
        return {
            subForm: {
                address: {
                    fields: Object.keys(new ContactAddress()),
                    list: ['addressLines']
                }
            },
            list: ['phoneNumbers']
        };
    }*/
    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            // {
            //     nameProp: 'address',
            //     objectInstance: new ContactAddress()
            // },
            {
                nameProp: 'postalAddress',
                objectInstance: new PostalAddress()
            }

        ];
        specificFieldForConfscreenModel.list = ['phoneNumbers'];
        specificFieldForConfscreenModel.excluded = ['customer', 'supplier', 'socialReason'];
        specificFieldForConfscreenModel.groupsField = {
            'type': ['customer', 'supplier'],
        };

        return specificFieldForConfscreenModel;
    }


}


