import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Etiquette, CategoryEtiquette } from '../../../shared/model/Etiquette/Etiquette';
import { EtiquetteFavoris } from '../../../shared/model/Etiquette/EtiquetteFavoris';
import { AppConfiguration } from '../application/app-configuration.service';
import { TableState } from '../../../shared/model';

@Injectable({
    providedIn: 'root'
})
export class FavorisEtiquetteService {
    _etiquettes: Observable<Etiquette[]> = null;
    _etiquettesBase: Observable<Etiquette[]> = null;
    _gridStateEtiquetteBase: Observable<string> = null;
    _gridStateEtiquettePerso: Observable<string> = null;

    constructor(
        private http: HttpClient,
        private appConfiguration: AppConfiguration) { }

    clearListTablesCache = (categoryEtiquette: CategoryEtiquette) => {
        this[categoryEtiquette] = null;
    }

    clearReportBaseCache() {
        this._etiquettesBase = null;
    }

    clearGridStateReportPersoCache() {
        this._gridStateEtiquettePerso = null;
    }

    clearGridStateReportBaseCache() {
        this._gridStateEtiquetteBase = null;
    }

    getAll(categoryEtiquette: CategoryEtiquette): Observable<Etiquette[]> {
        if (!this[categoryEtiquette]) {
            const url = this.appConfiguration.UrlsConfig.wsEtiquetteFavoris + categoryEtiquette;
            this[categoryEtiquette] = this.http.get<Etiquette[]>(url).pipe(shareReplay(1));
        }
        return this[categoryEtiquette];
    }

    post(payload: EtiquetteFavoris): Observable<EtiquetteFavoris> {
        const url = this.appConfiguration.UrlsConfig.wsEtiquetteFavoris;
        return this.http.post<EtiquetteFavoris>(url, payload);
    }

    delete(id: number, type: string): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsEtiquetteFavoris + id + '/' + type;
        return this.http.delete(url);
    }

    public getBase(): Observable<Etiquette[]> {
        if (!this._etiquettesBase) {
            this._etiquettesBase = this.http.get<Etiquette[]>(this.appConfiguration.UrlsConfig.wsEtiquetteBase).pipe(shareReplay(1));
        }
        return this._etiquettesBase;
    }

    public getGridStateReportBase(): Observable<string> {
        if (!this._gridStateEtiquetteBase) {
            const urlStateGrid = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('reportTableBase');
            this._gridStateEtiquetteBase = this.http.get<string>(urlStateGrid).pipe(shareReplay(1));
        }
        return this._gridStateEtiquetteBase;
    }

    public getGridStateReportPerso(): Observable<string> {
        if (!this._gridStateEtiquettePerso) {
            const urlStateGrid = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('reportTablePerso');
            this._gridStateEtiquettePerso = this.http.get<string>(urlStateGrid).pipe(shareReplay(1));
        }

        return this._gridStateEtiquettePerso;
    }

}
