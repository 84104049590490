import { Component, OnInit, Input } from '@angular/core';
import { FormField } from '@app/shared/modules/devextreme/dx-form/models';

@Component({
    selector: 'app-image',
    templateUrl: './app-image.component.html',
    styleUrls: ['./app-image.component.scss']
})
export class AppImageComponent implements OnInit {
    @Input() customField: FormField;
    @Input() item: any;
    @Input() empty: boolean = false;
    @Input() type: 'round' | 'rectangle' = 'rectangle';
    constructor() { }

    ngOnInit() {
    }

    defaultImage(item: any): any {
        if (item && this.customField && item[this.customField.dataField]) {
            let defaultImage: any = item[this.customField.dataField];
            /** si multiple */
            if (this.customField.editorOptions.multiple) {
                /** si le nom du champ de isdéfautValue est défini */
                if (this.customField.editorOptions.defaultImageFieldName) {
                    defaultImage = item[this.customField.dataField].find((v: any) => v[this.customField.editorOptions.defaultImageFieldName] && v[this.customField.editorOptions.multiple + '_upload'] === null);
                    /** si le nom du champ de isdéfautValue n'est pas défini we get the first */
                } else {
                    defaultImage = item[this.customField.dataField].find((v: any) => v[this.customField.editorOptions.multiple + '_upload'] === null);
                }
            }
            /** si non multiple retourner le champ  item[this.customField.dataField]*/
            return defaultImage;
        }
        return null;
    }

    get imageUrl() {
        if (this.customField.editorOptions.multiple) {
            if (this.defaultImage(this.item)[this.customField.editorOptions.externalUrlFieldName]) {
                return this.defaultImage(this.item)[this.customField.editorOptions.externalUrlFieldName];
            } else {
                if (this.customField && this.customField.editorOptions && this.customField.editorOptions.imageSize) {
                    return this.defaultImage(this.item)[this.customField.editorOptions.multiple.toString()] + '&w=' + this.customField.editorOptions.imageSize.w + '&h=' + this.customField.editorOptions.imageSize.h;
                } else {
                    return this.defaultImage(this.item)[this.customField.editorOptions.multiple.toString()];
                }
            }
        } else {
            if (this.customField && this.customField.editorOptions && this.customField.editorOptions.imageSize) {
                return this.defaultImage(this.item) + '&w=' + this.customField.editorOptions.imageSize.w + '&h=' + this.customField.editorOptions.imageSize.h;
            } else {
                return this.defaultImage(this.item);
            }
        }
    }

}
