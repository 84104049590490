import { BaseEntity1 } from '../base-entity1';
import { TypeArticleDesignation5 } from './typeArticleDesignation5';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { BaseEntity2 } from '../base-entity2';

export class Designation5 extends BaseEntity2 {
    code: string;
    designation: string;
    capacity: number;
    netWeight: number;
    grossWeight: number;
    type: TypeArticleDesignation5;
    visible: boolean;
    /**
     *
     */
    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.capacity = 0;
        this.netWeight = 0;
        this.grossWeight = 0;
        this.type = null;
        this.visible = true;
    }
    validator(): ValidationRulesByFieldName {
        return {
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', min: 1, max: 3 , message: 'FormErrorMessages.StringRangeLength', useTranslator: true},
                { type: 'pattern', pattern : '^[A-Z0-9\\-_]*$' , message: 'FormErrorMessages.FormatCode', useTranslator: true}
            ],
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true}
            ]
        };
    }

    initValues(): Partial<Designation5> {
        return {
            visible: true
        };
    }
}
