import { notifyErrorDelay } from '@app/globalsParameter';
import { TableState } from '@app/shared/model';
import { take, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfiguration } from './application/app-configuration.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DaoGridStateBaseService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    constructor(
        private httpSrv: HttpClient,
        private translateSrv: TranslateService,
        private appConfigurationSrv: AppConfiguration
    ) {
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }

    /**
     * méthode appeler par composent générique shared/module/devextreme/data-grid.component
     * pour savegarder le table state
     * @param tableState
     * @param stateParams
     */
    public tableStateSave(tableState: TableState, stateParams: any[]): void {
        const url = this.appConfigurationSrv.UrlsConfig.wsGridState.concat('tableStateSave');
        this.subscriptions.push(
            this.httpSrv.put(url, tableState).pipe(take(1)).subscribe(
                () => {
                    notify(this.translateSrv.instant('TableEtatSaved'), 'success', notifyErrorDelay);
                    this.clearGridStateCache(tableState.grid, stateParams);
                }
            )
        );
    }

    /**
     *  méthode appeler par composent générique shared/module/devextreme/data-grid.component
     *  pour récuperer table state
     * @param tableStateId
     * @param stateParams
     */
    public getGridState(tableStateId: number, stateParams: any[]): Observable<any> {
        let stateFieldName = '_gridState' + tableStateId;
        if (stateParams) {
            stateParams.forEach(param => {
                stateFieldName += param;
            });
        }

        const url = this.appConfigurationSrv.UrlsConfig.wsGridState + 'tableStateLoad/' + tableStateId;
        if (!this[stateFieldName]) {
            this[stateFieldName] = this.httpSrv.get<JSON>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this[stateFieldName];
    }


    /**
    *  méthode appeler par composent générique shared/module/devextreme/data-grid.component
    *  pour reseter la table state
    * @param tableStateId
    * @param stateParams
    */
    public resetGridState(tableStateId: number): Observable<any> {

        let stateFieldName = '_gridState' + tableStateId;

        const url = this.appConfigurationSrv.UrlsConfig.wsGridState + 'tableStateReset/' + tableStateId;
        this[stateFieldName] = null;
        return this.httpSrv.get<JSON>(url)
            .pipe(
                shareReplay(1)
            );
    }

    /**
     * méthode appeler par composent générique shared/module/devextreme/data-grid.component
     * pour faire clear cache des table state
     * @param stateParams
     */
    public clearGridStateCache(tableStateId: number, stateParams: any[]) {
        let stateFieldName = '_gridState' + tableStateId;
        if (stateParams) {
            stateParams.forEach(param => {
                stateFieldName += param;
            });
        }
        delete this[stateFieldName];
    }
}
