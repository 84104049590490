import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Packaging } from 'app/shared/model';

import { DaoGridStateBaseService } from '../dao-grid-state-base.service';
import { IDaoBaseService } from '@app/shared/interface';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PackagingService extends DaoGridStateBaseService implements IDaoBaseService {

    private _list: Observable<Packaging[]> = null;

    constructor(
        private http: HttpClient, 
        private router: Router, 
        private translate: TranslateService,
        private appConfiguration: AppConfiguration) {
        super(http, translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Packaging[]> {
        const url = this.appConfiguration.UrlsConfig.wsPackaging;
        if (!this._list) {
            this._list = this.http.get<Packaging[]>(url)
                .pipe(
                    shareReplay(1)
                )
                ;
        }
        return this._list;
    }
    /**
    * Create a Packaging
    * @param payload request body
    */
    post(payload: Packaging): Observable<NGCResults<Packaging>> {
        const url = this.appConfiguration.UrlsConfig.wsPackaging ;
        return this.http.post<NGCResults<Packaging>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a Packaging
    * @param id Packaging id
    * @param payload Packaging data
    */
    put(id: number, payload: Packaging): Observable<NGCResults<Packaging>> {
        const url = this.appConfiguration.UrlsConfig.wsPackaging + id ;
        return this.http.put<NGCResults<Packaging>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () =>  {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id): Observable<any> {

        const url: string = this.appConfiguration.UrlsConfig.wsPackaging;
        return this.http.delete(url + id);
    }

    navigateToDetail(id) {
        return this.router.navigate(['/parameters/article/packaging/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/packaging/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/packaging']);
    }

}

