import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

import { notifyErrorDelay } from '@app/globalsParameter';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';



export class Error {
    public message: string;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorsService implements OnDestroy {

    private error: Error;
    private subscriptions: Subscription[] = [];

    constructor(
        private translate: TranslateService,
    ) {
        this.error = new Error();
    }

    public getError() {
        return this.error;
    }

    public handleHttpError = (error: any): void => {
        let finalErrorText = '';
        console.log(error);
        if (error instanceof HttpErrorResponse) {
            const clientSideError = [400, 401, 402, 403, 404, 405, 406, 407, 408, 410, 411, 412, 413, 414, 415, 416, 417, 423, 429, 431, 451];
            const serverSideError = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];
            let errMsg = '';

            if (clientSideError.concat(serverSideError).some((err) => err === error.status)) {
                // errMsg = `${error.status}`;
                finalErrorText = this.getTranslatedError(error.status.toString());
            } else {
                if (error.message) {
                    errMsg = this.getTranslatedError(error.message);
                } else {
                    errMsg = 'Server error';
                }
                finalErrorText = errMsg;
            }
        } else {
            if (error.error !== '') {
                finalErrorText = error.error;
            }
        }
        // for unit testing;
        this.error.message = finalErrorText;
        notify(finalErrorText, 'error', notifyErrorDelay);
    }

    private getTranslatedError(error: string): string {
        const translatedError = this.translate.instant('errors.' + error);
        if (translatedError !== 'error.' + error) {
            return translatedError;
        } else {
            return error;
        }
    }

    public errorMessage = (errorMessage: string): void => {
        this.subscriptions.push(
            this.translate.get('error.' + errorMessage).subscribe(
                (str: string) => {
                    this.error.message = str;
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
