import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { User } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from './application/app-configuration.service';
import { ChangePasswordViewModel } from '@app/shared/viewModel/changePasswordViewModel';

@Injectable()
export class UserService extends DaoGridStateBaseService implements IDaoBaseService {
    public _users: Observable<User[]> = null;

    constructor(private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration) {
            super(http, _translate, appConfiguration);
        }

    clearListTablesCache = () => {
        this._users = null;
    }

    get(idUser: number): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsUser  + 'get/' + idUser;
        return this.http.get(serviceUrl);
    }

    getAll(): Observable<User[]> {
        if (!this._users) {
            const url = this.appConfiguration.UrlsConfig.wsUser;
            this._users = this.http.get<User[]>(url).pipe(shareReplay(1));
        }
        return this._users;
    }
    
    post(payload: User): Observable<NGCResults<User>> {
        const url = this.appConfiguration.UrlsConfig.wsUser;
        return this.http.post<NGCResults<User>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    put(id: any, payload: User): Observable<NGCResults<User>> {
        const url = this.appConfiguration.UrlsConfig.wsUser + id;
        return this.http.put<NGCResults<User>>(url, payload);
    }

    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsUser + id;
        return this.http.delete(url);
    }

    getGridTitle(): string {
        return 'Administrator.User.Users';
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/admin/user/create']);
    }

    navigateToDetail(id: string) {
        return this.router.navigate(['/admin/user/detail', id]);
    }

    navigateToList(): void {
        this.router.navigate(['/admin/user']);
    }

    changePassword(payload: ChangePasswordViewModel): Observable<NGCResults<ChangePasswordViewModel>> {
        const url = this.appConfiguration.UrlsConfig.wsUser + 'ChangePassword';
        return this.http.put<NGCResults<ChangePasswordViewModel>>(url, payload);
    }
}
