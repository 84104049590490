import { Injectable } from '@angular/core';
import { Advice } from '@app/shared/model/article/advice';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { IDaoBaseService } from '@app/shared/interface';
import { TranslateService } from '@ngx-translate/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AdviceService extends DaoGridStateBaseService implements IDaoBaseService {

    public _list: Observable<Advice[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Advice[]> {
        const url = this.appConfiguration.UrlsConfig.wsAdvice;
        if (!this._list) {
            this._list =  this.http.get<any[]>(url)
            .pipe(
                shareReplay(1)
            );
        }
        return this._list;
    }

    /**
    * Create a Advice
    * @param payload request body
    */
    post(payload: Advice): Observable<NGCResults<Advice>> {
        const url = this.appConfiguration.UrlsConfig.wsAdvice;
        return this.http.post<NGCResults<Advice>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a Advice
    * @param id Advice id
    * @param payload Conditioning data
    */
    put(id: number, payload: Advice): Observable<NGCResults<Advice>> {
        const url = this.appConfiguration.UrlsConfig.wsAdvice + id;
        return this.http.put<NGCResults<Advice>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getPageTableTitle(): string {
        return 'article.articleAdviceGrid';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsAdvice + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: string) {
        this.router.navigate(['/parameters/article/advice/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/advice/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/advice']);
    }

}
