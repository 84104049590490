import {
    CommercialOperation, CustomerCommercialOperationRepresentative, CustomerCommercialOperationHistory, CustomerCommercialOperationLine,
    CustomerCommercialOperationPricing, CustomerCommercialOperationLogistic, CustomerCommercialOperationVat, CustomerCommercialOperationLabel,
} from '..';
import { Customer } from '../..';
import { CustomerCommercialOperationInfo } from './customerCommercialOperationInfo';
import { CustomerCommercialOperationInvoicing } from './customercommercialOperationInvoicing';
import { EtiquetteBase } from '../../Etiquette/Etiquette';
import { RegleImpression } from '../../enum';

export class CustomerCommercialOperation extends CommercialOperation {
    ruleEtiquetteColisage:RegleImpression;
    ruleEtiquetteProduit:RegleImpression;
    etiquetteProduit: EtiquetteBase;
    etiquetteColisage: EtiquetteBase;
    company: Customer;
    invoicing: CustomerCommercialOperationInvoicing;
    representative: CustomerCommercialOperationRepresentative;
    histories: CustomerCommercialOperationHistory[];
    lines: CustomerCommercialOperationLine[];
    pricing: CustomerCommercialOperationPricing;
    logistic: CustomerCommercialOperationLogistic;
    opVat: CustomerCommercialOperationVat;
    label: CustomerCommercialOperationLabel;
    info: CustomerCommercialOperationInfo;

    constructor() {
        super();
        this.company = null;
        this.invoicing = null;
        this.representative = null;
        this.histories = [];
        this.lines = [];
        this.pricing = null;
        this.logistic = null;
        this.opVat = null;
        this.label = null;
        this.info = null;

    }
}
