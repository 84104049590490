export enum ScreenIdentifier {
    CUSTOMER = 0,
    SUPPLIER = 1,
    ARTICLE = 2,
    BASICRATE = 3,
    PACKAGING = 4,
    PAYMENTCHOICE = 5,
    PORTSCALE = 6,
    REFERENCING = 7,
    UNDERFAMILY = 8,
    ARTICLEFAMILY = 9,
    COSTGROUP = 10,
    ADVICE = 11,
    CONTACT = 12,
    SUPPLIERREPRESENTATIVE = 13,
    CUSTOMERREPRESENTATIVE = 14,
    SUPPLIERCRITERIA = 15,
    CUSTOMERCRITERIA = 16,
    SUPPLIER_CRITERIA_VALUE = 17,
    CUSTOMER_CRITERIA_VALUE = 18,
    SUPPLIER_ADDRESS = 19,
    CUSTOMER_ADDRESS = 20,
    PACKAGING_CROSSING = 21,
    PACKAGING_ARTICLE = 22,
    TAX = 23,
    WIDGETS_BASE = 24,
    OPERATION_UPDATE_CUSTOMER = 25,
    OPERATION_LOGISTIC_CUSTOMER = 26,
    PAYMENT_METHOD = 27,
    OPERATION_DETAIL_INVOICING_CUSTOMER = 28,
    TOTAL_INVOICE_CUSTOMER = 29,
    OPERATION_INVOICE_VALIDATION = 30,
    OPERATION_EXPEDITION_CUSTOMER = 31,
    CREATE_OPERATION = 32,
    SOCIETY = 33,
    STOCK_MOUVEMENT = 34,
    STOCK_LOT = 35,
    MAIL = 36,
    OPERATION_SUMMARY = 37,
    CREATE_SUPPLIER_OPERATION = 38,
    OPERATION_LOGISTIC_SUPPLIER = 39,
    OPEARTION_SUMMARY_SUPPLIER = 40,
    OPERATION_DETAIL_INVOICING_SUPPLIER = 41,
    TOTAL_ACCOUNTING_SUPPLIER = 42,
    OPERATION_UPDATE_SUPPLIER = 43,
    ETIQUETTE_TEMPLATE = 44,
    BANK = 45,
    REGLEMENT = 46,
    COMMISSION_RULES = 47,
    TRADING_TRACKING = 48,
    STOCK_MVT_SETTING = 49
}

