import { Injectable, OnDestroy } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { confirm } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import { CanDesactivate } from 'app/shared/interface/can-desactivate';
import { AuthService } from '../services';
import { CommercialOperationService } from '../services/commercialOperation/commercial-operation.service';


@Injectable()
export class UnlockGuard implements CanDeactivate<any>, OnDestroy {
    private observable: Observable<boolean>;
    private subscriptions: Subscription[] = [];

    constructor(private translate: TranslateService, private authService: AuthService, private commercialOperationService: CommercialOperationService) { }

    canDeactivate(component: any) {
        if (this.authService.userBehaviourSubject.value && component.lockState && +component.lockState.idUser === this.authService.userBehaviourSubject.value.id) {
            this.observable = new Observable(observer => {
                this.subscriptions.push(
                    this.commercialOperationService.unlock(component.unlockId, component.companyType).subscribe(
                        () => {
                            observer.next(true);
                        }
                    )
                );
            });
            return this.observable;
        } else {
            return true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
