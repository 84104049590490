import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**this resolver make sure the http request and translation are resolved ( for instant() usage ) */
@Injectable()
export class TranslationLoaderResolver {

    constructor(private translate: TranslateService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.translate.get('last.dummy'); //
    }

}
