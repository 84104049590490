<dx-drawer [opened]="visible" template="template" [position]="slidePanelOptions?.position? slidePanelOptions.position : 'right'" [minSize]="0"
    [revealMode]="'expand'" (openedChange)="closeOnOutsideClick($event)">

    <div *dxTemplate="let data of 'template'" [ngStyle]="{'width': slidePanelOptions.css.width }"
        class="content-slide-panel">

        <div *ngIf="slidePanelOptions.isForm">
            <!-- <div>Je suis un form</div> -->
            <app-dx-form-simplified [model]="slidePanelOptions.formSimplifiedOptions.model"
                [service]="slidePanelOptions.formSimplifiedOptions.service"
                [customFields]="slidePanelOptions.formSimplifiedOptions.customFieldsForm"
                (canDeactivate)="onDataChanged($event)"
                [serviceOptions]="slidePanelOptions.formSimplifiedOptions.serviceOptions"
                [inPopup]="slidePanelOptions.inPopup"
                [popupContentHeight]="slidePanelOptions.formSimplifiedOptions.popupContentHeight"
                (validateSuccess)="onValidateSuccess($event)"
                [excludedFieldList]="slidePanelOptions.formSimplifiedOptions.excludedFieldFormList"
                (formDataChange)="onFormDataChange($event)"
                [headerSimplified]="slidePanelOptions.formSimplifiedOptions.headerSimplified"
                [type]="slidePanelOptions.formSimplifiedOptions.type"
                [options]="slidePanelChildOptions.nextDxFormOptions"
                [id]="slidePanelOptions.formSimplifiedOptions.id"
                (beforeSave)="OnBeforeSave($event)"
                (confirmDialogYes)="OnConfirmDialogYes($event)"
                (confirmDialogNo)="OnConfirmDialogNo($event)">
            </app-dx-form-simplified>
        </div>

        <div>
            <ng-content select="[content]"></ng-content>
        </div>

        <div *ngIf="slidePanelOptions.isList">
            <app-dx-list [icon]="slidePanelOptions.listOptions.icon" [loadDataType]="slidePanelOptions.loadDataType"
                [service]="slidePanelOptions.listOptions.service"
                [serviceOptions]="slidePanelOptions.listOptions.serviceOptions"
                [model]="slidePanelOptions.listOptions.model"
                [customFields]="slidePanelOptions.listOptions.customFields"
                [listName]="slidePanelOptions.listOptions.listName">
            </app-dx-list>
        </div>

        <div *ngIf="slidePanelOptions.isGrid" class="slide-panel-grid">
            <div class="slide-panel-grid-header">
                <div class="titleBloc">
                    <span *ngIf="slidePanelOptions.dataGridOptions.icon!=''" class="titleIcon">
                        <ngc-icon size="24" [icon]="slidePanelOptions.dataGridOptions.icon"></ngc-icon>
                    </span>
                    <span class="title">{{ slidePanelOptions.dataGridOptions.pageTitle | translate }}</span>
                </div>
                <div class="search-and-column-params">
                    <app-extra-button-field [buttonsBefore]="datagridSearch.buttonsBefore" [clearButton]="true"
                        [buttonsAfter]="datagridSearch.buttonsAfter" [value]="searchingWord"
                        (ValueChanged)="onSearchingWordValueChanged($event)" class="search-bar">
                    </app-extra-button-field>
                    <dx-drop-down-button id="dropdownMore" icon="more" [items]="dropDownGridItems" displayExpr="name"
                        type="default" keyExpr="id" [dropDownOptions]="{ width: 230 }" [showArrowIcon]="false"
                        class="dropdownDataGrid" (onItemClick)="onDropDownGridItemsClicked($event)"
                        itemTemplate="dropDownButtonTemplate" class="drop-down-column-parms">
                        <div *dxTemplate="let data of
                            'dropDownButtonTemplate'">
                            <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                            {{data.name}}
                        </div>
                    </dx-drop-down-button>
                </div>
            </div>
            <app-data-grid #dxSlidePanelDataGrid class="slide-panel-data-grid" [inSlidePanel]="false"
                [service]="slidePanelOptions.dataGridOptions.service"
                [serviceOptions]="slidePanelOptions.dataGridOptions.serviceOptions"
                [model]="slidePanelOptions.dataGridOptions.model" [loadDataType]='slidePanelOptions.loadDataType'
                [tableStateName]="slidePanelOptions.dataGridOptions.tableStateName"
                [excludedFieldList]="slidePanelOptions.dataGridOptions.excludedFieldList"
                [options]="slidePanelOptions.dataGridOptions.options" [breadcrumb]="breadcrumb"
                [customFields]="slidePanelOptions.dataGridOptions.customFields" [inPopup]="false"
                [dataGridInput]="dataGridInput" [dataGridColumns]="[]" (rowDoubleClick)="onRowDoubleClick($event)"
                (onValueSelected)="onValueSelected($event)" [slidePanelOptions]="slidePanelOptions">
            </app-data-grid>
        </div>

        <div *ngIf="slidePanelOptions.isTemplate">
            <ng-container *ngTemplateOutlet="slidePanelTemplateContent; context: { $implicit: { } }"></ng-container>
        </div>

        <div class="footer" *ngIf="slidePanelOptions.buttonsConfig.type === 'oneButton'">
            <dx-button [focusStateEnabled]="false" [activeStateEnabled]="false" [hoverStateEnabled]="false"
                (onClick)="create()" type="default" stylingMode="text" class="addBtn">
                <ngc-icon size="20" icon="roundedAdd" class="footerIcon"></ngc-icon>
                <span class="btnLabel">{{ slidePanelOptions.buttonsConfig.oneButton.addBtnLabel | translate}} </span>

            </dx-button>
        </div>

        <div class="allButtons"
            *ngIf="slidePanelOptions.buttonsConfig.type === 'twoButtons' && !slidePanelOptions.isGrid">
            <dx-button text="{{ slidePanelOptions.buttonsConfig.twoButtons.cancelBtnLabel | translate}}"
                (onClick)="cancel()" type="default" stylingMode="outlined" class="slidePanelButton">
            </dx-button>
            <dx-button
                text="{{ slidePanelOptions.buttonsConfig.twoButtons.confirmBtnLabel |translate }}" (onClick)="save()"
                type="default" [useSubmitBehavior]="false" class="slidePanelButton">
            </dx-button>

        </div>

    </div>
</dx-drawer>
