import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export class Currency extends BaseEntity2 {
    designation: string;
    rate: number;
    symbol: string;
    externalCode: string;
    ISO4217Code: string;
    numericISOCode: number;
    minorUnit: number;
    visible: boolean;

    // obliger de declarer costructor avec tous les champs dans le modele pour l'utiliser dans dx-form-composant
    constructor() {
        super();
        this.designation = '';
        this.rate = 0;
        this.symbol = '';
        this.ISO4217Code = '';
        this.numericISOCode = 0;
        this.minorUnit = 2;
        this.externalCode = '';
        this.visible = true;
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            symbol : [
                { type: 'stringLength', max: 3, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            externalCode : [
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            ISO4217Code : [
                { type: 'stringLength', max: 3, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            numericISOCode: [
                { type: 'range', min: -2147483648, max: 2147483647, ignoreEmptyValue: true, message: 'FormErrorMessages.Int32', useTranslator: true}
            ],
            minorUnit: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'range', min: -2147483648, max: 2147483647, ignoreEmptyValue: true, message: 'FormErrorMessages.Int32', useTranslator: true}
            ]
        };
    }

    initValues(): Partial<Currency> {
        return {
            visible: true
        };
    }
}
