<div id="content">
    <div class="dx-form-wrapper" [class.in-popup]="inPopup">
        <div class="page-header-container" *ngIf="!inPopup && options.showHeader"
            id="dx-form-page-header">
            <app-breadcrumb class="breadcrumb"
                [enabled]="options.breadcrumb.enabled"
                [breadcrumb]="breadcrumb" (template)="Fullbreadcrumb">
            </app-breadcrumb>
            <app-dx-form-header [item]="item" [inPopup]="inPopup"
                [itemLoading]="itemLoading" [headerInfo]="headerInfo"
                [id]="id" [idPrevious]="idPrevious" [idNext]="idNext"
                [options]="options" [validForm]="validForm"
                [tabs]="tabs" [dropdownItems]="options.dropdownItems.Actions"
                [loadDataType]="loadDataType"
                [isEditable]="isEditable" [pendingSubmit]="pendingSubmit"
                [formCanDeactivate]="formCanDeactivate"
                [headerLeftBlocTemplate]="headerLeftBlocTemplate"
                [returnButton]="returnButton"
                (showTabsFunction)="showTabsFunction($event)"
                (dropDownClick)="dropDownClicked($event)"
                (navigateToDetail)="navigateToDetail($event)"
                (create)="create($event)" (save)="saveFromHeader($event)" [isDuplication]="isDuplication"
                >
            </app-dx-form-header>
            <ng-container *ngTemplateOutlet="middleHeaderTemplate ?
                middleHeaderTemplate.template : null; context: { $implicit:
                {item: item} }">
            </ng-container>
        </div>
        <form (submit)="save($event)" class="generic-form ngc-generic-form"
            [class.inpopup]="inPopup"
            autocomplete="off">
            <div class="form-content">

                <!-- Tabs  -->
                <dx-scroll-view [height]="inPopup ||
                    screenService.isSmallScreen() ?'auto':scrHeight -
                    getspace(0)" id="tabs" *ngIf="tabs && tabs.length> 1"
                    [showScrollbar]="never">
                    <div class="tab-container">
                        <div class="card vertical-tabs">
                            <div class="list-group list-group-flush"
                                role="tablist">

                                <div *ngFor="let tab of tabs; let i=index"
                                    (click)="onTabChanged(i)" class="dx-tab
                                    list-group-item
                                    list-group-item-action dx-item"
                                    data-toggle="list" role="tab"
                                    aria-selected="activeTab === i"
                                    [class.active]="activeTab === i"
                                    [class.has-error]="hasErrorToDisplay(tab)">
                                    <div class="dx-tab-content">
                                        {{tab.text}}
                                    </div>
                                    <span class="help-icon" [id]="'index' +
                                        tab.indexBlock"
                                        *ngIf="contextualHelpOn &&
                                        formItems[tab.indexBlock].helpText"
                                        (mouseenter)="toggleTabHelp(tab.indexBlock)"
                                        (mouseleave)="toggleTabHelp(tab.indexBlock)">
                                        <ngc-icon size="16" icon="info"></ngc-icon>
                                    </span>
                                    <dx-popover [target]="'#' + 'index' +
                                        tab.indexBlock" position="right"
                                        [width]="346"
                                        [(visible)]="tabHelpVisible[tab.indexBlock]">
                                        <div *dxTemplate="let data= model of
                                            'content'">
                                            {{formItems[tab.indexBlock].helpText}}
                                        </div>
                                    </dx-popover>

                                    <!-- <contextual-help class="help-text"
                                        [visible]="contextualHelpOn"
                                        [formItem]="formItems[tab.indexBlock]"
                                        position="right"> </contextual-help> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </dx-scroll-view>

                <div class="pl-0 pr-0 editable-content" [ngClass]="cssClasses">
                    <!-- <dx-scroll-view> -->
                    <dx-validation-group #validationGroupe>
                        <ng-container *ngIf="formBuilded">
                            <!-- itérer sur le tableau des groupes -->
                            <ng-template [ngIf]="formItems.length> 0 &&
                                formItems[0].itemType === 'group'">
                                <ng-template ngFor let-formItem
                                    [ngForOf]="formItems" let-i="index">
                                    <div id="form-container"
                                        class="form-container" *ngIf="(activeTab
                                        == i && formItem.itemType ==='group')
                                        || (formItem.itemType ==='group' &&
                                        formItem.simpleGroup)">
                                        <dx-scroll-view
                                            [height]="inPopup?getHeighFormScroll(popupContentHeight)
                                            : scrHeight - getspace(1) -
                                            (screenService.isSmallScreen() &&
                                            showTabs?(tabs.length / 2 * 50) :0)
                                            - (screenService.isSmallScreen() &&
                                            showTabs?(tabs.length % 2 * 50) :0)"
                                            id="formItem{{i}}">
                                            <!-- itérer sur le tableau des sous groupe (Block) -->
                                            <!-- show errors -->
                                            <div
                                                *ngIf="checkErrors(formItem.groups)">
                                                <div class="card errors-card">
                                                    <div
                                                        class="errors-card-body">
                                                        <div class="errors-msg">
                                                            <ngc-icon size="24"
                                                                icon="evenodd" ></ngc-icon>
                                                            <div>{{'FormErrorMessages.InvalidForm' | translate}}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngFor="let subBlock of
                                                filterGroupWithItems(formItem.groups);
                                                let i= index" class="sub-bloc
                                                {{'ngc-colspan-'
                                                + subBlock.colSpan}}"
                                                [ngStyle]="{'width':
                                                subBlock.width ,
                                                'display': subBlock.visible === false ? 'none' : (subBlock.colSpan ===
                                                formItem.colCount ? 'block' :
                                                'inline-block')}"
                                                [ngClass]="{'hidden':
                                                !(subBlock.items.length> 0) || (!subBlock.visible), 'show-error' : (!validForm && (subBlock.caption === formItem.caption && formItem.groups.length
                                                === 1) && i == 0), 'subBloc-padding-top':((formItem.groups.length < 1) ||  (formItem.groups.length === 1 && subBlock.caption=== formItem.caption )) }">
                                                <div [ngClass]="{'hidden':
                                                    (hideSubBloc(subBlock.items)) || (!subBlock.visible)}"
                                                    *ngIf="formItem.groups.length>
                                                    1 || (formItem.groups.length
                                                    === 1 && subBlock.caption
                                                    !== formItem.caption )"
                                                    class="subBlockCaption"> {{
                                                    subBlock.caption }}
                                                    <a
                                                        class="help-icon-sub-block"
                                                        [id]="'subBlockIndex' +
                                                        i"
                                                        *ngIf="subBlock.helpText
                                                        && contextualHelpOn"
                                                        (mouseenter)="toggleSubBlockHelp(i)"
                                                        (mouseleave)="toggleSubBlockHelp(i)">
                                                        <ngc-icon size="16"
                                                            icon="info"></ngc-icon>
                                                    </a>
                                                    <dx-popover [target]="'#' +
                                                        'subBlockIndex' + i"
                                                        position="right"
                                                        [width]="346"
                                                        [(visible)]="subBlockHelpVisible[i]">
                                                        <div *dxTemplate="let
                                                            data= model of
                                                            'content'">
                                                            {{subBlock.helpText}}
                                                        </div>
                                                    </dx-popover>
                                                </div>
                                                <ng-container *ngTemplateOutlet="cardState ?
                                                    cardState.template : null; context: { $implicit:
                                                    {item: item} }">
                                                </ng-container>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div style="width:
                                                            100%;">
                                                            <!-- itérer sur le tableau des champs du sous groupe -->
                                                            <ng-template ngFor
                                                                let-field
                                                                [ngForOf]="subBlock.items">
                                                                <div
                                                                    class="form-group"
                                                                    [className]="field.cssClass ? field.cssClass : ''"
                                                                    *ngIf="field.fieldType
                                                                    !== 1 &&
                                                                    field.visible"
                                                                    [ngClass]="{'espacement'
                                                                    :
                                                                    field.marginBottom}"
                                                                    [ngStyle]="{'width':((screenService.isSmallScreen())?
                                                                    '100%':field.width?
                                                                    field.width
                                                                    : '440px')
                                                                    }">
                                                                    <!-- <div class="dx-fieldset-header" [ngClass]="field.cssClass">
                                                                                    {{ field.designation }}</div> -->
                                                                    <app-dx-field
                                                                        [editable]="isEditable"
                                                                        [field]="field"
                                                                        [item]="item"
                                                                        [itemCopy]="itemCopy"
                                                                        [service]="service"
                                                                        [contextualHelpOn]="contextualHelpOn"
                                                                        (canDeactivate)="onCanDeactivateChanged($event,
                                                                        field)"
                                                                        (valueChange)="onValueChanged($event)"
                                                                        (initSlidePanel)="onInitSlidePanel($event)"
                                                                        [templateDirective]="getTemplate(field)"
                                                                        [id]="id"
                                                                        [showPopup]="field.popupVisible"
                                                                        [formFields]="dxFieldComponentList"
                                                                        (GenerateKeyBarCodeEvent)="GenerateKeyBarCode($event)"
                                                                        [editActionVisible]= "editActionVisible"
                                                                        (editButtonClickedEvent)="onEditButtonClicked($event)"
                                                                        [dataGridPickerVisible] = "dataGridPickerVisible"
                                                                        (addNewLineFromList)="onAddnewLineFromList($event)"
                                                                        (checkTvaEvent)="onTvaValueChanged($event)"
                                                                        (rowListClick)="onRowListClick($event)"
                                                                        (exciseNumberEvent)="onExciseNumberChanged($event)"
                                                                        (clickInfo)="onClickInfo()"
                                                                        (updateLineFromList)="onUpdateLineFromList($event)"
                                                                        (deleteLineFromList)="onDeleteLineFromList($event)">
                                                                    </app-dx-field>
                                                                </div>

                                                                <!-- si le champ est un sous form - itérer sur le tableau des sous champs du champ -->
                                                                <span
                                                                    *ngIf="field.fieldType
                                                                    === 1">
                                                                    <ng-template
                                                                        ngFor
                                                                        let-subField
                                                                        [ngForOf]="field.items">
                                                                        <div
                                                                            class="form-group"
                                                                            *ngIf="subField.visible"
                                                                            [ngClass]="{'takeFullRow':
                                                                            subField.LineBreak
                                                                            ,
                                                                            'espacement'
                                                                            :
                                                                            subField.marginBottom}"
                                                                            [ngStyle]="{'width':((screenService.isSmallScreen())?
                                                                            '100%':subField.width?
                                                                            subField.width
                                                                            :
                                                                            '440px')
                                                                            }">
                                                                            <app-dx-field
                                                                            *ngIf="item[field.dataField]"
                                                                                [editable]="isEditable"
                                                                                [parentItem]="item"
                                                                                [field]="subField"
                                                                                [item]="item[field.dataField]"
                                                                                [itemCopy]="itemCopy[field.dataField]"
                                                                                (valueChange)="onValueChanged($event)"
                                                                                [service]="service"
                                                                                [contextualHelpOn]="contextualHelpOn"
                                                                                (canDeactivate)="onCanDeactivateChanged($event,
                                                                                subField)"
                                                                                (initSlidePanel)="onInitSlidePanel($event)"
                                                                                [templateDirective]="getTemplate(subField)"
                                                                                [formFields]="dxFieldComponentList"
                                                                                [showPopup]="field.popupVisible"
                                                                                (editButtonClickedEvent)="onEditButtonClicked($event)"
                                                                                [editActionVisible]= "editActionVisible"
                                                                                [dataGridPickerVisible] = "dataGridPickerVisible"
                                                                                (addNewLineFromList)="onAddnewLineFromList($event)"
                                                                                (checkTvaEvent)="onTvaValueChanged($event)"
                                                                                (rowListClick)="onRowListClick($event)"
                                                                                (exciseNumberEvent)="onExciseNumberChanged($event)"
                                                                                (clickInfo)="onClickInfo()"
                                                                                (updateLineFromList)="onUpdateLineFromList($event)"
                                                                                (deleteLineFromList)="onDeleteLineFromList($event)">
                                                                            </app-dx-field>
                                                                        </div>
                                                                        <br
                                                                            *ngIf="subField.LineBreak && subField.dataField != field.items[field.items.length-1].dataField" >
                                                                        <span
                                                                            *ngIf="subField.fieldType
                                                                            ===
                                                                            1">
                                                                            <ng-template
                                                                                ngFor
                                                                                let-subSubField
                                                                                [ngForOf]="subField.items">
                                                                                <div
                                                                                    class="form-group"
                                                                                    *ngIf="subSubField.visible"
                                                                                    [ngClass]="{'takeFullRow':
                                                                                    subSubField.LineBreak
                                                                                    ,
                                                                                    'espacement'
                                                                                    :
                                                                                    subSubField.marginBottom}"
                                                                                    [ngStyle]="{'width':((screenService.isSmallScreen())?
                                                                                    '100%':subSubField.width?
                                                                                    subSubField.width
                                                                                    :
                                                                                    '440px')
                                                                                    }">
                                                                                    <app-dx-field
                                                                                        [editable]="isEditable"
                                                                                        [field]="subSubField"
                                                                                        [item]="item[field.dataField][subField.dataField]"
                                                                                        [itemCopy]="itemCopy[subField.dataField]"
                                                                                        (valueChange)="onValueChanged($event)"
                                                                                        [service]="service"
                                                                                        [contextualHelpOn]="contextualHelpOn"
                                                                                        (canDeactivate)="onCanDeactivateChanged($event,
                                                                                        subSubField)"
                                                                                        (initSlidePanel)="onInitSlidePanel($event)"
                                                                                        [templateDirective]="getTemplate(subSubField)"
                                                                                        [showPopup]="subField.popupVisible"
                                                                                        [formFields]="dxFieldComponentList"
                                                                                        [editActionVisible]="editActionVisible"
                                                                                        (editButtonClickedEvent)="onEditButtonClicked($event)"
                                                                                        [dataGridPickerVisible] = "dataGridPickerVisible"
                                                                                        (addNewLineFromList)="onAddnewLineFromList($event)"
                                                                                        (checkTvaEvent)="onTvaValueChanged($event)"
                                                                                        (rowListClick)="onRowListClick($event)"
                                                                                        (exciseNumberEvent)="onExciseNumberChanged($event)"
                                                                                        (clickInfo)="onClickInfo()"
                                                                                        (updateLineFromList)="onUpdateLineFromList($event)"
                                                                                        (deleteLineFromList)="onDeleteLineFromList($event)">
                                                                                    </app-dx-field>
                                                                                </div>
                                                                                <br
                                                                                    *ngIf="subSubField.LineBreak && subSubField.dataField != subField.items[subField.items.length-1].dataField">
                                                                            </ng-template>
                                                                        </span>
                                                                    </ng-template>
                                                                </span>
                                                                <br
                                                                    *ngIf="field.LineBreak">
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div>
                                    </div>
                                </div> -->
                                        </dx-scroll-view>

                                    </div>

                                </ng-template>
                            </ng-template>


                            <!-- itérer sur le tableau items  DEPERCATED -->
                            <ng-template [ngIf]="formItems.length> 0 &&
                                formItems[0].itemType !== 'group'">
                                <div class="card">
                                    <dx-scroll-view
                                        [height]="inPopup?getHeighFormScroll(popupContentHeight)
                                        :scrHeight - getspace(0)">
                                        <div class="form-inputs card-body">
                                            <div class="form-paddings">
                                                <!-- show errors -->
                                                <div
                                                    *ngIf="!validForm">
                                                    <div class="card
                                                        errors-card-not-group">
                                                        <div
                                                            class="errors-card-body">
                                                            <div
                                                                class="errors-msg">
                                                                <ngc-icon
                                                                    size="24"
                                                                    icon="evenodd"></ngc-icon>
                                                                    <div>{{'FormErrorMessages.InvalidForm' | translate}}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-template ngFor let-formItem
                                                    [ngForOf]="formItems"
                                                    let-i="index">
                                                    <!-- cas d'affichage de liste de champs directement ( sans groups) -->
                                                    <ng-template
                                                        [ngIf]="formItem.itemType
                                                        ==='simple'">
                                                        <!-- <div [hidden]="!formItem.visible && !formItem.label.visible" class="dx-fieldset-header"
                                                                        [ngClass]="formItem.cssClass">
                                                                        {{ formItem.designation }}</div> -->
                                                        <div
                                                        class="form-group"
                                                        *ngIf="formItem.fieldType
                                                        !== 1 &&
                                                        formItem.visible"
                                                        [ngClass]="{'espacement'
                                                        :
                                                        formItem.marginBottom}"
                                                        [ngStyle]="{'width':((screenService.isSmallScreen())?
                                                        '100%':formItem.width?
                                                        formItem.width
                                                        : '440px')
                                                        }">
                                                            <app-dx-field
                                                                *ngIf="formItem.dataField"
                                                                [hidden]="!formItem.visible
                                                                &&
                                                                !formItem.label.visible"
                                                                [editable]="isEditable"
                                                                [field]="formItem"
                                                                [item]="item"
                                                                [itemCopy]="itemCopy"
                                                                [service]="service"
                                                                [contextualHelpOn]="contextualHelpOn"
                                                                (canDeactivate)="onCanDeactivateChanged($event,
                                                                formItem)"
                                                                (valueChange)="onValueChanged($event)"
                                                                [templateDirective]="getTemplate(formItem)"
                                                                [id]="id"
                                                                (initSlidePanel)="onInitSlidePanel($event)"
                                                                [showPopup]="formItem.popupVisible"
                                                                [formFields]="dxFieldComponentList"(editButtonClickedEvent)="onEditButtonClicked($event)"
                                                                [editActionVisible]= "editActionVisible"
                                                                [dataGridPickerVisible] = "dataGridPickerVisible"
                                                                (addNewLineFromList)="onAddnewLineFromList($event)"
                                                                (checkTvaEvent)="onTvaValueChanged($event)"
                                                                (rowListClick)="onRowListClick($event)"
                                                                (exciseNumberEvent)="onExciseNumberChanged($event)"
                                                                (clickInfo)="onClickInfo()"
                                                                (updateLineFromList)="onUpdateLineFromList($event)"
                                                                (deleteLineFromList)="onDeleteLineFromList($event)">
                                                            </app-dx-field>
                                                        </div>
                                                        <div style="padding-bottom:10px;" *ngIf="getVisible(formItem) && formItem.LineBreak"></div>
                                                    </ng-template>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngTemplateOutlet="formFooterTemplate
                                            ? formFooterTemplate.template :
                                            null; context: { $implicit: {item:
                                            item, inPopup: inPopup, itemLoading:
                                            itemLoading} }">
                                        </ng-container>
                                    </dx-scroll-view>
                                    <div style="text-align: center;width:
                                        100%;height: 80px;padding: 25px;">
                                        <dx-button text="{{ 'form.cancel' |
                                            translate}}" (onClick)="cancel()"
                                            type="default"
                                            stylingMode="outlined"
                                            style="margin-left:10px;margin-right:10px;width:
                                            144px;">
                                        </dx-button>
                                        <dx-button text="CRÈER" type="default"
                                            [useSubmitBehavior]="false"
                                            (click)="save($event, 'validate',
                                            validationGroupe)"
                                            style="margin-left:10px;margin-right:10px;width:
                                            144px;">
                                        </dx-button>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </dx-validation-group>
                    <!-- </dx-scroll-view> -->
                    <div *ngIf="inPopup" class="m-b-05 m-r-05">
                        <!-- <dx-button [disabled]="!isEditable || formCanDeactivate || pendingSubmit"
                            class="validate-btn responsive-text-button button-has-text"
                            *ngIf="options.edit.showHeaderButton" [ngClass]="{'inpopup': inPopup}" type="success"
                            stylingMode="contained" [useSubmitBehavior]="false"
                            (click)="save($event, 'validate', validationGroupe)">
                                <ngc-icon size="24" icon="save"></ngc-icon>
                        </dx-button> -->
                    </div>
                </div>
            </div>

        </form>

        <div class="slide-panel" *ngIf="!inPopup">
            <app-slide-panel #slidePanel
                [slidePanelOptions]="slidePanelOptions"
                [slidePanelChildOptions]="slidePanelChildOptions"
                [visible]="isSlidePanelVisible" *ngIf="reloadSlidePanel"
                (validateSuccess)="onValidateSuccess($event)"
                (slidePanelClose)="onSlidePanelClose($event)"
                (beforeSaveSlidePanel)="onBeforeSaveSlidePanel($event)"
                (confimDialogYesSlidePanel)="onConfimDialogYesSlidePanel($event)"
                (confimDialogNoSlidePanel)="onConfimDialogNoSlidePanel($event)">
                {{slidePanelTemplateContent ? slidePanelTemplateContent.template
                : null
                }}
            </app-slide-panel>
        </div>

    </div>

</div>

<ngc-notify></ngc-notify>
