import { State, Society, ParameterSelected } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '../modules/devextreme/dx-form/models/validation-rules';

export enum UserType { SUPER_ADMINISTRATOR, ADMINISTRATOR, USER, ADMINISTRATION_IDSYSTEMES }
export const userTypeDataSource = [
    { 'text': 'Administrateur', 'value': UserType.ADMINISTRATOR },
    { 'text': 'Utilisateur', 'value': UserType.USER },

]
export enum CallingService {
    COMPANY = 1900,
    ARTICLE,
    USER,
    REPORT,
    SCHEDULER,
    CDN
}

export class User extends BaseEntity2 {
    email: string;
    password: string;
    name: string;
    firstName: string;
    state: State;
    type: UserType;
    cdnPhoto: string;
    nameFirstNameId: string;
    themeDX: string;
    societies: Society[];
    parametersSelected: ParameterSelected[];
    /** NO MAPPED : Attribut pour la création du template  */
    passwordSection: any;

    constructor() {
        super();
        this.cdnPhoto = '';
        this.name = '';
        this.firstName = '';
        this.email = '';
        this.state = State.ALLOWED;
        this.type = UserType.USER;
        this.password = '';
        this.nameFirstNameId = '';
        this.themeDX = '';
        this.societies = null;
        this.parametersSelected = null;
        this.passwordSection = null;

    }
    /*public static parseJson(json: any): User {
        return json != null ? {
            id: json.id,
            version: json.version,
            dateCreated: json.dateCreated,
            dateUpdated: json.dateUpdated,
            email: json.email,
            password: json.password,
            name: json.name,
            firstName: json.firstName,
            state: json.state,
            type: json.type,
            photo: json.photo,
            themeDX: json.themeDX,
            nameFirstNameId: json.name + ' ' + json.firstName + ' (' + json.id + ')', // Champ virtuel : pour l'affichage en IHM
            // un utilisateur a tjrs une société mais je laisse le test pour les futurs dev, pour le travail par analogie
            societies: (json.societies) ? json.societies.map((jsonSociety) => Society.parseJson(jsonSociety)) : null
        } : null;
    }*/

    public static getNameFirstNameId(user: User): string {
        return (user)
            ? (user.name ? user.name : null) + ' ' + (user.firstName ? user.firstName : null) + ' (' + user.id + ')' : '';
    }

    public static getType(value: UserType): string {
        let wording: string;
        if (value === UserType.SUPER_ADMINISTRATOR) {
            wording = 'Super administrateur';
        } else if (value === UserType.ADMINISTRATOR) {
            wording = 'Administrateur';
        } else if (value === UserType.USER) {
            wording = 'Utilisateur';
        } else {
            wording = 'Utilisateur';
        }
        return wording;
    }
    validator?(): ValidationRulesByFieldName {
        return {
            password: [
                { type: 'required', message: 'FormErrorMessages.imageOriginale', useTranslator: true }
            ],
            email: [
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true },
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            name: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            firstName: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            type: [
                { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }
}

export class UserOnline extends User {
    // idSociety_adminBase: number;
    // idUser_localBase: number;
    // user_adminBase: User;
    ip: string;
    idSessionASP: string;
    dateAuthentication: Date;
    listServicesCurrentlyUsed: CallingService[];

    constructor() {
        super();
        // this.idSociety_adminBase = 0;
        // this.idUser_localBase = 0;
        // this.user_adminBase = null;
        this.ip = '';
        this.idSessionASP = null;
        this.dateAuthentication = new Date();
        this.listServicesCurrentlyUsed = null;
    }

    /*public static parseJson(json: any): UserOnline {
        return json != null ? {
            idSociety_adminBase: json.idSociety_adminBase,
            idUser_localBase: json.idUser_localBase,
            user_adminBase: (json.user_adminBase) ? User.parseJson(json.user_adminBase) : null,
            ip: json.ip,
            idSessionASP: json.idSessionASP,
            dateAuthentication: json.dateAuthentication,
            listServicesCurrentlyUsed: (json.listServicesCurrentlyUsed) ? json.listServicesCurrentlyUsed : null
        } : null;
    }*/
}
