import { Output, Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScreenService implements OnDestroy {
    @Output() changed = new EventEmitter();
    private subscriptions: Subscription[] = [];

    constructor(private breakpointObserver: BreakpointObserver) {
        this.subscriptions.push(
            this.breakpointObserver
                .observe(
                    [Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]
                )
                .subscribe(
                    (val) => {
                        this.changed.next();
                    }
                )
        );
    }

    public isLargeScreen() {
        const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
        const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

        return isLarge || isXLarge;
    }

    public isSmallScreen() {
        const isSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);
        const isXSmall = this.breakpointObserver.isMatched(Breakpoints.Small);

        return isSmall || isXSmall;
    }

    public get sizes() {
        return {
            'handset': this.breakpointObserver.isMatched(Breakpoints.Handset),
            'tablet': this.breakpointObserver.isMatched(Breakpoints.Tablet),
            'tablet-portrait': this.breakpointObserver.isMatched(Breakpoints.TabletPortrait),
            'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
            'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
            'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
            'screen-large': this.isLargeScreen(),
        };
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
