/** 
 * Une fonction qui permet de ordonner un objet 
 * */
export function orderObject(obj) {
    if(obj) {
        if(obj != undefined || obj != null) {
            return Object.keys(obj).sort().reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        }
    }
    return 'undefiend';
}