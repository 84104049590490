import { DataGridActionColumn } from '../../data-grid/models/dataGridActionColumn';

export class NextDxFormOptions {

    navigateToCreateBtn: {
        showAlways: boolean;
        icon: string;
        text: string;
    };
    submitBtn: {
        show: boolean;
        text: string;
        disabled: boolean;
        message: string;
    };
    cancelBtn: {
        show : boolean;
        text : string;
    }

    create: {
        disableDefaultSaveEvent: boolean;
        disableDefaultSaveAndAddNewEvent: boolean;
        showHeaderButton: boolean;
        disableRedirect: boolean;
        text: string;
        icon: string;
    };

    edit: {
        disableDefaultSaveEvent: boolean;
        disableDefaultSaveAndAddNewEvent: boolean;
        showHeaderButton: boolean;
        showValidateButton: boolean;
        disableRedirect: boolean;
        onValidateDialogTexts: string[];
        showNavigateButtons: boolean;
        showUpdateButton: boolean;
        showPadlockOpen: boolean;
        showPadlockClosed: boolean;

    };

    delete: {
        disabled: boolean | ((item: any) => boolean);
        showHeaderButton: boolean;
        hintDisabledReason: string;
    };

    header: {
        labelFieldName: string,
        createPageTitle: string,
        showContextualHelpButton: boolean
    };

    dropdownItems: DataGridActionColumn;

    breadcrumb: {
        enabled: boolean
    };

    showHeader: boolean;

    css : {
        height: number;
    }
    checkBeforeSave: boolean;

    confirmDialog: {
        show: boolean;
        title: string;
        textYes: string;
        textNo: string;
        textCancel: string;
        textContent: string;
    }

    /**
     * initialise le paramétrage par défaut
     */
    constructor() {
        // new
        this.navigateToCreateBtn = {
            showAlways: false,
            icon: 'plus',
            text: 'form.Add'
        };
        this.submitBtn= {
            show: false,
            text: '',
            disabled: false,
            message: ''
        };
        this.cancelBtn = {
            show : false,
            text : ''
        }
        // save and new
        this.create = {
            disableDefaultSaveEvent: false,
            disableDefaultSaveAndAddNewEvent: false,
            showHeaderButton: true,
            disableRedirect: false,
            text: 'form.SaveAndNew',
            icon: 'plus'
        };

        this.edit = {
            disableDefaultSaveEvent: false,
            disableDefaultSaveAndAddNewEvent: false,
            showHeaderButton: true,
            showValidateButton: false,
            disableRedirect: false,
            onValidateDialogTexts: null,
            showNavigateButtons: true,
            showUpdateButton: false,
            showPadlockOpen: false,
            showPadlockClosed: false
        };

        this.delete = {
            disabled: false,
            showHeaderButton: true,
            hintDisabledReason: null
        };

        this.header = {
            labelFieldName: 'designation',
            createPageTitle: '',
            showContextualHelpButton: true
        };

        this.breadcrumb = { enabled: true };
        this.dropdownItems = new DataGridActionColumn();
        this.showHeader = true;

        this.css = {
            height: null
        }
        this.checkBeforeSave = true;
        this.confirmDialog = {
            show: false,
            title: '',
            textYes: '',
            textNo: '',
            textCancel: '',
            textContent: ''
        }
    }
}
