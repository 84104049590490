import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef, OnDestroy } from '@angular/core';
import { IDaoBaseService } from '@app/shared/interface';
import { take } from 'rxjs/operators';
import { FormField, IDaoServiceOptions } from '../dx-form/models';
import { concatDefinedArrray, Deferred, isDefined } from '@app/utils';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { notifySuccessDelay } from '@app/globalsParameter';
import { Tools } from '@app/shared/class/tools';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { loadDataType } from '../data-grid/models';
import { SingleColumnFilter, LookupColumnFilter, GlobalColumnFilter, DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { PaginationSet } from '@app/shared/viewModel/pagination-set';
import { DxListColumn } from './models/dxListColumn';
import { confirmDialog } from '@app/utils/dialogs';
import { Subscription } from 'rxjs';
registerLocaleData(localeFr, 'fr');

@Component({
    selector: 'app-dx-list',
    templateUrl: './dx-list.component.html',
    styleUrls: ['./dx-list.component.scss']
})
export class DxListComponent implements OnInit, OnChanges, OnDestroy {

    /** le service liés pour les données a afficher */
    @Input() service: IDaoBaseService;
    @Input() serviceOptions?: IDaoServiceOptions;
    @Input() customFields?: DxListColumn[] = [];
    @Input() searchingFields?: string[] = [];
    @Input() allowItemDeleting?: boolean;
    /** (optional input)pour les liste qui contien icon de retour pour annuler les modifications */
    @Input() withLeftArrow?: boolean;
    @Input() titleIsCentered = false;
    @Input() listForForm = false;
    /** (optional input)list qui contient un champs de recherche */
    @Input() withSearchBar = false;
    @Input() listName = '';
    // @Input() icon: string;
    @Input() set icon(icon: string) { this._icon = icon; }
    @Input() loadDataType?: loadDataType = 'templateSide';
    /** (optional input) nom du champ qui défini la clé primaire. Si non défini on prend id par défaut*/
    @Input() primaryKeyFieldName?: string = 'id';
    @Input() canAddNewLine?: boolean = false;
    @Input() shouldDisableAddNewLineBtn? : boolean | (() => boolean);
    @Input() canUpdateAndDelete?: boolean = false;
    /** le model pour extracter les champs */
    @Input() set model(value: any) { this._model = value; }
    /**Style du dxlist */
    @Input() cssClass: string;

    @Input() dataSource: DataSource;
    @Input() height: string;
    @Input() titleForMail = false;

    /**pour les liste qui contiens des checkBox et icon de retour pour annuler les modifications */
    @Output() checkBoxValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectAllValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBackClickEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() addNewLine: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onListRowClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateLineFromList: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteLineFromList: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('container', { static: true }) container: ElementRef;

    private subscriptions: Subscription[] = [];
    private _model: any;

    _icon: string;
    containerHeight: any;
    fields: any[] = [];
    toolbarItems = [];

    get ShouldDisableAddNewLineBtn(): boolean {
        if ('boolean' === typeof this.shouldDisableAddNewLineBtn) {
            return this.shouldDisableAddNewLineBtn;
        } else if ('function' === typeof this.shouldDisableAddNewLineBtn) {
            return this.shouldDisableAddNewLineBtn();
        } else {
            return this.shouldDisableAddNewLineBtn;
        }
    }

    constructor(
        private translate: TranslateService
    ) {
        this.toolbarItems = [
            {
                id: 1,
                value: 1,
                name: this.translate.instant('menuAction.modify'),
                icon: 'modify'
            },
            {
                id: 2,
                value: 2,
                name: this.translate.instant('menuAction.delete'),
                icon: 'trash'
            }
        ];
    }

    ngOnInit(): void {
        if (this.loadDataType !== 'async') {
            this.preparefields();
            this.loadData();
        }
    }

    onBackClick(): void {
        this.onBackClickEvent.emit();
    }

    onAddNewLine(): void {
        this.addNewLine.emit(true);
    }

    getHeight(): number {
        return (window.innerHeight - 500);
    }

    handleValueChange(event: any, item: any): void {
        const param: string[] = [event, item]
        this.checkBoxValueChanged.emit(param);
    }

    selectAllValueChange(event: any, field: any): void {
        const param: string[] = [event, field]
        this.selectAllValueChanged.emit(param);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loadDataType === 'async' && this.dataSource) {
            this.fields = [];
            this.preparefields();
        }
        if (changes && changes.serviceOptions && !changes.serviceOptions.firstChange) {
            this.fields = [];
            this.preparefields();
            this.loadData();
        }
    }

    getClass(field: DxListColumn) {
        let classCss = 'cell';
        if (field.cssClass && field.cssClass !== 'item-padding-right') {
            classCss = field.cssClass;
        }
        return classCss;
    }

    preparefields() {
        for (let index = 0; index < this.customFields.length; index++) {
            if (!this.customFields[index]['type']) {
                this.fields.push({
                    caption: this.customFields[index]['caption'],
                    fieldName: this.customFields[index]['name'],
                    type: this.customFields[index]['type'] ? this.customFields[index]['type'] : Tools.getType(this._model[this.customFields[index]['name']]),
                    cssClass: this.customFields[index]['cssClass'] ? this.customFields[index]['cssClass'] : ''
                });
            } else if (this.customFields[index]['type'] === 'object') {
                this.fields.push({
                    caption: this.customFields[index]['caption'] ? this.customFields[index]['caption'] : this.customFields[index]['displayValue'],
                    fieldName: this.customFields[index]['name'],
                    type: this.customFields[index]['type'] ? this.customFields[index]['type'] : Tools.getType(this._model[this.customFields[index]['name']]),
                    displayValue: this.customFields[index]['displayValue'] ? this.customFields[index]['displayValue'] : null,
                    displayValueCallback: this.customFields[index]['displayValueCallback'] ? this.customFields[index]['displayValueCallback'] : null,
                    cssClass: this.customFields[index]['cssClass'] ? this.customFields[index]['cssClass'] : ''
                });
            } else {
                this.fields.push({
                    caption: this.customFields[index]['caption'],
                    fieldName: this.customFields[index]['name'],
                    type: this.customFields[index]['type'] ? this.customFields[index]['type'] : Tools.getType(this._model[this.customFields[index]['name']]),
                    displayValue: this.customFields[index]['displayValue'] ? this.customFields[index]['displayValue'] : null,
                    values: this.customFields[index]['values'] ? this.customFields[index]['values'] : null,
                    cssClass: this.customFields[index]['cssClass'] ? this.customFields[index]['cssClass'] : '',
                    value: this.customFields[index]['value'] ? this.customFields[index]['value'] : null,
                    insideNestedObject: this.customFields[index]['insideNestedObject'] ? this.customFields[index]['insideNestedObject'] : false,
                });
            }
        }
    }

    getNestedObject(parent: any, path: string) {
        if (!parent) {
            return '';
        }
        const keyArr = path.split('.');
        let tmp = parent;
        for (let index = 0; index < keyArr.length; index++) {
            const key = keyArr[index];
            if (!isDefined(tmp[key])) {
                return '';
            }
            tmp = tmp[key];
        }
        return tmp.toString() || '';
    }

    getEnumInsideNestedObject(values , parent: any, path: string) {
        const value = this.getNestedObject(parent, path);
        return values[+value]['designation'];
    }

    loadData() {
        if (this.loadDataType === 'serverSide') {
            const store = new CustomStore({
                load: (loadOptions: any) => {
                    let beginning = 0;
                    if (loadOptions.skip) {
                        beginning = loadOptions.skip;
                    }
                    const filter: Array<SingleColumnFilter> = [];
                    const filterHeader: Array<LookupColumnFilter> = [];
                    // filtre globale ( 1ér objet de la liste)
                    const filterGlobal: Array<GlobalColumnFilter> = [];

                    let numberOfTuples = 'all';
                    let trisField = null;
                    let trisStrategie = 'asc';
                    if (!loadOptions.isLoadingAll) {
                        numberOfTuples = loadOptions.take || 100;
                    }
                    if (loadOptions.sort) {
                        trisField = loadOptions.sort[0].selector;
                        if (loadOptions.sort[0].desc) {
                            trisStrategie = 'desc';
                        }
                    }
                    // gestion du filtre global
                    if (loadOptions.filter) {
                        let dxGridFilterGlobal: Array<any>;
                        if (loadOptions.filter.length > 3 && Array.isArray(loadOptions.filter) && loadOptions.filter[1] === 'or') {
                            dxGridFilterGlobal = loadOptions.filter;
                        } else {
                            // la recherche global est toujours le dernier element du tableau avec un or comme 1er operateur
                            dxGridFilterGlobal = loadOptions.filter.find(x => x.length > 3 && Array.isArray(x) && x[1] === 'or');
                        }
                    }
                    if (loadOptions.dataField.includes('.')) { // Alimentation des données des header filter
                        const loadOptionsDataField = loadOptions.dataField;
                        const dataFieldName = loadOptionsDataField.split('.')[0];
                        const dataFieldValue = loadOptionsDataField.split('.')[1];
                        const dataSource = [];
                    }

                    const params: DataGridFiltersParam = new DataGridFiltersParam();
                    params.globalSearch = filterGlobal;
                    params.lookupSearch = filterHeader;
                    params.singleColumnSearch = filter;
                    this.service.trisField = trisField;
                    this.service.trisStrategie = trisStrategie;
                    this.service.beginning = beginning;
                    this.service.numberOfTuples = numberOfTuples;
                    this.service.params = params;
                    return this.service.getByFilter(...this.getAllParams)
                        .toPromise()
                        .then((data: PaginationSet<any, any>) => {
                            this.service.list = data.items;
                            if (data.redisKeyPrint) {
                                this.service.redisKeyPrint = data.redisKeyPrint;
                            }

                            return {
                                data: data.items || [],
                                // summary: response.itemsSummary,
                                totalCount: data.totalFiltered
                            };
                        })
                        .catch((error: any) => {
                            throw new Error('Data Loading Error');
                        });
                },
                remove: (key: any) => {
                    return this.service.delete(key[this.primaryKeyFieldName], ...this.getGlobalParams).toPromise()
                        .then((data: any) => {
                            notify('Suppression effectuée', 'success', notifySuccessDelay);
                            return null;
                        })
                        .catch((error: any) => {
                            throw new Error('Error Delete');
                        });
                }
            });
            this.dataSource = new DataSource(store);
        } else if (this.loadDataType !== 'async') {
            this.subscriptions.push(
                this.service.getAll(...this.getAllParams).pipe(take(1)).subscribe(
                    (result: any[]) => {
                        this.dataSource = new DataSource(result);
                    }
                )
            );
        }

    }

    onItemClick(event) {
        this.onListRowClick.emit(event);
        //this.service.navigateToDetail(event.itemData['id'], ...this.getNavigateToDetailPageParams);
    }

    create() {
        this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
    }

    onItemDeleting(event: any): void {
        if (event && event.itemData && event.itemData.companyAddressOrder === 1) {
            event.cancel = true;
            notify('Interdit de supprimer l\'adresse par défaut');
            return;
        }

        const d = new Deferred<any>();
        event.cancel = d.promise;
        const result = confirmDialog(this.translate.instant('confirmChanges'), this.translate.instant('YES'),
            this.translate.instant('NO'), this.translate.instant('msgConfirmChanges'));
        result.show().then((dialogResult) => {
            if (dialogResult) {
                this.getGlobalParams.push(event.itemData['companyId']);
                this.subscriptions.push(
                    this.service.delete(event.itemData['id'], ...this.getGlobalParams).pipe(take(1)).subscribe(
                        () => {
                            notify('Suppression effectuée', 'success', notifySuccessDelay);
                            d.resolve();
                        },
                        (error: any) => {
                            console.log('reach error');
                            d.resolve(true);
                        }
                    )
                );
            } else {
                d.resolve(true);
            }
        });
    }

    onDropDownClick(event): void {
        if (event.itemData.id === 1) {
            this.updateLineFromList.emit(event);
        } else if (event.itemData.id === 2) {
            this.deleteLineFromList.emit(event);
        }
    }

    get getGlobalParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams && this.serviceOptions.methodsParams.global) {
            return this.serviceOptions.methodsParams.global;
        }
        return [];
    }

    get getAllParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.getAll);
        }
        return [];
    }

    get getNavigateToDetailPageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToDetail);
        }
        return [];
    }

    get getNavigateToCreatePageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToCreatePage);
        }
        return [];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
