export class DataGridFiltersParam {
    /**
     *
     */
    constructor() {
        this.globalSearch = new Array<GlobalColumnFilter>();
        this.lookupSearch = new Array<LookupColumnFilter>();
        this.singleColumnSearch = new Array<SingleColumnFilter>();
    }

    globalSearch: Array<GlobalColumnFilter>;
    lookupSearch: Array<LookupColumnFilter>;
    singleColumnSearch: Array<SingleColumnFilter>;
}

export class ColumnFilter {
    /** Nom du champ */
    dataField: string;


    // filterValues?: Array<string>;
}

// TODO
export class LookupColumnFilter extends ColumnFilter {
    /** valeur du champ a filtrer */
    filterValue?: Array<string>;
    filterValues: Array<string>;

    // ajout des données front pour la gestion des types enum
    dataType?: unknown;
    enumType?: unknown;
    translateSource?: string;
    origin?: 'advance' | 'dataGrid';
    caption: string;

}

export class SingleColumnFilter extends ColumnFilter {

    /// <summary>
    /// type du filtre a appliquer ("contains")
    /// </summary>
    selectedFilterOperation: string;

    /// <summary>
    /// ???
    /// </summary>
    filterValueRange: Array<string>;

    /** valeur du champ a filtrer */
    filterValue: string | number;

    /** défini d'où le filtre est appelé*/
    origin?: 'advance' | 'dataGrid';

    /** défini le type de filterValue */
    type?: 'number' | 'date' | 'string' | 'boolean' | 'enum' | 'ignoreSelectedFilterOperation';

    /** défini le type de enum */
    enumType?: unknown;

    /** défini la source du traduction */
    translateSource?: string;

    /** défini le tootip du filtre */
    caption: string;

    showFilterBadge?: boolean;
    
    displayChips?: string;

}

export class GlobalColumnFilter extends ColumnFilter {
    /// <summary>
    /// type du filtre a appliquer ("contains")
    /// </summary>
    selectedFilterOperation: string;
    /** valeur du champ a filtrer */
    filterValue: string;
}
