import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';
import { BaseEntity2, Tax } from 'app/shared/model';

export class TaxToDate extends BaseEntity2 {
    beginDate: Date;
    endDate: Date;
    coefficient: number;
    vesMin: number;
    vesMax: number;
    tax: Tax;
    createdByUser: boolean;

    constructor() {
        super();
        this.beginDate = new Date();
        this.endDate = new Date();
        this.coefficient = 0;
        this.vesMin = 0;
        this.vesMax = 0;
        this.tax = null;
        this.createdByUser = true;
    }



}
