import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, HostListener, AfterViewChecked, Renderer2, SimpleChanges, ViewChild, QueryList, ViewChildren } from '@angular/core';
import notify from 'devextreme/ui/notify';
import * as globalsParameter from '@app/globalsParameter';
import { Tools } from '@app/shared/class/tools';
import { DetailSet } from '@app/shared/viewModel/detailSet';
import { TranslateService } from '@ngx-translate/core';
import { ErrorsService, ScreenService, AppContextService } from '@app/core/services';
import { KeyedTemplateDirective } from '@app/shared/directive/keyed-template-directive/keyed-template.directive';
import { ItemplateHostComponent } from '@app/shared/directive/keyed-template-directive/i-template-host.component';
import { NextDxFormOptions } from './models/next-dx-form-options';
import { FormFieldObject, FormField, IDaoServiceOptions, EditorOptions } from './models/form-field';
import { BreadcrumbItem } from '@app/shared/component/breadcrumb/models/breadcrumb';
import { FormGroupItem } from './models/form-group';
import { concatDefinedArrray } from '@app/utils/daoHelper';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { ConfScreenService } from '@app/core/services/admin/conf-screen.service';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import validationEngine from 'devextreme/ui/validation_engine';
import { HttpErrorResponse } from '@angular/common/http';
import { FormSubmitEvent } from './models/form-submit-event';
import { BehaviorSubject, Subscription, Observable, of, Subject } from 'rxjs';
import { ScreenIdentifier } from '@app/shared/model/admin/screen-type';
import { HeaderInfo } from '@app/shared/component/left-header-bloc/models';
import { cloneObject } from '@app/utils/cloningObject';
import * as $ from 'jquery';
import { FunctionalCaution } from '@app/shared/model/functionalCaution';
import { SlidePanelComponent } from '../slide-panel/slide-panel.component';
import { showAlertsNGCResults, confirmDialog, CustomConfirmDialogResult } from '@app/utils/dialogs';
import { NGCResult, NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { User } from '@app/shared/model';
import { SlidePanelModel } from '../slide-panel/slide-panel-model';
import { DxFieldComponent } from '../dx-field/dx-field.component';
import { ApplicationMode } from '@app/shared/model/base-entity1';
import { SlidePanelChildOptions } from '../genericComponentOption';

export enum SavingStateEnum {
    NO,
    YES,
    CANCEL
}

@Component({
    selector: 'app-dx-form',
    templateUrl: './dx-form.component.html',
    styleUrls: ['./dx-form.component.scss'],
})
export class DxFormComponent implements ItemplateHostComponent, OnChanges, OnInit, OnDestroy, AfterViewChecked {

    private idItems: number[] = [];
    private _excludedFieldList: string[] = [];
    private idsLoaded = false;
    private subscriptions: Subscription[] = [];
    private alwaysDisabledFields: Array<string> = ['dateCreated', 'dateUpdated', 'id', 'version'];

    public formCanDeactivate = true;
    public templateDirectiveMap: Map<string, any> = new Map<string, any>();
    public items: any[] = [];
    public item: any = {};
    public itemCopy: any = {};
    public itemLoading = new BehaviorSubject(false);
    public idPrevious: string = null;
    public idNext: string = null;
    public formItems: FormGroupItem[] | FormField[] = [];
    public formBuilded = false;
    public tabs: { text: string, visibleIndex: number, indexBlock: number }[];
    public activeTab = 0;
    public additionalButtonsVisibility = false;
    public cssClasses: any;
    public contextualHelpOn = false;
    public pendingSubmit = false;
    public showTabs = true;
    public savingState = new BehaviorSubject<SavingStateEnum>(SavingStateEnum.NO);
    errorList: FunctionalCaution[] = [];
    validForm = true;
    navigateId?: string | number;
    tabHelpVisible: boolean[] = [];
    subBlockHelpVisible: boolean[] = [];
    scrHeight: any;
    userConnected: User = null;
    formFieldForSlidePanel: FormField | SlidePanelModel | any;
    dataGridOptions?: EditorOptions;
    isSlidePanelVisible = false;
    reloadSlidePanel = true;
    errorCard = false;
    
    itemIsEmitted = false;
    @ViewChild('validationGroupe', { static: false }) validationGroupe: DxValidationGroupComponent;
    @ViewChild('slidePanel', { static: false }) slidePanelComponent: SlidePanelComponent;
    @ViewChildren(DxFieldComponent) dxFieldComponentList: QueryList<DxFieldComponent>;

    @Input() breadcrumb: (BreadcrumbItem | string)[];
    @Input() id?: string | number;
    @Input() idToDuplicate?: string;
    @Input() service?: IDaoBaseService;
    @Input() serviceOptions?: IDaoServiceOptions;
    @Input() loadDataType?: 'serverSide' | 'templateSide' | 'simple' = 'templateSide';
    @Input() model: any;
    @Input() customFields?: FormFieldObject = {};
    @Input() groups?: FormGroupItem[];
    @Input() screenIdentifier: ScreenIdentifier;
    @Input() primaryKeyFieldName?: string = 'id';
    @Input() options?: NextDxFormOptions = new NextDxFormOptions();
    @Input() inPopup?: boolean = false;
    @Input() editable: boolean | ((item: any, editForm: boolean) => boolean) = true;
    @Input() headerInfo: HeaderInfo;
    @Input() returnButton: string;
    @Input() set excludedFieldList(list: string[]) { this._excludedFieldList = list; }
    @Input() popupContentHeight: number = null;
    @Input() slidePanelOptions?: SlidePanelModel = new SlidePanelModel();
    @Input() slidePanelOptionsSubject: Subject<SlidePanelModel> ;
    @Input() isDuplication: boolean;
    @Input() editActionVisible = false;
    @Input() dataGridPickerVisible = false;
    @Input() slidePanelChildOptions: SlidePanelChildOptions = new SlidePanelChildOptions();

    @Output() submit = new EventEmitter<FormSubmitEvent>();
    @Output() formDataChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() canDeactivate = new EventEmitter<boolean>();
    @Output() validateSuccess: EventEmitter<any> = new EventEmitter<any>();

    @Output() afterClone: EventEmitter<any> = new EventEmitter<any>();
    @Output() specificRedirection: EventEmitter<any> = new EventEmitter<any>();

    @Output() validateSuccessSlidePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() dropDownClick: EventEmitter<any> = new EventEmitter();
    @Output() GenerateKeyBarCodeForm: EventEmitter<any> = new EventEmitter();
    @Output() addNewLineFromListSlidePanel: EventEmitter<boolean> = new EventEmitter();
    @Output() rowListClick: EventEmitter<any> = new EventEmitter();
    @Output() slidePanelClosed: EventEmitter<boolean> = new EventEmitter();
    @Output() beforeSaveSlidePanel: EventEmitter<any> = new EventEmitter();
    @Output() confimDialogYesSlidePanel: EventEmitter<any> = new EventEmitter();
    @Output() confimDialogNoSlidePanel: EventEmitter<any> = new EventEmitter();
    @Output() editButtonClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() checkTvaEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() exciseNumberEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickInfo: EventEmitter<any> = new EventEmitter<any>();
    @Output() addLineFromList: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateLineFromList: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteLineFromList: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemLoaded: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _translate: TranslateService,
        // private _authService: AuthService,
        private _errorsSrv: ErrorsService,
        private confScreenService: ConfScreenService,
        private appContext: AppContextService,
        private renderer: Renderer2,
        public screenService: ScreenService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.userConnected = this.appContext.getPersisted('user');
        this.getScreenSize();
        this.subscriptions.push(
            this.canDeactivate.subscribe(
                (val: boolean) => {
                    this.formCanDeactivate = val;
                }
            )
        );
    }

    ngOnInit(): void {
        if (this.tabs) {
            for (let index = 0; index < this.tabs.length; index++) {
                this.tabHelpVisible.push(false);
            }
        }

        if (this.filterGroupWithItems) {
            for (let index = 0; index < this.filterGroupWithItems.length; index++) {
                this.subBlockHelpVisible.push(false);
            }
        }

        if (this.inPopup === true) {
            this.options.breadcrumb.enabled = false;
        }
        if (this.options.header.showContextualHelpButton && this.screenIdentifier === undefined) {
            this.options.header.showContextualHelpButton = false;
        }
        if (this.slidePanelOptionsSubject) {
            //this.subscriptions.push(
                this.slidePanelOptionsSubject.subscribe(
                    event => {
                        this.onInitSlidePanel(event);
                    }
                )
           // );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.prepareFieldForm();
        if (changes.customFields && !changes.customFields.firstChange) {
            this.formBuilded = false;
            this.formItems = [];
            this.prepareFieldForm();
        }
        if (
            this.id &&
            ((changes.id && changes.id.firstChange) || !this.items || this.loadDataType === 'serverSide' || this.loadDataType === 'simple') ||
            this.idToDuplicate &&
            ((changes.idToDuplicate && changes.idToDuplicate.firstChange) || !this.items || this.loadDataType === 'serverSide' || this.loadDataType === 'simple') ||
            (changes.id && changes.id.currentValue && changes.id.previousValue && changes.id.currentValue !== changes.id.previousValue)
        ) {
            this.fetchData();
        } else if (this.id) {
            this.manageCurrentItemTemplateSide();
        }
        this.manageCssClasses();
    }

    manageCurrentItemTemplateSide() {
        this.item = cloneObject(this.items.find((item: any) => item[this.primaryKeyFieldName] === this.id));
        if (!this.item) {
            this.itemNotFoundAndRedirect();
        }
        this.itemCopy = cloneObject(this.item);
        this.canDeactivate.emit(true);
        this.itemLoading.next(false);
        this.prepareVariableForTemplateSideNavigation();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
        if (this.slidePanelOptionsSubject) {
            this.slidePanelOptionsSubject.unsubscribe();
        }
    }

    get templateHeaderButton() {
        return this.templateDirectiveMap.has('templateHeaderButton') ? this.templateDirectiveMap.get('templateHeaderButton').templateDirective : null;
    }

    get middleHeaderTemplate() {
        return this.templateDirectiveMap.has('middleHeaderTemplate') ? this.templateDirectiveMap.get('middleHeaderTemplate').templateDirective : null;
    }

    get cardState() {
        return this.templateDirectiveMap.has('cardState') ? this.templateDirectiveMap.get('cardState').templateDirective : null;
    }

    get headerLeftBlocTemplate() {
        return this.templateDirectiveMap.has('headerLeftBloc') ? this.templateDirectiveMap.get('headerLeftBloc').templateDirective : null;
    }
    get formFooterTemplate() {
        return this.templateDirectiveMap.has('formFooter') ? this.templateDirectiveMap.get('formFooter').templateDirective : null;
    }

    /**
     * this function is called from the template directive to add a template to this component
     * @param key identifiant du template
     * @param templateDirective directive contenant le template
     */
    public registerTemplate = function (key: string, templateDirective: KeyedTemplateDirective): void {
        if (!this.templateDirectiveMap) {
            this.templateDirectiveMap = new Map<string, any>();
        }
        const keyedTemplate = {
            key: key,
            templateDirective,
        };
        this.templateDirectiveMap.set(key, keyedTemplate);
    };

    public setItem(item: any) {
        this.item = item;
        this.itemCopy = cloneObject(this.item);
        this.canDeactivate.emit(false);
    }

    /**
     * récupére le template qui va remplacer celui par défaut de l'input si il existe
     * @param formItem l'objet identifiant le champ
     */
    getTemplate(formItem: FormField): any {
        const keyedTemplate = { field: null };
        keyedTemplate.field = this.templateDirectiveMap.has(formItem.dataField) ? this.templateDirectiveMap.get(formItem.dataField).templateDirective : null;
        if (formItem.template && formItem.template.length > 0) {
            formItem.template.forEach(templateName => {
                keyedTemplate[templateName] = this.templateDirectiveMap.has(templateName) ? this.templateDirectiveMap.get(templateName).templateDirective : null;
            });
        }
        return keyedTemplate;
    }

    private itemNotFoundAndRedirect() { // TODO a verifier le message d'erreur avec la traduction
        this.service.navigateToList(...this.getNavigateToListPageParams);
        notify('Item not found', 'error', globalsParameter.notifyErrorDelay);
    }

    /**
     *  méthode utilise pour appler le web service specifique afin de recuperer les données de l'objet en cas de modification
     */
    private async fetchData(): Promise<void> {
        this.itemLoading.next(true); // = true;
        if (this.loadDataType === 'serverSide') {
            if (this.service.list && this.service.list.length > 0) {
                this.items = this.service.list;
                const searchItem = this.items.find((item: any) => item[this.primaryKeyFieldName] == this.id);
                if (!searchItem) {
                    await this.getItemsAndIds();
                    return;
                } else {
                    this.item = cloneObject(searchItem);
                    if (!this.item) {
                        this.itemNotFoundAndRedirect();
                    }
                    this.itemCopy = cloneObject(this.item);
                    this.canDeactivate.emit(true);
                    this.itemLoading.next(false);
                    this.prepareVariableForServerSideNavigation();
                }
            } else {
                this.getItemsAndIds();
                return;
            }
        } else if (this.loadDataType === 'templateSide') {

            this.subscriptions.push(
                (this.serviceOptions && this.serviceOptions.getAll ? this.serviceOptions.getAll : this.service.getAll(...this.getAllParams)).subscribe(
                    (items: any[]) => {

                        this.items = items;
                        this.manageCurrentItemTemplateSide();
                    },
                    (error: any) => {
                        this.itemLoading.next(false);
                    }
                )
            );
        } else if (this.loadDataType === 'simple') {
            if (this.id) {
                if(!this.itemIsEmitted){
                    this.subscriptions.push(
                        this.service.get(this.id, ...this.getAllParams).subscribe(
                            (item: any) => {
                                this.item = item;
                                if (!this.item) {
                                    this.itemNotFoundAndRedirect();
                                }
                                this.itemCopy = cloneObject(this.item);
                                this.canDeactivate.emit(true);
                                //To prevent multiple calls or a get OR to know when the item has been loaded
                                if(!this.itemIsEmitted){
                                    this.itemLoaded.emit(this.itemCopy);
                                }                    
                                this.itemIsEmitted = true
                                this.itemLoading.next(false);
                            },
                            (error: any) => {
                                this.itemLoading.next(false);
                                console.log('fetchData: ', error)
                            }
                        )
                    );
                }
            } else {
                this.subscriptions.push(
                    this.service.get(this.idToDuplicate, ...this.getAllParams).subscribe(
                        (item: any) => {
                            this.item = item;
                            if (!this.item) {
                                this.itemNotFoundAndRedirect();
                            }
                            this.afterClone.emit(item);
                            this.itemCopy = cloneObject(this.item);
                            this.canDeactivate.emit(false);
                            this.itemLoading.next(false);
                            // this.itemLoading = false;
                        },
                        () => {
                            this.itemLoading.next(false);
                            // this.itemLoading = false;
                        }
                    )
                );
            }
        }
        //this.validateFormWithGroup();
    }

    private manageFieldsForModel(model: any, customFields: FormFieldObject | FormField[], item: any, itemCopy: any): any {
        let fields: any[] = (Object.getOwnPropertyNames(model));
        fields = fields.filter(el => {
            return this._excludedFieldList.indexOf(el) === -1;
        });
        let modelValidator = {};
        if (model.validator !== undefined) {
            const applicationMode = this.userConnected.societies.length > 0 ? this.userConnected.societies[0].applicationMode : ApplicationMode.COMMUN
            modelValidator = model.validator(applicationMode);
        }
        let initValues = {};
        if (model.initValues !== undefined) {
            initValues = model.initValues();
        }

        fields.forEach((field: string, index: number) => {
            if (!(customFields && customFields[field] && customFields[field].subFields)) {
                // si on est pas dans un sous-formulaire
                this.manageField(field, customFields, index, modelValidator, model, item, initValues);
                // regarder si le champ est un champ groupé
                if (model && model.specificFieldForConfscreen && model.specificFieldForConfscreen.groupsField[field]) {
                    // on mémorise l'infos dans le customField pour l'avoir dans le merge du conf_screen
                    customFields[field].groupsField = model.specificFieldForConfscreen.groupsField[field];
                }
            } else if (customFields[field].subFields) {
                if (!item[field]) {
                    item[field] = customFields[field]['editorOptions']['model'];
                    itemCopy[field] = cloneObject(item[field]);
                }
                this.manageFieldsForModel(customFields[field]['editorOptions']['model'], customFields[field].subFields, item[field], itemCopy[field]);
            }
        });
    }


    private manageField(field: any, customFields: FormFieldObject | FormField[], index, modelValidator, model, item: any, initValues): any {
        let dxiItem: FormField;
        if (customFields[field] && !customFields[field].mergeConfig) {
            dxiItem = customFields[field];
        } else {
            dxiItem = {
                dataField: field,
                editorType: Tools.getDxFormInputType(model[field]),
            };
        }

        if (dxiItem.visibleIndex == null || dxiItem.visibleIndex === undefined) {
            dxiItem.visibleIndex = index;
        }
        if (dxiItem.visible === undefined) {
            dxiItem.visible = true;
        }
        // concatener les validation dans le model + les validation du composant principale
        dxiItem.validationRules = concatDefinedArrray(modelValidator[dxiItem.dataField], dxiItem.validationRules);
        dxiItem.isRequired = dxiItem.validationRules && dxiItem.validationRules.some(t => t.type === 'required');
        if (!dxiItem.label) {
            dxiItem.label = { text: this._translate.instant('model.' + dxiItem.dataField), visible: dxiItem.visible };
        } else {
            dxiItem.label = { text: dxiItem.label.text, visible: dxiItem.label.visible };
        }

        // défault (always config)
        dxiItem.editorOptions = customFields[field] ? customFields[field].editorOptions : null;
        dxiItem.itemType = 'simple';
        if (customFields[field] && customFields[field].mergeConfig) {
            customFields[field] = { ...dxiItem, ...customFields[field] };
        } else {
            customFields[field] = dxiItem;
        }
        // disactive le champ clé primaire en mode edition.
        if (field === this.primaryKeyFieldName && this.id) {
            customFields[field].disabled = true;
        }

        // disactive les champs always disabled.
        if (this.alwaysDisabledFields.some(f => f === field)) {
            customFields[field].disabled = true;
            if (field === 'dateCreated' || field === 'dateUpdated') {
                customFields[field].editorOptions = {
                    type: 'datetime'
                };
            }

            // masquer les champs en mode création
            if (!this.id) {
                customFields[field].visible = false;
            }
        }
        // initialiser les valeur par défaut à partir du this.customFields[field].editorOptions.value
        if (
            !this.id && customFields[field].editorOptions &&
            customFields[field].editorOptions.value != null &&
            customFields[field].editorOptions.value !== undefined
        ) {
            item[field] = this.initValuesFromCustomField(customFields[field], item[field]);
        } else if (!this.id && initValues[field]) {
            item[field] = initValues[field];
        }
    }

    /**
     * hide subBlock if all fields visibility is false
     * @param subBlockItems
     */
    hideSubBloc(subBlockItems: FormGroupItem[]): boolean {
        if (subBlockItems?.length > 0 && subBlockItems?.find((item) => item.items?.length > 0) !== undefined ){
            return subBlockItems.find((item) => item.items.length > 0).items.every((item) => item.visible === false);
        } else {
            return subBlockItems.every((item) => item.visible === false);
        }
    }


    /**
     *
     * @param event event :{event, method: validate | create}
     */
    saveFromHeader(event: { event: any, method: 'validate' | 'create' }) {
        this.save(event.event, event.method, this.validationGroupe);
    }

    private prepareFieldForm(): void {
        if (this.formItems.length === 0 && !this.formBuilded) {
            this.manageFieldsForModel(this.model, this.customFields, this.item, this.itemCopy);
            // si il existe un screenIdentifier : fusionner les configuration
            if (typeof this.screenIdentifier !== 'undefined' && this.screenIdentifier !== null) {
                this.subscriptions.push(
                    this.confScreenService.getByScreen(this.screenIdentifier).subscribe(
                        result => {
                            if (result) {
                                const formLayout = this.confScreenService.buildFormLayout(result);
                                this.tabs = formLayout.tabs;
                                this.formItems = this.confScreenService.mergeFormFieldObjectWithFormGroupItem(formLayout.formItems, this.customFields);
                                this.formBuilded = true;
                                this.manageCssClasses();

                            } else { // si jamais  on arrive ici cad le back n'as pas arriver à nous fournir la config de cette écran => obliger de retourner une exception
                                // TODO traduire
                                notify('Une erreur technique est servenue ! Merci de contacter l\'administrateur du site !');
                                throw new Error('Une erreur technique est servenue ! Merci de contacter l\'administrateur du site !');
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    )
                );
            } else {
                this.formItems = Object.values(this.customFields) as FormField[];
                this.formItems.sort((t1, t2) => t1.visibleIndex - t2.visibleIndex);
                if (typeof this.groups !== 'undefined' && this.groups.length > 0) {
                    this.buildFieldSet();
                }
                this.formBuilded = true;
            }
        }
    }

    /**
     * creation des groups du dxForm
     */
    private buildFieldSet(): void {
        if (this.formBuilded) {
            return;
        }
        this.tabs = this.groups.map((block, index) => {
            return { text: block.caption, visibleIndex: block.visibleIndex || index, indexBlock: index };
        });
        // itérer sur le tableau principal
        for (let i = 0; i < this.groups.length; i++) {
            const group: FormGroupItem = this.groups[i] as FormGroupItem;
            if (group.visibleIndex == null || group.visibleIndex === undefined) {
                group.visibleIndex = i;
            }
            if (typeof group !== 'string') {
                // <!-- itérer sur le tableau des sous groupe -->
                if (group.groups) {
                    for (let g = 0; g < group.groups.length; g++) {
                        const sousGroupe = group.groups[g];
                        /** Rendre le group visibile si la valeur n'est pas défini */
                        if (sousGroupe.visible === undefined) {
                            sousGroupe.visible = true;
                        }
                        if (sousGroupe.items) {
                            for (let index = 0; index < sousGroupe.items.length; index++) {
                                const element = typeof sousGroupe.items[index] === 'object' ? sousGroupe.items[index].dataField : sousGroupe.items[index];
                                if(this.customFields[element] != undefined){
                                    sousGroupe.items[index] = this.customFields[element] as any
                                }
                                else {
                                    sousGroupe.items.splice(index, 1);
                                    index -= 1;
                                }
                                if (this.customFields[element]?.subFields) {
                                    const tabOfSubFields = Object.values(this.customFields[element].subFields);
                                    for (let j = 0; j < tabOfSubFields.length; j++) {
                                        sousGroupe.items[index] = tabOfSubFields[j];
                                        index++;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        this.groups = this.groups.sort((t1, t2) => t1.visibleIndex - t2.visibleIndex);
        this.formItems = this.groups as any;
        this.manageCssClasses();
    }

    dropDownClicked(data) {
        if (data.itemData.value === 'DELETE' && !this.deleteDisabled) {
            this.delete();
        } else if (data.itemData.value === 'CONTEXUTAL_HELP') {
            this.contextualHelpOn = !this.contextualHelpOn;
        } else if (data.itemData.value === 'initAdress') {
            this.dropDownClick.emit(data.itemData.value);
        } else if (data.itemData.value === 'commissionRules') {
            this.dropDownClick.emit(data.itemData.value);
        } else {
            data.itemData.callback();
        }
    }


    onTabChanged(event: any) {
        this.activeTab = event; // event.itemIndex;
        // window.scroll(0, 0);
    }

    /**
     * preparer les variables : idNext, idPrevious pour la navigation templateSide
     */
    private prepareVariableForTemplateSideNavigation(): void {
        const index: number = this.items.findIndex(
            // we dont check the type of this.id because id could be number or string
            // eslint-disable-next-line eqeqeq
            item => item[this.primaryKeyFieldName] == this.id
        );
        this.idPrevious = index > 0 ? this.items[index - 1][this.primaryKeyFieldName] : null;
        this.idNext = index + 1 < this.items.length ? this.items[index + 1][this.primaryKeyFieldName] : null;
    }

    /**
     * preparer les variables : idNext, idPrevious pour la navigation serverSide
     */
    private prepareVariableForServerSideNavigation(): void {
        let index: number;
        // eslint-disable-next-line eqeqeq
        index = this.items.findIndex(item => item[this.primaryKeyFieldName] == this.id, 10);

        if (index >= 0) {
            this.idPrevious = this.items[index - 1] ? this.items[index - 1][this.primaryKeyFieldName] : null;
        }
        if (index < this.items.length) {
            this.idNext = this.items[index + 1] ? this.items[index + 1][this.primaryKeyFieldName] : null;
        }

        if (this.idPrevious === null || this.idNext === null) {
            (!this.idsLoaded) ? this.getItemsAndIds() : this.getForNavigation();
        }
    }

    GenerateKeyBarCode(action: string) {
        this.GenerateKeyBarCodeForm.emit(action);
    }

    private async getItemsAndIds(): Promise<void> {
        if (this.id) {
            this.subscriptions.push(this.service.getForDetail(this.id, ...this.getGlobalParams).subscribe(
                (data: DetailSet<any>) => {
                    this.idsLoaded = true;
                    this.items = this.service.list = data.items;
                    this.idItems = this.service.idList = <number[]>data.listId;
                    // ne pas mettre === car ca peut être un number (ex: pour id) ou une chaine (ex: pour code)
                    this.item = cloneObject(this.items.find((item: any) => item[this.primaryKeyFieldName] == this.id));

                    if (!this.item) {
                        this.itemNotFoundAndRedirect();
                    }
                    this.service.currentItem.next(this.item);
                    this.itemLoading.next(false);
                    this.validateFormWithGroup();
                })
            );
        }
        else {
            this.itemLoading.next(false);
            // this.itemLoading = false;
            notify('Verifier votre ID SVP.', 'error', globalsParameter.notifyErrorDelay);
        }
    }

    private getForNavigation(): void {

        const indexItem: number = this.idItems.findIndex(
            // eslint-disable-next-line eqeqeq
            item => item == this.id
        );

        if (typeof this.idItems[indexItem - 1] === 'undefined' || typeof this.idItems[indexItem + 1] === 'undefined') {
            this.itemLoading.next(false);
            // this.itemLoading = false;
            return;
        }

        const listId: number[] = [];

        if (this.idPrevious === null) {
            for (let i = indexItem - 1; i > indexItem - 40 && i >= 0; --i) {
                listId.unshift(this.idItems[i]);
            }
        } else if (this.idNext === null) {
            for (let i = indexItem + 1; i < indexItem + 40 && i < this.idItems.length; ++i) {
                listId.push(this.idItems[i]);
            }
        }

        this.subscriptions.push(
            this.service.getForNavigation(listId, ...this.getGlobalParams).subscribe(
                (data: any[]) => {
                    if (this.idPrevious === null) {
                        this.service.list.unshift(...data);
                        this.items = this.service.list;
                    } else if (this.idNext === null) {
                        this.service.list.push(...data);
                        this.items = this.service.list;
                    }
                    this.item = cloneObject(this.items.find((item: any) => item[this.primaryKeyFieldName] === this.id));
                    if (!this.item) {
                        this.itemNotFoundAndRedirect();
                    }
                    this.itemCopy = cloneObject(this.item);
                    this.canDeactivate.emit(true);
                    this.itemLoading.next(false);
                    // this.itemLoading = false;
                    this.prepareVariableForServerSideNavigation();
                },
                (error: any) => {
                    // this.itemLoading = false;
                    this.itemLoading.next(false);
                }
            )
        );
    }

    // a revoir cette methode
    public navigateToDetail(cle: string | number): void {
        this.service.navigateToDetail(cle, ...this.getNavigateToDetailParams);
    }

    public validateFormWithGroup(): boolean {
        let isValid = true;
        for (let i = 0; i < this.formItems.length; i++) {// itérer sur le tableau principal
            let isGroupValid = true;
            let isWarningValid = true;
            const group: FormGroupItem = this.formItems[i] as FormGroupItem;
            group.errorList = { list: {} };
            group.warningList = { list: {} };
            if (group.groups) {
                for (let g = 0; g < group.groups.length; g++) {// <!-- itérer sur le tableau des sous groupe -->
                    const subGroupe = group.groups[g];
                    subGroupe.errorList = { list: {} };
                    subGroupe.warningList = { list: {} };
                    if (subGroupe.items) {
                        for (let index = 0; index < subGroupe.items.length; index++) {
                            const subFields = subGroupe.items[index];
                            if (subFields.items && subFields.items.length > 1) {
                                for (let j = 0; j < subFields.items.length; j++) {
                                    const result = this.validateItem(subFields.items[j], this.item[subFields.dataField]);
                                    subFields.items[j].errorList = { list: {}, isValid: true };
                                    subFields.items[j].warningList = { list: result.itemsOptional.filter(x=> !x.isValid), isValid: true };
                                    result.itemsOptional.filter(x=> !x.isValid).forEach(item => {
                                        subFields.items[j].warningList.list = item;
                                        subGroupe.warningList.list[item.name] = item;
                                        group.warningList.list[subGroupe.name] = item;
                                        isWarningValid = false;
                                    })
                                    if (!result.isValid) {
                                        for (const item of result.items) {
                                            if (!item.isValid) {
                                                isValid = false;
                                                isGroupValid = false;
                                                subFields.items[j].errorList.list = item;
                                                subFields.items[j].errorList.isValid = false;
                                                subGroupe.errorList.list[item.name] = item;
                                            }
                                        }
                                    }
                                }
                            } else {
                                const result = this.validateItem(subGroupe.items[index], this.item);
                                subGroupe.items[index].errorList = { list: {}, isValid: true };
                                subGroupe.items[index].warningList = { list: result.itemsOptional.filter(x=> !x.isValid), isValid: true };
                                result.itemsOptional.filter(x=> !x.isValid).forEach(item => {
                                    subGroupe.items[index].warningList.list = item;
                                    subGroupe.warningList.list[item.name] = item;
                                    isWarningValid = false;
                                })
                                if (!result.isValid) {
                                    for (const item of result.items) {
                                        if (!item.isValid) {
                                            isValid = false;
                                            isGroupValid = false;
                                            subGroupe.items[index].errorList.list = item;
                                            subGroupe.items[index].errorList.isValid = false;
                                            subGroupe.errorList.list[item.name] = item;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            group.errorList.isValid = isGroupValid;
            group.warningList.isValid = isWarningValid;
        }
        return isValid;
    }

    private validateItem(field: FormField, itemModel: any): {
        isValid: boolean,
        items: {
            name: string,
            value: any,
            brokenRule: any,
            isValid: boolean,
            validationRules: any[],
            label: string
        }[],
        itemsOptional: {
            name: string,
            value: any,
            brokenRule: any,
            isValid: boolean,
            validationRules: any[],
            label: string
        }[]
    } {
        const returnValidations: {
            isValid: boolean,
            items: {
                name: string,
                value: any,
                brokenRule: any,
                isValid: boolean,
                validationRules: any[],
                label: string
            }[],
            itemsOptional: {
                name: string,
                value: any,
                brokenRule: any,
                isValid: boolean,
                validationRules: any[],
                label: string
            }[],
        } = { isValid: true, items: [] , itemsOptional: [] };
        if (field.fieldType === 1) {
            const subFormModel = itemModel[field.dataField];
            for (const childScreenItem of field['items']) {
                const validations = this.validateItem(childScreenItem, subFormModel);
                returnValidations.isValid = returnValidations.isValid && validations.isValid;
                returnValidations.items.push(...validations.items);
            }

        } else {
            if (itemModel){
            const itemValidation = (validationEngine as any).validate(itemModel[field.dataField], field.validationRules, field.dataField);
            const itemOptionalValidation = (validationEngine as any).validate(itemModel[field.dataField], field.validationOptionalRules, field.dataField);
            itemValidation.label = field.label.text;
            returnValidations.isValid = itemValidation.isValid;
            returnValidations.items.push(itemValidation);
            returnValidations.itemsOptional.push(itemOptionalValidation);
            }
        }
        return returnValidations;
    }


    create() {
        this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
    }

    /**
     * fonction appelé lors du click sur un des boutons du l'header
     * @param event evenement du click par défaut
     * @param method valeur du bouton
     * @param validationGroupe liste de validation
     */
    save(event: any, method?: 'validate' | 'create', validationGroupe?: DxValidationGroupComponent): boolean {
        if (/*this.formCanDeactivate && */method === 'create') {
            this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
            return;
        }
        this.pendingSubmit = true;
        this.savingState.next(SavingStateEnum.YES);
        let isValid = false;
        if (this.formItems.length > 0 && this.formItems[0].itemType === 'group') {
            isValid = this.validateFormWithGroup() || validationGroupe.instance.validate().isValid;
        } else {
            isValid = validationGroupe.instance.validate().isValid;
        }
        if (isValid) {
            this.validForm = true;
            if (this.id && ((method === 'validate' && !this.options.edit.disableDefaultSaveEvent)
            /* || (method === 'create' && !this.options.edit.disableDefaultSaveAndAddNewEvent)*/)) {

                this.subscriptions.push(
                    this.service.put(this.id, this.item, ...this.getPutParams).subscribe(
                        (item) => {
                            this.putCallback(method, item);
                        },
                        (error: HttpErrorResponse) => {
                            this.putCallback(method, null, validationGroupe, error);
                        }
                    )
                );
            } else if (!this.id
                && ((method === 'validate' && !this.options.create.disableDefaultSaveEvent)
                    /*|| (method === 'create' && !this.options.create.disableDefaultSaveAndAddNewEvent)*/)) {
                this.subscriptions.push(
                    this.service.post(this.item, ...this.getPostParams).subscribe(
                        (item) => {
                            this.postCallback(method, item, validationGroupe);
                        },
                        (error: HttpErrorResponse) => {
                            this.postCallback(method, null, validationGroupe, error);
                        }
                    )
                );
            }
            this.submit.emit({
                eventType: method,
                objectToSend: this.item, validationGroupe,
                putCallback: (result: any, _validationGroupe: DxValidationGroupComponent, error?: HttpErrorResponse) => this.putCallback(method, result, _validationGroupe, error),
                postCallback: (result: any, _validationGroupe: DxValidationGroupComponent, error?: HttpErrorResponse, extraData?: any) => this.postCallback(method, result, _validationGroupe, error, extraData)
            });
        } else {
            this.validForm = false;
            this.pendingSubmit = false;
            this.savingState.next(SavingStateEnum.NO);
            notify(this._translate.instant('FormErrorMessages.Notify'), 'error', globalsParameter.notifyErrorDelay);
        }
        return isValid;
    }

    public saveThenQuit(): Observable<any> {
        const validationState = this.save(null, 'validate', this.validationGroupe);
        if (validationState === false) {
            return of(SavingStateEnum.CANCEL);
        }
        return this.savingState.asObservable();
    }

    // méthode qui appelle l'ancien objet lorsque l'on clique sur "ne pas enregistrer"
    public resetForm(): void {
        this.getItemsAndIds();
    }

    private showServerError(error: any) {
        console.log(error.error);
    }

    private putCallback(method: 'validate' | 'create', result: NGCResults<any>, validationGroupe?: DxValidationGroupComponent, error?: HttpErrorResponse): void {
        this.validateFormWithGroup();
        if (!error) {
            notify('Modification effectuée', 'success', globalsParameter.notifySuccessDelay);
            if (result.withCautions) {
                showAlertsNGCResults(result.ngcResults, this._translate.instant('WarningList')).then(() => {
                    this.closePopup();
                });
            }
            this.navigateId = result.ngcResults[0].data.id;

            result = result.ngcResults[0].data;

            this.validateSuccess.emit(result);
            // on met à jour l'objet dans la liste
            const idxCurrentItem = this.items.findIndex((item: any) => item[this.primaryKeyFieldName] == this.id);
            this.items[idxCurrentItem] = result;
            if (!this.options.edit.disableRedirect) {
                if (method === 'create') {
                    this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
                } else {
                    this.item = result;
                    this.itemCopy = cloneObject(this.item);
                }
            }
            this.canDeactivate.emit(true);
        } else {
            this.savingState.next(SavingStateEnum.CANCEL);
            if (error.status === 400) {
                this.showServerError(error.error);
            }
        }
        this.pendingSubmit = false;
        this.savingState.next(SavingStateEnum.NO);
    }

    private postCallback(method: 'validate' | 'create', result: NGCResults<any>, validationGroupe: DxValidationGroupComponent, error?: HttpErrorResponse, extraData?: any): void {
        if (!error) {
            this.canDeactivate.emit(true);
            if (this.isDuplication) {
                notify(this._translate.instant(extraData.notificationMsg), 'success', globalsParameter.notifySuccessDelay);
            }
            else {
                notify('Création effectuée', 'success', globalsParameter.notifySuccessDelay);
            }

            if (result.withCautions) {
                // let delay = 3000;
                showAlertsNGCResults(result.ngcResults, this._translate.instant('WarningList')).then(() => {
                    this.navigateId = result.ngcResults[0]?.data?.id;
                    this.closePopup();
                });
            } else {
                this.specificRedirection.emit(result.ngcResults[0].data.id);
            }
            this.validateSuccess.emit(result);
            if (!this.options.create.disableRedirect && !this.inPopup) {
                if (method === 'validate') {
                    if (!result.withCautions) {
                        this.service.navigateToDetail(result.ngcResults[0].data.id, ...this.getNavigateToDetailParams);
                    } else {
                        this.navigateId = result.ngcResults[0].data.id;
                    }
                } else {
                    // using this reset restore all value to undefined ( so no validation error are shown) and trigger change on fields( can deactivate false)
                    validationGroupe.instance.reset();
                    setTimeout(() => {
                        this.initFormValues();
                    });
                }
            }
        } else {
            this.savingState.next(SavingStateEnum.CANCEL);
            if (error.status === 400) {
                this.showServerError(error.error);
            }
        }
        this.pendingSubmit = false;
        this.savingState.next(SavingStateEnum.NO);
    }

    private initFormValues(): void {
        let initValues = {};
        if (this.model.initValues !== undefined) {
            initValues = this.model.initValues();
        }
        for (const key in this.model) {
            if (this.customFields.hasOwnProperty(key) && this.customFields[key]?.editorOptions?.value) {
                this.item[key] = this.initValuesFromCustomField(this.customFields[key], this.item[key]);
            } else if (initValues.hasOwnProperty(key)) {
                this.item[key] = initValues[key];
            }
        }
    }

    onAddnewLineFromList(event) {
        this.isSlidePanelVisible = true;
        this.addLineFromList.emit(event);
    }

    private initValuesFromCustomField(customField: FormField, item: any): any {
        if (customField.editorOptions.valueExpr && !customField.editorOptions.setValueExprOnly) {
            if (item === undefined || item === null) {
                item = {};
            }
            item[customField.editorOptions.valueExpr] = customField.editorOptions.value;
        } else {
            item = customField.editorOptions.value;
        }
        return item;
    }

    delete(): void {
        this.subscriptions.push(
            this._translate.get([
                'Administrator.User.DeleteSuccess',
                'Confirm',
                'AreYouSure'
            ]).subscribe(
                (messages: string[]) => {
                    const result = confirmDialog(this._translate.instant('popup.confirmDelete.title'), this._translate.instant('popup.confirmDelete.validateButton'),
                        this._translate.instant('popup.confirmDelete.cancelButton'), this._translate.instant('popup.confirmDelete.body'))
                    result.show().then((dialogResult: CustomConfirmDialogResult) => {
                        if (dialogResult === CustomConfirmDialogResult.YES) {
                            this.subscriptions.push(
                                this.service.delete(this.id, ...this.getGlobalParams).subscribe(
                                    () => {
                                        notify(messages['Administrator.User.DeleteSuccess'], 'success', globalsParameter.notifySuccessDelay);
                                        this.service.navigateToList(...this.getNavigateToListPageParams);
                                    }
                                )
                            );
                        }
                    });
                }
            )
        );
    }

    onCanDeactivateChanged(event: boolean, field?: FormField): void { 
        this.canDeactivate.emit(event); 
    }

    onValueChanged(val: any): void { 
        this.formDataChange.emit(val);
    }

    focusField(error) {
        if (error && error.key) {
            const element = this.renderer.selectRootElement('#' + error.key);
            if (element) {
                setTimeout(() => element.focus() ? element.focus() : element.click(), 0);
            }
        }
    }

    get isEditable(): boolean {
        if ('boolean' === typeof this.editable) {
            return this.editable;
        } else if ('function' === typeof this.editable) {
            return this.editable(this.item, !!this.id);
        } else {
            throw new Error('what did you put as editable input ??');
        }
    }

    get deleteDisabled(): boolean {
        if ('boolean' === typeof this.options.delete.disabled) {
            return this.options.delete.disabled;
        } else if ('function' === typeof this.options.delete.disabled) {
            return this.options.delete.disabled(this.item);
        } else {
            throw new Error('what did you put as options.delete.disabled input ??');
        }
    }

    private get getAllParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.getAll);
        }
        return [];
    }

    public get getGlobalParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams && this.serviceOptions.methodsParams.global) {
            return this.serviceOptions.methodsParams.global;
        }
        return [];
    }

    public get getPostParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.post);
        }
        return [];
    }

    public get getPutParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.put);
        }
        return [];
    }

    private get getNavigateToDetailParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToDetail);
        }
        return [];
    }

    private get getNavigateToCreatePageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToCreatePage);
        }
        return [];
    }

    private get getNavigateToListPageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {

            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToListPage);
        }
        return [];
    }
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight - 90;
    }

    getspace(mode: number): number {
        if (mode === 1 && document.getElementById('form-container')) {
            return document.getElementById('form-container').offsetTop + ((this.additionalButtonsVisibility) ? 47 : 0)
                + document.getElementById('dx-form-page-header').offsetHeight;
        } else if (mode === 0) {
            return ((this.additionalButtonsVisibility) ? 47 : 0) + document.getElementById('dx-form-page-header').offsetHeight;
        } else {
            return 0;
        }

    }

    getHeighFormScroll(popupContentHeight: number): number {
        // calcul la hauteur du scroll du formulaire en fonction du content popup et des autres element visible
        let heightErrorList = 0;
        const eltErrorList = document.getElementById('errorList');
        if (eltErrorList) {
            heightErrorList = eltErrorList.offsetHeight;
        }
        return popupContentHeight - heightErrorList;
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }

    private manageCssClasses(): void {
        this.cssClasses = {
            'col': !this.inPopup && typeof this.tabs !== 'undefined',
            // 'col-md-10' : !this.inPopup && typeof this.tabs !== 'undefined',
            'inpopup': this.inPopup,
            // 'card': this.formItems.length > 0 && this.formItems[0].itemType !== 'group'
        };
    }

    hasErrorToDisplay(tab: { text: string, visibleIndex: number, indexBlock: number }): boolean {
        let hasError = false;
        let hasWarning = false;
        if (this.formItems[tab.indexBlock]?.errorList) {
            hasError = !this.formItems[tab.indexBlock]?.errorList?.isValid
        }
        if (this.formItems[tab.indexBlock]?.warningList) {
            hasWarning = !this.formItems[tab.indexBlock]?.warningList?.isValid
        }
        return hasError || hasWarning;
    }

    filterGroupWithItems(subBlocks: FormGroupItem[]): FormGroupItem[] {
        return subBlocks.filter(subBlock => subBlock.items.length > 0);
    }

    checkErrors(subBlocks: FormGroupItem[]): number {
        return subBlocks.filter(subBlock => {
            let errorCount = 0;
            if (subBlock.errorList?.list != null) {
                errorCount += Object.keys(subBlock.errorList?.list).length
            }
            if (subBlock.warningList?.list != null) {
                errorCount += Object.keys(subBlock.warningList?.list).length
            }
            return errorCount !== 0;
        }).length;
    }

    showTabsFunction(): void {
        if (this.screenService.isLargeScreen()) {
            $('#tabs').animate({ width: 'toggle' });
            this.showTabs = !this.showTabs;
        } else {
            $('#tabs').slideToggle();
            this.showTabs = !this.showTabs;
        }
    }

    closePopup(): void {
        if (!this.id) {
            this.service.navigateToDetail(this.navigateId, ...this.getNavigateToDetailParams);
        } else {
            this.errorList = [];
            this.service.navigateToDetail(this.id, ...this.getNavigateToDetailParams);
        }
    }

    onInitSlidePanel(slidePanelObject: FormField | SlidePanelModel | any): void {
        this.reloadSlidePanelForChanges();
        if (slidePanelObject.isList || slidePanelObject.isGrid) {
            this.slidePanelOptions = slidePanelObject;
        } else {
            this.formFieldForSlidePanel = slidePanelObject;
            this.slidePanelOptions = slidePanelObject.slidePanelOptions;
            this.dataGridOptions = slidePanelObject.editorOptions.dataGridOptions;
        }
        const slidePanel: HTMLElement = document.getElementsByClassName('slide-panel')[0] as HTMLElement;
        slidePanel.style.top = this.slidePanelOptions.css.top;
        slidePanel.style.height = this.slidePanelOptions.css.height;

        this.isSlidePanelVisible = true;
    }

    reloadSlidePanelForChanges(): void {
        this.reloadSlidePanel = false;
        // now notify angular to check for updates
        this.changeDetector.detectChanges();
        // change detection should remove the component now
        // then we can enable it again to create a new instance
        this.reloadSlidePanel = true;
    }

    onValidateSuccess(event: { ngcResults, data, name, click }): void {
        this.onSlidePanelClose();
        this.validateSuccessSlidePanel.emit(true);
        // cas de validation d'un formulaire
        if (event.ngcResults) {
            const ngcResult: NGCResult<any> = event.ngcResults[0];
            this.dxFieldComponentList.forEach((child) => {
                if (child.field.dataField === this.formFieldForSlidePanel.dataField) {
                    child.addNewDropdownItem(ngcResult.data);
                }
            });
        } else { // cas du grid
            (event.click === 'singleClick') ?
                this.formDataChange.emit({ dataField: event.name, value: event }) :
                this.formDataChange.emit({ dataField: event.name, value: event.data });
        }
    }

    onSlidePanelClose(): void {
        this.isSlidePanelVisible = false;
        this.slidePanelClosed.emit(true);
    }

    toggleTabHelp(index: number): void {
        this.tabHelpVisible[index] = !this.tabHelpVisible[index];
    }

    toggleSubBlockHelp(index: number): void {
        this.subBlockHelpVisible[index] = !this.subBlockHelpVisible[index];
    }

    getVisible(field: FormField): boolean {
        if ('boolean' === typeof field.visible) {
            return field.visible;
        } else if ('function' === typeof field.visible) {
            return field.visible(this.item, field);
        } else {
            return field.visible;

        }
    }

    onRowListClick(event): void {
        this.rowListClick.emit(event);
    }

    onEditButtonClicked(event): void {
        this.editButtonClickedEvent.emit(event);
    }

    onBeforeSaveSlidePanel(event): void {
        this.beforeSaveSlidePanel.emit(event)
    }

    onConfimDialogYesSlidePanel(event): void{
        this.confimDialogYesSlidePanel.emit(event);
    }

    onConfimDialogNoSlidePanel(event): void {
        this.confimDialogNoSlidePanel.emit(event);
    }

    onTvaValueChanged(event): void {
        this.checkTvaEvent.emit(event);
    }
    onExciseNumberChanged(event): void {
        this.exciseNumberEvent.emit(event);
    }
    onClickInfo(): void {
        this.clickInfo.emit();
    }

    onUpdateLineFromList(event): void {
        this.isSlidePanelVisible = true;
        this.updateLineFromList.emit(event);
    }

    onDeleteLineFromList(event): void {
        this.deleteLineFromList.emit(event);
    }
}
