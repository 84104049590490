import {
    Component, OnInit, Input, EventEmitter, Output, ViewContainerRef,
    ViewChild, ComponentFactoryResolver, ComponentRef, ChangeDetectorRef, AfterViewInit, HostListener, ElementRef, OnDestroy
} from '@angular/core';
import { KeyedTempalteDirectiveObject } from '@app/shared/directive/keyed-template-directive/i-template-host.component';
import { FormField } from '../dx-form/models/form-field';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { concatDefinedArrray } from '@app/utils/daoHelper';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import { DxDataGridPickerComponent } from '../dx-data-grid-picker/dx-data-grid-picker.component';
import { NextDxPopupComponent } from '../dx-popup/next-dx-popup.component';
import { Tools } from '@app/shared/class/tools';
import { isDefined } from '@app/utils';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { DxPopupPrintComponent } from '../dx-popup-print/dx-popup-print.component';
import { CHECKVALIDITY, ReportType, TVAIntracomValidator } from '@app/shared/model';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { DataGridInput } from '../genericComponentOption';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DxListComponent } from '../dx-list/dx-list.component';

@Component({
    selector: 'app-dx-field',
    templateUrl: './dx-field.component.html',
    styleUrls: ['./dx-field.component.scss'],
})
export class DxFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('popupContainer', { static: false, read: ViewContainerRef }) popupContainer: ViewContainerRef;
    @ViewChild(DxFileUploaderComponent, { static: false }) fileUploader: DxFileUploaderComponent;
    @ViewChild(DxDataGridPickerComponent, { static: false }) dxGridPicker: DxDataGridPickerComponent;
    @ViewChild('dxPopupAddElement', { static: false }) dxPopupAddElement: NextDxPopupComponent;
    @ViewChild(DxDropDownBoxComponent, { static: false }) dxdropdown: DxDropDownBoxComponent;
    @ViewChild(DxSelectBoxComponent, { static: false }) dxselect: DxSelectBoxComponent;
    @ViewChild('dxPopupPrint', { static: false }) dxPopupPrint: DxPopupPrintComponent;
    @ViewChild(DxHtmlEditorComponent, { static: false }) editor: DxHtmlEditorComponent;
    @ViewChild(DxListComponent) dxList: DxListComponent;

    @Input() field: FormField;
    @Input() parentItem: any;
    @Input() id: string;
    @Input() item: any;
    @Input() itemCopy: any;
    @Input() service: IDaoBaseService;
    @Input() set templateDirective(keyedTempalteDirectiveObject: KeyedTempalteDirectiveObject) { this._templateDirective = keyedTempalteDirectiveObject; }
    @Input() editable = true;
    @Input() contextualHelpOn = false;
    @Input() disableTrim = false;
    @Input() formFields: any;
    @Input() dataGridInput: DataGridInput = new DataGridInput();
    @Input() showFilters?: boolean = true;
    @Input() editActionVisible = false;
    @Input() dataGridPickerVisible = false;
    @Input() set showPopup(bool: boolean) { this.popupVisible = bool; }

    /** emet false si la nouvelle valeur est differente a la précedente */
    @Output() canDeactivate = new EventEmitter<boolean>();
    /** emet la nouvelle valeur  aprés une edition*/
    @Output() valueChange = new EventEmitter<any>();
    @Output() addNewLineFromList = new EventEmitter<boolean>();
    @Output() initSlidePanel = new EventEmitter<any>();
    @Output() GenerateKeyBarCodeEvent = new EventEmitter<any>();
    @Output() rowListClick = new EventEmitter<any>()
    @Output() editButtonClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() checkTvaEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() exciseNumberEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickInfo: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateLineFromList = new EventEmitter<any>();
    @Output() deleteLineFromList = new EventEmitter<any>();

    validField = true;
    clearDropDown: {
        icon?: string;
        template?: string;
        focusStateEnabled: boolean;
        hoverStateEnabled: boolean;
        activeStateEnabled: boolean;
        onClick: () => void;
    };
    helpVisible = false;
    _templateDirective?: KeyedTempalteDirectiveObject;
    focusedField = false;
    isMultiline = true;
    dropdownValues: { [key: string]: any[] }[] = []; // Contient la liste des valeurs dropdown
    openedDropDown: boolean;
    openedSelectBox: boolean;
    radioGroupValues: { [key: string]: any[] }[] = []; // Contient la liste des valeurs dropdown
    file: any[] = []; // s'il ya du dx-file ds notre formulaire
    popupVisible = false;
    componentRefInPopup: ComponentRef<any>;
    phoneButton: any;
    urlButton: any;
    emailButton: any;
    showPasswordButton: any;
    isUrl = false;
    findButton: any;
    removeButton: any;
    isPopupPrintVisible = false;
    editorValue: string;
    popupVisibleImg: boolean;
    myCursor: number;
    tvaButton: any;
    exciseNumberButton: any;
    disabledCheckButton: any;
    stateIcons: any;
    // control mouse for zipCode input
    mouseInter = false;
    mask: string;
    validTva: number = CHECKVALIDITY.NOTCHECKED;
    checkValidity = CHECKVALIDITY;
    errorMessage: string;
    validExciseNumber: number = CHECKVALIDITY.INVALID_INPUT;
    private subscriptions: Subscription[] = [];
    constructor(private resolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef, private eRef: ElementRef, private translate: TranslateService) {
    }


    reevalidate(event): void {
        this.formFields.forEach((child) => {
            if (child.field.dataField === event.reevalidateField.fieldToReeValidate) {
                const fieldToReeValidate = child.field;
                if (!fieldToReeValidate.reevalidateField.validationRulesList) {
                    fieldToReeValidate.reevalidateField.validationRulesList = fieldToReeValidate.validationRules;
                }
                let validationRulesList = fieldToReeValidate.validationRules;
                if (!validationRulesList && (child.field.dataField === event.reevalidateField.fieldToReeValidate)) {
                    validationRulesList = fieldToReeValidate.reevalidateField.validationRulesList;
                }
                if (validationRulesList) {
                    fieldToReeValidate.validationRules = [];
                    validationRulesList.forEach((element: any) => {
                        const rule: { [key: string]: any } = {};
                        for (const key in element) {
                            if (element.hasOwnProperty(key)) {
                                rule[key] = element[key];
                            }
                        }
                        fieldToReeValidate.validationRules.push(rule);
                    });
                    fieldToReeValidate.reevalidateField.validationRulesList = child.field.validationRules;
                }
            }
        });
    }



    public ngOnInit(): void {
        if (this.field.editorOptions && !this.field.editorOptions.dataGridInput) {
            this.field.editorOptions.dataGridInput = new DataGridInput();
        }

        if (this.field.editorType === 'dxPopupPrint') {
            this.fetchDropdownList(this.field, this.id);
        }
        if (this.field.editorType === 'dxSelectBox') {
            this.fetchDropdownList(this.field, this.id);
        }
        if (this.field.withoutAllItems) {
            this.isMultiline = false;
        }
        if (this.field.inputType === 'phone') {
            this.phoneButton = {
                icon: 'tel',
                stylingMode: 'text',
                width: 40,
                focusStateEnabled: false,
                hoverStateEnabled: false,
                activeStateEnabled: false,
                onClick: (e) => {
                    if (this.item[this.field.dataField] != null) {
                        window.open('tel:' + this.item[this.field.dataField]);
                    }

                }
            };
        }
        if (this.field.inputType === 'showPassword') {
            this.setShowPasswordButton()
        }
        if (this.field.inputType === 'url') {
            this.urlButton = {
                icon: 'globe',
                stylingMode: 'text',
                width: 40,
                focusStateEnabled: false,
                hoverStateEnabled: false,
                activeStateEnabled: false,
                onClick: (e) => {
                    let url = this.item[this.field.dataField];
                    if (url) {
                        url = url.match(/^https?:/) ? url : '//' + url;
                        window.open(url, '_blank');
                    }
                }
            };
        }

        if (this.field.inputType === 'email') {
            this.emailButton = {
                icon: 'assets/icons/svg/Email.svg',
                stylingMode: 'text',
                width: 40,
                focusStateEnabled: false,
                hoverStateEnabled: false,
                activeStateEnabled: false,
                onClick: (e) => {
                    if (this.item[this.field.dataField] != null) {
                        window.open('mailto:' + this.item[this.field.dataField]);
                    }

                }
            };
        }
        if (this.field.inputType === 'TVA') {
            this.validTva = CHECKVALIDITY.NOTCHECKED;
            this.setVatBtnConfig();
        }
        if (this.field.inputType === 'exciseNumber') {
            this.errorMessage = this.translate.instant('FormErrorMessages.invalidExciseNumber');
            this.setSeedCheckBtnConfig();
        }

        this.disabledCheckButton = {
            iconType: 'ngcIcons',
            template: 'templateCheck',
            icon: 'searchCheck',
            stylingMode: 'text',
            width: 40,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            disabled: true
        };

        this.findButton = {
            template: 'templatefind',
            stylingMode: 'text',
            width: 34,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            onClick: (e) => {
                this.managePopupPosition();
            }
        };
        this.removeButton = {
            icon: 'assets/icons/svg/CloseRounded.svg',
            stylingMode: 'text',
            width: 29,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            onClick: () => {
                this.clearTextBox();
            }
        };
    }
    /**evenement du clic en dehors du zipCode input */
    @HostListener('window:click', ['$event'])
    onClick(event) {
        if ((event.target.id == 'zipCode' && !this.mouseInter && this.field.dataField == 'zipCode') || (event.target.id == 'country' && !this.mouseInter && this.field.dataField == 'country')) {
            this.mouseInter = true;
        }
        else if (this.mouseInter) {
            this.mouseInter = false;

        }
    }

    public ngAfterViewInit(): void {
        if (this.dataGridPickerVisible === true) {
            this.popupVisible = true;
            this.dxGridPicker?.refreshData();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }



    checkExciseNumber(): void {
        if (this.item[this.field.dataField] != null) {
            this.field.service.get(this.item[this.field.dataField]).subscribe(data => {
                if (data === TVAIntracomValidator.VALID) {
                    this.validExciseNumber = CHECKVALIDITY.VALID;
                    this.exciseNumberEvent.emit(true);
                    this.validField = true;
                    this.setSeedCheckBtnConfig();
                } else {
                    this.validExciseNumber = CHECKVALIDITY.INVALID;
                    this.exciseNumberEvent.emit(false);
                    this.validField = false;
                    this.setSeedCheckBtnConfig();
                }

            });
        }

    }

    setSeedCheckBtnConfig(): void {
        this.exciseNumberButton = {
            iconType: 'ngcIcons',
            template: 'templateCheckExciseNbr',
            icon: 'searchCheck',
            stylingMode: 'text',
            width: 40,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            disabled: !(this.item[this.field.dataField] && this.item[this.field.dataField].length === 13 && this.validExciseNumber != CHECKVALIDITY.VALID && this.validExciseNumber != CHECKVALIDITY.INVALID && this.validExciseNumber != CHECKVALIDITY.INVALID_INPUT),
            visible: !(this.validExciseNumber === CHECKVALIDITY.VALID),
            onClick: () => {
                this.checkExciseNumber();
            }
        };
    }

    setShowPasswordButton(): void {
        if (this.field.editorOptions.mode === 'password') {
            this.showPasswordButton = {
                iconType: 'ngcIcons',
                icon: 'show',
                template: 'templateShowPassword',
                stylingMode: 'text',
                width: 40,
                focusStateEnabled: false,
                hoverStateEnabled: false,
                activeStateEnabled: false,
                onClick: () => {
                    this.field.editorOptions.mode = 'text';
                    this.setShowPasswordButton();
                }
            }
        } else if (this.field.editorOptions.mode === 'text') {
            this.showPasswordButton = {
                iconType: 'ngcIcons',
                icon: 'hide',
                template: 'templateHidePassword',
                stylingMode: 'text',
                width: 40,
                focusStateEnabled: false,
                hoverStateEnabled: false,
                activeStateEnabled: false,
                onClick: () => {
                    this.field.editorOptions.mode = 'password';
                    this.setShowPasswordButton();
                }
            }
        }
    }

    setVatBtnConfig(): void {
        this.tvaButton = {
            iconType: 'ngcIcons',
            template: 'templateCheckVat',
            icon: 'searchCheck',
            stylingMode: 'text',
            width: 40,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            visible: this.validTva != CHECKVALIDITY.VALID && this.validTva != CHECKVALIDITY.INVALID && (this.validTva == CHECKVALIDITY.NOTCHECKED || this.validTva == CHECKVALIDITY.INVALID_INPUT),

            onClick: () => {
                this.onVatBtnClicked()

            }
        };
    }

    onVatBtnClicked(): void {
        if (this.item[this.field.dataField] != null) {
            this.field.service.get(this.item[this.field.dataField]).subscribe(data => {
                if (data === TVAIntracomValidator.VALID) {
                    this.validTva = CHECKVALIDITY.VALID;
                    this.checkTvaEvent.emit(true);
                    this.validField = true;
                    this.setVatBtnConfig();

                } else {
                    this.validTva = CHECKVALIDITY.INVALID;
                    this.checkTvaEvent.emit(false);
                    this.validField = false;
                    this.setVatBtnConfig();

                }

            });
        }
    }


    isPopupPrintVisibleChange(event): void {
        this.isPopupPrintVisible = event;
    }

    popupPrintItemSelected(event): void {
        if (event.reportType === ReportType.PERSO && this.field.popupPrintOptions.reportTypeColumn) {
            this.item[this.field.popupPrintOptions.reportTypeColumn] = 1;
        } else if (event.reportType === ReportType.BASE && this.field.popupPrintOptions.reportTypeColumn) {
            this.item[this.field.popupPrintOptions.reportTypeColumn] = 0;
        }
        if (this.field.popupPrintOptions?.contentType === 'EtiquetteIdAndName') {
            this.item[this.field.dataField] = {
                id: event.id,
                name: event.name
            }
        }
        else {
            this.item[this.field.dataField] = event.id
        }
        this.canDeactivate.emit(false);
    }

    managePopupPosition = (): void => {
        if (this.isPopupPrintVisible) {
            this.isPopupPrintVisible = false;
        } else {
            this.isPopupPrintVisible = true;
            this.dxPopupPrint.managePopupsPosition();
        }
    }

    clearTextBox(): void { 
        this.item[this.field.dataField] = null;
        this.canDeactivate.emit(false);
    }

    private verifyIsUrl(s) {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(s);
    }


    addNewDropdownItem(selectedNewItem: any): void {
        this.dropdownValues[this.field.dataField].push(selectedNewItem);
        this.item[this.field.dataField] = selectedNewItem;
        this.canDeactivate.emit(false);
    }

    GenerateKeyBarCode(action: string): void {
        this.GenerateKeyBarCodeEvent.emit(action);
    }

    // obtenir les données pour dxSelectBox
    public fetchDropdownList(field: FormField, id: string): void {
        if (typeof field.service !== 'undefined' && field.service !== null) {
            this.subscriptions.push(
                field.service.getAll(...this.getAllParams).subscribe(
                    (data: any) => {
                        this.dropdownValues[field.dataField] = field.editorOptions.order ? (data as Array<any>).sort(field.editorOptions.order) : data as Array<any>;
                        // Test sur l'editor option pour cacher une ligne de dx select box
                        if (isDefined(field.editorOptions.visible)) {
                            for (let index = 0; index < this.dropdownValues[field.dataField].length; index++) {
                                if (field.editorOptions.visible instanceof Function) {
                                    this.dropdownValues[field.dataField][index].visible = field.editorOptions.visible(this.dropdownValues[field.dataField][index], field);
                                } else {
                                    this.dropdownValues[field.dataField][index].visible = field.editorOptions.visible;
                                }
                            }
                        }
                        if (isDefined(field.editorOptions.value) && (this.item[this.field.dataField] === null || this.item[this.field.dataField] === undefined)) {
                            for (let index = 0; index < this.dropdownValues[field.dataField].length; index++) {
                                if (this.dropdownValues[field.dataField][index][field.editorOptions.valueExpr] === field.editorOptions.value) {
                                    this.item[this.field.dataField] = this.dropdownValues[field.dataField][index];
                                    break;
                                }
                            }
                        }
                    }
                )
            );
        } else {
            this.dropdownValues[field.dataField] = field.editorOptions.dataSource;
            if (isDefined(field.editorOptions.value) && (this.item[this.field.dataField] === null || this.item[this.field.dataField] === undefined)) {
                for (let index = 0; index < this.dropdownValues[field.dataField].length; index++) {
                    if (this.dropdownValues[field.dataField][index][field.editorOptions.valueExpr] === field.editorOptions.value) {
                        if (field.editorOptions.setValueExprOnly === true) {
                            this.item[this.field.dataField] = this.dropdownValues[field.dataField][index][field.editorOptions.valueExpr];
                        } else {
                            this.item[this.field.dataField] = this.dropdownValues[field.dataField][index];
                        }
                        break;
                    }
                }
            }
        }

    }

    /**
     * event lors de changement de valeur de selectbox
     * @param event $event
     */
    public onDropDownValueChanged(event: any): void {

        if (event.itemData) {
            this.item[this.field.dataField] = event.itemData;
            this.canDeactivate.emit(false);
            this.dxdropdown.instance.close();
            this.valueChange.emit({ event: event, dataField: this.field.dataField });
        } else if (event.value == null) {
            this.item[this.field.dataField] = null;
            this.canDeactivate.emit(false);
            this.dxdropdown.instance.close();
            this.valueChange.emit({ event: event, dataField: this.field.dataField });
        }
    }


    public get selectedDropItem(): any {

        const filterResult = (this.dropdownValues[this.field.dataField] as Array<any>).find(item => item.id === this.selectBoxValue);
        return [filterResult];
    }

    /**
    * event lors de changement de valeur de selectbox
    * @param event $event
    */
    public onSelectBoxValueChanged(event: any): void { 
        if (event.previousValue !== event.value && this.dropdownValues[this.field.dataField]) {
            const fullObject = this.dropdownValues[this.field.dataField].find(t => t[this.field.editorOptions.valueExpr] === event.value);
            if (!this.field.editorOptions.setValueExprOnly) {
                this.item[this.field.dataField] = fullObject ? fullObject : this.field.editorOptions.simpleData ? event.value : null; // this.dropdownValues[this.field.dataField].find(t => t === event.value);
            } else {
                this.item[this.field.dataField] = fullObject ? fullObject[this.field.editorOptions.valueExpr] : null;
            }
            // if the change is due to user interaction
            if (event.event && (!this.itemCopy || this.item[this.field.dataField] !== this.itemCopy[this.field.dataField])) {
                this.canDeactivate.emit(false);
            }
        }
        /* SK 21/09/2020 : Code commenter pour eviter la fermeture de la liste déroulante
            this.dxselect.instance.close();
        */
        this.valueChange.emit({ event: event, dataField: this.field.dataField });
    }

    public get selectBoxValue(): any {
        if (this.field.editorOptions.setValueExprOnly) {
            if (this.item &&
                (this.item[this.field.dataField] || this.item[this.field.dataField] === 0)) {
                return this.item[this.field.dataField];
            } else {
                return null;
            }
        } else if (this.field.editorOptions.initValueCallback) {
            return this.field.editorOptions.initValueCallback(this.dropdownValues[this.field.dataField]);
        } else {
            if (
                this.item &&
                (this.item[this.field.dataField] || this.item[this.field.dataField] === 0) &&
                (this.item[this.field.dataField][this.field.editorOptions.valueExpr] || this.item[this.field.dataField][this.field.editorOptions.valueExpr] === 0)
                && this.dropdownValues[this.field.dataField] && this.dropdownValues[this.field.dataField].length > 0
            ) {
                const itemFromDropdownValues = (this.dropdownValues[this.field.dataField] as any[]).some(item => item.id == this.item[this.field.dataField][this.field.editorOptions.valueExpr]);
                if (itemFromDropdownValues) {
                    return this.item[this.field.dataField][this.field.editorOptions.valueExpr];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }

    onValueChangedSwitch(event): void {
        this.item[this.field.dataField] = event;
        this.canDeactivate.emit(false);
        this.valueChange.emit({ event: event, dataField: this.field.dataField });

    }

    /**
     * event au changement de la valeur du champ
     * @param event $event
     * @param setValue  Boolean qui indique s'il faut affecter la valeur
     */
    public onValueChanged(event: any, setValue = false): void { 

        let alreadyEmitValueChanged = false;
        if (setValue) {
            this.item[this.field.dataField] = event.value;
            if (!alreadyEmitValueChanged) {
                this.valueChange.emit({ event: event, dataField: this.field.dataField });
                alreadyEmitValueChanged = true;
            }
        }

        if (this.field.reevalidateField && this.field.reevalidateField.reevalidate) {
            this.reevalidate(this.field);
        }

        if (this.field.editorType === 'dxDataGrid') {
            if (!this.itemCopy[this.field.dataField] ||
                (this.itemCopy[this.field.dataField] && (event.value.id !== this.itemCopy[this.field.dataField].id || (!event.value.id && event.value !== this.itemCopy[this.field.dataField])))
            ) {
                this.canDeactivate.emit(false);
            }
        }

        if (this.field.editorType === 'dxDataGridField' && this.itemCopy && this.itemCopy[this.field.dataField] && event !== Object.values(this.itemCopy[this.field.dataField])) {
            if (event && event.event) {
                this.canDeactivate.emit(false);
            }
        }


        if (event.event && (!this.itemCopy || event.value !== this.itemCopy[this.field.dataField])) {
            this.canDeactivate.emit(false);
            if (!alreadyEmitValueChanged) {
                this.valueChange.emit({ event: event, dataField: this.field.dataField });
                alreadyEmitValueChanged = true;
            }

        }

        if (this.field.editorType === 'dxDateBox') {
            if (event && event.event) {
                this.canDeactivate.emit(false);
            }
            if (!alreadyEmitValueChanged) {
                this.valueChange.emit({ event: event, dataField: this.field.dataField });
                alreadyEmitValueChanged = true;
            }
        }

        if (this.field.inputType === 'url') {
            this.isUrl = this.verifyIsUrl(event.value);
        }

        if (this.field.editorType === 'dxTextBox' && !this.disableTrim && typeof this.item[this.field.dataField] === 'string') {
            if (event.event && event.event.keyCode != 8 && this.item[this.field.dataField]) {
                this.item[this.field.dataField] = this.item[this.field.dataField].trim();
            }
        }

        if (this.field.editorType === 'dxMultiSelectBox') {
            this.canDeactivate.emit(false);
            this.valueChange.emit({ event: event, dataField: this.field.dataField });
        }
        if (this.field.inputType === 'TVA') {
            this.validTva = CHECKVALIDITY.NOTCHECKED;
            this.setVatBtnConfig();
        }

        if (this.field.inputType === 'exciseNumber') {
            // ajout du mask excise number
            this.mask = "AAAAAAAAAAAAA";
            if (event.value == null || event.value == '' || event.value.length != 13) {
                this.validExciseNumber = CHECKVALIDITY.INVALID_INPUT;
            }

            else if (event.value.length === 13) {
                this.validExciseNumber = CHECKVALIDITY.NOTCHECKED;
            }
            this.setSeedCheckBtnConfig();
        }
    }

    public onValueChangedInterval(event: any, dataField, setValue = false): void {

        const dateSerializationFormat = this.getDateSerializationFormat(this.field.editorOptions ? this.field.editorOptions.type : 'date');
        if (dateSerializationFormat === 'yyyy-MM-dd') {
            event.value = (new Date(event.value)).toDateString();
        } else if (dateSerializationFormat === 'HH:mm:ss') {
            event.value = (new Date(event.value)).toTimeString().split(' ')[0];
        }

        if (setValue) {
            this.item[this.field.dataField] = event.value;
        }
        if (event && (!this.itemCopy || event.value !== this.itemCopy[dataField])) {
            this.canDeactivate.emit(false);
        }
        this.valueChange.emit({ event: event, dataField: dataField });
    }

    onCanDeactivateChanged(event: boolean, field?: FormField): void {
        this.canDeactivate.emit(event);
    }

    onaddNewLineFromList(event): void {
        this.addNewLineFromList.emit(event)
    }

    onListRowClick(event): void {
        this.rowListClick.emit(event)
    }

    onUpdateLineFromList(event): void {
        this.updateLineFromList.emit(event);
    }

    onDeleteLineFromList(event): void {
        this.deleteLineFromList.emit(event);
    }

    onFieldValidated(event): void {
        this.validField = event.isValid;
    }

    public templateExist(templateName: string): any {
        if (this.field.template) {
            return this.field.template.find(tn => tn === templateName);
        }
        return false;
    }

    /**
     * this method is called when the add new sub object is succeded
     * @param newEntry is the new added value in the list
     */
    private popupRequestDoneWithSuccess(newEntry: NGCResults<any>): void {
        this.popupVisible = false;
        if (newEntry?.ngcResults[0]?.data && this.field.editorType === 'dxSelectBox') {
            this.dropdownValues[this.field.dataField].push(newEntry?.ngcResults[0]?.data);
            setTimeout(() => {
                if (this.dropdownValues[this.field.dataField]) {
                    this.item[this.field.dataField] = this.dropdownValues[this.field.dataField][this.dropdownValues[this.field.dataField].length - 1];
                    this.dropdownValues[this.field.dataField].sort(this.field.editorOptions.order);
                    const event = {
                        value: this.item[this.field.dataField][this.field.editorOptions.valueExpr ?? 'id'],
                        previousValue: null
                    };
                    this.canDeactivate.emit(false);
                    this.valueChange.emit({ event: event, dataField: this.field.dataField });
                }
            });
        } else if (this.field.editorType === 'dxDataGrid') {
            this.dxGridPicker.refreshData();
        }
        this.componentRefInPopup.destroy();
    }

    popupContentHeightChanged(event): void {
        if (this.componentRefInPopup) {
            this.componentRefInPopup.instance.popupContentHeight = event;
        }
    }

    /**
     * this method is called when a user click add new in the select list
     */
    private createComponent(): void {
        if (this.field.popupOptions.detailComponentName) {
            this.popupContainer.clear();
            const factory = this.resolver.resolveComponentFactory(this.field.popupOptions.detailComponentName);
            this.componentRefInPopup = this.popupContainer.createComponent(factory);
            this.componentRefInPopup.instance.inPopup = true;
            if (this.componentRefInPopup.instance.itemType === null) {
                this.componentRefInPopup.instance.itemType = this.field.serviceOptions.methodsParams.global[0];
            }
            if (!this.componentRefInPopup.instance.validateSuccess) {
                throw new Error(this.field.popupOptions.detailComponentName + ' dosen\t support popup');
            }
            this.subscriptions.push(
                this.componentRefInPopup.instance.validateSuccess.subscribe(
                    out => {
                        this.popupRequestDoneWithSuccess(out);
                    }
                )
            );
        }
    }

    /**
     * this method is called when a use clic add new in the select list
     */
    public addNewToSelectBox(event: any): void {
        if (this.field.slidePanelOptions) {
            this.initSlidePanelEvent(null);
        } else {
            this.createComponent();
            this.dxPopupAddElement.managePosition();
            this.popupVisible = true;
            this.dxdropdown.instance.close();
        }
    }


    onready(event: any): void {
        this.dxdropdown.instance.close();
    }

    public deleteField(event: string): void { 
        this.canDeactivate.emit(false);
        this.item[event] = null;
    }

    get disabled(): boolean {
        if ('boolean' === typeof this.field.disabled) {
            return this.field.disabled;
        } else if ('function' === typeof this.field.disabled) {
            return this.field.disabled(this.item, this.field, this.parentItem);
        } else {
            return this.field.disabled;
        }
    }
    get visible(): boolean {
        if ('boolean' === typeof this.field.visible) {
            return this.field.visible;
        } else if ('function' === typeof this.field.visible) {
            return this.field.visible(this.item, this.field);
        } else {
            return this.field.visible;

        }
    }

    get iconErrorVisible(): boolean {
        if (this.field.hasIconError) {
            if ('function' === typeof this.field.iconErrorVisible) {
                return this.field.iconErrorVisible(this.item, this.field);
            } else {
                this.field.iconErrorVisible;
            }
        } else {
            return false;
        }
    }

    get labelVisible(): boolean {
        if ('boolean' === typeof this.field.label.visible) {
            return this.field.label.visible;
        } else if ('function' === typeof this.field.label.visible) {
            return this.field.label.visible(this.item, this.field);
        } else {
            return this.field.label.visible;

        }
    }

    private get getAllParams(): any[] {
        if (this.field.serviceOptions && this.field.serviceOptions.methodsParams) {

            return concatDefinedArrray(this.field.serviceOptions.methodsParams.getAll, this.field.serviceOptions.methodsParams.global);
        }
        return [];
    }

    getDateSerializationFormat(type: string): string {
        return Tools.getDateSerializationFormat(type);
    }

    getplaceHolder(): string {
        if (this.focusedField) {
            return '';
        } else if (!this.focusedField && this.field.isRequired) {
            return this.field.label.text + ' *';
        } else {
            return this.field.label.text;
        }
    }

    initSlidePanelEvent($event): void {
        this.initSlidePanel.emit(this.field);
    }

    toggleHelp(): void {
        this.helpVisible = !this.helpVisible;
    }

    getReportName(): string {
        if (this.item[this.field.dataField] == null) {
            return null;
        }
        else if (this.dropdownValues[this.field.dataField]) {
            let currentObject;
            const currentData = this.item[this.field.dataField];
            //On vérifie si le champ contient uniquement l'id du report ou sinon c'est qu'il est l'objet EtiquetteIdAndName
            const currentId = typeof (currentData) === 'number' ? currentData : currentData.id;
            if (this.item[this.field.popupPrintOptions.reportTypeColumn] == ReportType.BASE) {
                currentObject = this.dropdownValues[this.field.dataField].find((object: any) => object.id === currentId);
            } else {
                currentObject = this.dropdownValues[this.field.dataField].find((object: any) => object.id === currentId && (object.idOriginePerso || object.idOrigine));
            }
            if (typeof (this.field.editorOptions.displayExpr) === 'string') {
                if (currentObject != undefined) {
                    return currentObject[this.field.editorOptions.displayExpr];
                } else {
                    return '';
                }
            }
        } else {
            return this.item[this.field.dataField];
        }
    }

    onEditButtonClicked(event): void {
        this.editButtonClickedEvent.emit(event);
    }

    selectClick(): void {
        this.fileUploader.instance['_isCustomClickEvent'] = true;
        this.fileUploader.instance['_$fileInput'][0].click();
        const cursor = this.editor.instance.getSelection();
        this.myCursor = cursor !== null ? cursor.index : 0;

    }

    onFocusIn(e): void {
        this.focusedField = true;
        const inputEl = e.element.querySelector(".dx-texteditor-input");
        inputEl.selectionStart = 0;
        inputEl.selectionEnd = inputEl.value.length;
    }

    onClickInfo(): void {
        this.clickInfo.emit();
    }

    public onFileChange(event: any): void {
        const file = event.value[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            this.editor.instance.insertEmbed(
                this.myCursor,
                "extendedImage",
                {
                    src: reader.result,
                    width: 100,
                    height: 100
                }
            );
            this.canDeactivate.emit(false);
        };
    }



}
