import { defaultDataGridOptions } from '../../defaultDevExtremeConfig';
import { cloneObject } from '@app/utils/cloningObject';
import { dxDataGridOptions } from 'devextreme/ui/data_grid';

export class NextDxDataGridOptions {

    header: {
        showHeaderTitle: boolean;
        showHeaderButtons: boolean;
        showHeaderTitleIcon: boolean;
        disableCreateButton: boolean;
        hideCreateButton: boolean;
        headerbuttonsTemplate: boolean;
        showDate: boolean;
        showFilters: boolean;
        showSearchBar: boolean;
        enableCloneRow: boolean;
        saveTextButton: {
            visible: boolean;
            text: string;
            width: string;
        }
        selectionMultile: {
            enabled: boolean;
            default: boolean;
            multipleAction: boolean
        }
        buttons: any[];
        /**toggle pour chargement des données  avec des getAllParams additional*/
        toggle: {
            showToggle: boolean;
            value: boolean;
            firstChoiceText: string;
            secondChoiceText: string;
            firstGetAllParams: number[];
            secondGetAllParams: number[]
        }
    };

    allowNavigateToDetail: boolean;

    breadcrumb: {
        enabled: boolean;
    };

    columns: {
        enableCellParameterTemplate: boolean;
        command: any;
    };

    rows: {
        clickEnabled: boolean,
        doubleClickEnabled: boolean,
    };

    toolbarButtons: {
        headerSearch: boolean
    };

    synchronize: {
        update: boolean;
        delete: boolean;
        add: boolean
    };

    selection: {
        mode: 'allPages' | 'page';
    };


    /*
    *   les options disponible pour parametrer l'affichage du dataGrid
    *   ( l'affichage de barre du recherche, la possibilités du l'export du données...)
    *   dataGridOptions envoyer sous formation object comme dans fichier devextremeConfig.ts
    *   ex :  dataGridOptions = {
                    remoteOperations: {
                        sorting: false,
                        paging: false,
                        filtering: false
                    }
               };
                TODO use options instead
    */
    customDxDataGridOptions: Partial<dxDataGridOptions>;
    /**
     * initialise le paramétrage par défaut
    */
    noGridState: boolean;
    constructor() {

        this.header = {
            showHeaderTitle: true,
            showHeaderButtons: true,
            showFilters: true,
            showHeaderTitleIcon: false,
            disableCreateButton: false,
            hideCreateButton: false,
            showDate: false,
            enableCloneRow: false,
            showSearchBar: true,
            buttons: null,
            headerbuttonsTemplate: false,
            saveTextButton: {
                visible: false,
                width: 'auto',
                text: ''
            },
            toggle: {
                showToggle: false,
                value: true,
                firstChoiceText: '',
                secondChoiceText: '',
                firstGetAllParams: [],
                secondGetAllParams: []
            },
            selectionMultile: {
                enabled: false,
                default: false,
                multipleAction: false
            }
        };

        this.allowNavigateToDetail = true,

            this.rows = {
                clickEnabled: true,
                doubleClickEnabled: true,

            };

        this.breadcrumb = {
            enabled: true
        };

        this.columns = {
            enableCellParameterTemplate: false,
            command: null
        };
        this.customDxDataGridOptions = cloneObject(defaultDataGridOptions);
        this.toolbarButtons = {
            headerSearch: true
        };
        this.synchronize = {
            update: true,
            delete: true,
            add: true
        };
        this.selection = {
            mode: 'page'
        };

        this.noGridState = false;
    }
}
