import { CommercialOperationInfo, SupplierCommercialOperation } from '..';

export class SupplierCommercialOperationInfo extends CommercialOperationInfo {
    operation: SupplierCommercialOperation;
    constructor() {
        super();
        this.operation = null;

    }
}

