import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { PhoneTypes } from './phoneTypes';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { Contacts } from './contacts';

export class PhoneNumber extends BaseEntity2 {

    number: string;
    type: PhoneTypes;
    contactId: number;

    constructor() {
        super();
        this.number = '';
        this.type = null;
        this.contactId = null;
    }
    validator(): ValidationRulesByFieldName {
        return {
            number : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true},
                // { type: 'pattern', pattern: '^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            type : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }


    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = [];
        return specificFieldForConfscreenModel;
    }


}
