import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ArticleFamily } from '@app/shared/model/article/articleFamily';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleFamilyService extends DaoGridStateBaseService implements IDaoBaseService {
    public _list: Observable<ArticleFamily[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<ArticleFamily[]> {
        const url = this.appConfiguration.UrlsConfig.wsArticleFamily;
        if (!this._list) {
            this._list =  this.http.get<any[]>(url)
            .pipe(
                shareReplay(1)
            );
        }
        return this._list;
    }

    /**
    * Create a ArticleFamily
    * @param payload request body
    */
    post(payload: ArticleFamily): Observable<NGCResults<ArticleFamily>> {
        const url = this.appConfiguration.UrlsConfig.wsArticleFamily;
        return this.http.post<NGCResults<ArticleFamily>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a ArticleFamily
    * @param code ArticleFamily code
    * @param payload ArticleFamily data
    */
    put(id: number, payload: ArticleFamily): Observable<NGCResults<ArticleFamily>> {
        const url = this.appConfiguration.UrlsConfig.wsArticleFamily + id;
        return this.http.put<NGCResults<ArticleFamily>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getPageTableTitle(): string {
        return 'article.articleFamilyGrid';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticleFamily + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
        this.router.navigate(['/parameters/article/family/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/family/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/family']);
    }

}
