import { Injectable } from '@angular/core';
import { Designation4 } from '@app/shared/model/article/designation4';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';
@Injectable({
    providedIn: 'root'
})
export class Designation4Service extends DaoGridStateBaseService implements IDaoBaseService {
    public _list: Observable<Designation4[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Designation4[]> {
        const url = this.appConfiguration.UrlsConfig.wsDesignation4;
        if (!this._list) {
            this._list = this.http.get<any[]>(url)
            .pipe(
                shareReplay(1)
            );
        }
        return this._list;
    }

    /**
    * Create a ArticleDesignation4
    * @param payload request body
    */
    post(payload: Designation4): Observable<NGCResults<Designation4>> {
        const url = this.appConfiguration.UrlsConfig.wsDesignation4;
        return this.http.post<NGCResults<Designation4>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a ArticleDesignation4
    * @param id ArticleDesignation4 id
    * @param payload ArticleDesignation4 data
    */
    put(id: number, payload: Designation4): Observable<NGCResults<Designation4>> {
        const url = this.appConfiguration.UrlsConfig.wsDesignation4 + id;
        return this.http.put<NGCResults<Designation4>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(): string {
        return 'article.designation4Grid';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsDesignation4 + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
        this.router.navigate(['/parameters/article/designation4/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/article/designation4/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/article/designation4']);
    }

}
