import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { variablesKeys } from '@app/globalsParameter';
import { UserType } from '../../../app/shared/model';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
    constructor(
        public router: Router,
        public localStorage: StorageService
    ) { }

    canActivate(): boolean {
        const currentUser = JSON.parse(this.localStorage.getItem(variablesKeys.USER));
        if (currentUser.type === UserType.SUPER_ADMINISTRATOR) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
