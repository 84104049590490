import { BaseEntity2 } from '@app/shared/model/base-entity2';

export class CompanyFollowUpStatus extends BaseEntity2 {
    designation: string;
    propagationMode: number;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.propagationMode = 0;
        this.visible = true;
    }

    initValues(): Partial<CompanyFollowUpStatus> {
        return {
            visible: true
        };
    }
}

export class CustomerFollowUpStatus extends CompanyFollowUpStatus {
    constructor() {
        super();
    }
}

export class SupplierFollowUpStatus extends CompanyFollowUpStatus {
    constructor() {
        super();
    }
}
