import {
    Currency
} from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export class Referencing extends BaseEntity2 {
    designation: string;
    currency: Currency;
    searchReferencingCode: number;
    searchReferencingBarCode: number;
    updateReferencingCode: number;
    updateReferencingBarCode: number;
    updateLabelPrice: number;
    updateResaleCoefficient: number;
    updateRound: number;
    updateLabelReport: number;
    updateComment: number;
    updatedArticleDesignation: number;
    updateDepot: number;
    updateAuxiliarySocietyNumber: number;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.currency = null;
        this.searchReferencingCode = 0;
        this.searchReferencingBarCode = 0;
        this.updateReferencingCode = 0;
        this.updateReferencingBarCode = 0;
        this.updateLabelPrice = 0;
        this.updateResaleCoefficient = 0;
        this.updateRound = 0;
        this.updateLabelReport = 0;
        this.updateComment = 0;
        this.updatedArticleDesignation = 0;
        this.updateDepot = 0;
        this.updateAuxiliarySocietyNumber = 0;
        this.visible = true;

    }
    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<Referencing> {
        return {
            visible: true
        };
    }
}
