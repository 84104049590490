import { Currency } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { TaxingType } from '../commercialOperation';
import { type } from 'os';
export enum TaxIncluded { YES, NO, DEFINED_BY_ARTICLE }
export enum RateEnteredOrCalculatedEnum { ENTERED, CALCULATED }

export class BasicRate extends BaseEntity2 {
    designation: string;
    currency: Currency;
    type: TaxingType;
    basicRateReplacement: BasicRate;
    startDateApplication: Date;
    endDateApplication: Date;
    quantitativeDiscountApplication: boolean;
    exciseDutiesIncluded: TaxIncluded;
    socialSecurityStampDutiesIncluded: TaxIncluded;
    rightsAndTaxesIncluded: TaxIncluded;
    rateEnteredOrCalculated: RateEnteredOrCalculatedEnum;
    calculationFormula: string;
    lastCalculationFormula: string;
    visible: boolean;
    drinkTaxIncluded: TaxIncluded;

    constructor() {
        super();
        this.designation = '';
        this.currency = null;
        this.type = TaxingType.HT;
        this.basicRateReplacement = null;
        this.startDateApplication = new Date();
        this.endDateApplication = new Date();
        this.quantitativeDiscountApplication = false;
        this.exciseDutiesIncluded = TaxIncluded.NO;
        this.socialSecurityStampDutiesIncluded = TaxIncluded.NO;
        this.rightsAndTaxesIncluded = TaxIncluded.NO;
        this.rateEnteredOrCalculated = RateEnteredOrCalculatedEnum.ENTERED;
        this.calculationFormula = '';
        this.lastCalculationFormula = '';
        this.visible = true;
        this.drinkTaxIncluded = TaxIncluded.NO;
    }
    validator(): ValidationRulesByFieldName {
        return {
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            currency: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            type: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<BasicRate> {
        return {
            visible: true,
        };
    }
}
