import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from '../services';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // continue if not cachable.
        let reqClone: HttpRequest<any>;
        if (!this.cache.isCachable(req)) {
            return next.handle(req);
        } else {
            reqClone = req.clone({
                params : req.params.delete('cache'),
                headers: req.headers.delete('Content-Type')
            });
        }

        const cachedResponse = this.cache.get(reqClone);
        return cachedResponse ?
            of(cachedResponse) : sendRequest(reqClone, next, this.cache);
    }
}
/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
        tap(event => {
            // There may be other events besides the response.
            if (event instanceof HttpResponse) {
                cache.put(req, event); // Update the cache.
            }
        })
    );
}
