<dx-button class="header-buttons" [hoverStateEnabled]="false" [focusStateEnabled]="false" type="default"
stylingMode="text" [template]="'buttonTemplate'" id="linkImportExport"
(click)="toggleMobiStore()">
<div *dxTemplate="let data of 'buttonTemplate'">
    <div class="header-button-notif">
        <span>
            <ngc-icon size="24" icon="cashRegister"></ngc-icon>
        </span>
        <!-- <span class="notif"></span> -->
    </div>
    <!-- <div class="loader">
        <div class="progress"></div>
    </div> -->
</div>
</dx-button>

<dx-popover class="import-export-header-container" target="#linkImportExport" [width]="200" [showTitle]="true" 
title="{{'mobilpanel.connect' | translate}}"  [visible]="toggleImportExportVisible" (visibleChange)="visibleChanged($event)" [wrapperAttr]="{ class: 'import-export-header-container' }">

<dxo-position my="top" at="top">
    <dxo-offset [x]="-75" [y]="40"></dxo-offset>
</dxo-position>
<div *dxTemplate="let data of 'content'" class="menuMobiConnect">
    <div class="centred padding">
        <dx-button [template]="'buttonTemplate'" type="default" [height]="32" [width]="104"
            (click)="ExportMore()">
            <div *dxTemplate="let data of 'buttonTemplate'">
                <span>
                    <ngc-icon size="16" icon="export"></ngc-icon>
                </span>
                <span>
                    {{'mobilpanel.export' | translate}}
                </span>
            </div>
        </dx-button>
    </div>
    <div *ngIf="exportParam" class="paddingCheck">
        <dx-check-box [(value)]="mobileStoreData" text="{{'mobilpanel.data' | translate}}"></dx-check-box>
    </div>
    <div *ngIf="exportParam" class="paddingEndCheck">
        <dx-check-box [(value)]="imageArticle" text="{{'mobilpanel.image' | translate}}"></dx-check-box>
    </div>
    <div *ngIf="exportParam" class="centred padding">
        <dx-button class="validate" text="{{'mobilpanel.validate' | translate}}" type="success" stylingMode="text" [height]="32" [width]="104"
            [disabled]="!imageArticle && !mobileStoreData" [hoverStateEnabled]="false" (click)="validateExport(mobileStoreData,imageArticle)"
            [focusStateEnabled]="false"></dx-button>
    </div>
    <div class="centred paddingImport">
        <dx-button [template]="'buttonTemplate'" type="default" [height]="32" [width]="104"  (click)="validateImport()">
            <div *dxTemplate="let data of 'buttonTemplate'">
                <span>
                    <ngc-icon size="16" icon="import"></ngc-icon>
                </span>
                <span>
                    {{'mobilpanel.import' | translate}}
                </span>
            </div>
        </dx-button>
    </div>
    <div class="centred underlined">
        <dx-button [hoverStateEnabled]="false" [focusStateEnabled]="false" text="{{'mobilpanel.results' | translate}}"
            type="default" stylingMode="text" (click)="showResults()"></dx-button>
    </div>



</div>
</dx-popover>
