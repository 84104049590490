<div *ngIf="customField && !customField.showOnlyIfImage" style="display: flex;"
  [ngStyle]="{'margin':  type === 'round'?'0':'6px'}">
  <div class="logo" [ngClass]="{'rounded': type === 'round'}" *ngIf="empty || customField">
    <img alt="logo" *ngIf="!empty && defaultImage(item); else emptyImg"
      [src]="defaultImage(item)[customField.editorOptions.externalUrlFieldName] ? imageUrl : (( imageUrl | securesrc) | async)">
    <ng-template #emptyImg>
      <div class="empty-img"></div>
    </ng-template>
  </div>
</div>

<div *ngIf="customField && customField.showOnlyIfImage && defaultImage(item)" class="item_img"
  [ngClass]="{'rounded': type === 'round'}">
  <img alt="logo" *ngIf="!empty && defaultImage(item);"
    [src]="defaultImage(item)[customField.editorOptions.externalUrlFieldName] ? imageUrl : (( imageUrl | securesrc) | async)">
</div>
