import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Location } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService extends DaoGridStateBaseService implements IDaoBaseService {

    public _list: Observable<Location[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Location[]> {
        const url = this.appConfiguration.UrlsConfig.wsLocation;
        if (!this._list) {
            this._list =  this.http.get<Location[]>(url)
            .pipe(
                shareReplay(1)
            );
        }
        return this._list;
    }

    /**
    * Create a location
    * @param payload request body
    */
    post(payload: Location): Observable<NGCResults<Location>> {
        const url = this.appConfiguration.UrlsConfig.wsLocation;
        return this.http.post<NGCResults<Location>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a location
    * @param id location id
    * @param payload location data
    */
    put(id: number, payload: Location): Observable<NGCResults<Location>> {
        const url = this.appConfiguration.UrlsConfig.wsLocation + id;
        return this.http.put<NGCResults<Location>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(): string {
        return 'location';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsLocation + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
        this.router.navigate(['/parameters/stock/location/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/stock/location/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/stock/location']);
    }
}
