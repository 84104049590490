import { Component, OnInit, Input, ChangeDetectorRef, Renderer2, Output, EventEmitter, ViewChild, HostListener, SimpleChanges, OnChanges, OnDestroy, AfterViewChecked } from '@angular/core';
import * as globalsParameter from '@app/globalsParameter';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { IDaoBaseService } from '@app/shared/interface';
import { IDaoServiceOptions, FormGroupItem, FormField, NextDxFormOptions, FormSubmitEvent, FormFieldObject } from '../dx-form/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { concatDefinedArrray } from '@app/utils/daoHelper';
import { TranslateService } from '@ngx-translate/core';
import { ErrorsService, ConfScreenService, ScreenService } from '@app/core/services';
import { HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { ItemplateHostComponent, KeyedTemplateDirective } from '@app/shared/directive';
import { Tools } from '@app/shared/class/tools';
import { SavingStateEnum } from '../dx-form/dx-form.component';
import { cloneObject } from '@app/utils/cloningObject';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { confirmDialogWithCancel, CustomConfirmDialogResult, showAlertsNGCResults } from '@app/utils/dialogs';


@Component({
    selector: 'app-dx-form-simplified',
    templateUrl: './dx-form-simplified.component.html',
    styleUrls: ['./dx-form-simplified.component.scss']
})


export class DxFormSimplifiedComponent implements ItemplateHostComponent, OnChanges, OnDestroy {
    /** the id of the object to get by the service fetchData() (if id is != null we are on edit mode) obligatoire en cas de modifiction */
    @Input() id?: string | number;
    /** options avancé du formulaire (exemple faire le traitement du service ou non) */
    @Input() options?: NextDxFormOptions = new NextDxFormOptions();
    /**  obligatoire pour les composant qui implementent IPopupComponent*/
    @Input() inPopup?: boolean = false;
    // used from popup
    @Input() popupContentHeight: number = null;
    @Input() scrollwindowHeight = 300;
    /** service de la classe ex : productService */
    @Input() service?: IDaoBaseService;
    /** options du service obligatoire pour les cas de companyType ou ClassName service generic */
    @Input() serviceOptions?: IDaoServiceOptions;
    /** (optional input) nom du champ qui défini la clé primaire. Si non défini on prend id par défaut*/
    @Input() primaryKeyFieldName?: string = 'id';
    /** le model Ex:Category, Company // obligatoire s'il n'existe pas du formItems input */
    @Input() model: any;
    /** (optional input) pour personnaliser que quelque champ de notre object */
    @Input() customFields?: FormFieldObject = {};
    /** (optional input) les champs que tu veux exclure du formulaire */
    @Input() set excludedFieldList(list: string[]) {
        this._excludedFieldList = list;
    }
    @Input() headerSimplified: string;
    @Input() type: string;
    /**
    * @param editForm pour dire si c'est en mode edition ou non
    */
    @Input() editable: boolean | ((item: any, editForm: boolean) => boolean) = true;

    /** evenement de l'état d'annuler l'ajout*/
    @Output() cancelAdd = new EventEmitter<boolean>();
    /** evenement de l'état du formulaire ( modifié ou dans l'état initial)*/
    @Output() canDeactivate = new EventEmitter<boolean>();
    /** evenement du click sur les boutons du formulaire */
    @Output() submit = new EventEmitter<FormSubmitEvent>();
    /** evenement de réussite de la requete (POST | PUT) vers le serveur */
    @Output() validateSuccess: EventEmitter<any> = new EventEmitter<any>();
    /** evenement de changement de valeur d'un champ du formulaire */
    @Output() formDataChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() linkClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveBtnSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() visibilityClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelBtnClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() beforeSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() confirmDialogYes: EventEmitter<any> = new EventEmitter<any>();
    @Output() confirmDialogNo: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('validationGroupe', { static: false }) validationGroupe: DxValidationGroupComponent;

    public formCanDeactivate = true;
    public pendingSubmit = false;
    /** used to describe the state of form saving process [true = doing] */
    public savingState = new BehaviorSubject<SavingStateEnum>(SavingStateEnum.NO);
    /** la liste de champ du formulaire a affiché */
    public formItems: FormGroupItem[] | FormField[] | any = [];
    public cssClasses: any;
    /** spécifie si le formulaire à était buildé (le tableau formItems est construit) */
    public formBuilded = false;
    public additionalButtonsVisibility = false;
    public itemCopy: any = {};
    public contextualHelpOn = false;
    // Local Variables
    public templateDirectiveMap: Map<string, any> = new Map<string, any>();
    public item: any = {};
    public items: any[] = [];
    public itemLoading = new BehaviorSubject(false);

    private alwaysDisabledFields: Array<string> = ['dateCreated', 'dateUpdated', 'id', 'version'];
    /** les champs par defaut qui ne sont pas affichés ds le formulaire dxForm */
    private _excludedFieldList: string[] = []; // ['id', 'version', 'dateCreated', 'dateUpdated'];
    // subscriptions
    private subscriptions: Subscription[] = [];

    idPrevious: any;
    idNext: any;
    scrHeight: any;
    validForm = true;

    constructor(
        private _translate: TranslateService,
        // private _authService: AuthService,
        private _errorsSrv: ErrorsService,
        private confScreenService: ConfScreenService,
        private _changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2,
        public screenService: ScreenService
    ) {
        this.getScreenSize();
        this.subscriptions.push(
            this.canDeactivate.subscribe(
                (val: boolean) => {
                    this.formCanDeactivate = val;
                }
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.prepareFieldForm();
        if (changes.customFields && !changes.customFields.firstChange) {
            this.formBuilded = false;
            this.formItems = [];
            this.prepareFieldForm();
        }
        if (this.id) {
            this.subscriptions.push(
                (this.serviceOptions && this.serviceOptions.getAll ? this.serviceOptions.getAll : this.service.getAll(...this.getAllParams)).subscribe(
                    (items: any[]) => {
                        this.items = items;
                        this.manageCurrentItemTemplateSide();
                    },
                    (error: any) => {
                        this.itemLoading.next(false);
                    }
                )
            );
        }
        this.manageCssClasses();
    }

    private get getAllParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.getAll);
        }
        return [];
    }

    manageCurrentItemTemplateSide() {
        this.item = cloneObject(this.items.find((item: any) => item[this.primaryKeyFieldName] === this.id));
        if (!this.item) {
        }
        this.itemCopy = cloneObject(this.item);
        this.canDeactivate.emit(true);
        this.itemLoading.next(false);
        this.prepareVariableForTemplateSideNavigation();
    }

    /**
     * preparer les variables : idNext, idPrevious pour la navigation templateSide
     */
    private prepareVariableForTemplateSideNavigation(): void {
        const index: number = this.items.findIndex(
            // we dont check the type of this.id because id could be number or string
            // eslint-disable-next-line eqeqeq
            item => item[this.primaryKeyFieldName] == this.id
        );
        this.idPrevious = index > 0 ? this.items[index - 1][this.primaryKeyFieldName] : null;
        this.idNext = index + 1 < this.items.length ? this.items[index + 1][this.primaryKeyFieldName] : null;
    }


    private manageCssClasses(): void {
        this.cssClasses = {
            'inpopup': this.inPopup,
            // 'card': this.formItems.length > 0 && this.formItems[0].itemType !== 'group'
        };
    }

    private prepareFieldForm(): void {
        if (this.formItems.length === 0 && !this.formBuilded) {
            this.manageFieldsForModel(this.model, this.customFields, this.item);
            this.formItems = Object.values(this.customFields) as FormField[];
            this.formItems.sort((t1, t2) => t1.visibleIndex - t2.visibleIndex);

            this.formItems.forEach((item) => {
                if (item.subFields) {
                    item.items = Object.values(item.subFields);
                }
            });

            this.formBuilded = true;
        }
    }

    private manageFieldsForModel(model: any, customFields: FormFieldObject | FormField[], item: any): any {
        let fields: any[] = (Object.getOwnPropertyNames(model));
        fields = fields.filter(el => {
            return this._excludedFieldList.indexOf(el) === -1;
        });
        let modelValidator = {};
        if (model.validator !== undefined) {
            modelValidator = model.validator();
        }
        let initValues = {};
        if (model.initValues !== undefined) {
            initValues = model.initValues();
        }

        fields.forEach((field: string, index: number) => {

            if (!(customFields && customFields[field] && customFields[field].subFields)) {
                // si on est pas dans un sous-formulaire
                this.manageField(field, customFields, index, modelValidator, model, item, initValues);
                // regarder si le champ est un champ groupé
                if (model && model.specificFieldForConfscreen && model.specificFieldForConfscreen.groupsField[field]) {
                    // on mémorise l'infos dans le customField pour l'avoir dans le merge du conf_screen
                    customFields[field].groupsField = model.specificFieldForConfscreen.groupsField[field];
                }
            } else {
                if (!item[field]) {
                    item[field] = customFields[field]['editorOptions']['model'];
                }
                this.manageFieldsForModel(customFields[field]['editorOptions']['model'], customFields[field].subFields, item[field]);
            }


        });
    }

    private manageField(field: any, customFields: FormFieldObject | FormField[], index, modelValidator, model, item: any, initValues): any {
        let dxiItem: FormField;
        if (customFields[field] && !customFields[field].mergeConfig) {
            dxiItem = customFields[field];
        } else {
            dxiItem = {
                dataField: field,
                editorType: Tools.getDxFormInputType(model[field]),
            };
        }

        if (dxiItem.visibleIndex == null || dxiItem.visibleIndex === undefined) {
            dxiItem.visibleIndex = index;
        }
        if (dxiItem.visible === undefined) {
            dxiItem.visible = true;
        }
        // concatener les validation dans le model + les validation du composant principale
        dxiItem.validationRules = concatDefinedArrray(modelValidator[dxiItem.dataField], dxiItem.validationRules);
        dxiItem.isRequired = dxiItem.validationRules && dxiItem.validationRules.some(t => t.type === 'required');
        if (!dxiItem.label) {
            dxiItem.label = { text: this._translate.instant('model.' + dxiItem.dataField), visible: dxiItem.visible };
        } else {
            dxiItem.label = { text: dxiItem.label.text, visible: dxiItem.label.visible };
        }

        // défault (always config)
        dxiItem.editorOptions = customFields[field] ? customFields[field].editorOptions : null;
        dxiItem.itemType = 'simple';
        if (customFields[field] && customFields[field].mergeConfig) {
            customFields[field] = { ...dxiItem, ...customFields[field] };
        } else {
            customFields[field] = dxiItem;
        }
        // disactive le champ clé primaire en mode edition.
        if (field === this.primaryKeyFieldName && this.id) {
            customFields[field].disabled = true;
        }

        // disactive les champs always disabled.
        if (this.alwaysDisabledFields.some(f => f === field)) {
            customFields[field].disabled = true;
        }

        // initialiser les valeur par défaut à partir du this.customFields[field].editorOptions.value
        if (
            !this.id && customFields[field].editorOptions &&
            customFields[field].editorOptions.value != null &&
            customFields[field].editorOptions.value !== undefined
        ) {
            item[field] = customFields[field].editorOptions.value;
        } else if (!this.id && initValues[field]) {
            item[field] = initValues[field];
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
    onLinkClick(name: string, formItem: any) {
        let linkparam: string[] = [name, formItem.dataField]
        this.linkClick.emit(linkparam);
    }

    /**
     * Event emitied par app-dx-form-simplified pour rendre un champ invisible
     */
    onVisibilityClick(formItem: any) {
        this.visibilityClick.emit(formItem);
    }

    /**
     * this function is called from the template directive to add a template to this component
     * @param key identifiant du template
     * @param templateDirective directive contenant le template
     */
    public registerTemplate = function (key: string, templateDirective: KeyedTemplateDirective): void {
        if (!this.templateDirectiveMap) {
            this.templateDirectiveMap = new Map<string, any>();
        }
        const keyedTemplate = {
            key: key,
            templateDirective,
        };
        this.templateDirectiveMap.set(key, keyedTemplate);
    };

    /**
     * récupére le template qui va remplacer celui par défaut de l'input si il existe
     * @param formItem l'objet identifiant le champ
     */
    getTemplate(formItem: FormField): any {
        const keyedTemplate = { field: null };
        keyedTemplate.field = this.templateDirectiveMap.has(formItem.dataField) ? this.templateDirectiveMap.get(formItem.dataField).templateDirective : null;
        if (formItem.template && formItem.template.length > 0) {
            formItem.template.forEach(templateName => {
                keyedTemplate[templateName] = this.templateDirectiveMap.has(templateName) ? this.templateDirectiveMap.get(templateName).templateDirective : null;
            });
        }
        return keyedTemplate;
    }

    get formFooterTemplate() {
        return this.templateDirectiveMap.has('formFooter') ? this.templateDirectiveMap.get('formFooter').templateDirective : null;
    }
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight - 90;
    }
    saveBtn() {
        this.saveBtnSubmit.emit(this.item);
    }
    cancelBtn() {
        this.cancelBtnClick.emit(null);
    }


    CheckBeforeSave(event: any, method?: 'validate' | 'create') {
        this.beforeSave.emit(this.item);
        if (this.options.confirmDialog.show && this.options.checkBeforeSave) {
            const dialogConfirm =
                confirmDialogWithCancel(
                    this.options.confirmDialog.title,
                    this.options.confirmDialog.textCancel,
                    this.options.confirmDialog.textNo,
                    this.options.confirmDialog.textYes,
                    this.options.confirmDialog.textContent,
                    '110',
                    '110',
                    '110'
                );
            dialogConfirm.show().then(
                (dialogResult: CustomConfirmDialogResult) => {
                    if (dialogResult === CustomConfirmDialogResult.YES) {
                        this.confirmDialogYes.emit(this.item);
                        this.save(event, method);
                    } else if (dialogResult === CustomConfirmDialogResult.NO) {
                        this.confirmDialogNo.emit(this.item);
                        this.save(event, method);
                    } else {
                        this.options.checkBeforeSave = false;
                    }
                }
            );
        } else if (this.options.checkBeforeSave) {
            this.save(event, method);
        }
    }
    /**
     * fonction appelé lors du click sur un des boutons du l'header
     * @param event evenement du click par défaut
     * @param method valeur du bouton
     * @param validationGroupe liste de validation
     */
    save(event: any, method?: 'validate' | 'create'): void {
        if (this.formCanDeactivate && method === 'create') {
            this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
            return;
        }
        this.pendingSubmit = true;
        this.savingState.next(SavingStateEnum.YES);
        let isValid: boolean = false;
        isValid = this.validationGroupe.instance.validate().isValid;
        if (isValid) {
            this.validForm = true;
            if (this.id && ((method === 'validate' && !this.options.edit.disableDefaultSaveEvent))) {
                this.subscriptions.push(
                    this.service.put(this.id, this.item, ...this.getPutParams).subscribe(
                        (item) => {
                            this.putCallback(method, item);
                        },
                        (error: HttpErrorResponse) => {
                            this.putCallback(method, null, this.validationGroupe, error);
                        }
                    )
                );
            } else if (!this.id
                && ((method === 'validate' && !this.options.create.disableDefaultSaveEvent)
                    || (method === 'create' && !this.options.create.disableDefaultSaveAndAddNewEvent))) {
                this.subscriptions.push(
                    this.service.post(this.item, ...this.getPostParams).subscribe(
                        (item: any) => {
                            this.postCallback(method, item, this.validationGroupe);
                        },
                        (error: HttpErrorResponse) => {
                            this.postCallback(method, null, this.validationGroupe, error);
                        }
                    )
                );
            }
        }
    }

    public get getPostParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.post);
        }
        return [];
    }

    public get getPutParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.put);
        }
        return [];
    }

    private get getNavigateToCreatePageParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams) {
            return concatDefinedArrray(this.getGlobalParams, this.serviceOptions.methodsParams.navigateToCreatePage);
        }
        return [];
    }
    public get getGlobalParams(): any[] {
        if (this.serviceOptions && this.serviceOptions.methodsParams && this.serviceOptions.methodsParams.global) {
            return this.serviceOptions.methodsParams.global;
        }
        return [];
    }

    private postCallback(method: 'validate' | 'create', result: any, validationGroupe: DxValidationGroupComponent, error?: HttpErrorResponse): void {
        if (!error) {
            this.canDeactivate.emit(true);
            notify('Création effectuée', 'success', globalsParameter.notifySuccessDelay);
            this.validateSuccess.emit(result);

            if (!this.options.create.disableRedirect && !this.inPopup) {
                if (method === 'validate') {
                    this.service.navigateToDetail(result[this.primaryKeyFieldName], ...this.getNavigateToCreatePageParams);
                } else {
                    // using this reset restore all value to undefined ( so no validation error are shown) and trigger change on fields( can deactivate false)
                    validationGroupe.instance.reset();
                    setTimeout(() => {
                        this.initFormValues();
                    });
                }
            }
        } else {
            this.savingState.next(SavingStateEnum.CANCEL);
            if (error.status === 400) {
                this.showServerError(error.error);
            }
        }
        this.pendingSubmit = false;
        this.savingState.next(SavingStateEnum.NO);
    }

    private putCallback(method: 'validate' | 'create', result: NGCResults<any>, validationGroupe?: DxValidationGroupComponent, error?: HttpErrorResponse): void {
        if (!error) {
            notify('Modification effectuée', 'success', globalsParameter.notifySuccessDelay);
            if (result.withCautions) {
                showAlertsNGCResults(result.ngcResults, this._translate.instant('WarningList')).then(() => {
                });
            }
            result = result.ngcResults[0].data;

            this.validateSuccess.emit(result);
            // on met à jour l'objet dans la liste
            const idxCurrentItem = this.items.findIndex((item: any) => item[this.primaryKeyFieldName] == this.id);
            this.items[idxCurrentItem] = result;
            if (!this.options.edit.disableRedirect) {
                if (method === 'create') {
                    // this.canDeactivate.emit(true);
                    this.service.navigateToCreatePage(...this.getNavigateToCreatePageParams);
                } else {
                    this.item = result;
                    this.itemCopy = cloneObject(this.item);
                    // this.canDeactivate.emit(true);
                }
            }
            this.canDeactivate.emit(true);
        } else {
            this.savingState.next(SavingStateEnum.CANCEL);
            if (error.status === 400) {
                this.showServerError(error.error);
            }
        }
        this.pendingSubmit = false;
        this.savingState.next(SavingStateEnum.NO);
    }


    initFormValues(): void {
        let initValues = {};
        if (this.model.initValues !== undefined) {
            initValues = this.model.initValues();
        }
        for (const key in this.model) {
            if (this.customFields.hasOwnProperty(key) && this.customFields[key].editorOptions && (this.customFields[key].editorOptions.value != undefined || this.customFields[key].editorOptions.value == null)) {
                this.item[key] = this.customFields[key].editorOptions.value;
            } else if (initValues.hasOwnProperty(key)) {
                this.item[key] = initValues[key];
            }
        }
    }

    private showServerError(error: any) {
        console.log(error.error);
    }

    get isEditable(): boolean {
        if ('boolean' === typeof this.editable) {
            return this.editable;
        } else if ('function' === typeof this.editable) {
            return this.editable(this.item, !!this.id);
        } else {
            throw new Error('what did you put as editable input ??');
        }
    }

    onCanDeactivateChanged(event: boolean, field?: FormField): void {
        this.canDeactivate.emit(event);
    }

    onValueChanged(val: any): void {
        this.formDataChange.emit(val);
    }

    getHeight() {
        if (this.options.css.height != null) {
            return this.options.css.height;
        } else {
            return (window.innerHeight - this.scrollwindowHeight);
        }
    }
}
