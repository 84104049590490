import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DevextremeModule } from './modules/devextreme.module';
import * as service from 'app/core/services';
import { DesactivateGuard } from 'app/core/guard/desactivate.guard';
import { PopupReportPrintComponent } from './component/popup-report-print/popup-report-print.component';
import { PopupMailComponent } from './component/popup-mail/popup-mail.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { UnlockGuard } from '@app/core/guard/unlock.guard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PopupLabelComponent } from './component/popup-label/popup-label.component';
import { MobilStorePanelComponent } from '@app/connected-content/widgets/header/mobil-store-panel/mobil-store-panel.component';

@NgModule({
    imports: [
        DevextremeModule,
        DragDropModule,
        DxRadioGroupModule,
        InfiniteScrollModule
    ],
    exports: [
        RouterModule,
        DevextremeModule,
        DragDropModule,
        PopupReportPrintComponent,
        PopupMailComponent,
        PopupLabelComponent,
        MobilStorePanelComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    declarations: [
        PopupReportPrintComponent,
        PopupMailComponent,
        PopupLabelComponent,
        MobilStorePanelComponent
    ],
    providers: [
        service.UserService,
        DesactivateGuard,
        UnlockGuard,
    ]
})
export class SharedModule { }
