import { BaseEntity2 } from '@app/shared/model/base-entity2';

export class MovementTitleType extends BaseEntity2 {
    designation: string;
    code: string;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.code = '';
        this.visible = true;
    }

    initValues(): Partial<MovementTitleType> {
        return {
            visible: true
        };
    }
}
