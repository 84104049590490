export function concatDefinedArrray(val1: any[], val2: any[]): any[] {
    if (!(val1 && val2)) {
        return val1 || val2;
    } else {
        return [...val1, ...val2];
    }
}

/**
 * Sort Mixed Array (alphabets and numbers)
 * @param a object1 to compare
 * @param b object 2 to compare
 * @returns number negatif or positif
 */
export function naturalCompare(a, b): number {
    const ax = [], bx = [];

    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || '']); });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || '']); });

    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
      if (nn) {
       return nn;
      }
    }
    return ax.length - bx.length;
 }
