<dx-toast type="custom" [(visible)]="isVisibleNotify" [contentTemplate]="contentTemplate" [displayTime]="delay">
    <div *dxTemplate="let data of 'success'">
        <div class="successNotify">
            <ngc-icon class="notifyIcon" icon="success" size="24"></ngc-icon>
            <span class="notifyText">{{notifyText}}</span>
        </div>
    </div>
    <div *dxTemplate="let data of 'info'">
        <div class="defaultNotify">
            <ngc-icon class="notifyIcon" icon="info" size="24"></ngc-icon>
            <span class="notifyText">{{notifyText}}</span>
        </div>
    </div>
    <div *dxTemplate="let data of 'warning'">
        <div class="warningNotify">
            <ngc-icon class="notifyIcon" icon="warning" size="24"></ngc-icon>
            <span class="notifyText">{{notifyText}}</span>
        </div>
    </div>
    <div *dxTemplate="let data of 'error'">
        <div class="dangerNotify">
            <ngc-icon class="notifyIcon" icon="error" size="24"></ngc-icon>
            <span class="notifyText">{{notifyText}}</span>
        </div>
    </div>
</dx-toast>
