import { BaseEntity2 } from '..';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';

export class CommercialOperationInfo extends BaseEntity2 {
    /** */
    importDate: Date;
    /** CustomerCommercialOperation not mapped */
    origin: number;
    /** */
    refForCompany: string;
    /** utilisé pour l'application mobile */
    idTablette: string;
    /** id de l'app mobiBusiness */
    idMobiBusiness: number;
    /** */
    typeImport: number;
    /** Nom(societer) de celui qui l'as crée (peut être externe) */
    userName: string;

    constructor() {
        super();
        this.importDate = new Date();
        this.origin = 0;
        this.refForCompany = '';
        this.idTablette = '';
        this.idMobiBusiness = 0;
        this.typeImport = 0;
        this.userName = '';

    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {

        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm =  [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = ['importDate', 'userName',
         'origin', 'idTablette', 'idMobiBusiness', 'typeImport', 'operation'];
        return specificFieldForConfscreenModel;
    }
}
