import { BaseEntity2 } from '../base-entity2';
import { LabelRule, RoundingRule } from '..';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';

export class CommercialOperationLabel extends BaseEntity2 {
    /** n° de rapport etiquette */
    report: number;
    /** Regle étiquette pour définir quel type de quantité se baser pour 1 étiquette
     *
     * obligatoire
    * */
    qtyTypeForOne: LabelRule;
    /** */
    quantity: number;
    /** Coefficient pour le prix a afficher sur l'étiquette */
    coefficientResale: number;
    /** Règle d'arrondi pour le calcul du prix étiquette
     *
     * obligatoire
     */
    roundingRuleForPriceCalculation: RoundingRule;

    constructor() {
        super();
        this.report = 0;
        this.qtyTypeForOne = LabelRule.NONE;
        this.quantity = 0;
        this.coefficientResale = 0;
        this.roundingRuleForPriceCalculation = RoundingRule.DEFINED_FOR_SOCIETY;

    }


    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {

        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm =  [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = ['coefficientResale'];
        return specificFieldForConfscreenModel;
    }
}
