import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountingCodeType, PurchaseCode, SalesCode, CustomerAccountingFamily } from '@app/shared/model/accounting/accountingCode';
import { shareReplay } from 'rxjs/operators';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { TranslateService } from '@ngx-translate/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingCodeService extends DaoGridStateBaseService implements IDaoBaseService {

    // public _Purchaselist: PurchaseCode[] = null;
    // public _Saleslist: SalesCode[] = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);
     }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(accountingCodeType: AccountingCodeType): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsAccountingCode + accountingCodeType;
        if (!this[accountingCodeType]) {
            this[accountingCodeType] = this.http.get<any[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }

        return this[accountingCodeType];
    }

    /**
    * Create a AccountingCode
    * @param payload request body
    */
    post(payload: PurchaseCode | SalesCode | CustomerAccountingFamily, accountingCodeType: AccountingCodeType): 
    Observable<NGCResults<PurchaseCode | SalesCode | CustomerAccountingFamily>> {
        const url = this.appConfiguration.UrlsConfig.wsAccountingCode + accountingCodeType;
        return this.http.post<NGCResults<PurchaseCode | SalesCode | CustomerAccountingFamily>>(url, payload).clearListCache(this.clearListTablesCache, accountingCodeType);
    }

    /**
    * Update a Advice
    * @param id Advice id
    * @param payload Conditioning data
    */
    put(id: number, payload: PurchaseCode | SalesCode | CustomerAccountingFamily, accountingCodeType: AccountingCodeType): 
    Observable<NGCResults<PurchaseCode | SalesCode | CustomerAccountingFamily>> {
        const url = this.appConfiguration.UrlsConfig.wsAccountingCode + accountingCodeType + '/' + id;
        return this.http.put<NGCResults<PurchaseCode | SalesCode | CustomerAccountingFamily>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (accountingCodeType: AccountingCodeType) => {
        this[accountingCodeType] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(accountingCodeType: AccountingCodeType): string {
        if (accountingCodeType === AccountingCodeType.SALES) {
            return 'article.salesCodeGrid';
        } else if (accountingCodeType === AccountingCodeType.PURCHASE) {
            return 'article.purchasesCodeGrid';
        } else {
            return 'customer.customerAccountingFamilyGrid';
        }
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number, accountingCodeType: AccountingCodeType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsAccountingCode + accountingCodeType + '/' + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: string, accountingCodeType: AccountingCodeType) {
        this.router.navigate(['/parameters/article/accountingCode/' + accountingCodeType + '/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(accountingCodeType: AccountingCodeType): void {
        this.router.navigate(['/parameters/article/accountingCode/' + accountingCodeType + '/create']);
    }

    navigateToList(accountingCodeType: AccountingCodeType): void {
        this.router.navigate(['/parameters/article/accountingCode/' + accountingCodeType]);
    }

}
