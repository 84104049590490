<!-- affichage du child -->
<div class="child-box" style="display: inline-flex; padding-right: 3em;min-width: 55%;"
    *ngIf="childItem && headerInfo.parent.parentService && headerInfo.child">
    <app-image [item]="childItem" [type]="headerInfo.parent.imageDisplayType"
        [customField]="headerInfo.child.displayImage"></app-image>
    <div style="display: flex;
    justify-content: center;
    flex-direction: column;">
        <div class="header-indicator" *ngIf="!headerInfo.child.displayImage && headerInfo.child.icon">
            <div class="header-icon">
                <ngc-icon [icon]="[headerInfo.child.icon]" size="32"></ngc-icon>
            </div>
            <div class="header-icon-text">
                {{headerInfo.pageTitle}}
            </div>
        </div>
    </div>
    <div class="text-zone">
        <div class="title-zone">
            <div class="title">
                <ng-template ngFor let-labelFieldName [ngForOf]="headerInfo.child.labelFieldName">
                    {{ getNestedObject(childItem, labelFieldName) }}
                </ng-template>
                <div *ngIf="headerInfo.child.fixedTitle">
                    {{headerInfo.child.fixedTitle(childItem)}}
                </div>
            </div>
        </div>


        <div style="width: 100%; margin: 10px;" *ngIf="headerInfo.parent.subtitleItems"
            [ngStyle]="{'border-top': headerInfo.mode === HeaderMode.List?'0px':'1px solid lightgrey'}">
            <div class="sub-left-bloc">
                <div class="subtitle">
                    <ng-template ngFor let-subtitleItem [ngForOf]="headerInfo.child.subtitleItems">
                        <div style="display: flex;">
                            <div class="subtitle-label" *ngIf="subtitleItem.label" style="width: 100px;">
                                {{subtitleItem.label}}
                            </div>
                                <span class="feather-icon" *ngIf="subtitleItem.icon">
                                    <ngc-icon size="20" [icon]="[subtitleItem.icon]"></ngc-icon>
                                </span>
                                <ng-template [ngIf]="subtitleItem.attribute!=='email'">
                                    {{ getNestedObject(childItem, subtitleItem.attribute) }}
                                </ng-template>
                                <a *ngIf="subtitleItem.attribute === 'email' && getSubtitleItemText(childItem, subtitleItem) !== undefined && getSubtitleItemText(childItem, subtitleItem) !== ''"
                                        [href]="href" class="truncate-text">
                                    <span>{{ getSubtitleItemText(childItem, subtitleItem) }}</span></a>
                                <ng-template [ngIf]="subtitleItem.textCallback">
                                    {{subtitleItem.textCallback(childItem) }}
                                </ng-template>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- affichage en mode creation -->

<div style="display: inline-flex;" [ngClass]="{'ml-3': headerInfo.parent}"
    *ngIf="headerInfo.mode === HeaderMode.Create && (!(childItem && headerInfo.parent.parentService && headerInfo.child))">
    <!-- image empty si mode normal et parent à une image ou si mode multi et fils a une image -->
    <app-image
        [empty]="multiHeaderBlocMode && headerInfo.child.displayImage  || !multiHeaderBlocMode && headerInfo.parent.displayImage">
    </app-image>

    <div class="header-indicator" *ngIf="headerInfo.child && headerInfo.child.icon">
        <div class="header-icon">
            <ngc-icon [icon]="[headerInfo.child.icon]" size="32"></ngc-icon>
        </div>
        <div class="header-icon-text">
            {{ 'Creating' | translate }}
        </div>
        <div class="header-icon-text">
            {{headerInfo.pageTitle}}
        </div>
    </div>

    <div class="text-zone" *ngIf="!headerInfo.child">
        <div class="title">
            <span *ngIf="!headerInfo.isDuplication">{{ 'Creating' | translate }}</span>
            <span *ngIf="headerInfo.isDuplication">Duplication</span>
            <span class="page-title">
                {{ headerInfo.pageTitle ? (headerInfo.pageTitle | translate) : ('merci de spécifier headerInfo.pageTitle') }}
            </span>
        </div>
    </div>


</div>
<!-- affichage en mode List -->
<div *ngIf="headerInfo.mode === HeaderMode.List" style="    display: flex;
flex-direction: column;
justify-content: center;">
    <div>
        <div *ngIf="headerInfo.icon" class="header-indicator">

            <div class="header-icon">
                <ngc-icon [icon]="[headerInfo.icon]" size="32"></ngc-icon>
            </div>
            <div class="header-icon-text">
                {{ headerInfo.pageTitle ? (headerInfo.pageTitle | translate) : ('merci de spécifier headerInfo.pageTitle') }}
            </div>

        </div>
        <div *ngIf="!headerInfo.icon" class="text-zone">
            <div class="title">
                <div class="page-title">
                    {{ headerInfo.pageTitle ? (headerInfo.pageTitle | translate) : ('merci de spécifier headerInfo.pageTitle') }}
                </div>
            </div>
        </div>
    </div>
</div>

<!-- affichage en mode simple (pas de child) -->
<div class="parent" style="display: inline-flex;" [ngClass]="{'has-child': headerInfo.child}"
    *ngIf="((headerInfo.mode === HeaderMode.Create && headerInfo.parent.parentService) || headerInfo.mode !== HeaderMode.Create) && parentItem">
    <app-image [item]="parentItem" *ngIf="headerInfo.parent.displayImage && (headerInfo.mode !== HeaderMode.List)"
        [type]="headerInfo.parent.imageDisplayType" [customField]="headerInfo.parent.displayImage"></app-image>

    <div class="header-indicator" *ngIf="!headerInfo.parent.displayImage && headerInfo.parent.icon">
        <div class="header-icon">
            <ngc-icon [icon]="getIcon(headerInfo.parent.icon, parentItem)" size="32"></ngc-icon>
        </div>
        <div class="header-icon-text" style="white-space: pre-line;text-align: center;">
            <!-- {{headerInfo.pageTitle(parentItem)}} -->
            {{pageTitle | translate}}
        </div>
    </div>

    <div class="text-zone">
        <div style="height: 42px;width: 100%;display: flex;justify-content: center;flex-direction: column;">
            <div class="title" style="padding-left: 10px;">
                <ng-template ngFor let-labelFieldName [ngForOf]="headerInfo.parent.labelFieldName">
                    {{ getNestedObject(parentItem, labelFieldName) | translate }}
                </ng-template>
                <div *ngIf="headerInfo.parent.fixedTitle" style="display: flex;">
                    <span>{{headerInfo.parent.fixedTitle(parentItem)}}</span>
                    <span *ngIf="headerInfo.parent.state" [class]="'badge ' + headerInfo.parent.state(parentItem).type">
                        <span class="badge-text">{{headerInfo.parent.state(parentItem).label}}</span>
                    </span>
                    <span class="additionalInfo" *ngIf="headerInfo.parent.state">{{headerInfo.parent.state(parentItem).additionalInfo}}</span>
                </div>
            </div>
        </div>
        <div style="width: 100%;margin: 10px;"
            *ngIf="headerInfo.parent.subtitleItems &&  headerInfo.parent.detailsFields"
            [ngStyle]="{'border-top': headerInfo.mode === HeaderMode.List?'0px':'1px solid lightgrey'}">
            <div class="sub-left-bloc">
                <div class="subtitle">
                    <ng-template ngFor let-subtitleItem [ngForOf]="headerInfo.parent.subtitleItems">
                        <div style="display: flex;">
                            <div class="truncate-text" class="subtitle-label" *ngIf="subtitleItem.label" style="width: 100px;">
                                {{subtitleItem.label}}
                            </div>
                            <span class="truncate-text"
                                *ngIf="subtitleItem.attribute !== 'email' && subtitleItem.attribute !== 'phone' && getSubtitleItemText(parentItem,subtitleItem) !== undefined && getSubtitleItemText(parentItem, subtitleItem) !== ''">
                                {{ getSubtitleItemText(parentItem, subtitleItem) }}
                            </span>
                            <a *ngIf="subtitleItem.attribute === 'email' && getSubtitleItemText(parentItem, subtitleItem) !== undefined && getSubtitleItemText(parentItem, subtitleItem) !== ''"
                                [href]="href" class="truncate-text">
                                <span>{{ getSubtitleItemText(parentItem, subtitleItem) }}</span></a>

                            <a *ngIf="subtitleItem.attribute === 'phone' && getSubtitleItemText(parentItem, subtitleItem) !== undefined && getSubtitleItemText(parentItem, subtitleItem) !== ''"
                            [href]="hrefPhone" class="truncate-text">
                            <span>{{ getSubtitleItemText(parentItem, subtitleItem) }}</span></a>
                        </div>
                    </ng-template>
                </div>
                <div class="details" style="width: calc(100% - 360px);" *ngIf="headerInfo.parent.detailsFields">
                    <p>
                        <ng-template ngFor let-detailsField [ngForOf]="headerInfo.parent.detailsFields">
                            <span *ngIf="item[detailsField]" class="truncate-text" style="display:block;">
                                {{item[detailsField]}}
                            </span>
                        </ng-template>
                    </p>
                </div>
            </div>
        </div>

        <div class="multiple-columns" *ngIf="headerInfo.parent.subtitleItems &&  !headerInfo.parent.detailsFields"
            [ngStyle]="{'border-top': headerInfo.mode === HeaderMode.List && headerInfo.hasParent === false?'0px':'1px solid lightgrey'}">
            <ng-template ngFor let-subtitleItem [ngForOf]="filterItemsOfType(headerInfo.parent.subtitleItems)"
                index="i">

                <div  class="subtitleItem" style="display: flex" *ngIf="subtitleItem.attribute || subtitleItem.label || subtitleItem.textCallback; else tes">
                    <div class="subtitle-label {{subtitleItem.classLabel}}" *ngIf="subtitleItem.label">
                        {{subtitleItem.label}} :
                    </div>
                    <div [innerHtml]="getSubtitleItemText(parentItem, subtitleItem)" class="subtitle-item {{subtitleItem.classItem}}" [ngStyle]="{'width': subtitleItem.label ? '160px' : '260px','visibility': subtitleItem.displayIfEmpty && getSubtitleItemText(parentItem, subtitleItem) === 'hidden' ? 'hidden':'initial',
                    'text-align': subtitleItem.rightAlign ? 'right' : 'left', 'padding-right': subtitleItem.rightAlign ? '10px' : '0px'}"
                        *ngIf="subtitleItem.attribute !== 'email' && (subtitleItem.displayIfEmpty || ( getSubtitleItemText(parentItem, subtitleItem) !== undefined && getSubtitleItemText(parentItem, subtitleItem) !== ''))">
                    </div>
                    <div class="subtitle-item" [ngStyle]="{'width': subtitleItem.label ? '160px' : '260px','visibility': subtitleItem.displayIfEmpty && getSubtitleItemText(parentItem, subtitleItem) === 'hidden' ? 'hidden':'initial'}"
                        *ngIf="subtitleItem.attribute === 'email' && (subtitleItem.displayIfEmpty || ( getSubtitleItemText(parentItem, subtitleItem) !== undefined && getSubtitleItemText(parentItem, subtitleItem) !== ''))">
                        {{ getSubtitleItemText(parentItem, subtitleItem) }}
                    </div>
                </div>

                <ng-template #tes>
                    <div class="subtitleItem" style="display: flex">
                        <div class="subtitle-label" style="height: 22px;">
                        </div>
                        <div class="subtitle-item">
                        </div>
                    </div>
                </ng-template>

            </ng-template>

            <div class="commentColumn" *ngIf="headerInfo.parent.commentColumn">
                <div class="comment-label" *ngIf="headerInfo.parent.commentColumn.label">
                    {{headerInfo.parent.commentColumn.label}} :
                </div>
                <div class="comment-item" *ngIf="headerInfo.parent.commentColumn.attribute">
                    {{ getSubtitleItemText(parentItem, headerInfo.parent.commentColumn) }}
                </div>
            </div>
        </div>

    </div>
</div>
