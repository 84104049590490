<dx-popup [(width)]="width" [wrapperAttr]="{ class: 'next-dx-popup right-slider ' + popupClass  }"
    (widthChange)="widthChangeBinder($event)" [(height)]="height" (heightChange)="heightChangeBinder($event)"
    [fullScreen]="fullScreen" minHeight="300" minWidth="600" (fullScreenChange)="fullScreenChangeBinder($event)"
    [showTitle]="showTitle" [title]="title" (showTitleChange)="showTitleChangeBinder($event)"
    (titleChange)="titleChangeBinder($event)" [dragEnabled]="dragEnabled"
    (dragEnabledChange)="dragEnabledChangeBinder($event)" [closeOnOutsideClick]="closeOnOutsideClick"
    (closeOnOutsideClickChange)="closeOnOutsideClickChangeBinder($event)" [(visible)]="visible"
    [resizeEnabled]="resizeEnabled" (onHidden)="onHiddenBinder($event)"
    (resizeEnabledChange)="resizeEnabledChangeBinder($event)"
    (onHiding)="onHidingBinder($event)" (onResizeEnd)="onResizeEndBinder($event)" titleTemplate="title"
    [shading]="shading" shadingColor="rgba(48, 62, 105, 0.4)" (onShown)="onShowingBinder($event)" (onContentReady)="onContentReadyBinder($event)" >

    <dxo-position my="right" at="right" *ngIf="dropDownAdd">
    </dxo-position>
    <dxo-animation [hide]="{ type: 'slideOut', direction: 'right', duration: 250
        }" [show]="{type: 'slideIn', direction: 'right', duration: 250 }" *ngIf="dropDownAdd"></dxo-animation>
    <div *dxTemplate="let data of 'title'">
        <div class="popupHeader">

            <div class="title">
                <span *ngIf="(!popupPrintType && isPopupForEtiquette) || popupPrintType === 'popupMail'" class="icon popupMail">
                    <ngc-icon size="32" icon="{{popupPrintType === 'popupMail'? 'mail' : 'label'}}"></ngc-icon>
                </span>
                <span *ngIf="popupPrintType === 'popupFavoris'" class="icon">
                    <ngc-icon size="32" icon="{{!isPopupForEtiquette ? 'printer' : 'label'}}"></ngc-icon>
                </span>
                <span *ngIf="popupPrintType === 'popupNotFavoris'" class="icon">
                    <ngc-icon size="32" icon="document"></ngc-icon>
                </span>
                <span class="title popupNotFavoris" *ngIf="popupPrintType && popupPrintType === 'popupNotFavoris'"
                    class="title-print-popup"> {{ (!isPopupForEtiquette ? 'popup.print.title.availableModelsForPrinting'
                    : 'popup.print.title.availableModelsForLabel') | translate }}
                </span>
                <span class="title popupFavoris" *ngIf="popupPrintType && popupPrintType === 'popupFavoris'"
                    class="title-print-popup"> {{ (!isPopupForEtiquette ? 'popup.print.title.favoriteListForPrinting' :
                    'popup.print.title.favoriteListForLabel') | translate }}</span>
                <span class="title popupMail" *ngIf="!popupPrintType || popupPrintType === 'popupMail'">{{ title }}</span>

            </div>
            <div class="search-and-column-params" *ngIf="isPopupGrid">
                <app-extra-button-field #extraButton [buttonsBefore]="datagridSearch.buttonsBefore" [clearButton]="true"
                    [buttonsAfter]="datagridSearch.buttonsAfter" [value]="searchingWord"
                    (ValueChanged)="onSearchingWordValueChanged($event)" class="search-bar"
                    [slidePanelOptions]="slidePanelOptions" (deleteAdvancedFilter)="onDeleteAdvancedFilter($event)"
                    (validateSuccess)="onValidateSuccess($event)" (slidePanelClose)="onSlidePanelClose($event)">

                    <div *templateKey="'filterTemplateContent'; let d">
                        <div id="content">
                            <dx-scroll-view [height]="slidePanelOptions.css.height">
                                <form class="generic-form ngc-generic-form" autocomplete="off" style="margin-top: 13px">
                                    <div class="form-content">
                                        <div class="pl-0 pr-0 editable-content">
                                            <dx-validation-group #validationGroupe class="validationGroup">
                                                <div *ngFor="let advancedFilterConfig of advancedFilterConfigArray">
                                                    <div *ngIf="advancedFilterConfig.separator">
                                                        <div class="filter-separator"></div>
                                                    </div>
                                                    <div *ngIf="advancedFilterConfig.group">
                                                        <p class="filter-title-group">{{advancedFilterConfig.group}}</p>
                                                    </div>
                                                    <div class="filter-fields">
                                                        <div *ngFor="let advancedFilter of advancedFilterConfig.fields"
                                                            [ngStyle]="{'width': advancedFilter.field.width}">
                                                            <app-dx-field [field]="advancedFilter.field"
                                                                [item]="advancedFilter" [editable]="true"
                                                                [itemCopy]="advancedFilterCopy">
                                                            </app-dx-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dx-validation-group>
                                        </div>
                                    </div>
                                </form>
                            </dx-scroll-view>
                        </div>
                    </div>

                </app-extra-button-field>
                <div class="actionButtons">
                <dx-button class="responsive-text-button add-button" type="default" stylingMode="contained">
                    <ngc-icon size="20" icon="add"></ngc-icon>
                </dx-button>
                <dx-drop-down-button id="dropdownMore" icon="more" [items]="dropDownGridItems" displayExpr="name"
                    type="default" keyExpr="id" [dropDownOptions]="{ width: 230
                    }" [showArrowIcon]="false" class="dropdownDataGrid"
                    (onItemClick)="onDropDownGridItemsClicked($event)" itemTemplate="dropDownButtonTemplate"
                    class="drop-down-column-parms">
                    <div *dxTemplate="let data of
                        'dropDownButtonTemplate'">
                        <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                        {{data.name}}
                    </div>
                </dx-drop-down-button>
</div>
            </div>
            <div class="buttons">
                <span *ngIf="isPopupPrintVisible && !isPopupPrintTwoVisible" class="add-button-print-popup"
                    (click)="clickMore.next(true)">
                    <ngc-icon size="32" icon="default"></ngc-icon>
                </span>
                <span *ngIf="isPopupPrintVisible || isPopupPrintTwoVisible" class="popup-print-close-btn"
                    (click)="closePopup()">
                    <ngc-icon style="color:white" (click)="onCloseClick()" size="26" class="close-button" icon="times">
                    </ngc-icon>

                </span>


            </div>
            <div *ngIf="showCloseButton">
                <ngc-icon class="mail-close-button" (click)="onCloseClick()"
                    *ngIf="!popupPrintType || popupPrintType === 'popupMail'" size="24" icon="times"></ngc-icon>
            </div>
        </div>
    </div>
    <div *dxTemplate="let data of 'content'" class="next-dx-popup" [id]="'popup'
        + identifier">
        <dx-scroll-view width="100%" height="100%">
            <ng-content select="[content]"></ng-content>
        </dx-scroll-view>
    </div>

</dx-popup>
