import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import * as service from '@app/core/services';
import { AuthGuard, CanLoadGuard } from '@app/core/guard';
import { AuthInterceptorService } from '@app/core/interceptor';
import { LoaderInterceptorService } from '@app/core/interceptor';

import { CachingInterceptor } from './interceptor/caching-interceptor.service';
import { BaseReportService } from './services/report/base-report.service';
import { ActiveUserService } from './services/superAdmin/active-user.service';
import { ErrorInterceptor } from './interceptor/error-interceptor.service';
import { PersoReportService } from './services/report/perso-report.service';

@NgModule({
    imports: [
    ],
    declarations: [],
    providers: [
        AuthGuard,
        CanLoadGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
    ],
})
export class CoreModule { }
