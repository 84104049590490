import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import DxThemes from 'devextreme/ui/themes';
import { Country, User } from './shared/model';
import { AuthService } from '@app/core/services/auth/auth.service';
import { AppContextService } from './core/services/app-context.service';
import { variablesKeys, notifyErrorDelay, languages } from './globalsParameter';
import * as Globalize from 'globalize';
import { AppConfiguration } from '@app/core/services/application/app-configuration.service';
import { Subscription } from 'rxjs';
import { StorageService } from './core/services/storage.service';

// DexExpress import
declare function require(url: string);

import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';

// Load localized messages (English included by default)
const frMessages = require('devextreme/localization/messages/fr.json');
const frCaGregorian = require('cldr-data/main/fr/ca-gregorian.json');
const frNumbers = require('cldr-data/main/fr/numbers.json');
const frCurrencies = require('cldr-data/main/fr/currencies.json');
const likelySubtags = require('cldr-data/supplemental/likelySubtags.json');
const timeData = require('cldr-data/supplemental/timeData.json');
const weekData = require('cldr-data/supplemental/weekData.json');
const currencyData = require('cldr-data/supplemental/currencyData.json');
const numberingSystems = require('cldr-data/supplemental/numberingSystems.json');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = '';
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    private subscriptions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        public translate: TranslateService,
        private appContext: AppContextService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appConfiguration: AppConfiguration,
        public localStorage: StorageService,
    ) {
        this.initLocalisation();
        this.initApplication();
    }

    ngOnInit(): void {
        const appTitle = this.titleService.getTitle();
        this.subscriptions.push(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(
                    () => {
                        let child = this.activatedRoute.firstChild;
                        while (child.firstChild) {
                            child = child.firstChild;
                        }
                        if (child.snapshot.data['title']) {
                            return child.snapshot.data['title'];
                        }
                        return appTitle;
                    }
                )
            ).subscribe(
                (ttl: string) => {
                    this.title =
                        ((this.router.url.indexOf('idhortiflow.com') > -1) ||
                            (this.router.url.indexOf('idviniflow.com') == -1 && this.appConfiguration.defaultMode == "horti")) ? 'iDHortiFlow' : 'iDViniFlow';
                    this.favIcon.href = this.title == 'iDHortiFlow' ? this.favIcon.href = './assets/icons/Sigle_iDHortiFlow_RVB.png' : './assets/icons/Sigle_iDViniFlow_RVB.png'
                    if (ttl) {
                        this.titleService.setTitle(this.title + ' - ' + ttl);
                    }
                    else {
                        this.titleService.setTitle(this.title);
                    }
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }

    // <!-- localisation -->
    // appel fait apartir du app.component.ts a cause bug detecter cote
    public initLocalisation(): void {
        this.translate.addLangs(languages);
        let locale = this.localStorage.getItem(variablesKeys.LOCALE);
        if (
            !locale ||
            languages.indexOf(locale) === -1
        ) {
            this.localStorage.setItem(variablesKeys.LOCALE, 'fr');
            locale = 'fr';
        }
        this.translate.setDefaultLang('fr');
        this.translate.use(locale);
        this.initGlobalize();
        Globalize.locale(locale);
    }

    private initGlobalize() {
        Globalize.load(
            frCaGregorian,
            frNumbers,
            frCurrencies,

            likelySubtags,
            timeData,
            weekData,
            currencyData,
            numberingSystems
        );
        Globalize.loadMessages(frMessages);
    }
    // <!-- /localisation -->

    // get first use  constants from server and persist them
    private initApplication() {
        // check global Vars (les reinitialise si manquant)
        if (!this.appContext.hasKey(variablesKeys.COUNTRY_LIST)) {
            this.subscriptions.push(
                this.appContext.getCountries().subscribe(
                    (countries: Country[]) => {
                        this.appContext.setPersist(variablesKeys.COUNTRY_LIST, countries);
                    }
                )
            );
        }
        this.subscriptions.push(
            this.authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    if (user && user.themeDX && DxThemes.current() !== user.themeDX) {
                        DxThemes.current(user.themeDX);
                    }
                },
                (error) => notify(error.error, 'error', notifyErrorDelay)
            )
        );
    }
}
