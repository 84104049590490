import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, HostListener, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { SlidePanelModel } from './slide-panel-model';
import { FormFieldObject, IDaoServiceOptions, EditorOptions } from '../dx-form/models';
import { IDaoBaseService, IPopupComponent } from '@app/shared/interface';
import { DxListComponent } from '../dx-list/dx-list.component';
import { KeyedTemplateDirective } from '@app/shared/directive';
import { DxFormSimplifiedComponent } from '../dx-form-simplified/dx-form-simplified.component';
import { Subscription } from 'rxjs';
import { DataGridInput, SlidePanelChildOptions } from '../genericComponentOption';
import { TranslateService } from '@ngx-translate/core';
import { DataGridComponent } from '../data-grid/data-grid.component';


@Component({
    selector: 'app-slide-panel',
    templateUrl: './slide-panel.component.html',
    styleUrls: ['./slide-panel.component.scss']
})
export class SlidePanelComponent implements OnInit, IPopupComponent, OnChanges {

    @ViewChild(DxFormSimplifiedComponent, { static: false }) dxFormSimplifiedComponent: DxFormSimplifiedComponent;
    @ViewChild(DxListComponent) childDxList: DxListComponent;
    @ViewChild('dxSlidePanelDataGrid', { static: false }) slidePanelDataGrid: DataGridComponent;

    @Input() slidePanelOptions: SlidePanelModel = new SlidePanelModel();
    @Input() slidePanelChildOptions: SlidePanelChildOptions = new SlidePanelChildOptions();
    @Input() service?: IDaoBaseService;
    @Input() visible: boolean;

    @Output() validateSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() slidePanelClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() beforeSaveSlidePanel: EventEmitter<any> = new EventEmitter<any>();
    @Output() confimDialogYesSlidePanel: EventEmitter<any> = new EventEmitter<any>();
    @Output() confimDialogNoSlidePanel: EventEmitter<any> = new EventEmitter<any>();

    isCanDeactivate = true;
    public formCanDeactivate = true;
    public templateDirectiveMap: Map<string, any>;
    private temporarySelectedItem: any;
    dataGridInput: DataGridInput = new DataGridInput();
    searchingWord: string;
    datagridSearch: any;

    dropDownGridItems = [
        { id: 1, value: 1, name: this.translateService.instant('data-grid.hint-columnchooser'), icon: 'column' },
        { id: 2, value: 2, name: this.translateService.instant('data-grid.hint-filterrow'), icon: 'filterInDropDown' },
    ];

    constructor(
        public translateService: TranslateService
    ) {

    }

    ngOnInit() {
        this.datagridSearch = {
            buttonsBefore:
                [
                    {
                        name: 'loop',
                        options: {
                            disabled: true,
                            stylingMode: 'text',
                            icon: 'search',
                            template: 'templateButtonBefore'
                        }
                    }
                ],
                buttonsAfter: [{
                    name: 'clearSearch',
                    options: {
                        focusStateEnabled: false,
                        hoverStateEnabled: false,
                        activeStateEnabled: false,
                        stylingMode: 'text',
                        icon: 'clear',
                        template: 'templateButtonAfter',
                        visible: false,
                        onClick: () => {
                            this.searchingWord = "";
                        }
                    }
                }]
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.slidePanelOptions?.dataGridOptions) {
            this.dataGridInput.showHeader = this.slidePanelOptions.dataGridOptions.showHeader;

            this.dataGridInput.slidePanelGirdHeaderIcon = this.slidePanelOptions.dataGridOptions.icon;

            this.dataGridInput.dataGridFiltersParam = this.slidePanelOptions.dataGridOptions.dataGridFiltersParam;

            this.dataGridInput.showActions = this.slidePanelOptions.dataGridOptions.showActions;
        }
    }

    @HostListener('window:click', ['$event'])
    onClick(event) {
        if (this.slidePanelOptions.isGrid || this.slidePanelOptions.isList) {
            const clickIsOnSlidePanel = (event.path as Array<any>).some((path) => path.className === 'slide-panel');
            const clickIsOnNgcIcon = (event.path as Array<any>).some((path) => path.className === 'right-header-toolbar' || path.className === 'dx-template-wrapper dx-item-content dx-list-item-content' || path.className === 'arrowMvts' || path.className === 'dx-popup-content');
            const clickIsOnScrollBarSlidePanel = (event.target.lastChild?.previousElementSibling?.className == "slide-panel");

            if (!clickIsOnSlidePanel && this.visible && !clickIsOnNgcIcon && !clickIsOnScrollBarSlidePanel) {
                this.visible = false;
            }

        }
    }

    closeOnOutsideClick(event): void  {
        if (!event) {
            this.slidePanelClose.emit(event);
        }

    }

    onDataChanged(event: boolean): void {
        this.isCanDeactivate = event;
    }

    onValidateSuccess(val: any): void {
        this.validateSuccess.emit(val);
    }

    onFormDataChange(val): void  {
        this.slidePanelOptions.formSimplifiedOptions.formDataChange.emit(val);
    }

    cancel(): void  {
        this.slidePanelClose.emit();
    }

    save() : void {
        if (this.slidePanelOptions.isForm) {
            this.dxFormSimplifiedComponent.CheckBeforeSave(null, 'validate');
        } else {
            this.validateSuccess.emit(this.temporarySelectedItem);
        }
    }

    create(): void  {
        if (this.childDxList) {
            this.childDxList.create();
        }
        else if (this.slidePanelDataGrid) {
            this.slidePanelDataGrid.navigateToCreatePage()
        }
    }

    getTemplate(tplName: string): void {
        return this.templateDirectiveMap && this.templateDirectiveMap.has(tplName) ? this.templateDirectiveMap.get(tplName).templateDirective.template : null;
    }

    get slidePanelTemplateContent(): void {
        if (this.slidePanelOptions.templateDirectiveMap && this.slidePanelOptions.templateDirectiveMap.key) {
            return this.slidePanelOptions.templateDirectiveMap.templateDirective.template;
        }
    }

    /**
     * this function is called from the template directive to add a template to this component
     * @param key identifiant du template
     * @param templateDirective directive contenant le template
     */
    public registerTemplate = function (key: string, templateDirective: KeyedTemplateDirective): void {

        if (!this.templateDirectiveMap) {
            this.templateDirectiveMap = new Map<string, any>();
        }

        const keyedTemplate = {
            key: key,
            templateDirective,
        };
        this.templateDirectiveMap.set(key, keyedTemplate);
    };

    onRowDoubleClick(event): void  {
        event['name'] = this.slidePanelOptions.name;
        this.validateSuccess.emit(event);
    }

    onValueSelected(event): void  {
        event = event[0];
        event['name'] = this.slidePanelOptions.name;
        event['click'] = 'singleClick';
        this.temporarySelectedItem = event;
    }

    onSearchingWordValueChanged(event: any): void  {
        if (this.slidePanelDataGrid) {
            if (event === null || event === undefined || event.value == undefined || event.value == '') {
                this.datagridSearch.buttonsAfter.forEach(
                    btn => {
                        if (btn.name == "clearSearch") {
                            btn.options.visible = false;
                        }
                    }
                );
            }
            else {
                this.datagridSearch.buttonsAfter.forEach(
                    btn => {
                        if (btn.name == "clearSearch") {
                            btn.options.visible = true;
                        }
                    }
                );
            }
            this.searchingWord = event.value;
            this.slidePanelDataGrid.onSearchingWordValueChanged(event);
        }
    }

    onDropDownGridItemsClicked(event: any): void  {
        if (event.itemData.id === 1) {
            this.slidePanelDataGrid.getColumnChooser();
        } else if (event.itemData.id === 2) {
            this.slidePanelDataGrid.headerSearch = !this.slidePanelDataGrid.headerSearch;
            this.slidePanelDataGrid.manageHeightGrid();
        }
    }

    OnBeforeSave(event): void  {
        this.beforeSaveSlidePanel.emit(event);
    }

    OnConfirmDialogYes(event): void  {
        this.confimDialogYesSlidePanel.emit(event);
    }

    OnConfirmDialogNo(event): void  {
        this.confimDialogNoSlidePanel.emit(event);
    }
}
