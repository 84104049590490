import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Society } from '@app/shared/model';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { ApplicationMode } from '@app/shared/model/base-entity1';
import { AppConfiguration } from './application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class SocietyService extends DaoGridStateBaseService implements IDaoBaseService {

    public _list: Observable<Society[]> = null;
    private _gridState: Observable<JSON> = null;
    currentItem: BehaviorSubject<Society> = new BehaviorSubject<Society>(null);
    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    get(mode: ApplicationMode): Observable<Society> {
        const url = this.appConfiguration.UrlsConfig.wsSociety;
        return this.http.get<Society>(url)
            .pipe(
                shareReplay(1)
            );
    }
    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(mode: ApplicationMode): Observable<Society[]> {
        let url = this.appConfiguration.UrlsConfig.wsSociety;
        if (mode !== null){
            url= url +mode;

        }
        return this.http.get<any[]>(url)
            .pipe(
                shareReplay(1)
            );
    }

    /**
    * Create an Society
    * @param payload request body
    */
    post(payload: Society): Observable<NGCResults<Society>> {
        return throwError('Methode non implémenter !');
    }

    /**
    * Update an society
    * @param id society id
    * @param payload society data
    */
    put(id: number, payload: Society): Observable<NGCResults<Society>> {
        const url = this.appConfiguration.UrlsConfig.wsSociety + id;
        return this.http.put<NGCResults<Society>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache() {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(): string {
        return 'Society';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsSociety + id;
        return this.http.delete(url);
    }

    // TODO enlevé detail
    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
        this.router.navigate(['/admin/society-detail/', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
        this.router.navigate(['/admin/society-detail/create']);
    }

    navigateToList(): void {
        throw new Error('not implemented');
    }
}
