import { BasicRate, Article } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export enum AcceptPit { True, False, Not_Defined }
export class ArtCost extends BaseEntity2 {
    article: Article;
    basicRate: BasicRate;
    beginDate: Date;
    endDate: Date;
    valueHT: number;
    valueTTC: number;
    calculable: boolean;
    valueDutyFree: number;
    exciseIncluded: boolean;
    exciseDuties: number;
    socialSecurityStampIncluded: boolean;
    socialSecurityStampDuties: number;
    drinkTaxIncluded: boolean;
    sugarDuties: number;
    sweetenerDuties: number;
    energizingDuties: number;
    softDrinkDuties: number;

    constructor() {
        super();
        this.article = null;
        this.basicRate = null;
        this.beginDate = new Date();
        this.endDate = new Date();
        this.valueHT = 0;
        this.valueTTC = 0;
        this.valueDutyFree = 0;
        this.exciseIncluded = false;
        this.exciseDuties = 0;
        this.socialSecurityStampIncluded = false;
        this.socialSecurityStampDuties = 0;
        this.drinkTaxIncluded = false;
        this.sugarDuties = 0;
        this.sweetenerDuties = 0;
        this.energizingDuties = 0;
        this.softDrinkDuties = 0;
    }

    validator(): ValidationRulesByFieldName {
        return {
            basicRate: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
            ],
            beginDate: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },

            ],
        };
    }

}
