import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'ngc-switch',
    templateUrl: './ngc-switch.component.html',
    styleUrls: ['./ngc-switch.component.scss']
})
export class NgcSwitchComponent implements OnInit {

    @Input() disabled = false;
    @Input() value = true;
    @Input() dataGridView = false;
    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    onValueChanged() {
        this.value = !this.value;
        this.valueChanged.emit(this.value);
    }

}
