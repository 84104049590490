import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import DataGrid from 'devextreme/ui/data_grid';
import Form from 'devextreme/ui/form';
import { DataGridComponent } from './devextreme/data-grid/data-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxFormComponent } from './devextreme/dx-form/dx-form.component';
import { BreadcrumbComponent } from '../component/breadcrumb/breadcrumb.component';
import { NextDxPopupComponent } from './devextreme/dx-popup/next-dx-popup.component';
import { DxFieldComponent } from './devextreme/dx-field/dx-field.component';
import { NgcSwitchComponent } from './devextreme/ngc-switch/ngc-switch.component';
import { NgcCheckboxComponent } from './devextreme/ngc-checkbox/ngc-checkbox.component';
import { NgcIconComponent } from './devextreme/ngc-icon/ngc-icon.component';
import { NgcNotifyComponent } from './devextreme/ngc-notify/ngc-notify.component';
import { ExtraButtonFieldComponent } from './devextreme/extra-button-field/extra-button-field.component';
import { DxPopupPrintComponent } from './devextreme/dx-popup-print/dx-popup-print.component';
import { NgcBarcodeComponent } from '../component/ngc-barcode/ngc-barcode.component';

import { KeyedTemplateDirective } from '@app/shared/directive/keyed-template-directive/keyed-template.directive';
import { defaultDataGridOptions } from './devextreme/defaultDevExtremeConfig';
import { SecureSrcPipe } from '../pipes/secure-src.pipe';
import { DxDataGridPickerComponent } from './devextreme/dx-data-grid-picker/dx-data-grid-picker.component';
import { DataGridFieldComponent } from './devextreme/data-grid-field/data-grid-field.component';

import { ContextualHelpComponent } from './devextreme/dx-form/contextual-help/contextual-help.component';
import { PopupIdentifierPipe } from '../pipes/popupIdentifier.pipe';
import { FileUploadComponent } from '../component/file-upload/file-upload.component';
import { LeftHeaderBlocComponent } from '../component/left-header-bloc/left-header-bloc.component';
import { AppImageComponent } from '../component/app-image/app-image.component';
import { DxFormHeaderComponent } from './devextreme/dx-form-header/dx-form-header.component';
import { SlidePanelComponent } from './devextreme/slide-panel/slide-panel.component';
import { DxListComponent } from './devextreme/dx-list/dx-list.component';
import { DxFormSimplifiedComponent } from './devextreme/dx-form-simplified/dx-form-simplified.component';
import { NgcGridStateComponent } from './devextreme/ngc-grid-state/ngc-grid-state.component'; import { NgcAccordionComponent } from './devextreme/ngc-accordion/ngc-accordion.component'; import { DataGridFooterComponent } from '../component/data-grid-footer/data-grid-footer.component';
import { cloneObject } from '@app/utils/cloningObject';
import { DxTileViewModule } from 'devextreme-angular/ui/tile-view';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxCalendarModule } from 'devextreme-angular/ui/calendar';
import { DxColorBoxModule } from 'devextreme-angular/ui/color-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxResponsiveBoxModule } from 'devextreme-angular/ui/responsive-box';
import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxToastModule } from 'devextreme-angular/ui/toast';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DxButtonGroupModule, DxTabsModule } from 'devextreme-angular';
import { NgcToogleComponent } from './devextreme/ngc-toogle/ngc-toogle.component';

const communModules = [
    CommonModule,
    DxTileViewModule,
    TranslateModule,
    DxDataGridModule,
    DxPopupModule,
    DxCalendarModule,
    DxColorBoxModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxListModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxFormModule,
    DxCheckBoxModule,
    DxTextAreaModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxMenuModule,
    DxDrawerModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxResponsiveBoxModule,
    DxRangeSelectorModule,
    DxHtmlEditorModule,
    DxDropDownButtonModule,
    DxPopoverModule,
    DxToastModule,
    DxAccordionModule,
    DxButtonGroupModule,
    DxTabsModule
];

@NgModule({
    imports: [
        communModules,
        InfiniteScrollModule,
        DxTagBoxModule,
        DragDropModule
    ],
    exports: [
        communModules,
        BreadcrumbComponent,
        DxFormComponent,
        DxFormSimplifiedComponent,
        DxListComponent,
        DxFormHeaderComponent,
        DataGridComponent,
        NextDxPopupComponent,
        DxFieldComponent,
        NgcSwitchComponent,
        NgcCheckboxComponent,
        NgcIconComponent,
        NgcNotifyComponent,
        ExtraButtonFieldComponent,
        FileUploadComponent,
        DxDataGridPickerComponent,
        DataGridFieldComponent,
        SecureSrcPipe,
        PopupIdentifierPipe,
        DxScrollViewModule,
        KeyedTemplateDirective,
        ContextualHelpComponent,
        LeftHeaderBlocComponent,
        AppImageComponent,
        SlidePanelComponent,
        NgcGridStateComponent,
        DataGridFooterComponent,
        NgcAccordionComponent,
        DxPopupPrintComponent,
        NgcBarcodeComponent,
        NgcToogleComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    declarations: [
        ContextualHelpComponent,
        DataGridComponent,
        DxFormComponent,
        DxFormSimplifiedComponent,
        DxListComponent,
        DxFormHeaderComponent,
        BreadcrumbComponent,
        NextDxPopupComponent,
        DxFieldComponent,
        NgcSwitchComponent,
        NgcCheckboxComponent,
        NgcIconComponent,
        NgcNotifyComponent,
        ExtraButtonFieldComponent,
        FileUploadComponent,
        DxDataGridPickerComponent,
        DataGridFieldComponent,
        KeyedTemplateDirective,
        PopupIdentifierPipe,
        SecureSrcPipe,
        AppImageComponent,
        LeftHeaderBlocComponent,
        SlidePanelComponent,
        NgcGridStateComponent,
        NgcAccordionComponent,
        DataGridFooterComponent,
        DxPopupPrintComponent,
        NgcBarcodeComponent,
        NgcToogleComponent
    ]
})
export class DevextremeModule {
    constructor() {
        const dataGrid = DataGrid;
        dataGrid.defaultOptions({
            device: { deviceType: 'desktop' },
            options: cloneObject(defaultDataGridOptions)
        });

        Form.defaultOptions({
            options: {
            }
        });
    }
}
