import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';

export class Widget   {

  code: string;
  description: string;
  refreshRate: number;

  constructor() {
    this.code = '';
    this.description = '';
    this.refreshRate = 0;
  }
}
