import { ManagedEditorType } from '../modules/devextreme/dx-form/models/form-field';
import { dataType } from '../modules/devextreme/data-grid/models/dxDataGridColumn';

export class Tools {

    public static getType(object): dataType {
        let result: dataType;

        // typeof => '"string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
        switch (typeof object) {
            case 'number': result = 'number'; break;
            case 'string': result = 'string'; break;
            case 'boolean': result = 'boolean'; break;
            case 'object':
                if (object instanceof Date) {
                    result = 'date';
                } else if (object && object.constructor) {
                    result = 'object';
                }
                break;
            default: result = 'string'; break;
        }

        return result;
    }

    public static getDxFormInputType(object: any): ManagedEditorType  {
        const tableOfDxType = {
            undefined: 'dxTextBox',
            function: 'dxTextBox',
            number: 'dxNumberBox',
            symbol: 'dxTextBox',
            string: 'dxTextBox',
            boolean: 'dxSwitch',
            bigint: 'dxNumberBox',
            date: 'dxDateBox',
            array: 'dxTextBox'
        };
        return tableOfDxType[this.getType(object)];
    }

    public static getDateSerializationFormat(type: string): string {
        if (typeof type === 'undefined') { throw new Error('il faut definir le type du champ date'); }
        switch (type) {
            case 'date': return 'yyyy-MM-dd';
            case 'time': return 'HH:mm:ss';
            case 'datetime': return 'yyyy-MM-ddTHH:mm:ss';
            default: return 'yyyy-MM-ddTHH:mm:ss';
        }
    }
}
