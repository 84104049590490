import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export class PackagingFamily extends BaseEntity2 {
    code: string;
    designation: string;
    visible: boolean;

    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.visible = true;
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 6, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

    initValues(): Partial<PackagingFamily> {
        return {
            visible: true
        };
    }
}
