import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import dxDataGrid, { dxDataGridRowObject } from 'devextreme/ui/data_grid';
import { NextDxFormOptions } from './dx-form/models';

export class DataGridInput {
    // Utiliser si on veux afficher le dataGrid à l'intérieur d'une page et non plus comme une page entiére
    // l'affichage du tous le bloc header
    showHeader = true;
    // filtrage specifique pour le grid
    dataGridFiltersParam?: DataGridFiltersParam;
    /**l'affichage du bouton create au niveau du header bloc */
    showHeaderCreateButton = true;
    /**l'affichage du bouton de suppression de l'action de grid */
    allowDeleteItemActions = false;
    /**Affichage du bouton update dans le meatball */
    allowModifyItemActions: boolean | ((options: { component?: dxDataGrid, row?: dxDataGridRowObject }) => boolean) = false;

    showButtonsEditing = true;

    /**l'affichage du data grid header icon en cas du slidePanel*/
    slidePanelGirdHeaderIcon = 'map';
    /**l'affichage des actions du dataGrid*/
    showActions = true;
    /**add all editable field for grid editable*/
    editableFieldOnlyThis: string[] = [];
    /**appeler au moment de applychange(envoyer les données) pour fermé popup ou pas*/
    closePopupCallback: boolean | ((rowsData: any) => any) = true;
}

export class DataGridOutput {
}



export class SlidePanelChildOptions {
    nextDxFormOptions?: NextDxFormOptions = new NextDxFormOptions();
}
