import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export class Location extends BaseEntity2 {
    code: string;
    designation: string;
    noOrder: number;
    storagePotential: number;
    visible: boolean;

    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.noOrder = 0;
        this.storagePotential = 0;
        this.visible = true;
    }

    validator(): ValidationRulesByFieldName {
        return {
            code : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'pattern', ignoreEmptyValue: false, message: 'FormErrorMessages.FormatCode', useTranslator: false, pattern: '^[A-Z0-9\\-_]*$' },
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: false }
            ],
            designation: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: false }
            ]
        };
    }

    initValues(): Partial<Location> {
        return {
            visible: true
        };
    }
}
