import { BaseEntity2 } from '../base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { User } from '../user';
import { ConfBlock } from './conf-block';
import { ScreenIdentifier } from './screen-type';
import { ApplicationMode } from '../base-entity1';

export const isDefaultDataSource = [
    {'text': 'Personalisé', 'value': 0},
    {'text': 'Par défaut', 'value': 1},
];
export class ConfScreen extends BaseEntity2 {

    public screen: ScreenIdentifier; // max length 10, not null
    public designation: string; // max length 50, not null
    public user?: User;
    public blocks?: ConfBlock[];
    public applicationMode: ApplicationMode;
    public isDefault: boolean;

    constructor() {
        super();
        this.screen = ScreenIdentifier.CUSTOMER;
        this.designation = '';
        this.user = null;
        this.applicationMode = ApplicationMode.HORTI;
        this.isDefault = true;
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            screen: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', min: 4, max: 10, ignoreEmptyValue: false, message: 'FormErrorMessages.StringRangeLength', useTranslator: true }
            ],
        };
    }
}
