import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, OnDestroy, AfterViewInit, HostListener, SimpleChanges, ViewContainerRef, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';

import { FormField } from '../dx-form/models';
import { NextDxDataGridOptions } from '../data-grid/models';
import { DataGridComponent } from '../data-grid/data-grid.component';
import { NextDxPopupComponent } from '../dx-popup/next-dx-popup.component';
import { DataGridInput } from '../genericComponentOption';
import { FooterInfo } from '@app/shared/component/data-grid-footer/models/footer-info';
import { isDefined } from '@app/utils';
import { SlidePanelModel } from '../slide-panel/slide-panel-model';
import { GenericCriteria } from '@app/shared/model';

@Component({
    selector: 'dx-data-grid-picker',
    templateUrl: './dx-data-grid-picker.component.html',
    styleUrls: ['./dx-data-grid-picker.component.scss']
})
export class DxDataGridPickerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @Input() PopupOnly: boolean;
    @Input() displayExpr: string | string[];
    @Input() valueExpr: string;
    @Input() disabled = false;
    @Input() set value(val: any) { this.selectedItem = val; }
    @Input() set fileName(name: any) { this.selectedName = name; }
    @Input() placeholder: string;
    @Input() showClearButton: boolean;
    @Input() searchEnabled: boolean;
    @Input() isValid: boolean;
    @Input() visible: boolean;
    @Input() set showPopup(value: boolean) { this.popupVisibility = value; }
    @Input() showTitle?: boolean = true;
    @Input() formField?: FormField;
    @Input() dataGridOptions?: any;
    @Input() btnPickerText: string;
    @Input() btnPickerIcon: string;
    @Input() type?: 'slide-panel' | 'popup' = 'popup';
    @Input() dataGridInput: DataGridInput = new DataGridInput();
    @Input() editActionVisible = false;
    @Input() dataGridPickerVisible = false;
    @Input() showFilters?: boolean = true;
    @Input() filterButton?: boolean = false;
    @Input() slidePanelOptions?: SlidePanelModel;
    @Input() advancedFilterConfigArray?: [];
    @Input() advancedFilter?: any;
    @Input() advancedFilterCopy?: any;
    @Input() criteriasList?: GenericCriteria[];

    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowUpdating: EventEmitter<any> = new EventEmitter<any>();
    @Output() clearSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() editButtonClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() validateFilter : EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() deleteAdvancedFilter: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() closeDataGridPicker : EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('dxPopupDataGrid', { static: true }) popupGridComponent: DataGridComponent;
    @ViewChild('dxSlidePanelDataGrid', { static: true }) slidePanelGridComponent: DataGridComponent;
    @ViewChild('dxTextBox', { static: false }) dxTextBoxComponent: DxTextBoxComponent;
    @ViewChild('dataGridPickerPopup', { static: false }) dxPopup: NextDxPopupComponent;

    findButton: any;
    removeButton: any;
    public popupVisibility?: boolean;
    public popupTitle: string;
    public editing: any;
    private selectedNameTemporary: any;
    public selectedItem: any;
    public selectedName = {};
    private temporarySelectedItem: any;
    options: NextDxDataGridOptions = new NextDxDataGridOptions();
    doubleClickSubscription: Subscription;
    focusedField = false;
    footerInfo: FooterInfo;
    applychangeActionClick = false;
    applychangeAction = false;

    constructor(
    ) {
        this.editing = {
            allowDeleting: false,
            mode: 'row',
            useIcons: true,
        };
        this.options.header.buttons = [];
    }

    get dataGridComponent(): DataGridComponent {
        return this.type === 'popup' ? this.popupGridComponent : this.slidePanelGridComponent;
    }

    private buildFooterInfo() {
        this.footerInfo = new FooterInfo();
        this.footerInfo.elementName = this.popupTitle;
        this.footerInfo.pageSize = 10;
    }

    ngOnInit() {
        if (this.showTitle) {
            this.popupTitle = (this.formField) ? this.formField.label.text : this.dataGridOptions.popupTitle;
        }
        this.findButton = {
            template: 'templatefind',
            stylingMode: 'text',
            width: 40,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            onClick: (e) => {
                this.showPopupBtn(e);

            }
        };
        this.removeButton = {
            icon: 'assets/icons/svg/CloseRounded.svg',
            stylingMode: 'text',
            width: 40,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            activeStateEnabled: false,
            onClick: (e) => {
                this.clearTextBox(e);
            }
        };
        this.buildFooterInfo();

    }

    ngAfterViewInit(): void {
        if (this.options.rows.doubleClickEnabled) {
            this.doubleClickSubscription = this.dataGridComponent.dxGrid.onRowDblClick.subscribe(event => {
                this.applychange(event);
            });
        }
        if (this.dataGridPickerVisible) {
            this.popupVisibility = true;
            this.refreshData();
        }
    }

    onVisibleChange(event: boolean) {
        this.popupVisibility = event;
    }

    onPopupResizeEnd(event) {
        this.dataGridComponent.manageHeightGrid();
    }

    ngOnChanges(): void {
        this.options.header.showFilters = this.showFilters;
        if (this.formField) {
            if (this.formField.editorOptions.dataGridOptions) {
                for (const key in this.formField.editorOptions.dataGridOptions) {
                    if (this.formField.editorOptions.dataGridOptions.hasOwnProperty(key)) {
                        this.dataGridComponent[key] = this.formField.editorOptions.dataGridOptions[key];
                        if (key === 'options') {
                            this.options = this.formField.editorOptions.dataGridOptions.options;
                        }
                    }
                }
            }
            // if we have custom validation we need to send fullValue to the callback
            if (this.formField.validationRules) {
                for (let index = 0; index < this.formField.validationRules.length; index++) {
                    if (this.formField.validationRules[index].type === 'custom') {
                        // used to add paramétre to a callback function.
                        if ((this.formField.validationRules[index] as any).validationCallbackFullItem) {
                            (this.formField.validationRules[index] as any).validationCallback = (e) => {
                                return (this.formField.validationRules[index] as any).validationCallbackFullItem(e, this.selectedItem);
                            };
                        }
                    }
                }
            }

        } else if (this.dataGridOptions) {
            for (const key in this.dataGridOptions) {
                if (this.dataGridOptions.hasOwnProperty(key)) {
                    this.dataGridComponent[key] = this.dataGridOptions[key];
                }
            }
            this.refreshData();
        }
    }


    onValueSelected(event) {
        if (this.formField) {
            this.selectedNameTemporary = this.formField.dataField;
        }
        if (this.dataGridComponent.options.header.selectionMultile.enabled) {
            this.temporarySelectedItem = event;
        } else {
            this.temporarySelectedItem = event[0];
        }
    }

    onRowUpdating (event) {
        this.dataGridComponent.selectedRows = this.dataGridComponent.selectedRows.filter(row => row.id != event.key.id);
        this.dataGridComponent.selectedRows.push(event.key);

        this.rowUpdating.emit(event);
    }

    public showPopupBtn(event, searchingWord?: string): void {
        // on supprime le cache à l'ouverture du popup de recherche, car il faut pouvoir voir les
        // nouveaux éléments qui auraient été enregistrés par quelqu'un d'autre
        // if (this.formField && this.formField.service) {
        //     this.formField.service.clearListTablesCache();
        // }
        if (!this.dataGridOptions) {
            if (searchingWord == null || searchingWord == undefined || searchingWord == '') {
                this.refreshData();
            } else {
                setTimeout(() => {
                    this.refreshData();
                }, 1201);
            }
        }
        // calcul de la taille du popup avant de le rendre visible sinon si on le fait de onShowing erreur "Expresseion changed..."
        // et si on le fait dans le onShown on voit le popup se deplacer à l'écran
        if (this.type === 'popup') {
            this.dxPopup.managePosition();
        }
        this.popupVisibility = !this.popupVisibility;
    }

    applychange(ev) {
        const event: any = {};
        event.previousValue = this.selectedItem;
        this.selectedName = this.selectedNameTemporary;
        this.selectedItem = this.temporarySelectedItem;


        if (this.dataGridComponent.options.header.selectionMultile.enabled) {
            if (!this.dataGridComponent.dxGrid.instance.hasEditData()) {
                this.applyChangeOnSelectionMultile(this.selectedItem);
            } else {
                this.applychangeActionClick = true;
            }
        } else {
            this.popupVisibility = !this.popupVisibility;
            if (this.formField && this.formField.editorOptions && this.formField.editorOptions.setValueExprOnly) {
                event.value = this.selectedItem[this.formField.editorOptions.valueExpr];
            } else {
                event.value = this.selectedItem;
            }
            event.fullValue = this.selectedItem;
            event.event = ev.event;
            this.applychangeActionClick = false;
            this.valueChanged.emit(event);
        }
    }

    onRowUpdated(event) {
        this.dataGridComponent.selectedRows = this.dataGridComponent.selectedRows.filter(row => row.id != event.key.id);
        this.dataGridComponent.selectedRows.push(event.key);

        if (this.applychangeActionClick === true) {
            this.applyChangeOnSelectionMultile(this.dataGridComponent.selectedRows);
        }

        this.rowUpdated.emit(event);
    }

    applyChangeOnSelectionMultile(rowsData: any) {
        let closePopup = true;
        if (isDefined(this.dataGridInput.closePopupCallback)) {
            if (this.dataGridInput.closePopupCallback instanceof Function) {
                closePopup = this.dataGridInput.closePopupCallback(this.dataGridComponent.selectedRows);
            } else {
                closePopup = this.dataGridInput.closePopupCallback;
            }
        }
        this.applychangeActionClick = false;
        this.popupVisibility = !closePopup;

        if (closePopup) {
            this.valueChanged.emit(this.dataGridComponent.selectedRows);
            this.dataGridComponent.dxGrid.instance.deselectAll();
        }
    }

    /**
     * called from parent to reload data
    */
    public refreshData() {
        this.dataGridComponent?.refreshData();
    }

    cancel() {
        this.temporarySelectedItem = null;
        this.dataGridComponent.dxGrid.instance.deselectAll();
        this.dataGridComponent.searchingWord = '';
        this.popupVisibility = !this.popupVisibility;
        this.closeDataGridPicker.emit(true);
    }

    get displayedValue(): any {
        if (!this.selectedItem && this.selectedItem !== 0) {
            return undefined;
        }



        if (this.displayExpr) {

            if (typeof (this.displayExpr) === 'string') {
                let val = null;
                const nest = this.displayExpr.split('.');
                if (nest.length > 1) {
                    val = this.selectedItem;
                    for (let i = 0; i < nest.length; i++) {
                        if (!val) {
                            break;
                        }
                        val = val[nest[i]];
                    }
                } else {
                    val = this.selectedItem[this.displayExpr];
                }
                return val;
            } else {
                let val = '';
                let indexedVal = '';
                for (let index = 0; index < this.displayExpr.length; index++) {

                    const nest = this.displayExpr[index].split('.');
                    if (nest.length > 1) {
                        indexedVal = this.selectedItem;
                        for (let i = 0; i < nest.length; i++) {
                            if (!indexedVal) {
                                break;
                            }
                            indexedVal = indexedVal[nest[i]];
                        }
                    } else {
                        indexedVal = this.selectedItem[this.displayExpr[index]];
                    }

                    val = val + (indexedVal ?? '') + ' ';

                }
                return val;
            }

        } else {
            if (this.selectedItem && this.selectedItem.length > 1) {
                return ('Vous avez choisi ' + this.selectedItem.length + ' élements ');
            }
            if (typeof (this.selectedItem) === 'object') {
                return ('Vous avez choisi 1 élement ');
            }

            return this.selectedItem;
        }
    }

    get popupIdentifier() {
        if (this.formField) {
            return this.formField.editorOptions.popupIdentifier;
        } else {
            return this.dataGridOptions.popupIdentifier;
        }
    }

    clearTextBox(event) {
        this.clearSelected.emit(this.selectedName);
        this.dxTextBoxComponent.instance.reset();
        this.selectedItem = null;
    }

    ngOnDestroy(): void {
        if (this.doubleClickSubscription) {
            this.doubleClickSubscription.unsubscribe();
        }
    }

    getplaceHolder(): string {
        if (this.focusedField) {
            return '';
        } else if (!this.focusedField && this.formField.isRequired) {
            return this.formField.label.text + ' *';
        } else {
            return this.formField.label.text;
        }
    }

    searchingWordChange(event: any) {
        this.popupGridComponent.onSearchingWordValueChanged(event);
    }

    columnChooser() {
        this.popupGridComponent.getColumnChooser();
    }

    manageHeightGrid() {
        this.popupGridComponent.headerSearch = !this.popupGridComponent.headerSearch;
        this.popupGridComponent.manageHeightGrid();
    }

    onEditButtonClicked(event) {

        this.editButtonClickedEvent.emit(event);
    }

    onValidateFilter(validate: boolean): void {
        this.validateFilter.emit(validate);
    }

    onDeleteAdvancedFilter(event: unknown) : void{
        this.deleteAdvancedFilter.emit(event);
    }

    getCriteriaSelected(item: { data: { articleCriteriaSelected: string | any[]; }; }, idCriteria: number): string {
        let criteriaValue = '';
        if (item.data.articleCriteriaSelected && item.data.articleCriteriaSelected.length) {
            for (const criteriaSelected of item.data.articleCriteriaSelected) {
                if (criteriaSelected.criteriaValue.criteriaId == idCriteria) {
                    criteriaValue = criteriaSelected.criteriaValue.designation;
                    break;
                }
            }
        }
        return criteriaValue;
    }
}
