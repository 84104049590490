import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ngc-notify',
    templateUrl: './ngc-notify.component.html',
    styleUrls: ['./ngc-notify.component.scss']
})
export class NgcNotifyComponent {
    isVisibleNotify: boolean = false;
    contentTemplate: string;
    notifyText: string;
    delay: number;
    size: string;

    constructor() { }
    onClickNotify(text: string, type: string, delay: number, size: string) {
        this.contentTemplate = type;
        this.notifyText = text;
        this.delay = delay;
        this.isVisibleNotify = true;
    }

}


