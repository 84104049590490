import { BaseEntity2 } from './base-entity2';

export class TableState extends BaseEntity2 {
    grid: number;
    state: string;
    constructor() {
        super();
        this.grid = 0;
        this.state = '';
    }

    public static convertIdStringTableToInt(nameTable: string): number {
        let idNumber: number;
        if (nameTable === 'customersTable') {
            idNumber = 1001;
        } else if (nameTable === 'applicationParameters') {
            idNumber = 1002;
        } else if (nameTable === 'usersTable') {
            idNumber = 1003;
        } else if (nameTable === 'usersTableInCbbScheduler') {
            idNumber = 1004;
        } else if (nameTable === 'reportTableBase') {
            idNumber = 1005;
        } else if (nameTable === 'reportTablePerso') {
            idNumber = 1006;
        } else if (nameTable === 'customersTableServerSide') {
            idNumber = 1007;
        } else if (nameTable === 'connectedUsersTable') {
            idNumber = 1008;
        } else if (nameTable === 'suppliersTable') {
            idNumber = 1009;
        } else if (nameTable === 'appointmentTable') {
            idNumber = 1010;
        } else if (nameTable === 'customersCategoriesTable') {
            idNumber = 1011;
        } else if (nameTable === 'supplierCategoriesTable') {
            idNumber = 1012;
        } else if (nameTable === 'RepresentativeType') {
            idNumber = 1013;
        } else if (nameTable === 'Civility') {
            idNumber = 1014;
        } else if (nameTable === 'MovementTitleType') {
            idNumber = 1021;
        } else if (nameTable === 'CustomerClass') {
            idNumber = 1022;
        } else if (nameTable === 'SupplierClass') {
            idNumber = 1023;
        } else if (nameTable === 'CustomerFollowUpStatus') {
            idNumber = 1024;
        } else if (nameTable === 'SupplierFollowUpStatus') {
            idNumber = 1025;
        } else if (nameTable === 'CustomersRepresentative') {
            idNumber = 1026;
        } else if (nameTable === 'SuppliersRepresentative') {
            idNumber = 1027;
        } else if (nameTable === 'Currency') {
            idNumber = 1028;
        } else if (nameTable === 'BasicRate') {
            idNumber = 1029;
        } else if (nameTable === 'Packaging') {
            idNumber = 1030;
        } else if (nameTable === 'PaymentChoice') {
            idNumber = 1031;
        } else if (nameTable === 'PortScale') {
            idNumber = 1032;
        } else if (nameTable === 'Referencing') {
            idNumber = 1033;
        } else if (nameTable === 'billing') {
            idNumber = 1034;
        } else if (nameTable === 'supplierTableParameter') {
            idNumber = 1035;
        } else if (nameTable === 'customerTableParameter') {
            idNumber = 1036;
        } else if (nameTable === 'customerCriteria') {
            idNumber = 1037;
        } else if (nameTable === 'supplierCriteria') {
            idNumber = 1038;
        } else if (nameTable === 'customerCriteriaValues') {
            idNumber = 1039;
        } else if (nameTable === 'supplierCriteriaValues') {
            idNumber = 1040;
        } else if (nameTable === 'location') {
            idNumber = 1041;
        } else if (nameTable === 'articles') {
            idNumber = 1042;
        } else if (nameTable === 'conditioning') {
            idNumber = 1043;
        } else if (nameTable === 'advice') {
            idNumber = 1044;
        } else if (nameTable === 'purchaseCode') {
            idNumber = 1045;
        } else if (nameTable === 'salesCode') {
            idNumber = 1046;
        } else if (nameTable === 'articleFamily') {
            idNumber = 1047;
        } else if (nameTable === 'articleUnderFamily') {
            idNumber = 1048;
        } else if (nameTable === 'whyFree') {
            idNumber = 1049;
        } else if (nameTable === 'designation4') {
            idNumber = 1050;
        } else if (nameTable === 'designation5') {
            idNumber = 1051;
        } else if (nameTable === 'articleCriteria') {
            idNumber = 1052;
        } else if (nameTable === 'articleCriteriaValues') {
            idNumber = 1053;
        } else if (nameTable === 'cost-group') {
            idNumber = 1054;
        } else if (nameTable === 'adresseassociate') {
            idNumber = 1055;
        } else if (nameTable === 'roundingRules') {
            idNumber = 1056;
        } else if (nameTable === 'Contacts') {
            idNumber = 1057;
        } else if (nameTable === 'ContactsPhoneType') {
            idNumber = 1058;
        } else if (nameTable === 'ContactsFunction') {
            idNumber = 1059;
        } else if (nameTable === 'contactSupplier') {
            idNumber = 1060;
        } else if (nameTable === 'contactCustomer') {
            idNumber = 1061;
        } else if (nameTable === 'clientRef') {
            idNumber = 1062;
        } else if (nameTable === 'pricing') {
            idNumber = 1063;
        } else if (nameTable === 'PackagingFamily') {
            idNumber = 1064;
        } else if (nameTable === 'articlePackaging') {
            idNumber = 1065;
        } else if (nameTable === 'packaging_packagingArticle') {
            idNumber = 1066;
        } else if (nameTable === 'pricing_pricingArticle') {
            idNumber = 1077;
        } else if (nameTable === 'idPrivilegiedPackaging1_packagingArticle') {
            idNumber = 1067;
        } else if (nameTable === 'idPrivilegiedPackaging2_packagingArticle') {
            idNumber = 1068;
        } else if (nameTable === 'idPrivilegiedPackaging3_packagingArticle') {
            idNumber = 1069;
        } else if (nameTable === 'idPrivilegiedPackaging4_packagingArticle') {
            idNumber = 1070;
        } else if (nameTable === 'tva') {
            idNumber = 1071;
        } else if (nameTable === 'tvaToDate') {
            idNumber = 1072;
        } else if (nameTable === 'PackagingCrossing') {
            idNumber = 1073;
        } else if (nameTable === 'packaging_packagingCrossing') {
            idNumber = 1074;
        } else if (nameTable === 'packaging_packagingOverPack') {
            idNumber = 1075;
        } else if (nameTable === 'apiKey') {
            idNumber = 1076;
        } else if (nameTable === 'companyAddresses') {
            idNumber = 1077;
        } else if (nameTable === 'product') {
            idNumber = 1078;
        } else if (nameTable === 'tax') {
            idNumber = 1079;
        } else if (nameTable === 'tva_tvaTax') {
            idNumber = 1080;
        } else if (nameTable === 'taxToDate') {
            idNumber = 1081;
        } else if (nameTable === 'taxFamily') {
            idNumber = 1082;
        } else if (nameTable === 'tax_taxFamily') {
            idNumber = 1083;
        } else if (nameTable === 'taxFamily_Product') {
            idNumber = 1084;
        } else if (nameTable === 'WidgetsBase') {
            idNumber = 1085;
        } else if (nameTable === 'InWaitingWidgetsBasePicker') {
            idNumber = 1086;
        } else if (nameTable === 'customerToInvoiceTable') {
            idNumber = 1087;
        } else if (nameTable === 'supplierToInvoiceTable') {
            idNumber = 1088;
        } else if (nameTable === 'depot') {
            idNumber = 1089;
        } else if (nameTable === 'PaymentMethod') {
            idNumber = 1090;
        } else if (nameTable === 'customerOpInvoicing') {
            idNumber = 1091;
        } else if (nameTable === 'supplierOpInvoicing') {
            idNumber = 1092;
        } else if (nameTable === 'depotParamUser') {
            idNumber = 1093;
        } else if (nameTable === 'operationLogistiqueCustomer') {
            idNumber = 1094;
        } else if (nameTable === 'articlesForOperation') {
            idNumber = 1095;
        } else if (nameTable === 'operationHistory') {
            idNumber = 1096;
        } else if (nameTable === 'commercialOperationLine') {
            idNumber = 1097;
        } else if (nameTable === 'mobileEquipement') {
            idNumber = 1098;
        } else if (nameTable === 'transportTable') {
            idNumber = 1099;
        } else if (nameTable === 'ftpChoice') {
            idNumber = 1100;
        } else if (nameTable === 'ftp') {
            idNumber = 1101;
        } else if (nameTable === 'customerOfReferenceMobilEquipment') {
            idNumber = 1102;
        } else if (nameTable === 'usersTableMobilEquipement') {
            idNumber = 1103;
        } else if (nameTable === 'checkoutCustomerMobilEquipement') {
            idNumber = 1104;
        } else if (nameTable === 'stockLotForMvts') {
            idNumber = 1105;
        } else if (nameTable === 'mouvements') {
            idNumber = 1106;
        } else if (nameTable === 'inventory') {
            idNumber = 1107;
        } else if (nameTable === 'stocklot') {
            idNumber = 1108;
        } else if (nameTable === 'packaging1_packagingArticle') {
            idNumber = 1109;
        } else if (nameTable === 'packagingFinal_packagingArticle') {
            idNumber = 1110;
        } else if (nameTable === 'ebacchus_product') {
            idNumber = 1111;
        } else if (nameTable === 'productColor_product') {
            idNumber = 1112;
        } else if (nameTable === 'productType_product') {
            idNumber = 1113;
        } else if (nameTable === 'tax_taxLabels') {
            idNumber = 1114;
        } else if (nameTable === 'packaging') {
            idNumber = 1115;
        } else if (nameTable === 'typeDesignation5') {
            idNumber = 1116;
        } else if (nameTable === 'suppliersOpNewTable') {
            idNumber = 1118;
        } else if (nameTable === 'articleRef') {
            idNumber = 1119;
        } else if (nameTable === 'ContactsPhoneNumber') {
            idNumber = 1120;
        } else if (nameTable === 'uniteArticle') {
            idNumber = 1121;
        } else if (nameTable === 'society') {
            idNumber = 1122;
        } else if (nameTable === 'mailTable') {
            idNumber = 1123;
        } else if (nameTable === 'suppliersOpDetailTable') {
            idNumber = 1124;
        } else if (nameTable === 'operationLogistiqueSupplier') {
            idNumber = 1125;
        } else if (nameTable === 'etiquetteTable') {
            idNumber = 1126;
        } else if (nameTable === 'etiquetteTablePerso') {
            idNumber = 1127;
        } else if (nameTable === 'customersOpNewTable') {
            idNumber = 1128;
        } else if (nameTable === 'customersOpDetailTable') {
            idNumber = 1129;
        } else if (nameTable === 'customersOpGridTable') {
            idNumber = 1130;
        } else if (nameTable === 'suppliersOpGridTable') {
            idNumber = 1131;
        } else if (nameTable === 'printer') {
            idNumber = 1132;
        } else if (nameTable === 'articleByBasicRateTable') {
            idNumber = 1133;
        } else if (nameTable === 'traitement') {
            idNumber = 1134;
        } else if (nameTable === 'tache') {
            idNumber = 1135;
        } else if (nameTable === 'bankTable') {
            idNumber = 1136;
        } else if (nameTable === 'zoneProtegee') {
            idNumber = 1137;
        } else if (nameTable === 'mailTemplateBase') {
            idNumber = 1138;
        } else if (nameTable === 'mailTemplatePerso') {
            idNumber = 1139;
        } else if (nameTable === 'reglement') {
            idNumber = 1140;
        } else if (nameTable === 'invoices') {
            idNumber = 1141;
        } else if (nameTable === 'customerAccountingFamily') {
            idNumber = 1142;
        } else if (nameTable === 'trackInvoicesTable') {
            idNumber = 1143;
        } else if (nameTable === 'externUsers') {
            idNumber = 1144;
        } else if (nameTable === 'usersTableExternUsers') {
            idNumber = 1145;
        } else if (nameTable === 'moduleTable') {
            idNumber = 1146;
        } else if (nameTable === 'familyCommissionBrokerageRuleTable') {
            idNumber = 1147;
        } else if (nameTable === 'commissionFamily') {
            idNumber = 1148;
        } else if (nameTable === 'brokerageFamily') {
            idNumber = 1149;
        } else if (nameTable === 'tradingTable') {
            idNumber = 1150;
        } else if (nameTable === 'representativeGridPicker') {
            idNumber = 1151
        } else if (nameTable === 'invoiceInCommissionTable') {
            idNumber = 1151;
        } else if (nameTable === 'tradingLineTable') {
            idNumber = 1152;
        } else if (nameTable === 'stockMvtSettingTable') {
            idNumber = 1153;
        } else if (nameTable === 'designation6') {
            idNumber = 1154;
        }

        return idNumber;
    }
}

