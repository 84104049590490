/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportType, User } from '@app/shared/model';
import { AuthService } from '@app/core/services';
import { FavorisEtiquetteService } from '@app/core/services/etiquette/favoris-etiquette.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NextDxPopupComponent } from '@app/shared/modules/devextreme/dx-popup/next-dx-popup.component';
import { positionConfig } from 'devextreme/animation/position';
import { CategoryEtiquette, Etiquette } from '@app/shared/model/Etiquette/Etiquette';
import { EtiquetteFavoris } from '@app/shared/model/Etiquette/EtiquetteFavoris';
import { ApplicationMode } from '@app/shared/model/base-entity1';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dx-popup-print',
    templateUrl: './dx-popup-print.component.html',
    styleUrls: ['./dx-popup-print.component.scss']
})
export class DxPopupPrintComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('popupFavoris', { static: false }) popupFavoris: NextDxPopupComponent;
    @ViewChild('popupNotFavoris', { static: false }) popupNotFavoris: NextDxPopupComponent;

    @Input() isPopupPrintVisible: boolean;
    @Input() id: string;
    @Input() categoryEtiquette: CategoryEtiquette;
    @Input() stringPopupId = 'defaultPrintPrint';
    @Input() title: string;
    @Input() redisKeyPrint: string;
    @Input() isPopupPrintTwoVisible: boolean;
    @Input() popupClass = '';

    @Output() isPopupPrintVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

    etiquetteList: Etiquette[] = new Array<Etiquette>();
    favoriteEtiquetteList: Etiquette[] = new Array<Etiquette>();
    etiquetteListBase: Etiquette[] = new Array<Etiquette>();
    etiquetteListPerso: Etiquette[] = new Array<Etiquette>();
    applicationMode: ApplicationMode;
    user: User;
    subscriptions: Array<Subscription> = new Array<Subscription>();
    isLoadPanelVisible = true;
    isPopupForEtiquette  = false;
    popupPrintTwoPosition: positionConfig | Function | string = {
        at: { x: 'center', y: 'center' },
        my: { x: 'center', y: 'center' },
        of: window, offset: { x: 291, y: 1 }
    };
    popupPrintOnePosition: positionConfig | Function | string = {
        at: { x: 'left', y: 'center' },
        my: { x: 'left', y: 'center' },
        of: window, offset: { x: 328, y: 1 }
    };

    public popupFavEtiquettesIdentifier: string;
    public popupEtiquettesIdentifier: string;

    constructor(
        private cdRef: ChangeDetectorRef,
        private favorisEtiquetteService: FavorisEtiquetteService,
        private _authService: AuthService,
    ) {
        this.subscriptions.push(
            this._authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    this.user = user;
                    this.applicationMode = user.societies[0].applicationMode;
                },
                (error) => console.log('error user in report : ', error)
            )
        );
    }

    ngOnChanges(): void {
        if (this.isPopupPrintVisible) {
            this.getReports();
        }
    }

    ngOnInit(): void {
        if (typeof this.title === 'undefined') {
            throw new Error('Popup Print must have title');
        }
        this.popupFavEtiquettesIdentifier = this.stringPopupId + '_favorisReports';
        this.popupEtiquettesIdentifier = this.stringPopupId + '_Reports';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    getReports(clearCache = false): void {
        if (clearCache) {
            this.favorisEtiquetteService.clearListTablesCache(this.categoryEtiquette);
        }
        this.subscriptions.push(
            this.favorisEtiquetteService.getAll(this.categoryEtiquette).subscribe(
                (etiquettes: Etiquette[]) => {
                    this.isLoadPanelVisible = false;
                    this.etiquetteList = etiquettes.filter((etiquette: Etiquette) => etiquette.state === true && !etiquette.favoris);
                    this.favoriteEtiquetteList = etiquettes.filter((etiquette: Etiquette) => etiquette.favoris === true);
                    this.etiquetteListBase = etiquettes.filter((etiquette: Etiquette) => etiquette.state === true && !etiquette.favoris && !etiquette.idOrigine && !etiquette.idOriginePerso);
                    this.etiquetteListPerso = etiquettes.filter((etiquette: Etiquette) => etiquette.state === true && !etiquette.favoris && (etiquette.idOrigine || etiquette.idOriginePerso));
                    this.isPopupForEtiquette = true;
                    if (!this.favoriteEtiquetteList.length) {
                        this.isPopupPrintTwoVisible = true;
                    }
                }
            )
        );

    }

    drop(event: CdkDragDrop<string[]>): void {
        let index: number;
        if (event.previousContainer === event.container) {
            // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            switch (event.previousContainer.id) {
                case 'allEtiquettes':
                    (event.previousIndex > this.etiquetteList.length) ? index = event.previousIndex - this.etiquetteList.length : index = event.previousIndex;
                    this.addToFavorite(this.etiquetteList[index]);
                    break; // add favoris
                case 'favoriteEtiquettes': this.removeFromFavorite(this.favoriteEtiquetteList[event.previousIndex]); break; // remove from favoris
                case 'default': break; // nothing to do
            }
        }
    }

    addToFavorite(etiquette: Etiquette): void {
        const etiquetteFavoris: EtiquetteFavoris = new EtiquetteFavoris();
        if (!etiquette.idOrigine && !etiquette.idOriginePerso) {
            etiquetteFavoris.etiquetteBaseFavorisId = etiquette.id;
        } else if (etiquette.idOrigine || etiquette.idOriginePerso) {
            etiquetteFavoris.etiquettePersoFavorisId = etiquette.id;
        }
        etiquetteFavoris.userId = this.user.id;
        this.subscriptions.push(
            this.favorisEtiquetteService.post(etiquetteFavoris).subscribe(
                () => {
                    this.onRefreshClicked();
                },
                (error) => {
                    console.log('error post: ', error);
                }
            )
        );
    }

    removeFromFavorite(etiquette: Etiquette): void {
        let type: string;
        if (!etiquette.idOrigine && !etiquette.idOriginePerso) {
            type = 'EtiquetteBase';
        } else if (etiquette.idOrigine || etiquette.idOriginePerso) {
            type = 'EtiquettePerso';
        }
        this.subscriptions.push(
            this.favorisEtiquetteService.delete(etiquette.id, type).subscribe(
                () => {
                    this.onRefreshClicked();
                },
                (error) => {
                    console.log('error delete: ', error);
                }
            )
        );
    }

    onMoreClicked(): void {
        this.isPopupPrintTwoVisible = true;
    }

    onRefreshClicked(): void {
        this.isLoadPanelVisible = true;
        this.etiquetteListBase = this.etiquetteListPerso = this.favoriteEtiquetteList = [];
        this.getReports(true);
    }

    viewerEtiquette(etiquette: Etiquette, reportType: ReportType): void | boolean {
        if (etiquette.id === 0) {
            return false;
        }
        const event = {
            id: etiquette.id,
            reportType: reportType,
            category : etiquette.category,
            name : etiquette.name
        }

        this.itemSelected.emit(event);

        this.isPopupPrintVisible = false;
        this.isPopupPrintTwoVisible = false;
        this.cdRef.detectChanges();
    }

    popupOneHiding(): void {
        this.isPopupPrintVisible = false;
        this.isPopupPrintTwoVisible = false;
        this.isPopupPrintVisibleChange.emit(this.isPopupPrintVisible);
        this.isPopupPrintVisibleChange.emit(this.isPopupPrintTwoVisible);
    }

    popupTwoHiding(): void {
        this.isPopupPrintTwoVisible = false;
    }

    managePopupsPosition(): void {
        this.popupFavoris.managePosition();
        this.popupNotFavoris.managePosition();
    }
}
