import { dxDataGridOptions } from 'devextreme/ui/data_grid';
const defaultDGridOptions: dxDataGridOptions = {
    width: '100%',
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnResizingMode: 'widget',
    columnAutoWidth: true,
    columnMinWidth: 50,
    showBorders: false,
    rowAlternationEnabled: true,
    hoverStateEnabled: true,
    // allowColumnFiltering: true, TODO what is this
    // allowSearch: true, TODO what is this
    showColumnLines: false,
    showRowLines: false,
    columnChooser: {
        enabled: false,
        mode: 'select',
        // css: 'collapsableItem'
    },
    columnFixing: {
        enabled: true
    },

    loadPanel: {
        enabled: false
    },
    sorting: {
        // mode: 'multiple'
    },
    /*selection: {
        mode: 'multiple',
        deferred: false,
        selectAllMode: 'page',
        showCheckBoxesMode: 'none'
    },*/
    remoteOperations: {
        sorting: true,
        paging: true,
        filtering: true
    },
    paging: {
        pageSize: 10
    },
    pager: {
        visible: false,
        showInfo: true,
        showNavigationButtons: true,
        showPageSizeSelector: false,
        allowedPageSizes: [10, 20, 50, 100],
        displayMode: 'adaptive'
    },
    // filterRow: {
    // visible: true,
    // applyFilter: 'auto'
    // },
    headerFilter: {
        visible: true,
        allowSearch: true
    },
    grouping: {
        autoExpandAll: true,
        allowCollapsing: true,
        contextMenuEnabled: true
    },
    groupPanel: {
        visible: 'auto',
    },
    editing: {
        allowDeleting: false,
        mode: 'row',
        useIcons: true,
    },
    export: {
        enabled: true,
        allowExportSelectedData: false,
        excelFilterEnabled: true,
        fileName: 'My data'
    },
    onCellPrepared(e) { },
    onContentReady: function (e) {}


};
defaultDGridOptions['allowColumnFiltering'] = true;
defaultDGridOptions['allowSearch'] = true;
export const defaultDataGridOptions = defaultDGridOptions;
