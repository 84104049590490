import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges {

    @Input() breadcrumb: any;
    @Input() enabled = true;
    template: any[] = [];

    constructor(
        private router: Router,
    ) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.template = [];        
        this.breadcrumb.forEach(item => {
            if (typeof item === 'object' && item.path && typeof item.path === 'string' && item.breadcrumb && typeof item.breadcrumb === 'string') {
                this.template.push({
                    route: item.path,
                    title: item.breadcrumb,
                });
            } else {
                this.router.config.forEach(routes => {
                    this.build(routes, item, '');
                });
            }
        });
    }

    build(routes, item, url) {
        if (typeof item === 'string' && routes.data && routes.data.name === item) {
            this.template.push({
                route: url + routes.path,
                title: routes.data.breadcrumb,
            });
        } else if (typeof item === 'object' && routes.data && routes.data.name === item.name) {
            this.template.push({
                route: item.path ? item.path : url + routes.path,
                title: item.breadcrumb ? item.breadcrumb : routes.data.breadcrumb,
            });
        } else if (routes['_loadedConfig']) {
            url = url + routes.path + '/';
            routes['_loadedConfig'].routes.forEach(route => {
                this.build(route, item, url);
            });
        } else if (routes.children) {

            url = url + routes.path + '/';
            routes.children.forEach(route => {
                this.build(route, item, url);
            });
        }
    }

}
