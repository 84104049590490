<div id="content" [ngClass]="{'border-GridForForm': isGridForForm}">
    <ng-container *ngIf="inSlidePanel; else elseTemplate">
        <div class="header-slide-panel row">

            <div class="col col-6">
                <ngc-icon size="24" icon="{{dataGridInput.slidePanelGirdHeaderIcon}}"></ngc-icon>
                <span class="slide-panel-title"><b style="font-weight: 600;">{{pageTitle
                        | translate}}</b></span>
            </div>
            <div class="col no-padding" *ngIf="options.header.toggle.showToggle
                && dataGridInput.showHeader">
                <div class="can-toggle">
                    <div class="switch-field">
                        <input (change)="changeMode()" type="radio" id="radio-two" name="switch-one" value="no"
                            checked />
                        <label for="radio-two">{{options.header.toggle.firstChoiceText
                            | translate}}</label>
                        <input (change)="changeMode()" type="radio" id="radio-one" name="switch-one" value="yes" />
                        <label for="radio-one">{{options.header.toggle.secondChoiceText
                            | translate}}</label>
                    </div>
                </div>
            </div>

            <div *ngIf="dataGridInput.showHeader" class="col
                search-field-slide-panel no-padding ml-1">
                <app-extra-button-field [buttonsBefore]="datagridSearch.buttonsBefore" [value]="searchingWord"
                    (ValueChanged)="onSearchingWordValueChanged($event)" [ngClass]="{'onlySearch':
                    !options.header.headerbuttonsTemplate}">
                </app-extra-button-field>
            </div>

            <div *ngIf="dataGridInput.showHeader" class="col no-padding">
                <dx-drop-down-button id="dropdownMore" icon="more" [items]="dropDownGridItems" displayExpr="name"
                    type="default" keyExpr="id" [dropDownOptions]="{ width: 230
                    }" [showArrowIcon]="false" class="dropdownDataGrid"
                    (onItemClick)="onDropDownGridItemsClicked($event)">
                </dx-drop-down-button>
            </div>
        </div>
    </ng-container>

    <ng-template #elseTemplate>
        <div class="page-header-container" style="margin-bottom:0px">
            <div *ngIf="dataGridInput.showHeader">
                <app-breadcrumb *ngIf="!inPopup && !isGridForForm" class="breadcrumb"
                    [enabled]="options.breadcrumb.enabled" [breadcrumb]="breadcrumb"></app-breadcrumb>
                <div [ngClass]="{
                    'gridForForm-page-header' : isGridForForm,
                    'page-header datagrid-page-header': !isGridForForm,
                    'page-header-popup': inPopup && dataGridInput.showHeader}">
                    <ng-container *ngTemplateOutlet="headerLeftBlocTemplate ?
                        headerLeftBlocTemplate.template : null; context: {
                        $implicit: { inPopup: inPopup } }">
                    </ng-container>
                    <!-- DEPERCATED TO BE DELETED AFTER MIGRATION -->
                    <div class="left-bloc" *ngIf="!headerLeftBlocTemplate &&
                        !headerInfo && !inPopup" style="width:
                        -webkit-fill-available;">
                        <div class="text-zone" *ngIf="!inPopup && !isGridForForm">
                            <div class="title" [hidden]="!options.header.showHeaderTitle">
                                <span>{{pageTitle | translate}}</span>
                                <span>{{pageTitleAdds}}</span>
                            </div>
                        </div>
                        <div class="text-zone-GridForForm" *ngIf="isGridForForm">
                            <div class="title-GridForForm" [hidden]="!options.header.showHeaderTitle">
                                <ngc-icon size="24" icon="{{pageTitleIcon}}" *ngIf="options.header.showHeaderTitleIcon">
                                </ngc-icon>
                                <span class="title">{{pageTitle | translate}}</span>
                                <span>{{pageTitleAdds}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- END DEPERCATED -->
                    <div class="left-bloc" *ngIf="!headerLeftBlocTemplate && headerInfo && !inPopup">
                        <app-left-header-bloc [headerInfo]="headerInfo"></app-left-header-bloc>
                    </div>

                    <div class="right-bloc" *ngIf="options.header.showHeaderButtons" style="width: -webkit-fill-available; display: flex;
                        flex-direction: column;
                        justify-content: center;"
                        [ngStyle]="{'height': isGridForForm ? '41px' : '53px' , 'border-bottom': headerInfo && headerInfo.hasParent ? '1px solid #D4D4D4' : '0'}">

                        <div style="height: 32px;width: 100%;display:
                            flex;justify-content: flex-end; margin-top: -5px;">

                            <ng-container [ngIf]="!inPopup" *ngTemplateOutlet="getTemplate('headerbuttonsTemplate');
                                context: { $implicit: { data: data } }">
                            </ng-container>
                            <div *ngIf="options.header.showSearchBar && !isGridForForm">
                                <div *ngIf="!filterButton; else filterInSeachBar" [style]="searchBarStyle === null? 'width:700px !important' : searchBarStyle">
                                    <app-extra-button-field
                                        [buttonsBefore]="datagridSearch.buttonsBefore" [clearButton]="true"
                                        [buttonsAfter]="datagridSearch.buttonsAfter" [value]="searchingWord"
                                        (ValueChanged)="onSearchingWordValueChanged($event)"
                                        [ngClass]="{'onlySearch':
                                    !options.header.headerbuttonsTemplate}">
                                    </app-extra-button-field>
                                </div>
                                <ng-template #filterInSeachBar >
                                    <app-extra-button-field
                                        [buttonsBefore]="datagridSearch.buttonsBefore" [clearButton]="true"
                                        [buttonsAfter]="datagridSearch.buttonsAfter" [value]="searchingWord"
                                        (ValueChanged)="onSearchingWordValueChanged($event)" [style]="searchBarStyle"
                                        [ngClass]="{'onlySearch':
                                    !options.header.headerbuttonsTemplate}" [slidePanelOptions]="slidePanelOptions"
                                        (deleteAdvancedFilter)="onDeleteAdvancedFilter($event)"
                                        (validateSuccess)="onValidateSuccess($event)"
                                        (slidePanelClose)="onSlidePanelClose($event)">

                                        <div *templateKey="'filterTemplateContent'; let d">
                                            <div id="content">
                                                <dx-scroll-view [height]="slidePanelOptions.css.height">
                                                    <form class="generic-form ngc-generic-form" autocomplete="off" style="margin-top: 13px">
                                                        <div class="form-content">
                                                            <div class="pl-0 pr-0 editable-content">
                                                                <dx-validation-group #validationGroupe class="validationGroup">
                                                                    <div *ngFor="let advancedFilterConfig of advancedFilterConfigArray">
                                                                        <div *ngIf="advancedFilterConfig.separator">
                                                                            <div class="filter-separator"></div>
                                                                        </div>
                                                                        <div *ngIf="advancedFilterConfig.group">
                                                                            <p class="filter-title-group">{{advancedFilterConfig.group}}</p>
                                                                        </div>
                                                                        <div class="filter-fields">
                                                                            <div *ngFor="let advancedFilter of advancedFilterConfig.fields"
                                                                                [ngStyle]="{'width': advancedFilter.field.width}">
                                                                                <app-dx-field [field]="advancedFilter.field"
                                                                                    [item]="modelAdvancedFilter" [editable]="true"
                                                                                    [itemCopy]="modelAdvancedFilterCopy">
                                                                                </app-dx-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dx-validation-group>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </dx-scroll-view>
                                            </div>
                                        </div>
                                    </app-extra-button-field>
                                </ng-template>
                            </div>
                            <ng-container [ngTemplateOutlet]="additionelTemplate"></ng-container>


                            <span *ngIf="returnButton !== undefined && returnButton !== null">
                                <dx-button type="default" id="returnButton" stylingMode="text"
                                    (click)="ReturnButtonPath()">
                                    <span class='icon'>
                                        <ngc-icon size="16" icon="left"></ngc-icon>
                                    </span>
                                    <div class='content'>{{'Return' | translate}}</div>
                                </dx-button>
                            </span>

                            <span class="arrows" *ngIf="showNavigationButtons">
                                <dx-button type="default" class="arrow" stylingMode="text">
                                    <ngc-icon size="20" icon="left"></ngc-icon>
                                </dx-button>
                                <dx-button class="arrow" type="default" stylingMode="text">
                                    <ngc-icon size="20" icon="right"></ngc-icon>
                                </dx-button>
                            </span>

                            <dx-drop-down-button *ngIf="options.header.selectionMultile.multipleAction
                            && selectedRows && selectedRows.length>1"
                                [text]="'data-grid.placeholderMultipleAction' | translate"
                                [dropDownOptions]="{ width: 230 }" [items]="selectionActions.Actions"
                                (onItemClick)="onMultipleActionItemClicked($event)" displayExpr="name"
                                itemTemplate="dropDownButtonTemplate" id="multipleActionButton">
                                <div *dxTemplate="let data of
                                'dropDownButtonTemplate'">
                                    <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                                    {{data.name}}
                                </div>
                            </dx-drop-down-button>

                            <dx-button type="default" class="save-text-button" stylingMode="contained"
                                *ngIf="options.header.saveTextButton.visible" (click)="onSaveTextButton()" [ngStyle]="{'width':
                            options.header.saveTextButton.width}">
                                <span class="save-text-button-title">{{options.header.saveTextButton.text}}</span>
                            </dx-button>
                            <dx-button *ngIf="slidePanelOptions &&
                            slidePanelOptions.filter" width="32" style="height: 32px;" type="default" stylingMode="outlined"  
                            (click)="onFilterButtonClicked()" class="filter">
                                <ngc-icon size="20" icon="filter" class="align-bottom"></ngc-icon>
                                <!-- <span class="dx-button-text" translate>iconsText.FILTERS</span> -->
                            </dx-button>

                            <dx-button *ngIf="!options.header.hideCreateButton &&
                            !inPopup && dataGridInput.showHeaderCreateButton && !isGridForForm"
                                style="height: 32px;width: 32px" type="default" stylingMode="contained"
                                [disabled]="options.header.disableCreateButton" (click)="onAddbuttonClicked()"
                                class="add-button">
                                <ngc-icon size="20" icon="add"></ngc-icon>
                            </dx-button>

                            <dx-drop-down-button id="dropdownMore" icon="more" [items]="dropDownGridItems"
                                displayExpr="name" type="default" keyExpr="id" [dropDownOptions]="{ width: 230 }"
                                [showArrowIcon]="false" class="dropdownDataGrid" *ngIf="!isGridForForm"
                                (onItemClick)="onDropDownGridItemsClicked($event)"
                                itemTemplate="dropDownButtonTemplate">
                                <div *dxTemplate="let data of
                                    'dropDownButtonTemplate'">
                                    <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                                    {{data.name}}
                                </div>
                            </dx-drop-down-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filtres avancés-->
            <div class="filters-content" *ngIf="!isGridForForm && options.header.showFilters">
                <div class="filters" *ngIf="filter.length> 0 || filterHeader.length > 0">
                    <div class="badge-content" *ngIf="filter.length> 0">
                        <div *ngFor="let badge of filter; let i = index">
                            <div *ngIf="badge.displayChips;else EmptyDisplayChips" class="badge" id="badge{{i}}"
                                [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                    showEvent="mouseenter" hideEvent="mouseleave">
                                    <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                        {{badge.caption}}
                                    </div>
                                </dx-popover>
                                <div>
                                    <div class="badge-text">
                                        {{badge.displayChips}}
                                    </div>
                                </div>
                                <dx-button class="badge-button" *ngIf="deleteSearchElementVisible"
                                    (click)="deleteSearchElement(badge)">
                                    <ngc-icon size="16" icon="closeRoundedFlat">
                                    </ngc-icon>
                                </dx-button>
                            </div>
                            <ng-template #EmptyDisplayChips>
                                <div class="badge" id="badge{{i}}" *ngIf="badge.filterValue !== '' && badge.filterValue !== null && badge.type !== 'date'
                                && badge.type !== 'enum' && badge.showFilterBadge !== false"
                                    [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}
                                        </div>
                                    </dx-popover>
                                    <div
                                        *ngIf="badge.selectedFilterOperation === '>=' || badge.selectedFilterOperation === '>'">
                                        <div class="badge-text" *ngIf="badge.type !== 'ignoreSelectedFilterOperation'">
                                            Supérieur à {{badge.filterValue}}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="badge.selectedFilterOperation === '<=' || badge.selectedFilterOperation === '<'">
                                        <div class="badge-text" *ngIf="badge.type !== 'ignoreSelectedFilterOperation'">
                                            Inférieur à {{badge.filterValue}}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="badge.selectedFilterOperation !== '>=' && badge.selectedFilterOperation !== '<='
                                        && badge.selectedFilterOperation !== '>' && badge.selectedFilterOperation !== '<'">
                                        <div *ngIf="badge.type !== 'boolean'">
                                            <div class="badge-text">
                                                {{badge.filterValue}}
                                            </div>
                                        </div>
                                        <div *ngIf="badge.type === 'boolean'">
                                            <div *ngIf="badge.filterValue === 'true'">
                                                <div class="badge-text">{{'form.true' | translate}}</div>
                                            </div>
                                            <div *ngIf="badge.filterValue === 'false'">
                                                <div class="badge-text">{{'form.false' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <dx-button class="badge-button" *ngIf="deleteSearchElementVisible"
                                        (click)="deleteSearchElement(badge)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                                <div class="badge" id="badge{{i}}" *ngIf="badge.filterValue !== '' && badge.filterValue !== null &&
                                    badge.type === 'date' && badge.showFilterBadge !== false"
                                    [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}
                                        </div>
                                    </dx-popover>
                                    <div *ngIf="badge.selectedFilterOperation ===
                                            '>='">
                                        <div class="badge-text">Depuis le
                                            {{badge.filterValue | date:
                                            'dd/MM/yyyy'}}</div>
                                    </div>
                                    <div *ngIf="badge.selectedFilterOperation ===
                                            '<='">
                                        <div class="badge-text">Avant le
                                            {{badge.filterValue | date:
                                            'dd/MM/yyyy'}}</div>
                                    </div>
                                    <div *ngIf="badge.selectedFilterOperation !==
                                            '>=' && badge.selectedFilterOperation !==
                                            '<='">
                                        <div class="badge-text">{{badge.filterValue
                                            | date: 'dd/MM/yyyy'}}</div>
                                    </div>
                                    <dx-button class="badge-button" (click)="deleteSearchElement(badge)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                                <div class="badge" id="badge{{i}}" *ngIf="badge.type === 'enum' && badge.showFilterBadge !== false"
                                    [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}</div>
                                    </dx-popover>
                                    <div class="badge-text">
                                        {{ convertEnumToKeyValue(badge.filterValue,
                                        badge.enumType,
                                        badge.translateSource)}}
                                    </div>
                                    <dx-button class="badge-button" (click)="deleteSearchElement(badge)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                                <div class="badge" id="badge{{i}}" *ngIf="badge.filterValueRange !== [] &&
                                            badge.filterValueRange !== null &&
                                            badge.type === 'date' && badge.showFilterBadge !== false"
                                    [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}</div>
                                    </dx-popover>
                                    <div class="badge-text">Du
                                        {{badge.filterValueRange[0] | date:
                                        'dd/MM/yyyy'}} au
                                        {{badge.filterValueRange[1] | date:
                                        'dd/MM/yyyy'}}</div>
                                    <dx-button class="badge-button" (click)="deleteSearchElement(badge)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                                <div class="badge" id="badge{{i}}" *ngIf="badge.filterValueRange !== [] &&
                                            badge.filterValueRange !== null &&
                                            badge.type !== 'date' && badge.showFilterBadge !== false"
                                    [ngStyle]="{'background':badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge{{i}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}</div>
                                    </dx-popover>
                                    <div class="badge-text">De
                                        {{badge.filterValueRange[0] }} à
                                        {{badge.filterValueRange[1] }}</div>
                                    <dx-button class="badge-button" (click)="deleteSearchElement(badge)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="badge-content" *ngIf="filterHeader.length> 0">
                        <div *ngFor="let badge of filterHeader; let i = index" style="display: flex;">
                            <div *ngFor="let filterValue of badge.filterValues; let j = index">
                                <div class="badge" id="badge1{{i+j}}"
                                    *ngIf="badge.filterValues!== [] && badge.filterValues !== null"
                                    [ngStyle]="{'background': badge.origin === 'dataGrid'? '#C5DCFF':'#E3E6F9'}">
                                    <dx-popover class="tooltip" target="#badge1{{i+j}}" position="bottom"
                                        showEvent="mouseenter" hideEvent="mouseleave">
                                        <div class="tooltip-text" *dxTemplate="let data = model of 'content'">
                                            {{badge.caption}}
                                        </div>
                                    </dx-popover>
                                    <div *ngIf="badge.dataType &&
                                            badge.dataType === 'enum'" class="badge-text">

                                        {{convertEnumToKeyValue(filterValue,
                                        badge.enumType,
                                        badge.translateSource)}}</div>

                                    <div *ngIf="!badge.dataType" class="badge-text">{{filterValue}}</div>

                                    <dx-button class="badge-button" (click)="deleteSearchElement(badge, filterValue)">
                                        <ngc-icon size="16" icon="closeRoundedFlat">
                                        </ngc-icon>
                                    </dx-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-divider-GridForForm" *ngIf="isGridForForm"></div>
    </ng-template>

    <ng-container *ngTemplateOutlet="getTemplate('afterTitleBlock'); context: { $implicit: { } }"></ng-container>

    <div class="ngc-generic-datagrid table-container">

        <div class="can-toggle" style="margin : 10px" *ngIf="options.header.toggle.showToggle && !inSlidePanel">
            <input id="mode" type="checkbox" class="toggle-option" (change)="changeMode()">
            <label for="mode">
                <div id="tog" class="can-toggle-switch" [attr.data-checked]="options.header.toggle.secondChoiceText
                            | translate" [attr.data-unchecked]="options.header.toggle.firstChoiceText
                            | translate">
                </div>
            </label>
        </div>
        <div>
            <div class="slide-panel" *ngIf="slidePanelOptions">
                <app-slide-panel [visible]="slidePanelOptions.isSlidePanelOpen"
                    [slidePanelOptions]="slidePanelOptions.isMultiple ? slidePanelOptionsForMultiple : slidePanelOptions"
                    [slidePanelChildOptions]="slidePanelChildOptions" (validateSuccess)="onValidateSuccess($event)"
                    (slidePanelClose)="onSlidePanelClose($event)" *ngIf="slidePanelOptions.isSlidePanelOpen">
                    {{slidePanelTemplateContent ?
                    slidePanelTemplateContent.template : null }}
                </app-slide-panel>
            </div>
            <dx-data-grid [ngClass]="{'editable': editing.allowUpdating, 'dxGrid-ForForm' : isGridForForm}" [dataSource]="gridDataSource"
                [editing]="editing" [cacheEnabled]="cacheEnabled" id="{{ tableStateName }}" 
                [columns]="columns" [export]="export" [(selectedRowKeys)]="selectedRows"
                (onEditorPreparing)="onEditorPreparing($event)" (onRowRemoving)="onRowRemoving($event)"
                (onCellClick)="onRowClick($event)" (onRowPrepared)="onRowPrepared($event)"
                (onToolbarPreparing)="onToolbarPreparing($event)" (onRowUpdating)="onRowUpdating($event)"
                (onRowInserting)="onRowInserting($event)" (onContentReady)="onContentReady($event)"
                (onInitNewRow)="onInitNewRow($event)" (onOptionChanged)="onOptionChanged($event)"
                (onSelectionChanged)="onSelectionChanged($event)" (onEditingStart)="onEditingStart($event)" 
                (onEditCanceled)="onEditCanceled($event)" (onEditCanceling)="onEditCanceling($event)" 
                (onRowUpdated)="onRowUpdated($event)" (onExporting)="onExporting($event)" (onRowValidating)="onRowValidating($event)">

                <dxo-scrolling mode="infinite" *ngIf="isGridForForm && totalCount > 5"></dxo-scrolling>
                <dxo-state-storing [enabled]="true" type="custom" [customLoad]="tableStateLoad"></dxo-state-storing>
                <dxo-filter-row [visible]="headerSearch"></dxo-filter-row>
                <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
                <dxo-selection *ngIf="!isGridForForm" mode="multiple" [deferred]="false" [selectAllMode]="options.selection.mode" 
                    [showCheckBoxesMode]="multipleActionVisibility ? 'always' : 'none'"></dxo-selection>
                <dxo-selection *ngIf="isGridForForm" mode="none"></dxo-selection>

                <div *templateKey="'cellLogoTemplate'; let data">
                    <app-image *ngIf="customFields[data.data.column.dataField]" [type]="customFields[data.data.column.dataField]
                                ?
                                customFields[data.data.column.dataField].type :
                                'rectangle'" [item]="data.data.data"
                        [customField]="customFields[data.data.column.dataField]">
                    </app-image>
                </div>
                <div *templateKey="'buttonEdit'; let itemData">
                    <dx-button [template]="'buttonEdit'" class="buttonEdit" (onClick)="gridForFormEditingRow()"
                        *ngIf="((!isAdding && !isEditing) || !itemData?.data.row.isEditing )&& !dataGridInput.allowModifyItemActions">
                        <div *dxTemplate="let data of 'buttonEdit'">
                            <ngc-icon size="20" icon="modify" class="icon"></ngc-icon>
                        </div>
                    </dx-button>
                </div>
                <span *templateKey="'buttonSave'; let d">
                    <dx-button [template]="'buttonSave'" class="buttonSave" (onClick)="gridForFormSavingData()">
                        <div *dxTemplate="let data of 'buttonSave'">
                            <ngc-icon size="20" icon="save" class="icon"></ngc-icon>
                        </div>
                    </dx-button>
                </span>
                <span *templateKey="'buttonCancel'; let d">
                    <dx-button [template]="'buttonCancel'" class="buttonCancel" (onClick)="gridForFormCancelRow()">
                        <div *dxTemplate="let data of 'buttonCancel'">
                            <ngc-icon size="20" icon="undo" class="icon"></ngc-icon>
                        </div>
                    </dx-button>
                </span>

                <div *templateKey="'actionButtonTemplate'; let
                            itemData">

                    <dx-drop-down-button id="dropdownMore"
                        *ngIf="!isGridForForm && itemActions.isMultiple && !editActionVisible" icon="more"
                        [items]="itemActions.Actions" [disabled]="someActionsIsDisabled(itemData.data.row.data)"
                        displayExpr="name" keyExpr="id" [dropDownOptions]="{ width: 230 }" [showArrowIcon]="false"
                        (onItemClick)="onDropDownGridItemClicked($event)" itemTemplate="dropDownButtonTemplate">
                        <div *dxTemplate="let data of
                                    'dropDownButtonTemplate'" [ngClass]="{'hidden-dropdown-item':
                                    (data.visibleCallback ?
                                    !data.visibleCallback(itemData.data.row.data)
                                    :
                                    false)}" style="padding: 3px;">
                                    <div class="titleItemAction">{{data.title}}</div>
                            <dx-button style="background-color:
                                        transparent;
                                        border-color: transparent;">
                                <ngc-icon [icon]="data.icon" size="20" style="padding-right: 5px;"></ngc-icon>
                                <span style="vertical-align: middle;">{{data.name}}</span>
                            </dx-button>
                        </div>
                    </dx-drop-down-button>
                    <div *ngIf="editActionVisible">
                        <dx-button class="icon-right-modify" type="default" stylingMode="outlined">
                            <ngc-icon size="20" icon="modify" (click)="editButtonClicked()"></ngc-icon>
                        </dx-button>
                    </div>
                    <div *ngIf="isGridForForm">
                        <dx-button style="background-color: transparent; border-color: transparent;"
                            (onClick)="gridForFormEditingRow()"
                            *ngIf="((!isAdding && !isEditing) || !itemData?.data.row.isEditing )&& !dataGridInput.allowModifyItemActions">
                            <ngc-icon size="20" icon="modify"></ngc-icon>
                        </dx-button>
                        <dx-button style="background-color: transparent; border-color: transparent;"
                            (onClick)="gridForFormDeleteRow()"
                            *ngIf="(!isAdding && !isEditing) || !itemData.data.row.isEditing">
                            <ngc-icon size="20" icon="trash"></ngc-icon>
                        </dx-button>
                        <dx-button style="background-color: transparent; border-color: transparent;"
                            (onClick)="gridForFormSaveRow(itemData)"
                            *ngIf="(isAdding || isEditing) && itemData.data.row.isEditing">
                            <ngc-icon size="20" icon="save"></ngc-icon>
                        </dx-button>
                    </div>
                    <dx-button *ngIf="!itemActions.isMultiple">
                        <ngc-icon size="24" icon="Settings" class="icon"></ngc-icon>
                    </dx-button>
                </div>

                <ng-template [ngIf]="templateDirectiveMap">
                    <ng-template ngFor let-tpl [ngForOf]="templateDirectiveMap |
                                keyvalue">
                        <div *dxTemplate="let data of tpl.key">
                            <ng-container *ngTemplateOutlet="getTemplate(tpl.key);
                                        context: { $implicit: { data: data } }">
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-template>

                <div *dxTemplate="let cellData of 'colorCellTemplate'">
                    <dx-color-box [value]="cellData.value" [readOnly]="true"></dx-color-box>
                </div>

                <div *dxTemplate="let cellData of 'switchCellTemplate'">
                    <ngc-switch [value]="cellData.value" [disabled]="true" [dataGridView]="true"></ngc-switch>
                </div>

                <div *dxTemplate="let cellData of
                            'editswitchCellTemplate'">
                    <ngc-switch [value]="cellData.value" (valueChanged)='handleSwitchChanged($event,cellData)'>
                    </ngc-switch>
                </div>

                <div *dxTemplate="let cellData of
                            'dataGridPickerCellTemplate'">
                    {{ cellData?.value ?
                    cellData?.value[cellData.column.editorOptions.displayExpr]
                    :
                    ''}}

                </div>
                <div *dxTemplate="let cellData of
                            'editDataGridPickerCellTemplate'" style="min-height:
                            33px;">
                    <dx-data-grid-picker [formField]="cellData.column"
                        [valueExpr]="cellData.column.editorOptions.valueExpr"
                        [placeholder]="cellData.column.editorOptions.placeholder" [disabled]="false"
                        [value]="cellData.value" [showClearButton]="!cellData.column.isRequired"
                        [fileName]="cellData.column.dataField" [displayExpr]="cellData.column.editorOptions.displayExpr"
                        [searchEnabled]="true" [isValid]="(!cellData.column.errorList ||
                                cellData.column.errorList.isValid)" [visible]="true"
                        (valueChanged)='handleDataGridPickerChanged($event,cellData)'
                        (clearSelected)='handleDataGridPickerClear($event,cellData)'>
                    </dx-data-grid-picker>

                </div>
            </dx-data-grid>

            <div *ngIf="!isGridForForm && (footerInfo?.totalElements>10 && this.slidePanelOptions?.isGrid)">
                <app-data-grid-footer [navigationNextButtonDisabled]="navigationNextButtonDisabled"
                    [currentPage]="currentPage" [numberOfPages]="numberOfPages"
                    [navigationPreviousButtonDisabled]="navigationPreviousButtonDisabled"
                    [footerInfo]="footerInfoForGridFooter" (navigateToNextPage)="onNavigateToNextPage()"
                    (navigateToPreviousPage)="onNavigateToPreviousPage()" (navigateToPage)="onNavigateToPage($event)"
                    (changePageSize)="onChangePageSize($event)" [pageSize]="pageSize">
                </app-data-grid-footer>
            </div>
            <div *ngIf="!isGridForForm && !this.slidePanelOptions?.isGrid">
                <app-data-grid-footer [navigationNextButtonDisabled]="navigationNextButtonDisabled"
                    [currentPage]="currentPage" [numberOfPages]="numberOfPages"
                    [navigationPreviousButtonDisabled]="navigationPreviousButtonDisabled"
                    [footerInfo]="footerInfoForGridFooter" (navigateToNextPage)="onNavigateToNextPage()"
                    (navigateToPreviousPage)="onNavigateToPreviousPage()" (navigateToPage)="onNavigateToPage($event)"
                    (changePageSize)="onChangePageSize($event)" [pageSize]="pageSize">
                </app-data-grid-footer>
            </div>
        </div>

        <app-ngc-grid-state [(columnChooserPopupVisible)]="columnChooserPopupVisible"
            [columnChooserColumn]="columnChooserColumn" [columnChooserSelected]="columnChooserSelected"
            (resetTable)="tableStateReset()" (cancelResetTable)="cancelResetTable()"
            (saveTableState)="tableStateSave($event)"></app-ngc-grid-state>
    </div>
    <div class="GridForForm-diviseur" *ngIf="isGridForForm"></div>
    <dx-button [disabled]="isAdding || isEditing" (click)="onAddbuttonClicked()" *ngIf="isGridForForm"
        class="GridForForm-AddButton">
        <ngc-icon size="20" icon="roundedAdd"></ngc-icon>
        <span class="button-title">{{ "ButtonLabel.NEW" |translate }}</span>
    </dx-button>
</div>
