export * from './Tax';
export * from './TaxToDate';
export * from './TaxFamily';

export enum TaxCalculMode {
    VolumeEffectif,
    VolumeAlccolPure,
    VolumeEffectifSucre
}
export enum TaxType {
  Excise = 1,
  SocialSecurityStamp = 3,
  Sugar = 20,
  Sweetener = 21,
  Energizing = 22,
  SoftDrink = 23,
  Other = 40
}

