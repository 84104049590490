import {
    PackagingFamily
} from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { PackagingImage } from './packaging-images';
import { GammaPackagingType } from '../customs/gamma-tab';

export enum Pictogram { AUTRE, CARTON, PALETTE, ROLL }

export class Packaging extends BaseEntity2 {
    code: string;
    designation: string;
    unloadedWeight: number;
    numberOfLabels: number;
    finalPackaging: boolean;
    visible: boolean;
    pictogram: Pictogram;
    packagingFamily: PackagingFamily;
    packagingImages: PackagingImage[];
    packagingType: GammaPackagingType;

    // Ces données ont été provisoirement mises  en commentaire see: https://projects.zoho.com/portal/lagraineinformatique#taskdetail/588742000003648009/588742000006832055/588742000006941459
    // returnable: boolean;
    // stackable: boolean;
    // maxWeight: number;
    // bulk: number;
    // costPrice: number;
    // equivalenceQuantityTransport: number;
    // hortiTracePackagingCode: string;
    // depositPackaging1: Packaging;
    // depositPackaging1Quantity: number;
    // depositPackaging2: Packaging;
    // depositPackaging2Quantity: number;
    // depositPackaging3: Packaging;
    // depositPackaging3Quantity: number;
    // unitPriceInstruction: number;
    // decliningUnitPrice: number;

    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.unloadedWeight = 0;
        this.numberOfLabels = 0;
        this.finalPackaging = false;
        this.visible = true;
        this.pictogram = Pictogram.AUTRE;
        this.packagingFamily = null;
        this.packagingImages = null;
        this.packagingType = null;

        // Ces données ont été provisoirement mises  en commentaire see: https://projects.zoho.com/portal/lagraineinformatique#taskdetail/588742000003648009/588742000006832055/588742000006941459
        // this.maxWeight = 0;
        // this.returnable = false;
        // this.stackable = false;
        // this.bulk = 0;
        // this.depositPackaging1 = null;
        // this.depositPackaging1Quantity = 0;
        // this.depositPackaging2 = null;
        // this.depositPackaging2Quantity = 0;
        // this.depositPackaging3 = null;
        // this.depositPackaging3Quantity = 0;
        // this.decliningUnitPrice = 0;
        // this.decliningUnitPrice = 0;
        // this.costPrice = 0;
        // this.equivalenceQuantityTransport = 0;
        // this.hortiTracePackagingCode = '';
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 6, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            pictogram: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<Packaging> {
        return {
            visible: true
        };
    }

}
