import { Injectable } from '@angular/core';
import { PackagingArticle, Article } from '@app/shared/model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { IDaoBaseService } from '@app/shared/interface';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/operators';
import { ArticleService } from '../article/article.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlePackagingService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(
        private http: HttpClient, 
        private router: Router, 
        private translate: TranslateService, 
        public articleService: ArticleService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(articleId: number | string): Observable<PackagingArticle[]> {
        const url = this.appConfiguration.UrlsConfig.wsArticlePackaging + 'getByArticle/' + articleId;
        if (!this[articleId]) {
            this[articleId] = this.http.get<PackagingArticle[]>(url)
                .pipe(shareReplay(1));
        }
        return this[articleId];
    }

    /**
    * Create a Article Packaging
    * @param payload request body
    */
    post(payload: PackagingArticle, articleId?: number | string, currentArticle?: Article): Observable<NGCResults<PackagingArticle>> {
        if (currentArticle) {
            payload.article = currentArticle;
        }
        const url = this.appConfiguration.UrlsConfig.wsArticlePackaging;
        return this.http.post<NGCResults<PackagingArticle>>(url, payload).clearListCache(this.clearListTablesCache, articleId);
    }

    /**
    * Update a Article Packaging
    * @param id ArticlePackaging id
    * @param payload ArticlePackaging data
    */
    put(id: number, payload: PackagingArticle, articleId: number | string): Observable<NGCResults<PackagingArticle>> {
        const url = this.appConfiguration.UrlsConfig.wsArticlePackaging + id;
        return this.http.put<NGCResults<PackagingArticle>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (articleId: number | string) => {
        this[articleId] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number, articleId: number | string): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticlePackaging;
        return this.http.delete(url + id);
    }

    navigateToDetail(id: number | string, articleId: string | number, obj: Article) {
    }

    navigateToCreatePage(articleId: string | number, obj: Article): void {
        this.router.navigate(['/article/detail/' + articleId + '/packaging/create']);
    }

    navigateToList(articleId: string | number): void {
        this.router.navigate(['/article/detail/' + articleId + '/packaging/grid']);
    }

}
