import { BaseEntity2 } from 'app/shared/model';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { PredefinedData } from '../common';

export class TVA extends BaseEntity2 {
    code: string;
    prestashopID: number;
    predefinedData: PredefinedData;

    constructor() {
        super();
        this.code = '';
        this.prestashopID = 0;
        this.predefinedData = PredefinedData.READONLY;

    }

    validator(): ValidationRulesByFieldName {
        return {
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 5, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ]
        };
    }
}

