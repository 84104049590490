import { Component, Input } from '@angular/core';
import { AppContextService } from '@app/core/services';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    indicatorUrl: string = './../../../../assets/images/loader.svg';
    @Input() space: string;
    constructor(public appContext: AppContextService) {
    }
}
