import { ParameterCategory, ParameterSubCategory, ParameterValue, ParamScope } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';


export enum ParameterType {
    MULTIPLE_SELECTABLE_VALUES = 0, // Liste déroulante avec sélection
    A_SELECTABLE_VALUE = 1, // Liste déroulante
    FREE_VALUE = 2, // Input
    LONG_FREE_VALUE = 3, // Textarea dans l'IHM au lieu d'un input
    MULTIPLE_FREE_VALUE = 4, // Plusieurs input
    RADIO = 5, // radio bouton
    COLOR = 6, // ColorPicker
    FILE = 7, // Fichier utilisateur, stocké sur serveur dans un dossier dédié, fichier nommé avec l'id du paramètre et l'utilisateur ou la société. Pas besoin de tuple en base normalement (dans ParameterSocieteUser)
    NUMBER = 8,
    EMAIL = 9,
    EMAIL_WITH_ALIAS_MANDATORY = 10, // Alias dans l'exemple est "Test" : Test<test@test.fr>
    HTML = 11, // Générer via éditeur HTML style CKEditor
    PERCENTAGE = 12,
    PHONE = 13, // N° de téléphone
    DATE = 14,
    CHECKBOX = 15,
    RELATION_ID_VALUE = 16, // Data grid picker
    PASSWORD = 17
}

export enum ParameterCode {
    TEST_VALUE = 900001,
    ODYSSEY_WEB_SERVICE_URL = 900004,
    ODYSSEY_LOGIN = 900005,
    ODYSSEY_PASSWORD = 900006,
    ODYSSEY_DOMAIN = 900007,
    PROHIBIT_HIDING_COLUMNS = 900008,
    PROHIBIT_EXPORT_EXCEL = 900009,
    CUSTOMER_REFERENCE = 900010,
    SUPPLIER_REFERENCE = 900011,
    EXERCISE_START_DATE = 900012,
    EXERCISE_END_DATE = 900013,
    INITIALIZATION_RULE_START_DATE_FIRST_ITEM_RATE = 900014,
    USER_DEFAULT_REPOSITORY = 900015, // dépot par défaut de l'utilisateur
    CAN_GET_USER_DEFAULT_REPOSITORY_IN_OPERATION = 900016, // peut utiliser le dépot par défaut de l'utilisateur
    DENY_ADD_OPERATION_ON_BLOCKED_COMPANY = 900017, // la sociéte interdit la création sur client bloquer
    /** param société la modification de la date de livraison modifie la date d'expedition prévue */
    SOCIETY_EXP_DATE_DEPEND_ON_DELEVERY_DATE = 900018,
    /** param société  LegalHolidaysList*/
    LEGAL_HOLIDAYS_LIST = 900019,
    /** param société  Expédition les jours fériés */
    CAN_SHIP_ON_HOLIDAYS = 900020,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_MONDAY = 900021,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_TUESDAY = 900022,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_WEDNESDAY = 900023,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_THURSDAY = 900024,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_FRIDAY = 900025,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_SATURDAY = 900026,
    /** param société est un jour non ouvré pour la société */
    SHIP_ON_SUNDAY = 900027,
    /** param société Calcul du délai entre l'expédition et la livraison uniquement sur les jours ouvrable  anciennement psocietyCalcDeliveryDateByBusinessDays*/
    SOCIETY_CALC_DELIVERY_DATE_BY_BUSINESS_DAYS = 900028,
    /** Param Society règle de calcul date d'échéance: sur date d'expedition (0) ou sur date de facturation (1) anciennement: pDueDateRule */
    DUE_DATE_RULE = 900029,
    /** param société 'StatementNoDay' : Jour du relevé si relevé hebdomadaire : Combo avec jour de la semaine de Lundi à Dimanche avec Lundi=0 anciennement: StatementNoDay */
    STATEMENT_NO_DAY = 900030,
    //
    USER_DEFAULT_WHYFREE_FOR_COMMERCIAL_MANAGEMENT = 900031,
    //
    SOCIETY_DEFAULT_WHYFREE_FOR_COMMERCIAL_MANAGEMENT = 900032,
    //
    USER_DEFAULT_WHYFREE_FOR_PURCHASE = 900186,
    //
    SOCIETY_DEFAULT_WHYFREE_FOR_PURCHASE = 900185,
    // paramètres Opération commerciale
    TYPE = 1,
    LINE_TYPE = 2,
    ITEM_ON_TARIFF_MANDATORY = 3,
    TAKEN_ON_RESERVE = 4,
    TAKE_ON_SCOOP = 5,
    SEARCH_OFFER = 6,
    AUTORISATION_TRANSFORMATION = 7,
    LIST_OF_DESTINATION_TRANSACTION_TYPES = 8,
    OPERATION_TYPE_NEGATIVES = 9,
    SETPOINT_MANAGEMENT = 10,
    PORT_MANAGEMENT = 11,
    DISPLAY_OF_EXISTING_TASKS = 12,
    STOCK_CONTROL_IN_SEIZURE = 13,
    INVENTORY_MANAGEMENT = 100,
    EXPEDITION_MANAGEMENT = 300,
    BILLING_MANAGEMENT = 500,
    ACCOUNTING_INVOICE_NUMBERING = 501,
    COMMISSION_MANAGEMENT = 502,
    ACCOUNTING_MANAGEMENT = 503,

    // paramètres MobileBusiness depuis société

    // Paramètre de recherche article évolué
    ArticleAdvancedSearch = 900033,
    //  Critères de sélection Article pour les recherches sur le N° : Code Barre Article
    ArticleSearchBarCodeArticle = 900034,
    // Critères de sélection Article pour les recherches sur le N° : Code Barre Référencement
    ArticleSearchBarCodeRef = 900035,
    // Critères de sélection Article pour les recherches sur le N° : Code Article
    ArticleSearchCodeArticle = 900036,
    // Critères de sélection Article pour les recherches sur le N° : Code Référencement
    ArticleSearchCodeRef = 900037,
    // Critères de sélection Article pour les recherches sur le N° : N°Article
    ArticleSearchNoArticle = 900038,
    // Interdire les articles non tarifés
    BanArtNotTarif = 900039,
    // Affichage en saisie de commande (Concaténation des critères des articles)
    ConcatenationBase = 900040,
    // Formule utilisée pour le calcul de marge
    Const_FormulMargeTotalSell = 900041,
    // Contrôle du prix de vente
    CtrlSellPriceActive = 900042,
    // Utiliser le tarif de remplacement si besoin
    CtrlSellPriceApplyReplacementCost = 900043,
    // Pourcentage marge minimum
    CtrlSellPriceCoefReference = 900044,
    // Motif de contrôle des prix par défaut
    CtrlSellPriceDefaultMotive = 900045,
    // N° tarif
    CtrlSellPriceNoCostNumber = 900046,
    // Base de calcul du prix de ref marge
    CtrlSellPricePriceType = 900047,
    // Gestion par société auxiliaire
    CtrlSellPriceSocAuxActive = 900048,
    // Devise de base
    CurrencyBase = 900049,
    // Client de référence
    CustomerReference = 900050,
    // Mode de calcul des consignes
    DepositTypeCalc = 900051,
    // Valorisation des consignes
    DepositValorized = 900052,
    // Caractère FN1 des EAN128
    EAN128FN1 = 900053,
    // Modification date d'expédition prévue si modification de la date de livraison
    ExpDateDependOnDeleveryDate = 900054,
    // Jours d'expédition : Vendredi
    ExpOnFriday = 900055,
    // Expédition les jours fériés
    ExpOnHolidays = 900056,
    // Jours d'expédition : Lundi
    ExpOnMonday = 900057,
    // Jours d'expédition : Samedi
    ExpOnSaturday = 900058,
    // Jours d'expédition : Dimanche
    ExpOnSunday = 900059,
    // Jours d'expédition : Jeudi
    ExpOnThursday = 900060,
    // Jours d'expédition : Mardi
    ExpOnTuesday = 900061,
    // Jours d'expédition : Mercredi
    ExpOnWednesday = 900062,
    // Règle sur dépassement d'encours
    ExposureOvertakingRule = 900063,
    // Nb points nécessaire pour 1 Euro de chèque cadeau
    FidelityCard_ConvertCoef = 900066,
    // Prendre en compte les points de fidélité acquis sur la commande en cours
    FidelityCardAvailableOnCurrentOrder = 900065,
    // Règle sur les taxes et royalties
    FreeRule = 900066,
    // Login x-on-line
    HortiOnLineLogin = 900067,
    // Mot de passe x-on-line
    HortiOnLinePass = 900068,
    // Url x-on-line
    HortiOnLineUrl = 900069,
    // Gestion de frais de facturation
    InvoiceChargesManagement = 900070,
    // Règle sur le prix étiquette
    LabelPriceRule = 900071,
    // Liste des jours fériés
    LegalHolidaysList = 900072,
    // Gestion du fichier flag
    ManageFlagFile = 900073,
    // Tâches (messages) gérées
    MessageBase = 900074,
    // Export des mouvements de consignes dans les commandes (0/1)
    MobilBusiness_ExportMvtDeposit = 900075,
    // Mobil Customer : Mode de règlements proposés
    MobilCustomerListCodePayment = 900076,
    // Mobil Delivery : Gestion des clés à emporter
    MobilDelivery_FieldnameKey = 900077,
    // Mobil Delivery : Mode de règlements proposés
    MobilDeliveryListCodePayment = 900078,
    // Mobil Delivery : Ordre des Cdes dans tournées
    MobilDeliverySortOrder = 900079,
    // Mobil Order : Calcul des tarifs selon le type d'offre/réservation
    MobilOrder_CalTarifOnEstimate = 900080,
    // Mobil Order : Recherche des articles évolués dans les commandes
    MobilOrder_SearchArticleExtendedInOrder = 900081,
    // Mobil Order : Type de cumul de stock
    MobilOrderTypeCumulStock = 900082,
    // Mobil Store : Code de paiement de type espèce
    MobilStoreCodePaymentEsp = 900083,
    // Mobil Store : Code de paiement de type fidélité
    MobilStoreCodePaymentFidelity = 900084,
    // Mobil Store : Emballage final par défaut
    MobilStoreDefaultEmbFinalForTicket = 900085,
    // Mobil Store : Mode de réglements proposés
    MobilStoreListCodePayment = 900086,
    // Mobil Stat : Priorité au dépôt du vendeur
    MobilStoreUserDepotForTicket = 900087,
    // Mobil Stat : Mode calcul marge
    MStat_CalcMarge = 900088,
    // Mobil Stat : Nombre de période
    MStat_PeriodNumber = 900089,
    // Mobil Stat : Type de période
    MStat_PeriodType = 900090,
    // Message (tâche) à afficher à la connexion
    NatureTask_DisplayAtConnect = 900091,
    // Key SumUp (terminal de paiement)
    ND2AccesKeySumUp = 900092,
    // Utiliser les tarifs évolués
    OptionOtherCost = 900093,
    // Déporter les remises personnalisées sur la remise ligne
    PersonalDiscountOnDiscountLine = 900094,
    // Colonne de référence pour le calcul des prix
    PriceColumnReference = 900095,
    // Nombre de décimales pour le calcul des prix unitaires
    PriceDecimalCount = 900096,
    // Base de calcul du prix étiquette
    PriceLabelBase = 900097,
    // Prospect de référence
    ProspectReference = 900098,
    // Nbre de période
    SellNbPeriod = 900099,
    // Type de période
    SellTypeOfPeriod = 900100,
    // Analyser le double des périodes pour alertes
    TeleventelAnalyzeTwicePeriodSell = 900101,
    // Télévente gérée par société
    TeleVenteManagedBySociety = 900102,
    // Gestion de la campagne
    UseCampaignMode = 900103,
    // Utiliser les N° Sociétés dans l'application des tarifs personnalisés Vente
    UseNoSocAuxInPersonalCostAplicationSell = 900104,
    // Utiliser les tarifs généraux uniquement
    UseSimpleCost = 900105,
    // Motif de gratuité par défaut
    XLPosDefaultWhyFree = 900106,

    // Paramètres MobilBusiness depuis l’utilisateur :
    // Colonne sélectionnée par défaut dans la fenêtre de choix Articles/Lots
    ArticleLotOrderChoiceSelectedColumn = 900107,
    // Calcul du délai entre l'expédition et la livraison uniquement sur les jours ouvrables
    CalcDeliveryDateByBusinessDays = 900108,
    // Interdire la modification de la désignation article
    CanNotModifyArtDesign = 900109,
    // Motif de contrôle des prix lors de l'allotement
    CtrlSellPriceDefaultMotiveAllot = 900110,
    // Règle sur le contrôle du tarif de vente
    CtrlSellPriceRule = 900111,
    // No client de référence pour le règlement
    CustomerReferenceReglement = 900112,
    // Dépôt/Site par défaut
    DefaultDepot = 900113,
    // Interdire le choix de client si interdit
    InterdireClient = 900114,
    // Afficher le rappel des tâches de l'utilisateur à l'ouverture de session
    IsEnableUserTaskRecall = 900115,
    // Fiches Correspondants en lecture seule
    IsNotAllowedToEditCorres = 900116,
    // Fiches Clients en lecture seule
    IsNotAllowedToEditCustomers = 900117,
    // Mobil Order : Remise autorisée
    MobilOrder_AuthorizedDiscount = 900118,
    // Mobil Order : Mise à jour du tarif autorisée
    MobilOrder_AuthorizedUpdateTarif = 900119,
    // Mobil Order : Mise à jour du code de representant autorisée
    MobilOrder_AutorizedUpdateCodeRepOrder = 900120,
    // Mobil Order : Calcul du tarif pour les articles hors cadencier
    MobilOrder_CalcTarif = 900121,
    // Mobil Order : Impression de ticket
    MobilOrder_UsePrinter = 900122,
    // Mobil Contact : Création/modification client en mode simplifié par défaut
    MobilContact_ManageCustomerSimplify = 900123,
    // Fiche Article : Masquer l'onglet Critères
    ND2Article_HideCriteria = 900124,
    // Fiche Article : Masquer l'onglet Général
    ND2Article_HideGeneral = 900125,
    // Fiche Article : Masquer l'onglet Emballages logisitique
    ND2Article_HidePackage = 900126,
    // Fiche Article : Masquer l'onglet Fiche technique
    ND2Article_HideTechnical = 900127,
    // Fiche Client : Masquer l'onglet Compléments
    ND2Customer_HideComplement = 900128,
    // Fiche Client : Masquer l'onglet Correspondants
    ND2Customer_HideCorres = 900129,
    // Fiche Client : Masquer l'onglet Livraison logistique
    ND2Customer_HideDelivery = 900130,
    // Fiche Client : Masquer l'onglet Général
    ND2Customer_HideGeneral = 900131,
    // Fiche Client : Masquer l'onglet Tarification-Facturation
    ND2Customer_HideTarif = 900132,
    // Société de sélection des Clients
    NoSocieteAuxilliaireCustomer = 900133,
    // N'autoriser que la saisie d'emballages de l'article sur les lignes
    OnlyPackageArticleOrderLine = 900134,
    // Interdire le passage de Prospects en Clients
    ProspectForbiddenAccess = 900135,
    // Recherche sur référencement en saisie vente
    SearchOnReferencing = 900136,
    // Recherche uniquement sur le référencement de la commande en saisie vente
    SearchOnReferencingOrderSell = 900137,
    // Mode restrictif (uniquement articles référencés) sur vente
    SearchOnReferencingRestrict = 900138,
    // Sélectionner les clients liés dans la fenêtre de choix Client
    SelectLinkedCustomer = 900139,
    // Ne pas récupérer le dépot de l'utilisateur dans le site de la commande
    StockNotRetrieveUserDepotInOrder = 900140,
    // Identification : Email
    UserAccountEmail = 900141,
    // Identification : Facebook
    UserAccountFacebook = 900142,
    // Identification : Fax
    UserAccountFax = 900143,
    // Identification : N° téléphone portable
    UserAccountMobilPhone = 900144,
    // Identification : N° téléphone portable 1
    UserAccountPhone1 = 900145,
    // Identification : N° téléphone portable 2
    UserAccountPhone2 = 900146,
    // Identification : Twitter
    UserAccountTwitter = 900147,
    // Identification : Site internet
    UserAccountWebSite = 900148,
    // Mobil Order : Afficher tous les articles
    MobilOrder_DisplayAllArticles = 900149,
    // N° de la société auxiliaire
    NoSocieteAuxilliaire = 900150,
    // Mode d'affectation de la société sur les opérations
    SocieteAuxilliaireAffectationMode = 900151,
    // Société auxiliaire verrouillée
    SocieteAuxilliaireVerouillee = 900152,
    // Mobil Customer : Dernier N° Client utilisé sur la tablette
    MobilCustomer_IDCustomer = 900153,
    // Mobil Order : Dernier N°facture utilisé sur la tablette
    MobilOrder_NumInvoice_NumSociete = 900154,
    // Mobil Order : Dernier N°de commande utilisé sur la tablette
    MobilOrder_NumOrder_NumSociete = 900155,
    // Mobil Order : Dernier N°session de caisse utilisé sur la tablette
    MobilOrder_NumStoreState_NumSociete = 900156,
    //  Adresse ftpUtilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Address = 900157,
    //  Port ftp Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Port = 900158,
    //  ftp mode  (A ou P )Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Mode = 900159,
    //  Utilisateur ftp Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_User = 900160,
    //  Mot de passe ftp Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Password = 900161,
    //  Répertoire ftp export Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Export_Path = 900162,
    //  Répertoire ftp import Utilisé le transfert ftp envoi et réception
    MobilBusiness_FTP_Import_Path = 900163,
    //  Choix ftp
    FtpChoice = 900164,
    // Client (customer) d'import par défaut
    CUSTOMER_IMPORT_REFERENCE = 900167,
    // Client (supplier) d'import par défaut
    SUPPLIER_IMPORT_REFERENCE = 900168,
    // Article d'import par défaut
    ARTICLE_IMPORT_REFERENCE = 900169,
    // paramètres envoie mail
    parameterSendmail = 900170,
    // port smtp
    SMTP_Port = 900171,
    // server smtp
    SMTP_Server = 900172,
    // méthode d'authentification smtp
    SMTP_Authentification = 900173,
    // login smtp
    SMTP_Login = 900174,
    // mot de passe smtp
    SMTP_Password = 900175,
    // type de sécurité smtp
    SMTP_Security_Type = 900176,
    //adresse mail expéditeur
    mailSender = 900177,
    /**param société sur le gestion des droits gratuits */
    FREE_ARTICLE_RULE_FOR_COMMERCIAL_MANAGEMENT = 900178,
    //
    FREE_ARTICLE_RULE_FOR_PURCHASE = 900182,
    //

    // Paramètres DRM IDRegiFlow
    urlDRMIDRegiFlow = 900192,
    apiKeyDRMIDRegiFlow = 900193,
    bddDRMIDRegiFlow = 900194,

    // Paramètres Gamma IDRegiFlow
    urlGammaIDRegiFlow = 900195,
    apiKeyGammaIDRegiFlow = 900196,
    bddGammaIDRegiFlow = 900197,

    // Paramètres Concerto IDRegiFlow
    urlConcertoIDRegiFlow = 900198,
    apiKeyConcertoIDRegiFlow = 900199,
    bddConcertoIDRegiFlow = 900200,

    // Paramétrage des types d'opération commerciales Vente
    codeMouvementStock = 900201,
    codeMouvementStockVenteRetour = 900202,
    codeMouvementStockVenteTicket = 900203,

    // Paramétrage des types d'opération commerciales Achat
    codeMouvementStockAchat = 900204,
    codeMouvementStockAchatRetour = 900205,

    // Paramètres date limite initialisation
    deadLineInit = 900206,
}

export enum INVOICE_VALIDATION {
    BILLABLE,
    NOT_BILLABLE,
    COMMERCIAL_OPERATION_NOT_SHIPPED,
    NOT_SHIPPABLE
}

export enum PARAMETER_DEFAULT_VALUE {
    NO,
    YES,
    POSITIVE,
    NEGATIVE,
    PROHIBITED,
    REQUIRED,
    ACCEPTED
}

export class Parameter extends BaseEntity2 {
    category: ParameterCategory;
    subCategory: ParameterSubCategory;
    defaultParameterValue: ParameterValue;
    listParameterValues: ParameterValue[];

    name: string;
    explanation: string;
    order: number;
    type: ParameterType;
    defaultValue: string;
    scope: ParamScope;
    applicationMode: number;
    code: ParameterCode;

    constructor() {
        super();
        this.category = null;
        this.subCategory = null;
        this.defaultParameterValue = null;
        this.listParameterValues = null;
        this.name = '';
        this.explanation = '';
        this.order = 0;
        this.type = null;
        this.defaultValue = '';
        this.scope = ParamScope.SOCIETY_AND_USER;
        this.applicationMode = 0;
        this.code = ParameterCode.TEST_VALUE;
    }

    public static getType(value: ParameterType): string {
        const wording = '';
        console.log(value);
        // a implementer
        /*if (value === UserType.SUPER_ADMINISTRATOR) {
            wording = 'Super administrateur';
        } else if (value === UserType.ADMINISTRATOR) {
            wording = 'Administrateur';
        } else if (value === UserType.USER) {
            wording = 'Utilisateur';
        }*/

        return wording;
    }
}
