import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { HeaderInfo, HeaderMode } from '@app/shared/component/left-header-bloc/models';
import { NextDxFormOptions } from '../dx-form/models';
import * as $ from 'jquery';
import { confirmDialog, CustomConfirmDialogResult } from '@app/utils/dialogs';
@Component({
    selector: 'app-dx-form-header',
    templateUrl: './dx-form-header.component.html',
    styleUrls: ['./dx-form-header.component.scss']
})

export class DxFormHeaderComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input() headerLeftBlocTemplate: any;
    @Input() item: any;
    @Input() inPopup: boolean;
    @Input() itemLoading: boolean;
    @Input() headerInfo: HeaderInfo;
    @Input() id: any;
    @Input() idPrevious: any;
    @Input() idNext: any;
    @Input() options?: NextDxFormOptions;
    @Input() validForm: boolean;
    @Input() tabs: { text: string, visibleIndex: number, indexBlock: number }[];
    @Input() loadDataType: 'serverSide' | 'templateSide' | 'simple' = 'templateSide';
    @Input() isEditable: boolean;
    @Input() pendingSubmit: boolean;
    @Input() formCanDeactivate: boolean;
    @Input() returnButton: string;
    @Input() breadcrumb: any[];
    @Input() dropdownItems: Array<any> = [];
    @Input() isDuplication: boolean;

    @Output() showTabsFunction: EventEmitter<void> = new EventEmitter();
    @Output() navigateToDetail: EventEmitter<any> = new EventEmitter();
    @Output() create: EventEmitter<void> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() dropDownClick: EventEmitter<any> = new EventEmitter();
    @Output() lockOperation: EventEmitter<boolean> = new EventEmitter<boolean>();

    leftBlockSize: number = 188;
    HeaderMode = HeaderMode;
    btnVisible: Array<any> = [];
    isDropDownBoxOpened: Boolean = false;
    toolbarItems: any[] = [];

    constructor(private _translate: TranslateService,
        private cdRef: ChangeDetectorRef,
        private router: Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dropdownItems && changes.dropdownItems.currentValue) {
            this.toolbarItems = [];
            this.buildDropDown();
        }
    }

    ngOnInit() {
        if (this.headerInfo && this.headerInfo.parent && this.headerInfo.parent.toolbarItems) {
            for (let i = 0; i != this.headerInfo.parent.toolbarItems.length; i++) {
                this.btnVisible.push(false);
            }
        }
    }

    ngAfterViewChecked(): void {
        this.leftBlockSize = $('.right-bloc').width();
        this.cdRef.detectChanges();
    }

    changeDropDownBoxValue(args) {
        this.isDropDownBoxOpened = false;
    }

    ReturnButtonPath() {
        return this.router.navigate([this.returnButton]);
    }

    buildDropDown() {
        this.toolbarItems.push(...this.dropdownItems);
        if (this.id && this.options.delete.showHeaderButton && !this.isDuplication) {
            this.toolbarItems.push({
                name: this._translate.instant('form.Remove'),
                value: 'DELETE',
                disabledCallback: this.options.delete.disabled,
                icon: 'trash',
                hint: 'form.Remove'
            });
        }
        if (this.options.header.showContextualHelpButton) {
            this.toolbarItems.push({
                name: this._translate.instant('form.ShowContextualHelp'),
                value: 'CONTEXUTAL_HELP',
                icon: 'info',
            });
        }
    }

    isDisabled(e): boolean {
        if (e === undefined || e === null) { return false; }
        if ('boolean' === typeof e) {
            return e;
        } else if ('function' === typeof e) {
            return e(this.item);
        } else {
            throw new Error('what did you put as options.delete.disabled input ??');
        }
    }

    onDropDownClick(data) {
        this.dropDownClick.emit(data);
    }

    onShowTabsFunction() {
        this.showTabsFunction.emit();
    }

    onNavigateToDetail(id: any) {
        this.navigateToDetail.emit(id);
    }

    onCreate() {
        this.create.emit();
    }

    onLockOperation() {
        this.lockOperation.emit(true);
    }

    onSave($event: any, method: string) {
        this.save.emit({ event: $event, method });
    }

    onUpdate(event: any) {
        this.update.emit(event)
    }

    onValidate($event: any, method: string) {
        if (this.options.edit.onValidateDialogTexts) {
            const dialogOnValidate = confirmDialog(this.options.edit.onValidateDialogTexts[0], this.options.edit.onValidateDialogTexts[1],
                this.options.edit.onValidateDialogTexts[2], this.options.edit.onValidateDialogTexts[3]);
            dialogOnValidate.show()
                .then((dialogResult: CustomConfirmDialogResult) => {
                    switch (dialogResult) {
                        case CustomConfirmDialogResult.YES:
                            this.onSave($event, method);
                            break;
                        default:
                            break;
                    }
                });
        } else {
            this.onSave($event, method);
        }
    }

    onHoverBtnVisible(i: number) {
        this.btnVisible[i] = true;
    }
    onLeaveBtnVisible(i: number) {
        this.btnVisible[i] = false;
    }
    valueOfThisIcon(index: number) {
        return this.headerInfo.parent.toolbarItems[index].name;
    }
}
