import { User, Parameter, ParameterValue } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';


export class ParameterSelected extends BaseEntity2 {
    user: User;
    parameter: Parameter;
    parameterValue: ParameterValue;
    value: string;
    constructor() {
        super();
        this.user = null;
        this.parameter = null;
        this.parameterValue = null;
        this.value = '';
    }
}
