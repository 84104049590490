import { Component, OnInit, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { DxTreeViewComponent } from 'devextreme-angular';
import { filter, map } from 'rxjs/operators';

import { User, UserType } from '@app/shared/model/user';
import { ArticleService, AuthService, SidebarService } from '@app/core/services';
import { notifyErrorDelay } from '@app/globalsParameter';
import { IMenuItem, AllMenuItems, ISubMenuItem } from './navigation-menu';
import { ApplicationMode } from '@app/shared/model';

@Component({
    selector: 'ngc-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild(DxTreeViewComponent, { static: false }) private dxTreeViewMenu: DxTreeViewComponent;

    public currentUser: User;
    /** the local copy of the menu */
    public menuContent = [];
    private subscribers: Subscription[] = [];
    errorRegi = false;
    applicationMode: ApplicationMode;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private location: Location,
        public sidebarService: SidebarService,
        private articleService: ArticleService
    ) {
    }

    ngOnInit(): void {
        this.subscribers.push(
            this.authService.userBehaviourSubject.subscribe(
                (user: User) => {
                    if (user) {
                        this.currentUser = user;
                        this.InitMenu();
                        if (user.type != UserType.SUPER_ADMINISTRATOR){
                            this.applicationMode = user.societies[0].applicationMode;   
                            if(this.applicationMode === ApplicationMode.VITI){
                                this.subscribers.push(
                                    this.articleService.errorRegi().subscribe(error => {                                   
                                        this.errorRegi = error;                         
                                        if(this.errorRegi)
                                            this.InitMenu();
                                    })
                                );
                            }
                        }
                    }
                },
                (error) => notify(error.error, 'error', notifyErrorDelay)
            )
        );
        this.subscribers.push(
            this.sidebarService.openedState$.subscribe(
                (evt: boolean) => {
                    if (!evt) {
                        this.dxTreeViewMenu.instance.collapseAll();
                    }
                }
            )
        );
        this.subscribers.push(
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) { route = route.firstChild; }
                    return route;
                }),
                filter((route) => route.outlet === 'primary')
            ).subscribe(
                () => {
                    this.initSelectedRoute();
                }
            ),
        );
    }

    ngOnChanges(): void {
        return
    }

    ngOnDestroy(): void {
        this.subscribers.forEach(subsc => subsc.unsubscribe());
    }
    //removed unused
    // locationContains(): boolean {
    //     return this.location.path().indexOf('/admin/') > -1;
    // }

    private getSelectedFirstDegreeMenu(currentPath: string): IMenuItem {
        const menuItem = this.menuContent.find((item: IMenuItem) => {
            // search in menu
            if (this.shouldBeSelected(currentPath, item)) {
                return true
            }
            // search in subMenu
            if (item.items) {
                return item.items.some((subItem: ISubMenuItem) => {
                    if (this.shouldBeSelected(currentPath, subItem)) {
                        return true;
                        //search subsubitem
                    } else if (subItem.items) {
                        return subItem.items.some(subsubitem => this.shouldBeSelected(currentPath, subsubitem));
                    } else {
                        return false;
                    }
                });
            } else {
                return false;
            }
        });
        return menuItem;
    }

    private shouldBeSelected(currentPath, subItem): boolean {
        let excludedItem = false;
        let foundItem = false;
        if (subItem.excludedSelectionPrefix && subItem.excludedSelectionPrefix.length) {
            for (let index = 0; index < subItem.excludedSelectionPrefix.length; index++) {
                const element = subItem.excludedSelectionPrefix[index];
                if (currentPath.indexOf(element) > -1) {
                    excludedItem = true;
                    break;
                }
            }
        }
        if (!excludedItem) {
            if (subItem.selectionPrefix && subItem.selectionPrefix.length) {
                for (let index = 0; index < subItem.selectionPrefix.length; index++) {
                    const element = subItem.selectionPrefix[index];
                    if (currentPath.indexOf(element) > -1) {
                        foundItem = true;
                        break;
                    }
                }
            }
        }
        return !excludedItem && foundItem;
    }

    initSelectedRoute(): void {
        const currentRoutePath = this.location.path();
        const menuItem = this.getSelectedFirstDegreeMenu(currentRoutePath);
        if (menuItem && currentRoutePath) {
            // collapse all others expanded items
            const expandedItems = this.menuContent.filter(ex => ex.expanded);
            expandedItems.forEach(val => {
                if (val.identifier !== menuItem.identifier) {
                    this.dxTreeViewMenu.instance.collapseItem(val.identifier);
                }
            });
            // expand and select if leaf in items
            this.expandAndSelectInSubMenu(menuItem, currentRoutePath);
        } else if (currentRoutePath === '') {
            this.dxTreeViewMenu.instance.unselectAll();
            // this.dxTreeViewMenu.instance.collapseAll();
        } else {
            this.dxTreeViewMenu.instance.unselectAll();
        }
    }

    /**
     *
     * @param parentMenuItem
     * @param currentRoutePath
     */
    private expandAndSelectInSubMenu(parentMenuItem, currentRoutePath) {
        // if not direct link
        if (parentMenuItem.items && parentMenuItem.items.length) {
            //find the selected item
            const menuItem = parentMenuItem.items.find((subItem: any) => this.shouldBeSelected(currentRoutePath, subItem));
            // expand parent menu if not expanded
            if (!parentMenuItem.expanded) {
                this.dxTreeViewMenu.instance.expandItem(parentMenuItem.identifier);
            }
            if (parentMenuItem.items && parentMenuItem.items.length) {
                // collapse all others expanded items
                const expandedItems = parentMenuItem.items.filter(ex => ex.expanded);
                expandedItems.forEach(val => {
                    if (val.identifier !== menuItem.identifier) {
                        this.dxTreeViewMenu.instance.collapseItem(val.identifier);
                    }
                });
            }
            this.expandAndSelectInSubMenu(menuItem, currentRoutePath);
        } else {
            // this.dxTreeViewMenu.instance.collapseAll();
            this.dxTreeViewMenu.instance.selectItem(parentMenuItem.identifier);
        }
    }

    private runIconCallback(item: any): string {
        if (item.icon instanceof Function) {
            return item.icon(this.currentUser.societies[0].applicationMode);
        } else {
            return item.icon;
        }
    }

    private runIconAfterCallback(item: IMenuItem): string {
        if (item.iconAfter instanceof Function) {
            if (item.path == '/article') {
                return item.iconAfter(this.currentUser.societies[0].applicationMode, this.errorRegi);
            } else {
                return item.iconAfter(this.currentUser.societies[0].applicationMode);
            }
        } else {
            return item.iconAfter;
        }
    }

    private runTextCallback(item: any): string {
        if (item.text instanceof Function) {
            return item.text(this.currentUser.societies[0].applicationMode);
        } else {
            return item.text;
        }
    }

    onTreeViewInitialized(event): void {
        this.initSelectedRoute();
    }

    InitMenu(): void {
        this.menuContent = [];
        AllMenuItems.forEach((itemOrigin: IMenuItem, index: number) => {
            const item = Object.assign({}, itemOrigin);
            item.identifier = (index + 1).toString();
            if (this.canDisplayMenuItem(item)) {
                if (item.items) {
                    // copy item ?? why
                    item.items = [];
                    itemOrigin.items.forEach((menuItem: ISubMenuItem) => {
                        item.items.push(Object.assign({}, menuItem));
                    });
                    // recursively called
                    this.filterMenuItems(item, item.items);
                }
                item.text = this.translate.instant(item.text);
                item.icon = this.runIconCallback(item);
                item.iconAfter = this.runIconAfterCallback(item);
                this.menuContent.push(item);
            }
        });
    }

    /**
     *
     * @param item menu item
     * @returns true if user have permission to view item
     */
    private canDisplayMenuItem(item): boolean {
        return (item.display && item.display.length > 0 && (this.currentUser && item.display.indexOf(this.currentUser.type) > -1)) || !item.display;
    }


    filterMenuItems(item, items) {
        items = items.filter((subItem: ISubMenuItem) => this.canDisplayMenuItem(subItem));
        items.map((value: ISubMenuItem, subIndex: number) => {
            value.text = this.translate.instant(this.runTextCallback(value));
            value.icon = this.runIconCallback(value);
            value.identifier = item.identifier + (subIndex + 1);
            if (value.items) {
                this.filterMenuItems(value, value.items)
            }
            return value;
        });
    }

    onItemClick(event) {
        this.sidebarService.opened = true;
        this.sidebarService._openedState.next(true);
        if (!event.itemData.path) {
            event.event.preventDefault();
        } else {
            this.router.navigate([event.itemData.path]);
        }
    }

    /**
     *
     * - si on ouvre un niveau 1 , on ferme tous les autres niveaux 1
     *   - dans un niveau 1 , si on ouvre un niveau 2 on fermes les autres niveaux 2 deja ouverts
     * @param event
     */
    onItemExpanded(event) {
        this.menuContent.forEach(item => {
            if (event.itemData.identifier.length === 1 && item.identifier != event.itemData.identifier && item.expanded) {
                this.dxTreeViewMenu.instance.collapseItem(item.identifier);
            }
            if (item.items) {
                item.items.forEach(subItem => {
                    if (subItem.identifier != event.itemData.identifier && subItem.expanded) {
                        this.dxTreeViewMenu.instance.collapseItem(subItem.identifier);
                    }
                });
            }
        })
    }
}
