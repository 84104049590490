import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ParamScope } from '.';
export enum ParameterCategoryCode {
    GENERAL = 0,
    CUSTOMER_PARAMETERS = 1,
    SUPPLIER_PARAMETERS = 2,
    EXERCISE = 3,
    INIT_RULES = 4,
    OPERATION_COMMANDE_PARAMS = 5,
    OPERATION_OFFER_PARAMS = 6,
    OPERATION_RETOUR_CLIENT_PARAMS = 7,
    STOCK = 8,
    COMMANDE = 9,
    COMMERCIAL_MANAGEMENT = 10,
    MOBILE_BUSINESS = 11,
    ARTICLE_PARAMETERS = 12,
    PURSHASE = 13,
    OPERATION_RETOUR_FOURNISSEUR_PARAMS = 14,
    DRM_IDREGIFLOW_PARAMETERS = 17,
    GAMMA_IDREGIFLOW_PARAMETERS = 18,
    CONCERTO_IDREGIFLOW_PARAMETERS = 19
}

export class ParameterCategory extends BaseEntity2 {
    name: string;
    explanation: string;
    order: number;
    scope: ParamScope;
    applicationMode: number;
    code: ParameterCategoryCode;
    constructor() {
        super();
        this.name = '';
        this.explanation = '';
        this.scope = ParamScope.SOCIETY_AND_USER;
        this.order = 0;
        this.applicationMode = 0;
        this.code = null;
    }
}
