import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { CompanyType, GenericCriteria, GenericCriteriaValue } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { TranslateService } from '@ngx-translate/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyCriteriaValueService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration) {
            super(http, _translate, appConfiguration);
         }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(companyType: CompanyType, id: number): Observable<GenericCriteriaValue[]> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteria + companyType;
        if (!this[companyType + '' + id]) {
            this[companyType + '' + id] = this.http.get<GenericCriteria[]>(url)
                .pipe(
                    map((criteriaArray: GenericCriteria[]) => {
                        const criteriaValues = criteriaArray.find((criteria: GenericCriteria) => criteria.id === id).criteriaValues;
                        return criteriaValues.map((value: GenericCriteriaValue) => {
                            value.criteriaId = id;
                            return value;
                        });
                    }),
                    shareReplay(1)
                );
        }
        return this[companyType + '' + id];
    }

    post(payload: any, companyType: CompanyType, id: number): Observable<NGCResults<GenericCriteriaValue>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteriaValue + companyType;
        return this.http.post<NGCResults<GenericCriteriaValue>>(url, payload).clearListCache(this.clearListTablesCache, companyType, id);
    }

    put(code: string, payload: any, companyType: CompanyType, id: number): Observable<NGCResults<GenericCriteriaValue>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteriaValue + companyType + '/' + code;
        return this.http.put<NGCResults<GenericCriteriaValue>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType, id) => {
        this[companyType + '' + id] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any, companyType: CompanyType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompanyCriteriaValue + companyType + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: string | number, companyType: CompanyType, idCriteria: number) {
        this.router.navigate([ '/parameters/' + companyType + '/criteria/' + idCriteria + '/values/detail', id]);
    }

    navigateToCreatePage( companyType: CompanyType, idCriteria: number): void {
        this.router.navigate([ '/parameters/' + companyType + '/criteria/' + idCriteria + '/values/create']);
    }

    navigateToList( companyType: CompanyType, idCriteria: number): void {
        this.router.navigate([ '/parameters/' + companyType + '/criteria/' + idCriteria + '/values']);
    }

}
