import { Component, OnInit, Input } from '@angular/core';
import { SVGElem } from './SVGElement';
import { ngcIconsDifferentSizes } from 'src/assets/icons/iconsDifferentSizes';
import { ngcIcons16 } from 'src/assets/icons/icons16';
import { ngcIcons20 } from 'src/assets/icons/icons20';
import { ngcIcons24 } from 'src/assets/icons/icons24';
import { ngcIcons32 } from 'src/assets/icons/icons32';
@Component({
    selector: 'ngc-icon',
    templateUrl: './ngc-icon.component.svg',
    styleUrls: ['./ngc-icon.component.scss']
})
export class NgcIconComponent implements OnInit {
    // eslint-disable-next-line max-len
    @Input() size: string;
    @Input() height: string;
    @Input() set icon(icon: string | SVGElem[]) {
        if (!this.size || this.size == null) {
            throw new Error('set size before icon name');
        } else {
            // get list of icons whith specified size
            let ngcIcons = {};
            switch (this.size) {
                case '16':
                    ngcIcons = ngcIcons16;
                    break;
                case '20':
                    ngcIcons = ngcIcons20;
                    break;
                case '24':
                    ngcIcons = ngcIcons24;
                    break;
                case '32':
                    ngcIcons = ngcIcons32;
                    break;
                default:
                    ngcIcons = ngcIconsDifferentSizes;
                    this.differentSize = true;
                    break;
            }

            if (this.differentSize && Array.isArray(ngcIcons[icon + 'Icon' + this.size])) {
                this.finalIcon = ngcIcons[icon + 'Icon' + this.size];
            } else if (Array.isArray(ngcIcons[icon as string])) {
                this.finalIcon = ngcIcons[icon as string];
            } else {
                if (ngcIcons[icon as string]) {
                    this.finalIcon = [];
                    const path = new SVGElem();
                    path.type = 'path';
                    path.d = ngcIcons[icon as string];
                    this.finalIcon.push(path);
                } else {
                    this.finalIcon = [];
                }
            }
        }
    }
    @Input() svgClasses = "";
    viewbox: string;
    finalIcon: SVGElem[] = [];
    differentSize = false;

    ngOnInit(): void {
        this.viewbox = '0 0 ' + this.size + ' ' + this.size;
    }
}

