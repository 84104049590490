<dx-drawer [opened]="sidebarService.opened" position="left" [maxSize]="248" [minSize]="sidebarService.minSize"
    [closeOnOutsideClick]="sidebarService.shaderEnabled" (openedChange)="closeOnOutsideClick($event)"
    [shading]="sidebarService.shaderEnabled" [openedStateMode]="sidebarService.openMode"
    [revealMode]="sidebarService.revealMode" class="dx-theme-background-color">
    <!--
    <app-header (sideNavtoggleState)="onSideNavToggleState()"></app-header>
    <app-sidenav class="dx-swatch-additional" *dxTemplate="let dataMenu of 'panel'" (menuToggle)="menuOpened = false;"></app-sidenav>
    <router-outlet (activate)="componentAdded($event)" (desactivate)="componentRemoved($event)"></router-outlet>
    -->

    <ngc-sidenav class="dx-swatch-additional sidenav-with-bg" *dxTemplate="let dataMenu of 'panel'"></ngc-sidenav>

    <div id="content-wrapper">
        <app-loader [space]="{ of: '#content-wrapper' }"></app-loader>
        <app-header></app-header>
        <!-- <app-progress-bar></app-progress-bar> -->
        <router-outlet></router-outlet>
    </div>

</dx-drawer>
