import { Injectable } from '@angular/core';
import { variablesKeys } from '@app/globalsParameter';
import lsEncrypt from 'localstorage-slim';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
        // Activer ou non l'encryptage du localStorage
        lsEncrypt.config.encrypt = true;
    }

    setItem(key: string, value: string): void {
        lsEncrypt.set(key, value);
    }

    getItem(key: string): any {
        try {
            return (lsEncrypt.get(key));
        }
        catch (e) {
            console.log(e);
        }
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    clean(): void {
        localStorage.removeItem(variablesKeys.LOCALE);
        localStorage.removeItem(variablesKeys.REVERTED_DATASOURCE);
        localStorage.removeItem(variablesKeys.EMPTY_ROW);
        localStorage.removeItem(variablesKeys.COUNTRY_LIST);
        localStorage.removeItem(variablesKeys.REMEMBER_ME);
        localStorage.removeItem(variablesKeys.ACCESS_TOKEN);
        localStorage.removeItem(variablesKeys.USER);
        localStorage.removeItem(variablesKeys.USER_PROFILE);
        localStorage.removeItem(variablesKeys.POPUP_CONFIG);
        localStorage.removeItem(variablesKeys.SCREEN_CONFIG);
        localStorage.removeItem(variablesKeys.CUSTOMER_COMMERCIAL_OPERATION_PARAMETERS);
        localStorage.removeItem(variablesKeys.SUPPLIER_COMMERCIAL_OPERATION_PARAMETERS);
    }

    clear(): void {
        localStorage.clear();
    }
}
