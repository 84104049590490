export * from './commercialOperation';
export * from './commercialOperationHistory';
export * from './commercialOperationLine';
export * from './commercialOperationLineLabel';
export * from './commercialOperationLinePricing';
export * from './commercialOperationLogistic';
export * from './commercialOperationPricing';
export * from './commercialOperationType';
export * from './commercialOperationVat';
export * from './commercialOperationLabel';
export * from './commercialOperationLineViti';
export * from './commercialOperationLineInfo';
export * from './commercialOperationInfo';
export * from './comOpDeliveryAddress';
export * from './commercialOperationInvoicing';




export * from './supplier/supplierCommercialOperation';
export * from './supplier/supplierCommercialOperationHistory';
export * from './supplier/line/supplierCommercialOperationLine';
export * from './supplier/line/supplierCommercialOperationLineLabel';
export * from './supplier/line/supplierCommercialOperationLinePricing';
export * from './supplier/supplierCommercialOperationLogistic';
export * from './supplier/supplierCommercialOperationPricing';
export * from './supplier/supplierCommercialOperationVat';
export * from './supplier/supplierCommercialOperationLabel';
export * from './supplier/line/supplierCommercialOperationLineViti';
export * from './supplier/line/supplierCommercialOperationLineInfo';
export * from './supplier/supplierCommercialOperationInfo';
export * from './supplier/supplierComOpDeliveryAddress';
export * from './supplier/supplierCommercialOperationRepresentative';
export * from './supplier/suppliercommercialOperationInvoicing';




export * from './customer/customerCommercialOperation';
export * from './customer/customerCommercialOperationHistory';
export * from './customer/line/customerCommercialOperationLine';
export * from './customer/line/customerCommercialOperationLineLabel';
export * from './customer/line/customerCommercialOperationLinePricing';
export * from './customer/customerCommercialOperationLogistic';
export * from './customer/customerCommercialOperationPricing';
export * from './customer/customerCommercialOperationVat';
export * from './customer/customerCommercialOperationLabel';
export * from './customer/line/customerCommercialOperationLineViti';
export * from './customer/line/customerCommercialOperationLineInfo';
export * from './customer/customerCommercialOperationInfo';
export * from './customer/customerComOpDeliveryAddress';
export * from './customer/customerCommercialOperationRepresentative';
export * from './customer/customercommercialOperationInvoicing';


/**Status de la facturation */
export enum CommercialOperationInvoicingState {

    /// <summary>
    /// non facturable
    /// </summary>
    NOT_BILLABLE = 0,

    /// <summary>
    /// facturable
    /// </summary>
    BILLABLE = 1,

    /// <summary>
    /// facturée
    /// </summary>
    BILLED = 2
}

/**Status de l'expedition*/
export enum CommercialOperationExpeditionState {

    /// <summary>
    /// non expediable
    /// </summary>
    NOT_SHIPPABLE = 0,

    /// <summary>
    /// expédiable
    /// </summary>
    SHIPPABLE = 1,

    /// <summary>
    /// expédié
    /// </summary>
    SHIPPED = 2
}


export enum ManagementTypeTaxLine {
    /// <summary>
    /// Droits Acquittés
    /// </summary>
    DAQ,
    /// <summary>
    /// Droits suspendus
    /// </summary>
    DSU,
}


export enum CommercialOperationState {
    /// <summary>
    /// Achat: saisie
    /// Vente: saisie
    /// </summary>
    GRAPSED = 0,
    /// <summary>
    /// Achat: Validée
    /// Vente: Validée
    /// </summary>
    VALIDATED = 10,
    /// <summary>
    /// Achat: A préparer
    /// Vente: A préparer
    /// </summary>
    TO_PREPARE = 20,
    /// <summary>
    /// Achat: Attente réception
    /// Vente: En Préparation
    /// </summary>
    WAITING = 30,
    /// <summary>
    /// Achat: Préparée
    /// Vente: Préparée
    /// </summary>
    PREPARED = 40,
}

/** état de la commande en interne (pour les devloppeur) */
export enum CommercialOperationInternalState {
    /** operation crée mais sans ligne */
    INVALID = 0,
    /** operation crée avec des ligne */
    VALID
}


export enum ManagementTypeTax {
    /**  Droits Acquittés */
    DAQ=1,
    /**  Droits suspendus */
    DSU,
    EXO,

}

export enum OperationHistoryType {
    CREATION,
    /** Edition de l'operation */
    EDITING,
    /** Edition de document*/
    PRINT_REPORT,
    /** Edition d’étiquettes */
    PRINT_ETIQUETTE,
    /** Envoi des Emails */
    MAILING,
    /** Mise à jour avancement */
    PROGRESS_UPDATE,
    /** event d'expedition ou réception (si achat)*/
    SHIPPING,
    /** event de facturation*/
    BILLING,
}

export enum OperationHistoryOrigin {
    MANUAL,
    AUTOMATIC
}
export enum CommercialOperationTypeVisiblity {
    /** visible pour customer uniquement */
    CUSTOMER,
    /** visible pour supplier uniquement */
    SUPPLIER,
    /** visible pour supplier et customer */
    BOTH
}
export enum TaxingType {
    /** BIG */
    HT,
    /** DET */
    TTC
}
export enum DiscountType {
    /** la remise est un pourcentage */
    PERCENT,
    /** la remise est un montant fixe */
    AMOUNT
}

export enum OfferStatus {
    NONE,
    INPUT,
    CLOSED
}

