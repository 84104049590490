import { BaseEntity2 } from '../base-entity2';
import { Article } from '../article';
import { UploadDocument } from '../uploadDocument';
import { CommercialOperationLineLabel, CommercialOperationLinePricing } from '.';
import { ActionCommercialOperationLine } from '../company';

export class CommercialOperationLine extends BaseEntity2 {

    /**
     * Ajoutée pour marquer l'état des lignes
     * non encore mappée
     */
    actionCommercialOperationLine: ActionCommercialOperationLine;

    /** index de la ligne dans la liste
     *
     * obligatoire
    */
    position: number;
    /** designation de la ligne de commande
     *
     * obligatoire
     *
     * longueur max 100
    */
    designation: string;
    /**
     * quantité en unité d'article
     *
     * obligatoire
    */
    quantityInit: number;
    /**
     * longueur max 255
     *
     * obligatoire
    */
    comment: string;
    /** */
    cdnImage: string;
    /** */
    cdnImage_Upload: UploadDocument;
    /** longueur max 14
    */
    barCode: string;
    /** */
    designationChanged: boolean;
    /** */
    quantityValidate: number;
    /** */
    quantityOrder: number;
    /** */
    partnerCode: string;
    /** par combien */
    byHowMuch: string;
    originLine: number;
    lineToUpdate: number;
    previousLine: number;
    /**0 = add new, 1 = edit */
    status: boolean;

    constructor() {
        super();
        this.position = 0;
        this.designation = '';
        this.quantityInit = 0;
        this.comment = '';
        this.cdnImage = '';
        this.cdnImage_Upload = null;
        this.barCode = '';
        this.designationChanged = false;
        this.quantityValidate = 0;
        this.quantityOrder = 0;
        this.partnerCode = '';
        this.byHowMuch = '';
        this.originLine = 0;
        this.lineToUpdate = 0;
        this.previousLine = 0;
        this.actionCommercialOperationLine = ActionCommercialOperationLine.NONE;

    }
}


export enum CommercialOperationLineCell {
    LINE_DESIGNATION,
    QUANTITY_INIT,
    QUANTITY_VALIDATE,
    QUANTITY_ORDER,
    PCB, // byHowMuch
    VAT,
    WHYFREE,
    // QTYPACKAGE2,
    // QTYPACKAGE3,
    // QTYPACKAGE4,
    PACKAGE1,
    QTY_PACKAGE1,
    QTY_CROSS_TO_PACKAGE1,
    PACKAGE_FINAL,
    QTY_PACKAGE_FINAL,
    QTY_CROSS_TO_PACKAGE_FINAL,
    ROUNDING_RULE_PACKAGE,
    HT_CURENCY_UNIT_PRICE,
    TTC_CURENCY_UNIT_PRICE,
    INFO_FREEDATE01,
    DISCOUNT,
    DISCOUNT_TYPE,
    PRICE_ETIQUETTE_PRODUIT
}

export class CommercialOperationLineVirtual extends BaseEntity2 {
    // code peut acvoir 4 valeurs :
    // sous Total : STO
    // debut paragraphe : DPA
    // Fin paragraphe : FPA
    // Commentaire: COM
    code: string;
    constructor() {
        super();
        this.code = '';
    }
}

export class CommercialOperationLineReel extends BaseEntity2 {
    /** obligatoire */
    article: Article;
    constructor() {
        super();
        this.article = null;
    }
}
