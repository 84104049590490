import { Pipe, PipeTransform } from '@angular/core';
import { PopupConfig } from '../model/popupConfig';

@Pipe({
  name: 'popupIdentifier'
})
export class PopupIdentifierPipe implements PipeTransform {

  transform(value: string): number {
    return PopupConfig.convertIdStringPopupToInt(value);
  }

}
