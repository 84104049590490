import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { KeyedTemplateDirective } from '@app/shared/directive';
import { InputButton } from '@app/shared/model/input-button';
import { SlidePanelChildOptions } from '../genericComponentOption';
import { SlidePanelModel } from '../slide-panel/slide-panel-model';

@Component({
    selector: 'app-extra-button-field',
    templateUrl: './extra-button-field.component.html',
    styleUrls: ['./extra-button-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExtraButtonFieldComponent implements OnInit {

    @Input() buttonsAfter: InputButton[];
    @Input() buttonsBefore: InputButton[];
    @Input() value;
    @Input() clearButton;
    @Input() filterButton?: boolean;
    @Input() slidePanelOptions?: SlidePanelModel;
    @Input() slidePanelChildOptions: SlidePanelChildOptions = new SlidePanelChildOptions();

    @Output() ValueChanged: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() FocusIn: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() validateSuccess: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() deleteAdvancedFilter: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() slidePanelClose: EventEmitter<Event> = new EventEmitter<Event>();

    public templateDirectiveMap: Map<string, any>;
    visiblePopup = false;

    ngOnInit(): void {
        if (this.clearButton) {
            if (this.buttonsAfter) {
                this.buttonsAfter.push();
            } else {
                this.buttonsAfter = [
                    {
                        name: 'clearDate',
                        options: {
                            stylingMode: 'text',
                            icon: 'clear',
                            template: 'templateButtonAfter',
                            onClick: () => {
                                this.ValueChanged.emit(null);
                            }
                        }
                    }
                ];
            }
        }
    }

    onValueChanged(event: Event): void {
        this.ValueChanged.emit(event);
    }


    onFocusIn(event: Event): void {
        this.FocusIn.emit(event);
    }

    onValidateSuccess(val: Event): void {
        this.slidePanelOptions.isSlidePanelOpen = false;
        this.validateSuccess.emit(val);
    }

    onSlidePanelClose(): void {
        this.slidePanelOptions.isSlidePanelOpen = false;
    }

    /**
 * this function is called from the template directive to add a template to this component
 * @param key identifiant du template
 * @param templateDirective directive contenant le template
 */
    public registerTemplate = function (key: string, templateDirective: KeyedTemplateDirective): void {
        if (!this.templateDirectiveMap) {
            this.templateDirectiveMap = new Map<string, any>();
        }

        const keyedTemplate = {
            key: key,
            templateDirective,
        };
        this.templateDirectiveMap.set(key, keyedTemplate);
    };

    get filterTemplateContent(): void {
        this.slidePanelOptions.templateDirectiveMap = this.templateDirectiveMap.get('filterTemplateContent');
        return null;
    }
}
