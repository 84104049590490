export class RedisObjectLock {
    public user: string;
    public date: Date;
    public idUser; string;
    /**
     *
     */
    constructor() {
    }
}

export enum LockObjectKey {
    CUSTOMER_OPERATION,
    SUPPLIER_OPERATION
}
