import { Injectable } from '@angular/core';
import { AppContextService } from '../app-context.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfScreen } from '@app/shared/model/admin/conf-screen';
import { ConfField } from '@app/shared/model/admin/conf-field';
import { FormGroupItem } from '@app/shared/modules/devextreme/dx-form/models/form-group';
import { TranslateService } from '@ngx-translate/core';
import { ConfUnderBlock } from '@app/shared/model/admin/conf-under-block';
import { FormField, FormFieldObject } from '@app/shared/modules/devextreme/dx-form/models/form-field';
import { variablesKeys } from '@app/globalsParameter';
import { tap } from 'rxjs/operators';
import { ScreenIdentifier } from '@app/shared/model/admin/screen-type';
import { ApplicationMode } from '@app/shared/model/base-entity1';
import { Router } from '@angular/router';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { Society, UserType } from '@app/shared/model';
import { AppConfiguration } from '../application/app-configuration.service';
import { DetailSet } from '@app/shared/viewModel/detailSet';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root'
})
export class ConfScreenService {

    constructor(
        private appContext: AppContextService,
        private httpClient: HttpClient,
        private _translate: TranslateService,
        private router: Router,
        private appConfiguration: AppConfiguration,
        public localStorage: StorageService
    ) { }

    getAll(mode: ApplicationMode): Observable<ConfScreen[]> {
        return this.httpClient.get<ConfScreen[]>(this.appConfiguration.UrlsConfig.wsConfScreen + 'GetByMode/' + mode);
    }

    get(id: number): Observable<ConfScreen> {
        return this.httpClient.get<ConfScreen>(this.appConfiguration.UrlsConfig.wsConfScreen + id);
    }

    getByScreen(screen: ScreenIdentifier): Observable<ConfScreen> {
        // const  httpParams = new  HttpParams().set('screen', screen).set('userId', userId ? userId.toString() : null);
        // return this.httpClient.get<ConfScreen>(environment.serviceUser + 'ConfScreen', {params : httpParams});
        let config: ConfScreen;
        if (this.appContext.hasKey(variablesKeys.SCREEN_CONFIG)) {
            const allConfig = this.appContext.getPersisted(variablesKeys.SCREEN_CONFIG);
            const bothConfig = allConfig[screen];
            if (bothConfig && this.appContext.hasKey(variablesKeys.USER)) {
                config = bothConfig[this.appContext.getPersisted(variablesKeys.USER).id];
            }
            if (bothConfig && !config) {
                config = bothConfig['default'];
            }
        }
        if (config) {
            return of(config);
        } else {
            return this.httpClient.get<ConfScreen>(this.appConfiguration.UrlsConfig.wsConfScreen + 'GetByScreen/' + screen).pipe(tap(val => {
                if (val) {
                    const allConfig = {};
                    allConfig[screen] = {};
                    if (val.user) {
                        const id = val.user.id;
                        delete val.user;
                        allConfig[screen][id] = val;
                    } else {
                        delete val.user;
                        allConfig[screen]['default'] = val;
                    }
                    this.appContext.patchPersist(variablesKeys.SCREEN_CONFIG, allConfig);
                }
            }));
        }
    }

    getForDetail?(id: any, ...params: any[]): Observable<DetailSet<ConfScreen>>{
        throw new Error('Method not implemented.');
    }

    post(payload: any, ...params: any[]): Observable<any> {
        throw new Error('Method not implemented.');
    }

    put(id: any, screen: ConfScreen): Observable<NGCResults<ConfScreen>> {
        return this.httpClient.put<NGCResults<ConfScreen>>(this.appConfiguration.UrlsConfig.wsConfScreen + id, screen);
    }

    delete(id: any, ...params: any[]): Observable<any> {
        throw new Error('Method not implemented.');
    }

    getPageTableTitle(...params: any[]): string {
        throw new Error('Method not implemented.');
    }

    navigateToDetail(id: string | number, ...params: any[]): void {
        const currentUser = JSON.parse(this.localStorage.getItem(variablesKeys.USER));
        if (currentUser.type === UserType.ADMINISTRATION_IDSYSTEMES) {
            this.router.navigate(['/admin/conf-screen/detail', id]);
        } else if (currentUser.type === UserType.SUPER_ADMINISTRATOR) {
            this.router.navigate(['/superAdmin/conf-screen/detail', id]);
        }
    }

    navigateToCreatePage(...params: any[]): void {
        throw new Error('Method not implemented.');
    }

    navigateToList(mode?: number): void {
        this.router.navigate(['/superAdmin/conf-screen', mode]);
    }

    public buildFormLayout(confScreen: ConfScreen): { formItems: FormGroupItem[], tabs: { text: string, visibleIndex: number, indexBlock: number }[] } {
        const tabs = confScreen.blocks.map((block, index) => {
            return { text: block.designation, visibleIndex: index, indexBlock: index };
        });

        const formItems: FormGroupItem[] = [];
        for (let i = 0; i < confScreen.blocks.length; i++) {
            const group: FormGroupItem = {
                itemType: 'group',
                caption: confScreen.blocks[i].designation,
                helpText: confScreen.blocks[i].helpText,
                cssClass: 'block' + confScreen.blocks[i].designation,
                visibleIndex: i,
                colCount: confScreen.blocks[i].underBlocks.length,
                visible: true,
                items: [],
                groups: []
            };

            group.groups.push(...this.buildSubGroups(confScreen.blocks[i].underBlocks));
            formItems.push(group);
        }
        return { formItems, tabs };
    }

    private buildSubGroups(confUnderBlock: ConfUnderBlock[]): FormGroupItem[] {
        const formGroups: FormGroupItem[] = [];
        for (let i = 0; i < confUnderBlock.length; i++) {
            const group: FormGroupItem = {
                itemType: 'group',
                caption: confUnderBlock[i].designation,
                helpText: confUnderBlock[i].helpText,
                cssClass: 'block' + confUnderBlock[i].designation,
                colSpan: confUnderBlock[i].takeFullRow ? confUnderBlock.length : 1,
                width: confUnderBlock[i].width,
                visibleIndex: i,
                visible: true,
                explanatoryWording: confUnderBlock[i].explanatoryWording,
                items: this.buildFormField(confUnderBlock[i].fields),
            };
            formGroups.push(group);
        }
        return formGroups;
    }

    private buildFormField(fields: ConfField[]): FormField[] {
        const list: FormField[] = [];
        for (let i = 0; i < fields.length; i++) {
            const fieldConfig: FormField = {};
            fieldConfig.dataField = fields[i].name;
            fieldConfig.editorType = 'dxTextBox';
            fieldConfig.itemType = 'simple';
            fieldConfig.isRequired = fields[i].required;
            fieldConfig.editorOptions = null;
            fieldConfig.label = { text: fields[i].name, visible: true }; // fieldConfig.visible };
            fieldConfig.helpText = fields[i].helpText;
            fieldConfig.disabled = !fields[i].enabled;
            fieldConfig.visibleIndex = fields[i].position || i;
            fieldConfig.width = fields[i].width;
            fieldConfig.LineBreak = fields[i].lineBreak;
            fieldConfig.validationRules = [];
            fieldConfig.marginBottom = fields[i].marginBottom;
            fieldConfig.fieldType = fields[i].fieldType;
            fieldConfig.visible = fields[i].visible;

            // if items for each item call again build formfields
            if (fields[i].fields) {
                // TODO à ameliorer car obliger d'ajouter manuellement un items avec la liste des champs sans clé valeur
                fieldConfig['items'] = this.buildFormField(fields[i].fields);
            }
            list.push(fieldConfig);

        }
        return list;
    }

    public mergeFormFieldObjectWithFormGroupItem(formItems: FormGroupItem[], customFields: FormFieldObject): FormGroupItem[] {
        for (let index = 0; index < formItems.length; index++) {
            for (let g = 0; g < formItems[index].groups.length; g++) {
                this.manageMergeFields(formItems[index].groups[g].items, customFields);

            }
        }
        return formItems;
    }

    private manageMergeFields(items: any, customFields: FormFieldObject): void {
        for (let i = 0; i < items.length; i++) {
            /********************* isRequired */
            const isRequired = (customFields[items[i].dataField] ? customFields[items[i].dataField].isRequired : false) || items[i].isRequired;
            /********************* /isRequired */

            /********************* IsDisabled  */
            let isDisabled;
            if (customFields[items[i].dataField] && typeof customFields[items[i].dataField].disabled === 'function') {
                isDisabled = customFields[items[i].dataField].disabled;
            } else {
                isDisabled = ((customFields[items[i].dataField] ? customFields[items[i].dataField].disabled : false));
            }
            /********************* /IsDisabled */


            /********************* Isvisible  */

            let isVisible;
            if (customFields[items[i].dataField] && typeof customFields[items[i].dataField].visible === 'function') {
                isVisible = customFields[items[i].dataField].visible;
            } else {
                isVisible = ((customFields[items[i].dataField] ? customFields[items[i].dataField].visible : true) && items[i].visible) || items[i].isRequired;
            }
            /********************* /Isvisible */

            /********************* validationRules */
            const validationRulesMerged = customFields[items[i].dataField] && customFields[items[i].dataField].validationRules ? customFields[items[i].dataField].validationRules : [];

            if (isRequired && validationRulesMerged.findIndex(item => item['type'] === 'required') < 0) {
                validationRulesMerged.push({ type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true, reevaluate: true });
            }
            /********************* /validationRules */

            items[i] = { ...items[i], ...customFields[items[i].dataField] };

            items[i].validationRules = validationRulesMerged;
            items[i].visible = isVisible;
            items[i].isRequired = isRequired;
            items[i].disabled = isDisabled;

            // add sub items to item
            if (customFields[items[i].dataField] && customFields[items[i].dataField].subFields) {
                this.manageMergeFields(items[i].items, customFields[items[i].dataField].subFields);
                // add dependent fields
            } else if (customFields[items[i].dataField] && customFields[items[i].dataField].groupsField) {

                customFields[items[i].dataField].groupsField.forEach((fieldInGroup: string) => {
                    for (let j = 0; j < items.length; j++) {
                        if (items[j].dataField === fieldInGroup) {
                            items.splice(j, 1);
                        }
                    }
                    items.splice(i + 1, 0, customFields[fieldInGroup]);
                });
            }
        }
    }

    public duplicate(id: number, applicationMode: ApplicationMode): Observable<NGCResults<ConfScreen>> {
        const url = this.appConfiguration.UrlsConfig.wsConfScreen + 'duplicate/' + id;
        return this.httpClient.post<NGCResults<ConfScreen>>(url, applicationMode);
    }
    public applyConfScreenBase(id: number, societys: Society[]): Observable<NGCResults<ConfScreen>> {
        const url = this.appConfiguration.UrlsConfig.wsConfScreen + 'ApplyConfScreenBase/' + id;
        return this.httpClient.put<NGCResults<ConfScreen>>(url, societys);
    }


}
