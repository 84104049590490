import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';

export class CompanyClass extends BaseEntity2 {
    designation: string;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.visible = true;

    }
    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<CompanyClass> {
        return {
            visible: true
        };
    }
}

export class CustomerClass extends CompanyClass {
    constructor() {
        super();
    }
}

export class SupplierClass extends CompanyClass {
    constructor() {
        super();
    }
}
