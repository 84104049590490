import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { TableState, PortScale } from 'app/shared/model';
import { AppConfiguration } from './application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class PortScaleService {

    private _list: Observable<PortScale[]> = null;
    private _gridState: Observable<JSON> = null;

    constructor(
        private http: HttpClient, 
        private router: Router,
        private appConfiguration: AppConfiguration) { }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsPortScale;
        if (!this._list) {
            this._list = this.http.get<PortScale[]>(url)
                .pipe(
                    shareReplay(1)
                )
                ;
        }
        return this._list;
    }

    /**
    * Create a location
    * @param payload request body
    */
    post(payload: PortScale): Observable<PortScale> {
        const url = this.appConfiguration.UrlsConfig.wsPortScale;
        return this.http.post<PortScale>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a location
    * @param id location code
    * @param payload location data
    */
    put(id: string, payload: PortScale): Observable<PortScale> {
        const url = this.appConfiguration.UrlsConfig.wsPortScale + id;
        return this.http.put<PortScale>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () =>  {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour récuperer table state
    getGridState(): Observable<JSON> {
        const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('PortScale');
        if (!this._gridState) {
            this._gridState = this.http.get<JSON>(url).pipe(shareReplay(1));
        }
        return this._gridState;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des table state
    clearGridStateCache() {
        this._gridState = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsPortScale;
        return this.http.delete(url + id);
    }

    navigateToDetail(id) {
        return this.router.navigate(['/company/settings/port-scale/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/company/settings/port-scale/create']);
    }
}

