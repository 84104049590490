import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import * as auth0 from 'auth0-js';
import { Observable, BehaviorSubject, of, timer, Subscription } from 'rxjs';
import { tap, mergeMap, timeout } from 'rxjs/operators';

import { User } from '@app/shared/model';
import { variablesKeys, sessionLifeDuration } from '@app/globalsParameter';
import { AppContextService } from '../app-context.service';
import { ErrorsService } from '../errors.service';
import { environment } from '@env/environment';
import { jwtDecode, mapOwnerTokenToAUth0 } from '@app/utils/jwtHelper';
import { AccessToken } from './models/access-token';
import { UserProfile, VerifUserSession } from './models/user-profile';
import { IAuthService } from './iauth.service';
import { TokensData } from '@app/shared/model/tokensData';
import { SignalRService } from '../signal-r/signalR.service';
import { AppConfiguration } from '../application/app-configuration.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService implements IAuthService, OnDestroy {
    public userBehaviourSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public auth0: auth0.WebAuth;
    // define the refreshSubscription property
    refreshSubscription: any;
    public returnUrl: string = null;
    public reloadOnLogin;
    private subscriptions: Subscription[] = [];

    constructor(
        private location: Location,
        public router: Router,
        private http: HttpClient,
        private appContext: AppContextService,
        private errorsSrv: ErrorsService,
        private signalRService: SignalRService,
        private appConfiguration: AppConfiguration
    ) {
        this.InitIdentificationData();
    }

    getAuth0() {
        if (!this.auth0) {
            this.auth0 = new auth0.WebAuth(this.appConfiguration.auth0);
        }
        return this.auth0;
    }
    /**
    * initialise le current user et récupére le user si il n'est pas récupéré sachant que l'access token exist dans le localstrage
    * Code chargée de récupérer la session utilisateur suite F5
    * Si elle n'existe pas alors redirection vers la page d'accueil
    * Si jamais on a besoin de récupere le currentUser on s'inscrit a userBehaviourSubject(c'est un observable)
    */
    private InitIdentificationData() {
        if (!this.userBehaviourSubject.value) {
            if (this.appContext.hasKey(variablesKeys.USER)) {
                this.userBehaviourSubject.next(this.appContext.getPersisted(variablesKeys.USER));
            } else if (
                this.appContext.hasKey(variablesKeys.ACCESS_TOKEN)
                && this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN).AccessToken
            ) {
                // TODO traiter le cas
            }
        }
    }

    /**
     * isUserAlreadyLoggedIn
     */
    public isUserAlreadyLoggedIn(username: string, password: string) {
        return this.http.post<any>(this.appConfiguration.UrlsConfig.wsSession + 'isUserAlreadyLoggedIn', { email: username, password });
    }

    /**
    * general login function do everything
    */
    public login(username: string, password: string, verifUserSession: VerifUserSession): Observable<AccessToken & User & UserProfile> {
        if (this.appConfiguration.loginMethod === 'owner') {
            return this.loginOwner(username, password, verifUserSession);
        } else {
            return new Observable((observer) => {
                this.subscriptions.push(
                    this.loginAuth0(username, password).subscribe(
                        auth0Data => {
                            this.persistSession(auth0Data);
                            this.subscriptions.push(
                                this.setSession(auth0Data).subscribe(
                                    sessionResult => {
                                        this.scheduleRenewal();
                                        observer.next({ ...auth0Data, ...sessionResult });
                                    }, sessionError => {
                                        observer.error(sessionError);
                                        this.clear();
                                    }, () => {
                                        observer.complete();
                                    }
                                )
                            );
                        }, auth0Err => {
                            observer.error(auth0Err);
                            observer.complete();
                            this.clear();
                        }
                    )
                );
            });
        }
    }

    /**
    * login from owner using username and password
    */
    private loginOwner(username: string, password: string, verifUserSession: VerifUserSession): Observable<any> {

        return this.http.post<any>(this.appConfiguration.UrlsConfig.wsApi + 'authenticateowner/' + verifUserSession, { email: username, password }).pipe(
            tap(result => {
                const accessToken = mapOwnerTokenToAUth0(jwtDecode(result.token), result.token, result.refreshToken);
                this.persistSession(accessToken);
                this.appContext.setPersist(variablesKeys.USER, result.user);
                this.userBehaviourSubject.next(result.user);
            }));
    }

    /**
    * login from auth0 third party using username and password
    */
    private loginAuth0(username: string, password: string): Observable<AccessToken> {
        return new Observable((observer) => {
            this.getAuth0().login(
                {
                    username,
                    password,
                    realm: 'Username-Password-Authentication',
                },
                (err, authResult) => {
                    if (authResult) {
                        if (authResult && authResult.accessToken && authResult.idToken) {
                            observer.next(authResult);
                            observer.complete();
                        } else {
                            observer.error('unknown error');
                            observer.complete();
                        }
                    }
                    if (err) {
                        observer.error(err);
                        observer.complete();
                    }
                }
            );
        });
    }

    /**
    * check acces token validity and refrech expiration time in local storage
    * @param doLogout if error have value & doLogout is true we do logout
    */
    public checkLoginAuth0(doLogout: boolean = true): Observable<any> {
        return new Observable((observer: any) => {
            this.getAuth0().checkSession({}, (err, authResult) => {
                if (authResult) {
                    this.persistSession(authResult);
                    observer.next(authResult);
                }
                if (err) {
                    observer.error(err);
                    if (doLogout) {
                        this.logout();
                    }
                }
                observer.complete();
            });
        });
    }

    /**
    * persist the time that the Access Token will expire at in local storage
    */
    public persistSession(authResult: AccessToken) {
        const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        authResult.expiresAt = expiresAt;
        authResult.sessionExpiresAt = sessionLifeDuration + new Date().getTime();
        authResult.expiresIn = (authResult.expiresIn * 1000);
        authResult.idToken = authResult.idToken;
        this.appContext.setPersist(variablesKeys.ACCESS_TOKEN, authResult);
    }

    /**
    * récupére l'utilisateur du service user + profile de auth0 et les persiste
    */
    public setSession(authResult: AccessToken): Observable<User & UserProfile> {
        return new Observable((observer) => {
            this.subscriptions.push(
                this.http.get(this.appConfiguration.UrlsConfig.wsSession + 'GetUserForAuth0').subscribe(
                    (user: User) => {
                        if (user) {
                            this.getProfile((err: any, userProfile: UserProfile) => {
                                if (userProfile) {
                                    this.appContext.setPersist(variablesKeys.USER_PROFILE, userProfile);
                                    this.appContext.setPersist(variablesKeys.USER, user);
                                    this.userBehaviourSubject.next(user);

                                    observer.next({ ...user, ...userProfile });
                                } else if (err) {
                                    observer.error(err);
                                }
                                observer.complete();
                            });
                        } else {
                            observer.error('no user found');
                            observer.complete();
                        }
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                        console.log('error @ get user from session');
                        console.log(error);
                    }
                )
            );
        });
    }

    private clear() {
        this.subscriptions.push(
            this.http.get(this.appConfiguration.UrlsConfig.wsApi + 'disconnect').subscribe(
                () => {
                }
            )
        );
        // Remove tokens and expiry time from localStorage
        this.appContext.deletePersisted(variablesKeys.REMEMBER_ME);
        this.appContext.deletePersisted(variablesKeys.ACCESS_TOKEN);
        this.appContext.deletePersisted(variablesKeys.USER);
        this.appContext.deletePersisted(variablesKeys.USER_PROFILE);
        this.appContext.deletePersisted(variablesKeys.SCREEN_CONFIG);
        this.userBehaviourSubject.next(null);
    }

    /**
    * remove persisted current user and redirect to login page
    * @param oldState optional : the url to redirect to when login is completed
    */
    public logout(oldState?: any): void {
        const accessToken = (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).accessToken;
        if (accessToken && accessToken != null) { // 16/07/2019 MK: Ce teste sert à annuler les appels si on n'as pas un token coté front
            this.subscriptions.push(
                this.http.get(this.appConfiguration.UrlsConfig.wsApi + 'disconnect').subscribe(
                    () => {
                    }
                )
            );
        }
        // Go back to the home route or the old state if passed in param

        // window.location.reload();
        // Remove tokens and expiry time from localStorage
        this.appContext.deletePersisted(variablesKeys.REMEMBER_ME);
        this.appContext.deletePersisted(variablesKeys.ACCESS_TOKEN);
        this.appContext.deletePersisted(variablesKeys.USER);
        this.appContext.deletePersisted(variablesKeys.USER_PROFILE);
        this.appContext.deletePersisted(variablesKeys.SCREEN_CONFIG);
        this.signalRService.closeConnection();
        this.userBehaviourSubject.next(null);

        if (oldState) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: oldState } });
        } else {
            this.router.navigate(['/auth/login']);
        }
    }

    public logoutWithReload(oldState?: any) {
        this.logout(oldState);

        this.reloadOnLogin = true;
    }
    /**
    * return either user is authenticated or not
    * Check whether the current time is past the Access Token's expiry time
    */
    public isAuthenticated(): boolean {
        if (this.appContext.hasKey(variablesKeys.ACCESS_TOKEN)) {
            const accessToken = this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) as AccessToken;
            return new Date().getTime() < accessToken.expiresAt;
        }
        return false;
    }

    public refreshOwnerToken(): Observable<any> {
        const accessToken = this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) as AccessToken;
        const tokenData: TokensData = new TokensData();
        tokenData.token = accessToken.accessToken;
        tokenData.refreshToken = accessToken.idToken;
        const url: string = this.appConfiguration.UrlsConfig.wsApi + 'refreshtokenowner';
        return this.http.post<TokensData>(url, tokenData).pipe(tap(result => {
            const accessTokens = mapOwnerTokenToAUth0(jwtDecode(result.token), result.token, result.refreshToken);
            this.persistSession(accessTokens);
        }));
    }
    /**
    * Vérifiez si l'heure de session_expires_at est passée
    * Check whether the current time is past the Access Token's expiry time
    */
    public sessionNotExpired(): boolean {
        if (this.appContext.hasKey(variablesKeys.ACCESS_TOKEN)) {
            const sessionExpiresAt = (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).sessionExpiresAt || {};
            return new Date().getTime() < sessionExpiresAt;
        }
        return false;
    }

    /**
    * get the profile of the user using his access token
    * @param cb callback function, take two params "error" and "profile"
    */
    private getProfile(cb): void {
        const accessToken = this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN);
        if (!accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }
        this.getAuth0().client.userInfo(accessToken.accessToken, (err, profile) => {
            cb(err, profile);
        });
    }

    handleAuthentication() {
        this.getAuth0().parseHash((error, result) => {
            if (result) {
                this.persistSession(result as AccessToken);
                this.subscriptions.push(
                    this.setSession(result as AccessToken).subscribe(
                        () => {
                            this.router.navigateByUrl(this.returnUrl);
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                        }, (error: any) => {
                            console.log(error);
                            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl } });
                        }
                    )
                );
            }
        });

    }

    public finalizationAccount(keyFinalization: string): Observable<User> {
        return this.http.get<User>(this.appConfiguration.UrlsConfig.wsApi.concat('finalizationAccount/', keyFinalization));
    }

    public scheduleRenewal() {
        if (!this.isAuthenticated()) { return; }
        this.unscheduleRenewal();

        const expiresAt = (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) as AccessToken).expiresAt;

        const expiresIn$ = of(expiresAt).pipe(
            mergeMap(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (expiresAt) => {
                    const now = Date.now();
                    // Use timer to track delay until expiration
                    // to run the refresh at the proper time
                    return timer(Math.max(1, expiresAt - now));
                }
            )
        );

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSubscription = expiresIn$.subscribe(
            () => {
                this.renewTokens();
                this.scheduleRenewal();
            }
        );
    }

    public renewTokens() {
        this.getAuth0().checkSession({}, (err, result) => {
            if (err) {
                console.log(err);
            } else {
                this.persistSession(result);
            }
        });
    }

    public unscheduleRenewal() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
