<div class="page-header form-page-header">
    <ng-container *ngTemplateOutlet="headerLeftBlocTemplate ?
        headerLeftBlocTemplate.template : null; context: { $implicit: {item:
        item, inPopup: inPopup, itemLoading: itemLoading} }">
    </ng-container>
    <!-- DEPERCATED TO BE DELETED AFTER MIGRATION -->
    <div class="left-bloc" *ngIf="!headerLeftBlocTemplate && !headerInfo">
        <ng-template [ngIf]="!id">
            <div class="text-zone">
                <div class="title">{{ 'Creating' | translate }}
                    <span class="page-title">
                        {{ options.header.createPageTitle ?
                        (options.header.createPageTitle | translate) : ('merci de spécifier NextDxFormOptions.header.createPageTitle')
                        }}
                    </span>
                </div>
            </div>
        </ng-template>
        <div class="text-zone" *ngIf="id && item">
            <div class="title">
                {{item && item[options.header.labelFieldName] ?
                item[options.header.labelFieldName] : '' }}
            </div>
            <div class="subtitle">
            </div>
        </div>
    </div>

    <div class="left-bloc" [ngStyle]="{'width': 'calc(100% - ' +leftBlockSize +
        'px)' }" *ngIf="headerInfo">
        <app-left-header-bloc [headerInfo]="headerInfo" [item]="item"></app-left-header-bloc>
    </div>
    <!-- right-bloc -->
    <div class="right-bloc">
        <div style="height: 42px;width: 100%;display: flex;justify-content: center;flex-direction: column;">
            <div class="buttons">

                <span
                    *ngIf="returnButton !== undefined && returnButton !== null && headerInfo?.mode !== HeaderMode.Create">
                    <dx-button type="default" id="returnButton" stylingMode="text" (click)="ReturnButtonPath()">
                        <span class='icon'>
                            <ngc-icon size="16" icon="left"></ngc-icon>
                        </span>
                        <div class='content'>{{'Return' | translate}}</div>
                    </dx-button>
                </span>

                <span
                    *ngIf="returnButton !== undefined && returnButton !== null && headerInfo?.mode === HeaderMode.Create">
                    <dx-button type="default" id="returnButton" stylingMode="text" (click)="ReturnButtonPath()">
                        <span class='content'>{{'dialog.cancel' | translate}}</span>
                    </dx-button>
                </span>

                <span class="arrows"
                    *ngIf="id && (loadDataType !== 'simple') && (id && !isDuplication) && options.edit.showNavigateButtons">
                    <dx-button type="default" class="arrow" stylingMode="outlined" [disabled]="!idPrevious"
                        [focusStateEnabled]="true" [activeStateEnabled]="false"
                        (click)="onNavigateToDetail(idPrevious)">
                        <ngc-icon size="20" icon="left"></ngc-icon>
                    </dx-button>
                    <dx-button class="arrow" type="default" stylingMode="outlined" [disabled]="!idNext"
                        [focusStateEnabled]="true" [activeStateEnabled]="false"
                        (click)="onNavigateToDetail(idNext)">
                        <ngc-icon size="20" icon="right"></ngc-icon>
                    </dx-button>
                </span>

                <span class="update-btn" *ngIf="options.edit.showUpdateButton">
                    <dx-button class="update" type="default" stylingMode="text" [focusStateEnabled]="false"
                        [hoverStateEnabled]="false" [activeStateEnabled]="false" (click)="onUpdate($event)">
                        <ngc-icon size="20" icon="modify2"></ngc-icon>
                    </dx-button>
                </span>

                <span>
                    <!-- Bouton valider en toutes lettres -->
                    <dx-button [visible]="true" class="valid-btn responsive-text-button"
                        *ngIf="options.edit.showValidateButton" [ngClass]="{'inpopup': inPopup}" type="default"
                        stylingMode="contained" [useSubmitBehavior]="false" (click)="onValidate($event,
                    'validate')">
                        {{'form.validatePopup' | translate}}
                    </dx-button>

                    <!-- Enregistrer & nouveau -->
                    <dx-button [visible]="isEditable && !pendingSubmit" class="responsive-text-button" type="default"
                        stylingMode="contained" *ngIf="options.create.showHeaderButton &&
                !formCanDeactivate" [useSubmitBehavior]="false" (click)="onSave($event,
                'create')">
                        <ngc-icon size="20" icon="add"></ngc-icon>
                        <!-- <span class="dx-button-text">{{ options.create.text |translate }}</span> -->
                    </dx-button>

                    <!-- nouveau -->
                    <dx-button [visible]="isEditable && !pendingSubmit" class="responsive-text-button" type="default"
                        stylingMode="contained" *ngIf="(options.create.showHeaderButton &&
            formCanDeactivate) ||
            options.navigateToCreateBtn.showAlways" [useSubmitBehavior]="false" (click)="onCreate()">
                        <ngc-icon size="20" icon="add"></ngc-icon>
                        <!-- <span class="dx-button-text">{{ options.navigateToCreateBtn.text |translate }}</span> -->
                    </dx-button>

                    <dx-button [visible]="isEditable && !formCanDeactivate &&
            !pendingSubmit" class="validate-btn responsive-text-button save24" *ngIf="options.edit.showHeaderButton"
                        [ngClass]="{'inpopup': inPopup}" type="success" stylingMode="contained"
                        [useSubmitBehavior]="false" (click)="onSave($event,
            'validate')" >
                        <ngc-icon size="24" icon="save" ></ngc-icon>
                        <span style="vertical-align: middle;padding: 4px;font-size: 15px;font-weight: 600;">{{ 'form.Save' |translate }}</span>
                    </dx-button>
                </span>

                <span class="padlockOpen-btn" *ngIf="options.edit.showPadlockOpen">
                    <dx-button class="padlockOpen" type="default" stylingMode="text" [focusStateEnabled]="false"
                        [hoverStateEnabled]="false" [activeStateEnabled]="false" (click)="onLockOperation()">
                        <ngc-icon size="20" icon="padlockOpen"></ngc-icon>
                    </dx-button>
                </span>

                <span class="padlockClosed" *ngIf="options.edit.showPadlockClosed">
                    <ngc-icon size="24" icon="padlock"></ngc-icon>
                </span>

                <dx-drop-down-button id="dropdownMore" *ngIf="toolbarItems &&
                    toolbarItems.length" class="header-more-dropdown more" displayExpr="name" type="normal"
                    stylingMode="contained" [dropDownOptions]="{ width: 230 }" icon="more" [items]="toolbarItems"
                    itemTemplate="dropDownButtonTemplate" (onItemClick)="onDropDownClick($event)">

                    <div *dxTemplate="let data of 'dropDownButtonTemplate'"
                        [ngClass]="{'dropDownItemSeparator': data.separator}">
                        <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                        {{data.name}}
                    </div>
                </dx-drop-down-button>

            </div>
        </div>
        <div *ngIf="headerInfo && headerInfo.parent &&
            headerInfo.parent.subtitleItems && id && !isDuplication" class="right-header-toolbar">
            <div style="display: flex; justify-content: flex-end;position: absolute;bottom: 13px;right: 0px">
                <div *ngFor="let data of headerInfo.parent.toolbarItems; let i = index">
                    <dx-button [ngClass]="data.cssClass? data.cssClass : 'icon-btn'" id="link{{i}}" [disabled]="data && data.disabled" (mouseenter)="onHoverBtnVisible(i)"
                        (mouseleave)="onLeaveBtnVisible(i)" type="default" (click)="data.options.onClick(data)"
                        stylingMode="outlined" style="margin:4px;"
                        [template]="'buttonTemplate'" [visible]="data.visible">
                        <div *dxTemplate="let dxBtnData of 'buttonTemplate'" style="padding: 3px;">
                            <div *ngIf="data.options.icon; else imgButton">
                                <ngc-icon [icon]="[data.options.icon]" size="24"></ngc-icon>
                            </div>
                            <ng-template #imgButton>
                                <img [src]="[data.options.img]" [alt]="data.options.hint">
                            </ng-template>
                        </div>
                    </dx-button>

                    <dx-popover target="#link{{i}}" position="bottom" [width]="auto" [(visible)]="btnVisible[i]">
                        <span style="color : white;"> {{valueOfThisIcon(i)}} </span>
                    </dx-popover>
                </div>
                <div *ngFor="let data of headerInfo.parent.textAction; let i = index">
                    <dx-button id="link{{i}}" [disabled]="data && data.disabled" (mouseenter)="onHoverBtnVisible(i)"
                        (mouseleave)="onLeaveBtnVisible(i)" type="default" (click)="data.options.onClick(data)"
                        stylingMode="outlined" class="textActionButton"
                        [template]="'textTemplate'" [visible]="data.visible">
                        <div *dxTemplate="let dxBtnData of 'textTemplate'" class="textAction">
                            <div class="textActionText">{{data.text}}</div>
                            <ngc-icon [icon]="[data.options.icon]" size="20"></ngc-icon>
                        </div>
                    </dx-button>
                </div>
            </div>
        </div>
    </div>
</div>
