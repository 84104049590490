import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { CompanyType, GenericCriteria } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyCriteriaService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(companyType: CompanyType): Observable<GenericCriteria[]> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteria + companyType;
        if (!this[companyType]) {
            this[companyType] = this.http.get<GenericCriteria[]>(url)
                .pipe(
                    map((criteriaArray) => {
                        return criteriaArray.map(criteria => {
                            criteria.criteriaValues.map(value => {
                                value.criteriaId = criteria.id;
                                return value;
                            });
                            return criteria;
                        });
                    }),
                    shareReplay(1)
                );
        }
        return this[companyType];
    }

    post(payload: any, companyType: CompanyType): Observable<NGCResults<GenericCriteria>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteria + companyType;
        return this.http.post<NGCResults<GenericCriteria>>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    put(code: string, payload: any, companyType: CompanyType): Observable<NGCResults<GenericCriteria>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCriteria + companyType + '/' + code;
        return this.http.put<NGCResults<GenericCriteria>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType) => {
        this[companyType] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any, companyType: CompanyType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompanyCriteria + companyType + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: any, companyType: CompanyType) {
        this.router.navigate(['/parameters/' + companyType + '/criteria/detail', id]);
    }

    navigateToCreatePage(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/criteria/create']);
    }

    navigateToList(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/criteria']);
    }

}
