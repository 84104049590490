import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AppContextService } from '../services/app-context.service';
import { AppConfiguration } from '../services/application/app-configuration.service';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
    private urlExculudedFromLoading = [];

    constructor(
        private appContext: AppContextService,
        public router: Router,
        private appConfiguration: AppConfiguration) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appConfiguration) {
            this.urlExculudedFromLoading = [this.appConfiguration.serviceCDN];
        }
        // used to ignore i18n or any internal request.
        if (request.url.startsWith('/') || request.url.includes('CompleteAndCalculate/')) {
            return next.handle(request);
        }
        // adding request to request queue need to be done after cloning and adding header
        if (!this.urlExculudedFromLoading.some(v => request.url.startsWith(v))) {
            this.appContext.addRequestInProgress();
        }
        // we do something after getting the HttpResponse (stop loader)
        return next.handle(request).pipe(finalize(() => {
            if (!this.urlExculudedFromLoading.some(v => request.url.startsWith(v))) {
                this.appContext.removeRequestInProgress();
            }
        }));
    }
}
