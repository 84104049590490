import { Injectable } from '@angular/core';
import { IDaoBaseService } from '@app/shared/interface';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyType, TVA } from '@app/shared/model';
import { Observable } from 'rxjs/internal/Observable';

import { shareReplay } from 'rxjs/operators';
import { DaoGridStateBaseService } from '../../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../../application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class TvaService extends DaoGridStateBaseService implements IDaoBaseService {
    private _list: Observable<TVA[]> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<TVA[]> {
        const url = this.appConfiguration.UrlsConfig.wsTva;
        if (!this._list) {
            this._list = this.http.get<TVA[]>(url)
                .pipe(
                    shareReplay(1)
                )
                ;
        }
        return this._list;
    }

    post(payload: TVA): Observable<NGCResults<TVA>> {
        const url = this.appConfiguration.UrlsConfig.wsTva;
        return this.http.post<NGCResults<TVA>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    put(id: number, payload: TVA): Observable<NGCResults<TVA>> {
        const url = this.appConfiguration.UrlsConfig.wsTva + '/' + id;
        return this.http.put<NGCResults<TVA>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsTva + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: any) {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/tva/detail', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/tva/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/tva']);
    }

}
