import { BaseEntity2 } from '../base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { ConfBlock } from './conf-block';
import { ConfScreen } from './conf-screen';
import { ConfUnderBlock } from './conf-under-block';

export enum FieldType { FIELD, SUBFORM, LISTE }
export class ConfField extends BaseEntity2 {

    public position: number; // not null
    public designation: string; // max length 50, not null
    public name: string; // max length 30, not null
    public helpText: string;
    public required: boolean; // not null
    public enabled: boolean; // not null
    public visible: boolean;
    public marginBottom: boolean;
    public block?: ConfBlock;
    public screen?: ConfScreen;
    public underBlock: ConfUnderBlock;
    public width: string;
    public lineBreak: boolean;
    public fields: ConfField[];
    public fieldType: FieldType;

    constructor() {
        super();
        this.position = null;
        this.designation = '';
        this.name = '';
        this.helpText = '';
        this.required = false;
        this.enabled = true;
        this.visible = true;
        this.marginBottom = false;
        this.block = null;
        this.screen = null;
        this.underBlock = null;
        this.width = '';
        this.lineBreak = true;
        this.fields = [];
        this.fieldType = FieldType.FIELD;
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', min: 4, max: 50, ignoreEmptyValue: false, message: 'FormErrorMessages.StringRangeLength', useTranslator: true }
            ],
            name: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 30, ignoreEmptyValue: false, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            position: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
            ],
        };
    }
}
