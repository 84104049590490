import { loadDataType, DxDataGridColumn, NextDxDataGridOptions } from '../data-grid/models';
import { BaseEntity1 } from '@app/shared/model';
import { GenericService } from '@app/core/services';
import { IDaoBaseService } from '@app/shared/interface';
import { IDaoServiceOptions, FormFieldObject } from '../dx-form/models';
import { dxTemplateOptions } from 'devextreme/core/templates/template';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { EventEmitter } from '@angular/core';

export class SlidePanelModel {
    isMultiple: boolean = false;
    list: SlidePanelModel[] = null

    quickAdding: boolean = false;
    filter: boolean = false;
    isSlidePanelOpen: boolean = false;
    isForm: boolean = false;
    isList: boolean = false;
    isGrid: boolean = false;
    isTemplate: boolean = false;
    templateDirectiveMap: any;
    dataGridOptions: DataGridOptions;
    formSimplifiedOptions: FormSimplifiedOptions;
    listOptions: ListOptions;
    name: string = '';
    /**  obligatoire pour les composant qui implementent IPopupComponent*/
    inPopup: boolean = true;

    loadDataType: loadDataType = 'templateSide';
    css: {
        top: string,
        width: string,
        height: string
    };
    position: String;

    // si twoButton on affiche les 2 boutons save et concel si non on affiche le boutton add
    buttonsConfig: {
        type: 'twoButtons' | 'oneButton',
        twoButtons: {
            cancelBtnLabel: string,
            confirmBtnLabel: string,
            updateBtnLabel: string
        }
        oneButton: {
            addBtnLabel: string
        }
    };

    constructor() {
        this.quickAdding = false;
        this.filter = false;
        this.isSlidePanelOpen = false;
        this.isForm = false;
        this.isList = false;
        this.isGrid = false;
        this.isTemplate = false;
        this.formSimplifiedOptions = null;
        this.listOptions = new ListOptions();
        this.css = { top: '132px', width: '495px', height: '795px' };
        this.css.height = (window.innerHeight - 144) + 'px';
        this.position = 'right';
        this.buttonsConfig = {
            type: 'twoButtons',
            oneButton: { addBtnLabel: 'form.addFromSlidePanel' },
            twoButtons: { cancelBtnLabel: 'form.cancel', confirmBtnLabel: 'form.validate', updateBtnLabel: 'form.apply' }
        }
    }
}

export class FormSimplifiedOptions {
    model: any;
    service: IDaoBaseService | any;
    /**entete de slide panel */
    headerSimplified: string;
    type: 'create' | 'update';
    excludedFieldFormList: string[];
    serviceOptions: IDaoServiceOptions;
    popupContentHeight: number;
    /** (optional input) pour personnaliser que quelque champ de notre object */
    customFieldsForm: FormFieldObject;
    customFields: FormFieldObject;
    id?: number;
    formDataChange: EventEmitter<any>;
    constructor() {
        this.model = null;
        this.service = null;
        this.headerSimplified = '';
        this.type = 'create';
        this.id = null;
        this.excludedFieldFormList = [];
        this.serviceOptions = null;
        this.popupContentHeight = null;
        this.customFieldsForm = {};
        this.customFields = {};
        this.formDataChange = new EventEmitter<any>();
    }

}

export class ListOptions {
    service: IDaoBaseService | any;
    model: any;
    icon: string;
    listName: string;
    customFields: { name: string, type?: string, displayValue?: string, values?: any[], caption?: string, displayValueCallback?: Function }[];
    serviceOptions: IDaoServiceOptions;
    constructor() {
        this.service = null;
        this.model = null;
        this.icon = '';
        this.listName = '';
        this.serviceOptions = null;
        this.customFields = [];
    }
}

export class DataGridOptions {
    service: IDaoBaseService | any;
    model: any;
    icon: string;
    listName: string;
    customFields: { [key: string]: DxDataGridColumn };
    serviceOptions: IDaoServiceOptions;
    pageTitle: string;
    tableStateName: string;
    excludedFieldList: string[];
    options: NextDxDataGridOptions;
    dataGridFiltersParam: DataGridFiltersParam;
    showHeader: boolean;
    showActions: boolean = true;
    constructor() {
        this.service = null;
        this.model = null;
        this.icon = '';
        this.listName = '';
        this.customFields = {};
        this.excludedFieldList = [];
        this.options = new NextDxDataGridOptions();
        this.dataGridFiltersParam = null;
        this.showHeader = false;
    }
}
