import { Observable } from 'rxjs';

import { AuthService, AppContextService } from '@app/core/services';
import { variablesKeys, sessionLifeDuration } from '@app/globalsParameter';
import { AccessToken } from '@app/core/services/auth/models/access-token';
import { environment } from '@env/environment';
import { tap, switchMap, finalize, map, catchError } from 'rxjs/operators';

export const canActivateAndCandLoad =
(auth: AuthService, appContext: AppContextService, url: string): Observable<boolean> | Promise<boolean> | boolean  => {
    // let observable: Observable<boolean>;
    // let isRefreshingToken: boolean = false;

    if (auth.isAuthenticated()) {
        const newAccessToken = appContext.getPersisted(variablesKeys.ACCESS_TOKEN) as AccessToken;
        newAccessToken.sessionExpiresAt = sessionLifeDuration + new Date().getTime();
        appContext.setPersist(variablesKeys.ACCESS_TOKEN, newAccessToken);
        return true;
    } else if (
        (
            appContext.getPersisted(variablesKeys.REMEMBER_ME) != undefined
            && appContext.getPersisted(variablesKeys.REMEMBER_ME) == 1
        )
        || auth.sessionNotExpired()
    ) {
        return true;
    } else { // !auth.isAuthenticated()
        auth.logout(url);
        return false;
    }
};
