import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedContentComponent } from './connected-content.component';
import { ConnectedContentRoutingModule } from './connected-content-routing.module';
import { SidenavComponent } from './widgets/sidenav/sidenav.component';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { HeaderComponent } from './widgets/header/header.component';
import { TranslationLoaderResolver } from '@app/core/resolvers/translate-loader.resolver';
import { LoaderComponent } from '@app/connected-content/widgets/loader/loader.component';


@NgModule({
    imports: [
        ConnectedContentRoutingModule,
        CommonModule,
        SharedModule,
        CoreModule
    ],
    //    entryComponents: [SidenavComponent],
    declarations: [
        ConnectedContentComponent,
        SidenavComponent,
        HeaderComponent,
        LoaderComponent
    ],
    providers: [
        TranslationLoaderResolver
    ]
})
export class ConnectedContentModule { }
