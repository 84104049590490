import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';
import { BaseEntity2 } from 'app/shared/model';
import { Tax } from './Tax';
import { TaxLabels } from './tax-labels';

export class TaxFamily extends BaseEntity2 {

    code: string;
    designation: string;
    used: boolean;
    Excise: Tax;
    SocialSecurityStamp: Tax;
    Sugar: Tax;
    Sweetener: Tax;
    Energizing: Tax;
    SoftDrink: Tax;
    label: TaxLabels;

    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.used = false;
        this.Excise = null;
        this.SocialSecurityStamp = null;
        this.Sugar = null;
        this.Sweetener = null;
        this.Energizing = null;
        this.SoftDrink = null;
        this.label = null;
    }

    validator(): ValidationRulesByFieldName {
        return {
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 4, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            designation: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

}
