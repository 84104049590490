import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
    Customer, TableState, Supplier,
    Company, CompanyType
} from 'app/shared/model';
import { numberOfTuplesForCache } from 'app/globalsParameter';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';
import { DetailSet } from '@app/shared/viewModel/detailSet';

@Injectable({
    providedIn: 'root'
})
// Chaque service doit être injectable afin d'être injecté dans des composants
export class CompanyService extends DaoGridStateBaseService implements IDaoBaseService {
    private _gridStateCustomer: Observable<string> = null;
    private _gridStateSupplier: Observable<string> = null;

    // utiliser pour la page addresse
    // companyType: CompanyType;

    // variable utiliser pour passer les données de filtrage de companyList -> company detail
    params: DataGridFiltersParam = new DataGridFiltersParam();
    redisKeyPrint: string;
    trisField = null;
    trisStrategie = 'asc';
    beginning: number = 0;
    numberOfTuples: string = '10';
    list: Company[] = [];
    idList: number[] = [];

    currentItem: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    getByFilter(companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCompany + 'getByFilter/' + companyType + '/' + this.beginning + '/' + this.numberOfTuples + '/' + this.trisField + '/' + this.trisStrategie;
        return this.http.post(serviceUrl, this.params);
    }

    get(id: number, companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCompany + 'get/' + companyType + '/' + id;
        return this.http.get(serviceUrl);
    }

    getForDetail(id: number, companyType: CompanyType): Observable<DetailSet<Company>> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompany + 'getForDetail/' + companyType + '/' + id + '/' + numberOfTuplesForCache + '/' + this.trisField + '/' + this.trisStrategie;
        return this.http.post<DetailSet<Company>>(url, this.params);
    }

    getForNavigation(idList: number[], companyType: CompanyType): Observable<Company[]> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompany + 'getForDetailNavigation/' + companyType;
        return this.http.post<Company[]>(url, idList);
    }

    post(payload: Customer | Supplier, companyType: CompanyType): Observable<NGCResults<Customer | Supplier>> {
        const url = this.appConfiguration.UrlsConfig.wsCompany + companyType;
        return this.http.post<NGCResults<Customer | Supplier>>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    /**
     * Update a location
     * @param code location code
     * @param payload location data
     */
    put(code: number, payload: Customer | Supplier, companyType: CompanyType): Observable<NGCResults<Customer | Supplier>> {
        const url = this.appConfiguration.UrlsConfig.wsCompany + companyType + '/' + code;
        return this.http.put<NGCResults<Customer | Supplier>>(url, payload);
    }

    /**
     * Delete a company
     * @param id company id
     */
    delete(id: number, companyType: CompanyType): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsCompany + companyType + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: number, companyType: CompanyType) {
        this.router.navigate(['/company/' + companyType + '/detail/', id]);
    }
    
    navigateToDetailWithExtra(id: number, companyType: CompanyType, isFromDuplication: boolean) {
        this.router.navigate(['/company/' + companyType + '/detail/', id],{ state : {canDeactivate: isFromDuplication}});
    }

    navigateToCreatePage(companyType: CompanyType): void {
        this.router.navigate(['/company/' + companyType + '/create']);
    }

    navigateToDuplicationPage(id: number, companyType: CompanyType , isFromDuplication: boolean, data: any): void {
        this.router.navigate(['/company/' + companyType + '/duplication/' + id], { state : {canDeactivate: isFromDuplication , duplicatedItem : data.duplicatedItem, notificationMsg: data.notificationMsg}});
    }

    navigateToList(companyType: CompanyType): void {

        this.currentItem.next(null);
        this.router.navigate(['/company/' + companyType + '/grid']);
    }

    clearListTablesCache = () => {
        this.idList = null;
        this.list = null;
    }

    public getSupplierParameterListGridState(): Observable<any> {
        if (!this._gridStateSupplier) {
            const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('supplierTableParameter');
            this._gridStateSupplier = this.http.get<string>(url).pipe(shareReplay(1));
        }

        return this._gridStateSupplier;
    }
    public getCustomersParameterListGridState(): Observable<any> {
        if (!this._gridStateCustomer) {
            const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('customerTableParameter');
            this._gridStateCustomer = this.http.get<string>(url).pipe(shareReplay(1));
        }

        return this._gridStateCustomer;
    }

    clearGridStateCustomerCache() {
        this._gridStateCustomer = null;
    }

    clearGridStateSupplierCache() {
        this._gridStateSupplier = null;
    }

    duplicate(id: number, companyType: CompanyType): Observable<any> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsCompany + 'duplicate/' + companyType + '/' + id;
        return this.http.post(serviceUrl, this.params).clearListCache(this.clearListTablesCache, companyType);
    }
}
