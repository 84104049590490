import { ApplicationMode } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';

export class ApplicationParameter extends BaseEntity2 {
    name: string;
    value: string;
    explanation: string;
    applicationMode: ApplicationMode;
    visible: boolean;

    constructor() {
        super();
        this.name = '';
        this.value = '';
        this.explanation = '';
        this.applicationMode = null;
        this.visible = true;
    }
}
