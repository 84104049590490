import { Injectable, OnDestroy } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import { CanDesactivate } from 'app/shared/interface/can-desactivate';
import { CustomConfirmDialogResult, confirmDialogWithCancel } from '@app/utils/dialogs';
import { SavingStateEnum } from '@app/shared/modules/devextreme/dx-form/dx-form.component';
import { AuthService } from '../services';

@Injectable()
export class DesactivateGuard implements CanDeactivate<CanDesactivate>, OnDestroy {
    private observable: Observable<boolean>;
    private subscriptions: Subscription[] = [];

    constructor(private translate: TranslateService, private authService: AuthService) { }

    canDeactivate(component: CanDesactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        let can: boolean = true;
        if (component.canDeactivate && this.authService.isAuthenticated() && this.authService.sessionNotExpired()) {
            can = component.canDeactivate();
        }
        let warning: any;
        if (component.getWarningForExit) {
            warning = component.getWarningForExit(currentState.url, nextState.url);
        }

        if (!can && !warning) {

            this.observable = new Observable(observer => {
                this.subscriptions.push(
                    this.translate.get(['Confirm', 'AreYouSureLeavePage', 'CANCEL', 'DONTSAVE', 'SAVE']).subscribe(
                        (messages: string[]) => {
                            const myDialog = confirmDialogWithCancel(messages['Confirm'], messages['CANCEL'],
                                messages['DONTSAVE'], messages['SAVE'], messages['AreYouSureLeavePage'], '90', '180', '130');
                            myDialog.show().then((dialogResult: CustomConfirmDialogResult) => {
                                switch (dialogResult) {
                                    case CustomConfirmDialogResult.YES:
                                        if (component.confirmDeactivateSave) {
                                            this.subscriptions.push(
                                                component.confirmDeactivateSave().subscribe(
                                                    (saving: SavingStateEnum) => {
                                                        switch (saving) {
                                                            case SavingStateEnum.NO:
                                                                observer.next(true);
                                                                break;
                                                            case SavingStateEnum.CANCEL:
                                                                observer.next(false);
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }, error => {
                                                        observer.next(false);
                                                    }
                                                )
                                            );
                                        } else {
                                            observer.next(true);
                                            console.error('ajouter la méthode confirmDeactivateSave() dans le composant');
                                        }
                                        break;
                                    case CustomConfirmDialogResult.NO:
                                        observer.next(true);
                                        break;
                                    default: // annulation
                                        observer.next(false);
                                        break;
                                }
                            });
                        }
                    )
                );
            });

            return this.observable;
        }
        if (warning) {
            this.observable = component.getWarningForExit(currentState.url, nextState.url);
            return this.observable;
        }
        return can;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
