export * from './base-entity1';
export * from './base-entity2';
export * from './applicationParameter';
export * from './appointment';
export * from './country';
export * from './destinationTypeCode';
export * from './genericCriteria';
export * from './genericCriteriaSelected';
export * from './genericCriteriaValue';
export * from './parameter';
export * from './parameterCategory';
export * from './parameterSubCategory';
export * from './parameterSelected';
export * from './parameterValue';
export * from './report';
export * from './tableState';
export * from './round';
export * from './society';
export * from './user';
export * from './accounting';
export * from './admin';
export * from './article';
export * from './company';
export * from './common';
export * from './livraison';
export * from './stock';
export * from './contact';
export * from './pricing';
export * from './packaging';
export * from './TAX';
export * from './widget';
export * from './commercialOperation';
export * from './enum';



export enum ApplicationMode { COMMUN, HORTI, VITI }
export enum State { BLOCKED, ALLOWED }
export enum ArticleType { ARTICLE,  SUPPLY }
/**
 * Pour qui le paramétre est destiné
 */
export enum ParamScope { SOCIETY_AND_USER, SOCIETY, USER }
export function getState(value: State): string {
    let wording: string;
    if (value === State.BLOCKED) {
        wording = 'Bloqué';
    } else if (value === State.ALLOWED) {
        wording = 'Autorisé';
    }
    return wording;
}

export function getApplicationMode(value: ApplicationMode): string {
    let wording: string;
    if (value === ApplicationMode.COMMUN) {
        wording = 'Commun';
    } else if (value === ApplicationMode.HORTI) {
        wording = 'Horti';
    } else if (value === ApplicationMode.VITI) {
        wording = 'Viti';
    }

    return wording;
}
