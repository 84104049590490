import { BaseEntity2 } from '..';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';

export class CommercialOperationPricing extends BaseEntity2 {
    htBaseTotal: number;
    htCurrencyTotal: number;
    ttcBaseTotal: number;
    ttcCurrencyTotal: number;
    vatBaseTotal: number;
    vatCurrencyTotal: number;
    discountNet: number;
    tradeDiscountNet: number;
    /**
     * Valeur total Type accompte
    */
    advancePaymentNet: number;
    netToPay: number;
    htCurrencySubTotal: number;
    ttcCurrencySubTotal: number;
    discountAmount: number;
    constructor() {
        super();
        this.htBaseTotal = 0;
        this.htCurrencyTotal = 0;
        this.ttcBaseTotal = 0;
        this.ttcCurrencyTotal = 0;
        this.vatBaseTotal = 0;
        this.vatCurrencyTotal = 0;
        this.discountNet = 0;
        this.tradeDiscountNet = 0;
        this.netToPay = 0;
        this.htCurrencySubTotal = 0;
        this.ttcCurrencySubTotal = 0;
        this.discountAmount = 0;
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.excluded = ['dateUpdated', 'dateCreated', 'version', 'id'];
        return specificFieldForConfscreenModel;
    }
}
