import { UserType, CompanyType, ApplicationMode } from '@app/shared/model';
export interface IMenuItem {
    identifier?: string;
    text: string;
    path?: string;
    icon?: string | Function;
    iconAfter?: string | Function;
    iconType?: 'ngcIcons';
    selectionPrefix?: string[];
    excludedSelectionPrefix?: string[];
    display?: UserType[];
    items?: ISubMenuItem[];
    expanded?: boolean;

}
export interface ISubMenuItem {
    identifier?: string;
    text: string | Function;
    path?: string;
    icon?: string | Function;
    iconType?: 'ngcIcons';
    selectionPrefix?: string[];
    excludedSelectionPrefix?: string[];
    display?: UserType[];
    items?: ISubSubMenuItem[];
    expanded?: boolean;
}
export interface ISubSubMenuItem {
    identifier?: string;
    text: string | Function;
    path: string;
    icon?: string | Function;
    iconType?: 'ngcIcons';
    selectionPrefix?: string[];
    excludedSelectionPrefix?: string[];
    display?: UserType[];
}

export const AllMenuItems: IMenuItem[] = [
    {
        text: 'Menu.Dashboard',
        icon: 'dashboard',
        iconType: 'ngcIcons',
        path: '/dashboard',
        selectionPrefix: ['/dashboard'],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Thirds',
        icon: 'company',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'Menu.Customers',
                path: '/company/' + CompanyType.CUSTOMER + '/grid',
                selectionPrefix: ['/company/' + CompanyType.CUSTOMER],
                excludedSelectionPrefix: ['/company/' + CompanyType.CUSTOMER + '/settings', '/parameters'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.Suppliers',
                path: '/company/' + CompanyType.SUPPLIER + '/grid',
                selectionPrefix: ['/company/' + CompanyType.SUPPLIER],
                excludedSelectionPrefix: ['/company/' + CompanyType.SUPPLIER + '/settings', '/parameters'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.Contacts',
                path: '/contact',
                selectionPrefix: ['/contact'],
                excludedSelectionPrefix: ['/contact/settings', '/parameters'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
        ],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Articles',
        icon: (appMode: ApplicationMode): string => {
            if (appMode === ApplicationMode.VITI) {
                return 'bottles';
            } else {
                return 'plant';
            }
        },
        iconAfter:(appMode: ApplicationMode, errorRegi: boolean): string => {
            if (appMode === ApplicationMode.VITI && errorRegi) {
                return 'info';
            }
        },
        iconType: 'ngcIcons',
        selectionPrefix: ['/article'],
        excludedSelectionPrefix: ['/article/settings', '/article/product', '/parameters'],
        path: '/article',
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Rates',
        icon: 'price',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'BasicRate',
                path: '/basicRate',
                selectionPrefix: ['/basicRate'],
                excludedSelectionPrefix: [],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            }
        ]
    },
    {
        text: 'Menu.Commercial',
        icon: 'CommercialOperation',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'Menu.OperationCustomer',
                iconType: 'ngcIcons',
                selectionPrefix: ['/operation/vente'],
                items: [
                    {
                        text: 'Menu.Commande',
                        path: '/operation/vente/commandes',
                        selectionPrefix: ['/operation/vente/commandes'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                    {
                        text: 'Menu.Offre',
                        path: '/operation/vente/offres',
                        selectionPrefix: ['/operation/vente/offres'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                    {
                        text: 'Menu.Ticket',
                        path: '/operation/vente/tickets',
                        selectionPrefix: ['/operation/vente/tickets'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                    {
                        text: 'Menu.AllOperation',
                        path: '/operation/vente',
                        selectionPrefix: ['/operation/vente'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                ]
            },
            {
                text: 'Menu.Logistic',
                iconType: 'ngcIcons',
                selectionPrefix: ['/operation/logistic'],
                items: [
                    {
                        text: 'Menu.Expedition',
                        path: '/operation/logistic/toShip',
                        selectionPrefix: ['/operation/logistic/toShip'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    }
                ]
            },
            {
                text: 'Menu.Invoices',
                iconType: 'ngcIcons',
                selectionPrefix: ['/trackInvoices','/reglement'],
                items: [
                    {
                        text: 'Menu.ToBill',
                        path: '/operation/trackInvoices/toBill',
                        selectionPrefix: ['/operation/trackInvoices/toBill'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                    {
                        text: 'Menu.TrackInvoices',
                        path: '/trackInvoices',
                        selectionPrefix: ['/trackInvoices'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                    {
                        text: 'Menu.Reglement',
                        path: '/reglement',
                        selectionPrefix: ['/reglement'],
                        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
                    },
                ]
            }
        ],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Purchase',
        icon: 'purchase',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'Menu.OperationSupplier',
                path: '/operation/achat',
                selectionPrefix: ['/operation/achat'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            }

        ],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Commissions',
        icon: 'commissions',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'Menu.RepresentativeManagement',
                path: '/commissionCourtage/' + CompanyType.CUSTOMER + '/representative',
                selectionPrefix: ['/commissionCourtage/' + CompanyType.CUSTOMER],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.BrokerManagement',
                path: '/commissionCourtage/' + CompanyType.SUPPLIER + '/representative',
                selectionPrefix: ['/commissionCourtage/' + CompanyType.SUPPLIER],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.CommissionTracking',
                path: '/commissionCourtage/commission/' + CompanyType.CUSTOMER,
                selectionPrefix: ['/commissionCourtage/commission/' + CompanyType.CUSTOMER],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.BrokerageTracking',
                path: '/commissionCourtage/brokerage/'+ CompanyType.SUPPLIER,
                selectionPrefix: ['/commissionCourtage/brokerage/'+ CompanyType.SUPPLIER],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            }
        ],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.Stocks',
        icon: 'stocks',
        iconType: 'ngcIcons',
        items: [
            {
                text: 'Menu.StocksMvts',
                path: '/stock/mouvement',
                selectionPrefix: ['/stock/mouvement'],
                excludedSelectionPrefix: ['/parameters'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.StockLot',
                path: '/stock/stocklot',
                selectionPrefix: ['/stock/stocklot'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            },
            {
                text: 'Menu.Inventory',
                path: '/stock/inventory',
                selectionPrefix: ['/stock/inventory'],
                display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
            }
        ],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Parameters.parameters',
        icon: 'setting',
        iconType: 'ngcIcons',
        path: '/parameters',
        selectionPrefix: ['/parameters'],
        display: [UserType.ADMINISTRATOR, UserType.USER, UserType.ADMINISTRATION_IDSYSTEMES]
    },
    {
        text: 'Menu.AppSettings',
        icon: 'cog',
        iconType: 'ngcIcons',
        path: '/superAdmin/application-parameters',
        selectionPrefix: ['/superAdmin/application-parameters'],
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.LoggedUsers',
        icon: 'profileCheck',
        iconType: 'ngcIcons',
        path: '/superAdmin/connected-users',
        selectionPrefix: ['/superAdmin/connected-users'],
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.Report',
        icon: 'clipBoard',
        iconType: 'ngcIcons',
        path: '/superAdmin/report',
        selectionPrefix: ['/superAdmin/report'],
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.Billing',
        icon: 'creditCard',
        iconType: 'ngcIcons',
        path: '/superAdmin/billing',
        selectionPrefix: ['/superAdmin/billing'],
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.Administration',
        icon: 'userCog',
        iconType: 'ngcIcons',
        path: '/superAdmin/administration',
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.Environments',
        icon: 'laptop',
        iconType: 'ngcIcons',
        path: '/superAdmin/environments',
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.MailModel',
        icon: 'mail',
        iconType: 'ngcIcons',
        path: '/superAdmin/mail',
        display: [UserType.SUPER_ADMINISTRATOR]
    },
    {
        text: 'Menu.EtiquetteModel',
        //icon: 'mail',
        iconType: 'ngcIcons',
        path: '/superAdmin/etiquette',
        display: [UserType.SUPER_ADMINISTRATOR]
    }

];
