import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CustomerFollowUpStatus, SupplierFollowUpStatus, CompanyType } from 'app/shared/model';
import { DaoGridStateBaseService } from './dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfiguration } from './application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
// TODO
export class FollowUpStatusService extends DaoGridStateBaseService {

    constructor(
        private http: HttpClient,
        private router: Router, private translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(companyType: CompanyType): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsFollowUpStatus + companyType;
        if (!this[companyType]) {
            this[companyType] = this.http.get<any[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }

        return this[companyType];
    }

    post(payload: CustomerFollowUpStatus | SupplierFollowUpStatus, companyType: CompanyType): Observable<CustomerFollowUpStatus | SupplierFollowUpStatus> {
        const url = this.appConfiguration.UrlsConfig.wsFollowUpStatus + companyType;
        return this.http.post<any>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    /**
     * Update a location
     * @param code location code
     * @param payload location data
     */
    put(code: string, payload: CustomerFollowUpStatus | SupplierFollowUpStatus, companyType: CompanyType): Observable<CustomerFollowUpStatus | SupplierFollowUpStatus> {
        const url = this.appConfiguration.UrlsConfig.wsFollowUpStatus + companyType + '/' + code;
        return this.http.put<CustomerFollowUpStatus | SupplierFollowUpStatus>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType: CompanyType) => {
        this[companyType] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any, companyType: CompanyType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsFollowUpStatus + companyType + '/' + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: any, companyType: CompanyType) {
        this.router.navigate(['/company/' + companyType + '/settings/follow-up-status/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(companyType: CompanyType): void {
        this.router.navigate(['/company/' + companyType + '/settings/follow-up-status/create']);
    }
}

