<div class="menu-container" [ngClass]="{'sidebar-closed': !sidebarService.opened}">
    <div class="brand brand-open" routerLink="/" *ngIf="sidebarService.opened">
        <!-- <img src="./../../../../assets/icons/nextgclogo.png" alt="NextGC" width="124px"  height="44px" > -->
        <div class="logo-img">
            <img *ngIf="applicationMode === 2" src="./../../../../assets/icons/Logo_iDViniFlow_Horizontal_White_RVB.png"
                alt="NextGC" width="124px" height="44px">
            <img *ngIf="applicationMode === 1"
                src="./../../../../assets/icons/Logo_iDHortiFlow_Horizontal_White_RVB.png" alt="NextGC" width="124px"
                height="44px">
        </div>
    </div>
    <div class="brand brand-close" routerLink="/" *ngIf="!sidebarService.opened">
        <!-- <img src="./../../../../assets/icons/logo-icon.png" alt="NextGC" width="37px"> -->
        <img *ngIf="applicationMode === 2" src="./../../../../assets/icons/Sigle_iDViniFlow_RVB.png" alt="NextGC"
            width="37px">
        <img *ngIf="applicationMode === 1" src="./../../../../assets/icons/Sigle_iDHortiFlow_RVB.png" alt="NextGC"
            width="37px">
    </div>
    <dx-tree-view 
    expandEvent="click" 
    width="100%" 
    [focusStateEnabled]="false"
    [items]="menuContent" 
    (onInitialized)="onTreeViewInitialized($event)"
    (onItemClick)="onItemClick($event)"
    (onItemExpanded)="onItemExpanded($event)"
    selectionMode="single"
    keyExpr="identifier"
    selectedExpr="selected"
    class="dx-theme-accent-as-text-color dx-theme-accent-as-border-color" itemTemplate="itemTemplate">
        <div *dxTemplate="let itemObj of 'itemTemplate'">
            <div class="sidebar-menu-item">
                <span *ngIf="itemObj.icon">
                    <ngc-icon *ngIf="itemObj.iconType === 'ngcIcons' || !itemObj.iconType" [icon]="itemObj.icon"
                        size="20"></ngc-icon>


                </span>
                <span *ngIf="itemObj.icon" [hidden]="!sidebarService.opened" class="title">{{itemObj.text}}
                    <ngc-icon *ngIf="itemObj.iconAfter" [icon]="itemObj.iconAfter" class="iconAfter"
                        size="20">
                    </ngc-icon>
                </span>
                <br>
                <span *ngIf="!itemObj.icon" [hidden]="!sidebarService.opened" class="subtTitle">{{itemObj.text}}</span>

            </div>
        </div>
    </dx-tree-view>

</div>
