import { BaseEntity1 } from '@app/shared/model/base-entity1';

export class BaseEntity2 extends BaseEntity1 {
    id: number;
    version: number;

    constructor() {
        super();
        this.id = 0;
        this.version = 0;
    }
}
