import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from '../application/app-configuration.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    constructor(private http: HttpClient, private appConfiguration: AppConfiguration) { }

    mobilStoreImport(): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsImportApi + 'mobilStore';
        return this.http.post<any>(url, null);
    }


}
