import {
    Packaging
} from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';

export class PortScale extends BaseEntity2 {
    designation: string;
    boundType: number;
    calculationMode: number;
    roundToTheUpperInteger: number;
    recalculateToGroupBilling: number;
    transportationBase: Packaging;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.boundType = 0;
        this.calculationMode = 0;
        this.roundToTheUpperInteger = 0;
        this.recalculateToGroupBilling = 0;
        this.transportationBase = null;
        this.visible = true;
    }

    initValues(): Partial<PortScale> {
        return {
            visible: true
        };
    }
}
