import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import notify from 'devextreme/ui/notify';

import { AuthService } from '../services';
import * as globalsParameter from '@app/globalsParameter';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const requestObservable = next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // cas des exception ne devant pas afficher de notification
                if (error?.error?.ManageSpecificNotify || error.url?.includes('disconnect')) {
                    return throwError(error);
                }
                if (error?.error?.Message == 'UserIsNotLoggedIn') {
                    this.authService.logout();
                    notify('Vous avez été déconnecté suite à une autre connexion du même utilisateur !', 'info', globalsParameter.notifyInfoDelay);
                } else {
                    if (typeof error?.error?.Message == 'string') {
                        notify(error.error.Message, 'error', globalsParameter.notifyErrorDelay);
                    } else {
                        notify(JSON.stringify(error?.error?.Message), 'error', globalsParameter.notifyErrorDelay);
                    }
                }

                return throwError(error);
            })
        );

        return requestObservable;
    }
}
