export enum RoundingRulePackaging {
    NO_RULE, NUMBER/*, CALCULATED*/
}

export enum GestionDecimal {
    ENTIER,
    DECIMALE
}

export enum MailCategory {
    FICHE_SALE,
    FICHE_PURCHASE,
    ALL
}

export enum MailFromSource {
    PrincipalCompany,
    LogisitcCommercialOperation,
    InvoicingCompany,
    Address,
    Contact
}

// Regle Impression
export enum RegleImpression {
    NONE,
    ETIQUETTE_PER_LIGNE,
    ETIQUETTE_PER_UNITE,
    ETIQUETTE_PER_EMBALLAGE,
    ETIQUETTE_PER_EMBALLAGEFINAL
}

// Regle Etiquette
export enum RegleEtiquette {
    NONE,
    CUSTOMER,
    ARTICLE
}

// Report type
export enum ReportType {
    BASE,
    PERSO
}

// Report type
export enum MouvementRoutesManagement {
    FROM_INVENTORY,
    FROM_STOCKLOT
}

export enum OriginPage {
    NEW_OPERATION,
    DUPLICATE_OPERATION,
    REF_COMMANDE,
    INVOICING,
    OPERATION_DETAIL,
    COMPANY_DETAIL,
    REGLEMENT,
    TRACK_INVOICE,
    OPERATION_TO_SHIP,
    OPERATION_TO_BILL,
}

// InvoicedRules -> Gratuité : Règle de facturation des droits et taxes
export enum InvoicedRules {
    GENERAL_SETTINGS, // Selon paramètrage général
    ALL_TAXES_CHARGED, // Droits, Vss et taxes facturé
    NOT_CHARGED, // Non facturés
    VITI_AND_DRINK_TAXES_ONLY, // Droits viti et taxes boisson seulement
    VIGNETTE_ONLY, // Vignette seulement
}
// Frequence
export enum Frequence {
    EVERY_DAY,
    EVERY_WEEK,
    EVERY_MONTH,
    EVERY_YEAR
}

// Action
export enum Action {
    CUS_OPE_COM_EXPEDITION = 3,
    CUS_OPE_COM_FACTURATION = 4,
    CUS_OPE_COM_UPDATE_INTERNALSTATE_TO_PREPARE = 5,
    CUS_OPE_COM_UPDATE_INTERNALSTATE_TO_PREPARE_INPROGRESS = 6,
    CUS_OPE_COM_UPDATE_INTERNALSTATE_TO_PREPARED = 7,
    CUS_OPE_COM_UPDATE_INTERNALSTATE_TO_VALIDATED = 8,
}

// Action
export enum TacheLogState {
    STARTED,
    IN_PROGESS,
    FINISHED,
    ERROR
}

export enum TVAIntracomValidator {
    VALID,
    INVALID,
    INVALID_INPUT,
    OTHER
}

export enum CHECKVALIDITY {
    VALID,
    INVALID,
    INVALID_INPUT,
    NOTCHECKED
}


// CodeCriteriaMobilStore
export enum CodeCriteriaMobilstoreForCustomer {
    CUSCRITERIA1 = 1,
    CUSCRITERIA2 = 2,
    CUSCRITERIA3 = 3,
    CUSCRITERIA4 = 4,
    CUSCRITERIA5 = 5,
    CUSCRITERIA6 = 6,
    CUSCRITERIA7 = 7,
    CUSCRITERIA8 = 8,
    CUSCRITERIA9 = 9,
    CUSCRITERIA10 = 10,
    CUSCRITERIA11 = 11,
    CUSCRITERIA12 = 12,
    CUSCRITERIA13 = 13,
    CUSCRITERIA14 = 14,
    CUSCRITERIA15 = 15,
    CUSCRITERIA16 = 16,
    CUSCRITERIA17 = 17,
    CUSCRITERIA18 = 18
}

export enum CodeCriteriaMobilstoreForArticle {
    ARTCRITERIA1 = 1,
    ARTCRITERIA2 = 2,
    ARTCRITERIA3 = 3,
    ARTCRITERIA4 = 4,
    ARTCRITERIA5 = 5,
    ARTCRITERIA6 = 6,
    ARTCRITERIA7 = 7,
    ARTCRITERIA8 = 8,
    ARTCRITERIA9 = 9,
    ARTCRITERIA10 = 10,
    ARTCRITERIA11 = 11,
    ARTCRITERIA12 = 12,
    ARTCRITERIA13 = 13,
    ARTCRITERIA14 = 14,
    ARTCRITERIA15 = 15,
    ARTCRITERIA16 = 16,
    ARTCRITERIA17 = 17,
    ARTCRITERIA18 = 18,
    ARTCRITERIA19 = 19,
    ARTCRITERIA20 = 20,
    ARTCRITERIA21 = 21
}

export enum NumAcciseValidator {
    VALID,
    INVALID,
    INVALID_INPUT,
    OTHER
}

export enum CHECKNumAccise {
    VALID,
    INVALID,
    NOTCHECKED
}

export enum Exploitation {
    Export = 0,
    CEE = 1,
    France = 2
}

export enum ZoneViticole {
    A = 1,
    B = 2,
    CI = 3,
    CII = 4,
    CIII_a = 5,
    CIII_b = 6
}

export enum TypeSignalR {
    NONE = 0,
    ARTICLE_REGI_OK = 1,
    ARTICLE_REGI_FAILED = 2
}

export enum IDRegiFlowStatus {
    ERROR = 0,
    COMPLETED = 1
}

// Commission / Brokerage
export enum PaymentCommissionRule {
    NOT_COMMISSIONED = 0,
    BILLING = 1,
    ON_COLLECTION = 2
}
export enum GestionRegiFlow {
    NON_VALIDE = 0,
    VALIDE = 1,
    EN_COURS = 2,
    ERROR = 3
}

export enum CommissionBrokerageType {
    AMOUNT = 0,
    RATE = 1
}

export enum CommissionBrokerageBaseCalcul {
    QUANTITE_TOTALE = 0,
    QUANTITE_HORS_GRATUIT = 1,
    MONTANT_NET_LIGNE = 2,
    MONTANT_NET_HORS_DROIT = 3,
    MONTANT_NET_NET_HORS_DROIT = 4,
    MONTANT_NET = 5
}

export enum CommissionBrokerageState {
    ATTENTE_PAIEMENT = 0,
    BONNE_A_PAYER = 1,
    PAYEE = 2
}
