import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { TableState, PaymentChoice, CompanyType } from 'app/shared/model';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from './application/app-configuration.service';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class PaymentChoiceService extends DaoGridStateBaseService implements IDaoBaseService {

    private _list: Observable<PaymentChoice[]> = null;
    private _gridState: Observable<JSON> = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration) { super(http, translate, appConfiguration); }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsPaymentChoice;
        if (!this._list) {
            this._list = this.http.get<PaymentChoice[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this._list;
    }

    get(id: number): Observable<PaymentChoice> {
        const url = this.appConfiguration.UrlsConfig.wsPaymentChoice + id;
        return this.http.get<PaymentChoice>(url);
    }

    post(payload: PaymentChoice): Observable<NGCResults<PaymentChoice>> {
        const url = this.appConfiguration.UrlsConfig.wsPaymentChoice;
        return this.http.post<NGCResults<PaymentChoice>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a location
    * @param id location code
    * @param payload location data
    */
    put(id: string, payload: PaymentChoice): Observable<NGCResults<PaymentChoice>> {
        const url = this.appConfiguration.UrlsConfig.wsPaymentChoice + id;
        return this.http.put<NGCResults<PaymentChoice>>(url, payload);
    }
    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour récuperer table state
    getGridState(): Observable<JSON> {
        const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('PaymentChoice');
        if (!this._gridState) {
            this._gridState = this.http.get<JSON>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this._gridState;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des table state
    clearGridStateCache() {
        this._gridState = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsPaymentChoice;
        return this.http.delete(url + id);
    }

    navigateToDetail(id) {
        return this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/paymentChoice/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/paymentChoice/create']);
    }

    navigateToList(): void {
        this.router.navigate(['/parameters/' + CompanyType.COMMUN + '/paymentChoice']);
    }

}

