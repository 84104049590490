<div class="dx-field" *ngIf="((!_templateDirective ||
    !_templateDirective['field']) && (labelVisible || visible))">
    <div class="divLabelField" *ngIf="!field.insideGroup">

        <label *ngIf="(((item[field.dataField] ||
            focusedField ||
            openedDropDown ||
            field.editorType === 'dxSwitch'||
            field.editorType === 'dxCheckBox' ||
            field.editorType === 'NgcInterval' ||
            field.editorType === 'dxRadioGroup' ||
            (dxGridPicker && dxGridPicker.focusedField) ||
            field.editorType === 'dxDateBox' && focusedField ||
            item[field.dataField] === 0))
            &&
            field.editorType !== 'dxSwitch' &&
            field.editorType !== 'dxCheckBox' &&
            field.editorType !== 'dxDataGridField' &&
            field.editorType !== 'dxGridForForm' &&
            field.editorType !== 'dxList' &&
            field.editorType !== 'dxFileUploader' &&
            field.editorType !== 'dxTagBox' &&
            field.editorType !== 'info')
            ||
            (field.editorType === 'dxTagBox' &&
            ((item[field.dataField] && item[field.dataField].length> 0) || focusedField))" class="dx-field-label"
            [for]="field.dataField" [hidden]="!labelVisible" [ngClass]="{'dx-field-label-focus': ((focusedField ||
            openedDropDown) && validField), 'dx-field-label-error':
            !validField}">{{
            field.label.text }}
            <span class="dx-field-item-required-mark" *ngIf="field.isRequired">*</span>
        </label>
        <span class="help-icon" [id]="field.dataField" *ngIf="field.helpText &&
            contextualHelpOn" (mouseenter)="toggleHelp()" (mouseleave)="toggleHelp()">
            <ngc-icon size="16" icon="info"></ngc-icon>
        </span>
        <dx-popover [target]="'#' + field.dataField" position="top" [width]="346" [(visible)]="helpVisible">
            <div *dxTemplate="let data= model of 'content'">
                {{ field.helpText | translate }}
            </div>
        </dx-popover>
    </div>

    <!-- TEXT BOX -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxTextBox'">
        <dx-text-box [disabled]="disabled  || !editable" valueChangeEvent="input keyup"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible"
            [mode]="field.editorOptions ? field.editorOptions.mode : 'text' " (onFocusIn)="focusedField = true"
            (onFocusOut)="focusedField = false" [placeholder]="getplaceHolder()" [mask]="mask"
            [readOnly]="field.editorOptions ? field.editorOptions.readOnly : false "
            (keydown.enter)="$event.preventDefault()" [height]="(field.height?
            field.height :
            '43px')" [ngClass]="field.cssClass">
            <dx-validator (onValidated)="onFieldValidated($event)">
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>
            <ng-container *ngIf="field.textBoxActions">
                <dxi-button *ngFor="let data of field.textBoxActions" [name]="data.name" [location]="data.location"
                    [options]="data.options">
                </dxi-button>
            </ng-container>
            <dxi-button *ngIf="field.inputType === 'phone' &&
                item[field.dataField]" name="call" location="after" [options]="phoneButton">
            </dxi-button>
            <dxi-button *ngIf="field.inputType === 'showPassword'" name="find" location="after"
                [options]="showPasswordButton">
                <div *dxTemplate="let data of 'templateShowPassword'">
                    <ngc-icon size="20" icon="show"></ngc-icon>
                </div>
                <div *dxTemplate="let data of 'templateHidePassword'">
                    <ngc-icon size="20" icon="showOff"></ngc-icon>
                </div>
            </dxi-button>
            <dxi-button *ngIf="field.inputType === 'url'" name="link" location="after" [options]="urlButton">
            </dxi-button>
            <dxi-button *ngIf="field.inputType === 'email'" name="email" location="after" [options]="emailButton">
            </dxi-button>
            <ng-container *ngIf="field.inputType === 'TVA'">
                <dxi-button name="tvaDisabledButton" location="after" [options]="disabledCheckButton">
                    <div *dxTemplate="let data of 'templateCheck'">
                        <ng-container *ngIf="validTva === checkValidity.INVALID">
                            <ngc-icon size="20" icon="control"></ngc-icon>
                        </ng-container>
                        <ng-container *ngIf="validTva === checkValidity.VALID">
                            <ngc-icon size="20" icon="check"></ngc-icon>
                        </ng-container>
                    </div>
                </dxi-button>
                <dxi-button name="tvaButton" location="after" [options]="tvaButton">
                    <div *dxTemplate="let data of 'templateCheckVat'">
                        <ngc-icon size="20" icon="apure"></ngc-icon>
                    </div>
                </dxi-button>
            </ng-container>
            <ng-container *ngIf="field.inputType === 'exciseNumber'">
                <dxi-button *ngIf="validExciseNumber !== checkValidity.NOTCHECKED" name="checkExciseNumberResult" location="after"
                    [options]="disabledCheckButton">
                    <div *dxTemplate="let data of 'templateCheck'">
                        <ng-container *ngIf="validExciseNumber === checkValidity.INVALID">
                            <ngc-icon size="20" icon="control"></ngc-icon>
                        </ng-container>
                        <ng-container *ngIf="validExciseNumber === checkValidity.VALID">
                            <ngc-icon size="20" icon="check"></ngc-icon>
                        </ng-container>
                    </div>
                </dxi-button>
                <dxi-button name="checkExciseNumber" location="after" [options]="exciseNumberButton">
                    <div *dxTemplate="let data of 'templateCheckExciseNbr'">
                        <ngc-icon size="20" icon="apure"></ngc-icon>
                    </div>
                </dxi-button>
            </ng-container>
        </dx-text-box>
        <div *ngIf="(field.inputType === 'TVA' && validTva ===
            checkValidity.INVALID) || (field.inputType === 'exciseNumber' &&
            validExciseNumber === checkValidity.INVALID)" class="errorMessage">{{errorMessage}}</div>
    </div>
    <!-- TEXT AREA -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxTextArea'">
        <dx-text-area [disabled]="disabled  || !editable" [height]="field.height"
            [autoResizeEnabled]="field.editorOptions ? field.editorOptions.autoResizeEnabled : false"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible"
            [readOnly]="field.editorOptions ? field.editorOptions.readOnly : false" [placeholder]="getplaceHolder()"
            [maxLength]="field.maxLenght">
            <dx-validator (onValidated)="onFieldValidated($event)">
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-text-area>
    </div>

    <!-- DX Radio -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxRadioGroup'">
        <dx-radio-group (onValueChanged)="onValueChanged($event, 1)" [valueExpr]="field.editorOptions.valueExpr"
            [displayExpr]="field.editorOptions.displayExpr" [disabled]="disabled
            || !editable" [dataSource]="field.editorOptions.dataSource" layout="horizontal" [value]="selectBoxValue"
            class="radioGroup" [height]="(field.height?field.height :'43px')">
        </dx-radio-group>
    </div>
    <!-- Tag Box -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxTagBox'">
        <dx-tag-box [disabled]="disabled" [items]="null" [value]="field.values
            || item[field.dataField]" [openOnFieldClick]="false" [acceptCustomValue]="true" (onValueChanged)="onValueChanged($event,
            field)" [placeholder]="getplaceHolder()" (onFocusOut)="focusedField= false"
            (onFocusIn)="focusedField= true">
        </dx-tag-box>
    </div>
    <!-- MULTISELECT-->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxMultiSelectBox'">
        <dx-tag-box class="multiSelect"  [disabled]="disabled" [valueExpr]="field.editorOptions.valueExpr"
        [displayExpr]="field.editorOptions.displayExpr" [disabled]="disabled
        || !editable" [dataSource]="field.editorOptions.dataSource"
        [openOnFieldClick]="true" [acceptCustomValue]="false" (onValueChanged)="onValueChanged($event,
        field)" [placeholder]="getplaceHolder()" (onFocusOut)="focusedField= false"
        (onFocusIn)="focusedField= true" [showSelectionControls]="true" [showDropDownButton]="true" [value]="item[field.dataField]">
        <dx-validator>
            <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                    | translate: {field: field.label.text}) :
                    validator.message" [pattern]="validator.pattern" [trim]="validator.trim"
                    [ignoreEmptyValue]="validator.ignoreEmptyValue"
                    [validationCallback]="validator.validationCallback">
                </dxi-validation-rule>
            </ng-template>
        </dx-validator>
    </dx-tag-box>

    </div>
    <!-- SELECT BOX -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxSelectBox'">
        <dx-drop-down-box [value]="selectBoxValue"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            [disabled]="disabled  || !editable" [valueExpr]="field.editorOptions.valueExpr" [(opened)]="openedDropDown"
            [displayExpr]="field.editorOptions.displayExpr" [placeholder]="getplaceHolder()"
            [dataSource]="dropdownValues[field.dataField]" (onValueChanged)="onDropDownValueChanged($event)"
            dropDownButtonTemplate="dropDownButtonTemplate" [ngClass]="{'openedDropDownClass': openedDropDown}"
            [isValid]="!field.errorList || field.errorList.isValid" *ngIf="templateExist('buttonAddItemInCbb')"
            [height]="(field.height? field.height : '43px')"
            [showClearButton]="!field.isRequired && selectBoxValue !== null">
            <div *dxTemplate="let data of 'dropDownButtonTemplate'">
                <ngc-icon size="16" icon="dropdown" *ngIf="!openedDropDown"></ngc-icon>
                <ngc-icon size="16" icon="dropup" *ngIf="openedDropDown"></ngc-icon>
            </div>
            <div *dxTemplate="let data of 'content'">
                <div class="dropDownList">
                    <dx-list id="listField" [dataSource]="dropdownValues[field.dataField]" selectionMode="single"
                        [displayExpr]="field.editorOptions.displayExpr" [selectedItemKeys]="selectedDropItem"
                        (onItemClick)="onDropDownValueChanged($event)">
                    </dx-list>
                </div>
                <div class="dropDownAdd">
                    <dx-button [focusStateEnabled]="false" [activeStateEnabled]="false" [hoverStateEnabled]="false"
                        (onClick)="addNewToSelectBox($event)" type="default" stylingMode="text" class="addBtn">
                        <ngc-icon size="20" icon="roundedAdd"></ngc-icon>
                        <span class="btnLabel">
                            {{'ButtonLabel.NEW' | translate}}
                        </span>
                    </dx-button>
                </div>
            </div>
            <dx-validator (onValidated)="onFieldValidated($event)">
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>

        </dx-drop-down-box>

        <dx-select-box
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            [valueExpr]="field.editorOptions.valueExpr" [dataSource]="dropdownValues[field.dataField]"
            [deferRendering]="field.deferRendering" [openOnFieldClick]="true" (onFocusIn)="focusedField = true"
            (onFocusOut)="focusedField = false" [placeholder]="getplaceHolder()" [disabled]="disabled  || !editable"
            [value]="selectBoxValue" [displayExpr]="field.editorOptions.displayExpr"
            [searchEnabled]="field.editorOptions.searchEnabled === undefined ? true :field.editorOptions.searchEnabled"
            (onValueChanged)="onSelectBoxValueChanged($event, 1)" [(opened)]="openedSelectBox"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible"
            [valueChangeEvent]="field.editorOptions.valueChangeEvent === undefined ? 'change' : field.editorOptions.valueChangeEvent"
            dropDownButtonTemplate="dropDownButtonTemplate" *ngIf="!templateExist('buttonAddItemInCbb')"
            [height]="(field.height? field.height : '43px') "
            [showClearButton]="!field.isRequired && selectBoxValue !== null"
            [acceptCustomValue]="field.editorOptions.acceptCustomValue === undefined ? false : field.editorOptions.acceptCustomValue">

            <div *dxTemplate="let data of 'dropDownButtonTemplate'">
                <ngc-icon size="16" icon="dropdown" *ngIf="!openedSelectBox"></ngc-icon>
                <ngc-icon size="16" icon="dropup" *ngIf="openedSelectBox"></ngc-icon>
            </div>


            <dx-validator (onValidated)="onFieldValidated($event)">
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>

        </dx-select-box>


    </div>
    <!-- DATAGRID PICKER -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxDataGrid' &&
        visible">
        <dx-data-grid-picker [type]="field.editorOptions.type ?
            field.editorOptions.type : 'popup'" [formField]="field" [valueExpr]="field.editorOptions.valueExpr"
            [disabled]="disabled ||
            !editable" [value]="item[field.dataField]" [showClearButton]="!field.isRequired"
            [fileName]="field.dataField" [dataGridInput]="field.editorOptions.dataGridInput"
            [displayExpr]="field.editorOptions.displayExpr" [searchEnabled]="true" (clearSelected)="deleteField($event)"
            (valueChanged)="onValueChanged($event, 1)" [isValid]="!field.errorList || field.errorList.isValid"
            [showFilters]="field.editorOptions.showFilters ?
            field.editorOptions.showFilters : true" [visible]="visible" [showPopup]="popupVisible"
            [editActionVisible]="editActionVisible" (editButtonClickedEvent)="onEditButtonClicked($event)">
        </dx-data-grid-picker>
    </div>
    <!-- DATAGRID Field -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxDataGridField'">
        <dx-data-grid-field [(dataSource)]="item[field.dataField]" [allowAdding]="!disabled && editable"
            [allowUpdating]="!disabled && editable" [allowDeleting]="!disabled
            && editable" [columns]="field.editorOptions.columnsdataGrid" [title]="field.label.text"
            [isRequired]="field.isRequired" (valueChanged)="onValueChanged($event)">
        </dx-data-grid-field>

    </div>
    <!-- NUMBER BOX -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxNumberBox'">
        <dx-number-box (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [showSpinButtons]="false" [disabled]="disabled  || !editable"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible" valueChangeEvent="keyup"
            (onFocusIn)="onFocusIn($event)" (onFocusOut)="focusedField = false" [placeholder]="getplaceHolder()"
            [min]="field.min" [format]="field.format" [ngClass]="field.cssClass">
            <ng-container *ngIf="field.buttons">
                <dxi-button *ngFor="let data of field.buttons" [name]="data.name" [location]="data.location"
                    [options]="data.options">
                </dxi-button>
            </ng-container>
            <dx-validator>
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max, maxNumericCharacter:
                        validator.maxNumericCharacter }) : validator.message" [pattern]="validator.pattern"
                        [trim]="validator.trim" [max]="validator.max" [min]="validator.min"
                        [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback"></dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-number-box>
    </div>
    <!-- COLOR BOX -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxColorBox'">
        <dx-color-box (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            [disabled]="disabled || !editable" [isValid]="!field.errorList || field.errorList.isValid"
            [editAlphaChannel]="false" [visible]="visible">
            <dx-validator>
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback"></dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-color-box>
    </div>
    <!-- DATE BOX -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxDateBox'">
        <dx-date-box [openOnFieldClick]="true" [inputAttr]="{autocomplete: 'off'}"
            [readOnly]="field.editorOptions ? field.editorOptions.readOnly : false" [disabled]="disabled  || !editable"
            (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible"
            [type]="field.editorOptions ? field.editorOptions.type : 'date'"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss" [displayFormat]="field.editorOptions?.displayFormat"
            dropDownButtonTemplate="dropDownButtonTemplate" (onFocusIn)="focusedField= true"
            (onFocusOut)="focusedField= false" [placeholder]="getplaceHolder()">
            <div *dxTemplate="let data of 'dropDownButtonTemplate'">
                <ngc-icon size="20" icon="calendar"></ngc-icon>
            </div>
            <dx-validator>
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-date-box>
    </div>
    <!-- BAR CODE -->
    <div class="dx-field-value dx-bar-code" *ngIf="field.editorType ===
        'dxBarCode'">

        <div class="dx-bar-code-header">

            <div class="dx-bar-code-title">{{field.label.text}}</div>

            <div class="dx-bar-code-buttons">
                <dx-button (onClick)="GenerateKeyBarCode('generateCodeBar')">
                    <ngc-icon size="32" icon="BarCode"></ngc-icon>
                </dx-button>

                <dx-button (onClick)="GenerateKeyBarCode('generateKey')">
                    <ngc-icon size="32" icon="Key"></ngc-icon>
                </dx-button>


            </div>

        </div>

        <ngc-barcode *ngIf="item[field.dataField]" [bc-value]="item[field.dataField]" [bc-display-value]="false"
            bc-format="EAN13"></ngc-barcode>

        <dx-text-box class="dx-bar-code-input" [disabled]="disabled  || !editable" valueChangeEvent="keyup"
            [inputAttr]="{id : field.dataField, autocomplete: field.autocompleteType === undefined ? 'off' : field.autocompleteType}"
            (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible"
            [mode]="field.editorOptions ? field.editorOptions.mode : 'text' " (onFocusIn)="focusedField = true"
            (onFocusOut)="focusedField = false" [placeholder]="getplaceHolder()"
            [readOnly]="field.editorOptions ? field.editorOptions.readOnly : false "
            (keydown.enter)="$event.preventDefault()" [height]="(field.height)" [ngClass]="field.cssClass">
            <dx-validator (onValidated)="onFieldValidated($event)">
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>

        </dx-text-box>

    </div>
    <!-- CHECK BOX -->
    <!-- TODO [inputAttr]="{id: field.dataField}" -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxCheckBox'">
        <ngc-checkbox [field]="field.dataField" [value]="item[field.dataField]" [disabled]="disabled || !editable"
            (valueChanged)='onValueChangedSwitch($event)'></ngc-checkbox>

    </div>
    <!-- Switch -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxSwitch'" style="margin-top: 20px;">
        <div>
            <div class="labelForSwitch">{{ field.label.text }} </div>
            <ngc-switch [value]="item[field.dataField]" [disabled]="disabled"
                (valueChanged)='onValueChangedSwitch($event)'></ngc-switch>
        </div>
    </div>
    <!-- FILE BOX -->
    <!-- TODO [inputAttr]="{id: field.dataField}" -->
    <app-file-upload *ngIf="field.editorType === 'dxFileUploader'" [item]="item" [field]="field"
        [disabled]="disabled || !editable" (valueChange)="onValueChanged($event)"
        (canDeactivate)="onCanDeactivateChanged($event, formItem)">
    </app-file-upload>
    <!-- HTML EDITOR -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxHtmlEditor'">
        <dx-html-editor class="html-editor-perso" [disabled]="disabled  || !editable"
            (onValueChanged)="onValueChanged($event)" [(value)]="item[field.dataField]" valueType="html"
            [isValid]="!field.errorList || field.errorList.isValid" [visible]="visible" [ngClass]="field.cssClass">
            <dxo-toolbar>
                <dxi-item *ngIf="!field.withoutAllItems" name="undo"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="redo"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item name="font" [acceptedValues]="['Arial', 'Courier New', 'Georgia',
                    'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman',
                    'Verdana']">
                </dxi-item>
                <dxi-item name="size" [acceptedValues]="['8pt', '10pt',
                    '12pt', '14pt', '18pt', '24pt', '36pt']">
                </dxi-item>

                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="alignJustify"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]">
                </dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="orderedList"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="bulletList"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="color"></dxi-item>
                <dxi-item name="background"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
                <dxi-item name="image"><i (click)="selectClick()"
                        class="dx-icon dx-icon-image dx-icon-image-customize"></i></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="separator"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="clear"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="codeBlock"></dxi-item>
                <dxi-item *ngIf="!field.withoutAllItems" name="blockquote"></dxi-item>
                <dx-file-uploader [multiple]="false" accept="image/*" (onValueChanged)="onFileChange($event)"
                    uploadMode="useButtons"></dx-file-uploader>
            </dxo-toolbar>
            <dxo-media-resizing [enabled]=true></dxo-media-resizing>
            <dx-validator>
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-html-editor>
        <dx-popup [showTitle]="true" title="Markup" [(visible)]="popupVisibleImg">
            <div>{{editorValue}}</div>
        </dx-popup>
    </div>
    <!-- interval pour DATE BOX -->
    <div class="dx-field-value dx-field-interval" *ngIf="field.editorType ===
        'NgcInterval'" [ngClass]="{'dx-field-interval-error': field.errorList &&
        !field.errorList.isValid}">
        <div class="divLabelField">
            <label class="dx-field-label">
                <div *ngIf="field.editorOptions.type === 'date'; else
                    hoursStart">{{'form.DAYSFROM' | translate}}</div>
                <ng-template #hoursStart>{{'form.HOURSFROM' | translate}}</ng-template>
            </label>
        </div>

        <dx-date-box [openOnFieldClick]="true" [inputAttr]="{id:
            field.dataField}" [disabled]="disabled || !editable"
            (onValueChanged)="onValueChangedInterval($event, field.dataField)" [(value)]="item[field.dataField]"
            [visible]="visible" [type]="field.editorOptions ?
            field.editorOptions.type : 'date'" dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
            [displayFormat]="field.editorOptions?.displayFormat" (onFocusIn)="focusedField= true"
            (onFocusOut)="focusedField= false" [placeholder]="getplaceHolder()">
        </dx-date-box>
        <div class="divLabelField">
            <label class="dx-field-label">
                <div *ngIf="field.editorOptions.type === 'date'; else hoursEnd">{{'form.DAYSTO'
                    | translate}}</div>
                <ng-template #hoursEnd>{{'form.HOURSTO' | translate}}</ng-template>
            </label>
        </div>
        <dx-date-box [openOnFieldClick]="true" [inputAttr]="{id:
            field.fin.dataField}" [disabled]="disabled || !editable" (onValueChanged)="onValueChangedInterval($event,
            field.fin.dataField)" [(value)]="item[field.fin.dataField]"
            [isValid]="!field.fin.errorList || field.fin.errorList.isValid"
            [type]="field.editorOptions ? field.editorOptions.type : 'date'"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss" [displayFormat]="field.editorOptions?.displayFormat"
            (onFocusIn)="focusedField= true" (onFocusOut)="focusedField= false" [placeholder]="getplaceHolder()">
            <dx-validator>
                <ng-template ngFor let-validator [ngForOf]="field.validationRules">
                    <dxi-validation-rule [type]="validator.type" [message]="validator.useTranslator ? (validator.message
                        | translate: {field: field.label.text, min:
                        validator.min, max: validator.max }) :
                        validator.message" [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                        [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                        [validationCallback]="validator.validationCallback">
                    </dxi-validation-rule>
                </ng-template>
            </dx-validator>
        </dx-date-box>
    </div>

    <!-- GRID FOR FORM -->
    <div class="dx-field-value dx-grid-for-form" *ngIf="field.editorType ===
        'dxGridForForm'">
        <app-data-grid [tableStateName]="field.editorOptions.dataGridOptions.tableStateName"
            [service]="field.editorOptions.dataGridOptions.service"
            [serviceOptions]="field.editorOptions.dataGridOptions.serviceOptions"
            [loadDataType]="field.editorOptions.dataGridOptions.loadDataType"
            [model]="field.editorOptions.dataGridOptions.model"
            [customFields]="field.editorOptions.dataGridOptions.customFields"
            [excludedFieldList]="field.editorOptions.dataGridOptions.excludedFieldList"
            [options]="field.editorOptions.dataGridOptions.options" [isGridForForm]="true"
            [pageTitle]="field.editorOptions.dataGridOptions.pageTitle"
            [pageTitleIcon]="field.editorOptions.dataGridOptions.pageTitleIcon"
            [isModeCreation]="field.editorOptions.dataGridOptions.isModeCreation"
            [height]="field.editorOptions.dataGridOptions.height" [itemGridForForm]="item"
            [fieldNameGridForForm]="field.dataField">
            <div *templateKey="'designationNumber' ; let data">
                {{data.data?.data?.type?.designation}}
                <a href="tel:{{data.data.data.number}}">
                    <ngc-icon size="20" icon="cellPhone" style="margin-left: 15px;"></ngc-icon>
                </a>
            </div>
            <div *templateKey="'traitementDesignation' ; let data">
                {{data.data?.data?.traitement?.designation}}
            </div>

        </app-data-grid>
    </div>
    <!-- LIST -->
    <div class="dx-field-value dx-list-field" *ngIf="field.editorType ===
        'dxList'">
        <app-dx-list [dataSource]="field.editorOptions.listOptions.dataSource"
            [service]="field.editorOptions.listOptions.service"
            [serviceOptions]="field.editorOptions.listOptions.serviceOptions"
            [icon]="field.editorOptions.listOptions.icon"
            [listName]="field.editorOptions.listOptions.listName"
            [loadDataType]="field.editorOptions.listOptions.loadDataType"
            [model]="field.editorOptions.listOptions.model"
            [customFields]="field.editorOptions.listOptions.customFields"
            [height]="field.editorOptions.listOptions.height" [cssClass]="field.cssClass" listForForm=true
            [canAddNewLine]="field.editorOptions.listOptions.canAddNewLine"
            [shouldDisableAddNewLineBtn]="field.editorOptions.listOptions.disableAddNewLineBtn"
            [canUpdateAndDelete]="field.editorOptions.listOptions.canUpdateAndDelete"
            (addNewLine)="onaddNewLineFromList($event)"
            (onListRowClick)="onListRowClick($event)"
            (updateLineFromList)="onUpdateLineFromList($event)"
            (deleteLineFromList)="onDeleteLineFromList($event)">
        </app-dx-list>
    </div>
    <!-- POPUP PRINT -->
    <div class="dx-field-value" *ngIf="field.editorType === 'dxPopupPrint'">
        <dx-text-box #dxTextBox [value]="getReportName()" [disabled]="disabled" (onFocusIn)="focusedField= true"
            (onFocusOut)="focusedField= false" [placeholder]="getplaceHolder()" class="clearButton"
            [isValid]="!field.errorList || field.errorList.isValid">
            <dxi-button *ngIf="item[field.dataField]" name="remove" location="after" [options]="removeButton">
            </dxi-button>
            <dxi-button name="find" location="after" [options]="findButton">
                <div *dxTemplate="let data of 'templatefind'">
                    <ngc-icon size="20" icon="search"></ngc-icon>
                </div>
            </dxi-button>
        </dx-text-box>

        <dx-popup-print #dxPopupPrint *ngIf="isPopupPrintVisible" [(isPopupPrintVisible)]="isPopupPrintVisible"
            [categoryEtiquette]="field.popupPrintOptions.category" [stringPopupId]="field.popupPrintOptions.stringPopupId"
            (isPopupPrintVisibleChange)="isPopupPrintVisibleChange($event)"
            (itemSelected)="popupPrintItemSelected($event)" [id]="field.popupPrintOptions.id"
            [popupClass]="field.editorOptions.editorName">
        </dx-popup-print>
    </div>

    <!-- INFO -->
    <div class="dx-field-value infoField" *ngIf="field.editorType === 'info'">
        <div *ngIf="item[field.dataField] === 0 || item[field.dataField] === null || field.nullValue; else infoNotNull">
            <span class="infoForm">{{field.label.text}} : -</span>
            <dx-button (onClick)="onClickInfo()" [visible]="iconErrorVisible">
                <ngc-icon size="20" icon="info" class="infoError"></ngc-icon>
            </dx-button>
        </div>
        <ng-template #infoNotNull>
            <span class="infoForm">
                {{field.label.text}} : {{item[field.dataField]}}
            </span>
            <dx-button (onClick)="onClickInfo()" [visible]="field.iconVisible">
                <ngc-icon size="20" icon="info" class="infoOk"></ngc-icon>
            </dx-button>
        </ng-template>
    </div>
</div>
    <!-- CUSTOM FROM OUTSIDE -->
    <div *ngIf="_templateDirective && _templateDirective['field']">
        <ng-container *ngTemplateOutlet="_templateDirective['field'] ?
        _templateDirective['field'].template : null; context: { $implicit: {
        field: field, item: item } }">
        </ng-container>
    </div>
    <!-- POPUP (selectBOX CASE) -->
    <ngc-next-dx-popup #dxPopupAddElement *ngIf="field.popupOptions" [identifier]="field.dataField | popupIdentifier"
        class="" [height]="400" (popupContentHeightChanged)="popupContentHeightChanged($event)" [width]="300"
        [showTitle]="true" [title]="('Creating' | translate) + ' ' + (('popup.generic.title.' +
    field.dataField) | translate)" [dragEnabled]="false" [closeOnOutsideClick]="false" [resizeEnabled]="false"
        [(visible)]="popupVisible" [dropDownAdd]="true">

        <div content>
            <ng-template #popupContainer>
            </ng-template>
        </div>
    </ngc-next-dx-popup>
