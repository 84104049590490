import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Article } from 'app/shared/model';
import { numberOfTuplesForCache } from '@app/globalsParameter';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { PaginationSet } from '@app/shared/viewModel/pagination-set';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { CommercialOperationAndLinesDto } from '@app/shared/model/commercialOperation/commercialOperationAndLines';
import { AllDutiesValuesArticleViewModel } from '@app/shared/viewModel/article/AllDutiesValuesArticleViewModel';
import { AppConfiguration } from '../application/app-configuration.service';
import { DetailSet } from '@app/shared/viewModel/detailSet';
import { I4Data } from '@app/shared/model/i4/i4-data';
import { ProductDataI4 } from '@app/shared/model/i4/product-data-i4';

@Injectable({
    providedIn: 'root'
})
export class ArticleService extends DaoGridStateBaseService implements IDaoBaseService {

    private _obsGetAll: Observable<Article[]> = null;
    list: Article[] = [];
    idList: number[] = [];
    currentItem: BehaviorSubject<Article> = new BehaviorSubject<Article>(null);
    params: DataGridFiltersParam = new DataGridFiltersParam();
    redisKeyPrint: string;
    trisField = null;
    trisStrategie = 'asc';
    beginning = 0;
    numberOfTuples = '10';
    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    getAll(): Observable<Article[]> {
        const url = this.appConfiguration.UrlsConfig.wsArticle;
        if (!this._obsGetAll) {
            this._obsGetAll = this.http.get<Article[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this._obsGetAll;
    }

    getForDetail(id: number): Observable<DetailSet<Article>> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticle + 'getForDetail/' + id + '/' + numberOfTuplesForCache + '/' + this.trisField + '/' + this.trisStrategie;
        return this.http.post<DetailSet<Article>>(url, this.params);
    }

    getForNavigation(idList: number[]): Observable<Article[]> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticle + 'getForDetailNavigation';
        return this.http.post<Article[]>(url, idList);
    }

    getByFilter(): Observable<PaginationSet<Article, Article>> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsArticle + 'getByFilter/' + this.beginning + '/' + this.numberOfTuples + '/' + this.trisField + '/' + this.trisStrategie;
        // let filter: DataGridFiltersParam = new DataGridFiltersParam();
        return this.http.post<PaginationSet<Article, Article>>(serviceUrl, this.params);
    }

    get(id: number | string): Observable<Article> {
        const url = this.appConfiguration.UrlsConfig.wsArticle + id;
        return this.http.get<Article>(url);
        // .pipe(
        //     shareReplay(1)
        // );
    }

    /**
     * search No article
     * @param payload
     */
    searchNoArticle(body: unknown, code_article: string, companyType: number): Observable<NGCResults<CommercialOperationAndLinesDto>> {
        const url = this.appConfiguration.UrlsConfig.wsCommercialOperationLine + 'SearchNoArticle/' + companyType + '?searchNo=' + code_article;
        // return throwError('Methode non implémenter !');
        return this.http.post<NGCResults<CommercialOperationAndLinesDto>>(url, body);
    }

    /**
    * Create an article
    * @param payload request body
    */
    post(payload: Article, isDuplication = false): Observable<NGCResults<Article>> {
        const url = this.appConfiguration.UrlsConfig.wsArticle + isDuplication;
        return this.http.post<NGCResults<Article>>(url , payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Create an article
    * @param payload request body
    */
    duplicate(id: number, copyImage = false): Observable<NGCResults<Article>> {
        const url = this.appConfiguration.UrlsConfig.wsArticle + 'Duplicate/' + id + '/' + copyImage;
        return this.http.post<NGCResults<Article>>(url, null).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update an article
    * @param id article id
    * @param payload article data
    */
    put(id: number, payload: Article, confirmDeleteSpecPackage?: number): Observable<NGCResults<Article>> {

        const url = this.appConfiguration.UrlsConfig.wsArticle + id + '/' + (confirmDeleteSpecPackage ? 1 : 0);
        return this.http.put<NGCResults<Article>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<unknown> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticle + id;
        return this.http.delete(url);
    }

    /***
     * Elle permet d'effacer plusieurs articles à la fois
     */
    deleteAll(idList: string[]): Observable<unknown> {
        const url: string = this.appConfiguration.UrlsConfig.wsArticle + 'deleteAll';
        return this.http.post(url, idList);
    }

    calculateDutiesForArticle(id: number):  Observable<NGCResults<AllDutiesValuesArticleViewModel>> {
        const date = new Date();
        const url = this.appConfiguration.UrlsConfig.wsArticle + 'CalculateDutiesForArticle/' + id + '/' + date.toISOString();
        return this.http.get<NGCResults<AllDutiesValuesArticleViewModel>>(url);
    }

    errorRegi(): Observable<boolean> {
        const url = this.appConfiguration.UrlsConfig.wsArticle + 'ErrorRegi';
        return this.http.get<boolean>(url);
    }

    sendToI4(payload: Article): Observable<unknown> {
        const url = this.appConfiguration.UrlsConfig.wsArticle + 'SendToI4';
        return this.http.post(url , payload);
    }

    GetErrorI4(checkId: number): Observable<I4Data> {
        const url = this.appConfiguration.UrlsConfig.wsArticle +'GetErrorI4/' + checkId;
        return this.http.get<I4Data>(url);
    }

    GetInfoI4(idArticleRegi: number): Observable<ProductDataI4> {
        const url = this.appConfiguration.UrlsConfig.wsArticle +'GetInfoI4/' + idArticleRegi;
        return this.http.get<ProductDataI4>(url);
    }
    CheckArticleErrors(id: number): Observable<string[]> {
        const url = this.appConfiguration.UrlsConfig.wsArticle +'CheckArticleErrors/' + id;
        return this.http.get<string[]>(url);
    }

    /**
     * méthode appeler par composent générique shared/module/devextreme/data-grid.component
     * pour faire clear cache des données
     */
    clearListTablesCache = (): void => {
        this._obsGetAll = null;
        this.list = null;
        this.idList = null;
    }

    /**
     * méthode appeler par composent générique shared/module/devextreme/data-grid.component
     * @param code object identifier
     */
    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) : void{
        this.router.navigate(['/article/detail', id]);
    }

    navigateToDetailWithExtra(id: number, isFromDuplication: boolean): void {
        this.router.navigate(['/article/detail', id],{ state : {canDeactivate: isFromDuplication}});
    }

    navigateToDuplicationPage(id: number,  isFromDuplication: boolean, data: {copyImage, notificationMsg}): void {
        this.router.navigate(['/article/duplication/' + id], { state : {canDeactivate: isFromDuplication , copyImage : data.copyImage , notificationMsg: data.notificationMsg}});
    }

    /**
     *  méthode appeler par composent générique shared/module/devextreme/data-grid.component
     */
    navigateToCreatePage(): void {
        this.router.navigate(['/article/create']);
    }

    navigateToList(): void {
        this.currentItem.next(null);
        this.router.navigate(['/article']);
    }
}
