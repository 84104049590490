import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

declare module 'rxjs' {
    interface Observable<T> {
        clearListCache(this: Observable<T>, clearFCT: any, ...params): Observable<T>;
    }
}
function clearListCache<T>(this: Observable<T>, clearFCT: any, ...params) {
    return this.pipe<T>(
        tap(val => clearFCT(...params))
    );
}

Observable.prototype.clearListCache = clearListCache;
