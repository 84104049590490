<div [ngClass]="{'title-listForMail' : titleForMail, 'title-listForForm': !titleForMail}">
    <div [ngClass]="{'listName': !titleIsCentered, 'listName-centered': titleIsCentered, 'listForForm': listForForm}">
        <ngc-icon [icon]="_icon" size="24"></ngc-icon>
        <span [ngClass]="{'slide-panel-title': !titleIsCentered, 'slide-panel-title-centered': titleIsCentered}">{{
            listName
            | translate }}</span>
        <ngc-icon class="listName-leftArrow" *ngIf="withLeftArrow" (click)="onBackClick()" size="24" icon="left">
        </ngc-icon>
    </div>
    <div class="addNewLine" *ngIf="canAddNewLine">
        <dx-button type="default" id="btnAddNewLine" stylingMode="text" (click)="onAddNewLine()" [disabled]="ShouldDisableAddNewLineBtn">
            <ngc-icon size="20" icon="roundedAdd"></ngc-icon>
            <span class='contentNewLine'>{{'ButtonLabel.NEW' | translate}}</span>
        </dx-button>
    </div>
</div>
<div *ngIf="!withSearchBar" class="container-list" #container>
    <div class="headerList" [ngClass]="cssClass">
        <ng-container *ngFor="let field of fields; let i=index">
            <div *ngIf="field.type !== 'checkbox'" class="labelfield">
                <span [ngClass]="field.cssClass">
                    {{ field.caption ? ("model."+field.caption|translate) : ("model."+field.fieldName|translate) }}
                </span>
            </div>
            <div *ngIf="field.type === 'checkbox'" class="labelfield-checkBox"></div>
        </ng-container>
        <div style="font-family: Nunito; font-weight: 600; font-size: 15px; color: #494949;" *ngIf="canUpdateAndDelete">
            <span>
                {{ "menuAction.Actions" | translate }}
            </span>
        </div>
    </div>

    <dx-list [height]="height ? height : getHeight()" [scrollByContent]="true" id="dxlist" [dataSource]="dataSource"
        [focusStateEnabled]="false" [activeStateEnabled]="false" [hoverStateEnabled]="true" itemTemplate="data"
        [allowItemDeleting]="allowItemDeleting" (onItemDeleting)="onItemDeleting($event)"
        (onItemClick)="onItemClick($event)">

        <div *dxTemplate="let item of 'data'">
            <ng-container *ngFor="let field of fields; let i=index">
                <div [ngClass]="getClass(field)" *ngIf="!field.visible">
                    <span *ngIf="field.type === 'string'" [ngClass]="field.cssClass">
                        {{item[field.fieldName]}}
                    </span>
                    <span *ngIf="field.type === 'number'" [ngClass]="field.cssClass">
                        {{item[field.fieldName]}}
                    </span>
                    <span *ngIf="field.type === 'date'">
                        {{item[field.fieldName] | date: 'short': undefined : 'fr' }}
                    </span>
                    <span *ngIf="field.type === 'boolean'">
                        <ngc-switch [disabled]="true" [value]="item[field.fieldName]"></ngc-switch>
                    </span>
                    <span *ngIf="field.type === 'object' && field.displayValue && item[field.fieldName]">
                        {{ getNestedObject(item[field.fieldName], field.displayValue) }}
                    </span>
                    <span *ngIf="field.type === 'object' && item && field.displayValueCallback">
                        {{field.displayValueCallback(item)}}
                    </span>
                    <span *ngIf="field.type === 'enum' && field.insideNestedObject">
                        {{ getEnumInsideNestedObject(field.values, item[field.fieldName], field.displayValue) }}
                    </span>
                    <span *ngIf="field.type === 'enum' && field.values[+item[field.fieldName]]">
                        {{field.values[+item[field.fieldName]]['designation']}}
                    </span>
                    <span *ngIf="field.type === 'checkbox'">
                        <dx-check-box [(value)]="item[field.fieldName]"
                            (onValueChanged)="handleValueChange($event,item)">
                        </dx-check-box>
                    </span>
                </div>
            </ng-container>
            <span *ngIf="canUpdateAndDelete">
                <dx-drop-down-button id="dropdownMore" *ngIf="true" class="header-more-dropdown more" displayExpr="name"
                    type="normal" style="float: right;" stylingMode="contained" [dropDownOptions]="{ width: 138 }"
                    icon="more" [items]="toolbarItems" itemTemplate="dropDownButtonTemplate"
                    (onItemClick)="onDropDownClick($event)">
                    <div *dxTemplate="let data of 'dropDownButtonTemplate'"
                        [ngClass]="{'dropDownItemSeparator': data.separator}">
                        <ngc-icon size="20" [icon]="data.icon"></ngc-icon>
                        {{data.name}}
                    </div>
                </dx-drop-down-button>
            </span>
        </div>

    </dx-list>
</div>
<div *ngIf="withSearchBar" class="list-container">

    <dx-list #list [dataSource]="dataSource" [height]="height ? height : getHeight()" [scrollByContent]="true"
        [searchEnabled]="true" searchMode="contains" searchExpr="name" [grouped]="true" groupTemplate="group"
        itemTemplate="item" [collapsibleGroups]="true">
        <div *dxTemplate="let data of 'item'">
            <div>
                <span>
                    <dx-check-box class="with-search-checkbox" [(value)]="data.favoris"
                        (onValueChanged)="handleValueChange($event,data)">
                    </dx-check-box>
                </span>
                <span>
                    {{data.name}}
                </span>
            </div>
        </div>
        <div *dxTemplate="let groupObj of 'group'">
            <div class="group-title" *ngIf="groupObj.key === 0">
                <p class="title">
                    {{'popup.mail.slide.base' | translate}} 
                </p>
                <img class="icon"/>
            </div>
            <div class="group-title" *ngIf="groupObj.key === 1">
                <p class="title">
                    {{'popup.mail.slide.perso' | translate}} 
                </p>
                <img class="icon"/>
            </div>
        </div>
    </dx-list>
</div>