import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { TableState, Referencing } from 'app/shared/model';
import { AppConfiguration } from './application/app-configuration.service';


@Injectable({
    providedIn: 'root'
})
export class ReferencingService  {

    private _list: Observable<Referencing[]> = null;
    private _gridState: Observable<JSON> = null;

    constructor(
        private http: HttpClient, 
        private router: Router,
        private appConfiguration: AppConfiguration) { }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsReferencing;
        if (!this._list) {
            this._list = this.http.get<Referencing[]>(url).pipe(shareReplay(1));
        }
        return this._list;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour récuperer table state
    getGridState(): Observable<JSON> {
        const url = this.appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('Referencing');
        if (!this._gridState) {
            this._gridState = this.http.get<JSON>(url).pipe(shareReplay(1));
        }
        return this._gridState;
    }
    /**
    * Create a Referencing
    * @param payload request body
    */
    post(payload: any): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsReferencing ;
        return this.http.post<any>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a Referencing
    * @param id Referencing id
    * @param payload Referencing data
    */
    put(id: number, payload: any): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsReferencing + id ;
        return this.http.put<any>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des table state
    clearGridStateCache() {
        this._gridState = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getPageTableTitle(): string {
        return 'Referencing';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsReferencing;
        return this.http.delete(url + id);
    }

    navigateToDetail(id) {
        return this.router.navigate(['/company/settings/referencing/detail/', id]);
    }

    navigateToCreatePage(): void {
        this.router.navigate(['/company/settings/referencing/create']);
    }

}
