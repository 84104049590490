import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService, AppContextService } from '../services';
import { canActivateAndCandLoad } from './helper/authGuard';

@Injectable()
export class CanLoadGuard implements CanLoad {
    constructor(public auth: AuthService, private appContext: AppContextService) {}

    canLoad(
        route: Route, segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return canActivateAndCandLoad(this.auth, this.appContext, segments.join('/'));
    }
}
