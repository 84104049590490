import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from '../application/app-configuration.service';
import { Observable } from 'rxjs/internal/Observable';
import { MobilStoreExportParams } from '@app/shared/model/export/mobilStoreExportParams';

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    constructor(private http: HttpClient, private appConfiguration: AppConfiguration) { }

    mobilStoreExport(payload: MobilStoreExportParams): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsExportApi + 'mobilStore';
        return this.http.post<any>(url, payload);
    }

}
