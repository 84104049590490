

export interface DataGridAction {
    /** identifiant du bouton dans la liste */
    id?: number;
    /** text a afficher */
    name?: string;
    actionCode?: string;
    /** */
    value?: number | string;
    icon?: string;
    /** badge a afficher en haut a droite (notif) */
    badge?: string;
    /** fonction a executer lors du click */
    callback?: (list: any) => void;
    disabled?: boolean;
    html?: string;
    title?: string;

    /** visibleCallback est utilisé dans les dataGrid */
    visibleCallback?: ((item) => boolean);
    /** visibleCallback est utilisé dans les form */
    visible?: Boolean;
}

export class DataGridActionColumn {
    /** Boolean qui indique si il y a plusiers actions ou pas */
    isMultiple?: boolean;

    Actions: DataGridAction[];

    constructor() {
        this.isMultiple = true;
        this.Actions = [];
    }
}

