import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { PopupConfig } from '@app/shared/model/popupConfig'
import { AppConfiguration } from './application/app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PopupConfigService {
  private _list: Observable<any> = null;

  constructor(
      private http: HttpClient,
      private appConfiguration: AppConfiguration) { }



  get(id: number): Observable<{ size: { width: number, height: number }, position: { x: number, y: number }, fromLocalStorage: boolean }> {
    const url = this.appConfiguration.UrlsConfig.wsPopup + id;
    return this.http.get(url)
      .pipe(
        map((t: PopupConfig) => PopupConfig.mapToFormatObject(t)),
        shareReplay(1)
      )
      ;

  }

  post(payload: PopupConfig): Observable<PopupConfig> {
    const url = this.appConfiguration.UrlsConfig.wsPopup;
    return this.http.post<PopupConfig>(url, payload);
  }

  put(id: number, payload: PopupConfig): Observable<PopupConfig> {
    const url = this.appConfiguration.UrlsConfig.wsPopup + id;
    return this.http.put<PopupConfig>(url, payload);
  }

}
