import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Report, TableState, ReportFavoris, ReportCategory } from 'app/shared/model';
import { AppConfiguration } from './application/app-configuration.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
    _reports: Observable<Report[]> = null;
    _reportsBase: Observable<Report[]> = null;
    _gridStateReportBase: Observable<string> = null;
    _gridStateReportPerso: Observable<string> = null;


    constructor(
        private _http: HttpClient,
        private _appConfiguration: AppConfiguration,
        private _router: Router) {
    }

    clearListTablesCache = (reportCategory: ReportCategory) => {
        this[reportCategory] = null;
    }

    clearReportBaseCache() {
        this._reportsBase = null;
    }

    clearGridStateReportPersoCache() {
        this._gridStateReportPerso = null;
    }

    clearGridStateReportBaseCache() {
        this._gridStateReportBase = null;
    }

    getAll(reportCategory: ReportCategory): Observable<Report[]> {
        if (!this[reportCategory]) {
            const url = this._appConfiguration.UrlsConfig.wsReport + reportCategory;
            this[reportCategory] = this._http.get<Report[]>(url)
            .pipe(
                shareReplay(1)
            );
        }

        return this[reportCategory];
    }

    post(payload: ReportFavoris): Observable<ReportFavoris> {
        const url = this._appConfiguration.UrlsConfig.wsReport;
        return this._http.post<ReportFavoris>(url, payload);
    }

    delete(id: number, type: string): Observable<any> {
        const url: string = this._appConfiguration.UrlsConfig.wsReport + id + '/' + type;
        return this._http.delete(url);
    }

    public getBase(): Observable<Report[]> {
        if (!this._reportsBase) {
            this._reportsBase = this._http.get<Report[]>(this._appConfiguration.UrlsConfig.wsReportBase).pipe(shareReplay(1));
        }
        return this._reportsBase;
    }

    public getGridStateReportBase(): Observable<string> {
        if (!this._gridStateReportBase) {
            const urlStateGrid = this._appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('reportTableBase');

            this._gridStateReportBase = this._http.get<string>(urlStateGrid).pipe(shareReplay(1));
        }

        return this._gridStateReportBase;
    }

    public getGridStateReportPerso(): Observable<string> {
        if (!this._gridStateReportPerso) {
            const urlStateGrid = this._appConfiguration.UrlsConfig.wsGridState + 'tableStateLoad/' + TableState.convertIdStringTableToInt('reportTablePerso');

            this._gridStateReportPerso = this._http.get<string>(urlStateGrid).pipe(shareReplay(1));
        }
        return this._gridStateReportPerso;
    }

    navigateToList(reportCategory: ReportCategory): void{
        this._router.navigateByUrl(`/admin/report/${reportCategory}`)
    }
}
