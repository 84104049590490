import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CompanyType, CustomerCategory, SupplierCategory } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';



@Injectable({
    providedIn: 'root'
})
export class CompanyCategoryService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
     }

    getAll(companyType: CompanyType): Observable<(CustomerCategory | SupplierCategory)[]> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCategory + companyType;
        if (!this[companyType]) {
            this[companyType] = this.http.get<any[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this[companyType];
    }

    post(payload: CustomerCategory | SupplierCategory,
            companyType: CompanyType): Observable<NGCResults<CustomerCategory | SupplierCategory>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCategory + companyType;
        return this.http.post<NGCResults<CustomerCategory | SupplierCategory>>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    /**
     * Update a location
     * @param code location code
     * @param payload location data
     */
    put(code: string, payload: CustomerCategory | SupplierCategory,
            companyType: CompanyType): Observable<NGCResults<CustomerCategory | SupplierCategory>> {
        const url = this.appConfiguration.UrlsConfig.wsCompanyCategory + companyType + '/' + code;
        return this.http.put<NGCResults<CustomerCategory | SupplierCategory>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any, companyType: CompanyType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsCompanyCategory + companyType + '/' + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType: CompanyType) => {
        this[companyType] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: any, companyType: CompanyType) {
        this.router.navigate(['/parameters/' + companyType + '/category/detail', id]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/category/create']);
    }

    navigateToList(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/category']);
    }
}
