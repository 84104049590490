import { BaseEntity2 } from '../base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models/validation-rules';
import { InvoicedRules } from '..';

export enum InvoicedDuties { Yes, No, AccordingToSetting}
export class WhyFree extends BaseEntity2 {
    designation: string;
    invoicedDuties: InvoicedDuties;
    calculateCostPrice: boolean;
    calculateCostPriceDiscount: boolean;
    integratedToDeb: boolean;
    visible: boolean;
    invoicedRules : InvoicedRules
constructor() {
        super();
        this.designation = '';
        this.invoicedDuties = InvoicedDuties.AccordingToSetting;
        this.calculateCostPrice = true;
        this.calculateCostPriceDiscount = false;
        this.integratedToDeb = true;
        this.visible = true;
        this.invoicedRules = InvoicedRules.GENERAL_SETTINGS;
    }
    validator(): ValidationRulesByFieldName {
        return {
            designation: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 60 , message: 'FormErrorMessages.StringMaxLength', useTranslator: true}
            ]
        };
    }

    initValues(): Partial<WhyFree> {
        return {
            visible: true
        };
    }
}
