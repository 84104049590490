import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';
import { BaseEntity2, TVA } from 'app/shared/model';
import { TaxCalculMode, TaxType } from '.';
import { TaxToDate } from './TaxToDate';

export class Tax extends BaseEntity2 {

    code: string;
    designation: string;
    calculMode: TaxCalculMode;
    type: TaxType;
    used: boolean;
    tva: TVA;
    conversionRate: number;

    constructor() {
        super();
        this.code = '';
        this.designation = '';
        this.calculMode = TaxCalculMode.VolumeEffectif;
        this.type = null;
        this.used = false;
        this.tva = null;
        this.conversionRate = 1;
    }

    validator(): ValidationRulesByFieldName {
        return {
            code: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true },
                { type: 'stringLength', max: 4, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            designation: [
                { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            calculMode: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            conversionRate: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

}

