import { CustomerRepresentative } from '../../company';
import { BaseEntity2 } from '../..';
import { CustomerCommercialOperation } from './customerCommercialOperation';
import { SpecificFieldForConfscreenModel } from '../../admin/SpecificFieldForConfscreenModel';

export class CustomerCommercialOperationRepresentative extends BaseEntity2 {

    operation: CustomerCommercialOperation;

    representative1: CustomerRepresentative;
    representative2: CustomerRepresentative;
    representative3: CustomerRepresentative;
    representative4: CustomerRepresentative;
    representative5: CustomerRepresentative;
    representative6: CustomerRepresentative;

    constructor() {
        super();
        this.operation = null;
        this.representative1 = null;
        this.representative2 = null;
        this.representative3 = null;
        this.representative4 = null;
        this.representative5 = null;
        this.representative6 = null;

    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {

        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = ['operation'];
        return specificFieldForConfscreenModel;
    }
}
