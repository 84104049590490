import { User, RepresentativeType } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from 'app/shared/modules/devextreme/dx-form/models/validation-rules';
import { PostalAddress } from '../postalAddress';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { CommissionBrokerageBaseCalcul, CommissionBrokerageType, PaymentCommissionRule } from '../enum';

export class CompanyRepresentative extends BaseEntity2 {
    phone: string;
    cellPhone: string;
    fax: string;
    email: string;
    expirationDate: Date;
    type: RepresentativeType;
    comment1: string;
    comment2: string;
    user: User;
    visible: boolean;
    postalAddress: PostalAddress;
    defaultPaymentCommissionRule: PaymentCommissionRule;
    defaultCommissionValue: number;
    defaultCommissionBrokerageType: CommissionBrokerageType;
    defaultCommissionBrokerageBaseCalcul: CommissionBrokerageBaseCalcul;

    constructor() {
        super();
        this.phone = '';
        this.cellPhone = '';
        this.fax = '';
        this.email = '';
        this.expirationDate = new Date();
        this.type = null;
        this.comment1 = '';
        this.comment2 = '';
        this.visible = true;
        this.postalAddress = null;
        this.defaultPaymentCommissionRule = PaymentCommissionRule.NOT_COMMISSIONED;
        this.defaultCommissionValue = 0;
        this.defaultCommissionBrokerageType = null;
        this.defaultCommissionBrokerageBaseCalcul = null;
    }

    initValues(): Partial<CompanyRepresentative> {
        return {
            visible: true
        };
    }

    validator(): ValidationRulesByFieldName {
        return {
            phone: [
                { type: 'stringLength', max: 40, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            cellPhone: [
                { type: 'stringLength', max: 40, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            fax: [
                { type: 'stringLength', max: 40, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            email: [
                { type: 'stringLength', max: 100, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true }
            ],
            comment1: [
                { type: 'stringLength', max: 255, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment2: [
                { type: 'stringLength', max: 255, trim: true, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            {
                nameProp: 'postalAddress',
                objectInstance: new PostalAddress()
            }
        ];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = [];
        specificFieldForConfscreenModel.groupsField = {};

        return specificFieldForConfscreenModel;
    }
}

export class CustomerRepresentative extends CompanyRepresentative {

    constructor() {
        super();
    }
}

export class SupplierRepresentative extends CompanyRepresentative {

    constructor() {
        super();
    }
}
