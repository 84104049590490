
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: `root`
})
export class AppConfiguration {

    UrlsConfig: any;
    loginMethod: string;
    auth0: any;

    defaultMode : string;
    serviceUser: string;
    serviceCompany: string;
    serviceScheduler: string;
    serviceReport: string;
    serviceArticle: string;
    serviceCDN: string;
    serviceContact: string;
    servicePricing: string;
    servicePackaging: string;
    serviceConfiguration: string;
    serviceCommercialOperation: string;
    serviceStock: string;
    serviceSignalR: string;
    serviceExport: string;
    serviceMobile: string;
    serviceImport: string;
    serviceMail: string;
    serviceEtiquette: string;
    serviceReglement: string;
    serviceCommission: string;

    constructor(
        private httpClient: HttpClient
    ) { }

    ensureInit(): Promise<any> {
        return this.httpClient.get(`/assets/configurations/config.` + environment.configName + `.json`)
        .toPromise()
        .then((resp) => {
            Object.assign(this,resp);

            this.defaultMode = `${this.defaultMode}`;
            this.serviceUser = `${this.serviceUser}`;
            this.serviceCompany = `${this.serviceCompany}`;
            this.serviceScheduler = `${this.serviceScheduler}`;
            this.serviceReport = `${this.serviceReport}`;
            this.serviceArticle = `${this.serviceArticle}`;
            this.serviceCDN = `${this.serviceCDN}`;
            this.serviceContact = `${this.serviceContact}`;
            this.servicePricing = `${this.servicePricing}`;
            this.servicePackaging = `${this.servicePackaging}`;
            this.serviceConfiguration = `${this.serviceConfiguration}`;
            this.serviceCommercialOperation = `${this.serviceCommercialOperation}`;
            this.serviceStock = `${this.serviceStock}`;
            this.serviceSignalR = `${this.serviceSignalR}`;
            this.serviceExport = `${this.serviceExport}`;
            this.serviceMobile = `${this.serviceMobile}`;
            this.serviceImport = `${this.serviceImport}`;
            this.serviceMail = `${this.serviceMail}`;
            this.serviceEtiquette = `${this.serviceEtiquette}`;
            this.serviceReglement = `${this.serviceReglement}`;
            this.serviceCommission = `${this.serviceCommission}`;
            this.UrlsConfig = {
                wsApi: this.serviceUser.concat(`Api/`),
                wsOtherDocument: this.serviceCDN.concat(`Document/otherDocument?`),
                wsCompany: this.serviceCompany.concat(`Company/`),
                wsSession: this.serviceUser.concat(`Session/`),
                wsGridState: this.serviceConfiguration.concat(`GridState/`),
                wsUser: this.serviceUser.concat(`User/`),
                wsExternUser: this.serviceUser.concat(`ExternUser/`),
                wsApplicationParameter: this.serviceUser.concat(`ApplicationParameter/`),
                wsCustomer: this.serviceCompany.concat(`Customer/`),
                wsSupplier: this.serviceCompany.concat(`Supplier/`),
                wsCompanyCategory: this.serviceCompany.concat(`CompanyCategory/`),
                wsCompanyAddress: this.serviceCompany.concat(`CompanyAdresse/`),
                wsSociety: this.serviceUser.concat(`Society/`),
                wsParameter: this.serviceUser.concat(`Parameter/`),
                wsAppointment: this.serviceScheduler.concat(`Appointment/`),
                wsReport: this.serviceReport.concat(`Report/`),
                wsReportBase: this.serviceReport.concat(`Base/`),
                wsReportPerso: this.serviceReport.concat(`Perso/`),
                wsReportDesigner: this.serviceReport.concat(`ReportDesigner/`),
                wsAdditionalTables: this.serviceCompany.concat(`AdditionalTables/`),
                wsGeneric: this.serviceUser.concat(`Generic/`),
                wsClass: this.serviceCompany.concat(`Class/`),
                wsRepresentative: this.serviceCompany.concat(`CompanyRepresentative/`),
                wsCurrency: this.serviceCompany.concat(`Currency/`),
                wsPaymentChoice: this.serviceCompany.concat(`PaymentChoice/`),
                wsPaymentMethod: this.serviceCompany.concat(`PaymentMethod/`),
                wsPortScale: this.serviceCompany.concat(`PortScale/`),
                wsFollowUpStatus: this.serviceCompany.concat(`FollowUpStatus/`),
                wsReferencing: this.serviceCompany.concat(`Referencing/`),
                wsBasicRate: this.serviceCompany.concat(`BasicRate/`),
                wsCompanyCriteria: this.serviceCompany.concat(`CompanyCriteria/`),
                wsCompanyCriteriaValue: this.serviceCompany.concat(`CompanyCriteriaValue/`),
                wsCompanyCriteriaValueSelected: this.serviceCompany.concat(`CompanyCriteriaSelected/`),
                wsBilling: this.serviceUser.concat(`Billing/`),
                wsPopup: this.serviceConfiguration.concat(`PopupConfiguration/`),
                wsLocation: this.serviceArticle.concat(`Location/`),
                wsConditioning: this.serviceArticle.concat(`Conditioning/`),
                wsArticle: this.serviceArticle.concat(`Article/`),
                wsAdvice: this.serviceArticle.concat(`Advice/`),
                wsAccountingCode: this.serviceArticle.concat(`AccountingCode/`),
                wsArticleFamily: this.serviceArticle.concat(`ArticleFamily/`),
                wsArticleUnderFamily: this.serviceArticle.concat(`ArticleUnderFamily/`),
                wsTradeFamily: this.serviceArticle.concat(`TradeFamily/`),
                wsCostGroup: this.serviceArticle.concat(`CostGroup/`),
                wsWhyFree: this.serviceArticle.concat(`WhyFree/`),
                wsZoneProtegee: this.serviceArticle.concat(`ProtectedZone/`),
                wsDesignation4: this.serviceArticle.concat(`ArticleDesignation4/`),
                wsDepot: this.serviceArticle.concat(`Depot/`),
                wsDesignation5: this.serviceArticle.concat(`ArticleDesignation5/`),
                wsDesignation6: this.serviceArticle.concat(`ArticleDesignation6/`),
                wsUniteArticle: this.serviceArticle.concat(`UniteArticle/`),
                wsTypeDesignation5: this.serviceArticle.concat(`TypeArticleDesignation5/`),
                wsArticleCriteria: this.serviceArticle.concat(`ArticleCriteria/`),
                wsArticleCriteriaValue: this.serviceArticle.concat(`ArticleCriteriaValue/`),
                wsArticleCriteriaValueSelected: this.serviceArticle.concat(`ArticleCriteriaSelected/`),
                wsRound: this.serviceConfiguration.concat(`Round/`),
                wsContact: this.serviceContact.concat(`Contact/`),
                wsContactFunction: this.serviceContact.concat(`ContactFunction/`),
                wsContactPhoneNumber: this.serviceContact.concat(`PhoneNumber/`),
                wsContactPhoneType: this.serviceContact.concat(`PhoneType/`),
                wsArtCost: this.servicePricing.concat(`ArtCost/`),
                wsTva: this.servicePricing.concat(`TVA/`),
                wsTax: this.serviceArticle.concat(`Tax/`),
                wsTaxToDate: this.serviceArticle.concat(`TaxToDate/`),
                wsTvaToDate: this.servicePricing.concat(`TvaToDate/`),
                wsPackaging: this.servicePackaging.concat(`Packaging/`),
                wsPackagingFamily: this.servicePackaging.concat(`PackagingFamily/`),
                wsPackagingType: this.servicePackaging.concat(`PackagingType/`),
                wsPackagingCrossing: this.servicePackaging.concat(`PackagingCrossing/`),
                wsArticlePackaging: this.servicePackaging.concat(`PackagingArticle/`),
                wsApiKey: this.serviceUser.concat(`ApiKey/`),
                wsConfScreen: this.serviceConfiguration.concat(`ConfScreen/`),
                wsBarCodeUtils: this.serviceConfiguration.concat(`BarCodeUtils/`),
                wsProduct: this.serviceArticle.concat(`Product/`),
                wsTaxFamily: this.serviceArticle.concat(`TaxFamily/`),
                wsEbacchus: this.serviceArticle.concat(`Ebacchus/`),
                wsTaxLabels: this.serviceArticle.concat(`TaxLabels/`),
                wsProductColor: this.serviceArticle.concat(`ProductColor/`),
                wsProductType: this.serviceArticle.concat(`ProductType/`),
                signalrPrint: this.serviceReport.concat(`signalr/PrintHub`),
                wsDashboard: this.serviceConfiguration.concat(`Dashboard/`),
                wsWidgetBase: this.serviceConfiguration.concat(`WidgetBase/`),
                wsCommercialOperation: this.serviceCommercialOperation.concat(`CommercialOperation/`),
                wsCommercialOperationLine: this.serviceCommercialOperation.concat(`CommercialOperationLine/`),
                wsCommercialOperationType: this.serviceCommercialOperation.concat(`CommercialOperationType/`),
                wsCommercialOperationLogistique: this.serviceCommercialOperation.concat(`CommercialOperationLogistic/`),
                wsCommercialOperationInvoicing: this.serviceCommercialOperation.concat(`CommercialOperationInvoicing/`),
                wsCommercialOperationInvoice: this.serviceCommercialOperation.concat(`CommercialOperationInvoice/`),
                wsCommercialOperationPricingInfo: this.serviceCommercialOperation.concat(`CommercialOperationPricingInfo/`),
                wsTaxTypeRight: this.serviceArticle.concat(`TaxTypeRight/`),
                wsCommercialOperationTypesParams: this.serviceUser.concat(`Parameter/getCommercialOperationParameters/`),
                wsCommercialOperationHistory: this.serviceCommercialOperation.concat(`CommercialOperationHistory/`),
                wsCommercialOperationHistoryComment: this.serviceCommercialOperation.concat(`CommercialOperationHistoryComment/`),
                wsCommercialOperationExpedition: this.serviceCommercialOperation.concat(`CommercialOperationShipment/`),
                wsMobileEquipment: this.serviceMobile.concat(`MobilBusinessParam/`),
                wsFtp: this.serviceConfiguration.concat(`Ftp/`),
                wsStockMvt: this.serviceStock.concat(`StockMvt/`),
                wsInventory: this.serviceStock.concat(`Inventory/`),
                wsStockLot: this.serviceStock.concat(`StockLot/`),
                wsSignalR: this.serviceSignalR.concat(`signalr/NotificationHub`),
                wsArticleApi: this.serviceArticle.concat(`Api/`),
                wsCDNApi: this.serviceCDN.concat(`Api/`),
                wsCommercialOperationApi: this.serviceCommercialOperation.concat(`Api/`),
                wsCompanyApi: this.serviceCompany.concat(`Api/`),
                wsFamilyCommissionBrokerageRule: this.serviceCompany.concat(`FamilyCommissionBrokerageRule/`),
                wsConfigurationApi: this.serviceConfiguration.concat(`Api/`),
                wsContactApi: this.serviceContact.concat(`Api/`),
                wsExportApi: this.serviceExport.concat(`Export/`),
                wsImportApi: this.serviceImport.concat(`Import/`),
                wsMobileApi: this.serviceMobile.concat(`Api/`),
                wsPackagingtApi: this.servicePackaging.concat(`Api/`),
                wsPricingApi: this.servicePricing.concat(`Api/`),
                wsReportApi: this.serviceReport.concat(`Api/`),
                wsSchedulerApi: this.serviceScheduler.concat(`Api/`),
                wsSignalRApi: this.serviceSignalR.concat(`Api/`),
                wsStockApi: this.serviceStock.concat(`Api/`),
                wsArticleImport: this.serviceArticle.concat('ArticleImport/'),
                wsCompanyImport: this.serviceCompany.concat('CompanyImport/'),
                wsMail: this.serviceMail.concat(`Template/`),
                wsCommercialOperationSummary: this.serviceCommercialOperation.concat(`CommercialOperationSummary/`),
                wsMailFavoris: this.serviceMail.concat(`Favoris/`),
                wsMailing: this.serviceMail.concat(`Mail/`),
                wsLock: this.serviceCommercialOperation.concat(`Lock/`),
                wsEtiquetteBase: this.serviceEtiquette.concat(`EtiquetteBase/`),
                wsEtiquettePerso: this.serviceEtiquette.concat(`EtiquettePerso/`),
                wsEtiquetteFavoris: this.serviceEtiquette.concat(`EtiquetteFavoris/`),
                wsEtiquette: this.serviceEtiquette.concat(`Etiquette/`),
                wsPrinter: this.serviceConfiguration.concat(`Printer/`),
                wsTache : this.serviceConfiguration.concat(`Tache/`),
                wsTraitement: this.serviceConfiguration.concat(`Traitement/`),
                wsBank: this.serviceUser.concat(`Bank/`),
                wsReglement: this.serviceReglement.concat(`Reglement/`),
                wsSSISPackage : this.serviceConfiguration.concat(`SSISPackage/`),
                wsReglementDetail: this.serviceReglement.concat(`ReglementDetail/`),
                wsTacheLog: this.serviceConfiguration.concat(`TacheLog/`),
                wsCheck: this.serviceConfiguration.concat(`Check/`),
                wsModule: this.serviceConfiguration.concat(`Module/`),
                wsProductState: this.serviceArticle.concat(`ProductState/`),
                wsCommission: this.serviceCommission.concat(`Commission/`),
                wsBrokerage: this.serviceCommission.concat(`Brokerage/`),
                wsCommissionLine: this.serviceCommission.concat(`CommissionLine/`),
                wsBrokerageLine: this.serviceCommission.concat(`BrokerageLine/`),
                wsStockMvtSetting: this.serviceConfiguration.concat(`StockMvtSetting/`),
                wsManipulationViticole: this.serviceArticle.concat(`ManipulationViti/`),
                wsCruCognac: this.serviceArticle.concat(`CruCognac/`),
                wsQualiteCognac: this.serviceArticle.concat(`QualiteCognac/`)                
            };

            this.auth0 = `${this.auth0}`;
            this.loginMethod = `${this.loginMethod}`;
        });
    }
}
