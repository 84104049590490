<div *ngIf="!PopupOnly">
    <ng-container *ngIf="!dataGridOptions; else elseTemplate">
        <div class="dx-field-value popup-activator">
            <dx-text-box #dxTextBox [value]="displayedValue" [disabled]="disabled" (onFocusIn)="focusedField = true"
                (onFocusOut)="focusedField = false" [placeholder]="getplaceHolder()" [isValid]="isValid">
                <dx-validator>
                    <ng-template ngFor let-validator [ngForOf]="formField.validationRules">
                        <dxi-validation-rule [type]="validator.type"
                            [message]="validator.useTranslator ? (validator.message | translate: {field: formField.label.text, min: validator.min, max: validator.max }) : validator.message"
                            [pattern]="validator.pattern" [trim]="validator.trim" [max]="validator.max"
                            [min]="validator.min" [ignoreEmptyValue]="validator.ignoreEmptyValue"
                            [validationCallback]="validator.validationCallback">
                        </dxi-validation-rule>
                    </ng-template>
                </dx-validator>
                <dxi-button *ngIf="selectedItem && showClearButton" name="remove" location="after" [options]="removeButton">
                </dxi-button>
                <dxi-button name="find" location="after" [options]="findButton">
                    <div *dxTemplate="let data of 'templatefind'">
                        <ngc-icon size="20" icon="search"></ngc-icon>
                    </div>
                </dxi-button>

            </dx-text-box>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <dx-button
            class="responsive-text-button button-has-text dx-button dx-button-default dx-button-mode-contained dx-widget"
            [focusStateEnabled]="false" [hoverStateEnabled]="false" [activeStateEnabled]="false" [disabled]="disabled"
            (onClick)="showPopupBtn($event)">
            <span class="btnPickerTextMargin">{{btnPickerText}}</span>
        </dx-button>
    </ng-template>
</div>

<ngc-next-dx-popup #dataGridPickerPopup popupClass="data-grid-picker-popup" [isPopupGrid]="true" [isPopupPrintVisible]="false" [identifier]="popupIdentifier | popupIdentifier"
    [visible]="popupVisibility && type === 'popup'" [dragEnabled]="true" [resizeEnabled]="true" [filterButton]="filterButton"
    [closeOnOutsideClick]="false" [showTitle]="true" [title]="popupTitle" [height]="865" [width]="1795"
    (visibleChange)="onVisibleChange($event)" (onResizeEnd)="onPopupResizeEnd($event)" (columnChooser)="columnChooser()"
    (searchingWordChange)="searchingWordChange($event)" (manageHeightGrid)="manageHeightGrid()" [slidePanelOptions]="slidePanelOptions"
    [advancedFilterConfigArray]="advancedFilterConfigArray" [advancedFilter]="advancedFilter" [advancedFilterCopy]="advancedFilterCopy"
    (validateFilter)="onValidateFilter($event)"  [isPopupForEtiquette]="false">
    <div content class="popup-content">
        <app-data-grid #dxPopupDataGrid [options]="options" [dataGridInput]="dataGridInput" [inPopup]="true" (onValueSelected)="onValueSelected($event)"
            (rowUpdated)="onRowUpdated($event)" [footerInfo]="footerInfo" 
            (rowUpdating)="onRowUpdating($event)" [editActionVisible]="editActionVisible" (onEditButtonClicked)="onEditButtonClicked($event)" (deleteAdvancedFilter)="onDeleteAdvancedFilter($event)">
        
            <div *ngIf="criteriasList">
                <div *ngFor="let criteria of criteriasList">
                    <div *templateKey="'cellTemplateArticleCriteriaSelected_' + criteria.id; let data">
                        <div>                
                            {{ getCriteriaSelected(data.data, criteria.id) }}
                        </div>
                    </div>
                </div>
            </div>

        </app-data-grid>
        <div id="divBtnsFooter" class="btns_footer">
            <div class="border"></div>
            <dx-button type="default" class="btn-popup-cancel" stylingMode="outlined" text="{{ 'form.cancelPopup' | translate}}" (onClick)="cancel()"> </dx-button>
            <dx-button type="default" class="btn-popup-validate"  text="{{ 'form.validatePopup' | translate}}"
                (onClick)="applychange($event)"></dx-button>
        </div>
    </div>
</ngc-next-dx-popup>


