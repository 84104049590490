import { Injectable , EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { WidgetBase } from 'app/shared/model/widget';

import { IDaoBaseService } from '@app/shared/interface';
import { DaoGridStateBaseService } from '../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class WidgetsBaseService extends DaoGridStateBaseService implements IDaoBaseService {
    private _list: Observable<WidgetBase[]> = null;
    public  inWaitingWidgetsPickerEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(InWaiting?: boolean, available?: boolean): Observable<WidgetBase[]> {
        const url = this.appConfiguration.UrlsConfig.wsWidgetBase;
        const params: any = {};
        if (InWaiting !== null) {
            params.InWaiting = InWaiting;
        }
        if (available !== null) {
            params.available = available;
        }
        /*if (!this._list) {
          this._list = this.http.get<WidgetBase[]>(url,{params})
            .pipe(
              shareReplay(1)
            )
          ;
        }
        return this._list;*/
        return this.http.get<WidgetBase[]>(url, { params });
    }

    /**
     * get liste de widget en attente de traitement par le superAdmin (date de modification NULL)
     */
    getInWaitingWidgets(): Observable<number> {
        const url = this.appConfiguration.UrlsConfig.wsWidgetBase + 'GetNbInWaiting';
        return this.http.get<number>(url)
            .pipe(
                shareReplay(1)
            )
            ;
    }

    get(id: any): Observable<WidgetBase> {
        const url = this.appConfiguration.UrlsConfig.wsWidgetBase + id;
        return this.http.get<WidgetBase>(url)
            .pipe(
                shareReplay(1)
            );
    }

    /**
     * Create a WidgetBase
     * @param payload request body
     */
    post(payload: WidgetBase): Observable<NGCResults<WidgetBase>> {
        const url = this.appConfiguration.UrlsConfig.wsWidgetBase;
        return this.http.post<NGCResults<WidgetBase>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
     * Update a WidgetBase
     * @param id WidgetBase id
     * @param payload WidgetBase data
     */
    put(id: number, payload: WidgetBase): Observable<NGCResults<WidgetBase>> {
        const url = this.appConfiguration.UrlsConfig.wsWidgetBase + id;
        return this.http.put<NGCResults<WidgetBase>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id): Observable<any> {

        const url: string = this.appConfiguration.UrlsConfig.wsWidgetBase;
        return this.http.delete(url + id);
    }

    navigateToDetail(id, InWaiting: boolean) {
        return this.router.navigate(['/superAdmin/widgetsBase/detail/', InWaiting, id]);
    }

    navigateToCreatePage(): void {
        // Il n'y a pas de création de widget base mais on veut que ça ouvre la selection d'un widget en attente
        // donc on va emettre l'info
        this.inWaitingWidgetsPickerEvent.emit('showInWaitingWidgetsPicker');
    }

    navigateToList(): void {
        this.router.navigate(['/superAdmin/widgetsBase/list/']);
    }
}

