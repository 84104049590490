export const ngcIcons16 = {
    'duplicate':
        [{
            type: 'path',
            d: 'M3.5 1C2.67157 1 2 1.67157 2 2.5V10.7139C2 11.5423 2.67157 12.2139 3.5 12.2139H3.76931C4.04545 12.2139 4.26931 11.99 4.26931 11.7139C4.26931 11.4377 4.04545 11.2139 3.76931 11.2139H3.5C3.22386 11.2139 3 10.99 3 10.7139V2.5C3 2.22386 3.22386 2 3.5 2H9.96208C10.2382 2 10.4621 2.22386 10.4621 2.5V2.77674C10.4621 3.05288 10.6859 3.27674 10.9621 3.27674C11.2382 3.27674 11.4621 3.05288 11.4621 2.77674V2.5C11.4621 1.67157 10.7905 1 9.96208 1H3.5ZM6.03831 3.78613C5.20988 3.78613 4.53831 4.45771 4.53831 5.28613V13.5C4.53831 14.3284 5.20988 15 6.03831 15H12.5004C13.3288 15 14.0004 14.3284 14.0004 13.5V5.28613C14.0004 4.45771 13.3288 3.78613 12.5004 3.78613H6.03831ZM5.53831 5.28613C5.53831 5.00999 5.76217 4.78613 6.03831 4.78613H12.5004C12.7765 4.78613 13.0004 5.00999 13.0004 5.28613V13.5C13.0004 13.7762 12.7765 14 12.5004 14H6.03831C5.76217 14 5.53831 13.7762 5.53831 13.5V5.28613Z',
            fill: 'currentColor'
        }],
    'print':
        [{
            type: 'path',
            d: 'M3.98502 3C3.98502 2.44771 4.4305 2 4.98002 2H10.9501C11.4996 2 11.9451 2.44772 11.9451 3V4.26925H13.5075C14.3318 4.26925 15 4.94082 15 5.76925V10.6924C15 11.5208 14.3318 12.1924 13.5075 12.1924H11.9451V13C11.9451 13.5523 11.4996 14 10.9501 14H4.98002C4.4305 14 3.98502 13.5523 3.98502 13V12.1924H2.49251C1.66822 12.1924 1 11.5208 1 10.6924V5.76925C1 4.94082 1.66822 4.26925 2.49251 4.26925H3.98502V3ZM13.5075 11.1924H11.9451V9C11.9451 8.44772 11.4996 8 10.9501 8H4.98002C4.4305 8 3.98502 8.44771 3.98502 9V11.1924H2.49251C2.21775 11.1924 1.99501 10.9685 1.99501 10.6924V5.76925C1.99501 5.4931 2.21775 5.26925 2.49251 5.26925H13.5075C13.7823 5.26925 14.005 5.4931 14.005 5.76925V10.6924C14.005 10.9685 13.7823 11.1924 13.5075 11.1924ZM10.9501 4.26925H4.98003V3.00012H10.9501V4.26925ZM10.95 9H4.98005V13H10.95V9ZM12.4427 5.99984C12.168 5.99976 11.9451 6.22355 11.9451 6.49969C11.945 6.77583 12.1676 6.99976 12.4424 6.99984L12.9399 7C13.2147 7.00009 13.4375 6.7763 13.4376 6.50016C13.4377 6.22401 13.215 6.00009 12.9402 6L12.4427 5.99984Z',
            fill: 'currentColor'
        }],
    'trash':
        [{
            type: 'path',
            d: 'M5.92847 2.5C5.92847 2.22386 6.15232 2 6.42847 2H9.57132C9.84747 2 10.0713 2.22386 10.0713 2.5V3.28619L5.92847 3.28619V2.5ZM4.92847 3.28619V2.5C4.92847 1.67157 5.60004 1 6.42847 1H9.57132C10.3997 1 11.0713 1.67157 11.0713 2.5V3.28619L13.5 3.28619C13.7761 3.28619 14 3.51004 14 3.78619C14 4.06233 13.7761 4.28619 13.5 4.28619L2.5 4.28619C2.22386 4.28619 2 4.06233 2 3.78619C2 3.51004 2.22386 3.28619 2.5 3.28619L4.92847 3.28619ZM3.66841 4.71703C3.94338 4.69165 4.18686 4.89399 4.21224 5.16897L4.98558 13.5486C5.00932 13.8058 5.22512 14.0026 5.48346 14.0026H10.5167C10.775 14.0026 10.9908 13.8058 11.0146 13.5485L11.7879 5.16897C11.8133 4.89399 12.0568 4.69165 12.3317 4.71703C12.6067 4.74241 12.809 4.98589 12.7837 5.26086L12.0103 13.6404C11.9391 14.4122 11.2917 15.0026 10.5167 15.0026H5.48346C4.70844 15.0026 4.06103 14.4122 3.98981 13.6404L3.21647 5.26086C3.19109 4.98589 3.39343 4.74241 3.66841 4.71703ZM9.35718 6.14413C9.63332 6.14413 9.85718 6.36799 9.85718 6.64413L9.85718 12.1455C9.85718 12.4217 9.63332 12.6455 9.35718 12.6455C9.08104 12.6455 8.85718 12.4217 8.85718 12.1455L8.85718 6.64413C8.85718 6.36799 9.08104 6.14413 9.35718 6.14413ZM7.28564 6.64413C7.28564 6.36799 7.06179 6.14413 6.78564 6.14413C6.5095 6.14413 6.28564 6.36799 6.28564 6.64413V12.1455C6.28564 12.4217 6.5095 12.6455 6.78564 12.6455C7.06179 12.6455 7.28564 12.4217 7.28564 12.1455V6.64413Z',
            fill: 'currentColor'
        }],
    'chevronprevious': [
        {
            type: 'path',
            d: 'M7 1L1 7L7 13',
            stroke: 'currentColor',
            strokeWidth: '2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }],
    'modify':
        [{
            type: 'path',
            d: 'M11.2973 3.29698C11.486 3.10596 11.7401 3 12.0031 3C12.2662 3 12.5198 3.10596 12.7076 3.29698C12.8957 3.48824 13.0023 3.74916 13.0017 4.02273C13.0011 4.29562 12.8939 4.55593 12.7058 4.74706L5.07675 12.3329L3.1864 12.8121L3.66532 10.8858L11.2942 3.30016L11.2942 3.30018L11.2973 3.29698ZM12.0054 2C11.4732 2 10.9637 2.21424 10.5884 2.59327L2.86171 10.2762C2.79676 10.3408 2.75074 10.4219 2.72865 10.5108L2.0148 13.382C1.9724 13.5526 2.02254 13.7327 2.14688 13.8564C2.27122 13.9802 2.45153 14.0295 2.62187 13.9863L5.45344 13.2685C5.54048 13.2464 5.62002 13.2014 5.68373 13.138L13.4119 5.45362L13.4119 5.45364L13.4151 5.45044C13.7903 5.07057 14.0005 4.55687 14.0017 4.02273C14.0029 3.48859 13.7949 2.97489 13.4214 2.59501C13.0476 2.2149 12.5384 2 12.0054 2ZM8.00003 13C7.72389 13 7.5 13.2238 7.49996 13.4999C7.49992 13.7761 7.72375 14 7.99989 14L13.4654 14.0008C13.7415 14.0008 13.9654 13.777 13.9655 13.5008C13.9655 13.2247 13.7417 13.0008 13.4655 13.0008L8.00003 13Z',
            fill: '#5E5E5E'
        }],
    'dropdown':
        [{
            type: 'path',
            d: 'M3 6L8 11L13 6',
            stroke: 'currentColor',
            strokeWidth: '1.5',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }],
    'dropup':
        [{
            type: 'path',
            d: 'M13 11L8 6L3 11',
            stroke: 'currentColor',
            strokeWidth: '1.5',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }],
    'dropdownClear':
        [{
            type: 'path',
            d: 'M7.99999 14.125C11.3827 14.125 14.125 11.3827 14.125 7.99999C14.125 4.61725 11.3827 1.875 7.99999 1.875C4.61725 1.875 1.875 4.61725 1.875 7.99999C1.875 11.3827 4.61725 14.125 7.99999 14.125ZM7.99999 15C11.866 15 15 11.866 15 7.99999C15 4.134 11.866 1 7.99999 1C4.134 1 1 4.134 1 7.99999C1 11.866 4.134 15 7.99999 15ZM5.21575 5.21575C5.38661 5.0449 5.66362 5.0449 5.83447 5.21575L8 7.38128L10.1655 5.21578C10.3363 5.04493 10.6134 5.04493 10.7842 5.21578C10.9551 5.38664 10.9551 5.66365 10.7842 5.8345L8.61872 8L10.7842 10.1655C10.9551 10.3363 10.9551 10.6134 10.7842 10.7842C10.6134 10.9551 10.3363 10.9551 10.1655 10.7842L8 8.61872L5.83447 10.7842C5.66362 10.9551 5.38661 10.9551 5.21575 10.7842C5.0449 10.6134 5.0449 10.3364 5.21575 10.1655L7.38128 8L5.21575 5.83447C5.0449 5.66362 5.0449 5.38661 5.21575 5.21575Z',
            fill: 'currentColor',
        }],
    'closeRoundedFlat': [
        {
            type: 'circle',
            cx: 8,
            cy: 8,
            r: 7,
            stroke: '#5D78FF',
            fill: '#5D78FF'
        },
        {
            type: 'path',
            d: 'M5.17157 10.1213C4.97631 10.3166 4.97631 10.6332 5.17157 10.8284C5.36684 11.0237 5.68342 11.0237 5.87868 10.8284L7.99998 8.70715L10.1213 10.8285C10.3166 11.0237 10.6332 11.0237 10.8284 10.8285C11.0237 10.6332 11.0237 10.3166 10.8284 10.1214L8.70709 8.00004L10.8284 5.8787C11.0237 5.68344 11.0237 5.36686 10.8284 5.17159C10.6332 4.97633 10.3166 4.97633 10.1213 5.17159L7.99998 7.29293L5.87867 5.17162C5.68341 4.97636 5.36682 4.97636 5.17156 5.17162C4.9763 5.36688 4.9763 5.68347 5.17156 5.87873L7.29287 8.00004L5.17157 10.1213Z',
            fill: '#FFFFFF'
        }
    ],
    'right': [
        {
            type: 'path',
            d: 'M5 14L11 8L5 2',
            stroke: 'currentColor',
            strokeWidth: '2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }
    ],
    'left': [
        {
            type: 'path',
            d: 'M11 2L5 8L11 14',
            stroke: '#5D78FF',
            strokeWidth: '1.5',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }
    ],
    'info': [{
        type: 'path',
        d: 'M7.99998 13.4001C10.9823 13.4001 13.4 10.9824 13.4 8.0001C13.4 5.01776 10.9823 2.6001 7.99998 2.6001C5.01764 2.6001 2.59998 5.01776 2.59998 8.0001C2.59998 10.9824 5.01764 13.4001 7.99998 13.4001ZM7.99998 14.4001C11.5346 14.4001 14.4 11.5347 14.4 8.0001C14.4 4.46548 11.5346 1.6001 7.99998 1.6001C4.46535 1.6001 1.59998 4.46548 1.59998 8.0001C1.59998 11.5347 4.46535 14.4001 7.99998 14.4001ZM7.35998 7.32009C7.35998 6.98872 7.62861 6.72009 7.95998 6.72009C8.29135 6.72009 8.55998 6.98872 8.55998 7.32009V11.2401C8.55998 11.5715 8.29135 11.8401 7.95998 11.8401C7.62861 11.8401 7.35998 11.5715 7.35998 11.2401V7.32009ZM7.95998 4.16008C7.62861 4.16008 7.35998 4.42871 7.35998 4.76008C7.35998 5.09145 7.62861 5.36008 7.95998 5.36008C8.29135 5.36008 8.55998 5.09145 8.55998 4.76008C8.55998 4.42871 8.29135 4.16008 7.95998 4.16008Z',
        fill: 'currentColor'
    }],
    'edit': [
        {
            type: 'path',
            d: "M11.2974 3.29698C11.4861 3.10596 11.7401 3 12.0032 3C12.2663 3 12.5198 3.10596 12.7077 3.29698C12.8957 3.48824 13.0023 3.74916 13.0017 4.02273C13.0011 4.29562 12.8939 4.55593 12.7058 4.74706L5.07679 12.3329L3.18644 12.8121L3.66536 10.8858L11.2942 3.30016L11.2942 3.30018L11.2974 3.29698ZM12.0054 2C11.4732 2 10.9638 2.21424 10.5885 2.59327L2.86175 10.2762C2.7968 10.3408 2.75078 10.4219 2.72869 10.5108L2.01484 13.382C1.97244 13.5526 2.02258 13.7327 2.14692 13.8564C2.27126 13.9802 2.45157 14.0295 2.62191 13.9863L5.45348 13.2685C5.54052 13.2464 5.62006 13.2014 5.68377 13.138L13.4119 5.45362L13.4119 5.45364L13.4151 5.45044C13.7903 5.07057 14.0006 4.55687 14.0017 4.02273C14.0029 3.48859 13.795 2.97489 13.4214 2.59501C13.0476 2.2149 12.5384 2 12.0054 2ZM8.00007 13C7.72393 13 7.50004 13.2238 7.5 13.4999C7.49996 13.7761 7.72379 14 7.99993 14L13.4654 14.0008C13.7416 14.0008 13.9655 13.777 13.9655 13.5008C13.9656 13.2247 13.7417 13.0008 13.4656 13.0008L8.00007 13Z",
            fill: "#484848"
        }
    ],
    'export': [
        {
            type: 'path',
            d: 'M12.831 3.00024L9.1263 7.16806C8.94284 7.37445 8.96143 7.69049 9.16782 7.87395C9.37421 8.05741 9.69025 8.03882 9.8737 7.83243L13.4999 3.75291V5.50024C13.4999 5.77639 13.7238 6.00024 13.9999 6.00024C14.2761 6.00024 14.4999 5.77639 14.4999 5.50024V2.70024C14.4999 2.31364 14.1865 2.00024 13.7999 2.00024L10.9999 2.00024C10.7238 2.00024 10.4999 2.2241 10.4999 2.50024C10.4999 2.77639 10.7238 3.00024 10.9999 3.00024L12.831 3.00024ZM4 3.00025C3.72386 3.00025 3.5 3.22411 3.5 3.50025L3.5 12.5003C3.5 12.7764 3.72386 13.0003 4 13.0003L12.9956 13.0003C13.2718 13.0003 13.4956 12.7764 13.4956 12.5003V8.50025C13.4956 8.22411 13.7195 8.00025 13.9956 8.00025C14.2718 8.00025 14.4956 8.22411 14.4956 8.50025V12.5003C14.4956 13.3287 13.8241 14.0003 12.9956 14.0003L4 14.0003C3.17157 14.0003 2.5 13.3287 2.5 12.5003L2.5 3.50025C2.5 2.67182 3.17157 2.00025 4 2.00025L8 2.00025C8.27614 2.00025 8.5 2.22411 8.5 2.50025C8.5 2.77639 8.27614 3.00025 8 3.00025L4 3.00025Z',
            fill: 'currentColor'
        }
    ],
    'import': [
        {
            type: 'path',
            d: 'M8.50197 8.63732L9.49392 7.69866C9.6945 7.50887 10.011 7.5176 10.2008 7.71818C10.3906 7.91875 10.3818 8.23522 10.1812 8.42501L8.40059 10.11C8.17695 10.3216 7.82693 10.3216 7.60329 10.11L5.82264 8.42502C5.62206 8.23522 5.61332 7.91876 5.80312 7.71818C5.99292 7.5176 6.30938 7.50887 6.50996 7.69867L7.50197 8.63737L7.50191 2.5C7.50191 2.22386 7.72577 2 8.00191 2C8.27805 2 8.50191 2.22385 8.50191 2.5L8.50197 8.63732ZM3.29512 6.22668C3.12943 6.22668 2.99512 6.36099 2.99512 6.52668L2.99512 12.7002C2.99512 12.8658 3.12943 13.0002 3.29512 13.0002L12.709 13.0002C12.8747 13.0002 13.009 12.8659 13.009 12.7002L13.009 6.52668C13.009 6.36099 12.8747 6.22668 12.709 6.22668H10.5719C10.2958 6.22668 10.0719 6.00282 10.0719 5.72668C10.0719 5.45053 10.2958 5.22668 10.5719 5.22668H12.709C13.427 5.22668 14.009 5.8087 14.009 6.52668L14.009 12.7002C14.009 13.4181 13.427 14.0002 12.709 14.0002L3.29512 14.0002C2.57715 14.0002 1.99512 13.4181 1.99512 12.7002L1.99512 6.52668C1.99512 5.80871 2.57715 5.22668 3.29512 5.22668H5.43215C5.70829 5.22668 5.93215 5.45053 5.93215 5.72668C5.93215 6.00282 5.70829 6.22668 5.43215 6.22668L3.29512 6.22668Z',
            fill: 'currentColor'
        }
    ]
};