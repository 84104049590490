import { User, State, Country, ApplicationMode, ParameterSelected } from 'app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '../modules/devextreme/dx-form/models/validation-rules';
import { Currency } from './company';
import { SpecificFieldForConfscreenModel } from './admin/SpecificFieldForConfscreenModel';
import { PostalAddress } from './postalAddress';
 

export class Society extends BaseEntity2 {
    commentairePied: string;
    conditionsPenalite: string;
    conditionsEscompte: string;
    state: State;
    applicationMode: ApplicationMode;
    phone: string;
    fax: string;
    email: string;
    url: string;
    postalAddress: PostalAddress;
    placeOfRegistration: string;
    siretNumber: string;
    vatIdentification: string;
    APECode: string;
    phytoCode: string;
    exciseNumber: string;
    cvi: string;
    cnuf: number;
    users: User[];
    parametersSelected: ParameterSelected[];
    currency: Currency;
    countryCodeGS1: number;
    dateBeginInventory: Date;
    dateEndInventory: Date;

    cdnLogo: string;
    constructor() {
        super();
        this.commentairePied = '';
        this.conditionsPenalite = '';
        this.conditionsEscompte = '';
        this.phone = '';
        this.fax = '';
        this.url = '';
        this.email = '';
        this.state = State.BLOCKED;
        this.applicationMode = null;
        this.postalAddress = new PostalAddress();
        this.placeOfRegistration = '';
        this.siretNumber = '';
        this.vatIdentification = '';
        this.APECode = '';
        this.phytoCode = '';
        this.exciseNumber = '';
        this.cvi = '';
        this.cnuf = 0;
        this.users = null; // *
        this.parametersSelected = null; // *
        this.currency = null;
        this.cdnLogo = '';
        this.countryCodeGS1 = null;
        this.dateBeginInventory = new Date();
        this.dateEndInventory = new Date();
    }
    validator(): ValidationRulesByFieldName {
        return {
            phone: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            fax: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            currency: [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ],
            email: [
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true },
                { type: 'stringLength', max: 100, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            exciseNumber: [
                { type: 'stringLength', max: 13, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
        };
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            {
                nameProp: 'postalAddress',
                objectInstance: new PostalAddress()
            }

        ];
        specificFieldForConfscreenModel.list = ['phoneNumbers'];
        specificFieldForConfscreenModel.excluded = ['name1', 'name2', 'civility', 'socialReason', 'dateUpdated', 'dateCreated', 'version', 'id'];
        specificFieldForConfscreenModel.groupsField = {
            'type': ['customer', 'supplier'],
        };

        return specificFieldForConfscreenModel;
    }
}
