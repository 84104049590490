<div *ngIf="footerInfo" class="left-bloc">

    <div class="info1">
        {{'DataGridFooter.look' | translate}}
    </div>

    <dx-select-box [wrapperAttr]="{ class: 'data-grid-select-size' }" class="data-grid-select-size" [value]="pageSize" [dataSource]="footerInfo.allowedPageSizes"
        (onValueChanged)="changeSize($event)" valueExpr="value" displayExpr="designation"
        dropDownButtonTemplate="dropDownButtonTemplate">
        <div *dxTemplate="let data of 'dropDownButtonTemplate'">
            <ngc-icon size="16" icon="dropdown" style="margin-left: 15px;"></ngc-icon>
        </div>
    </dx-select-box>

    <div class="info2">
        {{footerInfo.elementName}}
    </div>

</div>

<div class="right-bloc" *ngIf="footerInfo">
    <span class='infos-page'>Page {{currentPage + 1}} {{'DataGridFooter.sur' | translate}} {{numberOfPages}}
        ({{footerInfo.totalElements}} {{'DataGridFooter.elements' | translate}})</span>

    <dx-button class='previous-btn' [disabled]="navigationPreviousButtonDisabled" (onClick)="goToPreviousPage()">
        <ngc-icon svgClasses="reverseX" size="16" icon="right"></ngc-icon>
    </dx-button>

    <span *ngIf="buttons.length <= 6">
        <dx-button *ngFor="let button of buttons; let i = index;" [text]="button.text" (onClick)="goToPage(i)"
            [ngClass]="(activeIndex == i) ? 'navigation-btn-active' : 'navigation-btn'">
        </dx-button>
    </span>


    <span *ngIf="buttons.length > 6  && buttons.length < 8">
        <span *ngIf="activeIndex < 4">
            <ng-container *ngFor="let button of buttons let i = index;let first = first;let last = last;">
                <dx-button *ngIf="first" (click)="goToPage(0)"
                    [ngClass]="activeIndex == 0 ? 'navigation-btn-active' : 'navigation-btn'">
                    {{button.text}}</dx-button>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(i)" *ngIf="i > 0 && i<5">{{button.text}}</dx-button>
                <span *ngIf='i == 5' class="dots">...</span>
                <dx-button *ngIf="last" [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(buttons.length - 1)">{{button.text}}</dx-button>
            </ng-container>
        </span>
        <span *ngIf="activeIndex > buttons.length - 4 && activeIndex < buttons.length">
            <ng-container *ngFor="let button of buttons let i = index;let first = first;let last = last;">
                <dx-button [ngClass]="activeIndex == 0 ? 'navigation-btn-active' : 'navigation-btn'" *ngIf="first"
                    (click)="goToPage(0)">{{button.text}}</dx-button>
                <span *ngIf='i == 1' class="dots">...</span>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(i)" *ngIf="i > buttons.length - 6 && i < buttons.length - 1">{{button.text}}
                </dx-button>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'" *ngIf="last"
                    (click)="goToPage(buttons.length - 1)">{{button.text}}</dx-button>
            </ng-container>
        </span>
    </span>

    <span *ngIf="buttons.length > 7">
        <span *ngIf="activeIndex < 4">
            <ng-container *ngFor="let button of buttons let i = index;let first = first;let last = last;">
                <dx-button *ngIf="first" (click)="goToPage(0)"
                    [ngClass]="activeIndex == 0 ? 'navigation-btn-active' : 'navigation-btn'">
                    {{button.text}}</dx-button>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(i)" *ngIf="i > 0 && i<5">{{button.text}}</dx-button>
                <span *ngIf='i == 5' class="dots">...</span>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'" *ngIf="last"
                    (click)="goToPage(buttons.length - 1)">{{button.text}}</dx-button>
            </ng-container>
        </span>
        <span *ngIf="activeIndex > 3 && activeIndex < buttons.length - 4">
            <ng-container *ngFor="let button of buttons let i = index;let first = first;let last = last;">
                <dx-button *ngIf="first" [ngClass]="activeIndex == 0 ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(0)">{{button.text}}</dx-button>
                <span *ngIf='i == 3' class="dots">...</span>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(i)" *ngIf="i == activeIndex-1 ||  i ==activeIndex || i ==  activeIndex+1">
                    {{button.text}}</dx-button>
                <span *ngIf='i == activeIndex + 2' class="dots">...</span>
                <dx-button *ngIf="last" [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(buttons.length - 1)">{{button.text}}</dx-button>
            </ng-container>
        </span>

        <span *ngIf="activeIndex > buttons.length - 5 && activeIndex < buttons.length">
            <ng-container *ngFor="let button of buttons let i = index;let first = first;let last = last;">
                <dx-button *ngIf="first" [ngClass]="activeIndex == 0 ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(0)">{{button.text}}</dx-button>
                <span *ngIf='i == 1' class="dots">...</span>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'"
                    (click)="goToPage(i)" *ngIf="i > buttons.length - 6 && i < buttons.length - 1">{{button.text}}
                </dx-button>
                <dx-button [ngClass]="activeIndex == i ? 'navigation-btn-active' : 'navigation-btn'" *ngIf="last"
                    (click)="goToPage(buttons.length - 1)">{{button.text}}</dx-button>
            </ng-container>
        </span>
    </span>


    <dx-button class='previous-btn' [disabled]="navigationNextButtonDisabled"  (onClick)="goToNextPage()">
        <ngc-icon size="16" icon="right"></ngc-icon>
    </dx-button>

</div>