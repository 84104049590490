import { ScreenIdentifier } from '@app/shared/model';
import { IDaoBaseService } from '@app/shared/interface';
import { FormField } from '@app/shared/modules/devextreme/dx-form/models';
export enum HeaderMode { List, Create, Edit }

export class SubtitleItem {
    attribute?: string;
    icon?: string;
    textCallback?: (item: any) => string;
    label?: string;
    priority?: number;
    displayIfEmpty?: boolean;
    rightAlign?: boolean;
    /** Class css pour le label du subtitle */
    classLabel?: string;
    /** Class css pour le item du subtitle */
    classItem?: string;
}
export class SubHeaderInfo {
    /**identifiant du parent used only in parent */
    public id?: any;
    /** nom des champs a affichés depuis l'objet currentItem récupéré du service */
    public labelFieldName?: string[] = [];
    /** text a afficher en titre */
    public fixedTitle?: string | ((item: any) => string);
    /** nom du champ clé primaire pour affiché l'identifiant*/
    public primaryKeyFieldName?: string = 'id';
    /** le service du parent pour récupéré l'objet parent  parentService.currentItem*/
    public parentService?: IDaoBaseService;
    // public parentServiceGetParams: any [];
    /** image customField name */
    public displayImage?: FormField;
    public imageDisplayType?: 'round' | 'rectangle';
    /** boutons a afficher en dessous du subtitle */
    public toolbarItems?: any[];
    /** actions en toutes lettres */
    public textAction?: any[];
    /** les champ à afficher en tant que subitems */
    public subtitleItems?: SubtitleItem[];
    /** icon to show */
    public icon?: string | ((item: any) => string);
    /** fields to show in details area */
    public detailsFields?: string[];
    public state?: ((item: any) => {
        label: string;
        type: string
    });
    public commentColumn : SubtitleItem;
}
export class HeaderInfo {
    /**ca indique le type du page (liste/creation/edition) */
    public mode: HeaderMode;
    /** page title To Translate */
    public pageTitle: string | ((item: any) => string);
    public icon?: string| ((item: any) => string);
    /** configuration parent */
    public parent?: SubHeaderInfo;
    /** configuration child */
    public child?: SubHeaderInfo;
    /** type d'ecran 0 - 1 - 2 = custom et > 2 = others (normal screen) */
    public type?: ScreenIdentifier;

    public service?: IDaoBaseService;

    public hasParent?: boolean;

    public isDuplication?: boolean;

    /**
     *
     */
    constructor() {

    }

    /**
     * crée une instance du left headerbloc 2éme constructeur
     * @param id identifiant de l'objet courant s'affiche dans le header # 'id'
     * @param title le titre qui s'affiche en mode creation
     * @param fieldNames tableaux contenant le nom des champ a afficher depuis l'objet inserer dans le composant left header bloc
     * @param modeList true si le bloc est dans une page datagird et pas un formulaire
     */
    static buildSimple(id: any, title: string, fieldNames: string[], modeList: boolean = false) {
        const _this = new this();
        _this.mode = modeList ? HeaderMode.List : id ? HeaderMode.Edit : HeaderMode.Create;
        _this.pageTitle = title;


        if (_this.mode !== HeaderMode.List) {
            _this.parent = new SubHeaderInfo();
            _this.parent.labelFieldName = fieldNames;
            _this.parent.id = id;
            _this.parent.imageDisplayType = 'rectangle';
        }
        return _this;
    }

}
