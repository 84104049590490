import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { BaseEntity2 } from '../base-entity2';
import { GestionRegiFlow, ZoneViticole } from '../enum';
import { CruCognac } from './cruCognac';
import { ManipulationViticole } from './manipulationViticole';
import { ProductState } from './productState';
import { QualiteCognac } from './qualiteCognac';

export class ArticleRegi extends BaseEntity2 {
    idArticleRegiFlow?: number;
    idCheckArticle: number;
    gestionRegiFlow: GestionRegiFlow;
    productState: ProductState;
    manipulationViticole: ManipulationViticole;
    zoneViticole?: ZoneViticole;
    isCognac: boolean;
    cruCognac: CruCognac;
    qualiteCognac: QualiteCognac;
    compteAge: string;

    constructor() {
        super();
        this.idArticleRegiFlow = null;
        this.idCheckArticle = null;
        this.gestionRegiFlow = null;
        this.productState = null;
        this.manipulationViticole = null;
        this.zoneViticole = null;
        this.isCognac = false;
        this.cruCognac = null;
        this.qualiteCognac = null;
        this.compteAge = null;
    }

    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {
        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = ['dateUpdated', 'dateCreated', 'version', 'id', 'gestionRegiFlow'];
        return specificFieldForConfscreenModel;
    }
}
