import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CompanyType, CompanyClass } from 'app/shared/model';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyClassService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(private http: HttpClient,
        private router: Router,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration) {
            super(http, _translate, appConfiguration);
        }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(companyType: CompanyType): Observable<any[]> {
        const url = this.appConfiguration.UrlsConfig.wsClass + companyType;
        if (!this[companyType]) {
            this[companyType] = this.http.get<any[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this[companyType];
    }

    post(payload: CompanyClass, companyType: CompanyType): Observable<NGCResults<CompanyClass>> {
        const url = this.appConfiguration.UrlsConfig.wsClass + companyType;
        return this.http.post<NGCResults<CompanyClass>>(url, payload).clearListCache(this.clearListTablesCache, companyType);
    }

    /**
     *
     * @param id
     * @param payload
     */
    put(id: any, payload: CompanyClass, companyType: CompanyType): Observable<NGCResults<CompanyClass>> {
        const url = this.appConfiguration.UrlsConfig.wsClass + companyType + '/' + id;
        return this.http.put<NGCResults<CompanyClass>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = (companyType: CompanyType) => {
        this[companyType] = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: any, companyType: CompanyType): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsClass + companyType + '/' + id;
        return this.http.delete(url);
    }

    navigateToDetail(id: any, companyType: CompanyType) {
        this.router.navigate(['/parameters/' + companyType + '/class/detail', id]);
    }

    navigateToCreatePage(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/class/create']);
    }

    navigateToList(companyType: CompanyType): void {
        this.router.navigate(['/parameters/' + companyType + '/class']);
    }
}
