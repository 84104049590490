import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { ExportService } from '@app/core/services/export/export.service';
import { ImportService } from '@app/core/services/import/import.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-mobil-store-panel',
    templateUrl: './mobil-store-panel.component.html',
    styleUrls: ['./mobil-store-panel.component.scss']
})
export class MobilStorePanelComponent implements OnDestroy  {

    // mobi strore
    visibleChange = new EventEmitter<boolean>();
    toggleImportExportVisible: boolean;
    exportParam: boolean;
    mobileStoreData = false;
    imageArticle = false;
    alertMobi: boolean;
    loadingMobi: boolean;
    private subscriptions: Subscription[] = [];

    constructor(
        private exportService: ExportService,
        private importService: ImportService

    ) { }

    toggleMobiStore(): void {
        this.toggleImportExportVisible = !this.toggleImportExportVisible
    }

    visibleChanged(visibility: boolean): void {
        if (!visibility) {
            this.exportParam = false;
            this.mobileStoreData = false;
            this.imageArticle = false;
         
        }
        this.toggleImportExportVisible = visibility;
        this.visibleChange.emit(this.toggleImportExportVisible);
    }

    ExportMore(): void {
        this.exportParam = !this.exportParam
    }

    validateExport(mobileStoreData: boolean, imageArticle: boolean): void {
        this.subscriptions.push(
            this.exportService.mobilStoreExport({ exportImage: imageArticle, exportData: mobileStoreData }).subscribe()
        );
        
        this.toggleMobiStore();
    }

    validateImport(): void {
        this.subscriptions.push(
            this.importService.mobilStoreImport().subscribe()
            );
        this.toggleMobiStore();
    }

    showResults(): void {
        console.log('Results');
        this.toggleMobiStore();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }

}
