import { ParameterCategory, BaseEntity2 } from '..';
import { CommercialOperationTypeVisiblity } from '.';

export enum CodeCommercialOperationType {
    COMMANDE = 1,
    OFFRE = 2,
    RETOUR_CLIENT = 3,
    ACHAT = 4,
    RETOUR_FOURNISSEUR = 5,
    TICKET = 6,
    ALL = 7

}
export class CommercialOperationType extends BaseEntity2 {

    label: string;
    designation: string;
    code: CodeCommercialOperationType;
    actif: boolean;
    parameterCategory: ParameterCategory;
    visibility: CommercialOperationTypeVisiblity;

    constructor() {
        super();
        this.label = '';
        this.designation = '';
        this.code = null;
        this.actif = true;
        this.parameterCategory = null;
        this.visibility = CommercialOperationTypeVisiblity.BOTH;

    }
}

