import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, timer, Subscription } from 'rxjs';
import { Country } from '@app/shared/model/country';
import { AppConfiguration } from './application/app-configuration.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AppContextService implements OnDestroy {
    private _loadingBar: Subject<boolean> = new Subject<boolean>();
    private subscriptions: Subscription[] = [];
    private _nbReqestInProgress = 0;


    constructor(
        private httpClient: HttpClient,
        private appConfiguration: AppConfiguration,
        public localStorage: StorageService
    ) {
    }

    public hasKey(name: string): boolean {
        return this.getPersisted(name) != undefined;
    }

    public setPersist(name: string, value: any): void {
        if (typeof (value) == 'string') {
            this.localStorage.setItem(name, value);
        } else {
            this.localStorage.setItem(name, JSON.stringify(value));
        }
    }

    public patchPersist(name: string, value: any): void {
        value = { ...this.getPersisted(name), ...value };
        if (typeof (value) == 'string') {
            this.localStorage.setItem(name, value);
        } else {
            this.localStorage.setItem(name, JSON.stringify(value));
        }
    }

    public getPersisted(name: string): any {
        let chaine = this.localStorage.getItem(name);
        if (chaine == undefined) {
            return undefined;
        }
        try {
            chaine = JSON.parse(chaine);
        } catch (error) {
            chaine = chaine;
        }
        return chaine;
    }

    public deletePersisted(name: string) {
        this.localStorage.removeItem(name);
    }

    //#region loading bar
    get loadingBar(): Observable<boolean> {
        return this._loadingBar.asObservable();
    }

    public changeLoadingBarState(state: boolean) {
        this._loadingBar.next(state);
    }
    //#endregion loading bar

    //#region loader panel
    public addRequestInProgress() {
        this._nbReqestInProgress++;
    }

    public removeRequestInProgress() {
        if (this.nbRequestInProgress == 1) {
            this.subscriptions.push(
                timer(200).subscribe(
                    () => this._nbReqestInProgress--
                )
            );
        } else {
            this._nbReqestInProgress--;
        }
    }

    public get nbRequestInProgress() {
        return this._nbReqestInProgress;
    }
    //#endregion loader panel

    public getCountries(): Observable<Country[]> {
        const url = this.appConfiguration.UrlsConfig.wsApi + 'getCountries';
        return this.httpClient.get<Country[]>(url);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subsc => {
            subsc.unsubscribe();
        });
    }
}
