import { SupplierRepresentative } from '../../company';
import { BaseEntity2 } from '../..';
import { SupplierCommercialOperation } from './supplierCommercialOperation';
import { SpecificFieldForConfscreenModel } from '../../admin/SpecificFieldForConfscreenModel';

export class SupplierCommercialOperationRepresentative extends BaseEntity2 {

    operation: SupplierCommercialOperation;

    representative1: SupplierRepresentative;
    representative2: SupplierRepresentative;
    representative3: SupplierRepresentative;
    representative4: SupplierRepresentative;
    representative5: SupplierRepresentative;
    representative6: SupplierRepresentative;

    constructor() {
        super();
        this.operation = null;
        this.representative1 = null;
        this.representative2 = null;
        this.representative3 = null;
        this.representative4 = null;
        this.representative5 = null;
        this.representative6 = null;

    }
    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {

        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = [ 'operation'];
        return specificFieldForConfscreenModel;
    }
}
