import { BaseEntity2 } from '../base-entity2';
import { ManagementTypeTaxLine } from '.';
import { TaxTypeRight } from '../article/taxTypeRight';

export class CommercialOperationLineViti extends BaseEntity2 {
    degreeAlcoholPur: number;
    unitEffectifVolume: number;
    totalEffectifVolume: number;
    pureAlcoholVolume: number;
    managementTypeTaxLine: ManagementTypeTaxLine;
    taxTypeRight: TaxTypeRight;

    constructor() {
        super();
        this.degreeAlcoholPur = 0;
        this.unitEffectifVolume = 0;
        this.totalEffectifVolume = 0;
        this.pureAlcoholVolume = 0;
        this.managementTypeTaxLine = ManagementTypeTaxLine.DAQ;
        this.taxTypeRight = null;
    }
}
