import {Widget} from 'app/shared/model/widget/Widget';
import {ApplicationMode, WidgetType} from 'app/shared/model/widget/index';
import {ChartType} from 'app/shared/model/chart/ChartType';
import {WidgetBreaking} from 'app/shared/model/widget/WidgetBreaking';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';

export class WidgetBase extends Widget  {

    name: string;
    type: WidgetType;
    available: boolean;
    applicationMode: ApplicationMode;
    chartTypes: ChartType[];
    widgetBreakings: WidgetBreaking[];

  constructor() {
    super();
    this.name = '';
    this.type = WidgetType.INTERNAL;
    this.available = false;
    this.applicationMode = ApplicationMode.COMMUN;
    this.chartTypes = [];
    this.widgetBreakings = [];
  }

  validator(): ValidationRulesByFieldName {
    return {
        name: [
            { type: 'stringLength', max: 50, message: 'FormErrorMessages.StringMaxLength', ignoreEmptyValue: false, useTranslator: true },
            { type: 'custom', message: 'FormErrorMessages.Required', useTranslator: true, reevaluate: true },
        ],
        description: [
            { type: 'stringLength', max: 50, message: 'FormErrorMessages.StringMaxLength', ignoreEmptyValue: false, useTranslator: true },
            { type: 'custom', message: 'FormErrorMessages.Required', useTranslator: true, reevaluate: true },
        ],
        refreshRate: [
            { type: 'range', min: 1, message: 'FormErrorMessages.MinNumber', ignoreEmptyValue: false, useTranslator: true },
        ],
        // city: [
        //     { type: 'stringLength', max: 60, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
        // ],
        // addressLine: [
        //     { type: 'required', message: 'FormErrorMessages.Required', useTranslator: true, reevaluate: true },
        //     {
        //         type: 'custom', validationCallback:
        //             ((options: { value?: any, rule?: any, validator?: any, data?: any }) => {
        //                 console.log('test options', options);
        //                 return options.value && options.value.length > 0 && options.value.length < 5;
        //             }), message: 'Nombre d\'adresse entre 1 et 4', useTranslator: true, reevaluate: true
        //     }
        // ]
    };
}

}
