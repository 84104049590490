import { Injectable } from '@angular/core';
import { Depot } from '@app/shared/model/stock/depot';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { IDaoBaseService } from '@app/shared/interface/i-dao-base-service';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { TranslateService } from '@ngx-translate/core';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';
@Injectable({
    providedIn: 'root'
})
export class DepotService extends DaoGridStateBaseService implements IDaoBaseService {
    public _list: Observable<Depot[]> = null;

    constructor(
        private http: HttpClient,
        private _translate: TranslateService,
        private appConfiguration: AppConfiguration
    ) {
        super(http, _translate, appConfiguration);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    getAll(): Observable<Depot[]> {
        const url = this.appConfiguration.UrlsConfig.wsDepot;
        if (!this._list) {
            this._list = this.http.get<Depot[]>(url)
                .pipe(
                    shareReplay(1)
                );
        }
        return this._list;
    }

    get(id: number): Observable<Depot> {
        let serviceUrl: string = null;
        serviceUrl = this.appConfiguration.UrlsConfig.wsDepot + '/' + id;
        return this.http.get<Depot>(serviceUrl);
    }

    /**
    * Create a ArticleDepot
    * @param payload request body
    */
    post(payload: Depot): Observable<NGCResults<Depot>> {
        const url = this.appConfiguration.UrlsConfig.wsDepot;
        return this.http.post<NGCResults<Depot>>(url, payload).clearListCache(this.clearListTablesCache);
    }

    /**
    * Update a ArticleDepot
    * @param id ArticleDepot id
    * @param payload ArticleDepot data
    */
    put(id: number, payload: Depot): Observable<NGCResults<Depot>> {
        const url = this.appConfiguration.UrlsConfig.wsDepot + id;
        return this.http.put<NGCResults<Depot>>(url, payload);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour faire clear cache des données
    clearListTablesCache = () => {
        this._list = null;
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    // pour afficher le titre de la page liste
    getGridTitle(): string {
        return 'depot';
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    delete(id: number): Observable<any> {
        const url: string = this.appConfiguration.UrlsConfig.wsDepot + id;
        return this.http.delete(url);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToDetail(id: number) {
       // this.router.navigate(['/article/settings/Depot/detail', code]);
    }

    // méthode appeler par composent générique shared/module/devextreme/data-grid.component
    navigateToCreatePage(): void {
       // this.router.navigate(['/article/settings/Depot/create']);
    }

    navigateToList(): void {
       // this.router.navigate(['/article/settings/Depot']);
    }

}
