import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { DxListComponent } from 'devextreme-angular/ui/list';

@Component({
    selector: 'app-ngc-grid-state',
    templateUrl: './ngc-grid-state.component.html',
    styleUrls: ['./ngc-grid-state.component.scss']
})
export class NgcGridStateComponent implements OnInit {
    @Input() columnChooserPopupVisible: boolean = false;
    @Input() columnChooserColumn: string[] = [];
    @Input() columnChooserSelected: string[] = [];
    confirmPopupUndo: boolean = false;
    @Output() resetTable = new EventEmitter();
    @Output() cancelResetTable = new EventEmitter();
    @Output() closeOnOutsideClickChange: EventEmitter<boolean | Function> = new EventEmitter<boolean | Function>();
    @Output() saveTableState = new EventEmitter<string[]>();
    @ViewChild('columnChooser_list', { static: false }) columnChooser_list: DxListComponent;
    datagridSearch = {
        buttonsBefore:
            [
                {
                    name: 'loop',
                    options: {
                        disabled: true,
                        stylingMode: 'text',
                        icon: 'search',
                        template: 'templateButtonBefore'
                    }
                }
            ]
    };
    screenHeight: number;
    searchingWord: string;

    constructor() { }

    ngOnInit() {
        this.screenHeight = window.innerHeight - 680;
    }

    onSearchingWordValueChanged(event) {
        this.columnChooser_list.searchValue = event?.value;
        this.searchingWord = event?.value;
    }
    onHidingBinder() {
        this.cancelResetTable.emit();
        this.onSearchingWordValueChanged(null);

    }

    // méthode utiliser pour désactiver le réordering en mode d'affichage plusieurs colomne
    resizePopup(e) {

        /*if ((this.columnChooser_list['element']['nativeElement']['clientWidth']) > 400) {
            this.columnChooser_list.allowItemReordering = false;
        } else {
            this.columnChooser_list.allowItemReordering = true;
        }*/
    }
    /*************************************** /méthdes pour columnChooser */

    // méthode appelé l'or de l'événement onItemReordered
    itemReordered(e) {

        this.columnChooserSelected = ['Id'];
        for (let i = 0; i < this.columnChooser_list.items.length; i++) {
            if (this.columnChooser_list.selectedItems.indexOf(this.columnChooser_list.items[i]) > -1) {
                this.columnChooserSelected.push(this.columnChooser_list.items[i]);
            }
        }

    }

    public onTableStateReset() {
        this.confirmPopupUndo = true;

    }

    public confirmTableStateReset() {
        this.resetTable.emit();
        this.confirmPopupUndo = false;
    }


    public cancelTableStateReset() {
        this.cancelResetTable.emit();
        this.confirmPopupUndo = false;
        this.columnChooserPopupVisible = false;
    }

    public tableStateSave() {
        this.saveTableState.emit(this.columnChooserSelected);
    }

    public getHeight(): number {
        return (window.innerHeight - 144);
    }
}
