<div class="search-field">
    <dx-text-box id='extraButton' valueChangeEvent="keyup" (onValueChanged)="onValueChanged($event)"
    (onFocusIn)="onFocusIn($event)" [(value)]="value" placeholder="Recherche..." >

    <dxi-button *ngFor="let after of buttonsAfter" [name]="after.name" location="after" [options]="after.options"
        [visible]="after.options.visible">
        <div *dxTemplate="let data of 'templateButtonAfter'" [className]="after.class">
            <ngc-icon [icon]="after.options.icon" size="20" style="padding-left: 4px;"></ngc-icon>
        </div>
    </dxi-button>
    <dxi-button *ngFor="let before of buttonsBefore" [name]="before.name" location="before" [options]="before.options">
        <div *dxTemplate="let data of 'templateButtonBefore'">
            <ngc-icon [icon]="before.options.icon" size="20" style="padding-left: 4px;"></ngc-icon>
        </div>
    </dxi-button>
</dx-text-box>

<div class="filter-slide-panel" *ngIf="slidePanelOptions">
    <app-slide-panel [visible]="slidePanelOptions.isSlidePanelOpen"
        [slidePanelOptions]="slidePanelOptions"
        [slidePanelChildOptions]="slidePanelChildOptions" (validateSuccess)="onValidateSuccess($event)"
        (slidePanelClose)="onSlidePanelClose($event)" *ngIf="slidePanelOptions.isSlidePanelOpen">
        {{filterTemplateContent ?
            filterTemplateContent.template : null }}
    </app-slide-panel>
</div>

</div>
