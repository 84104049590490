export class SVGElem {
    type: 'path' | 'ellipse' | 'circle' | 'rect';
    d?: string;
    viewBox?: string;
    fill?: string;

    // ellipse
    cx?: number;
    cy?: number;
    rx?: number;
    ry?: number;

    // circle
    r?: number;
    transform?: string;

    // rect
    x?: number;
    y?: number;
    width?: number;
    height?: number;

}


