<!-- popup report favoris -->
<ngc-next-dx-popup #popupFavoris [isPopupPrintTwoVisible]="isPopupPrintTwoVisible" [isPopupPrintVisible]="true"
    [identifier]="popupFavEtiquettesIdentifier | popupIdentifier" [showTitle]="true" [title]="title" [dragEnabled]="true"
    [resizeEnabled]="true" [position]="popupPrintOnePosition" [closeOnOutsideClick]="false"
    [visible]="isPopupPrintVisible" (onHidden)="popupOneHiding()" [height]="400" [width]="600"
    (clickMore)="onMoreClicked()" (clickRefresh)="onRefreshClicked()" [popupPrintType]="'popupFavoris'" [popupClass]="popupClass"
    [isPopupForEtiquette]="isPopupForEtiquette">

    <div content class="popup-content" [ngStyle]="{'height': popupFavoris.height - 71 + 'px'}">
        <div cdkDropList #favoriteEtiquettes="cdkDropList" [cdkDropListData]="favoriteEtiquetteList"
            cdkDropListConnectedTo="allEtiquettes" (cdkDropListDropped)="drop($event)" class="list" id="favoriteEtiquettes">

            <div class="no-reports" id="no-reports"  *ngIf="!favoriteEtiquetteList.length && !isLoadPanelVisible">
                {{ 'NoFavoriteReport' | translate }}
            </div>

            <div class="box" *ngFor="let item of favoriteEtiquetteList" cdkDrag>
                <ng-template [ngIf]="!item.idOrigine && !item.idOriginePerso">
                    <div (click)="viewerEtiquette(item, 0)" class="report baseReport">
                        <span>{{ item.name }}</span>
                    </div>
                </ng-template>
                <ng-template [ngIf]="item.idOrigine || item.idOriginePerso">
                    <div (click)="viewerEtiquette(item, 1)" class="report persoReport">
                        <span>{{ item.name }}</span>
                    </div>
                </ng-template>
            </div>

        </div>
    </div>

</ngc-next-dx-popup>

<!-- popup report !favoris -->
<ngc-next-dx-popup #popupNotFavoris [isPopupPrintTwoVisible]="isPopupPrintTwoVisible" [isPopupPrintVisible]="false"
    [identifier]="popupEtiquettesIdentifier | popupIdentifier" [visible]="isPopupPrintTwoVisible" [dragEnabled]="true"
    [resizeEnabled]="true" [closeOnOutsideClick]="false" [height]="400" [width]="600" [position]="popupPrintTwoPosition"
    [showTitle]="true" [title]="title" (onHidden)="popupTwoHiding()" [popupPrintType]="'popupNotFavoris'" [popupClass]="popupClass"
    [isPopupForEtiquette]="isPopupForEtiquette">

    <div content class="popup-content" [ngStyle]="{'height': popupNotFavoris.height - 71 + 'px'}">

        <div cdkDropList #allEtiquettes="cdkDropList" [cdkDropListData]="etiquetteList"
            cdkDropListConnectedTo="favoriteEtiquettes" (cdkDropListDropped)="drop($event)" class="list" id="allEtiquettes">

            <div class="no-reports" *ngIf="!etiquetteList.length && !isLoadPanelVisible">
                {{ 'NoReport' | translate }}
            </div>

            <div class="box_list" *ngIf="etiquetteListBase.length">
                <div class="box_header base">
                    <span class="title" translate>popup.print.title.baseList</span>
                </div>
                <div class="box" *ngFor="let item of etiquetteList" cdkDrag>
                    <ng-template [ngIf]="!item.idOrigine && !item.idOriginePerso && (item.activity === applicationMode || item.activity === 0)">
                        <div (click)="viewerEtiquette(item, 0)" class="report baseReport">
                            <span class="report-name">{{ item.name }}</span>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="box_list" *ngIf="etiquetteListPerso.length">
                <div class="box_header perso">
                    <span class="title" translate>popup.print.title.persoList</span>
                </div>
                <div class="box" *ngFor="let item of etiquetteList" cdkDrag>
                    <ng-template [ngIf]="item.idOrigine || item.idOriginePerso">
                        <div (click)="viewerEtiquette(item, 1)" class="report persoReport">
                            <span class="report-name">{{ item.name }}</span>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>
    </div>
</ngc-next-dx-popup>
