import { BaseEntity2 } from '../base-entity2';

export class CostGroup extends BaseEntity2 {
    designation: string;
    visible: boolean;
    constructor() {
        super();
        this.designation = '';
        this.visible = true;
    }

    initValues(): Partial<CostGroup> {
        return {
            visible: true
        };
    }
}
