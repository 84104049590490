<div class="header-wrapper">

    <dx-button class="focusing side-nav-toggler " [hoverStateEnabled]="false" [focusStateEnabled]="false" type="default"
        (click)="toggleSideNav()" stylingMode="text" [template]="'buttonTemplate'">
        <div *dxTemplate="let data of 'buttonTemplate'">
            <span style="color: #FFFFFF">
                <ngc-icon size="24" icon="menu"></ngc-icon>
            </span>
        </div>
    </dx-button>

    <div class="right-part">
        <app-mobil-store-panel *ngIf="mobilStore"></app-mobil-store-panel>
        <div id="userInfo" (click)="toggleMenu()" *ngIf="currentUser">
            <img *ngIf="currentUser && currentUser.cdnPhoto" alt="Photo"
                [src]="(currentUser.cdnPhoto + '&w=300&h=50' | securesrc) | async" class="circleImageCurrentUser">
            <div id="userName" class="dx-theme-background-color-as-text-color">{{ currentUser.name }}
                {{ currentUser.firstName }}
                <ngc-icon size="20" icon="down" style="margin: 10px;" *ngIf='!toggleMenuState'></ngc-icon>
                <ngc-icon size="20" icon="vectorStroke" style="margin: 10px;" *ngIf='toggleMenuState'></ngc-icon>
            </div>



        </div>
        <div id="menu">
            <div class="menu-link" *ngIf="currentUser && currentUser.type != UserType.SUPER_ADMINISTRATOR" [routerLink]="['/admin/user/detail/', currentUser.id]">
                <span class="icon">
                    <ngc-icon size="20" icon="profile"></ngc-icon>
                </span>
                <span class="title">{{ 'Menu.MonCompte' | translate }}</span>
            </div>
            <div class="menu-link" *ngIf="currentUser && currentUser.type != UserType.SUPER_ADMINISTRATOR && currentUser.type != UserType.USER" [routerLink]="['/admin/administration']">
                <span class="icon">
                    <ngc-icon size="20" icon="admin"></ngc-icon>
                </span>
                <span class="title">{{ 'Menu.Admin' | translate }}</span>
            </div>
            <div class="menu-link" *ngIf="currentUser && currentUser.type != UserType.SUPER_ADMINISTRATOR" [routerLink]="['/admin/imports']">
                <span class="icon">
                    <ngc-icon size="20" icon="import"></ngc-icon>
                </span>
                <span class="title">{{ 'Menu.Myimports' | translate }}</span>
            </div>

            <div class="menu-link" *ngIf="currentUser && currentUser.type != UserType.SUPER_ADMINISTRATOR"
                (click)="goToHelpCenter()">
                <span class="icon">
                    <ngc-icon size="20" icon="help"></ngc-icon>
                </span>
                <span class="title">{{ 'Menu.Help' | translate }}</span>
            </div>

            <div class="divider"></div>

            <div class="menu-link" (click)="authService.logoutWithReload()">
                <span class="icon">
                    <ngc-icon size="20" icon="logOut"></ngc-icon>
                </span>
                <span class="title">{{ 'Menu.Deconnexion' | translate }}</span>
            </div>

        </div>
    </div>

</div>
