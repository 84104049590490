<div class="row col-md-12 col-sm-12 col-lg-12 col-xs-12 fileUpload">
    <div class="col-md-6 col-sm-12 col-lg-2 col-xs-12" [ngClass]="{'offset-md-3
        offset-lg-0': field.editorOptions.multiple}">
        <dx-file-uploader [ngClass]="!item[field.dataField + '_upload'] ||
            !item[field.dataField + '_upload'].value || item[field.dataField +
            '_upload'].value == 'delete' ? 'empty-value' : ''"
            class="ngc-file-uploader {{transalteService.currentLang}}" [disabled]="disabled"
            [labelText]="'DropHere' | translate" [accept]="field.editorOptions && field.editorOptions.accept ?
            field.editorOptions.accept : 'image/*'" (onValueChanged)="onFileChange($event)" uploadMode="useButtons"
            [multiple]="(field.editorOptions && field.editorOptions.multiple) ?
            field.editorOptions.multiple : false" [isValid]="!field.errorList || field.errorList.isValid"
            [visible]="field.visible" readyToUploadMessage="">
        </dx-file-uploader>
        <div class="uploadUrl">
            <span>{{'upload.or' | translate}}</span>
            <a class="lien" (click)="!disabled && selectClick()">{{'upload.select'
                | translate}}</a>
            <span>{{'upload.fromPc' | translate}}</span>
            <span *ngIf="field.editorOptions.externalUrlFieldName">{{'upload.fromUrl'
                | translate}}</span>
            <a class="lien" *ngIf="field.editorOptions.externalUrlFieldName"
                (click)="!disabled && selectClickFromUrl()">URL</a>
        </div>
    </div>
    <!-- cas de upload non multiple -->
    <ng-template [ngIf]="!field.editorOptions.multiple" [ngIfElse]="multipleFiles">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xs-12" style="margin-left:
            32px;">
            <div class="item" *ngIf="(item[field.dataField] && !item[field.dataField +
                '_upload']) || (item[field.dataField] && item[field.dataField +
                '_upload'].value == null)">
                <div class="singleImageContainer">
                    <img alt="Photo" [src]="(item[field.dataField] + '&w=' +
                        field.editorOptions.imageSize.w + '&h=' +
                        field.editorOptions.imageSize.h | securesrc) | async" class="logoSocietyAddonly " />
                    <div class="spaced-image-btn">
                        <div class="btn-container">
                            <dx-button [hint]="'form.View'|translate" (click)="view(item)" type="normal"
                                stylingMode="text" [template]="'buttonTemplate'">
                                <div *dxTemplate="let data of
                                    'buttonTemplate'">
                                    <ngc-icon size="20" icon="show"></ngc-icon>
                                </div>
                            </dx-button>
                        </div>
                        <div class="btn-container">
                            <dx-button [hint]="'form.Remove'|translate" (click)="deleteFile(null, $event)" type="normal"
                                stylingMode="text" [template]="'buttonTemplate'">
                                <div *dxTemplate="let data of
                                    'buttonTemplate'">
                                    <ngc-icon size="20" icon="trash"></ngc-icon>
                                </div>
                            </dx-button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="item[field.dataField + '_upload'] && item[field.dataField
                + '_upload'].value && item[field.dataField + '_upload'].value !=
                'delete'">
                <div class="singleImageContainer">
                    <img alt="Photo" src="{{ item[field.dataField +
                        '_upload'].value }}" class="logoSocietyAddonly" />
                    <div class="spaced-image-btn">
                        <div class="btn-container">
                            <dx-button [hint]="'form.View'|translate" type="normal"
                                (click)="view(item[field.dataField + '_upload'])" stylingMode="text"
                                [template]="'buttonTemplate'">
                                <div *dxTemplate="let data of
                                    'buttonTemplate'">
                                    <ngc-icon size="20" icon="show"></ngc-icon>
                                </div>
                            </dx-button>
                        </div>
                        <div class="btn-container">
                            <dx-button [hint]="'form.Remove'|translate" type="normal" (click)="deleteFile(null, $event)"
                                stylingMode="text" [template]="'buttonTemplate'">
                                <div *dxTemplate="let data of
                                    'buttonTemplate'">
                                    <ngc-icon size="20" icon="trash"></ngc-icon>
                                </div>
                            </dx-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #multipleFiles>
        <div class="image-list-container col-md-12 col-sm-12 col-lg-9 col-xs-12">
            <div class="row">
                <!-- default image -->
                <div class="col-md-4 col-sm-12 col-lg-3 col-xs-12" *ngIf="field.editorOptions.defaultImageFieldName">
                    <div class="default-image-container" *ngIf="defaultImage">
                        <div class="tile-image default-image" *ngIf="defaultImage.id" [style.background-image]="'url(' +
                            ((field.editorOptions.externalUrlFieldName &&
                            defaultImage[field.editorOptions.externalUrlFieldName])
                            ?
                            defaultImage[field.editorOptions.externalUrlFieldName]
                            :
                            (( defaultImage[field.editorOptions.multiple] +
                            '&w=' + field.editorOptions.imageSize.w + '&h=' +
                            field.editorOptions.imageSize.h | securesrc) |
                            async))
                            + ')'">
                            <div class="spaced-image-btn">
                                <div class="btn-container" *ngIf="field.editorOptions.defaultImageFieldName">
                                    <dx-button [hint]="'form.AddFavoris'|translate" type="normal" stylingMode="text"
                                        [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of
                                        'buttonTemplate'">
                                            <ngc-icon size="20" icon="star1"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                                <div class="btn-container">

                                    <dx-button [hint]="'form.View'|translate" type="normal" (click)="view(defaultImage)"
                                        stylingMode="text" [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of
                                            'buttonTemplate'">
                                            <ngc-icon size="20" icon="show"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                                <div class="btn-container">
                                    <dx-button [hint]="'form.Remove'|translate" type="normal"
                                        (click)="deleteFile(null, $event)" stylingMode="text"
                                        [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of 'buttonTemplate'">
                                            <ngc-icon size="20" icon="trash"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                            </div>
                        </div>
                        <div class="tile-image" *ngIf="defaultImage.cdnImage_upload && defaultImage.id">
                            <div class="deleted-from-database" [style.background-image]="'url(' +
                            ((field.editorOptions.externalUrlFieldName && defaultImage[field.editorOptions.externalUrlFieldName]) ?
                            defaultImage[field.editorOptions.externalUrlFieldName] :
                             (( defaultImage[field.editorOptions.multiple] + '&w=' + field.editorOptions.imageSize.w + '&h=' + field.editorOptions.imageSize.h | securesrc) | async))
                             + ')'">
                            </div>
                            <dx-button class="restore-btn" [hint]="'form.Restore'|translate" type="normal"
                                (click)="restoreFile()" stylingMode="text" [template]="'buttonTemplate'">
                                <div *dxTemplate="let data of 'buttonTemplate'">
                                    <ngc-icon size="24" icon="undo"></ngc-icon>
                                </div>
                            </dx-button>
                        </div>
                        <div class="tile-image default-image" *ngIf="!defaultImage.id" [style.background-image]="'url('+
                            ((field.editorOptions.externalUrlFieldName && defaultImage[field.editorOptions.externalUrlFieldName]) ? defaultImage[field.editorOptions.externalUrlFieldName] :
                            defaultImage[field.editorOptions.multiple + '_upload'].value)
                             + ')'">
                            <div class="spaced-image-btn">
                                <div class="btn-container" *ngIf="field.editorOptions.defaultImageFieldName">
                                    <dx-button [hint]="'form.AddFavoris'|translate" type="normal" stylingMode="text"
                                        [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of
                                        'buttonTemplate'">
                                            <ngc-icon size="20" icon="star1"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                                <div class="btn-container">
                                    <dx-button [hint]="'form.Remove'|translate" type="normal"
                                        (click)="view(defaultImage)" stylingMode="text" [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of
                                            'buttonTemplate'">
                                            <ngc-icon size="20" icon="show"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                                <div class="btn-container">
                                    <dx-button [hint]="'form.Remove'|translate" type="normal"
                                        (click)="deleteFile(null, $event)" stylingMode="text"
                                        [template]="'buttonTemplate'">
                                        <div *dxTemplate="let data of 'buttonTemplate'">
                                            <ngc-icon size="20" icon="trash"></ngc-icon>
                                        </div>
                                    </dx-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-lg-8 col-xs-12">
                    <dx-tile-view class="images-list dx-tile-content" [dataSource]="imagesWithoutDefault" noDataText=""
                        showScrollbar="always" (onContentReady)="tileViewContentReady($event)" height="154" width="auto"
                        [baseItemHeight]="142" [baseItemWidth]="218" [itemMargin]="24">
                        <div *dxTemplate="let articleImage of 'item'; let i=
                            index">
                            <!-- cas de nouvel ajout -->
                            <div class="tile-image" *ngIf="!articleImage.id &&
                                ((articleImage[field.editorOptions.multiple +
                                '_upload'] &&
                                articleImage[field.editorOptions.multiple +
                                '_upload'].value) ||
                                articleImage[field.editorOptions.externalUrlFieldName])" [style.background-image]="'url('+
                                ((field.editorOptions.externalUrlFieldName &&
                                articleImage[field.editorOptions.externalUrlFieldName])
                                ?
                                articleImage[field.editorOptions.externalUrlFieldName]
                                :
                                articleImage[field.editorOptions.multiple +
                                '_upload'].value)
                                + ')'">
                                <div class="spaced-image-btn">
                                    <div class="btn-container" *ngIf="field.editorOptions.defaultImageFieldName">
                                        <dx-button [hint]="'form.AddFavoris'|translate" type="normal" (click)="toggledDefaultImage($event,
                                            i)" stylingMode="text" [template]="'buttonTemplate'">
                                            <div *dxTemplate="let data of
                                                'buttonTemplate'">
                                                <ngc-icon size="20" icon="favorite"></ngc-icon>
                                            </div>
                                        </dx-button>
                                    </div>
                                    <div class="btn-container">
                                        <dx-button [hint]="'form.View'|translate" type="normal"
                                            (click)="view(articleImage)" stylingMode="text"
                                            [template]="'buttonTemplate'">
                                            <div *dxTemplate="let data of
                                                'buttonTemplate'">
                                                <ngc-icon size="20" icon="show"></ngc-icon>
                                            </div>
                                        </dx-button>
                                    </div>
                                    <div class="btn-container">
                                        <dx-button [hint]="'form.Remove'|translate" type="normal"
                                            (click)="deleteAddedFile(i)" stylingMode="text"
                                            [template]="'buttonTemplate'">
                                            <div *dxTemplate="let data of
                                                'buttonTemplate'">
                                                <ngc-icon size="20" icon="trash"></ngc-icon>
                                            </div>
                                        </dx-button>
                                    </div>
                                    <!-- <span class="new-image-indicator">{{'upload.newImage' | translate}}</span> -->

                                </div>
                            </div>
                            <!-- cas d'affichage depuis la base -->
                            <div class="tile-image" *ngIf="articleImage.id &&
                                !articleImage[field.editorOptions.multiple +
                                '_upload']" [style.background-image]="'url(' +
                                ((field.editorOptions.externalUrlFieldName &&
                                articleImage[field.editorOptions.externalUrlFieldName])
                                ?
                                articleImage[field.editorOptions.externalUrlFieldName]
                                :
                                (( articleImage[field.editorOptions.multiple] +
                                '&w=' + field.editorOptions.imageSize.w + '&h='
                                + field.editorOptions.imageSize.h | securesrc) |
                                async))
                                + ')'">
                                <div class="spaced-image-btn">
                                    <ng-template [ngIf]="field.editorOptions.defaultImageFieldName
                                        &&
                                        !articleImage[field.editorOptions.defaultImageFieldName]">
                                        <div class="btn-container">
                                            <dx-button [hint]="'form.AddFavoris'|translate" type="normal" (click)="toggledDefaultImage($event,
                                                i)" stylingMode="text" [template]="'buttonTemplate'">
                                                <div *dxTemplate="let data of
                                                    'buttonTemplate'">
                                                    <ngc-icon size="20" icon="favorite"></ngc-icon>
                                                </div>
                                            </dx-button>
                                        </div>
                                    </ng-template>
                                    <div class="btn-container">
                                        <dx-button [hint]="'form.View'|translate" type="normal"
                                            (click)="view(articleImage)" stylingMode="text"
                                            [template]="'buttonTemplate'">
                                            <div *dxTemplate="let data of
                                                'buttonTemplate'">
                                                <ngc-icon size="20" icon="show"></ngc-icon>
                                            </div>
                                        </dx-button>
                                    </div>
                                    <div class="btn-container">
                                        <dx-button [hint]="'form.Remove'|translate" type="normal"
                                            (click)="deleteFile(i)" stylingMode="text" [template]="'buttonTemplate'">
                                            <div *dxTemplate="let data of
                                                'buttonTemplate'">
                                                <ngc-icon size="20" icon="trash"></ngc-icon>
                                            </div>
                                        </dx-button>
                                    </div>

                                </div>
                            </div>
                            <!-- cas d'affichage depuis la base  d'une image a supprimé-->
                            <div class="tile-image" *ngIf="articleImage[field.editorOptions.multiple
                                + '_upload'] && articleImage.id">
                                <div class="deleted-from-database" [style.background-image]="'url(' +
                                    ((field.editorOptions.externalUrlFieldName
                                    &&
                                    articleImage[field.editorOptions.externalUrlFieldName])
                                    ?
                                    articleImage[field.editorOptions.externalUrlFieldName]
                                    :
                                    ((
                                    articleImage[field.editorOptions.multiple] |
                                    securesrc) | async))
                                    + ')'">

                                </div>
                                <dx-button class="restore-btn" [hint]="'form.Restore'|translate" type="normal"
                                    (click)="restoreFile(i)" stylingMode="text" [template]="'buttonTemplate'">
                                    <div *dxTemplate="let data of
                                        'buttonTemplate'">
                                        <ngc-icon size="24" icon="undo"></ngc-icon>
                                    </div>
                                </dx-button>
                            </div>
                        </div>
                    </dx-tile-view>
                </div>
            </div>
        </div>
    </ng-template>

    <div style="position: absolute;right : 0px; margin-top: 5px;
        margin-right: -20px;">


        <dx-button id="picture-help" type="default" stylingMode="text" [focusStateEnabled]="false" [hoverStateEnabled]="false"
            [activeStateEnabled]="false" (mouseleave)="toggleDefaultLeave()" (click)="toggleDefault()">
            <ngc-icon size="24" icon="info"></ngc-icon>
        </dx-button>


        <dx-popover target="#picture-help" position="bottom" [width]="300" [(visible)]="defaultVisible">
            <div *dxTemplate="let data= model of 'content'">
                <h5>{{'upload.astuces' | translate}}</h5>
                <ul>
                    <li>{{'upload.astuce1' | translate}}</li>
                    <li>{{'upload.astuce2' | translate}}</li>
                    <li>{{'upload.astuce3' | translate}}</li>
                </ul>
            </div>
        </dx-popover>
    </div>


    <!--
    <div class="astuces col-md-12 col-sm-12 col-lg-3 col-xs-12" >
        <div>
            <h5>{{'upload.astuces' | translate}}</h5>
            <ul>
                <li>{{'upload.astuce1' | translate}}</li>
                <li>{{'upload.astuce2' | translate}}</li>
                <li>{{'upload.astuce3' | translate}}</li>
            </ul>
        </div>
    </div>

-->
</div>


<dx-popup [width]="700" [wrapperAttr]="{ class: 'popup' }" [height]="250" [title]="'upload.addFromUrlPopupTitle' | translate"
    [(visible)]="popupImageFromExternalUrl">
    <div *dxTemplate="let data of 'content'">
        <div class="popup-wrapper">
            <div class="popup-body">
                <div class="dx-field">
                    <label class="dx-field-label" style="float: none;" for="urlInput">
                        Coller l'URL de l'image
                    </label>
                    <dx-text-box class="dx-field-value auto" mode="url" style="float: none; width: auto;"
                        [(value)]="tempImageUrl" [inputAttr]="{id: 'urlInput',
                        autocomplete: 'autocomplete'}" [placeholder]="'upload.url' | translate">
                    </dx-text-box>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12">
                        <img *ngIf="tempImageUrl" alt="Photo" [src]="tempImageUrl"
                            class="logoSocietyAddonly divImageWithDelete">
                    </div>
                </div> -->
            </div>

            <div class="popup-footer bottom">
                <dx-button text="Ajouter l'image" type="default" class="btn" (onClick)="addImageFromUrl()"
                    style="float:right;margin:10px"></dx-button>
                <dx-button text="Annuler" (onClick)="cancelAddingFromUrl()" class="btn btn-default"
                    style="float:right;margin:10px"></dx-button>
            </div>
        </div>


        <div class="row">

        </div>
    </div>
</dx-popup>

<dx-popup [(visible)]="showImagePopup" [resizeEnabled]="true" [closeOnOutsideClick]="true" [wrapperAttr]="{ class: 'image-view-popup' }">
    <div *dxTemplate="let data of 'content'">
        <dx-button class="close-btn" [hint]="'popup.common.close'|translate" type="default" (click)="closeImageViewer()"
            [focusStateEnabled]="false" [hoverStateEnabled]="false" [activeStateEnabled]="false" stylingMode="text"
            [template]="'buttonTemplate'">
            <div *dxTemplate="let data of 'buttonTemplate'">
                <ngc-icon size="24" icon="times"></ngc-icon>
            </div>
        </dx-button>
        <div *ngIf="popupImage" class="image-holder">
            <div class="show-image default-image" *ngIf="popupImage.id" [style.background-image]="'url(' +
                ((field.editorOptions.externalUrlFieldName &&
                popupImage[field.editorOptions.externalUrlFieldName]) ?
                ((popupImage[field.editorOptions.externalUrlFieldName] | securesrc) | async) :
                (( popupImage[field.editorOptions.multiple] | securesrc) |
                async))
                + ')'">
            </div>
            <div class="show-image default-image" *ngIf="!popupImage.id && popupImage[field.editorOptions.multiple
                + '_upload']" [style.background-image]="'url('+
                popupImage[field.editorOptions.multiple + '_upload'].value+
                ')'">
            </div>
            <div class="show-image default-image" *ngIf="!popupImage.id && !popupImage[field.editorOptions.multiple
                + '_upload']" [style.background-image]="'url('+
                popupImage.value+
                ')'">
            </div>
        </div>
    </div>
</dx-popup>
