import { Country, IncotermCode, TransportUnitCode, ShippingAreaCode, TypeTransportCode, TypeOfGarantCode, CarrierCode, TransportOrganizerCode, TypeCodeAddress, ExportCustomsOffice, LocationDeliveryUECode, ShippingModeCode } from '@app/shared/model';
import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '@app/shared/modules/devextreme/dx-form/models';
import { BaseEntity1 } from '../base-entity1';
import { SpecificFieldForConfscreenModel } from '../admin/SpecificFieldForConfscreenModel';
import { DestinationTypeCode } from '../destinationTypeCode';
import { PostalAddress } from '../postalAddress';

export enum RuleShippingNumber { PARAMETER_SOCIETY, GROUPBY_ADDRESS_SHIPPINGDATE, NOORDER, GROUPBY_ADDRESS_SHIPPINGDATE_CARRIER }
export enum PointOfSalesType { PERMANENT = 1, SEASONAL }

export class CompanyAddress extends BaseEntity2 {
    ruleOnInitializationOfShippingNumber: RuleShippingNumber;
    startDateAnnualClose: Date;
    endDateAnnualClose: Date;
    deliveryTimeStart1: Date;
    deliveryTimeStart2: Date;
    deliveryTimeEnd1: Date;
    deliveryTimeEnd2: Date;
    preferedHour: Date;
    deliveryOnSunday: boolean;
    deliveryOnThursday: boolean;
    deliveryOnHolidayDays: boolean;
    deliveryOnMonday: boolean;
    deliveryOnTuesday: boolean;
    deliveryOnWednesday: boolean;
    deliveryOnSaturday: boolean;
    deliveryOnFriday: boolean;
    pointOfSalesType: PointOfSalesType;
    globalLocationNumber: string;
    comment: string;
    placeOfDelivery: string;
    exciseNumberOfFiscalWareHouse: string;
    postalAddress: PostalAddress;
    latitude: number;
    longitude: number;
    fax: string;
    email: string;
    mobilePhone: string;
    phone: string;
    numeric01: number;
    numeric02: number;
    numeric03: number;
    numeric04: number;
    numeric05: number;
    numeric06: number;
    durationTransport: string;
    preparationHour: Date;
    tourOrder: string;
    destinationTypeCode: DestinationTypeCode;
    incotermCode: IncotermCode;
    typeCodeAddress: TypeCodeAddress;
    exportCustomsOffice: ExportCustomsOffice;
    locationDeliveryUECode: LocationDeliveryUECode;
    shippingModeCode: ShippingModeCode;
    transportOrganizerCode: TransportOrganizerCode;
    carrierCode: CarrierCode;
    typeOfGarantCode: TypeOfGarantCode;
    typeTransportCode: TypeTransportCode;
    transportUnitCode: TransportUnitCode;
    shippingAreaCode: ShippingAreaCode;
    companyAddressDefaultAddress: boolean;

    constructor() {
        super();
        this.ruleOnInitializationOfShippingNumber = null;
        this.startDateAnnualClose = new Date();
        this.endDateAnnualClose = new Date();
        this.deliveryTimeStart1 = new Date();
        this.deliveryTimeStart2 = new Date();
        this.deliveryTimeEnd1 = new Date();
        this.deliveryTimeEnd2 = new Date();
        this.preferedHour = new Date();
        this.deliveryOnSunday = false;
        this.deliveryOnThursday = false;
        this.deliveryOnHolidayDays = false;
        this.deliveryOnMonday = false;
        this.deliveryOnTuesday = false;
        this.deliveryOnWednesday = false;
        this.deliveryOnSaturday = false;
        this.deliveryOnFriday = false;
        this.pointOfSalesType = null;
        this.globalLocationNumber = '';
        this.comment = '';
        this.placeOfDelivery = '';
        this.exciseNumberOfFiscalWareHouse = '';
        this.postalAddress = null;
        this.latitude = 0;
        this.longitude = 0;
        this.fax = '';
        this.email = '';
        this.mobilePhone = '';
        this.phone = '';
        this.numeric01 = 0;
        this.numeric02 = 0;
        this.numeric03 = 0;
        this.numeric04 = 0;
        this.numeric05 = 0;
        this.numeric06 = 0;
        this.durationTransport = '';
        this.preparationHour = new Date();
        this.tourOrder = '';
        this.destinationTypeCode = null;
        this.incotermCode = null;
        this.typeCodeAddress = null;
        this.exportCustomsOffice = null;
        this.locationDeliveryUECode = null;
        this.shippingModeCode = null;
        this.transportOrganizerCode = null;
        this.carrierCode = null;
        this.typeOfGarantCode = null;
        this.typeTransportCode = null;
        this.transportUnitCode = null;
        this.shippingAreaCode = null;
        this.companyAddressDefaultAddress = false;

    }

    initValues(): Partial<CompanyAddress> {
        return {
            ruleOnInitializationOfShippingNumber: RuleShippingNumber.PARAMETER_SOCIETY,
            deliveryOnSunday: true,
            deliveryOnThursday: true,
            deliveryOnHolidayDays: true,
            deliveryOnMonday: true,
            deliveryOnTuesday: true,
            deliveryOnWednesday: true,
            deliveryOnSaturday: true,
            deliveryOnFriday: true,
            pointOfSalesType: PointOfSalesType.PERMANENT
        };
    }

    validator(): ValidationRulesByFieldName {
        return {
            globalLocationNumber: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            comment: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            placeOfDelivery: [
                { type: 'stringLength', max: 255, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            exciseNumberOfFiscalWareHouse: [
                { type: 'stringLength', max: 20, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            fax: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            phone: [
                { type: 'stringLength', max: 40, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            mobilePhone: [
                { type: 'stringLength', max: 30, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'pattern', pattern: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$', message: 'FormErrorMessages.Phone', useTranslator: true }
            ],
            email: [
                { type: 'stringLength', max: 100, message: 'FormErrorMessages.StringMaxLength', useTranslator: true },
                { type: 'email', trim: true, message: 'FormErrorMessages.Mail', useTranslator: true }
            ],
            durationTransport: [
                { type: 'stringLength', max: 10, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ],
            tourOrder: [
                { type: 'stringLength', max: 8, message: 'FormErrorMessages.StringMaxLength', useTranslator: true }
            ]
        };
    }


    get specificFieldForConfscreen(): SpecificFieldForConfscreenModel {

        const specificFieldForConfscreenModel: SpecificFieldForConfscreenModel = new SpecificFieldForConfscreenModel();
        specificFieldForConfscreenModel.subForm = [
            {
                nameProp: 'postalAddress',
                objectInstance: new PostalAddress()
            }
        ];
        specificFieldForConfscreenModel.list = [];
        specificFieldForConfscreenModel.excluded = ['deliveryTimeEnd1'];
        return specificFieldForConfscreenModel;
    }

}

export class CustomerAddress extends CompanyAddress {
    constructor() {
        super();
    }
}

export class SupplierAddress extends CompanyAddress {
    constructor() {
        super();
    }
}
