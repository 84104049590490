import { BaseEntity2 } from '@app/shared/model/base-entity2';
import { ValidationRulesByFieldName } from '../modules/devextreme/dx-form/models';

export class GenericCriteriaValue extends BaseEntity2 {
    criteriaId: number;
    designation: string;
    visible: boolean;
    constructor() {
        super();
        this.criteriaId = 0;
        this.designation = '';
        this.visible = true;
    }

    validator(): ValidationRulesByFieldName {
        return {
            designation : [
                { type: 'required', trim: true, message: 'FormErrorMessages.Required', useTranslator: true }
            ]
        };
    }

    initValues(): Partial<GenericCriteriaValue> {
        return {
            visible: true
        };
    }
}
