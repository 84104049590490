import { CommercialOperationInfo, CustomerCommercialOperation } from '..';


export class CustomerCommercialOperationInfo extends CommercialOperationInfo {
    operation: CustomerCommercialOperation;
    constructor() {
        super();
        this.operation = null;
    }


}

