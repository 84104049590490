import { EventEmitter, Injectable } from '@angular/core';
import { variablesKeys } from '@app/globalsParameter';
import * as signalR from '@aspnet/signalr';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { AppContextService } from '../app-context.service';
import { AppConfiguration } from '../application/app-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    messageReceived = new EventEmitter<any>();
    importReceived = new EventEmitter<any>();

    private _hubConnection: HubConnection;
    constructor(
        private appContext: AppContextService,
        private appConfiguration: AppConfiguration) {

    }

    public connect(): Promise<any> {
        return new Promise(resolve => {
            this.createConnection();
            this.registerOnServerEvents();
            this.startConnection();
            //SH Migration 10 => 11 [15/06/2021]
            //Passée null comme param pour resolve puisque resole doit avoir un seul param
            resolve(null);
        });
    }

    public createConnection() {
        const options: signalR.IHttpConnectionOptions = {
            accessTokenFactory: () => {
                return (this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).accessToken;
            }
        };
        this._hubConnection = new HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(this.appConfiguration.UrlsConfig.wsSignalR, options)
            .build();

        this._hubConnection.onclose(err => {
            if ((this.appContext.getPersisted(variablesKeys.ACCESS_TOKEN) || {}).accessToken) {
                this.startConnection();
            }
        });
    }


    public startConnection(): void {
        this._hubConnection
            .start()
            .then(() => {
                this._hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 30;
                this._hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 15;
            })
            .catch(err => {
                setTimeout(() => { this.startConnection(); }, 5000);
            });
    }

    public registerOnServerEvents(): void {
        this._hubConnection.on('sendToAll', (data: any) => {
            this.messageReceived.next(data);
        });

        this._hubConnection.on('sendToSpecificUser', (data: any) => {
            this.messageReceived.next(data);
        });

        this._hubConnection.on('importProgress', (data: any) => {
            const importProgress = JSON.parse(data);
            this.importReceived.next(importProgress);
        });

        this._hubConnection.on('reportGenerated', (data: any) => {
            const report = {
                url : data
            }
            this.messageReceived.next(report);
        });

        this._hubConnection.on('sendToSociety', (data: any) => {
            this.messageReceived.next(data);
        });
    }

    public closeConnection(): void {
        if (this._hubConnection && this._hubConnection.state === 1) {
            this._hubConnection.stop();
        }
    }

}
