import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {
    private data: Map<string, HttpResponse<any>> = new Map<string, HttpResponse<any>>();
    constructor() {
    }


    public isCachable(req: HttpRequest<any>): boolean {
        if (req.params.has('cache')) {
            return req.params.get('cache') === 'true';
        }
    }

    public get(req: HttpRequest<any>): any {
        return this.data.get(req.url);
    }

    public put(req: HttpRequest<any>, event: HttpResponse<any>): void {
        this.data.set(req.url, event);
    }

    public clearCache() {
        this.data = new Map<string, HttpResponse<any>>();
    }
}
