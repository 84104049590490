import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ArtCost, AcceptPit, Article, IBaseEntity } from 'app/shared/model';

import { IDaoBaseService } from '@app/shared/interface';
import { TranslateService } from '@ngx-translate/core';
import { DaoGridStateBaseService } from './../dao-grid-state-base.service';
import { NGCResults } from '@app/shared/model/NGCResults/NGCResults';
import { AppConfiguration } from '../application/app-configuration.service';
import { DataGridFiltersParam } from '@app/shared/viewModel/data-grid-filters-param';
import { DetailSet } from '@app/shared/viewModel/detailSet';


@Injectable({
    providedIn: 'root'
})
export class ArtCostService extends DaoGridStateBaseService implements IDaoBaseService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService,
        private appConfiguration: AppConfiguration) {
        super(http, translate, appConfiguration);
    }

    getAll(id: string, date?: any): Observable<ArtCost[]> {
        const url = this.appConfiguration.UrlsConfig.wsArtCost;
        if (date) {
            return this.http.get<ArtCost[]>(url + id + '/' + date).pipe(
                shareReplay(1)
            );
        } else {
            return this.http.get<ArtCost[]>(url + id).pipe(
                shareReplay(1)
            );
        }
    }

    post(payload: ArtCost, article: Article): Observable<NGCResults<ArtCost>> {
        payload.article = article;
        const url = this.appConfiguration.UrlsConfig.wsArtCost;
        if (payload.valueHT) {
            payload.valueHT = Number(payload.valueHT.toString().replace(',', '.'));
        } else if (payload.valueTTC) {
            payload.valueTTC = Number(payload.valueTTC.toString().replace(',', '.'));
        }
        return this.http.post<NGCResults<ArtCost>>(url, payload);
    }


    put(id: number, payload: ArtCost, acceptPit: AcceptPit): Observable<NGCResults<ArtCost>> {
        const url = this.appConfiguration.UrlsConfig.wsArtCost + id + '/' + acceptPit;
        if (payload.valueHT) {
            payload.valueHT = Number(payload.valueHT.toString().replace(',', '.'));
        } else if (payload.valueTTC) {
            payload.valueTTC = Number(payload.valueTTC.toString().replace(',', '.'));
        }
        return this.http.put<NGCResults<ArtCost>>(url, payload);
    }


    calculate(payload: any): Observable<any> {
        const url = this.appConfiguration.UrlsConfig.wsArtCost + '/calculate';
        return this.http.put<any>(url, payload);
    }


    delete(id): Observable<any> {

        const url: string = this.appConfiguration.UrlsConfig.wsArtCost;
        return this.http.delete(url + id);
    }

    navigateToList(articleId: string | number): void {
        this.router.navigate(['/article/' + articleId + '/pricing']);
    }

    navigateToDetail(id: string | number, ...params: any[]): void {
        throw new Error('Method not implemented.');
    }
    navigateToCreatePage(...params: any[]): void {
        throw new Error('Method not implemented.');
    }
}
