export const codeVersion = '1.0.13';
export const languages = ['fr', 'en'];
export const sessionLifeDuration = 30 * 60 * 1000; // (30m * 60 => donne en seconde) * 1000 => en milliseconde
export const notifyErrorDelay = 3000;
export const notifySuccessDelay = 3000;
export const notifyInfoDelay = 3000;

export const theme = [
    { 'value': 'generic.light' },
    { 'value': 'generic.greenmist' },
    { 'value': 'material.blue.light' }
];

export const tippingPointTablet = 600;
export const tippingPointMobile = 400;
export const passwordMask = 'Aa00000000';
export const numberOfTuplesForCache = 41;

export const variablesKeys = {
    COUNTRY_LIST: 'countries',
    REMEMBER_ME: 'stayConnected',
    ACCESS_TOKEN: 'access_token',
    USER: 'user',
    USER_PROFILE: 'userProfile',
    POPUP_CONFIG: 'next-dx-popup',
    SCREEN_CONFIG: 'screenConfig',
    CUSTOMER_COMMERCIAL_OPERATION_PARAMETERS: 'CustomerCommercialOperationParameters',
    SUPPLIER_COMMERCIAL_OPERATION_PARAMETERS: 'SupplierCommercialOperationParameters',
    LOCALE: 'locale',
    REVERTED_DATASOURCE: 'revertedDataSource',
    EMPTY_ROW : 'emptyRow'
};

export const dataGrid_column_position_for_number = 'right';
export const dataGrid_column_position_for_string = 'left';
export const dataGrid_column_position_for_price = 'right';
